import React, { useState,useEffect } from "react";
import { Form } from "react-bootstrap";

export default function ScrollBarForForecast(props) {
  useEffect(()=>{
    setValue(props.data.value)
  },[props])
  var [value, setValue] = useState(props.data.value); // Initialize value
  // console.log(props.data,'data')
  var [data,setData]=useState(props.data)
  const handleChange =async (event) => {
    setValue(parseInt(event.target.value)); // Update value when slider is moved
    data.value = parseInt(event.target.value);
  };

  var min = 1;
  var max = 10;
  return (<>
  
    <div className="col-sm-12">
      <label className="ml-3 mb-0p col-sm-4" style={{color:'black'}}>{props.data.label}</label>
      <span className="col-sm-4">
      <Form.Range style={{marginLeft:'30px'}} min={min}  max={max} value={props.data.value} onChange={handleChange} />
      <span>&nbsp;&nbsp;&nbsp;<span className="mt-0 mb-3 pb-1" style={{color:'black',}}> &nbsp;{value}&nbsp;</span></span>
      </span>
    </div>
    </>
  );
}

