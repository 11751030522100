import React, { Component } from "react";
import Level2 from "./configL2";

class Level1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: true,
    };
  }
  componentDidMount = () => {
    // var data = this.props.data
    // var keys = Object.keys(data)
    // console.log(data,'hello')
  };
  changeHandler = async (item, event) => {
    var data = this.props.data;

    var keys = Object.keys(data);
    // console.log("item", item, item === "Telescopic Deviation");
    if (item === "Telescopic Deviation" && data[item].value === 0) {
      data[item]["General Loads Telescopic Deviation"].value = 1;
      data[item]["General Loads Telescopic Deviation"][
        "Dedicated Deviation Percentage"
      ].value = 40;
      data[item]["General Loads Telescopic Deviation"][
        "Dedicated Deviation Distance(KM)"
      ].value = 25;
      data[item]["General Loads Telescopic Deviation"][
        "Spot Deviation Percentage"
      ].value = 40;
      data[item]["General Loads Telescopic Deviation"][
        "Spot Deviation Distance(KM)"
      ].value = 75;
    }
    // if (item === "Telescopic Deviation") {
    //   console.log("item", data[item]);
    // }
    if (data[item].value === 0) {
      data[item].value = 1;
      if (data[item].type === "radio") {
        keys.map((key) => {
          if (
            data[item].id === data[key].id &&
            data[item].name !== data[key].name
          ) {
            data[key].value = 0;
          }
          return "";
        });
      }
    } else {
      data[item].value = 0;
    }

    if (data[item].type === "number") {
      data[item].value = parseInt(event.target.value);
    }
    if (item === "Use Dynamic Planning Window") {
      if (data[item].value === 1) {
        data["Number of Days to Use Planning Window"].disabled = true;
        data["Number of Days to Use Planning Window"].value = 0;
      } else {
        data["Number of Days to Use Planning Window"].disabled = false;
      }
    }
    await this.setState((prevState) => {
      return { click: !prevState.click };
    });
  };

  render() {
    var data = this.props.data;
    var keys = Object.keys(data);
    return (
      <div className="col-md-12 mr-5" style={{ display: "block" }}>
        <div className="row mt-30p">
          {keys.map((item, idx) => {
            if (
              item !== "type" &&
              item !== "location" &&
              item !== "order_id" &&
              item !== "value" &&
              item !== "disabled" &&
              item !== "name" &&
              item !== "id"
            ) {
              return (
                <div key={idx} className="col-md-12 mr-3" id={item}>
                  <label className="fw-600">
                    <input
                      checked={data[item].value === 1}
                      type={data[item].type}
                      disabled={data[item].disabled}
                      id={data[item].name}
                      name={data[item].name}
                      value={data[item].value}
                      onChange={(event) => {
                        this.changeHandler(item, event);
                      }}
                    />
                    &nbsp;&nbsp;{item}
                  </label>
                  <div>
                    <Level2 data={data[item]} status={data[item].value} />
                  </div>
                </div>
              );
            }
            return "";
          })}
        </div>
      </div>
    );
  }
}

export default Level1;
