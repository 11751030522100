import React,{Component} from 'react';
import CSVFileValidator from 'csv-file-validator';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker'
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Select from 'react-select';
import redirectURL from '../redirectURL';
import ViewMap from "./viewmap";
import Constant from "../common/constants";
import getToolConfiguration from "./commonFunctions";
import GridButton from './gridButton';
import {logFormChanges} from './userActivityPickupDrop'
import $ from 'jquery';
import { ColorRing } from 'react-loader-spinner'

var map;

var months={
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
}
export default class PickupDropLocations extends Component{
    constructor(props){
        super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
      },
      rowData: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 100,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      showSlideBlockPickupDrop: "",
      showloader: "show-n",
      csvfiledata: [],
      openPickupDropLocationsModel: false,
      address_name: "",
      addressLat: "",
      addressLng: "",
      location_name: "",
      location_city: "",
      location_type: "",
      location_code: "",
      show: false,
      basicTitle: "",
      basicType: "default",
      data_type: "",
      modalType: 1,
      location_address: "",
      latitude: "",
      longitude: "",
      pickup_from_time: "",
      pickup_to_time: "",
      drop_from_time: "",
      latError: "",
      longError: "",
      drop_to_time: "",
      rowId: "",
      pickup_window_time: ["00:00", "23:59"],
      drop_window_time: ["00:00", "23:59"],
      mapp: false,
      vehiclelist: [],
      vehicle_type: [{ value: "none", label: "None" }],
      drop_corresponding_pickup_location_code: "",
      correspondingPickupLocationList: [],
      zoneNames: [],
      zone: "",
      customerTypeList: [
        { label: "OT", value: "OT" },
        { label: "GT", value: "GT" },
      ],
      customer_type: { label: "GT", value: "GT" },
      distance_from_plant: "",
      standard_transit_time: "",
      week_off: [{ label: "No Week Off", value: "no_week_off" }],
      weekdays: [
        { label: "No Week Off", value: "no_week_off" },
        { label: "Sunday", value: "sunday" },
        { label: "Monday", value: "monday" },
        { label: "Tuesday", value: "tuesday" },
        { label: "Wednesday", value: "wednesday" },
        { label: "Thursday", value: "thursday" },
        { label: "Friday", value: "friday" },
        { label: "Saturday", value: "saturday" },
      ],
      location_threshold_time: 30,
      locationNames: [],
      location: "",
      dealer_type: "",
      csp_day_list: [{ label: "NONE", value: "NONE" }],
      csv_days: [
        { label: "NONE", value: "NONE" },
        { label: "SUNDAY", value: "SUNDAY" },
        { label: "MONDAY", value: "MONDAY" },
        { label: "TUESDAY", value: "TUESDAY" },
        { label: "WEDNESDAY", value: "WEDNESDAY" },
        { label: "THURSDAY", value: "THURSDAY" },
        { label: "FRIDAY", value: "FRIDAY" },
        { label: "SATURDAY", value: "SATURDAY" },
      ],
      loadshow: "show-n",
      overlayBlock: "show-n",
      previousData: "",
      zonesData: [],
      kddCusYes: "no",
      kddCusNo: "yes",
      kddPeriodFinite: "no",
      kddPeriodInfinite: "yes",
      kddStartMonth: { label: "Jan", value: 1 },
      kddEndMonth: { label: "Dec", value: 12 },

    };

    // this.showMap = this.showMap.bind(this);

    }

    componentDidMount = async() =>{
        this.setState({
            loadshow:"show-m",
            overlayBlock:"show-m"
        })

        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let toolparameters = {
            data_type : data_type
        }
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(toolparameters)
        await this.getLocationsData(params)
        await this.getVehiclesData(params);
        await this.getZoneMasterDetails(params)
        await this.boundTypeLocations(params)
        await this.setState({
            loadshow:"show-n",
            overlayBlock:"show-n"
        })
    }    
    getZoneMasterDetails = async(param) => {

        let zoneNames = this.state.zoneNames;
        
        await redirectURL.post('master/getZoneMasterDetails',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let zonesData = response.data.zonesData
                  if(zonesData.length > 0){
                      zonesData.map(item =>{
                          zoneNames.push({ value : item.zone,
                              label : item.zone })
                      })
                      await this.setState({
                          zoneNames : zoneNames,
                          zonesData : zonesData,
                          zone:zoneNames[0]
                      })
                  }
              }
          })
          .catch(function (error) {
            var errDetails={
                url:"master/getZoneMasterDetails",
                screen:'Pick Up/Drop Locations',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
      }

    getVehiclesData = (params) =>{
        
        redirectURL.post('master/getVehiclesData',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                let vehiclesData = response.data.vehiclesData
                var vehiclelist=[{value:"none",label:"None"},{value:"all",label:"All"}];
                await vehiclesData.map(item => {
                    vehiclelist.push({
                        value:item.vehicle_type,
                        label:item.vehicle_type,
                        locCode:item.location_code
                    })
                })
            this.setState({
                vehiclelist:vehiclelist
            })
        }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/getVehiclesData",
                screen:'Pick Up/Drop Locations',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
    }
    

    getLocationsData = (params) =>{
        let location_access = JSON.parse(localStorage.getItem("location_access"))
        let correspondingPickupLocationList =[];
        
        redirectURL.post('master/getLocationsData',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                // column value change for pickup/drop to show on ui
                let locationsData = response.data.locationsData;
                if(location_access != null){
                    locationsData = await locationsData.filter(itm =>{
                        if(location_access.includes(itm.drop_corresponding_pickup_location_code)){
                            return itm
                        }
                    })
                }
                
                locationsData.map(item =>{
                    if(item.location_type == 1 && item.data_type == 2){

                        correspondingPickupLocationList.push({ value : item.location_code,
                            label : item.location_code })
                    }
                })
            this.setState({
                rowData:locationsData,
                correspondingPickupLocationList:correspondingPickupLocationList,
                drop_corresponding_pickup_location_code:correspondingPickupLocationList[0]
            })
        }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/getLocationsData",
                screen:'Pick Up/Drop Locations',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
    }
  
    openModelForPickupDrop = async() =>{
        let logParams = {
            // "location_code" : location.value,
            // "location_name" : location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Pick up/Drop Locations",
            "activity" : 'clicked on Add PickUp/Drop Locations Button',
            "event" : "Add pickup/Drop Location Modal Opened",
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)               
        
        await this.setState(prevState =>({
            drop_corresponding_pickup_location_code:'',
            zone:'',
            openPickupDropLocationsModel :!prevState.openPickupDropLocationsModel,
            addressLng:'',
            addressLat:'',
            address_name:'',
            location_name:'',
            location_city:'',
            location_type:'',
            location_code:'',
            pickup_window_time:['00:00','23:59'],
            drop_window_time:['00:00','23:59'],
            rowId:'',
            customer_type:{label:'GT',value:'GT'},
            distance_from_plant:'',
            standard_transit_time:'',
            week_off:[{label:'No Week Off',value:'no_week_off'}],
            location_threshold_time:30,
            dealer_type:'',
            csp_day_list:[{label:'NONE',value:'NONE'}],

            modalType : 1,
            mapp : !prevState.mapp
        }))
        setTimeout(async()=> {
            await this.showMap();
		}, 1000 );
    }

    handelPickupDropModelCancel = ()=>{
        let logParams = {
          user_name: localStorage.getItem("username"),
          useremail: localStorage.getItem("email"),
          client: localStorage.getItem("client"),
          screen: "Summary Dashboard",
          activity: "clicked on Cancel Button in the Modal",
          event: "Modal is closed",
        };

        redirectURL.post("/master/loguserUpdatesorChanges", logParams);
        this.setState((prevState) => ({
          openPickupDropLocationsModel: !prevState.openPickupDropLocationsModel,
          addressLng: "",
          addressLat: "",
          address_name: "",
          location_name: "",
          location_city: "",
          location_type: "",
          location_code: "",
          pickup_window_time: ["00:00", "23:59"],
          drop_window_time: ["00:00", "23:59"],
          rowId: "",
          customer_type: { label: "GT", value: "GT" },
          distance_from_plant: "",
          standard_transit_time: "",
          week_off: [{ label: "No Week Off", value: "no_week_off" }],
          location_threshold_time: 30,
          dealer_type: "",
          csp_day_list: [{ label: "NONE", value: "NONE" }],
          kddCusYes: 'no',
          kddCusNo: 'yes',
          kddPeriodFinite: 'no',
          kddPeriodInfinite: 'yes',
          kddStartMonth: { label: "Jan", value: 1 },
          kddEndMonth: { label: "Dec", value: 12 },
        }));

    }

    openUploadOptionForPickupDrop = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            showSlideBlockPickupDrop:"slide25",
            overlayBlock:"show-m"
          })
    }

    hideSlideBlock = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            overlayBlock:"show-n",
            showSlideBlockPickupDrop:"",
        })
      }

      formCSVUpload = (event) =>
      {
        let data_type = this.state.data_type;
        event.preventDefault();
        var csvfiledata = this.state.csvfiledata;
        // console.log(csvfiledata,"first picku locs");
        csvfiledata.map((item) => {
          if (item.vehicle_list === "") {
            item.vehicle_list = '["None"]';
          }
          if (item.week_off === "") {
            item.week_off = '["None"]';
          }
        });
        var params = {
          filedata: csvfiledata,
          data_type: data_type,
          user: localStorage.getItem("username"),
          mail: localStorage.getItem("email"),
        };
        // console.log(params, "csv file data")
        redirectURL
          .post("/master/uploadLocationsData", params)
          .then(async (resp) => {
            if (resp.data.status === "success") {
              await this.setState({
                show: true,
                basicTitle: resp.data.message,
                basicType: "success",
                overlayBlock: "show-n",
                showSlideBlockPickupDrop: "",
              });
              this.getLocationsData({data_type:2});

              // window.location.reload();
            } else {
              this.setState({
                show: true,
                basicTitle: resp.data.message,
                basicType: "danger",
              });
            }
          })
          .catch(function (error) {
            var errDetails = {
              url: "/master/uploadLocationsData",
              screen: "Pick Up/Drop Locations",
              error,
            };
            redirectURL.post("/master/logErrorsForApiCalls", errDetails);
          });
    
      }


      onClickUploadFile() {
        $("#csvfile").val("");
      }
      
  changeCSVFileHandler = async (e) => {
    const config = {
      headers: [
        {
          name: "Location Type",
          inputName: "location_type",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Location Code",
          inputName: "location_code",
          required: true,
          unique: true,
          uniqueError: function (headerName) {
            return `${headerName} is not unique`;
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Location Name",
          inputName: "location_name",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Latitude",
          inputName: "latitude",
          required: true,
          validate: function (latValue) {
            return isValidLatitude(latValue);
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },

        {
          name: "Longitude",
          inputName: "longitude",
          required: true,
          validate: function (lngValue) {
            return isValidLongitude(lngValue);
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Zone",
          inputName: "zone",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Pickup Location Code",
          inputName: "drop_corresponding_pickup_location_code",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Customer Type",
          inputName: "customer_type",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
          validate: function(code) {
            return validateCusType(code,)
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Standard Transit Time",
          inputName: "standard_transit_time",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Distance From Plant",
          inputName: "distance_from_plant",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Location Threshold Time",
          inputName: "location_threshold_time",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Vehicle Restriction List",
          inputName: "vehicle_list",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Week Off",
          inputName: "week_off",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "CSP Day",
          inputName: "csp_day_list",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Location City",
          inputName: "location_city",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Dealer Type",
          inputName: "dealer_type",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "KDD Customer",
          inputName: "kdd_customer",
          required: true,
          validate: function (kdd_customer) {
            return validateYesNoforKDD(kdd_customer);
          },
          validateError: function (headerName) {
            return `${headerName} should be "yes" or "no" only`;
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "KDD Finite Period",
          inputName: "kdd_finite_period",
          required: true,
          validate: function (kdd_finite_period) {
            return validateYesNoforKDD(kdd_finite_period);
          },
          validateError: function (headerName) {
            return `${headerName} should be "yes" or "no" only`;
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "KDD Period Start Month",
          inputName: "kdd_period_start_month",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
          validate: function (kdd_period_start_month) {
            // return validateMonthforKDD(kdd_period_start_month);
            return kdd_period_start_month > 0 && kdd_period_start_month < 13;
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column s/b less than 13`;
          },
        },
        {
          name: "KDD Period End Month",
          inputName: "kdd_period_end_month",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
          validate: function (kdd_period_end_month) {
            return kdd_period_end_month > 0 && kdd_period_end_month < 13;
            // return validateMonthforKDD(kdd_period_end_month);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column s/b less than 13`;
          },
        },
        // {
        //     name: 'Location Address',
        //     inputName: 'location_address',
        //     required: true,
        //     requiredError: function (headerName, rowNumber, columnNumber) {
        //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
        //     }
        // },
        // {
        //     name: 'From Time',
        //     inputName: 'from_time',
        //     required: false,
        // },
        // {
        //     name: 'To Time',
        //     inputName: 'to_time',
        //     required: false,
        // }
      ],
    };
    var data = CSVFileValidator(e.target.files[0], config)
      .then((csvData) => {
        document.getElementById("inValidDataInfo").innerHTML = "";
        if (csvData.inValidData.length > 0) {
          $("#csvfile").val("");
          document.getElementById("bulkUploadBtn").type = "button";
          document
            .getElementById("bulkUploadBtn")
            .classList.remove("btn-danger");
          document
            .getElementById("bulkUploadBtn")
            .classList.add("btn-secondary");
          let invalidData = csvData.inValidData;
          let element = document.getElementById("inValidDataInfo");
          invalidData.map((item) => {
            let row, column;
            if (item.rowIndex == undefined) {
              row = "NA";
            } else {
              row = item.rowIndex;
            }
            if (item.columnIndex == undefined) {
              column = "NA";
            } else {
              column = item.columnIndex;
            }

            element.innerHTML +=
              "Column : " +
              column +
              "," +
              " Row : " +
              row +
              "--" +
              item.message +
              "<br></br>";
            return "";
          });
        } else {
          document.getElementById("bulkUploadBtn").type = "submit";
          document
            .getElementById("bulkUploadBtn")
            .classList.remove("btn-secondary");
          document.getElementById("bulkUploadBtn").classList.add("btn-danger");
          this.setState({
            csvfiledata: csvData.data,
          });
        }
      })
      .catch((err) => {});

    // var out = new Promise(function(reject, resolve){
    //     var reader =  new FileReader();
    //     reader.onload = async function(e) {
    //         var contents = await e.target.result;
    //         resolve(contents);
    //     };
    //     var tt =  reader.readAsText(e.target.files[0]);
    // });

    // console.log("e.target.files[0].type ", e.target.files[0]);

    // if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    // {
    // console.log(fileData);
    // console.log(typeof(fileData))
    // }
    // else{
    //     e.target.value = null;
    //     this.setState({
    //         uploadFile:'',
    //         show: true,
    //         basicType:'danger',
    //         basicTitle:'Please upload file having extensions .csv only.',
    //     });
    // }
  };


    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {

        //   loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyC6cJAlLsrTTgm5Ck795Vg6X_EToa7FG-A&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}


    initMap = () => {

        const getAddressLatLng = (address,lat,lng)=>{
            
            this.setState({
                addressLng:lng,
                addressLat:lat,
                address_name:address
               })
            }

        const updateLatLngOnClickMap = (lat,lng)=>{

            
            var reqparams = new FormData()
            reqparams.append("location_latitude", lat)
            reqparams.append("location_longitude", lng)

            
            redirectURL.post("http://216.48.191.229:8010/dispatch_planning/fetchLocationAddressDetails",reqparams)
            .then((response) => {
                if(response.data.status == "Success"){
                    this.setState({
                        addressLng:lng,
                        addressLat:lat,
                        address_name:response.data.formatted_address
                       })
                }else{
                    this.setState({
                        addressLng:lng,
                        addressLat:lat,
                        address_name:''
                       })
                }

            })
            .catch(function (error) {
                var errDetails={
                    url:"http://216.48.191.229:8010/dispatch_planning/fetchLocationAddressDetails",
                    screen:'Pick Up/Drop Locations',
                  error,
                  }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
            

        }    
        try{
            var latitude = 28.6139391;
            var longitude = 77.2090212;
            if(this.state.addressLat !=undefined && this.state.addressLng !=undefined && this.state.addressLat !="" && this.state.addressLng !="")
            {
                var latitude = this.state.addressLat;
                var longitude = this.state.addressLng;
            }
    	map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 12,
            center: new window.google.maps.LatLng(latitude,longitude),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: window.google.maps.ControlPosition.TOP_RIGHT
			},            
			styles: [
                    
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#444444"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 65
						},
						{
							"visibility": "on"
						}
					]
				},
				
				{
					"featureType": "landscape.man_made",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#c1c2c2"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
			],
			disableDefaultUI: true,
			zoomControl: true
          });
            if(this.state.addressLat !=undefined && this.state.addressLng !=undefined && this.state.addressLat !="" && this.state.addressLng !="")
            {
                var latLng=new window.google.maps.LatLng(parseFloat(this.state.addressLat),parseFloat(this.state.addressLng));
                var marker = new window.google.maps.Marker({
                    position: latLng,
                    icon: require('../../assets/images/business.png'),
                    map: map
                });
                
                markers.push(marker);
            }
        }
        catch(e){
            console.log(e)
        }
  	    var input = document.getElementById('search');
          var searchBox = new window.google.maps.places.SearchBox(input);
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

          map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());
          });

  		var markers = [];
  		 searchBox.addListener('places_changed', function() {
            var places = searchBox.getPlaces();
            
            if (places.length == 0) {
              return;
            }

            markers.forEach(function(marker) {
              marker.setMap(null);
            });
            markers = [];
            var bounds = new window.google.maps.LatLngBounds();
            places.forEach(function(place) {
              if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
              }
              var icon = {
                url: place.icon,
                size: new window.google.maps.Size(71, 71),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 34),
                scaledSize: new window.google.maps.Size(25, 25)
              };


			var image =  require('../../assets/images/business.png');
              markers.push(new window.google.maps.Marker({
                map: map,
                icon: image,
                title: place.name,
                position: place.geometry.location
              }));
            
                getAddressLatLng(place.formatted_address,place.geometry.location.lat(),place.geometry.location.lng())
                bounds.extend(place.geometry.location);
            });
            map.fitBounds(bounds);
          });
  		
  		window.google.maps.event.addListener(map, "click", (event) => {
            markers.forEach(function(marker) {
                marker.setMap(null);
              });
              var image
            markers.push(new window.google.maps.Marker({
                map: map,
                icon: image,
                // title: place.name,
                position: event.latLng
              }));
            
            updateLatLngOnClickMap(event.latLng.lat(),event.latLng.lng())
            
          });
    
 
    }
    
    
    addressInputField = (e) =>{
        
        this.setState({address_name:e.target.value})
    }
    handleSearchLocation = (e) => {

        this.setState({address_name:e.target.value})
    }
    validateLatLong = (value) => {
        const regex = /^-?\d+\.\d{4,}$/; // Regex to match at least 4 digits after decimal point
        return regex.test(value);
    };
    handelLatInput = (e) =>{

        let name = e.target.name
        let value = e.target.value
        var regex=/^^[-+]?(90(\.0+)?|[1-8]?\d(\.\d+)?)$/
        var out=regex.test(value)
        if( value !== ''){
        if(out){
        this.setState({ [name] : value })
        }}else{
        this.setState({ [name] : value })
        }
    }
    handleLatBlur = (e) => {
        if(!this.validateLatLong(this.state.addressLat)){
            this.setState({
                latError: "Please Enter Correct Latitude"
            })
        } else{
            this.setState({
                latError:''
            })
        }
    }
    handelLngInput = (e) =>{

        let name = e.target.name
        let value = e.target.value
        var regex=/^(-?)(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
        var out=regex.test(value)
        if(value !==''){
            if(out){
        this.setState({ [name] : value })}
            }else{
            this.setState({ [name] : value })
            }
    }
    handleLngBlur = (e) => {
        if(!this.validateLatLong(this.state.addressLng)){
            this.setState({
                longError: "Please Enter Correct Longitude"
            })
        } else{
            this.setState({
                longError:''
            })
        }
    }
    locationTypeHandler = (event) => {
        // console.log(event)
		let value = event.target.value;
    
    	this.setState({location_type:value});
    }

    kddTypeYesHandler = () => {
      // console.log('first',this.state.kddCusYes)
      this.setState({ kddCusYes: 'yes', kddCusNo: 'no' });
    }
    
    kddTypeNoHandler = () => {
      // console.log('first',this.state.kddCusNo)
      this.setState({
        kddCusYes: "no",
        kddCusNo: "yes",
        kddPeriodFinite: "no",
        kddPeriodInfinite: "yes",
        kddStartMonth: { label: "Jan", value: 1 },
        kddEndMonth: { label: "Dec", value: 12 },
      });
    }
    
    kddPeriodFiniteHandler = () => {
      this.setState({ kddPeriodFinite: 'yes', kddPeriodInfinite: 'no' });
    }
    
    kddPeriodInfiniteHandler = () => {
      
      this.setState({
        kddPeriodFinite: "no",
        kddPeriodInfinite: "yes",
        kddStartMonth: { label: "Jan", value: 1 },
        kddEndMonth: { label: "Dec", value: 12 },
      });
    }
    
    kddStartMonthHandler = (value,{name}) => {
      console.log("first mont", name, value);
      this.setState({ [name]: value });
    }
    
    locationCodeField = (e) => {
        let name = e.target.name
        let value = e.target.value.toUpperCase()
        var regex = /^[a-zA-Z0-9_]+$/
        var out=regex.test(value)
        if(value !==''){
        if(out){
        this.setState({ [name] : value })
        }
        }else{
        this.setState({ [name] : value })
        }
    }
    locationNameField = (e) => {
        let name = e.target.name
        let value = e.target.value
        var regex=/^[a-zA-Z\s]*$/
        var out=regex.test(value)
        if(name == "vehicle_type"){
            value = value.toUpperCase()
        }
        if(out){
        this.setState({ [name] : value })}
		// let value = event.target.value;
    	// this.setState({location_name:value});
    }
    locationCityField = (e) => {
        let name = e.target.name
        let value = e.target.value
        var regex=/^[a-zA-Z\s]*$/
        var out=regex.test(value)
        if(name == "vehicle_type"){
            value = value.toUpperCase()
        }
        if(out){
        this.setState({ [name] : value })}
		// let value = event.target.value;
    	// this.setState({location_name:value});
    }
    handlePickupWindowTime = (val) => {
    	this.setState({pickup_window_time:val});
    }
    handleDropWindowTime = (val) => {
    	this.setState({drop_window_time:val});
    }

    closeAlert = async() => {
        await this.setState({
          show: false,
          loadshow: "show-n",
        });
	}

    saveLocationsData = (params) =>{
        let data_type = this.state.data_type;
        var basicTitle;
        if (params.form_type === 1) {
          basicTitle = "Location Added Successfully";
        } else {
          basicTitle = "Location Updated Successfully";
        }

        // console.log(params, "params 1035")

        redirectURL
          .post("/master/saveLocationsData", params)
          .then(async (response) => {
            // console.log("firstd", response.data);
            if (response.data.status === "success") {
              let param = {
                data_type: data_type,
              };

              this.getLocationsData(param);
              this.setState((prevState) => ({
                openPickupDropLocationsModel: false,
                addressLng: "",
                addressLat: "",
                address_name: "",
                location_code: "",
                location_name: "",
                location_city: "",
                location_type: "",
                zone: "",
                drop_corresponding_pickup_location_code: "",
                pickup_window_time: ["00:00", "23:59"],
                drop_window_time: ["00:00", "23:59"],
                rowId: "",
                customer_type: { label: "GT", value: "GT" },
                distance_from_plant: "",
                standard_transit_time: "",
                week_off: [{ label: "No Week Off", value: "no_week_off" }],
                location_threshold_time: 30,
                dealer_type: "",
                csp_day_list: [{ label: "NONE", value: "NONE" }],
                showloader: "show-n",
                show: true,
                basicTitle: basicTitle,
                basicType: "success",
                loadshow: "show-n",
                kddCusYes: 'no',
                kddCusNo: 'yes',
                kddPeriodFinite: 'no',
                kddPeriodInfinite: 'yes',
                kddStartMonth: { label: "Jan", value: 1 },
                kddEndMonth: { label: "Dec", value: 12 },
              }));
            } else {
              this.setState({
                showloader: "show-n",
                show: true,
                basicTitle: response.data.message,
                basicType: "danger",
              });
            }
          })
          .catch(function (error) {
            var errDetails = {
              url: "/master/saveLocationsData",
              screen: "Pick Up/Drop Locations",
              error,
            };
            redirectURL.post("/master/logErrorsForApiCalls", errDetails);
          });
    
    }

    validateTime = (window_time) =>{
        let frm_hr = window_time[0].split(':')
            let to_hr = window_time[1].split(':')
            
            if(window_time[0] == window_time[1]){
                this.setState({
                    show:true,
                    basicTitle:"Please Input Time Appropriately",
                    basicType:"danger"
                })
            }
            else if(frm_hr[0] > to_hr[0]){
                this.setState({
                    show:true,
                    basicTitle:"Please Input Time Appropriately",
                    basicType:"danger"
                })
            }else if(frm_hr[0] == to_hr[0]){
                if(frm_hr[1] > to_hr[1]){
                    this.setState({
                        show:true,
                        basicTitle:"Please Input Time Appropriately",
                        basicType:"danger"
                    })
                }else{
                    return true
                }
            }else{
                return true
            }
    }

    locationFormHandler = (e) =>{
      // console.log('hit')
      e.preventDefault();
      this.setState({
        loadshow: "show-m",
        // openPickupDropLocationsModel: false,
      });
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        user_name: localStorage.getItem("username"),
        useremail: localStorage.getItem("email"),
        client: localStorage.getItem("client"),
        screen: "Pick Up/Drop Locations ",
        activity: "clicked on SAVE Button in the Modal",
        event: "Pick Up and Drop Location Details are saved",
        // "data_type" : this.state.data_type,
      };

      redirectURL.post("/master/loguserUpdatesorChanges", logParams);

      var vehicle_types = this.state.vehicle_type;
      var vehicle_type = [];
      if (vehicle_types.length > 0) {
        vehicle_types.map((itm) => {
          vehicle_type.push(itm.value);
        });
      }

      let week_Offs = this.state.week_off;
      let week_off_list = [];
      if (week_Offs.length > 0) {
        week_Offs.map((item) => week_off_list.push(item.value));
      }

      let csp_days = this.state.csp_day_list;
      let csp_day_lists = [];
      if (csp_days.length > 0) {
        csp_days.map((item) => csp_day_lists.push(item.value));
      }

      let address_name = this.state.address_name;
      let addressLat = parseFloat(this.state.addressLat);
      let addressLng = parseFloat(this.state.addressLng);
      let location_name = this.state.location_name;
      let location_city = this.state.location_city;
      let location_type = this.state.location_type;
      let location_code = this.state.location_code;
      let pickup_window_time = this.state.pickup_window_time;
      let drop_window_time = this.state.drop_window_time;
      let data_type = this.state.data_type;

      let drop_corresponding_pickup_location_code;

      if (this.state.location_type == "2") {
        drop_corresponding_pickup_location_code =
          this.state.drop_corresponding_pickup_location_code.value;
      } else {
        drop_corresponding_pickup_location_code = this.state.location_code;
      }
      let zone = this.state.zone.value;
      let standard_transit_time = this.state.standard_transit_time;
      let distance_from_plant = this.state.distance_from_plant;
      let customer_type = this.state.customer_type.value;
      let location_threshold_time = this.state.location_threshold_time;
      let dealer_type = this.state.dealer_type;

      let pickup_from_time, pickup_to_time, drop_from_time, drop_to_time;

      // if(address_name == ''){

      //         var reqparams = new FormData()
      //         reqparams.append("location_latitude", addressLat)
      //         reqparams.append("location_longitude", addressLng)

      //         redirectURL.post("http://216.48.191.229:8011/dispatch_planning/fetchLocationAddressDetails",reqparams)
      //         .then((response) => {
      //             if(response.data.status == "Success"){

      //                 address_name  = response.data.formatted_address

      //             }else{
      //                 this.setState({
      //                     show:true,
      //                     basicTitle:"Please check lat and lng",
      //                     basicType:"danger"
      //                 })
      //             }
      //             return
      //         })
      //         .catch(function (error) {
      //             var errDetails={
      //                 url:"http://216.48.191.229:8011/dispatch_planning/fetchLocationAddressDetails",
      //                 screen:'Pick Up/Drop Locations',
      //               error,
      //               }
      //             redirectURL.post("/master/logErrorsForApiCalls",errDetails)

      //         })

      // }

      if (pickup_window_time[0] == null) {
        pickup_from_time = "00:00";
        pickup_to_time = "23:59";
      } else {
        pickup_from_time = pickup_window_time[0];
        pickup_to_time = pickup_window_time[1];
      }
      if (drop_window_time[0] == null) {
        drop_from_time = "00:00";
        drop_to_time = "23:59";
      } else {
        drop_from_time = drop_window_time[0];
        drop_to_time = drop_window_time[1];
      }

      if (location_type == "") {
        this.setState({
          show: true,
          basicTitle: "Please check location type",
          basicType: "danger",
        });
        return;
      } else {
        if (pickup_window_time.length > 0) {
          var valid_time = this.validateTime(pickup_window_time);
        } else {
          pickup_window_time = ["00:00", "23:59"];
          var valid_time = this.validateTime(pickup_window_time);
        }
        if (drop_window_time.length > 0) {
          var valid_time = this.validateTime(drop_window_time);
        } else {
          drop_window_time = ["00:00", "23:59"];
          var valid_time = this.validateTime(drop_window_time);
        }
        var {kddStartMonth,kddEndMonth}=this.state
        if (this.state.kddPeriodInfinite === "yes") {
          kddEndMonth.value = 12;
          kddStartMonth.value = 1;
        }
        if (kddEndMonth.value === kddStartMonth.value) {
          this.setState({
            show: true,
            basicTitle: "KDD Start and End month should not be same",
            basicType: "danger",
          });
          return '';
        }
        if (valid_time) {
          let params = {
            address_name: address_name,
            addressLat: addressLat,
            addressLng: addressLng,
            location_name: location_name,
            location_city: location_city,
            location_type: parseInt(location_type),
            location_code: location_code,
            pickup_from_time: pickup_from_time,
            pickup_to_time: pickup_to_time,
            drop_from_time: drop_from_time,
            drop_to_time: drop_to_time,
            data_type: data_type,
            form_type: this.state.modalType,
            rowId: this.state.rowId,
            vehicle_type: vehicle_type,
            drop_corresponding_pickup_location_code:
              drop_corresponding_pickup_location_code,
            zone: zone,
            week_off: week_off_list,
            standard_transit_time: standard_transit_time,
            distance_from_plant: distance_from_plant,
            customer_type: customer_type,
            location_threshold_time: location_threshold_time,
            user: localStorage.getItem("username"),
            mail: localStorage.getItem("email"),
            dealer_type: dealer_type,
            csp_day_list: csp_day_lists,
            kdd_customer: this.state.kddCusYes,
            kdd_finite_period: this.state.kddPeriodFinite,
            kdd_period_start_month: kddStartMonth.value,
            kdd_period_end_month: kddEndMonth.value,
          };
          // console.log("Loacation ", params)
          let previousData = this.state.previousData;
          if (params.form_type !== 1) {
            logFormChanges(params, previousData);
          }
          this.setState({
            openPickupDropLocationsModel: false,
            previousData: "",
          });

          let logParams = {
            location_code: location_code,
            location_name: location_name,
            location_city: location_city,
            user_name: localStorage.getItem("username"),
            useremail: localStorage.getItem("email"),
            client: localStorage.getItem("client"),
            screen: "Pick Up/Drop Locations",
            activity: "Pick Up/Drop Location Entered",
            event: "Add Pick Up/Drop Location",
            data_type: this.state.data_type,
          };

          redirectURL
            .post("/master/logUserUpdateOrSaveDataActivities", logParams)
            .then(() => {})
            .catch(function (error) {
              var errDetails = {
                url: "/master/logUserUpdateOrSaveDataActivities",
                screen: "Pick Up/Drop Locations",
                error,
              };
              redirectURL.post("/master/logErrorsForApiCalls", errDetails);
            });

          this.saveLocationsData(params);
        } else {
          console.log("err");
        }
      }
    }
    
    updatePickupAndDropLocations = async(propdata) =>{
        let logParams = {
            // "location_code" : location.value,
            // "location_name" : location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Pick up/Drop Locations",
            "activity" : 'clicked on Edit Button in the aggrid',
            "event" : "Edit pickup/Drop Location Modal Opened",
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
                
        let data = propdata.data
        let ptime = [data.pickup_from_time,data.pickup_to_time]
        let ftime = [data.drop_from_time,data.drop_to_time]
        if(data.pickup_from_time == undefined){
            ptime = ['00:00','23:59']
        }
        if(data.drop_from_time == undefined){
            ftime = ['00:00','23:59']
        }
        var vehicletypes = []
        if(data.vehicle_list.length > 0)
        {
            data.vehicle_list.map((itm) => {
                if(itm === "none")
                {
                    var stitme = "None";
                }
                else if(itm === "all")
                {
                    var stitme = "All";
                }
                else{
                    var stitme = itm;
                }
                vehicletypes.push({
                    value:itm,
                    label:stitme
                })
            })
        }

        var week_Offs = []
        if(data.week_off != undefined && data.week_off.length > 0)
        {
            data.week_off.map((itm) => {
                if(itm === "no_week_off")
                {
                    var stitme = "No Week Off";
                }
                else if(itm === "sunday")
                {
                    var stitme = "Sunday";
                }
                else if(itm === "monday")
                {
                    var stitme = "Monday";
                }
                else if(itm === "tuesday")
                {
                    var stitme = "Tuesday";
                }
                else if(itm === "wednesday")
                {
                    var stitme = "Wednesday";
                }
                else if(itm === "thursday")
                {
                    var stitme = "Thursday";
                }
                else if(itm === "friday")
                {
                    var stitme = "Friday";
                }
                else if(itm === "saturday")
                {
                    var stitme = "Saturday";
                }
                else{
                    var stitme = itm;
                }
                week_Offs.push({
                    value:itm,
                    label:stitme
                })
            })
        }

        var csp_day_lists = []
        if(data.csp_day_list != undefined && data.csp_day_list.length > 0)
        {
            data.csp_day_list.map((itm) => {
                if(itm === "NONE")
                {
                    var stitme = "NONE";
                }
                else if(itm === "SUNDAY")
                {
                    var stitme = "SUNDAY";
                }
                else if(itm === "MONDAY")
                {
                    var stitme = "MONDAY";
                }
                else if(itm === "TUESDAY")
                {
                    var stitme = "TUESDAY";
                }
                else if(itm === "WEDNESDAY")
                {
                    var stitme = "WEDNESDAY";
                }
                else if(itm === "THURSDAY")
                {
                    var stitme = "THURSDAY";
                }
                else if(itm === "FRIDAY")
                {
                    var stitme = "FRIDAY";
                }
                else if(itm === "SATURDAY")
                {
                    var stitme = "SATURDAY";
                }
                else{
                    var stitme = itm;
                }
                csp_day_lists.push({
                    value:itm,
                    label:stitme
                })
            })
        }
        var {kdd_period_start_month,kdd_period_end_month}=data
        if (kdd_period_end_month){
          kdd_period_end_month={label:months[kdd_period_end_month],value:kdd_period_end_month}
        }else{
          kdd_period_end_month={label:'Dec',value:12}
        }
        if (kdd_period_start_month){
          kdd_period_start_month={label:months[kdd_period_start_month],value:kdd_period_start_month}
        }else{
          kdd_period_start_month={label:'Jan',value:1}
        }
        await this.setState({
          location_code: data.location_code,
          location_name: data.location_name,
          location_city: data.location_city,
          address_name: data.location_address,
          location_type: data.location_type,
          addressLat: data.latitude,
          addressLng: data.longitude,
          pickup_window_time: ptime,
          drop_window_time: ftime,
          vehicle_type: vehicletypes,
          rowId: data._id,
          drop_corresponding_pickup_location_code: {
            label: data.drop_corresponding_pickup_location_code,
            value: data.drop_corresponding_pickup_location_code,
          },
          zone: { label: data.zone, value: data.zone },
          distance_from_plant: data.distance_from_plant,
          standard_transit_time: data.standard_transit_time,
          customer_type: {
            label: data.customer_type,
            value: data.customer_type,
          },
          week_off: week_Offs,
          location_threshold_time: data.location_threshold_time,
          dealer_type: data.dealer_type,
          csp_day_list: csp_day_lists,
          kddCusYes: data.kdd_customer,
          kddCusNo: data.kdd_customer === "yes" ? "no" : "yes",
          kddPeriodFinite: data.kdd_finite_period?data.kdd_finite_period:'no',
          kddPeriodInfinite: data.kdd_finite_period === "yes" ? "no" : "yes",
          kddStartMonth: kdd_period_start_month,
          kddEndMonth: kdd_period_end_month,
        });
// console.log('first',months[data.kdd_period_end_month],'lll',months[data.kdd_period_start_month])
        var csp_prev_data =[];
        var week_off_prev_data = [];    
        var vehicle_type_prev_data = [];    
        csp_day_lists.map(item => csp_prev_data.push(item.value));
        week_Offs.map(item => week_off_prev_data.push(item.value));
        vehicletypes.map(item =>vehicle_type_prev_data.push(item.value));
        var previousData ={
            location_code:data.location_code,
            location_name:data.location_name,
            location_city: data.location_city,
            address_name:data.location_address,
            location_type:data.location_type,
            addressLat:data.latitude,
            addressLng:data.longitude,
            pickup_from_time:ptime[0],
            pickup_to_time:ptime[1],
            drop_from_time:ftime[0],
            drop_to_time:ftime[1],
            vehicle_type:vehicle_type_prev_data,
            rowId:data._id,
            drop_corresponding_pickup_location_code:data.drop_corresponding_pickup_location_code,
            zone:data.zone,
            distance_from_plant : data.distance_from_plant,
            standard_transit_time : data.standard_transit_time,
            customer_type : data.customer_type,
            week_off : week_off_prev_data,
            location_threshold_time:data.location_threshold_time,
            dealer_type:data.dealer_type,
            csp_day_list : csp_prev_data
        }
        // console.log(previousData,"previousData")
        await this.setState({
            previousData:previousData
        })
        
        this.openModelForPickupAndDropLocations()
    }

    openModelForPickupAndDropLocations = () =>{
        this.setState(prevState =>({
            openPickupDropLocationsModel :!prevState.openPickupDropLocationsModel,
            modalType : 2
        }))
        setTimeout(()=>{
            this.showMap() 
        },1000)
    }


    deleteLocationsDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        
        redirectURL.post('master/deleteLocationsDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getLocationsData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/deleteLocationsDetails",
                screen:'Pick Up/Drop Locations',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
    }

    vehicleTypeChange(vehicle_type)
    {
        // console.log(vehicle_type)
        // var arr = []
        // if(vehicle_type.length > 0)
        // {
        //     if(vehicle_type.length === 1)
        //     {
        //         vehicle_type.map((itm) => {
        //             if(itm.value === "none" || itm.value === "all")
        //             {
        //                 if(itm.value === "none")
        //                 {
        //                     arr.push({value:"none",label:"None"})
        //                 }
        //                 else{
        //                     arr.push({value:"all",label:"All"})
        //                 }
        //             }
        //             else{
                        
        //                 arr.push({value:itm.value,label:itm.label})        
        //             }
        //         })
        //     }
        //     else{
        //         vehicle_type.map((itm) => {
        //             if(itm.value !== "none" && itm.value !== "all")
        //             {
        //                 arr.push({value:itm.value,label:itm.label})         
        //             }
        //             else{
        //                 console.log(itm.value.includes("none"),'itm.value.includes("none")')
        //                 if(itm.value.includes("none"))
        //                 {
        //                     arr.push({value:"none",label:"None"})
        //                 }
        //             }
        //         })
        //     }
        // }
        // console.log("arr ",arr)
        // this.setState({vehicle_type:arr})
        this.setState({vehicle_type})
        
    }

    dropCorrespondingPickup = (drop_corresponding_pickup_location_code) =>{
        this.setState({
            drop_corresponding_pickup_location_code : drop_corresponding_pickup_location_code
        })
    }
    handleZone = (zone) =>{
        this.setState({
            zone : zone
        })
    }

    handleCustomerType = (customer_type) =>{
        this.setState({
            customer_type : customer_type
        })
    }

    transitTimeField = (e) => {
        
        let value = e.target.value
        let regex = /^\d{1,3}$/
        var out=regex.test(value)
        if(value !== ''){
        if(out && value>0){
        this.setState({ standard_transit_time : value })
        }}else{
    	this.setState({standard_transit_time:value});
        }
		// let value = event.target.value;
    }

    distanceFromPlantField = (e) => {
        let name = e.target.name
        let value = e.target.value
        let regex = /^-?\d+(\.\d+)?$/
        var out=regex.test(value)
        if(value !== ''){
        if(out && value>0){
        this.setState({ [name] : value })}   
        }else{
            this.setState({ [name] : value }) 
        }     
		// let value = event.target.value;
    	// this.setState({distance_from_plant:value});
    }

    handleThresholdTime = (event) => {
		let value = parseInt(event.target.value);
    	this.setState({location_threshold_time:value});
    }

    handleWeekOff = (week_off) =>{

        if(week_off.length>0){
        let selected = []
        if (week_off[week_off.length-1].value === 'no_week_off'){
            selected.push({label:'No Week Off',value:'no_week_off'})
        }else{
            selected = week_off.filter(item => item.value !== 'no_week_off');
        }
        this.setState({
            week_off : selected
        })
        }
    }

    handleCSVPlanDay = (week_off) =>{

        if(week_off.length>0){
        let selected = []
        if (week_off[week_off.length-1].value === 'NONE'){
            selected.push({label:'NONE',value:'NONE'})
        }else{
            selected = week_off.filter(item => item.value !== 'NONE');
        }
        this.setState({
            csp_day_list : selected
        })
        }
    }

    boundTypeLocations = async(param) => {

        let location_access = JSON.parse(localStorage.getItem("location_access"))

        let locationNames = [];

        if(location_access == null){
            locationNames.push({label:'ALL',value:''})
        }
        
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                        if(location_access != null){
                            if(location_access.includes(item.location_code)){
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })

                                }
                            }else{
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })
                            }
                      })
                      
                      if(locationNames.length>0){
                        await this.setState({
                                    locationNames : locationNames,
                                    location : locationNames[0]
                                })
                    }else{
                        await this.setState({
                            locationNames : [{label:'Please Add Data',value:'no_data'}],
                            location : [{label:'Please Add Data',value:'no_data'}]
                        })
                    }
                  }
              }
              this.setState({loadshow:'show-n'})
          })
          .catch(function (error) {
            var errDetails={
                url:"master/getBoundTypeLocations",
                screen:'Pickup/Drop Locations Screen',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
          
      }

    locationChanged =async (location) =>{
        this.setState({
            location : location,
            zone:""
        })
        await getToolConfiguration({plant_code:location.value})
    }    

    handleDealerLocation = async (e) =>{
        let name = e.target.name
        let value = e.target.value
 
            this.setState({ [name] : value })
        
    }

    render(){
        var zoneNames = this.state.zoneNames
        var zonesData = this.state.zonesData
        // console.log(zoneNames,"zonenames")
        var selectedLocation = this.state.location.value
        // console.log(selectedLocation,"selected Location")
        var filteredZones = []
        if (selectedLocation == "") {
            filteredZones = zoneNames
        }
        else {
            zonesData.map(item =>{
                if (selectedLocation == item.location_code) {
                    console.log(item.location_code, item.zone)
                filteredZones.push({ value : item.zone,
                    label : item.zone })
            }
                })
        }

        var colsDefs = [
            {
                headerName:"Actions",
                field:"cluster",
                width:"100",
                pinned:'left',
                filter:false,
                sortable:false,
                params:{buttonName:'Edit',
                        onClickFunction:this.updatePickupAndDropLocations},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            // {
            //     headerName:"",
            //     field:"cluster",
            //     width:"80",
            //     pinned:'left',
            //     params:{buttonName:'Delete',iconName:"fa fa-trash",
            //             onClickFunction:this.deleteLocationsDetails},
            //     cellRendererSelector:function(params){
                    
            //         var rendComponent = {
            //             component: "GridButton"
            //         };
            //         return rendComponent
                
            //     },
            // },
            {
                headerName:"Pickup Location Code",
                field:"drop_corresponding_pickup_location_code",
                width:"120"
            },
            {
                headerName:"Location Type",
                field:"location_type",
                width:"120",
                valueGetter:function(params){
                    if( params.data.location_type == 1){
                        return Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_PICKUP_DISPALY_NAME
                    }else if( params.data.location_type == 2){
                        return Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_DROP_DISPALY_NAME
                    }else{
                        return Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_BOTH_DISPALY_NAME
                    }
                }
            },
            {
                headerName:"Location Code",
                field:"location_code",
                width:"120"
            },
            {
                headerName:"Location Name",
                field:"location_name",
                width:"130"
            },
            {
                headerName:"City",
                field:"location_city",
                width:"130"
            },
            {
                headerName:"State",
                field:"location_state",
                width:"120"
            },
            {
                headerName:"Customer Type",
                field:"customer_type",
                width:"100"
            },
            {
                headerName:"Dealer Type",
                field:"dealer_type",
                width:"100"
            },
            {
                headerName:"Standard Transit Time (Days)",
                field:"standard_transit_time",
                width:"120"
            },
            {
                headerName:"Distance From Plant (KMs)",
                field:"distance_from_plant",
                width:"120"
            },
            {
                headerName:"Week Off",
                field:"week_off",
                width:"120"
            },
            {
                headerName:"CSP Day",
                field:"csp_day_list",
                width:"120"
            },
            {
                headerName:"Vehicle Restriction List",
                field:"vehicle_list",
                width:"140"
            },
            {
                headerName:"Zone",
                field:"zone",
                width:"100"
            },
            {
                headerName:"Address",
                field:"location_address",
                width:"200"
            },
            {
                headerName:"Pickup From Time",
                field:"pickup_from_time",
                width:"140"
            },
            {
                headerName:"Pickup To Time",
                field:"pickup_to_time",
                width:"130"
            },
            {
                headerName:"Drop From Time",
                field:"drop_from_time",
                width:"130"
            },
            {
                headerName:"Drop To Time",
                field:"drop_to_time",
                width:"130"
            },
            {
                headerName:"Location Threshold Time",
                field:"location_threshold_time",
                width:"130"
            },
            {
                headerName:"KDD Customer",
                field:"kdd_customer",
                width:"130",
                valueGetter:(params)=>{
                  if (params.data.kdd_customer!==undefined){
                    return params.data.kdd_customer
                  }else{
                    return 'N/A'
                  }
                }
            },
            {
                headerName:"KDD Finite period",
                field:"kdd_finite_period",
                width:"130",
                valueGetter:(params)=>{
                  if (params.data.kdd_finite_period!==undefined){
                    return params.data.kdd_finite_period
                  }else{
                    return 'N/A'
                  }
                }
            },
            {
                headerName:"KDD start month",
                field:"kdd_period_start_month",
                width:"130",
                valueGetter:(params)=>{
                  if (params.data.kdd_period_start_month!==undefined){
                    return months[params.data.kdd_period_start_month]
                  }else{
                    return 'N/A'
                  }
                }
            },
            {
                headerName:"KDD End Month",
                field:"kdd_period_end_month",
                width:"130",
                valueGetter:(params)=>{
                  if (params.data.kdd_period_end_month!==undefined){
                    return months[params.data.kdd_period_end_month]
                  }else{
                    return 'N/A'
                  }
                }
            },
            {
                headerName:"Latitude",
                field:"latitude",
                width:"130",
                hide:true
            },
            {
                headerName:"Longitude",
                field:"longitude",
                width:"130",
                hide:true
            },

        ]

        if (localStorage.getItem("email") === 'deletedataaccess@enmovil.in'){
            colsDefs.push(
                {
                    headerName:"",
                    field:"cluster",
                    width:"80",
                    pinned:'left',
                    params:{buttonName:'Delete',iconName:"fa fa-trash",
                            onClickFunction:this.deleteLocationsDetails},
                    cellRendererSelector:function(params){
                        
                        var rendComponent = {
                            component: "GridButton"
                        };
                        return rendComponent
                    
                    },
                },
            )
        }

        var pickupfieldToChk = 0;
        var dropfieldToChk = 0;
        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                var location_type = this.state.location_type;
                if(location_type == 1)
                {
                    pickupfieldToChk = toolConfig[0].pickup_time_windows;
                }
                else if(location_type == 2)
                {
                    dropfieldToChk = toolConfig[0].drop_time_windows;
                }
                else if(location_type == 3)
                {
                    pickupfieldToChk = toolConfig[0].pickup_time_windows;
                    dropfieldToChk = toolConfig[0].drop_time_windows;
                }
            }
        }

        let data = this.state.rowData;
        let locValue = this.state.location.value;   
        let filteredData;
        let filteredVehicleList;
        let vehicleListData = this.state.vehiclelist;
        if(locValue == ''){
            filteredData = data;
            filteredVehicleList = vehicleListData;
        }else{
            filteredData = data.filter(item => item.drop_corresponding_pickup_location_code == locValue);
            filteredVehicleList = vehicleListData.filter(itm => itm.locCode == locValue);
        }
        
        var templatePath = require("../../assets/json/Locations_input_data_template_new.csv");
        var userMailCheck =
          localStorage.getItem("user_type") === "admin" ? "show-m" : "show-n";
          var kddMonths = [
            { label: 'Jan', value: 1 },
            { label: 'Feb', value: 2 },
            { label: 'Mar', value: 3 },
            { label: 'Apr', value: 4 },
            { label: 'May', value: 5 },
            { label: 'Jun', value: 6 },
            { label: 'Jul', value: 7 },
            { label: 'Aug', value: 8 },
            { label: 'Sep', value: 9 },
            { label: 'Oct', value: 10 },
            { label: 'Nov', value: 11 },
            { label: 'Dec', value: 12 }
          ];
        return (
          <>
            <SweetAlert
              show={this.state.show}
              type={this.state.basicType}
              title={this.state.basicTitle}
              onConfirm={this.closeAlert}
            ></SweetAlert>

            <div className="row mb-5p ">
              <div className="col-sm-12 pt-5p">
                <h5 className="fbold ">
                  <span>
                    {Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_HEADER_NAME}
                  </span>
                  <div
                    className="float-right col-sm-3"
                    style={{ marginTop: "30px" }}
                  >
                    <a
                      href="javascript:;"
                      onClick={this.openModelForPickupDrop}
                      className="f12 float-right btn btn-default themefont fbold mr-10p mb-10p"
                    >
                      {Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_BUTTON_NAME}
                    </a>
                    <span className={"float-right " + userMailCheck}>
                      <a
                        href="javascript:;"
                        onClick={this.openUploadOptionForPickupDrop}
                        className="btn btn-warning f12 mr-10p"
                      >
                        {
                          Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_BULK_BUTTON_NAME
                        }
                      </a>
                    </span>
                  </div>
                </h5>
                <div className="col-md-2 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION
                      }
                    </label>
                    <Select
                      placeholder={"Select"}
                      onChange={this.locationChanged}
                      name="location"
                      value={this.state.location}
                      options={this.state.locationNames}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-20p">
              <div className="col-sm-12">
                <div className="col-sm-12 d-inline">
                  <ViewMap
                    locationsData={filteredData}
                    data_type={this.state.data_type}
                  />
                </div>
                <div className="col-sm-12 d-inline">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "70vh", marginTop: "2em" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      // modules={this.state.modules}
                      rowData={filteredData}
                      columnDefs={colsDefs}
                      gridOptions={{ context: { componentParent: this } }}
                      defaultColDef={this.state.defaultColDef}
                      frameworkComponents={this.state.frameworkComponents}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      enableRangeSelection={true}
                      enableCellContextMenu={true}
                      statusBar={this.state.statusBar}
                      sideBar={this.state.sideBar}
                      // enablePivot = {true}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={"overlay-block " + this.state.overlayBlock}
              onClick={this.hideSlideBlock}
            ></div>
            <div
              className={"sliderBlock2 " + this.state.showSlideBlockPickupDrop}
            >
              {/* <h5 className="crd-bg p-10p">{Constant.DEALER_FORM_UPLOAD_PARTS}</h5> */}
              <div className="row">
                <div className="col-sm-12">
                  <form id="bulkUploadForm" onSubmit={this.formCSVUpload}>
                    <div className="row p-20p">
                      <div className="form-group col-sm-12">
                        <a
                          className="btn btn-warning"
                          href={templatePath}
                          // target="_blank"
                        >
                          Download Sample Template
                        </a>
                      </div>
                      <div className="form-group col-sm-12 mb-20p">
                        {/* <label style={{color:"#000"}}>{Constant.DEALER_FORM_UPLOAD_FILE_XLS} <span className="rclr fbold">*</span></label> */}

                        <input
                          type="file"
                          name="csvfile"
                          id="csvfile"
                          className="form-control"
                          onChange={this.changeCSVFileHandler}
                          onClick={this.onClickUploadFile}
                          required
                        />
                      </div>
                      <div className="form-group col-sm-12 mb-20p">
                        <button
                          type="submit"
                          id="bulkUploadBtn"
                          className="btn btn-danger"
                        >
                          SAVE
                        </button>
                        <button
                          type="button"
                          className="btn btn-default"
                          onClick={this.hideSlideBlock}
                        >
                          CANCEL
                        </button>
                      </div>
                      <div id="inValidDataInfo" style={{ color: "red" }}></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <Modal
              closeOnOverlayClick={false}
              open={this.state.openPickupDropLocationsModel}
              onClose={this.handelPickupDropModelCancel}
              classNames={{ modal: "pickupModal" }}
            >
              <div className="container-fluid">
                {this.state.showloader === "show-m" ? (
                  <div className="row col-xl-12 display-center">
                    <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={["#3d87f5", "#ffffff"]}
                    />
                  </div>
                ) : (
                  <div className="mappg mprel">
                    <div className="row">
                      <div className={"col-xl-8 col-lg-8 " + this.state.mapp}>
                        <div
                          id="map_canvas"
                          className="col-xl-12 col-lg-12 ml-0p"
                          style={{
                            width: "100%",
                            height: "90vh",
                            borderRadius: "2px",
                          }}
                        ></div>
                      </div>
                      <form
                        className="col-xl-4 col-lg-4"
                        onSubmit={this.locationFormHandler}
                      >
                        <input
                          type="text"
                          name="search"
                          onChange={this.handleSearchLocation}
                          value={this.state.address_name}
                          className="col-xl-6 col-lg-6 mt-20p ml-20p form-control"
                          id="search"
                          placeholder="Enter Address to search"
                        />

                        <div
                          className="form-group"
                          style={{ paddingLeft: "15px", marginBottom: "3px" }}
                        >
                          <label className="c-lbl fbold">
                            Location Type <span className="redColor">*</span>
                          </label>
                          <div
                            className="row col-sm-12 "
                            style={{ marginLeft: "-10px", paddingRight: "0px" }}
                          >
                            <div className="col col-sm-6">
                              {this.state.location_type == 1 ? (
                                <input
                                  id="radio44"
                                  checked
                                  onChange={this.locationTypeHandler}
                                  name="location_type"
                                  type="radio"
                                  className="form-check-input"
                                  value="1"
                                />
                              ) : (
                                <input
                                  id="radio44"
                                  onChange={this.locationTypeHandler}
                                  name="location_type"
                                  type="radio"
                                  className="form-check-input"
                                  value="1"
                                />
                              )}
                              <label htmlFor="radio44" className="">
                                {
                                  Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_PICKUP_DISPALY_NAME
                                }
                              </label>
                            </div>

                            <div className="col col-sm-6">
                              {this.state.location_type == 2 ? (
                                <input
                                  id="radio55"
                                  checked
                                  onChange={this.locationTypeHandler}
                                  name="location_type"
                                  type="radio"
                                  className="form-check-input"
                                  value="2"
                                />
                              ) : (
                                <input
                                  id="radio55"
                                  onChange={this.locationTypeHandler}
                                  name="location_type"
                                  type="radio"
                                  className="form-check-input"
                                  value="2"
                                />
                              )}
                              <label htmlFor="radio55" className="">
                                {
                                  Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_DROP_DISPALY_NAME
                                }
                              </label>
                            </div>

                            <div className="col col-sm-6">
                              {this.state.location_type == 3 ? (
                                <input
                                  id="radio66"
                                  checked
                                  onChange={this.locationTypeHandler}
                                  name="location_type"
                                  type="radio"
                                  className="form-check-input"
                                  value="3"
                                />
                              ) : (
                                <input
                                  id="radio66"
                                  onChange={this.locationTypeHandler}
                                  name="location_type"
                                  type="radio"
                                  className="form-check-input"
                                  value="3"
                                />
                              )}
                              <label htmlFor="radio66" className="">
                                {
                                  Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_BOTH_DISPALY_NAME
                                }
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-12 col-lg-12">
                          <div className="form-group">
                            <label className="c-lbl fbold">
                              Location Code <span className="redColor">*</span>
                            </label>
                            <input
                              type="text"
                              disabled={this.state.rowId ? true : false}
                              name="location_code"
                              id="location_code"
                              value={this.state.location_code}
                              onChange={this.locationCodeField}
                              className="form-control forminp"
                              placeholder="Code"
                              maxLength="100"
                              required
                            />
                            <label className="c-lbl fbold">
                              Location Name <span className="redColor">*</span>
                            </label>
                            <input
                              type="text"
                              name="location_name"
                              value={this.state.location_name}
                              onChange={this.locationNameField}
                              className="form-control forminp"
                              placeholder="Name"
                              maxLength="100"
                              required
                            />
                            <label className="c-lbl fbold">
                              Location City <span className="redColor">*</span>
                            </label>
                            <input
                              type="text"
                              name="location_city"
                              value={this.state.location_city}
                              onChange={this.locationCityField}
                              className="form-control forminp"
                              placeholder="Name"
                              maxLength="100"
                              required
                            />

                            <label className="c-lbl fbold">Zone</label>
                            <Select
                              placeholder={"Select"}
                              onChange={this.handleZone}
                              name="zone"
                              value={this.state.zone}
                              options={filteredZones}
                            />
                            {/* {this.state.drop_corresponding_pickup_location_code != undefined && this.state.drop_corresponding_pickup_location_code.value != '' ?
                                        <>
                                        <label className="c-lbl fbold">Pickup Location code <span className="redColor">*</span></label>
                                        <Select
                                            placeholder={"Select"}
                                            onChange={this.dropCorrespondingPickup}
                                            name="drop_corresponding_pickup_location_code"
                                            value={this.state.drop_corresponding_pickup_location_code}
                                            options={this.state.correspondingPickupLocationList} 
                                            isDisabled = {this.state.location_code == this.state.drop_corresponding_pickup_location_code.value?true:false}
                                            />
                                        </>
                                        :''} */}
                            {this.state
                              .drop_corresponding_pickup_location_code ===
                              undefined ||
                            this.state.location_type == "2" ||
                            this.state.location_type === "" ? (
                              <>
                                <label className="c-lbl fbold">
                                  Pickup Location code{" "}
                                  <span className="redColor">*</span>
                                </label>
                                <Select
                                  placeholder={"Select"}
                                  onChange={this.dropCorrespondingPickup}
                                  name="drop_corresponding_pickup_location_code"
                                  value={
                                    this.state
                                      .drop_corresponding_pickup_location_code
                                  }
                                  options={
                                    this.state.correspondingPickupLocationList
                                  }
                                />
                              </>
                            ) : (
                              ""
                            )}
                            {(this.state.location_type == "1" ||
                              this.state.location_type == "3") && (
                              <>
                                <label className="c-lbl fbold">
                                  Pickup Location code{" "}
                                  <span className="redColor">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control forminp disable-field"
                                  //    onChange={this.onChangePickupLocationCode}
                                  name="drop_corresponding_pickup_location_code"
                                  value={this.state.location_code}
                                  isDisabled={true}
                                />
                              </>
                            )}
                            <label className="c-lbl fbold">
                              Customer Type <span className="redColor">*</span>
                            </label>
                            <Select
                              placeholder={"Select"}
                              onChange={this.handleCustomerType}
                              name="customer_type"
                              value={this.state.customer_type}
                              options={this.state.customerTypeList}
                            />
                            {/* <label className="c-lbl fbold">Address <span className="redColor">*</span></label>
                                            <input type="text" name="address_name" value={this.state.address_name} 
                                            onChange = {this.addressInputField}   className="form-control forminp" placeholder="Address" maxLength="100" required /> */}
                            {/* <p className={this.state.duplicateChk} style={{color:"red",fontWeight:"bold"}}>Already Exists</p> */}
                            {/* <div className="errfnt" style={{color: "rgb(153, 153, 153)"}}><i className="icofont icofont-warning-alt"></i> Special Characters @&-_. are allowed</div> */}
                          </div>
                          <div className="form-group">
                            <label className="c-lbl fbold">
                              Location Latitude{" "}
                              <span className="redColor">*</span>
                            </label>
                            <input
                              type="number"
                              name="addressLat"
                              value={this.state.addressLat}
                              disabled={this.state.rowId ? true : false}
                              onChange={this.handelLatInput}
                              onBlur={this.handleLatBlur}
                              className="form-control forminp"
                              placeholder="Latitude"
                              maxLength="100"
                              required
                            />
                            {this.state.latError && (
                              <span style={{ color: "red" }}>
                                {this.state.latError}
                              </span>
                            )}

                            <label className="c-lbl fbold">
                              Location Longitude{" "}
                              <span className="redColor">*</span>
                            </label>
                            <input
                              type="number"
                              name="addressLng"
                              value={this.state.addressLng}
                              disabled={this.state.rowId ? true : false}
                              onChange={this.handelLngInput}
                              onBlur={this.handleLngBlur}
                              className="form-control forminp"
                              placeholder="Longitude"
                              maxLength="100"
                              required
                            />
                            {this.state.longError && (
                              <span style={{ color: "red" }}>
                                {this.state.longError}
                              </span>
                            )}

                            <label className="c-lbl fbold">
                              Standard Transit Time (Days)
                              <span className="redColor">*</span>
                            </label>
                            <input
                              type="text"
                              name="standard_transit_time"
                              value={this.state.standard_transit_time}
                              onChange={this.transitTimeField}
                              className="form-control forminp"
                              placeholder="Name"
                              maxLength="100"
                              required
                            />

                            <label className="c-lbl fbold">
                              Distance From Plant (KMs)
                              <span className="redColor">*</span>
                            </label>
                            <input
                              type="text"
                              name="distance_from_plant"
                              value={this.state.distance_from_plant}
                              onChange={this.distanceFromPlantField}
                              className="form-control forminp"
                              placeholder="Name"
                              maxLength="100"
                              required
                            />

                            <label className="c-lbl fbold">
                              Location Threshold Time (Minutes){" "}
                              <span className="redColor">*</span>
                            </label>
                            <input
                              type="number"
                              name="location_threshold_time"
                              value={this.state.location_threshold_time}
                              onChange={this.handleThresholdTime}
                              className="form-control forminp"
                              placeholder="Time"
                              min="0"
                              max="60"
                              step="5"
                              required
                            />
                          </div>

                          <div className="form-group ">
                            <label
                              htmlFor="dealer_type"
                              className="col-form-label fbold"
                            >
                              Dealer Type
                            </label>

                            <select
                              name="dealer_type"
                              id="dealer_type"
                              value={this.state.dealer_type}
                              required
                              className="forminp form-control"
                              onChange={this.handleDealerLocation}
                            >
                              <option value="" disabled selected>
                                Select
                              </option>
                              <option value="Local">Local</option>
                              <option value="UpCountry">UpCountry</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <div className="row">
                              <div className="col-xl-12 col-lg-12">
                                <label className="c-lbl fbold">
                                  {Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_PICKUP_DISPALY_NAME +
                                    " Operational Hours"}{" "}
                                  <span className="redColor">*</span>
                                </label>
                                {pickupfieldToChk == 1 ? (
                                  <TimeRangePicker
                                    className={
                                      "text-color forminp form-control vehicle-time pad-left timer-width "
                                    }
                                    onChange={this.handlePickupWindowTime}
                                    value={this.state.pickup_window_time}
                                    rangeDivider={"to"}
                                    required={true}
                                    clearIcon={null}
                                    clockIcon={null}
                                    openClockOnFocus={false}
                                  />
                                ) : (
                                  <TimeRangePicker
                                    className={
                                      "text-color forminp form-control vehicle-time disable-field-time timer-width"
                                    }
                                    rangeDivider={"to"}
                                    value={this.state.pickup_window_time}
                                    disabled={true}
                                    clearIcon={null}
                                    clockIcon={null}
                                    openClockOnFocus={false}
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="row">
                              <div className="col-xl-12 col-lg-12">
                                <label className="c-lbl fbold">
                                  {Constant.MASTER_PICKUP_DROP_LOCATIONS_SCREEN_DROP_DISPALY_NAME +
                                    " Operational Hours"}{" "}
                                  <span className="redColor">*</span>
                                </label>
                                {dropfieldToChk == 1 ? (
                                  <TimeRangePicker
                                    className={
                                      "text-color forminp form-control vehicle-time pad-left timer-width"
                                    }
                                    onChange={this.handleDropWindowTime}
                                    value={this.state.drop_window_time}
                                    rangeDivider={"to"}
                                    required={true}
                                    clearIcon={null}
                                    clockIcon={null}
                                    openClockOnFocus={false}
                                  />
                                ) : (
                                  <TimeRangePicker
                                    className={
                                      "text-color forminp form-control vehicle-time disable-field-time timer-width"
                                    }
                                    rangeDivider={"to"}
                                    value={this.state.drop_window_time}
                                    disabled={true}
                                    clearIcon={null}
                                    clockIcon={null}
                                    openClockOnFocus={false}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group ">
                            <div className="row ">
                              <div className="col-xl-12 col-lg-12">
                                <label className="col-form-label f12">
                                  Vehicle Restriction List
                                </label>
                                <Select
                                  placeholder={"Select"}
                                  onChange={this.vehicleTypeChange.bind(this)}
                                  name="location"
                                  isMulti={true}
                                  value={this.state.vehicle_type}
                                  options={filteredVehicleList}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group ">
                            <div className="row ">
                              <div className="col-xl-12 col-lg-12">
                                <label className="col-form-label f12">
                                  Week Off
                                </label>
                                <Select
                                  placeholder={"Select"}
                                  onChange={this.handleWeekOff}
                                  name="week_off"
                                  isMulti={true}
                                  value={this.state.week_off}
                                  options={this.state.weekdays}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group ">
                            <div className="row ">
                              <div className="col-xl-12 col-lg-12">
                                <label className="col-form-label f12">
                                  CSP Day
                                </label>
                                <Select
                                  placeholder={"Select"}
                                  onChange={this.handleCSVPlanDay}
                                  name="csp_day_list"
                                  isMulti={true}
                                  value={this.state.csp_day_list}
                                  options={this.state.csv_days}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className="form-group"
                            style={{ paddingLeft: "0px", marginBottom: "3px" }}
                          >
                            <label className="col-form-label f12">
                              Is Customer KDD?
                            </label>
                            <div
                              className="row col-sm-12 "
                              style={{
                                marginLeft: "0px",
                                paddingRight: "0px",
                              }}
                            >
                              <div className="col col-sm-4">
                                <input
                                  id="kdd_customer_yes"
                                  checked={this.state.kddCusYes === "yes"}
                                  onChange={this.kddTypeYesHandler}
                                  name="kdd_customer"
                                  type="radio"
                                  className="form-check-input"
                                />
                                <label htmlFor="kdd_customer_yes" className="">
                                  Yes
                                </label>
                              </div>
                              <div className="col col-sm-4">
                                <input
                                  id="kdd_customer_no"
                                  checked={this.state.kddCusNo === "yes"}
                                  onChange={this.kddTypeNoHandler}
                                  name="kdd_customer"
                                  type="radio"
                                  className="form-check-input"
                                />
                                <label htmlFor="kdd_customer_no" className="">
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          {this.state.kddCusYes === "yes" ? (
                            <div
                              className="form-group"
                              style={{
                                paddingLeft: "0px",
                                marginBottom: "3px",
                              }}
                            >
                              <label className="col-form-label f12">
                                KDD Period
                              </label>
                              <div
                                className="row col-sm-12 "
                                style={{
                                  marginLeft: "0px",
                                  paddingRight: "0px",
                                }}
                              >
                                <div className="col col-sm-4">
                                  <input
                                    id="kkdPeriodFinite"
                                    checked={
                                      this.state.kddPeriodFinite === "yes"
                                    }
                                    onChange={this.kddPeriodFiniteHandler}
                                    name="kddPeriod"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  <label htmlFor="kkdPeriodFinite" className="">
                                    Finite
                                  </label>
                                </div>
                                <div className="col col-sm-4">
                                  <input
                                    id="kkdPeriodInfinite"
                                    checked={
                                      this.state.kddPeriodInfinite === "yes"
                                    }
                                    onChange={this.kddPeriodInfiniteHandler}
                                    name="kddPeriod"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  <label
                                    htmlFor="kkdPeriodInfinite"
                                    className=""
                                  >
                                    Infinite
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.kddPeriodFinite === "yes" ? (
                            <div
                              className="form-group"
                              style={{
                                paddingLeft: "0px",
                                marginBottom: "3px",
                              }}
                            >
                              <div
                                className="row col-sm-12 "
                                style={{
                                  marginLeft: "0px",
                                  paddingRight: "0px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <div className="col col-sm-6">
                                  <label htmlFor="startMonth" className="">
                                    Start Month
                                  </label>
                                  <Select
                                    id="startMonth"
                                    placeholder={"Select"}
                                    onChange={this.kddStartMonthHandler}
                                    name="kddStartMonth"
                                    value={this.state.kddStartMonth}
                                    options={kddMonths}
                                  />
                                </div>
                                <div className="col col-sm-6">
                                  <label htmlFor="endMonth" className="">
                                    End Month
                                  </label>
                                  <Select
                                    id="endMonth"
                                    placeholder={"Select"}
                                    onChange={this.kddStartMonthHandler}
                                    name="kddEndMonth"
                                    value={this.state.kddEndMonth}
                                    options={kddMonths}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn tbtn float-right save-btn"
                            id="saveGeo"
                          >
                            SAVE
                          </button>
                          <button
                            type="button"
                            className="btn tbtn float-right cancel-btn"
                            onClick={this.handelPickupDropModelCancel}
                          >
                            CANCEL
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </Modal>
            <div className={"loader " + this.state.loadshow}></div>
          </>
        );
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function isValidLatitude(latValue) {
    var regex=/^^[-+]?(90(\.0+)?|[1-8]?\d(\.\d+)?)$/
    var result =regex.test(latValue)
    return result    
}

function isValidLongitude(lngValue) {
    var regex=/^(-?)(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
    var result =regex.test(lngValue)
    return result
}

function validateYesNoforKDD(kddYes) {
  if (
    kddYes.toLowerCase() === "yes" ||
    kddYes.toLowerCase() === "no"
  ) {
    return true;
  } else {
    return false;
  }
}

function validateMonthforKDD(kddDate) {
  if (kddDate>0 && kddDate<13
  ) {
    return true;
  } else {
    return false;
  }
}

function validateCusType(code, plantCodes) {
  code=code.toUpperCase()
  // console.log('first',code)
  return (['OT','GT'].includes(code))
}
 