import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ConsignmentTrucksCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		active_consignments:0,
    		overspeeding:0,
            night_driving:0,
            transit_delay:0,
            unloading_dealer:0,
            left_dealer:0,
            gps_data_available:0,
            nogpslifetime:0,
            deptcode:this.props.deptcode,
            active_trucks:0,
            loading_delay:0,
            unloading_delay:0,
            enroute_stoppage:0,
            sim_issue:0,
            distance_deviation:0,
            deviation_in_customer_serving:0,
            sequence_not_followed:0,
            inside_plant:0,
            in_transit_to_customer:0,
            reached_customer:0
    	}
    
    }
    componentWillReceiveProps(newprops){
        // if(newprops.deptcode != this.state.deptcode)
        // {
        
            var transitdelaysarr=[];
            var gpsdatanaarr=[];
            var overspeedtrucksarr=[];
            var nogpslifetimearr = [];
            var nightdrivearr=[];
            var enroutearr=[];
            var loadingarr=[];
            var unloadingarr=[];
            var simissuearr=[];
            var distance_deviation=[];
            var deviation_in_customer_serving=[];
            var sequence_not_followed=[];
            var inside_plant=[];
            var in_transit_to_customer=[];
            var reached_customer = [];

            // console.log("newprops.gridData",newprops.gridData)
            try{
                if(newprops.gridData.length > 0)
                {
                    transitdelaysarr = newprops.gridData.filter(rec => {
                        if (rec["transit_delay"]) {
                            return rec["transit_delay"] == 1;
                        }
                    });
                    gpsdatanaarr = newprops.gridData.filter(rec => {
                        if (rec["is_gps_working"]) {
                            return rec["is_gps_working"] == "NOT_WORKING";
                        }
                    });
    
                    nogpslifetimearr = newprops.gridData.filter(rec => {
                        if (rec["no_gps_data_lifetime"]) {
                            return rec["no_gps_data_lifetime"] == 1;
                        }
                    });               
                   
                    overspeedtrucksarr = newprops.gridData.filter(rec => {
                        if (rec["overspeeding_exception"]) {
                            return rec["overspeeding_exception"] == 1;
                        }
                    });
                    nightdrivearr = newprops.gridData.filter(rec => {
                        if (rec["nightdriving_exception"]) {
                            return rec["nightdriving_exception"] == 1;
                        }
                    });
                    unloadingarr = newprops.gridData.filter(rec => {
                        if (rec["unloading_delay_exception"]) {
                            return rec["unloading_delay_exception"] == 1;
                        }
                    });
                    loadingarr = newprops.gridData.filter(rec => {
                        if (rec["loading_delay_exception"]) {
                            return rec["loading_delay_exception"] == 1;
                        }
                    });
                    enroutearr = newprops.gridData.filter(rec => {
                        if (rec["enroute_exception"]) {
                            return rec["enroute_exception"] == 1;
                        }
                    });
                    simissuearr = newprops.gridData.filter(rec => {
                        if (rec["sim_issue"]) {
                            return rec["sim_issue"] == 1;
                        }
                    });
                    distance_deviation = newprops.gridData.filter(rec => {
                        if (rec["distance_deviation"]) {
                            return rec["distance_deviation"] == 1;
                        }
                    });
                    deviation_in_customer_serving = newprops.gridData.filter(rec => {
                        if (rec["deviation_in_customer_serving"]) {
                            return rec["deviation_in_customer_serving"] == 1;
                        }
                    });
                    sequence_not_followed = newprops.gridData.filter(rec => {
                        if (rec["sequence_not_followed"]) {
                            return rec["sequence_not_followed"] == 1;
                        }
                    });
                    inside_plant = newprops.gridData.filter(rec => {
                        if (rec["status"]) {
                            return rec["status"] == 2 || rec["status"] == 3;
                        }
                    });
                    in_transit_to_customer = newprops.gridData.filter(rec => {
                        if (rec["status"]) {
                            return rec["status"] == 8;
                        }
                    });
                    reached_customer = newprops.gridData.filter(rec => {
                        if (rec["status"]) {
                            return rec["status"] == 11;
                        }
                    });
                   

                    this.setState({
                        active_trucks:newprops.gridData.length,
                        overspeeding:overspeedtrucksarr.length,
                        night_driving:nightdrivearr.length,
                        transit_delay:transitdelaysarr.length,
                        //unloading_dealer:counterdata.unloading_dealer,
                        //left_dealer:counterdata.left_dealer,
                        gps_data_available:gpsdatanaarr.length,
                        nogpslifetime:nogpslifetimearr.length,
                        //active_trucks:counterdata.active_trucks,
                        loading_delay:loadingarr.length,
                        unloading_delay:unloadingarr.length,
                        enroute_stoppage:enroutearr.length,
                        sim_issue:simissuearr.length,
                        distance_deviation:distance_deviation.length,
                        deviation_in_customer_serving:deviation_in_customer_serving.length,
                        sequence_not_followed:sequence_not_followed.length,
                        inside_plant:inside_plant.length,
                        in_transit_to_customer:in_transit_to_customer.length,
                        reached_customer:reached_customer.length
                    });
    
                }
                else
                {
                    this.setState({
                        active_trucks:0,
                        overspeeding:0,
                        night_driving:0,
                        transit_delay:0,
                        gps_data_available:0,
                        nogpslifetime:0,
                        loading_delay:0,
                        unloading_delay:0,
                        enroute_stoppage:0,
                        sim_issue:0,
                        distance_deviation:0,
                        deviation_in_customer_serving:0,
                        sequence_not_followed:0,
                        inside_plant:0,
                        in_transit_to_customer:0,
                        reached_customer:0
                    });
                }
    
            }
            catch(e){

            }
            

       // }
        

    }
    onClickCounter(data){
        console.log(data,"active page tab")
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row mt-20p">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"all")}>
                                    <span className="f13"><i className="fa fa-truck f24 darkgreen"></i><br />
                                    {   this.props.screen_type === "Active" ? "Active Trucks" :
                                        this.props.screen_type === "Delivered" ? "Delivered Trucks" :
                                        "All Trucks"
                                    }
                                    </span>
                                    <h4 className="darkgreen f50"><span className="counter"><CountUp end={(this.state.active_trucks != '')?this.state.active_trucks:0}/></span></h4>
                                    
                                </div>

                                {this.props.screen_type != 'Active' ?
                                <>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"distance_deviation")}>
                                    <span className="f13"><i className="fa fa-truck f24 darkgreen"></i><br /> Distance Deviation</span>
                                    <h4 className="darkgreen f50"><span className="counter"><CountUp end={(this.state.distance_deviation != '')?this.state.distance_deviation:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"deviation_in_customer_serving")}>
                                    <span className="f13"><i className="fa fa-truck f24 darkgreen"></i><br /> Deviation In Customer Serving</span>
                                    <h4 className="darkgreen f50"><span className="counter"><CountUp end={(this.state.deviation_in_customer_serving != '')?this.state.deviation_in_customer_serving:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"sequence_not_followed")}>
                                    <span className="f13"><i className="fa fa-truck f24 darkgreen"></i><br /> Sequence Not Followed</span>
                                    <h4 className="darkgreen f50"><span className="counter"><CountUp end={(this.state.sequence_not_followed != '')?this.state.sequence_not_followed:0}/></span></h4>
                                    
                                </div>
                                </>
                                :''}

                                {this.props.screen_type == 'Active' ?
                                <>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"inside_plant")}>
                                    <span className="f13"><i className="fa fa-truck f24 darkgreen"></i><br /> Inside Plant</span>
                                    <h4 className="darkgreen f50"><span className="counter"><CountUp end={(this.state.inside_plant != '')?this.state.inside_plant:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"in_transit_to_customer")}>
                                    <span className="f13"><i className="fa fa-truck f24 darkgreen"></i><br /> In Transit to Customer</span>
                                    <h4 className="darkgreen f50"><span className="counter"><CountUp end={(this.state.in_transit_to_customer != '')?this.state.in_transit_to_customer:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"reached_customer")}>
                                    <span className="f13"><i className="fa fa-truck f24 darkgreen"></i><br /> Reached Customer</span>
                                    <h4 className="darkgreen f50"><span className="counter"><CountUp end={(this.state.reached_customer != '')?this.state.reached_customer:0}/></span></h4>
                                    
                                </div>
                              </>
                              :''}
                                {/* {(localStorage.getItem("user_type") == "TECHUSER" || localStorage.getItem("plant_code") == "501-FG")  ?
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"sim_issue")}>
                                    <span className="f13"><i className="fa fa-mobile f22 maroon"></i><br /> SIM Issues</span>
                                    <h4 className="maroon f50"><span className="counter"><CountUp end={(this.state.sim_issue != '')?this.state.sim_issue:0}/></span></h4>                                   
                                </div>
                                :""} */}
                                {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"transit_delay")}>
                                    <span className="f13"><i className="fa fa-clock-o f22 txt-danger"></i><br /> Transit Delays</span>
                                    <h4 className="txt-danger f50"><span className="counter"><CountUp end={(this.state.transit_delay != '')?this.state.transit_delay:0}/></span></h4>
                                    
                                </div> */}
                                {/*
                                <div className="col cursorPointer">
                                    <span className="f13"><i className="fa fa-map-marker f24 txt-danger"></i><br /> GPS Not Available</span>
                                    <h4 className="txt-secondary f50">
                                        <span className="counter"  onClick={this.onClickCounter.bind(this,"no_gps_data")}>
                                            
                                            <CountUp className="txt-danger" end={(this.state.gps_data_available != '')?this.state.gps_data_available:0}/> 
                                        </span>
                                        <span className="f24">&nbsp;/&nbsp;</span>
                                        <span className="counter" onClick={this.onClickCounter.bind(this,"no_gps_data_lifetime")}>
                                            <CountUp  className="txt-secondary"  end={(this.state.nogpslifetime != '')?this.state.nogpslifetime:0}/>
                                        </span>  
                                    </h4>
                                </div>
                                */}
                                {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"no_gps_data")}>
                                    <span className="f13"><i className="fa fa-map-marker f24 txt-secondary"></i><br /> GPS Data NA</span>
                                    <h4 className="txt-secondary f50"><span className="counter"><CountUp end={(this.state.gps_data_available != '')?this.state.gps_data_available:0}/></span></h4>
                                    
                                </div> */}
                                {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"overspeeding_exception")}>
                                    <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Overspeeding</span>
                                    <h4 className="txt-success f50"><span className="counter"><CountUp end={(this.state.overspeeding != '')?this.state.overspeeding:0}/></span></h4>
                                   
                                </div> */}
                                {/* {(this.props.deptcode == 'LOG-PRT')?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"nightdriving_exception")}>
                                    <span className="f13"><i className="fa fa-moon-o f24 txt-primary"></i><br /> Night Driving</span>
                                    <h4 className="txt-primary f50"><span className="counter"><CountUp end={(this.state.night_driving != '')?this.state.night_driving:0}/></span></h4>
                                    
                                </div>
                                } */}
                                 {/*(this.props.deptcode == 'LOG-PRT')?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"loading_delay_exception")}>
                                    <span className="f13"><i className="fa fa-truck-loaded f24 darkorange"></i><br /> Loading Delay</span>
                                    <h4 className="darkorange f50"><span className="counter"><CountUp end={(this.state.loading_delay != '')?this.state.loading_delay:0}/></span></h4>
                                </div>
                                */}
                                 {/*(this.props.deptcode == 'LOG-PRT')?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"unloading_delay_exception")}>
                                    <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Unloading Delay</span>
                                    <h4 className="mildgreen f50"><span className="counter"><CountUp end={(this.state.unloading_delay != '')?this.state.unloading_delay:0}/></span></h4>
                                </div>
                                */}
                                {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"enroute_exception")}>
                                    <span className="f13"><i className="fa fa-stop-circle f22 maroon"></i><br /> Stoppages</span>
                                    <h4 className="maroon f50"><span className="counter"><CountUp end={(this.state.enroute_stoppage != '')?this.state.enroute_stoppage:0}/></span></h4>                                   
                                </div> */}
                                {(localStorage.getItem("user_type") == "TECHUSER")  ?
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"is_gps_working")}>
                                    <span className="f13"><i className="fa fa-map-marker f24 txt-danger"></i><br /> GPS Issues</span>
                                    <h4 className="txt-secondary f50"><span className="counter"><CountUp className="txt-danger" end={(this.state.gps_data_available != '')?this.state.gps_data_available:0}/></span></h4>                                   
                                </div>
                                :""}
                                
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            
            </div>
        );
    }
}