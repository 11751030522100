
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Viewsharablelink = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // props.context.componentParent.onCloseRouteDiv(e);
        props.context.componentParent.onClickLink(props);
    };
    var colr = props.data.comment_exists;
    //console.log(props.data,"Component");    

    return (
        <div>
            <button onClick={handleRouteClick} className="btn btn-warning custom-btn f12 label label-success" 
            title="" >View Sharable Link</button>
            
        </div>
    );
};

export default Viewsharablelink;
