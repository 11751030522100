
import React, { Component } from 'react';

 
const FontFlag = (props) => {
    // console.log("props ",props)
    var f = '';
    if(props.data.vehicle_planned_flag !== "" && props.data.vehicle_planned_flag !== undefined)
    {
        if(props.data.vehicle_planned_flag  === 1)
        {
            f = require("../../assets/images/truck_3_32.png");
        }
    }
  
    return (
        // {
        //     (props.data.vehicle_planned_flag !== "" && props.data.vehicle_planned_flag !== undefined)?
        //         props.data.vehicle_planned_flag === 1?<i className='fa fa-bookmark'></i>:""
        //     :""
        // }
        <>
        {f !== ""?
        <img src={f} style={{width:"16px"}} />
        :""}
        
        </>
        
    );
};

export default FontFlag;
