import React, { Component } from 'react';
import Select from 'react-select';
import redirectURL from "../../redirectURL";
import Constant from '../../common/constants';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';


var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;



export default class EditGeoFence extends Component {

    constructor(props) {
        super(props);

        this.state = {
			pageTitle: "Edit Route Restriction",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			currentDepartmentCode: null,
			currentDepartmentTitle: null,
        	routemap:'',
        	departments:[],
        	coords:[],
        	geo_name:'',
        	geo_type:'',
        	fence_type:'',
        	dept_code:[],
        	notify:'',
			erroMessage:'',
			redirectpage:'',
    	    locationNames:[],
            location:'', 
            vehiclelist:[],
            vehicle_type:[], 
            pickup_window_time : ['00:00','23:59'],
            rowId:''
        };
        this.showMap = this.showMap.bind(this);
        this.applyCoords = this.applyCoords.bind(this);
        this.checkHandler = this.checkHandler.bind(this);
        this.checkNotifyHandler = this.checkNotifyHandler.bind(this);
	}
	
    
    componentDidMount = async() =>{
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        
        // let path = path_type[path_type.length-1]
        let restricted_area =url_type

        
        let data_type = 2
        let params = {
            data_type : data_type
        }
		await this.boundTypeLocations(params)
        await this.getVehiclesData(params);
        let para ={
            restricted_area
        }
        redirectURL.post('geofence/getrestrictedroute',para)
        .then(response =>{
            var vehicletypes = []
        if(response.data[0].vehicle_list.length > 0)
        {
            response.data[0].vehicle_list.map((itm) => {
                if(itm === "none")
                {
                    var stitme = "None";
                }
                else if(itm === "all")
                {
                    var stitme = "All";
                }
                else{
                    var stitme = itm;
                }
                vehicletypes.push({
                    value:itm,
                    label:stitme
                })
            })
        }
            let from_time = [response.data[0].from_time,response.data[0].to_time]
            coordinates=JSON.parse(response.data[0].coordinates);
            this.setState({
                location:{value:response.data[0].location_code,label:response.data[0].location_name},
    			geo_name:response.data[0].restricted_area,
				coords:response.data[0].coordinates,
				coordinates:coordinates,
                pickup_window_time:from_time,
                vehicle_type:vehicletypes,
                rowId:response.data[0]._id
    		});
        })
        setTimeout(
            function() {
                this.showMap();
        }
        .bind(this),
        2000
    );
    }
   
    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {
    	
           loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyC6cJAlLsrTTgm5Ck795Vg6X_EToa7FG-A&libraries=places,drawing&callback=initMap");
           window.initMap = this.initMap
	}

    boundTypeLocations = async(param) => {

        let location_access = JSON.parse(localStorage.getItem("location_access"))

        let locationNames = [];

        if(location_access == null){
            // locationNames.push({label:'ALL',value:''})
        }
        
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                        if(location_access != null){
                            if(location_access.includes(item.location_code)){
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })

                                }
                            }else{
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })
                            }
                      })
                      
                      if(locationNames.length>0){
                        await this.setState({
                                    locationNames : locationNames,
                                    location : locationNames[0]
                                })
                    }else{
                        await this.setState({
                            locationNames : [{label:'Please Add Data',value:'no_data'}],
                            location : [{label:'Please Add Data',value:'no_data'}]
                        })
                    }
                  }
              }
              this.setState({loadshow:'show-n'})
          })
          .catch(function (error) {
            var errDetails={
                url:"master/getBoundTypeLocations",
                screen:'geofence Screen',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
      }

      getVehiclesData = (params) =>{
        
        redirectURL.post('master/getVehiclesData',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                let vehiclesData = response.data.vehiclesData
                // var vehiclelist=[{value:"none",label:"None"},{value:"all",label:"All"}];
                var vehiclelist=[];
                await vehiclesData.map(item => {
                    vehiclelist.push({
                        value:item.vehicle_type,
                        label:item.vehicle_type,
                        locCode:item.location_code
                    })
                })
            this.setState({
                vehiclelist:vehiclelist
            })
        }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/getVehiclesData",
                screen:'Geofence screen',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
    }
	
   
//     initMap = () => {
//     	var coordsvalues;
//     	var map = new window.google.maps.Map(document.getElementById('map_canvas'), {
//             zoom: 12,
//             center: new window.google.maps.LatLng(17.3580,78.4869),
//             mapTypeId: window.google.maps.MapTypeId.ROADMAP,
//             mapTypeControl: true,
//             mapTypeControlOptions: {
//                 style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
//                 position: window.google.maps.ControlPosition.TOP_RIGHT
//               },            
//             disableDefaultUI: true,
//             zoomControl: true
//           });
  		
//   		//added by nanda for places dropdown...
//   	    var input = document.getElementById('search');
//           var searchBox = new window.google.maps.places.SearchBox(input);
//           map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

//           // Bias the SearchBox results towards current map's viewport.
//           map.addListener('bounds_changed', function() {
//             searchBox.setBounds(map.getBounds());
//           });
  		
//   		var markers = [];
//   		console.log("searchBox",searchBox);
//   		 searchBox.addListener('places_changed', function() {
//             var places = searchBox.getPlaces();

//             if (places.length == 0) {
//               return;
//             }

//             // Clear out the old markers.
//             markers.forEach(function(marker) {
//               marker.setMap(null);
//             });
//             markers = [];
//   // For each place, get the icon, name and location.
//             var bounds = new window.google.maps.LatLngBounds();
//             places.forEach(function(place) {
//               if (!place.geometry) {
//                 console.log("Returned place contains no geometry");
//                 return;
//               }
//               var icon = {
//                 url: place.icon,
//                 size: new window.google.maps.Size(71, 71),
//                 origin: new window.google.maps.Point(0, 0),
//                 anchor: new window.google.maps.Point(17, 34),
//                 scaledSize: new window.google.maps.Size(25, 25)
//               };

//               // Create a marker for each place.
//               markers.push(new window.google.maps.Marker({
//                 map: map,
//                 icon: icon,
//                 title: place.name,
//                 position: place.geometry.location
//               }));

//               if (place.geometry.viewport) {
//                 // Only geocodes have viewport.
//                 bounds.union(place.geometry.viewport);
//               } else {
//                 bounds.extend(place.geometry.location);
//               }
//             });
//             map.fitBounds(bounds);
//           });		
//   		//End of adding by nanda
  		
//   		var polyOptions = {
//             strokeWeight: 0,
//             fillOpacity: 0.70,
//             editable: true
//           };
//           // Creates a drawing manager attached to the map that allows the user to draw
//           // markers, lines, and shapes.
//           drawingManager = new window.google.maps.drawing.DrawingManager({
//             drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
//             drawingControlOptions: {
//               drawingModes: [
//                 window.google.maps.drawing.OverlayType.POLYGON
//               ]
//             },
//             markerOptions: {
//               draggable: true
//             },
//             polylineOptions: {
//               editable: true
//             },
//             rectangleOptions: polyOptions,
//             circleOptions: polyOptions,
//             polygonOptions: polyOptions,
//             map: map
//           });

//           window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
//               if (e.type != window.google.maps.drawing.OverlayType.MARKER) {
//               // Switch back to non-drawing mode after drawing a shape.
//               drawingManager.setDrawingMode(null);
//               // To hide:
//               drawingManager.setOptions({
//                 drawingControl: false
//               });

//               // Add an event listener that selects the newly-drawn shape when the user
//               // mouses down on it.
//               var newShape = e.overlay;
//               newShape.type = e.type;
//               window.google.maps.event.addListener(newShape, 'click', function() {
//                 setSelection(newShape);
//               });
//               setSelection(newShape);
//               //console.log("selectedShape",newShape);
//   			  fencingLength = selectedShape.getPath().getLength();
  			  
//   				var latlngObj=[];
// 	  			var htmlStr = "";
// 	  	        for (var i = 0; i < fencingLength; i++) {
// 	  				var each_lat_lng = selectedShape.getPath().getAt(i).toUrlValue(5).split(',');
// 	  				var in_latlng_array={"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
// 	  				latlngObj.push(in_latlng_array);
// 	  				coordinates.push(in_latlng_array);
// 	  			}
	  			
//             }
              
//           });
          
          
//           // Clear the current selection when the drawing mode is changed, or when the
//           // map is clicked.
//   		window.google.maps.event.addListener(drawingManager, 'drawingmode_changed', function(){
//   			clearSelection()
//   		});
//   		window.google.maps.event.addListener(map, 'click', function(){
//   			clearSelection()
//   		});
//         /*
//          * window.google.maps.event.addDomListener(document.getElementById('delete-button'), 'click', function(){
//         		deleteSelectedShape()
// 		});
//         */
        
  		
//       //buildColorPalette();
//   		map.addListener('zoom_changed', function() {
//             mapZoom=map.getZoom();
//           });
  		
//   		/*window.google.maps.event.addDomListener(document.getElementById('saveGeo'), 'click', function(){
//   			getSelectedShape()
//   		});*/
  		
//   		console.log("coordinates", coordinates);
  		
//     }

initMap = () => {
    //console.log("coordinates ", this.state.coordinates)
    var coordsvalues;
    var geoFencingObj;
    if(this.state.coords != '')
    {
        
    }
    geoFencingObj = coordinates;
    console.log("here coordinates",geoFencingObj);
    if(geoFencingObj.length > 0)
    {
        var map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 13,
            center: new window.google.maps.LatLng(geoFencingObj[0].lat,geoFencingObj[0].lng),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
            },            
            disableDefaultUI: true,
            zoomControl: true
        });
    
      //added by nanda for places dropdown...
      var input = document.getElementById('search');
      var searchBox = new window.google.maps.places.SearchBox(input);
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

      // Bias the SearchBox results towards current map's viewport.
      map.addListener('bounds_changed', function() {
         
        searchBox.setBounds(map.getBounds());
      });
      
      var markers = [];
       searchBox.addListener('places_changed', function() {
        var places = searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }

        // Clear out the old markers.
        markers.forEach(function(marker) {
          marker.setMap(null);
        });
        markers = [];
// For each place, get the icon, name and location.
        var bounds = new window.google.maps.LatLngBounds();
        places.forEach(function(place) {
          if (!place.geometry) {
            console.log("Returned place contains no geometry");
            return;
          }
          var icon = {
            url: place.icon,
            size: new window.google.maps.Size(71, 71),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(17, 34),
            scaledSize: new window.google.maps.Size(25, 25)
          };

          // Create a marker for each place.
          markers.push(new window.google.maps.Marker({
            map: map,
            icon: icon,
            title: place.name,
            position: place.geometry.location
          }));

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
              
            bounds.union(place.geometry.viewport);
          } else {
              
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });		
      //End of adding by nanda
      geoFencingArea = new window.google.maps.Polygon({
          //map:map,
        paths: geoFencingObj,
        editable: true,
        strokeColor: '#1D9567',
        strokeOpacity: 0.70,
        strokeWeight: 0,
        fillColor: '#1D9567',
        fillOpacity: 0.35,
        draggable:true
        
      });
      geoFencingArea.setMap(map);
      fencingLength=geoFencingObj;
      console.log("test ",geoFencingArea.getPath());
      //window.google.maps.event.addListener(geoFencingArea, "dragend", getPolygonCoords);

      window.google.maps.event.addListener(geoFencingArea.getPath(), 'insert_at',getPolygonCoords);
      window.google.maps.event.addListener(geoFencingArea.getPath(), 'set_at', getPolygonCoords);
      
      /*
      var getPolygonCoords = function() {
          var len = geoFencingArea.getPath().getLength();
          console.log(len);
          var htmlStr = "";
          for (var i = 0; i < len; i++) {
           // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
            //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
            htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
          }
          console.log("htmlStr", htmlStr);
      }*/
    }
}
    
    
    
    fenceTypeHandler = (event) => {
    	console.log(event.target)
    }


    changeHandler = geo_type => {

    	this.setState(
	      { geo_type },
	      () => console.log(`Option selected:`, this.state.geo_type)
	    );
	}
    checkHandler = (event) => {
    	
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
    changeArrayHandler = dept_code => {
    	/*this.setState({
    		dept_code:[...this.state.dept_code,event.target.value]
    	},() => {
    		console.log(this.state.dept_code)
    	});
    	*/
    	
    	this.setState(
	      { dept_code },
	      () => console.log(`Option selected:`, this.state.dept_code)
	    );
    	
    	
    }
    
    checkNotifyHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
    applyCoords =(e) =>{
    	console.log(e);
    }
    
	formHandler = (event) => {
			
		event.preventDefault();
		var pickup_window_time = this.state.pickup_window_time;
		var vehicle_types = this.state.vehicle_type;
        var vehicle_type = [];
        if(vehicle_types.length > 0)
        {
            vehicle_types.map((itm) => {
                vehicle_type.push(itm.value)
            })
        }
		var formdata1 = new FormData(event.target);
		console.log(coordinates.length);
		
		if(coordinates.length > 0)
		{
			var formdata ={
					restricted_area: this.state.geo_name,
					location_code:this.state.location.value,
					location_name:this.state.location.label,
           			vehicle_type:vehicle_type,
					from_time : pickup_window_time[0],
            		to_time : pickup_window_time[1],
					coordinates: coordinates,
                    rowId:this.state.rowId
			} 
			console.log(formdata,"408");
			console.log("coordinates", coordinates);
			
			redirectURL.post("/geofence/addRouteRestrictionsGeo", formdata,{
				headers:{
					'Content-Type': 'application/json'
				}
			}).
			then(
				(response)=>{
					console.log(response);
					if(response.data.status == 'success')
					{
						this.setState({
							geo_name:'',
							dept_code:'',
							geo_type:'',
							fence_type:'',
							notify:'',
							redirectpage:'yes'
						});
						
					}
					window.location.href='/geofenceList';
					
				}
			)
			.catch(function(error){
				console.log(error);
			});
		}
		else
		{
			alert("Please Add Coordinates")
			// this.setState({
			// 	erroMessage:'Please Add Coordinates'
			// });
		}
		
	}

    handlePickupWindowTime = (val) => {
    	this.setState({pickup_window_time:val});
    }

    vehicleTypeChange(vehicle_type){
        this.setState({vehicle_type})
        
    }

    
    locationChanged =async (location) =>{
        this.setState({
            location : location
        })
    }

    handleCancelfence = () =>{
        deleteSelectedShape()
    }
  
    render(){

        let locValue = this.state.location.value;   
        let filteredData;
        let filteredVehicleList;
        let vehicleListData = this.state.vehiclelist;
        if(locValue == ''){
            // filteredData = data;
            filteredVehicleList = vehicleListData;
        }else{
            // filteredData = data.filter(item => item.drop_corresponding_pickup_location_code == locValue);
            filteredVehicleList = vehicleListData.filter(itm => itm.locCode == locValue);
        }
		
    	let order_cnt=[];

    	const dStyles={
				width:'100%',
				height:'500px'
		}
    	 const { dept_code } = this.state;
    	 const { geo_type } = this.state;
        
        return (
            <div className="container-fluid">
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
                    <div className="fbold">
				       			<h5>
				       				<span>{this.state.pageTitle}</span>
				       			</h5>
				   			</div>
		            	<div className="card">
		            	
				   			<div className="row card-body">
				   			
							<form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.formHandler}>
	                    			<div className="col-xl-12 col-lg-12">
					               		<div className="form-group">
					               			<label className="c-lbl">Restricted Area *:</label>
					               			<input type="text" name="geo_name" value={this.state.geo_name} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Name" required />
					               		</div>
				               		
					           			{/* <div className="form-group">
					               			<label className="c-lbl">Department *:</label>
					               			<Select value={this.state.dept_code} onChange={this.changeArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.departmentDropDown()} required />
					               			
					               	   </div> */}
                                          <div className="form-group ">
                                        <div className="row ">
                                            <div className="col-xl-12 col-lg-12">
                                                <label className="col-form-label f12">Plant</label>
                                                <Select
                                                placeholder={"Select"}
                                                onChange={this.locationChanged}
                                                name="location"
                                                value={this.state.location}
                                                options={this.state.locationNames} />
                                            </div>
                                        </div>
                                        </div>

                                        <div className="form-group ">
                                        <div className="row ">
                                            <div className="col-xl-12 col-lg-12">
                                                <label className="col-form-label f12">Vehicle Restriction List</label>
                                                <Select
                                                    placeholder={"Select"}
                                                    onChange={this.vehicleTypeChange.bind(this)}
                                                    name="location"
                                                    isMulti={true}
                                                    value={this.state.vehicle_type}
                                                    options={filteredVehicleList} 
                                                    />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className='row'>
                                            <div className="col-xl-12 col-lg-12">
                                                <label className="c-lbl fbold">Restriction Hours<span className="redColor">*</span></label>
                                                    
                                                    <TimeRangePicker className={"text-color forminp form-control vehicle-time pad-left timer-width "} onChange={this.handlePickupWindowTime} value={this.state.pickup_window_time} rangeDivider={'to'} required={true} clearIcon={null} clockIcon={null} openClockOnFocus={false}/>
                                                    
                                            </div>
                                            
                                        </div>
                                    </div>
				               	   
					           			{/* <div className="form-group">
					               			<label className="c-lbl">Type *:</label>
					               			
					               			<Select value={this.state.geo_type} onChange={this.changeHandler.bind(this)} style={{borderRadius:"0px"}} options={fenchtypeoptions} required />
					               								               			
					               	   </div> */}
				               	  
					               	   {/* <div className="form-group">
					               	   		<label className="c-lbl">Fence Type *:</label>
					               	   		<div className="row col-xl-12 col-lg-12">
							               	   	<div className="radio radio-primary col col-xl-6 col-lg-6">
							               	   		<input id="radio11" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" value="Both" />
							               	   		<label htmlFor="radio11" className="">Both</label>
							               		</div>
							               		<div className="radio radio-secondary col col-xl-6 col-lg-6">
							               	   		<input id="radio22" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" value="Loading Bay" />
							               	   		<label htmlFor="radio22" className="">Loading Bay</label>
							               		</div>
							               		<div className="radio radio-warning col col-xl-6 col-lg-6">
							               	   		<input id="radio33" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" value="Unloading Bay" />
							               	   		<label htmlFor="radio33" className="">Unloading Bay</label>
							               		</div>
						          			</div>
										</div> */}
									
					               	   {/* <div className="form-group">
						          			<label className="c-lbl">Notify When *:</label>
						          			<div className="row col-xl-12 col-lg-12">
							          			<div className="radio radio-primary col col-xl-6 col-lg-6">
							               	   		<input id="radio44" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Vehicle Comes in" />
							               	   		<label htmlFor="radio44" className="">Vehicle Comes in</label>
							               		</div>
							               		<div className="radio radio-info col col-xl-6 col-lg-6">
							               	   		<input id="radio55" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Vehicle Goes out" />
							               	   		<label htmlFor="radio55" className="">Vehicle Goes out</label>
							               		</div>
							               		<div className="radio radio-warning col col-xl-6 col-lg-6">
							               	   		<input id="radio66" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Both" />
							               	   		<label htmlFor="radio66" className="">Both</label>
							               		</div>
							               		<div className="radio radio-danger col col-xl-6 col-lg-6">
							               	   		<input id="radio77" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Never" />
							               	   		<label htmlFor="radio77" className="">Never</label>
							               		</div>
						           			</div>
										</div> */}
										<div className="form-group">
					               			<button type="submit" className="btn btn-success cs-btn" id="saveGeo">Save Geofence</button>
					               			<a href='/geofenceList' className="btn btn-info white">Cancel</a>
					               			
					               		</div>
									</div>
									
				           		</form>
				           		<div className="col-xl-8 col-lg-8">
								
									{/* {this.state.erroMessage == '' ? '':
											<div className="alert alert-danger">{this.state.erroMessage}</div>
									} */}
									  <input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" />
				
				
				               		<div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>
				
				 	               <div className="col-xl-12 col-lg-12">
				 					  <input type="hidden" name="coords" id="coords" value={this.state.coords} />
				 				   </div>
				               		
			               		</div>
			           		</div>
		           		</div>
	           		</div>
                </div>
            </div>
                
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


 function clearSelection() {
	    if (selectedShape) {
	      selectedShape.setEditable(false);
	      selectedShape = null;
	    }
	  }

	 function setSelection(shape) {
	    clearSelection();
	    selectedShape = shape;
	    //console.log("selectedShape",selectedShape);
	    shape.setEditable(true);
	    
	    selectColor(shape.get('fillColor') || shape.get('strokeColor'));
	  }

  function deleteSelectedShape() {
		fencingLength=0;  
	    if (selectedShape) {
	      selectedShape.setMap(null);
	    }
	    // To show:
	     drawingManager.setOptions({
	       drawingControl: true
	     });
	  }

	  
	 function selectColor(color) {
	      selectedColor = color;
	      for (var i = 0; i < colors.length; ++i) {
	        var currColor = colors[i];
	        //console.log(colorButtons[currColor]);
	        //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	      }

	      // Retrieves the current options from the drawing manager and replaces the
	      // stroke or fill color as appropriate.
	      var polylineOptions = drawingManager.get('polylineOptions');
	      polylineOptions.strokeColor = color;
	      drawingManager.set('polylineOptions', polylineOptions);

	      var rectangleOptions = drawingManager.get('rectangleOptions');
	      rectangleOptions.fillColor = color;
	      drawingManager.set('rectangleOptions', rectangleOptions);

	      var circleOptions = drawingManager.get('circleOptions');
	      circleOptions.fillColor = color;
	      drawingManager.set('circleOptions', circleOptions);

	      var polygonOptions = drawingManager.get('polygonOptions');
	      polygonOptions.fillColor = color;
	      drawingManager.set('polygonOptions', polygonOptions);
	    }

	function setSelectedShapeColor(color) {
	      if (selectedShape) {
	        if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
	          selectedShape.set('strokeColor', color);
	        } else {
	          selectedShape.set('fillColor', color);
	        }
	      }
	    }

	  function makeColorButton(color) {
	      var button = document.createElement('span');
	      button.className = 'color-button';
	      button.style.backgroundColor = color;
	      window.google.maps.event.addDomListener(button, 'click', function() {
	        selectColor(color);
	        setSelectedShapeColor(color);
	      });

	      return button;
	    }

	  function buildColorPalette() {
	       var colorPalette = document.getElementById('color-palette');
	       for (var i = 0; i < colors.length; ++i) {
	         var currColor = colors[i];
	         var colorButton = makeColorButton(currColor);
	         colorPalette.appendChild(colorButton);
	         colorButtons[currColor] = colorButton;
	       }
	       selectColor(colors[0]);
	     }  
	  
	 function getSelectedShape(coords){
		var lt=[]
		//console.log("coordsLatlngs ",coords);
		for (let value of Object.values(coords)) {
		  //console.log("Val is ",value); // John, then 30
		  lt.push(value)
		}
		//console.log("Combine ",lt);
		document.getElementById('coords').value=lt;
		//return lt;
	}


    function getPolygonCoords()
    {
        
         var len = geoFencingArea.getPath().getLength();
         console.log(len);
         var htmlStr = "";
         coordinates=[];
         for (var i = 0; i < len; i++) {
            // var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
            // coordinates.push()
             var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
             var spliting = strlatlng.split(",");
             var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
             coordinates.push(latlngd);
          // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
           //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
           //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
         }
        // console.log("htmlStr", coordinates);
         
    }