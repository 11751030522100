import React,{Component} from "react";

import redirectURL from '../redirectURL';

import getToolConfiguration from "./commonFunctions";
import { getHyphenDDMMMYYYYHHMM } from "../common/utils";
var infoBox = require('../common/google-infowindow');

var map
var overlay;
var infowindows=[];
var markersArraylist=[];
var circlsRad;
export default class OnMapVehiclesLocation extends Component{
    constructor(props){
        super(props);
        this.state={
            data_type:'',
            location:'',
            locationNames:[],
            vehicle_id:'',
            truckslist:[],
            plantinfo:[],
            vicinity:1000
        }
        
    }

   async componentDidMount(){
        var vicinity= 0;
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                vicinity = toolConfig[0].gps_truck_location_vicinity;
            }
        }
        this.setState({
            vicinity:vicinity
        })

        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path_type == 'inbound'){
            data_type = 1
            this.setState({data_type : data_type})
        }else{
            data_type = 2
            this.setState({data_type : data_type})
        }
        let params = {
            data_type : data_type
        }
        // await getToolConfiguration(params)
        await this.getTrucksLocationData(params)
        // await this.boundTypeLocations(params)
        // await this.getVehiclePriorityOrder(params)
        
    }

    componentWillReceiveProps(newprops){
        let limit = 10000;
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                limit = toolConfig[0].gps_truck_location_vicinity;
            }
        }
        this.setState({vicinity:newprops.truck_location_vicinity})
        if(newprops.truck_location_vicinity>limit){
        this.renderMap()
        }
    }

    getTrucksLocationData(params)
    {
        let runPlanLocation = (localStorage.getItem('runPlanLocation'))
        
        if(runPlanLocation == null || runPlanLocation == ''){
            let locCode = JSON.parse(localStorage.getItem('qry'))
            
            runPlanLocation = locCode["plant_location_code"]
        }
        params.plant_code = runPlanLocation;
        redirectURL.post('master/trucklocations',params)
        .then((response) => {
            var records = response.data.records;
            var plantinfo = response.data.plantinfo;
            // console.log("records ", records)
            this.setState({
                truckslist:records,
                plantinfo:plantinfo
            })
            this.renderMap();
        })
    }

    renderMap = () => {

        //   loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyC6cJAlLsrTTgm5Ck795Vg6X_EToa7FG-A&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }

    initMap = () => 
    {
        
        var lt=28.6139391;
		var ln=77.2090212;
        
        var mapOptions = {
            zoom: 11,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            labels:true,
            mapTypeControlOptions: {
                mapTypeIds: ['satellite', 'roadmap'],
            },
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            styles: [
                    
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#444444"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 65
						},
						{
							"visibility": "on"
						}
					]
				},
				
				{
					"featureType": "landscape.man_made",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#c1c2c2"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
			],
        };
        try{
            map = new window.google.maps.Map(document.getElementById('locationMap'), mapOptions);
        }catch(e){}
        
        
        var bounds = new window.google.maps.LatLngBounds();
        this.maploadData();

		// const iconBase = require("../../assets/images/");
		// const icons = {
		// 	plant: {
		// 	name: "Pickup",
		// 	icon: require("../../assets/images/plant-location.png"),
		// 	},
		// 	supplier: {
		// 	name: "Drop",
		// 	icon: require("../../assets/images/customer_pin_38.png"),
		// 	},
		// 	// both: {
		// 	// name: "Both",
		// 	// icon: require("../../assets/images/business.png"),
		// 	// },
			
		// };
        
        // var legend = document.getElementById("legend");
		// legend.innerHTML = '';
		// for (var key in icons) {
		// 	var type = icons[key];
		// 	var name = type.name;
		// 	var icon = type.icon;
		// 	var div = document.createElement("div");
			
		// 	div.innerHTML = '<img class="legend-height" src="' + icon + '"> ' + name;
		// 	legend.appendChild(div);
		// }

		// map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(legend);

        // overlay = new window.google.maps.OverlayView();
        // overlay.draw = function() {};
        // overlay.setMap(map);
	 
        window.google.maps.event.addListener(map, 'click', (function() {
            closeAllInfoWindows()
        }))
    }

    maploadData = () =>{
		let data_type = this.state.data_type
		
        for (let i = 0; i < markersArraylist.length; i++) {
            markersArraylist[i].setMap(null);
        }
        
        var locations = this.state.truckslist;
        // console.log("locations ", locations)
        // var lt=17.432143; 
        // var ln=78.374427;
		var lt=21.83715037983873; 
        var ln=77.96570454960938;
        
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng((lt),(ln)));
    
        try{
            var infowindow = new window.google.maps.InfoWindow();
            var vicinity = this.state.vicinity;
            if(locations.length > 0)
            {

                var image =  require('../../assets/images/business.png');
                    
                locations.map(function(e,index){
                    // console.log("ee ", e)
                    var image =  require('../../assets/images/truck_3_32.png');
						
                    bounds.extend(new window.google.maps.LatLng((e.latitude),(e.longitude)));
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng((e.latitude), (e.longitude)),
                        icon: image,
                        map: map,	
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker) {
                        return function() {
                            
                            var contentarr = []
                        
                        var header = e.vehicle_no
						contentarr.push({"key":"Vehicle No", "value":e.vehicle_no})
                        contentarr.push({"key":"Address", "value":e.address})
                        contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(e.timestamp)})
                        // contentarr.push({"key":"End Time", "value":e.to_time})
                        var contentString = infoBox(marker.icon, header, contentarr,'')
                                                
                        infowindow.setContent(contentString);
                        infowindows.push(infowindow);
                        
                        infowindow.open(map, marker);
                        
                        }
                    })(marker));
                    
                })  
                
            }
            var plantinfo = this.state.plantinfo;
            // console.log("plantinfo ", this.props.plantLocation.value)
            if(plantinfo.length > 0)
            {
                plantinfo.map((itm) => {
                    var image =  require('../../assets/images/business.png');
						
                    bounds.extend(new window.google.maps.LatLng((itm.latitude),(itm.longitude)));
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng((itm.latitude), (itm.longitude)),
                        icon: image,
                        map: map,	
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker) {
                        return function() {
                            
                            var contentarr = []
                        
                        var header = itm.location_name
                        contentarr.push({"key":"Address", "value":itm.location_address})
						contentarr.push({"key":"City", "value":itm.location_city})
                        contentarr.push({"key":"State", "value":itm.location_state})
                        // contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(e.timestamp)})
                        // contentarr.push({"key":"End Time", "value":e.to_time})
                        var contentString = infoBox(marker.icon, header, contentarr,'')
                                                
                        infowindow.setContent(contentString);
                        infowindows.push(infowindow);
                        
                        infowindow.open(map, marker);
                        
                        }
                    })(marker));
                    var markerLatlng1 = new window.google.maps.LatLng(itm.latitude,itm.longitude);
                    if(circlsRad)
                    {
                        circlsRad.setMap(null);
                    }
                    circlsRad = new window.google.maps.Circle({
                        strokeColor: '#71caab',
                        strokeOpacity: 0.7,
                        strokeWeight: 1,
                        fillColor: '#71caab',
                        fillOpacity: 0.6,
                        map: map,
                        center: markerLatlng1,
                        radius: vicinity
                    });
                })
            }
            map.fitBounds(bounds)
        }catch(e){}
    
    }

    render(){


        return(
            <div className="">        
                
                <div className="row">
                    <div id="locationMap" style={{width:"100%", height:"60vh"}}></div>
                    
                </div>
               
            </div>
        )
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function closeAllInfoWindows() {
    for (var i=0;i<infowindows.length;i++) {
        infowindows[i].close();
    }
}