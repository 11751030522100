
import React, { Component } from 'react';

 
const HyphenButton = (props) => {
    return (
        <div style={{"textAlign":"center"}}>           
            -            
        </div>
    );
};

export default HyphenButton;
