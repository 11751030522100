import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";
import { logFormChanges  } from "./auditLogfunction";

export default class ZoneLevelCost extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            data_type:'',
            location:this.props.plantLocation,
            vehicle_type: '',
            vehicleTypeList: [],
            location_city:'',
            location_zone:'',
            cost:0,
            rowId:'',
            zone_names:[],
            transporterDetails:'',
            transporter_names:[],
            previousData:""
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        // const {plantLocation} = this.props
        await this.setState({data_type : data_type,
        // location:plantLocation
    })
        let params = {
            data_type : data_type
        }

        let toolparameters = {
            data_type : data_type
        }
        if(this.props.plantLocation.value != undefined){
            toolparameters.plant_code = this.props.plantLocation.value
        }

        await getToolConfiguration(toolparameters)
        await this.getVehiclesData(params)
        await this.getZoneWiseCostData(params)
        await this.getZoneMasterDetails(params)
        await this.getTransporterMasterDetails(params)
    }

    async componentDidUpdate(oldProps){
        let data_type = this.state.data_type;
        let params = {
            data_type : data_type,
            location_code : this.props.plantLocation.value
        }

        if(oldProps.plantLocation != this.props.plantLocation){
            this.setState({location:this.props.plantLocation})
            await this.getVehiclesData(params)
        }

    }

    getVehiclesData = (params) =>{
        let vehicleTypeList = []
        redirectURL.post('master/getVehiclesData',params)
        .then(async response =>{
            if(response.data.status == 'success'){
                let vehiclesData = response.data.vehiclesData
                if(vehiclesData.length > 0){
                    vehiclesData.map(item =>{
                        vehicleTypeList.push({ value : item.vehicle_type,
                            label : item.vehicle_type , locCode : item.location_code})
                    })
                    await this.setState({
                        vehicleTypeList : vehicleTypeList,
                        vehicle_type : vehicleTypeList[0]
                    })
                }
        }
        })
    }

    getZoneWiseCostData = (params) =>{
        redirectURL.post('master/getZoneWiseCostData',params)
        .then(response =>{
            if(response.data.status == 'success'){

                this.setState({
                    rowData:response.data.zoneWiseCostData
                })
            }
        })
    }

    
    getZoneMasterDetails = (params) =>{
        redirectURL.post('master/getZoneMasterDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
            this.setState({
                zone_names:response.data.zonesData
            })
          }
        })
    }

    //function names need to be updated
    openModelForMaterialConfiguration = () =>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel
        }))
    }

    handelMaterialConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel,
            zone:'',
            cost:0,
            vehicle_type: '',
            rowId:'',
            transporterDetails:''
            
        }))

    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}

    onChangeStateNames(location_zone){
		this.setState(
            { location_zone }
        );
	}

    getZoneNames =()=> {
		let items = [];    
		try{
			if(this.state.zone_names.length > 0)
			{
				this.state.zone_names.map((item) =>{
                    if(item.location_code == this.state.location.value){
                        items.push({"value":item.zone,"label":item.zone})
                    }
					
				})
			}
		}
		catch(e){
		}		
		return items;
	}
    
    materialDetailsInputFields = (e) =>{
        const name = e.target.name
        const inputCost = e.target.value;
        // const pattern = /^-?\d+(\.\d+)?$/
        // let isValid = pattern.test(inputCost)
        // console.log(isValid)
        // if (isValid){
            this.setState({ [name] : inputCost })
        
    }

    saveZoneWiseCostDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveZoneWiseCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getZoneWiseCostData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        cost:0,
                        vehicle_type: '',
                        location_zone:'',
                        transporterDetails:''
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:response.data.message,
                    basicType:"danger"
                })
            }
        })
    }


    updateZoneWiseCostDetails = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/updateZoneWiseCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                let logParams = {
                    "user_name": localStorage.getItem("username"),
                    "useremail": localStorage.getItem("email"),
                    "client": localStorage.getItem("client"),
                    "screen": "Freight configurations - zone wise cost",
                    "location_code":this.state.location.value,
                    "activity":{}
                }    
                let previousData = this.state.previousData
                logFormChanges(logParams,params,previousData)
                this.getZoneWiseCostData(param)
                this.setState({
                        openMaterialConfigurationModel:false,
                        cost:0,
                        vehicle_type: '',
                        location_zone:'',
                        rowId:'',
                        transporterDetails:'',
                        previousData:""
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:response.data.message,
                    basicType:"danger"
                })
            }
        })
    }


    formMaterialDetails = (e)=>{
        e.preventDefault();
        let location_zone = this.state.location_zone;
        let cost = this.state.cost;
        let vehicle_type = this.state.vehicle_type;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let rowId = this.state.rowId;
        let transporterDetails = this.state.transporterDetails
        console.log(transporterDetails.value)

        let params = {
            vehicle_type : vehicle_type.value,
            location_zone : location_zone.value,
            cost : parseInt(cost),
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            transporter_code : transporterDetails.value,
            transporter_name : transporterDetails.label
        }

        if(rowId != ''){

            params.row_id = rowId            
            
                this.updateZoneWiseCostDetails(params)
            

        }else{
            if(location.value != null && location_zone.value != null && vehicle_type.value != null && transporterDetails.value !== undefined){
                this.saveZoneWiseCostDetails(params)
            }else{

                if(location_zone.value == null){
                    this.setState({
                        show:true,
                        basicTitle:"Please Select Zone",
                        basicType:"danger"
                    })
                }else if(vehicle_type.value == null){
                    this.setState({
                        show:true,
                        basicTitle:"Please Select Vehicle",
                        basicType:"danger"
                    })
                }else if (transporterDetails.value === '' || transporterDetails.value === undefined){
                    this.setState({
                        show:true,
                        basicTitle:"Please select transporter", 
                        basicType:"danger"
                    })
                }
                else{
                this.setState({
                    show:true,
                    basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                    basicType:"danger"
                })
                }
            }
        }
    }

    editMaterialConfiguration = async(propdata) =>{
        let data = propdata.data;
        var previousData = {
            "vehicle_type" : data.vehicle_type,
            "location_city":data.location_city,
            "location_zone":data.location_zone,
            "cost":data.cost,
            "transporter_code":data.transporter_code,
            "transporter_name":data.transporter_name,
        }
        await this.setState({
            "vehicle_type" : {label:data.vehicle_type,value:data.vehicle_type},
            "location_city" : data.location_city,
            "location_zone" : {label:data.location_zone, value:data.location_zone},
            "cost" : data.cost,
            "rowId" : data._id,
            "transporterDetails" : {label:data.transporter_name, value:data.transporter_code},
            previousData:previousData
        })
        this.openModelForMaterialConfiguration()
    }

    vehicleTypeChanged = (vehicle_type) =>{
        this.setState({
            vehicle_type : vehicle_type
        })
    }

    deleteZoneWiseCostDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteZoneWiseCostDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getZoneWiseCostData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }

    getTransporterMasterDetails = (params) =>{
        redirectURL.post('master/getTransporterMasterDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
            this.setState({
                transporter_names:response.data.transporterDetailsData
            })
          }
        })
    }

    getTransporterNames =()=> {
		let items = [];    
		try{
			if(this.state.transporter_names.length > 0)
			{
				this.state.transporter_names.map((item) =>{
                    if(item.location_code == this.state.location.value){
                        items.push({"value":item.transporter_code,"label":item.transporter_name})
                    }
					
				})
			}
		}
		catch(e){
		}		
		return items;
	}

    onChangeTransporterName = (transporterDetails) =>{        
		this.setState(
            { transporterDetails }
        );
	}


    render(){

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))

        var plant_wise_freight_zone_level_cost = 0;

        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                plant_wise_freight_zone_level_cost = toolConfig[0].zone_level_cost
            }
        }

        var colsDefs = [
            {
                headerName:Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_TYPE,
                field:"vehicle_type",
                width:"120"
            },
            // {
            //     headerName:Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_CITY,
            //     field:"location_city",
            //     width:"130"
            // },
            {
                headerName:"Zone",
                field:"location_zone",
                width:"130"
            },
            {
                headerName:"Transporter",
                field:"transporter_name",
                width:"130"
            },
            {
                headerName:"Cost (₹)",
                field:"cost",
                width:"130",
            },
            // {
            //     headerName:Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_KM_COST,
            //     field:"per_km_cost",
            //     width:"150",
            //     hide:per_km_cost
            // },
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Edit',
                        onClickFunction:this.editMaterialConfiguration},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteZoneWiseCostDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            
        ]

        
        let data = this.state.rowData
        let locValue = this.state.location.value
        let filteredVehicleList;
        let vehicleListData = this.state.vehicleTypeList;
         
        let filteredData = data.filter(item => item.location_code == locValue)
        filteredVehicleList = vehicleListData.filter(itm => itm.locCode == locValue);


        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12">
                                    
                        <h5 className='fbold  d-flex justify-content-between'>
                            <span>Zone Wise Cost</span>
                            { plant_wise_freight_zone_level_cost == 1 ?
                                <div className='float-right'>
                                    <a href="javascript:;" onClick={this.openModelForMaterialConfiguration} className="f18 float-right themefont fbold mr-10p mb-10p">+Add Zone Wise Cost</a>
                                </div> 
                            :''}

                        </h5>                                                   

                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                        <AgGridReact
                        modules={this.state.modules} 
                            rowData={filteredData}
                            columnDefs={colsDefs}
                            gridOptions={{context:{componentParent:this}}}
                            defaultColDef={this.state.defaultColDef}
                            frameworkComponents={this.state.frameworkComponents}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            enableRangeSelection = {true}
                            
                        />
                        </div>
                    </div>
                </div>

                <Modal closeOnOverlayClick={false} open={this.state.openMaterialConfigurationModel} onClose={this.handelMaterialConfigurationModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >
                        <div className="col-sm-12">
                        <div>
                            <h4>Freight Details</h4>
                        </div>
                    <form className="theme-form" onSubmit={this.formMaterialDetails}>

                        <div className="row number">
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        isDisabled
                                        name="location"
                                        value={this.state.location}
                                         />
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_TYPE} <span className="redColor fbold">*</span></label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.vehicleTypeChanged}
                                        name="vehicle_type"
                                        value={this.state.vehicle_type}
                                        options={filteredVehicleList} 
                                        maxMenuHeight={'170px'}
                                        required
                                        />

                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Zone <span className="redColor fbold">*</span></label>
                                    <Select 
                                    required
                                    placeholder={"Select Zone"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeStateNames.bind(this)} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.location_zone}
                                    options={this.getZoneNames()}
                                    maxMenuHeight={'170px'} />  
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Transporter <span className="redColor fbold">*</span></label>
                                    <Select 
                                    required
                                    placeholder={"Select Transporter"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeTransporterName} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.transporterDetails}
                                    options={this.getTransporterNames()}
                                    maxMenuHeight={'170px'} />  
                                </div>
                            </div>

                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_STATE} </label>
                                    <Select 
                                    placeholder={"Select "+Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_STATE}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeStateNames.bind(this)} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.location_state}
                                    options={this.getStateNames()} />  
                                </div>
                            </div> */}

                            {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_CITY} </label>
                                    <input required type="text"  className="forminp form-control" id="location_city" name="location_city" value={this.state.location_city} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div> */}

                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Cost (₹) <span className="redColor fbold">*</span></label>
                                    <input required type="number" min='0'  className="forminp form-control" id="cost" name="cost" value={this.state.cost} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>

                            {/* {per_cmt_cost == false ?
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_CMT_COST} </label>
                                    <input required type="number" min='0'  className="forminp form-control" id="per_cmt_cost" name="per_cmt_cost" value={this.state.per_cmt_cost} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>
                            :  ""  } */}

                            {/* { per_km_cost == false ?
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_KM_COST} </label>
                                    <input required type="number" min='0'  className="forminp form-control" id="per_km_cost" name="per_km_cost" value={this.state.per_km_cost} 
                                    onChange={this.materialDetailsInputFields}
                                     placeholder="" autoComplete="off" />
                                    
                                </div>
                            </div>
                            :  ""  } */}
                           

                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                            <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelMaterialConfigurationModelCancel}>CANCEL</button>	
                        </div>
                    </form>
                </div>
                    </Modal>
            </>
        )
    }
}