
import React, { Component } from 'react';


const RouteButton = (props) => {

    const handleOnClick = () => {
        props.colDef.params.onClickFunction(props, true);
    };
    return (
        <div>

            <button className={" grid-btn"} onClick={handleOnClick} title="View Route" style={{ border: "0px", backgroundColor: 'white' }}>
                <img style={{ height: "18px" }} toolTip={'View Route'} src={require("../../assets/icons/distance.png")} />
            </button>

        </div>
    );
};

export default RouteButton;
