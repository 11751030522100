import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import { AllModules } from "@ag-grid-enterprise/all-modules";
// import GridButton from "./gridButton";
import GridButton from "../optirun-admin/gridButton";
import React, { useState, useEffect, useRef } from "react";
import redirectURL from "../redirectURL";
import $ from "jquery";
import * as XLSX from "xlsx";
import { verifyext } from "../common/verifyext";
import CSVFileValidator from "csv-file-validator";
import SweetAlert from "react-bootstrap-sweetalert";
import ChartsForPlanManagement from "./chartsforplanmanagement";
import GridInPlanManagement from "./gridForPlanmanagement";
import Select from "react-select";
var moment = require("moment");

const PlanManagementDashboard = () => {
  var rowModelhighchartoptionsType = "enterprise";
  var rowGroupPanelShow = "always";
  var animateRows = true;
  var modules = AllModules;
  var defaultColDef = {
    sortable: true,
    filter: true,
    editable: false,
    resizable: true,
    minWidth: 100,
    initialWidth: 200,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  var frameworkComponents = { GridButton: GridButton };
  var paginationPageSize = 100;
  var [getDataValue, setGetDataValue] = useState(true);
  var [rowData, setRowData] = useState([]);
  var [loadshow, setLoadshow] = useState("show-n");
  var [overly, setOverlay] = useState("show-n");
  var [locationNames, setLocationNames] = useState([
    { label: "All", value: "" },
  ]);
  var [location, setLocation] = useState({ label: "All", value: "" });
  var statusBar = {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left",
      },
      {
        statusPanel: "agTotalRowCountComponent",
        align: "center",
      },
      { statusPanel: "agFilteredRowCountComponent" },
      { statusPanel: "agSelectedRowCountComponent" },
      { statusPanel: "agAggregationComponent" },
    ],
  };
  var sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };
  const componentRef = useRef(null);

  useEffect(() => {
    componentRef.current = PlanManagementDashboard;
  }, []);

  var [startDate, setStartDate] = useState(
    moment.parseZone().subtract(1, "months").format("YYYY-MM-DD")
  );
  var [endDate, setEndDate] = useState(moment.parseZone().format("YYYY-MM-DD"));
  useEffect(() => {
    setLoadshow("show-m");
    setOverlay("show-m");
    var params = {};

    params.start_date = startDate;
    params.end_date = endDate;
    redirectURL
      .post("plandash/getPlansEditDetails", params)
      .then((response) => {
        var data = response.data.records;
        // data = data.filter(
        //   (rec) => rec.transaction_id === "2004_20240502162543-1"
        // );
        // console.log(data)
        if (location.value !== "") {
          data = data.filter((item) => {
            // item.pickup_node === location.value
            var pickupNode = item.transaction_id.split("_")[0];
            // console.log(pickupNode, "hello");
            if (location.value === pickupNode) {
              return item;
            }
          });
        }
        // console.log(data, "data");
        data = data.filter((item) => {
          if (item.pickup_node !== "") {
            return item;
          } else if (item.is_remove_trip === 1) {
            return item;
          } else {
            // console.log(item,'item')
          }
        });
        // console.log(data,'final')
        var removedTrips = data.filter((rec) => rec.trip_no === 0);
        data = data.filter((item) => item.trip_no !== 0);
        var removedGrp = groupBy(removedTrips, (rdata) => rdata.transaction_id);
        var finalRevmRecords = [];
        removedGrp.forEach((revItem) => {
          var revmGrpTripL = groupBy(revItem, (rdata) => rdata.removed_trip_no);
          revmGrpTripL.forEach((item) => {
            finalRevmRecords.push(item[0]);
          });
        });
        var pickucodesnames = response.data.pickucodesnames;
        var plantCodes = [{ label: "All", value: "" }];
        for (var i in pickucodesnames) {
          plantCodes.push({ label: pickucodesnames[i], value: i });
        }
        setLocationNames(plantCodes);
        var agrRecs = {};
        var resData = groupBy(data, (rdata) => rdata.transaction_id);
        var finalData = [...finalRevmRecords];
        // console.log(finalData, finalData.length, "length");
        resData.forEach((item) => {
          var grpOnTrip = groupBy(item, (rdata) => rdata.trip_no);
          grpOnTrip.forEach((grprec) => {
            finalData.push(grprec[0]);
          });
        });
        data = finalData;
        // console.log(data.length,'before')
        // console.log(data.length,'after',location)
        data.map((item) => {
          var row = {};
          var createdDate = moment(item.invoicing_date, "DD-MM-YYYY").format(
            "YYYY-MM-DDTHH:mm:ss"
          );
          row.createdDate = createdDate;
          if (agrRecs[createdDate] !== undefined) {
            var record = agrRecs[createdDate];
            record.totalplans = record.totalplans + 1;
            record.legs_data.push(item);
            if (item.is_remove_trip === 1) {
              record.plansRemoved = record.plansRemoved + 1;
            } else if (item.is_addremove_plan == 1) {
              record.editplans = record.editplans + 1;
            }
          } else {
            var record = {};
            record.totalplans = 1;
            record.createdDate = createdDate;
            record.legs_data = [];
            if (item.is_remove_trip === 1) {
              record.plansRemoved = 1;
              record.editplans = 0;
            } else if (item.is_addremove_plan == 1) {
              record.editplans = 1;
              record.plansRemoved = 0;
            } else {
              record.editplans = 0;
              record.plansRemoved = 0;
            }
            record.legs_data.push(item);
            agrRecs[createdDate] = record;
          }
        });
        var rowdata = [];
        for (var i in agrRecs) {
          // console.log(i,agrRecs[i],'jhk')
          var rec = agrRecs[i];
          var legsData = rec.legs_data;
          var legsDetails = {};
          legsData.map((item) => {
            // console.log(rec);
            var pickupNode = item.transaction_id.split("_")[0];
            if (legsDetails[pickupNode] !== undefined) {
              var plantDetails = legsDetails[pickupNode];
              var transIds = legsDetails[pickupNode].transIds;
              var remvdTrans = legsDetails[pickupNode].remvdTrans;
              plantDetails.totalplans = plantDetails.totalplans + 1;

              if (item.is_remove_trip === 1) {
                plantDetails.plansRemvd = plantDetails.plansRemvd + 1;
                if (!remvdTrans.includes(item.transaction_id)) {
                  remvdTrans.push(item.transaction_id);
                }
              } else if (item.is_addremove_plan === 1) {
                plantDetails.plansEdited = plantDetails.plansEdited + 1;
                if (!transIds.includes(item.transaction_id)) {
                  transIds.push(item.transaction_id);
                }
              }
              plantDetails.transIds = transIds;
              plantDetails.remvdTrans = remvdTrans;
            } else {
              var plantDetails = {};
              var transIds = [];
              var remvdTrans = [];
              plantDetails.totalplans = 1;
              if (item.is_remove_trip === 1) {
                plantDetails.plansRemvd = 1;
                plantDetails.plansEdited = 0;
                remvdTrans.push(item.transaction_id);
              } else if (item.is_addremove_plan === 1) {
                plantDetails.plansEdited = 1;
                plantDetails.plansRemvd = 0;
                transIds.push(item.transaction_id);
              } else {
                plantDetails.plansRemvd = 0;
                plantDetails.plansEdited = 0;
              }
              plantDetails.transIds = transIds;
              plantDetails.remvdTrans = remvdTrans;
              plantDetails.pickup_node = pickupNode;
              plantDetails.location_name = pickucodesnames[pickupNode];
              legsDetails[pickupNode] = plantDetails;
            }
          });
          rec.editPerctg = parseFloat(
            ((rec.editplans + rec.plansRemoved) / rec.totalplans) * 100
          ).toFixed(2);
          var legDataFianl = [];
          for (var i in legsDetails) {
            // console.log(legsDetails[i].plansEdited, "plansEdited");
            if (
              legsDetails[i].plansEdited !== 0 ||
              legsDetails[i].plansRemvd !== 0
            ) {
              legDataFianl.push(legsDetails[i]);
            }
          }
          rec.legDetails = legDataFianl;
          rowdata.push(rec);
        }
        rowdata = rowdata.sort(GetSortDescOrder("createdDate"));
        setRowData(rowdata);
        setOverlay("show-n");
        setLoadshow("show-n");
      })
      .catch((err) => {
        var errDetails = {
          url: "/plandash/getPlansEditDetails",
          screen: "Plan Management",
          err,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  }, [location, getDataValue]);

  function hideSlideBlock() {
    setOverlay("show-n");
    setLoadshow("show-n");
  }
  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // January is 0
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function onClickGetData() {
    setGetDataValue(!getDataValue);
  }
  function onChangeStartDate(event) {
    setStartDate(event.target.value);
  }
  function onChangeEndDate(event) {
    setEndDate(event.target.value);
  }
  function onChangePlantcode(event) {
    setLocation(event);
  }
  return (
    <div className="col-md-12">
      <div className="col-md-12" style={{ marginLeft: "20px" }}>
        <h5 className="" style={{ color: "black", fontWeight: "bold" }}>
          Plans Overview Dashboard
        </h5>
        <div className="form-group col-md-2 col-lg-2 ml-0 pl-0">
          <label>Pickup Location</label>
          <Select
            maxHeight={200}
            placeholder={"All"}
            closeMenuOnSelect={true}
            value={location}
            className="border-radius-0"
            onChange={onChangePlantcode}
            style={{ borderRadius: "0px" }}
            options={locationNames}
            required
          />
        </div>
      </div>
      <div
        className="col-md-12 p-0"
        style={{ marginLeft: "30px", marginRight: "20px" }}
      >
        <ChartsForPlanManagement location={location} />
      </div>
      <div style={{ marginLeft: "20px" }}>
        <div className="col-md-2">
          <div className="form-group ">
            <label className="col-form-label f12">From Date</label>
            <input
              type="date"
              className="form-control"
              name="startData"
              id="startData"
              autoComplete="off"
              onKeyDown={(e) => e.preventDefault()}
              max={getCurrentDateTime()}
              onChange={onChangeStartDate}
              value={startDate}
              readonly
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group ">
            <label className="col-form-label f12">To Date</label>
            <input
              type="date"
              className="form-control"
              name="endDate"
              id="endDate"
              autoComplete="off"
              onKeyDown={(e) => e.preventDefault()}
              max={getCurrentDateTime()}
              onChange={onChangeEndDate}
              value={endDate}
              readonly
            />
          </div>
        </div>
        <div className="col-md-2">
          <button
            type="button"
            onClick={onClickGetData}
            className="btn btn-warning mt-30px"
            style={{ marginTop: "35px" }}
          >
            Get Data
          </button>
        </div>
      </div>
      {/* </div> */}
      <div className="col-sm-12">
        <GridInPlanManagement data={rowData} />
      </div>
      <div className={"loader " + loadshow}></div>
      <div className={"overlay-part " + overly} onClick={hideSlideBlock}></div>
    </div>
  );
};

export default PlanManagementDashboard;

function GetSortDescOrder(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
