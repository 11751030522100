
import React ,{useState, useEffect, useRef, Suspense} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import SatisfiedRenderer from "./satisfiedRenderer";
import { getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
var moment = require('moment');

const AssignRequirementToVehicle = () => {
    const gridRef = useRef(null);
    const [reqRrowData, setReqRowData] = useState([]);

    const [loadshow, setLoadShow] = useState('show-n')
    const [overlayBlock, setOverlayBlock] = useState('show-n')
    const [show, setShow] = useState(false);
    const [basicTitle, setBasicTitle] = useState('');
    const [basicType, setBasicType] = useState('default')

    const [assessedBy, setAssessed] = useState('')
    const [dateOfAssessment, setDateOfAssesment] = useState(moment.parseZone().format('YYYY-MM-DDTHH:mm:ss'))

    const [conclusion, setConclusion] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [comments, setComments] = useState('');
    const [commentsErrorMessage, setCommentsErrorMessage] = useState('')

    const [location, setLocation] = useState('');
    const [locationNames, setLocationNames] = useState([]);
    
    const [vehicle, setVehicle] = useState(null);
    const [vehicleOptions, setVehicleOptions] = useState([]);

    const [approved, setApproved] = useState(null);
    const [approveOptions, setApproveOptions] = useState([
        {value:"Yes", label: "Yes"},
        {value: "No", label: "No"}
    ])
    const [checkList, setCheckList] = useState([])
    useEffect(() => {
        setLoadShow('show-m')
        setOverlayBlock('show-m')
        let location = JSON.parse(localStorage.getItem('assignqry')) 
        let assessedBy = localStorage.getItem('email')
        let role = localStorage.getItem("user_type")
        
        fetchRequirementsByLocation()
        let params= {
            location_code: location.plant_location_code,
            data_type: localStorage.getItem('data_type')
        }
        setLocation({label: location.location_city, value: location.plant_location_code});
        let plantDetails = [{value: location.plant_location_code, label:location.location_city}]
        setLocationNames([plantDetails])
        setAssessed(assessedBy)
        fetchVehiclesByPlantName(params)
    }, []);

    const handleCheckboxChange = (nodeId, checked) => {
        const updatedRowData = [...checkList]
        updatedRowData[nodeId].isSatisfied = checked
        setCheckList(updatedRowData);
    };
    const saveChanges = async (e) => {
        let logParams = {
            // "location_code" : this.state.location.value,
            // "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "role" : localStorage.getItem("role"),
            "screen" : "Assign Requirements Page",
            "activity" : 'Saving Requirements for Location',
            "event" : `Saving Requirements for Location`,
            // "data_type" : this.state.data_type,
        }    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        e.preventDefault()
        setLoadShow('show-m')
        setOverlayBlock('show-m')
        let location = JSON.parse(localStorage.getItem('assignqry'))
        let checkListTicked = checkList.filter((e)=> e.isSatisfied === true)
        if(vehicle && vehicle.value){
            if(approved && approved.value){
                if(checkListTicked.length === 0){
                    setShow(true)
                    setBasicType("danger")
                    setBasicTitle("No Requirement is Selected")
                    setLoadShow('show-n')
                    setOverlayBlock('show-n')
                } else { 
                    let stage = JSON.parse(localStorage.getItem("assignqry"))
                    let qualityAssignQry = JSON.parse(localStorage.getItem("qualityAssignQry"))
                    let params = {
                        location: location.plant_location_code,
                        vehicle: vehicle.value,
                        dateOfAssessment: dateOfAssessment,
                        assessedBy,
                        conclusion,
                        comments,
                        role: localStorage.getItem("user_type"),
                        approved_by:checkList[0].requirement_type,
                        approved: approved.value,
                        stage: qualityAssignQry? (qualityAssignQry.stage === 1? 2:1):1
                    }
                    if(qualityAssignQry){
                        params.dateOfAssessment = qualityAssignQry.dateOfAssessment;
                        params.stage = qualityAssignQry.stage;
                        params.vehicle_no = qualityAssignQry.vehicle_no;
                        params.qualityCheckDateOfAssessment = new Date()
                    }
                    if(checkList[0].requirement_type === "security"){
                        params.securityCheckList = checkList
                    } 
                    if(checkList[0].requirement_type === "quality"){
                        params.qualityCheckList = checkList
                    }
                    await redirectURL.post("master/setCheckListForVehicle", params)
                    .then((resp)=>{
                        // console.log(resp.data,"resp.daraatatat")
                        if(resp.data.status === "success"){
                            setShow(true)
                            setBasicType("success")
                            setBasicTitle(resp.data.message)
                            setLoadShow('show-n')
                            setOverlayBlock('show-n')
                            setComments('')
                            setConclusion('')
                            setVehicle({value:"", label:""})
                            setApproved({value:"", label: ""})
                            setAssessed("")
                            if(resp.data.message === "Assigning Quality Checks" ){
                                localStorage.removeItem('qualityAssignQry');
                            }else {

                            }
                            setTimeout(()=>{
                                const newUrl = `${window.location.origin}/vehicle_checklist`;
                                window.location.href = newUrl;
                            }, 2000)
                        } 
                    })
                    .catch(function (error) {
                        var errDetails={
                            url:"master/setCheckListForVehicle",
                            screen:'Requirements',
                        error,
                        }
                        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                    })
                }
            }else {
                setShow(true)
                setBasicType("danger")
                setBasicTitle("Approve To Save")
            }
        } else {         
            setShow(true)
            setBasicType("danger")
            setBasicTitle("Select Vehicle")           
        }
    }
    
    const fetchRequirementsByLocation = async () => {
        
        let location = JSON.parse(localStorage.getItem('assignqry')) 
        let params = {
            location_code: location.plant_location_code,
            isDelete: false
        }
        let stage = JSON.parse(localStorage.getItem("assignqry"))
        let qualityAssignQry = JSON.parse(localStorage.getItem("qualityAssignQry"))
        // console.log(qualityAssignQry,"qualityAssignQry")
        if(qualityAssignQry){
            params.requirement_type = qualityAssignQry.stage === 1? "quality": "";
        }else {
            params.requirement_type = stage?.stage === 0? "security":"";
        }
        // console.log(params,"params for fetching the requirements")
        await redirectURL.post("master/fetchRequirementsByLocationToAssign", params)
        .then((resp)=>{
            if(resp.data.status === "success"){
                let records = resp.data.records;
                // console.log(records, "records for assigning to vehicles")
                setReqRowData(records);
                const updatedData = records.map(row => ({
                    ...row,
                    user: localStorage.getItem("user_type"),
                    isSatisfied: row.isSatisfied || false,
                }));
                // console.log(updatedData,"updatedDataupdatedData")
                setCheckList(updatedData)
                setLoadShow('show-n')
                setOverlayBlock('show-n')
            }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/fetchRequirementsByLocation",
                screen:'Requirements',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        })
    }
    const fetchVehiclesByPlantName = async (arg) => {
        await redirectURL.post("master/fetchPlantWiseVehicles", arg)
        .then((resp)=>{
            if(resp.data.status === "success"){
                let records = resp.data.records;
                // console.log(records, "Vehicle records")
                let vehicleOptions = []
                let duplicateOptions = []
                let vehicleSet = new Set();
                let x = records.forEach((e)=>{
                    if(vehicleSet.has(e.vehicle_no)){
                        duplicateOptions.push(e)
                    }else{
                        vehicleOptions.push({value: e.vehicle_no, label: e.vehicle_no})
                        vehicleSet.add(e.vehicle_no);
                    }
                })
                // console.log(vehicleOptions,"vehicleOptions")
                let role = localStorage.getItem("user_type")
                let qualityAssignQry = JSON.parse(localStorage.getItem("qualityAssignQry"))

        
                if((role === 'admin' || role === "quality" || role === "USER") && qualityAssignQry?.hasOwnProperty("vehicle_no")){
                    setVehicleOptions([{value: qualityAssignQry.vehicle_no, label:qualityAssignQry.vehicle_no}])
                    setVehicle({value: qualityAssignQry.vehicle_no, label:qualityAssignQry.vehicle_no})
                }else{
                    setVehicleOptions(vehicleOptions);
                }
            }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/fetchPlantWiseVehicles",
                screen:'Requirements',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        })
    }
    const columnDefs = [
        { headerName: 'Requirement', field: 'requirement', width: 650, valueGetter: (params) => params.data.requirementDetails.requirement, },
        { headerName: 'Satisfied', field: 'isSatisfied', cellRenderer: 'satisfiedRenderer', width: 265, cellRendererParams: {
            onCheckboxChange: (nodeId, checked) => handleCheckboxChange(nodeId, checked)
        } }
    ];

    const defaultColDef = {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true
    };

    const frameworkComponents = {
        satisfiedRenderer: SatisfiedRenderer
    };
    
    const onChangeDateOfAssesment = (event) => {
        setDateOfAssesment(event.target.value);		
	}
    const vehicleChanged = (selectedOption) => {
        setVehicle(selectedOption)
    }
    const onChangeConclusion = (event) => {
        const value = event.target.value;
        const sanitizedValue = value.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '')
                                    .replace(/<\/?[^>]+(>|$)/g, "");
        const regex = /^[a-zA-Z0-9 .,/"':]*$/;
    
        if (regex.test(sanitizedValue)) {
          setConclusion(sanitizedValue);
          setErrorMessage('');
        } else {
          setErrorMessage('Only alphanumeric characters and . , / " \' : are allowed.');
        }
    };    
    const validateConclusion = () => {
        if (conclusion.trim() === '') {
            setErrorMessage('This field is required.');
        } else if (conclusion.length > 35) {
            setErrorMessage('The input should not exceed 35 characters.');
        } else {
            setErrorMessage('');
        }
    };
    const onChangeComments= (event) => {
        const value = event.target.value;
        const sanitizedValue = value.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '')
                                    .replace(/<\/?[^>]+(>|$)/g, "");
        const regex = /^[a-zA-Z0-9 .,/"':]*$/;
    
        if (regex.test(sanitizedValue)) {
          setComments(sanitizedValue);
          setCommentsErrorMessage('');
        } else {
          setCommentsErrorMessage('Only alphanumeric characters and . , / " \' : are allowed.');
        }
    }
    const onChangeAssessedBy = (e) => {
        setAssessed(e.target.value);
    }
    const onSelectApprove = (selectedOption) => {
        setApproved(selectedOption)
    }
    const closeAlert = () => {
        setShow(false)
    }
    let qualityAssignQry = JSON.parse(localStorage.getItem("qualityAssignQry"))
  return (    
    <div className="row">
        <SweetAlert
            show={show}
            type={basicType}
            title={basicTitle}
            onConfirm={closeAlert}>
        </SweetAlert>
        <div className="col-sm-12">
            <h5 className='fbold d-flex justify-content-between'>
                <span>Minimum Requirements</span>
            </h5>
        </div>
        <div className="col-sm-12">
            <div className="col-sm-2">
                <div className="form-group ">
                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                    <Select
                        placeholder={"Select"}
                        name="location"
                        value={location}
                        options={locationNames}
                        isDisabled={true}
                    />
                </div>
            </div>
            {qualityAssignQry ? "":            
                <div className="col-sm-2 form-control-custom">
                    <div className="form-group ">
                        <label className="col-form-label f12">Assessment Date</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            name="startData"
                            id="startData"
                            autoComplete="off"
                            onKeyDown={(e) => e.preventDefault()}
                            max={getCurrentDateTime()}
                            onChange={onChangeDateOfAssesment}
                            value={dateOfAssessment}
                            readonly
                        />
                    </div>
                </div>
            }
            <div className="col-sm-2">
                <div className="form-group ">
                    <label className="col-form-label f12">Select Vehicle <span style={{color:"red"}} className="redColor">*</span></label>
                    <Select
                        placeholder={"Select..."}
                        name="vehicle"
                        value={vehicle}
                        onChange={vehicleChanged}
                        options={vehicleOptions}
                        isDisabled = {
                            (
                                localStorage.getItem("user_type") === 'admin' || 
                                localStorage.getItem("user_type") === "quality" || 
                                localStorage.getItem("user_type") === "USER"
                            ) 
                                && 
                                JSON.parse(localStorage.getItem("qualityAssignQry"))?.hasOwnProperty("vehicle_no")
                            }
                    />
                </div>
            </div>
            <div className="col-sm-2">
                <div className="form-group">
                    <label className="col-form-label f12">
                        Assessed By
                    </label>
                    <input
                        required
                        className="forminp form-control"
                        id="assessedBy"
                        name="assessedBy"
                        value={assessedBy}
                        // onChange={onChangeAssessedBy}
                        placeholder=""
                        autoComplete="off"
                        isDisabled={true}
                    />
                </div>
            </div>
        </div>
       
        <div id="myGrid" style={{maxWidth:"67%",height:"70vh", padding: "0 20px"}} className="col-sm-12 ag-theme-balham dropdown">
            <AgGridReact
            ref={gridRef}
            rowData={reqRrowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            frameworkComponents={frameworkComponents}
            />
        </div>
         {/* text areas */}
         <div className="col-sm-12">
            <div className="col-sm-4">
                <div className="form-group">
                    <label className="col-form-label f12">
                        Conclusion
                    </label>
                    <textarea
                        className="forminp form-control"
                        id="vehicle_type"
                        name="vehicle_type"
                        value={conclusion}
                        onChange={onChangeConclusion}
                        // onBlur={validateConclusion}
                        placeholder=""
                        autoComplete="off"
                    ></textarea>
                    {errorMessage ? <p style={{color:"red"}}>{errorMessage}</p>: ''}
                </div>
            </div>
            <div className="col-sm-4">
                <div className="form-group">
                    <label className="col-form-label f12">
                        Comments
                    </label>
                    <textarea
                        className="forminp form-control"
                        id="comment"
                        name="comment"
                        value={comments}
                        onChange={onChangeComments}
                        placeholder=""
                        autoComplete="off"
                    ></textarea>
                    {commentsErrorMessage ? <p style={{color:"red"}}>{commentsErrorMessage}</p>: ''}
                </div>
            </div>
        </div>
        {/* approved */}
        <div className="col-sm-12">
            <div className="col-sm-2">
                <div className="form-group ">
                    <label className="col-form-label f12">Approved <span style={{color:"red"}} className="redColor">*</span></label>
                    <Select
                        placeholder={"Select..."}
                        name=""
                        value={approved}
                        onChange={onSelectApprove}
                        options={approveOptions}
                    />
                </div>
            </div>
            <div className="col-sm-2">
                <div className="form-group " style={{marginTop:"30px"}}>
                    <button type="submit" onClick={saveChanges} style={{marginTop:"5px", float:"left"}} className="btn tbtn save-btn" id="saveAssignCheckList">SAVE</button>
                </div>
            </div>
        </div>
    </div>
  )
}
function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // January is 0
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
export default AssignRequirementToVehicle
