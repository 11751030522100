import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

export default class HourlyGateOutChart extends Component {
    render() {

        const dStyles = {
            width: '100%',
            height: '100%'
        }

        const highchartoptions = {
            chart: {
                type: 'spline',
                spacingBottom: 20
            },
            title: {
                text: this.props.title
            },

            subtitle: {
                text: ''
            },

            yAxis: {
                title: {
                    text: this.props.yaxistitle
                }
            },

            xAxis: {
                categories: this.props.categories,
                title: {
                    text: this.props.xaxistitle
                }
                // accessibility: {
                //     rangeDescription: 'Range: 2010 to 2017'
                // }
            },

            legend: {
                //layout: 'vertical',
                align: 'center',
                verticalAlign: 'bottom',
                y: 0
            },

            plotOptions: {
                series: {

                    tooltip: {
                        headerFormat: '<b>{series.name}</b><br>',
                        pointFormat: '{point.value} : {point.y:.2f} Hrs',
                    },
                    // pointStart: 2010
                }
            },
            tooltip: {
                formatter: function () {
                    //console.log("this.series.name ", this.series)
                    return this.x + ":<br> <b style='color:" + this.series.color + ";font-weight:bold;'>" + this.series.name + " - " + this.y + "</b>";
                }
            },
            // tooltip: {
            // 	formatter: function () {
            // 		var point = this.point;
            // 		console.log("Pints ", point	)
            // 		// return this.point.reduce(function (s, point) {
            // 		// 	return s + '<br/>' + point.series.name + ': ' +
            // 		// 		point.y + ' Hrs';
            // 		// }, '<b>' + this.x + '</b>');
            // 	},
            // 	shared: true
            // },
            series: this.props.series,

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
            credits: false
        };
        // console.log(this.props.series,'ser')
        return (
            <div>
                <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
            </div>
        );
    }
}