const SatisfiedRenderer = (props) => {
    const onCheckboxChange = (e) => {
        const checked = e.target.checked;
        props.onCheckboxChange(props.node.id, checked);
        props.data.isSatisfied = checked;
        props.api.refreshCells({ rowNodes: [props.node], force: true });
    };
  
    return (
      <input type="checkbox" checked={props.value} onChange={onCheckboxChange} />
    );
};
export default SatisfiedRenderer;