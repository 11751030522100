import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from 'react-select';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL'
import { getHyphenDDMMMYYYY, getHyphenYYYYMMDD } from "../common/utils";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


var moment = require('moment');
class VehicleDistaces extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            basicTitle: '',
            basicType: '',
            month: '',
            errorMessage: '',
            successMessage: '',
            loadshow: 'show-n',
            year: '',
            rowData: [],
            totalDistance: [],
            modules: '',
            columnDefs: [],
            defaultColDef: [],
            autoGroupColumnDef: [],
            showdata: 'show-m',
            overly: 'show-n',
            location: '',
            statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
        }
    }

    componentDidMount = async () => {
        var dates = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' }
        var params = {}
        var month = new Date().getMonth() + 1
        var mon = dates[month]
        var year = new Date().getFullYear()
        var plant = this.props.plantLocation.value
        params.month = mon
        params.year = year
        params.plant_code = plant
        this.getDetails(params)
        var month = { value: month, label: mon }
        await this.setState({ month, year: { value: year, label: year } })
    }

    componentDidUpdate = async (oldProps) => {

        if (oldProps.plantLocation != this.props.plantLocation) {
            this.setState({ location: this.props.plantLocation })
            var params = {}
            var dates = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' }
            var month = new Date().getMonth() + 1
            var mon = dates[month]
            var year = new Date().getFullYear()
            var plant = this.props.plantLocation.value
            params.month = mon
            params.year = year
            params.plant_code = plant
            this.getDetails(params)
            var month = { value: month, label: mon }
            await this.setState({ month, year: { value: year, label: year } })
            // this.getTrucksLocationData(params)
        }

    }
    closeAlert = () => {
        this.setState({
            show: false,
            overly: 'show-n',
            loadshow: 'show-n'
        });
    }

    changeMonthHandler = (month) => {
        this.setState(
            { month },
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    selectYears() {
        var items = []
        // items.push({ value: '', label: 'Year' });
        var cyear = moment.parseZone().format("YYYY");
        for (var y = 2021; y <= cyear; y++) {
            items.push({ value: y, label: y });
        }

        return items
    }
    selectMonths() {
        var items = []
        items.push({ value: '01', label: 'Jan' });
        items.push({ value: '02', label: 'Feb' });
        items.push({ value: '03', label: 'Mar' });
        items.push({ value: '04', label: 'Apr' });
        items.push({ value: '05', label: 'May' });
        items.push({ value: '06', label: 'Jun' });
        items.push({ value: '07', label: 'Jul' });
        items.push({ value: '08', label: 'Aug' });
        items.push({ value: '09', label: 'Sep' });
        items.push({ value: '10', label: 'Oct' });
        items.push({ value: '11', label: 'Nov' });
        items.push({ value: '12', label: 'Dec' });
        return items
    }
    changeYearHandler = (year) => {
        // console.log(year, 'hellooo')
        this.setState({ year },);
        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderUptime: ""
        });

    }
    getDetails = async (params) => {
        // console.log('month', params)
        redirectURL.post('master/truckEodTotalDistanceTravelled', params)
            .then(async (response) => {
                var data = []
                var pinnedrow = []
                console.log(response.data,'hello')
                response.data.map((item) => {
                    if (item.truck_no !== 'Total') {
                        data.push(item)
                    } else {
                        pinnedrow.push(item)
                    }
                })
                if (data.length === 0) {
                    column = []
                    this.setState({ columnDefs: [] })
                }
                this.setState({
                    rowData: data,
                    location: this.props.plantLocation,
                    overly: 'show-n',
                    loadshow: 'show-n',
                    totalDistance: pinnedrow

                })
                var column = [
                    {
                        headerName: 'Truck No',
                        field: "truck_no",
                        // width: 140,
                        pinned: 'left'
                    }
                ]
                var trucksData = []
                if (data.length > 0) {
                    data.map((item) => {
                        var details = {}
                        details.truck_no = item.truck_no
                        trucksData.push(details)
                    })
                }
                var truck = data[0]
                Object.keys(truck).map((item) => {
                    if (item !== 'truck_no') {
                        var header = {
                            headerName: item,
                            field: item,
                            width: 140,
                            valueGetter: function (params) {

                                if (item == "Total") {

                                    return parseFloat(params.data[item]).toFixed(2)
                                }
                                else {
                                    if (params.data[item]==='nan' || params.data[item]==0){
                                        return 0
                                    }
                                    return parseFloat(params.data[item]).toFixed(2)
                                }
                            }
                        }
                        column.push(header)
                    }
                })
                await this.setState({ columnDefs: column, })
            })
    }
    onClickFilterData = () => {
        this.setState({
            loadshow: "show-m"
        })
        var year = this.state.year
        if (this.state.month != "") {
            var month = this.state.month.value
        }
        else {
            var month = ""
        }
        if (this.state.year != "") {
            var year = this.state.year.value
        }
        else {
            var year = ""
        }

        if (month == "" || year == "") {
            this.setState({
                show: true,
                basicTitle: "All * fields are mandatory",
                basicType: "danger"
            })
        }
        else {
            var month = this.state.month
            var year = this.state.year
            var params = {
                month: month.label,
                year: year.value,
                plant_code: this.state.location.value
            }
            this.getDetails(params)
        }
    }

    render() {
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>
                <div className="row" style={{ color: 'black',marginTop:"20px" }}>
                    <div className="col-xl-12 col-lg-12">
                        <div className="card"  style={{backgroundColor:"transparent"}}>
                            <div className="card-body pt-20px">
                                <form method="POST" className="row col-xl-12 col-lg-12">
                                    <div className="form-group col-xl-3 col-lg-3">

                                        <lable>Select Month: *</lable>
                                        <Select placeholder={"All"}
                                            // closeMenuOnSelect={false} 
                                            value={this.state.month}
                                            className="border-radius-0"
                                            onChange={this.changeMonthHandler.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={this.selectMonths()}
                                            required />
                                    </div>
                                    {/* <div>
                                        <DatePicker
                                            selected={this.state.selectedDate}
                                            onChange={(date) => this.setState({ selectedDate: date })}
                                            showMonthYearPicker
                                            dateFormat="MM/yyyy"
                                        />
                                    </div> */}
                                    {/* <div className="form-group col-xl-3 col-lg-3">

                                        <lable>Select Month: *</lable>
                                        <DatePicker
                                            selected={this.state.selectedDate}
                                            onChange={(date) => this.setState({ selectedDate: date })}
                                            showMonthYearPicker
                                            dateFormat="MM/yyyy"
                                        />
                                    </div> */}
                                    <div className="form-group col-xl-3 col-lg-3">

                                        <lable>Select Year: *</lable>
                                        <Select placeholder={"All"}
                                            // closeMenuOnSelect={false} 
                                            value={this.state.year}
                                            className="border-radius-0"
                                            onChange={this.changeYearHandler.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={this.selectYears()}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button
                                            type="button"
                                            className="btn btn-warning mt-4"
                                            onClick={this.onClickFilterData.bind(this)}
                                        >Submit</button>
                                    </div>
                                </form>


                            </div>
                        </div>
                        <div className={"mt-3 card " + (this.state.showdata)} style={{ color: 'black' }}>
                            <div className="card-header">
                                <h5>
                                    {/* <i className="icofont icofont-vehicle-delivery-van cus-i"></i> */}
                                    <i className="fa fa-truck" style={{ fontSize: '24px' }}></i>
                                    <span>{String.fromCharCode(160)} Day Wise Distances (Monthly)

                                    </span>
                                </h5>
                            </div>
                            <div className={"card-body pt-15px "}>
                                <div className="row ml-1 mr-1">
                                    <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this },
                                                getRowStyle: function (params) {
                                                    // if (params.node.rowIndex === params.api.getDisplayedRowCount() - 1) {
                                                    //     return { fontWeight: 'bold', 'font-size': '16px' };
                                                    // }
                                                    if (params.node.rowPinned) {
                                                        return { 'font-weight': 'bold', 'font-size': '16px' };
                                                    }
                                                },
                                                // pinnedBottomRowData: this.state.totalDistance,
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            pinnedBottomRowData={this.state.totalDistance}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

export default VehicleDistaces