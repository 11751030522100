import redirectURL from "../redirectURL"

export function logFormChanges (params, previousData) {
            let logParams = {
                "user_name": localStorage.getItem("username"),
                "useremail": localStorage.getItem("email"),
                "client": localStorage.getItem("client"),
                "screen": "Pick Up/Drop Locations",
                "corresponding_drop_location_code":params.drop_corresponding_pickup_location_code,
                "activity":{}
            };

            Object.keys(previousData).forEach(key => {
                if (JSON.stringify(params[key]) !== JSON.stringify(previousData[key])) {
                    console.log(typeof params[key],params[key])
                    if (Array.isArray(params[key])) {
                        logParams.activity[key] = `${key} changed from ${previousData[key].map(item =>item).join(',')} to ${params[key].map(item => item).join(',')}`;
                    }
                    else if (typeof params[key] === 'object' && params[key] !== null && 'value' in params[key]) {
                        logParams.activity[key] = `${key} changed from ${previousData[key].value} to ${params[key].value}`
                    }
                    else {
                        logParams.activity[key] = `${key} changed from ${previousData[key]} to ${params[key]}`;
                    }
                }
            });

            console.log(logParams, "logParams");
            redirectURL.post("/master/loguserUpdatesorChanges", logParams);
        }

    

