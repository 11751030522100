import React from 'react';

function PowerBIReport({url}) {
  return (
    <div>
     <iframe title="Report Section" width='1350' height="1650" 
     src={url} 
     frameborder="0" allowFullScreen="true"></iframe>
    </div>
  );
}

export default PowerBIReport;
