
import React, { Component } from 'react';

 
const AssignVehicleBtn = (props) => {
    const handleOnClick = () => {
        props.context.componentParent.onClickShowAssignForm(props,true);
    };
  
    return (
        <div>
            <button type="button" className="btn btn-danger grid-btn" onClick={handleOnClick}>Assign Vehicle</button>        
        </div>
    );
};

export default AssignVehicleBtn;
