import React, { Component } from "react"
import Level3 from "./configL3";

class Level2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            click: true,
        }
    }
    componentDidMount = async () => {
        var data = this.props.data
        var keys = Object.keys(data)
    }

    statusChange = async () => {
        var data = this.props.data
        var keys = Object.keys(data)
        keys.map((item1) => {
            if (item1 !== 'type' && item1 !== 'disabled' && item1 !== 'value' && item1 !== 'name' && item1 !== 'id') {
                data[item1].value = 0
            }
        })
    }
    changeHandler = async (item, event) => {
        var data = this.props.data
        var keys = Object.keys(data)
        if (data[item].value === 0) {
            data[item].value = 1
            if (data[item].type == 'radio') {
                keys.map((key) => {

                    if (data[item].id === data[key].id && data[item].name !== data[key].name) {
                        data[key].value = 0
                    }
                })
            }
        } else {
            data[item].value = 0
        }
        if (item === 'Route Configuration') {
            data['Auto Clustering'].value = 0
        }
        if (item === 'Auto Clustering') {
            data['Route Configuration'].value = 0
        }
        // console.log(item)
        if (item === 'VFR') {
            // console.log(data,event.target.value,'value')
            data[item].value = parseInt(event.target.value)
        }

        if(data[item].type==='number'){
            data[item].value=parseInt(event.target.value)
        }
        await this.setState((prevState) => {
            return ({ click: !prevState.click })
        })
    }
    render() {

        var data = this.props.data
        var keys = Object.keys(data)
        if (this.props.status === 0) {
            this.statusChange()
        }
        return (<div>
            {keys.map((item,idx) => {
                if (item !== 'type' && item !== "value" && item !== 'disabled' && this.props.status === 1 && item !== 'name' && item !== 'id') {
                    // console.log(item,'ll2')
                    return (
                        <div className="row ml-3 per col-md-12" key={idx} id={item} style={{ display: 'block' }}>

                            <label className="fw-600"><input type={data[item].type} onChange={(event) => { this.changeHandler(item,event) }} disabled={data[item].disabled} checked={data[item].value === 1} value={data[item].value} name={data[item].id} />&nbsp;&nbsp;{item}</label>
                            <Level3 data={data[item]} status={data[item].value} />
                        </div>)
                }
            })}
        </div>)
    }
}

export default Level2