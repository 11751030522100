
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Trucklocaction = (props) => {
    //console.log("LoadingDelay ",props);
    const handleLoadingDelayClick = (e) => {
        e.stopPropagation();
        
       props.context.componentParent.onClickShowTruckLocation(props);
    };
   
    return (
        <div>
            <button onClick={handleLoadingDelayClick} 
            className="custom-btn f22 label label-success" title="View Location">
                <i className="fa fa-map-marker"></i> &nbsp;</button>
            
           
        </div>
    );
};

export default Trucklocaction;
