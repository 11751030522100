import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

// Initialize the Highcharts modules
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

const HighchartsComponent = (props) => {
    const chartComponentRef = useRef(null);
  
    const options = {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '80%',
        backgroundColor: null
      },
      title: {
        text: ''
      },
      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '75%'],
        size: '110%'
      },
      yAxis: {
        min: 0,
        max: 100,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
        tickLength: 40,
        tickWidth: 1,
        minorTickInterval: 1,
        minorTickColor:'#ffffff',
        minorTickWidth: 1,
        minorTickLength: 40,
        labels: {
          distance: 20,
          style: {
            fontSize: '14px',
            // display:"none"
          }
        },
        color: {
            radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
            stops: [
               [0, '#003399'],
               [1, '#3366AA']
            ]
        },
        lineWidth: 0,
        plotBands: [
          {
            from: 70,
            to: 100,
            color: '#55BF3B', // green
            thickness: 40,
            borderRadius: '50%',
            color: {
                linearGradient:  { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                   [0, '#DDDF0D'],
                   [1, '#55BF3B']
                ]
            }
            // className:"yellow-green"
          },
          {
            from: 0,
            to: 30,
            color: '#DF5353', // red
            thickness: 40,
            borderRadius: '50%',
            color: {
                linearGradient:  { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, '#DDDF0D'], // yellow
                    [1, '#DF5353'], // green
                //   [0.9, '#55BF3B'] // red
                ]
              },
          },
          {
            from: 30,
            to: 70,
            color: '#DDDF0D', // yellow
            thickness: 40,
            color: {
                linearGradient:  { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                   [0, '#DDDF0D'],
                   [1, '#DBDD2E']
                //    [0.1, '#DDDF0D']
                ]
            }
            
          }
        ]
      },
      series: [
        {
          name: 'Planning',
          data: [props.pointValue !== "" && props.pointValue !== undefined?parseInt(props.pointValue):0],
          tooltip: {
            valueSuffix: ' '
          },
          dataLabels: {
            format: '{y} ',
            borderWidth: 0,
            color: (
              Highcharts.defaultOptions.title &&
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || '#333333',
            style: {
              fontSize: '26px'
            }
          },
          dial: {
            radius: '80%',
            backgroundColor: 'gray',
            baseWidth: 8,
            baseLength: '0%',
            rearLength: '0%'
          },
          pivot: {
            backgroundColor: 'gray',
            radius: 4
          }
        }
      ],
      // responsive: {
      //     rules: [{
      //         condition: {
      //             maxWidth: 500
      //         },
      //         chartOptions: {
      //             title: {
      //                 style: {
      //                     fontSize: '1.5rem'
      //                 }
      //             },
      //             yAxis: {
      //                 labels: {
      //                     style: {
      //                         fontSize: '0.8rem'
      //                     }
      //                 }
      //             },
      //             series: [{
      //                 dataLabels: {
      //                     format: '<div style="text-align:center"><span style="font-size:1.5rem">{y}</span><br/><span style="font-size:1rem">km/h</span></div>'
      //                 }
      //             }]
      //         }
      //     }]
      // },
      credits:false
    };
  
    useEffect(() => {
      // const interval = setInterval(() => {
      //   const chart = chartComponentRef.current?.chart;
      //   if (chart && !chart.renderer.forExport) {
      //     const point = chart.series[0].points[0];
      //     // const inc = Math.round((Math.random() - 0.5) * 20);
  
      //     let newVal = parseInt(props.pointValue);
      //     console.log("newVal ", newVal)
      //     point.update(newVal);
      //   }
      // }, 3000);
  
      // return () => clearInterval(interval);
    }, [props]);
  
    return (
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { width: '100%', height: '100%' } }} 
        options={options}
        ref={chartComponentRef}
      />
    );
  };
  
  export default HighchartsComponent;