import redirectURL from '../redirectURL';

const getToolConfiguration = async (toolparameters) =>{
    // console.log(toolparameters,"toolparameters")
    if(!toolparameters.plant_code){
        var locationAcces= JSON.parse(localStorage.getItem('location_access'))
        
        if (locationAcces!==null){
            toolparameters.plant_code=locationAcces[0]
        }

    }
//    await redirectURL.post('master/getToolconfigurations',toolparameters)
   await redirectURL.post('master/getPlantwiseToolConfig',toolparameters)
            .then(response =>{
                
                if(response.status == 200){
                    let toolConfigData = response.data
                    let x = toolConfigData.map(e =>e.plant_code);
                    localStorage.setItem("tlcfg",JSON.stringify(toolConfigData))
                }
            })
            .catch((err)=>{
                var errDetails={
                    url:'master/getToolconfigurations',
                    err
                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            })

        
}

export default getToolConfiguration
