import React, { Component } from "react";
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import Gps from '../manage/gpsActionComponent.js';
import NoGps from '../manage/nogpsActionComponent.js';
import $ from "jquery";
import NoGpsLifetime from '../manage/nogpslifetimeActionComponent.js';
import ConsignmentInformation from "./consignmentinformation"
import Modal from 'react-responsive-modal';
import Viewsharablelink from "../layouts/viewsharablelink";
import { RxCodesandboxLogo } from "react-icons/rx";
import { IoMdCloseCircle } from "react-icons/io";
import { GrUserManager } from "react-icons/gr";
import { BsBuildingsFill } from "react-icons/bs";
import { FaTruckMoving } from "react-icons/fa";
import { TbUserX } from "react-icons/tb"
import { BiSolidFactory } from "react-icons/bi";

import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenDDMMYYYY
} from '../common/utils';

import DrawMap from '../common/drawmap';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import GridButton from "../optirun-admin/gridButton";
var infoBox = require('../common/google-infowindow');

var moment = require('moment');
export default class ConsignmentData extends Component {
	constructor(props) {
		var sdate = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD');
		var edate = moment.parseZone().format('YYYY-MM-DD');
		super(props);
		this.displayData = [];
		this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			originalData: [],
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			mapinfo: '',
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',			
			overly: 'show-n',			
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			modules: AllModules,			
			nightdrive: [],
			sliderCommentTranslate: "",
			commentsRowData: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			slideBar: "",
			consignments: "",			
			defaultColDef: {
				sortable: true,
				filter: true,
				editable: false,
				resizable: true,
				minWidth: 70,
				initialWidth: 200,
				wrapHeaderText: true,
				autoHeaderHeight: true,
			},
			rownode: [],
			rowData: [],
			maprowData: [],			
			overlayNoRowsTemplate: "",			
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				gps: Gps,
				nogps: NoGps,
				noGpsLifetime: NoGpsLifetime,
				ConsignmentInformation: ConsignmentInformation,
				Viewsharablelink: Viewsharablelink,
				GridButton: GridButton
			},
			paginationPageSize: 1000,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"
			},
			triptype: '',
			tripvalue: '',
			consigner: '',
			consigner_coordinates: [],
			consignee_coordinates: [],
			baseRouteCoords: [],
			pagecounters: '',
			startDate: moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD'),
			containerslist: [],
			endDate: moment.parseZone().format('YYYY-MM-DD'),
			defaultsdate: sdate,
			defaultedate: edate,
			tolls: [],
			loginplant: "",
			filterCities: [],
			filterCustomers: [],
			filterTransporters: [],
			flCity: "",
			flCustomer: [],
			flZone: "",
			flTransporter: "",
			flTransporterZone: "",
			movementtype: { "value": "all", "label": "All" },
			plants: [],
			selectedFilter: "",
			usergridstate: [],
			sliderBulkForceClose: "",
			screenurl: "",
			screentitle: "",
			map_type_of_tracking: "",
			filterNames: "Truck / Shipment No",
			filterOptions: [{ label: "Truck", value: "Truck" }, { label: "Shipment No", value: "shipment_id" }],
			rowIds: [],
			priority: 0,
			padding: 6,
			lastRunTime: "",
			seconds: 120,
			shipment_time: "",			
			transporterCode: "",
			transporter_name: "",
			consignee_code: "",
			screen_type: "Active",
			screen_type_val: { "value": "Active", label: "Active Trips" },
			overlyRing: 'show-n',
			base_route_show: true,
			selectedShipmentId: '',
			pre_sdate: moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD'),
			pre_edate: moment.parseZone().format('YYYY-MM-DD'),
			trucksArr: [],
			rowDataTrucks: [],
			shipmentCreatedCount:"",
			insidePlantCount:"",
			forceClosureCount:"",
			inTransitToCustomerCount:"",
			reachedCustomerCount:"",
			leftCustomerCount:"",
			dealerNotServedCount:"",
			reachedPlantCount:0,
			activeCounterTab:0,
			originalRowDataTrucks:[],
			restoreGridCols:""
		}

		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
		this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onShowCommentsDiv = this.onShowCommentsDiv.bind(this);
		this.onClickLink = this.onClickLink.bind(this);		
	}

	componentDidMount = () => {

		var from_date = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD');
		var to_date = moment.parseZone().format("YYYY-MM-DD")
		
		console.log('hit')
		var params = { from_date, to_date }
		redirectURL.post("/tracking/getRoutesForConsignments", params)
			.then((response) => {
				console.log(response, 'hello')
			})

		var params = { from_date, to_date }
		redirectURL.post("/tracking/getConsignmentDetails", params)
			.then((response) => {
				console.log(params)
				
				let consignmentsArray = response.data
				this.updateStatusCounters(consignmentsArray)
				this.setState({ 
					rowDataTrucks: response.data,
				 })

			})
		
		this.setState({
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			}
		});
		
		var dpt;
		var sdate = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD');
		var edate = moment.parseZone().format('YYYY-MM-DD');
		
		redirectURL.post("/tracking/plants", { dept_code: (dpt) })
			.then(async (resp) => {
				let responseData = resp.data.plants;
				if (responseData.length > 0) {
					responseData = await distinctArrayBy(responseData, "plant_code");
				}
				let location_access = JSON.parse(localStorage.getItem("location_access"))
				if (location_access != null) {
					responseData = responseData.filter(itm => location_access.includes(itm.plant_code))
				}
				this.setState({
					plants: responseData
				});
			})
			.catch((err) => {
				var errDetails = {
					url: "/tracking/plants",
					screen: 'Trips',
					err,
				}
				redirectURL.post("/master/logErrorsForApiCalls", errDetails)

			})

		var params = { dept_code: (dpt), email: localStorage.getItem('email'), role: localStorage.getItem('roles'), customer_code: localStorage.getItem('customer_code'), consigner_code: localStorage.getItem('pc'), plant_code: JSON.parse(localStorage.getItem("location_access")) }
		redirectURL.post("/tracking/getConsignmentsNewFilterKeysForSndTrucks", params)
			.then((resp) => {
				var data = resp.data.data
				var transporters = resp.data.data.transporters;
				let tlist = [];
				tlist.push({
					"value": "",
					"label": "All",
				});
				if (transporters.length > 0) {
					var uniqueArray = Array.from(new Set(transporters.map(item => item.transporter_code))).map(transporter_code => transporters.find(item => item.transporter_code === transporter_code));
					uniqueArray.map((t) => {
						tlist.push({
							"value": t.transporter_code,
							"label": t.transporter_name,
						});
					});
				}
				var cities = data.cities;
				var filterCities = [];
				if (cities.length > 0) {
					filterCities.push({
						label: "All",
						value: "",
					});
					cities.map(function (c) {
						filterCities.push({
							label: c,
							value: c,
						});
					});
				}
				var customers = data.customers;
				var filterCustomers = [];
				if (customers.length > 0) {
					filterCustomers.push({
						label: "All",
						value: "",
					});
					customers.map(function (c) {
						filterCustomers.push({
							label: c.location_code + " - " + c.location_name,
							value: c.location_code,
						});
					});
				}
				this.setState({
					filterCities: filterCities,
					filterCustomers: filterCustomers,
					filterTransporters: tlist,
				});
			})
			.catch((err) => {
				var errDetails = {
					url: "/tracking/getConsignmentsNewFilterKeysForSndTrucks",
					screen: 'Trips',
					err,
				}
				redirectURL.post("/master/logErrorsForApiCalls", errDetails)
			})

		this.setState({
			loadshow: 'show-m',
			deptcode: dpt,
			defaultsdate: sdate,
			defaultedate: edate
		});

		var reqparams = {
			dept_code: dpt,
			from_date: sdate,
			to_date: edate,
		}
		try {
			if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined) {
				reqparams.consigner_code = localStorage.getItem("pc")
				this.setState({
					consigner: [localStorage.getItem("pc")]
				})
			}
			else {
				reqparams.consigner_code = "ALL"
			}
		} catch (e) {

		}
		
		if (localStorage.getItem("transportercode") != "undefined" && localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != "") {
			reqparams.transporter_codes = localStorage.getItem("transportercode")
		}
		if (localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined" && localStorage.getItem("customer_code") != "") {
			reqparams.customer_code = localStorage.getItem("customer_code")
		}
		
		this.setState({ loadshow: 'show-n', overly: 'show-n' })
		this.restoreGridStates();
	};

	updateStatusCounters(consignmentsArray) {
		let shipmentCreatedCount = 0;
		let insidePlantCount = 0;
		let insideLoadingYardCount = 0
		let forceClosureCount = 0
		let inTransitToCustomerCount = 0
		let reachedCustomerCount = 0
		let leftCustomerCount = 0
		let dealerNotServedCount = 0
		let reachedPlantCount = 0 

		for ( let each of  consignmentsArray) {
			if (each.status === 1 ) {
				shipmentCreatedCount += 1
			}else if (each.status === 3 ||each.status === 4 ){
				insidePlantCount += 1
			}
			else if (each.status === 6 ) {
				forceClosureCount += 1
			}else if (each.status === 8 ){
				inTransitToCustomerCount += 1
			} else if (each.status === 11 ) {
				reachedCustomerCount += 1
			} else if ( each.status === 12){
				leftCustomerCount += 1
			}else if (each.status === 15) {
				dealerNotServedCount += 1 
			}else {
				reachedPlantCount += 1
			}
		}

		this.setState({
			shipmentCreatedCount,
			insidePlantCount,
			insideLoadingYardCount,
			forceClosureCount,
			inTransitToCustomerCount,
			reachedCustomerCount,
			leftCustomerCount,
			dealerNotServedCount,
			reachedPlantCount
		})
	}

	onLoadShowTrucksList = (urlpath, parameters) => {
		let location_access = JSON.parse(localStorage.getItem("location_access"))
		if (location_access != null) {
			parameters.plant_code = location_access
		}
		var k = {}
		if (!Object.keys(parameters).length) {
			parameters.consigner_code = 'ALL'
		}
		var reqparams = {
			...parameters,
			screen_type: this.state.screen_type,
			data_type: 'allTrucks',
			priority: this.state.priority,
			showRail: 0
		}
		
		redirectURL.post('/tracking/intranitRoadMobileData', reqparams)
			.then((response) => {
				var trucksArr = response.data;
				if (trucksArr.length > 0) {
					trucksArr = trucksArr.filter((ta) => ta.status <= 15);
				}
				if (typeof response.data == 'object') {
					var recordsarr = response.data;
				}
				else {
					var dd = response.data;
					var recordsarr = JSON.parse(response.data);
				}
				var records = [];

				var counters = recordsarr.counters_data;
				records = records.sort(GetSortDescOrder("invoice_time"));
				var lastRunTime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
				var params = {}
				params.from_date = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD')
				params.to_date = moment.parseZone().format('YYYY-MM-DD')
				redirectURL.post('/tracking/getConsignmentDetails', params)
					.then((response) => {
						trucksArr = response.data
					})
				this.setState({
					rowData: trucksArr,
					allRowData: trucksArr,
					originalData: trucksArr,
					lastRunTime: lastRunTime,
					loadshow: 'show-n',
					overly: 'show-n',
					pagecounters: counters,
					trucksArr
				});
			})
			.catch(function (error) {
				var errDetails = {
					url: "/tracking/intranitRoadMobileData",
					screen: 'Trips',
					error,
				}
				redirectURL.post("/master/logErrorsForApiCalls", errDetails)
				console.log(error);
			});
	}

	onShowTimelineDiv = (e) => {
		
		this.setState({
			loadshow: 'show-m'
		});
		var reqData = {
			consignment_code: e.consignment_code
		}
		
		redirectURL.post('/tracking/timeline', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;				
				this.setState({
					uploadDivWidth: '30%',
					sliderTranslate: "slider-translate-50p",
					showDiv: 'show-m',
					timelinedata: records,
					loadshow: 'show-n',
					overly: 'show-m'
				});
			})
			.catch(function (err) {
				console.log(err);
				var errDetails = {
					url: "/tracking/timeline",
					screen: 'Trips',
					err,
				}
				redirectURL.post("/master/logErrorsForApiCalls", errDetails)
			});

	}

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			sideBar: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}
	onShowCommentsDiv = (e) => {
		var reqparams = {
			truck_no: e.truck_no,
			dept_code: this.state.deptcode,
			screen: "Active Trucks"
		}
		redirectURL.post("/tracking/getCommentLogs", reqparams)
			.then((response) => {
				this.setState({
					commentsRowData: response.data.data
				})
			}).catch(function (error) {
				var errDetails = {
					url: "/tracking/getCommentLogs",
					screen: 'Trips',
					error,
				}
				redirectURL.post("/master/logErrorsForApiCalls", errDetails)
				console.log(error);
			})
		this.setState({
			sliderCommentTranslate: "slider-translate-60p",
			showDiv: 'show-m',
			loadshow: 'show-n',
			overly: 'show-m',
		});
	}
	onShowRouteDiv = (e) => {
		var fromDate = ""
		if (e.loading_yard_exit_time !== undefined && e.loading_yard_exit_time !== "" && e.loading_yard_exit_time !== null ) {
			fromDate = getHyphenYYYYMMDDHHMMSS(e.loading_yard_exit_time)
		}
		else if (e.shipment_time !== undefined && e.shipment_time !== "" && e.shipment_time !== null ) {
			fromDate = getHyphenYYYYMMDDHHMMSS(e.shipment_time)
		}
		else if  (e.loading_yard_entry_time !== undefined && e.loading_yard_entry_time !== "" && e.loading_yard_entry_time !== null ){
			fromDate = getHyphenYYYYMMDDHHMMSS(e.loading_yard_entry_time)
		}


		var toDate = ""
		if (e.left_dealer_marked !== undefined && e.left_dealer_marked !== ""  && e.left_dealer_marked !== null) {
			toDate = getHyphenYYYYMMDDHHMMSS(e.left_dealer_marked) 
		} 
		else {
			toDate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		}

		var reqParams = {
			truck_no :  e.truck_no,
			from_date : fromDate,
			to_date : toDate,
			consignee_code : e.consignment_code
		}
		redirectURL.post("/tracking/getRoutesForConsignments", reqParams)
        .then(async(resp) => {
            let records = resp.data
            this.setState({
				loadshow:"show-m"
			})
			if (records.coords.length === 0 ){
				this.setState({
					show:true,
					basicTitle:'No Route Available',
					basicType:"danger",
					loadshow:'show-n',
					overlyRing:"show-n"
				})
			}
			else {
				console.log(records,"records here")
				this.setState({
					sliderRouteTranslate: "slider-translate-60p",
					showDiv:"show-m",
					mapinfo:records,
					dealer:records.consignee_coordinates,
					consignment_code:"Truck No : "+ e.truck_no,
					maptruckno: e.truck_no,
					routeTruck: { 
						"truck_no": e.truck_no, 
						"startTime": records.route_details.start_time,
						"endTime": records.route_details.end_time 
					},
					loadshow:"show-n",
					sidebarSubHeader:'Track Route Details',
					overly : "show-m",
					rownode: e,
					consigner_coordinates : records.consigner_coordinates,
					consignee_coordinates: records.consignee_coordinates,
					baseRouteCoords: [],
					tolls: [],
					map_type_of_tracking : records.type_of_tracking,
					base_route_show:true
				})
			} 
        })
        .catch((error) => {
            console.log(error.message)
        })			
	}


	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			showDiv: 'show-n',
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			overlyRing: 'show-n',
		});
	}

	/*Truck Sidebar Component Starts*/

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	};

	onGridState = () => {

		this.colState = this.gridColumnApi.getColumnState();

		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);

	}
	onClickLink = (params) => {
		var truckNo = params.data.truck_no
		window.open('/connect/' + truckNo, '_blank');
	}

	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			sliderCommentTranslate: "",
			slideBar: ''
		});

	}

	hideSlideBlock = () => {
		this.setState({
			overlyRing: 'show-n',
			sliderRouteTranslate: "",
			overly: 'show-n',
		})
	}

	closeAlert = () => {
		this.setState({
			show: false,
			loadshow: 'show-n',
			overlyRing: 'show-n',
		});
		// window.location.reload()
	}

	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			//   () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	selectConsignerOptionsItems() {
		let items = [];

		var plants = this.state.plants;

		items.push({ value: 'ALL', label: 'All' });
		if (plants.length > 0) {
			plants.forEach(item => {
				if (this.state.loginplant != "" && this.state.loginplant != undefined) {
					if (this.state.loginplant == item.plant_code) {
						items.push({ value: item.plant_code, label: item.plant_name });
					}

				}
				else {
					items.push({ value: item.plant_code, label: item.plant_name });
				}

			});
		}

		// console.log(items,"items---3272")
		return items;
	}

	newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });		
	}

	newSelector = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}

	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		this.pre_sdate = this.state.startDate
		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		var pre_sdate = this.state.startDate
		this.setState({
			startDate: startdate,
			pre_sdate: pre_sdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		// this.pre_edate=this.state.endDate
		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		var pre_edate = this.state.endDate
		this.setState({
			endDate: edate,
			pre_edate
		});
	}

	onGetConsigners = async (event) => {
		this.setState({ loadshow: 'show-m', })
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;
		var selectcons = []
		var datechk = 1;
		var errpopmsg = "";

		if (sDate != undefined && sDate != "") {
			// console.log(eDate,"eDate")
			if (eDate == undefined || eDate == "") {
				datechk = 0;
				errpopmsg = "Please select Invoice To Date"
			}
		}

		if (eDate != undefined && eDate != "") {
			if (sDate == undefined || sDate == "") {
				// console.log(sDate,"sDate")
				datechk = 0;
				errpopmsg = "Please select Invoice From Date"
			}
		}
		if (sDate != undefined && sDate != "" && eDate != undefined && eDate != "") {
			// console.log(new Date(this.state.startDate) >=new Date(this.state.endDate))

			if (new Date(this.state.startDate) >= new Date(this.state.endDate)) {
				// console.log('hit')
				datechk = 0;
				errpopmsg = "Invoice From Date should be less than Invoice To Date"
				this.setState({ startDate: this.pre_sdate, endDate: this.pre_edate })

			}
		}
		if (datechk === 1) {
			if (localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "") {
				selectcons.push(localStorage.getItem("pc"));
			}
			else if (this.state.consigner != null) {
				if (this.state.consigner.length > 0) {
					this.state.consigner.forEach(item => {
						if (item.value != "" && item.value != undefined) {
							selectcons.push(item.value);
						}
					});
				}
				else {
					selectcons.push("ALL");
				}
			}
			else {
				selectcons.push("ALL");
			}


			var parameters = {
				consigner_code: selectcons,
				dept_code: this.state.deptcode,
				from_date: sDate,
				to_date: eDate
			}
			// console.log(localStorage.getItem("pc"),"plant")
			if (localStorage.getItem("transportercode") != "undefined" && localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != "") {
				parameters.transporter_code = localStorage.getItem("transportercode")
			}
			if (localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined" && localStorage.getItem("customer_code") != "") {
				parameters.customer_code = localStorage.getItem("customer_code")
			}
			if (this.state.deptcode == 'SNDG') {
				let zone = this.state.flZone
				if (zone !== "" && zone !== undefined && zone !== null) {
					parameters['zone'] = [zone.value]
				}
			}

			let transporter = this.state.flTransporter
			if (transporter !== "" && transporter !== undefined && transporter !== null) {
				if (transporter.value != "") {
					// parameters['transporter'] = [transporter.label]
					parameters['transporter_code'] = JSON.stringify([transporter.value])
				}
			}
			let flTransporterZone = this.state.flTransporterZone;
			if (flTransporterZone !== "" && flTransporterZone !== undefined && flTransporterZone !== null) {
				if (flTransporterZone.value != "") {
					// parameters['transporter'] = [transporter.label]
					parameters['transporter_zone'] = [flTransporterZone.value]
				}

			}
			let city = this.state.flCity
			let flCustomer = this.state.flCustomer

			if (city !== "" && (city) !== undefined && city !== null) {
				if (city.value != "") {
					parameters['city'] = [city.value]
				}
			}
			if (flCustomer !== "" && (flCustomer) !== undefined && flCustomer !== null) {
				var flList = [];
				flCustomer.map((fl) => {
					flList.push(fl.value);
				});
				if (flList.length > 0) {
					parameters['flCustomer'] = flList
				}
			}
			var urlpath;

			console.log(parameters, 'parameters')
			var params = {}
			params.from_date = parameters.from_date
			params.to_date = parameters.to_date
			var customerCode = parameters.flCustomer
			var transporterCode = parameters.transporter_code
			params.customerCode = customerCode
			params.consigner_code = parameters.consigner_code
			params.city = parameters.city
			if (transporterCode !== undefined) {
				transporterCode = JSON.parse(transporterCode)
			}
			params.transporterCode = transporterCode
			console.log(params, 'helloo')
			redirectURL.post('/tracking/getConsignmentDetails', params)
				.then((response) => {

					console.log(response, 'hello response')
					var data = response.data
					this.updateStatusCounters(data)

					this.setState({
						rowDataTrucks: response.data,
						loadshow: 'show-n',
						overly: 'show-n',

					})

				})

			// this.onLoadShowTrucksList(urlpath, parameters)

		}
		else {
			var pre_edate = this.state.pre_edate
			var pre_sdate = this.state.pre_sdate
			this.setState({
				show: true,
				basicTitle: errpopmsg,
				basicType: "warning",
				startDate: pre_sdate,
				endDate: pre_edate
			})
		}
		this.restoreGridStates()
	}

	onRowSelection(event) {
		var rowIds = [];
		var rwCount = event.api.getSelectedNodes();
		rwCount.map((item) => {
			rowIds.push(item.data.shipment_id)
		});

		this.setState({
			rowIds: rowIds
		})
		console.log("checkedlist ", rowIds)
	}

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ open: false });
	};

	onClickSaveGridState = async () => {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		// window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		let screenpage = '';

		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: [],
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		console.log(reqparams)

		await redirectURL.post("/tracking/saveGridStates", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				var errDetails = {
					url: "/tracking/saveGridStates",
					screen: 'consignments',
					e,
				}
				redirectURL.post("/master/logErrorsForApiCalls", errDetails)
				console.log("Error ", e)
			})
		setTimeout(() => {
			this.restoreGridStates()
		}, 1001)

		setTimeout(() => {
			this.closeAlert()
		}, 1000)


	}

	restoreGridStates = async () => {
		redirectURL.post("/tracking/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				this.setState({restoreGridCols : resp.data })
				if (resp.data.length !== 0) {
					try {
						setTimeout(() => {
							var windowstates = resp.data
							this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
							this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
							this.gridApi.setSortModel(windowstates[0].gridcolsort);
							this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
						}, 1000)


					}
					catch (e) {

					}
				}
				
			})

	}

	resetState = async () => {
		try {
			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			this.gridApi.setFilterModel();
			//console.log('column state reset');
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			// window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();

			let screenpage = '';

			var reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: [],
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: screenpage
			}
			// console.log("reqparams ", reqparams)
			await redirectURL.post("/tracking/saveGridStates", reqparams)
				.then((response) => {
					//console.log("State response ",response.data)
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
				.catch(function (e) {
					var errDetails = {
						url: "/tracking/saveGridStates",
						screen: 'consignments',
						e,
					}
					redirectURL.post("/master/logErrorsForApiCalls", errDetails)
					console.log("Error ", e)
				})
			setTimeout(() => {
				this.restoreGridStates()
			}, 1000)
	

		}
		catch (e) { }

	};
	selectMovementTypeOptionsItems() {
		let items = [];
		items.push({ value: "all", label: "All" });
		items.push({ value: true, label: "Local Movement" });
		items.push({ value: false, label: "Non Local Movement" });
		return items;
	}

	getTruckConsignments = async (event) => {
		event.preventDefault();
		this.setState({
			loadshow: "show-m",
			overly: "show-m",

		})
		var inputTruckConsignment = $("#inputTruckConsignment").val();

		var reqparams = {
			from_date: this.state.startDate,
			to_date: this.state.endDate
		}
		var trucksArr = []
		await redirectURL.post('/tracking/getConsignmentDetails', reqparams)
			.then(async (response) => {
				trucksArr = response.data;
				if (typeof response.data == 'object') {
					var recordsarr = response.data;
					this.updateStatusCounters(recordsarr)
				}
				else {
					var dd = response.data;
					var recordsarr = JSON.parse(response.data);
				}
				var records = [];
				records = records.sort(GetSortDescOrder("invoice_time"));
				var lastRunTime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
				await this.setState({
					rowData: trucksArr,
					allRowData: trucksArr,
					originalData: trucksArr,
					loadshow: 'show-n',
					overly: 'show-n',
					// rowDataTrucks: recordsarr
				});
			})
			.catch(function (error) {
				// console.log('error met')
				var errDetails = {
					url: "/tracking/intranitRoadMobileData",
					screen: 'Trips',
					error,
				}
				redirectURL.post("/master/logErrorsForApiCalls", errDetails)
				console.log(error);
			});
		let selectedFilter = this.state.selectedFilter
		// console.log("selectedFilter", selectedFilter)
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		// console.log("selFilter",inputTruckConsignment)

		if (selectedFilter == "" || inputTruckConsignment == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true,
				loadshow: "show-n",
				overly: "show-n"
			})
		}
		else {
			if (selectedFilter.value == "Truck") {
				let filteredData = trucksArr.filter(item => item.truck_no.includes(inputTruckConsignment))
				this.updateStatusCounters(filteredData)
				this.setState({
					rowData: filteredData,
					allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n",
					rowDataTrucks: filteredData
				});

			}
			if (selectedFilter == "GR No") {
				let filteredData = trucksArr.filter(item => item.consignment_codes.includes(inputTruckConsignment))
				this.updateStatusCounters(filteredData)
				this.setState({
					rowData: filteredData,
					allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"

				});
			}
			if (selectedFilter == "Invoice") {
				let filteredData = trucksArr.filter(item => item.sales_order.indexOf(inputTruckConsignment) >= 0)
				this.updateStatusCounters(filteredData)
				// console.log("Invoice", filteredData)
				this.setState({
					rowData: filteredData,
					allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});
			}
			if (selectedFilter.value == "shipment_id") {
				let filteredData = trucksArr.filter(item => item.shipment_id.includes(inputTruckConsignment))
				this.updateStatusCounters(filteredData)
				// console.log("Shipment", filteredData)
				this.setState({
					rowData: filteredData,
					allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n",
					rowDataTrucks: filteredData
				});
			}
		}
	}

	reloadPageData = () => {
		window.location.reload();
	}

	handle_base_route = () => {
		this.setState(prevState =>
			 ({ base_route_show: !prevState.base_route_show }))
	}

	updateCounterTabId = tabId => {
		this.setState({activeCounterTab:tabId})
	}

	render() {
		const modalStyles = {
			width: '1300px !important',
		}
		const { open, screen_type } = this.state;
		const { openforceclose } = this.state;

		var maptransporter = '';
		if (this.state.rownode.transporter) {
			maptransporter = this.state.rownode.transporter[0].transporter_name;
		}
		var hideforsales = false;
		var hideData = false;
		var hideForReverse = "";
		var hideForActive = true
		var columnwithDefs = [
			{
				headerName: "",
				field: "_id",
				width: 60,
				pinned: 'left',
				cellRenderer: 'consignmentActions',
				hide: hideForReverse,
				suppressColumnsToolPanel: hideForReverse,
			},
			{
				headerName: "",
				field: "is_gps_working",
				colId: "is_gps_working",
				width: 40,
				pinned: 'left',
				pivot: false,
				cellRendererSelector: function (params) {
					var comp = 'gps';
					if (params.data.no_gps_data_lifetime === 1 ) {
						comp = 'nogps'
					}
					else if (params.data.no_gps_data_lifetime === 0 ) {
						comp = 'gps'
					}
					var rendComponent = {
						component: comp
					};
					return rendComponent
				},
				filter: true, resizable: true
			},

			{
				headerName: "Shipment Id",
				field: "shipment_id",
				width: 140,
				hide: hideData,
				suppressColumnsToolPanel: hideforsales,
			},
			{
				headerName: "Consignment Code",
				field: "consignment_code",
				width: 100
			},
			{
				headerName: "Plant Code",
				field: "consigner_code",
				width: 100
			},
			{
				headerName: "Plant Name",
				field: "consigner_name",
				width: 110
			},
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 100,
				// pinned:"left",
				valueGetter: function (params) {
					if (params.data.truck_no != undefined && params.data.truck_no != "") {
						return params.data.truck_no;
					}
					else {
						return "No Data Available";
					}
				}
			},
			{
				headerName: "Expected vehicle Start Time",
				field: "expected_vehicle_start_time_mins",
				width: 130,
				valueGetter: (params) => {
					if (params.data.expected_vehicle_start_time_mins !== '' && params.data.expected_vehicle_start_time_mins !== undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.expected_vehicle_start_time_mins)
					} else {
						return 'N/A'
					}
				}
			},
			{
				headerName: "Plant Entry Time",
				field: "reached_plant_at",
				width: 130,
				valueGetter: (params) => {
					if (params.data.loading_yard_entry_time !== '' && params.data.loading_yard_entry_time !== undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_entry_time)
					} else {
						return 'N/A'
					}
				}
			},
			{
				headerName: "Plant Exit Time",
				field: "left_plant_at",
				width: 130,
				valueGetter: (params) => {
					if (params.data.loading_yard_exit_time !== '' && params.data.loading_yard_exit_time !== undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time)
					} else {
						return 'N/A'
					}
				}
			},
			{
				headerName: "Loading Time (Mins)",
				field: "loading_time",
				width: 130,
				valueGetter: (params) => {
					if (params.data.loading_time !== '' && params.data.loading_time !== undefined) {
						return (params.data.loading_time)
					} else {
						return 'N/A'
					}
				}
			},
			{
				headerName: "Dealer Code",
				field: "customer_code",
				width: 110,
				valueGetter: (params) => {
					if (params.data.customer_code !== '' && params.data.customer_code !== undefined) {
						return params.data.customer_code
					} else {
						return 'N/A'
					}
				}
			},
			{
				headerName: "RDD Delivery Time",
				field: "rdd_delivery_time",
				width: 130,
				valueGetter: (params) => {
					if (params.data.rdd_delivery_time !== '' && params.data.rdd_delivery_time !== undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.rdd_delivery_time)
					} else {
						return 'N/A'
					}
				}
			},
			{
				headerName: "Planned Delivery Time",
				field: "drop_node_total_time_hrs",
				width: 130,
				valueGetter: (params) => {
					if (params.data.drop_node_total_time_hrs !== '' && params.data.drop_node_total_time_hrs !== undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.drop_node_total_time_hrs)
					} else {
						return 'N/A'
					}
				}
			},
			{
				headerName: "Invoice Date",
				field: "invoice_time",
				width: 130,
				valueGetter: function (params) {
					try {

						if (params.data.invoice_time !== "" && params.data.invoice_time != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
						}
						else {
							return "Not Available";
						}
					}
					catch (e) { }
				},
				comparator: dateComparator,
				hide: hideForReverse,
				suppressColumnsToolPanel: hideForReverse,
			},
			{
				headerName: "Consignment Creation Time",
				field: "shipment_time",
				width: 130,
				valueGetter: function (params) {
					try {

						if (params.data.shipment_time != "" && params.data.shipment_time != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
						}
						else {
							return "Not Available";
						}
					}
					catch (e) { }
				},
			},
			
			{
				headerName: "Transporter Name",
				field: "transporter_name",
				width: 150,
			},
			{
				headerName: "Dealer Code",
				field: "consignee_code",
				width: 110,
				resizable: true,
				filter: true,
				hide: hideData,
				suppressColumnsToolPanel: hideData,
			},
			{
				headerName: "Dealer Name",
				field: "consignee_name",
				width: 120,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Dealer City",
				field: "consignee_city",
				width: 110,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Dealer State",
				field: "consignee_state",
				width: 120,
				valueGetter: function (params) {
					try {
						if (params.data.consignee_state != "" && params.data.consignee_state != undefined) {
							return params.data.consignee_state;
						}
						else {
							return "No Data Available";
						}

					}
					catch (e) { }
				},
				resizable: true,
			},
			{
				headerName: "Shipment Status",
				field: "cd_status",
				width: 110,
				resizable: true,
				filter: true,
				valueGetter:function(params){
					try {

						var status = "Shipment Created"
						if (params.data.status == 1 || params.data.status == "1") {
							status = "Shipment Created";
						}
						
						if (params.data.status == 3 || params.data.status == "3") {
							// status = "Intransit To Customer";
							status = "Inside Plant";
						}
						if (params.data.status == 4 || params.data.status == "4") {
							// status = "Possible Customer Location";
							status = "Inside Loading Yard";
						}
						if (params.data.status == 5 || params.data.status == "5") {
							// status = "Near Customer Location";
							status = "Outside Loading Yard";
						}
						if (params.data.status == 6 || params.data.status == "6") {
							status = "Force Closure";
						}						
						if (params.data.status == 8 || params.data.status == "8") {
							status = "In Transit to Customer";
						}
						if (params.data.status == 9 || params.data.status == "9") {
							status = "Proximity Customer Location";
						}
						if (params.data.status == 10 || params.data.status == "10") {
							status = "Near Customer Vicinity";
						}
						if (params.data.status == 11 || params.data.status == "11") {
							status = "Reached Customer";
						}
						if (params.data.status == 12 || params.data.status == "12") {
							status = "Left Customer";
						}
						if (params.data.status == 13 || params.data.status == "13") {
							status = "Possible Trip Closure";
						}
						if (params.data.status == 14 || params.data.status == "14") {
							status = "Proximity Trip Closure";
						}
						if (params.data.status == 15 || params.data.status == "15") {
							if(params.data.dealer_not_served == 1){
								status ="Dealer Not Served"
							}else{
							status = "Reached Plant";
							}
						}
					
						return status
					}
                    catch(e){}
                }
			},
			{
				headerName: "Reached Customer At",
				field: "locked_dealer_reported",
				width: 130,
				resizable: true,
				filter: true,
				valueGetter: (params) => {
					if (params.data.locked_dealer_reported !== '' && params.data.locked_dealer_reported !== undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.locked_dealer_reported)
					} else {
						return 'N/A'
					}
				}
			},
			{
				headerName: "Left Customer At",
				field: "left_dealer_marked",
				width: 130,
				resizable: true,
				filter: true,
				valueGetter: (params) => {
					if (params.data.left_dealer_marked !== '' && params.data.left_dealer_marked !== undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked)
					} else {
						return 'N/A'
					}
				}
			},
			{
				headerName: "Unloading Time",
				field: "unloading_time",
				width: 110,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Force Closure Time",
				field: "force_closure_time",
				width: 130,
				resizable: true,
				filter: true,
				valueGetter: (params) => {
					if (params.data.force_closure_time !== '' && params.data.force_closure_time !== undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.force_closure_time)
					} else {
						return 'N/A'
					}
				}
			},
			{
				headerName: "Force Closure Reason",
				field: "force_closure_reason",
				width: 110,
				resizable: true,
				filter: true,
			},
			{
				headerName: "Force Closed By",
				field: "force_closure_performed_by",
				width: 110,
				resizable: true,
				filter: true,
			},
		]

		var hText = 'All';
		var filterClassName = "form-group col-xl-12 col-lg-12";
		var filterCardStyle1 = { minHeight: "234px" };
		var filterCardStyle2 = { padding: '10px' };
		var mnFilterClass = { marginLeft: "0px", padding: "0px" };
		if (this.state.deptcode == 'SNDG') {
			hText = ''
			filterClassName = "form-group col-xl-12 col-lg-12";
			filterCardStyle1 = { minHeight: "234px" };
			filterCardStyle2 = { padding: '10px' };
		}

		if (this.state.deptcode == 'LOG-PRT') {
			hText = ''
			mnFilterClass = { marginLeft: "0px" };
		}
		if (this.state.deptcode == 'LOG-TNP') {
			hText = ''
			filterCardStyle1 = { minHeight: "240px" };
		}
		const tripoptoins = [
			{ "value": "0", label: "All" },
			{ "value": "S", label: "Short Haul" },
			{ "value": "L", label: "Long Haul" }
		]
		var baseRouteCoordsData = [];
		if (localStorage.getItem("user_type") == "TECHUSER") {
			baseRouteCoordsData = this.state.baseRouteCoords
		}
		var screenName = "Trips";
		try {
			if (this.state.screen_type != undefined && this.state.screen_type != "") {
				if (this.state.screen_type.label != undefined && this.state.screen_type.label != "") {
					screenName = this.state.screen_type.label;
				}
			}
		} catch (e) {

		}

		let consignmentsData = this.state.rowDataTrucks
		
		let activeCounterTab = this.state.activeCounterTab 

		let filteredConsignmentsData;

		if (activeCounterTab === 0){
			filteredConsignmentsData = consignmentsData
		}
		else if (activeCounterTab === 3 ){
			filteredConsignmentsData = consignmentsData.filter(each => 
				each.status === 3 || each.status === 4)
		}
		else if ( activeCounterTab === ""){
			filteredConsignmentsData = consignmentsData.filter(each =>
				each.status === "" || each.status === undefined || each.status === null)
		}
		else {
			filteredConsignmentsData = consignmentsData.filter(each => 
				each.status === activeCounterTab )
		}

		return (
			<>

				<div className="container-fluid">
					<SweetAlert
						show={this.state.show}
						type={this.state.basicType}
						title={this.state.basicTitle}
						onConfirm={this.closeAlert}
					>
					</SweetAlert>
					{this.state.alert}
					<div className="row">
						<div className="col-xl-4 col-lg-4">
							<div className="card card1" style={filterCardStyle1}>
								<div className="row card-body" style={{ padding: "10px 30px" }}>
									<form className="row" onSubmit={this.getTruckConsignments}>
										<div className={filterClassName}>
											<label>Select Type :  </label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedFilter"
												style={{ borderRadius: "0px" }}
												options={this.state.filterOptions}												
												onChange={this.newSelector.bind(this, "selectedFilter")}
												placeholder={'Select'}
												value={this.state.selectedFilter}

												required />
										</div>
										<div className={filterClassName}>
											<label className="reason">{this.state.filterNames}</label>
											<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
										</div>
										<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left" }}>
											<button type="submit" className="btn btn-success">Submit</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-xl-8 col-lg-8">
							<div className="card card2" style={filterCardStyle1}>
								<div className="row card-body" style={filterCardStyle2}>
									<div className="row col-xl-12 col-lg-12" style={mnFilterClass}>
										{(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined || localStorage.getItem("roles") == "CUSTOMER") ?
											"" :
											<div className="form-group col-xl-5 col-lg-3 ">
												<label>Select Plant: </label>
												<Select
													maxHeight={200}
													placeholder={"All"} closeMenuOnSelect={true}
													value={this.state.consigner} isMulti="true"
													className="border-radius-0"
													onChange={this.changeConsignerArrayHandler}
													style={{ borderRadius: "0px" }}
													options={this.selectConsignerOptionsItems()} required />

											</div>
										}
										<div className="col-xl-3 col-lg-3 form-group">
											<label>Invoice From Date</label>
											<Datetime
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ name: 'startDate', autoComplete: 'off', readOnly: 'true' }}
												style={{ color: "#000" }}
												dateFormat="YYYY-MM-DD"
												name="startDate"

												timeFormat={false}
												value={this.state.startDate}
												placeholder={moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD')}
												disableTimePicker={true}
												onChange={this.handlerStartDateTime} />
										</div>

										<div className="col-xl-3 col-lg-3 form-group">
											<label>Invoice To Date</label>
											<Datetime
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ name: 'endDate', autoComplete: 'off', readOnly: 'true' }}
												dateFormat="YYYY-MM-DD"
												timeFormat={false}
												value={this.state.endDate}
												placeholder={moment.parseZone().format('YYYY-MM-DD')}
												onChange={this.handlerEndDateTime} />
										</div>
									</div>

									<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
										{(localStorage.getItem("roles") != "CUSTOMER") ?
											<div className="form-group col-xl-5 col-lg-4">
												<label>Dealer Centers :  </label>
												<Select
													maxHeight={200}
													className="border-radius-0"
													closeMenuOnSelect={true}
													isMulti={true}
													id="selectedCustomer"
													style={{ borderRadius: "0px" }}
													value={this.state.flCustomer}
													onChange={this.newFilter.bind(this, "flCustomer")}
													options={this.state.filterCustomers}
												/>
											</div>
											: ""}

										{(localStorage.getItem("roles") != "CUSTOMER") ?
											<div className="form-group col-xl-3 col-lg-3">
												<label>Dispatch Centers City :  </label>
												<Select
													maxHeight={200}
													className="border-radius-0"
													isMulti={false}
													id="selectedCity"
													style={{ borderRadius: "0px" }}
													value={this.state.flCity}
													onChange={this.newFilter.bind(this, "flCity")}
													options={this.state.filterCities}
												/>
											</div>
											: ""}

										{(localStorage.getItem("transportercode") != "undefined" && localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != "" || localStorage.getItem("roles") == "CUSTOMER") ? "" :
											<div className="form-group col-xl-3 col-lg-3">
												<label>Transporter :  </label>
												<Select
													maxHeight={200}
													className="border-radius-0"
													isMulti={false}
													id="selectedTransporter"
													style={{ borderRadius: "0px" }}
													value={this.state.flTransporter}
													onChange={this.newFilter.bind(this, "flTransporter")}
													options={this.state.filterTransporters}
												/>
											</div>
										}
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
										<button type="button" className="btn btn-success" onClick={this.onGetConsigners}>Submit</button>
									</div>
								</div>
							</div>
						</div>

					</div>
					{/*  :""} */}
					{(localStorage.getItem('roles') != "CUSTOMER") ?
					  
						<div className="card mt-3">
						<div className="card-body m-0 p-0">
						  <div className="crm-numbers m-0 pb-0">
							<div className="row m-0 p-0 ">
							  <div className="col cursorPointer m-0 pt-3 pb-3"  onClick={()=>{this.updateCounterTabId(1)}}
							  style={ {border : this.state.activeCounterTab === 1 && "3px solid #619fb0" } }>
								<span className="f13">
								  <RxCodesandboxLogo  style={{color:"green", fontSize:"24px"}}/>
								  <br /> Shipment Created 
								</span>
								<h4 className="f30" style={{color:"green" }}>
								  <span className="counter">
									{this.state.shipmentCreatedCount}
								  </span>
								</h4>
							  </div>
  
							  <div className="col cursorPointer  m-0 pt-3 pb-3" onClick={()=>{this.updateCounterTabId(3)}}
							   style={ {border : this.state.activeCounterTab === 3 && "3px solid #619fb0"} }>
								<span className="f13">
								  <BsBuildingsFill style={{color: "black", fontSize:"24px"}} />
								  <br /> Inside Plant
								</span>
								<h4 className=" black f30">
								  <span className="counter">
									{this.state.insidePlantCount}
								  </span>
								</h4>
							  </div>
							  
							  <div className="col cursorPointer  m-0 pt-3 pb-3" onClick={()=>{this.updateCounterTabId(8)}}
							   style={ {border : this.state.activeCounterTab === 8 && "3px solid #619fb0" }} >
								<span className="f13">
								  <i className="fa fa-road  f24 txt-dark" style={{ fontSize: '24px' }}></i>
								  <br /> In Transit to Customer
								</span>
								<h4 className=" txt-dark f30">
								  <span className="counter">
									{this.state.inTransitToCustomerCount}
								  </span>
								</h4>
							  </div>
							  <div className="col cursorPointer  m-0 pt-3 pb-3" onClick={()=>{this.updateCounterTabId(11)}}
							   style={ {border : this.state.activeCounterTab === 11 &&  "3px solid #619fb0"} }>
								<span className="f13">						
								  <GrUserManager style={{color:"#b58e24", fontSize:"24px"}} />
								  <br /> Reached Customer
								</span>
								<h4 className="f30" style={{color:"#b58e24"}}>
								  <span className="counter">
									{this.state.reachedCustomerCount}
								  </span>
								</h4>
							  </div>
							  <div className="col cursorPointer  m-0 pt-3 pb-3" onClick={()=>{this.updateCounterTabId(12)}}
							   style={ {border : this.state.activeCounterTab === 12 &&  "3px solid #619fb0"} }>
								<span className="f13">								  
								  <FaTruckMoving style={{color: "darkgreen", fontSize:"24px"}}/>
								  <br /> Left Customer
								</span>
								<h4 className="f30" style={{color:"green"}}>
								  <span className="counter">
									{this.state.leftCustomerCount}
								  </span>
								</h4>
							  </div>
							  <div className="col cursorPointer  m-0 pt-3 pb-3" onClick={()=>{this.updateCounterTabId(15)}}
							   style={ {border : this.state.activeCounterTab === 15 &&  "3px solid #619fb0"} }>
								<span className="f13">								  
								  <TbUserX style={{color: "#fa4d53",fontSize:"24px"}} />
								  <br /> Dealer Not Served
								</span>
								<h4 className=" txt-danger f30">
								  <span className="counter">
									{this.state.dealerNotServedCount}
								  </span>
								</h4>
							  </div>
							  <div className="col cursorPointer  m-0 pt-3 pb-3" onClick={()=>{this.updateCounterTabId("")}}
							   style={ {border : this.state.activeCounterTab === "" &&  "3px solid #619fb0"} }>
								<span className="f13">
								  <BiSolidFactory  style={{color: "green", fontSize:"24px"}} />
								  <br /> Reached Plant
								</span>
								<h4 className="f30" style={{color:"green"}}>
								  <span className="counter">
									{this.state.reachedPlantCount}
								  </span>
								</h4>
							  </div>
							  <div className="col cursorPointer  m-0 pt-3 pb-3" onClick={()=>{this.updateCounterTabId(6)}}
							   style={ {border : this.state.activeCounterTab === 6 &&  "3px solid #619fb0"} } >
								<span className="f13">
								  <IoMdCloseCircle style={{color: "#fa4d53", fontSize:"24px"}} />
								  <br /> Force Closure
								</span>
								<h4 className="f30" style={{color:"#fa4d53"}}>
								  <span className="counter">
									{this.state.forceClosureCount}
								  </span>
								</h4>
							  </div>
							  
							  
							</div>
						  </div>
						</div>
						</div> 
					  :
						""
					}
					<div className="row mt-20p">
						<div className="col-xl-12 col-lg-12">
							<div className="card">
								<div className="card-header mt-0 mb-0 pt-0 pb-0">
									<h5>
										<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> Consignments </span>

									</h5>
								</div>
								<div className="card-body pt-10px" >
									<div className="row">
										<div className="col-xl-12 col-lg-12 mb-10p">
											<span className="" style={{ fontSize: "13px", fontWeight: "100" }}>
												<img style={{ height: "18px" }} src={require("../../assets/icons/distance.png")} /> - View Route
												&nbsp;
												&nbsp;<i className="fa fa-map-marker txt-danger" style={{ fontSize: "22px", borderLeft:"1px solid black",paddingLeft:"8px" }}></i> - GPS Data Not Available
												&nbsp;<i className="fa fa-map-marker greenfont" style={{ fontSize: "22px", borderLeft:"1px solid black",paddingLeft:"8px" }}></i> - GPS Data Available
											</span>
											<span className="layoutbtns">
												<button className="float-right custom-btn white btn-danger" style={{ marginRight: "10px" }} onClick={this.onClickSaveGridState.bind(this)}>
													<i className="icofont icofont-save"></i> Save Grid Layout
												</button>
												<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
													<i className="icofont icofont-refresh"></i> Reset Default Layout
												</button>
											</span>
										</div>
									</div>

									<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
										<AgGridReact
											columnDefs={columnwithDefs}
											defaultColDef={this.state.defaultColDef}
											rowData={filteredConsignmentsData}
											enableCharts={true}
											enableRangeSelection={true}
											autoGroupColumnDef={this.state.autoGroupColumnDef}
											onGridReady={this.onGridReady}
											onGridState={this.onGridState}
											statusBar={this.state.statusBar}
											paginationPageSize={this.state.paginationPageSize}
											pagination={true}
											frameworkComponents={this.state.frameworkComponents}
											detailCellRendererParams={this.state.detailCellRendererParams}
											stopEditingWhenGridLosesFocus={true}
											enableCellChangeFlash={true}
											suppressCellFlash={true}
											rowClassRules={this.state.rowClassRules}
											gridOptions={{
												context: { componentParent: this }
											}}
											masterDetail={true}
											onRowSelected={this.onRowSelection.bind(this)}
										/>
									</div>
								</div>
							</div>
						</div>

					</div>


					{this.state.sliderRouteTranslate != '' ?
						<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }} >
							<div className="slide-r-title">
								<h4>
									{(this.state.consignment_code)}
									<span className="float-right closebtn" style={{ marginRight: "25px", cursor: "pointer" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

								</h4>
							</div>
							<div className="slide-r-body" style={{ position: "relative" }}>
								<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
									<DrawMap
										mapFor={"truck"}
										consignercords={this.state.consigner_coordinates}
										tolls={this.state.tolls}
										consigneecords={this.state.consignee_coordinates}
										baseRouteCoords={this.state.baseRouteCoords}
										rownode={this.state.rownode}
										truckno={this.state.maptruckno}
										dealer={this.state.dealer}
										mapinfo={this.state.mapinfo}
										defTransitCoords={this.state.defTransitCoords}
										base_route_show={this.state.base_route_show}
										handle_base_route={this.handle_base_route} />
									<div className="col-xl-12 col-lg-12 n-p-0">
										<div className="crm-numbers pb-0">
											<h3 className="subH">{this.state.sidebarSubHeader}</h3>
											<div className="col-xl-12 col-lg-12 row">
												<div className="col route-block">
													<label className="sidebar-label">Truck No</label>
													<div>{this.state.routeTruck.truck_no}</div>
												</div>
												<div className="col route-block">
													<label className="sidebar-label">GPS Start Time</label>
													<div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
												</div>
												<div className="col route-block">
													<label className="sidebar-label">GPS End Time</label>
													<div>{(this.state.routeTruck.endTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime) : "NA"}</div>
												</div>

												<div className="col route-block">
													<label className="sidebar-label">Distance</label>
													<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
												</div>

												<div className="col route-block">
													<label className="sidebar-label">Avg Temparature</label>
													<div>{(!isEmpty(this.state.mapinfo.route_details)) ? (this.state.mapinfo.route_details.average_temperature).toFixed(2) : "0"} °C</div>
												</div>x

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						: ""
					}
					<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} classNames={{ overlay: 'customOverlay', modal: 'customModal' }}>
						<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
							{(this.state.maprowData != '') ?
								<ConsignmentDrawMap
									context={this} griddata={this.state.maprowData}
									consigner_coordinates={this.state.consigner_coordinates}
									consigneecoords={this.state.consigneecoords}
									rownode={this.state.rownode}
									mapFor={"consignment"} />
								: ""}

						</div>
					</Modal>

					<div className={"dataLoadpage " + (this.state.loadshow)}>
					</div>
					<div className={"dataLoadpageimg " + (this.state.loadshow)}>
						<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
					</div>
					<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
					<div className={"slide-r " + (this.state.slideBar)} >
						{this.displayData}
					</div>

					<div className={"overlay-part " + (this.state.overlyRing)} onClick={this.hideSlideBlock.bind(this)}></div>
				</div>
				<div className={"loader " + this.state.loadshow}></div>
			</>

		);
	}
}
function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}

function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber, monthNumber, dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}



function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}
function GetSortASCOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function secondsToDhms(seconds) {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor(seconds % (3600 * 24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);

	var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
	var result = arr.reduce(function (arr1, e1) {
		var matches = arr1.filter(function (e2) {
			return e1[propName] == e2[propName];
		})
		if (matches.length == 0)
			arr1.push(e1)
		return arr1;
	}, []);

	return result;
}
