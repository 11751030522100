import React,{Component} from 'react';
import Constant from '../../common/constants';
import CSVFileValidator from 'csv-file-validator';
import 'react-responsive-modal/styles.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Select from 'react-select';
import redirectURL from '../../redirectURL';
import GridButton from '../gridButton';
import NAButton from '../naButton';

export default class CompareActualData extends Component{

    constructor(props){
        super(props);
    this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: false,
            resizable:true,
            minWidth:100,
            initialWidth:100,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
            frameworkComponents:{
                GridButton:GridButton,
                NAButton:NAButton
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
        showSlideBlockPickupDrop:"",
        showloader:"show-n",
        csvfiledata:[],
        openPickupDropLocationsModel:false,
        show:false,
        basicTitle:"",
        basicType:"default",
        overlayBlock:"show-n",
        rowData:[],
        locationNames:[],
        location:'',
        executionDates:[],
        execution_date:'',
        loadshow:'show-m',
    }
}

componentDidMount = async() =>{
    var curl = window.location.search;
    var ul = curl.split("?");
    let url_type = ul[parseInt(ul.length) - 1];
    var transactionId = url_type;
    let orderId;
    if (
      transactionId != undefined &&
      transactionId != "undefined" &&
      transactionId != ""
    ) {
      transactionId = window.atob(transactionId);
    }
    
    await this.getComparisionData(transactionId);
}

formCSVUpload = (event) =>
    {
    //   let data_type = this.state.data_type;
      event.preventDefault();
      var csvfiledata = this.state.csvfiledata;
      // console.log(csvfiledata,"csvfiledata");
    //   csvfiledata.map((item) => {
    //     if (item.vehicle_list === "") {
    //       item.vehicle_list = "[\"None\"]";
    //     }
    //     if (item.week_off === "") {
    //       item.week_off = "[\"None\"]";
    //     }
    //   });
      var params = {
        filedata: csvfiledata,
        // data_type: data_type,
        user: localStorage.getItem("username"),
        mail: localStorage.getItem("email"),
      };
      // console.log(params, "csv file data")
      redirectURL.post("/actualdatafo/insertactualdatafo",params)
      .then(async(resp) => {
        
        if(resp.data.status == 'success'){
          await this.setState({
              show:true,
              basicTitle:resp.data.message,
              basicType:"success",
              overlayBlock:"show-n",
              showSlideBlockPickupDrop:"",
          })
        //   window.location.reload();
          
        }else{
          this.setState({
              show:true,
              basicTitle:resp.data.message,
              basicType:"danger"
          })
        }
      })
      .catch(function (error) {
          var errDetails={
              url:"/master/uploadData",
              screen:'adherence screen Locations',
            error,
            }
          redirectURL.post("/master/logErrorsForApiCalls",errDetails)
          
      })
  
    }


    changeCSVFileHandler = async (e) => {
      const config = {
          headers: [
              { 
                  name: 'Assigned FO',
                  inputName: 'fo_number',
                  required: true,
                  unique: true,
                  uniqueError: function (headerName) {
                  return `${headerName} is not unique`
                  },
                  requiredError: function (headerName, rowNumber, columnNumber) {
                      return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  }
              },
              { 
                  name: 'ERP delivery number',
                  inputName: 'order_number',
                  required: true,
                //   unique: true,
                //   uniqueError: function (headerName) {
                //       return `${headerName} is not unique`
                //   },
                  requiredError: function (headerName, rowNumber, columnNumber) {
                      return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  }
              },
              { 
                  name: 'Source Location Name',
                  inputName: 'plant_name',
                  // required: true,
                  // requiredError: function (headerName, rowNumber, columnNumber) {
                  //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  // }
              },
              { 
                  name: 'Dest. Location City',
                  inputName: 'location_city',
                //   required: true,
                //   requiredError: function (headerName, rowNumber, columnNumber) {
                //       return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //   }
               },
     
              { 
                  name: 'Consignee',
                  inputName: 'location_code',
                  required: true,
                  requiredError: function (headerName, rowNumber, columnNumber) {
                      return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  }
              },
              { 
                  name: 'Customer Name',
                  inputName: 'location_name',
                  // required: true,
                  // requiredError: function (headerName, rowNumber, columnNumber) {
                  //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  // }
              },
              { 
                  name: 'Quantity',
                  inputName: 'quantity',
                  required: true,
                  requiredError: function (headerName, rowNumber, columnNumber) {
                      return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  }
              },
              { 
                  name: 'Gross Weight (VU)',
                  inputName: 'weight',
                  required: true,
                  requiredError: function (headerName, rowNumber, columnNumber) {
                      return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  }
              },
              { 
                  name: 'Gross Volume (VU)',
                  inputName: 'volume',
                  required: true,
                  requiredError: function (headerName, rowNumber, columnNumber) {
                      return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  }
              },
              { 
                  name: 'Carrier',
                  inputName: 'transporter_code',
                  // required: true,
                  // requiredError: function (headerName, rowNumber, columnNumber) {
                  //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  // }
              },
              { 
                  name: 'Carrier description',
                  inputName: 'transporter_name',
                  // required: true,
                  // requiredError: function (headerName, rowNumber, columnNumber) {
                  //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  // }
              },
              { 
                  name: 'Means of Transport',
                  inputName: 'means_of_transport',
                  // required: true,
                  // requiredError: function (headerName, rowNumber, columnNumber) {
                  //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  // }
              },
              { 
                  name: 'Registration Number',
                  inputName: 'vehicle_no',
                  required: true,
                  requiredError: function (headerName, rowNumber, columnNumber) {
                      return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  }
              },
              { 
                  name: 'Vehicle Type',
                  inputName: 'vehicle_type',
                  required: true,
                  requiredError: function (headerName, rowNumber, columnNumber) {
                      return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                  }
              },
            //   { 
            //       name: 'Location City',
            //       inputName: 'location_city',
            //       // required: true,
            //       // requiredError: function (headerName, rowNumber, columnNumber) {
            //       //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            //       // }
            //   },
            //   { 
            //       name: 'Dealer Type',
            //       inputName: 'dealer_type',
            //       // required: true,
            //       // requiredError: function (headerName, rowNumber, columnNumber) {
            //       //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            //       // }
            //   },
              // { 
              //     name: 'Location Address',
              //     inputName: 'location_address',
              //     required: true,
              //     requiredError: function (headerName, rowNumber, columnNumber) {
              //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
              //     }
              // },
              // { 
              //     name: 'From Time',
              //     inputName: 'from_time',
              //     required: false,
              // },
              // { 
              //     name: 'To Time',
              //     inputName: 'to_time',
              //     required: false,
              // }
          ]
      }
      var data = CSVFileValidator(e.target.files[0], config)
      .then((csvData) => {

          document.getElementById("inValidDataInfo").innerHTML = "";
          if(csvData.inValidData.length >0){
              document.getElementById("bulkUploadBtn").type = "button"
              document.getElementById("bulkUploadBtn").classList.remove("btn-danger");
              document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
              let invalidData = csvData.inValidData;
              let element = document.getElementById("inValidDataInfo")
              invalidData.map(item =>{
                  let row,column
                  if(item.rowIndex == undefined){
                      row = 'NA'
                  }else{
                      row = item.rowIndex
                  }
                  if(item.columnIndex == undefined){
                      column = 'NA'
                  }else{
                      column = item.columnIndex
                  }
                  
                  element.innerHTML += "Column : "+column+","+" Row : " + row +"--"+ item.message +"<br></br>"
                  return '';
              })
               
          }else{
              document.getElementById("bulkUploadBtn").type = "submit"
              document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
              document.getElementById("bulkUploadBtn").classList.add("btn-danger");
              this.setState({
                  csvfiledata:csvData.data
              });
          }
      })
      .catch(err => {})
          
      
  }

    openUploadOptionForPickupDrop = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            showSlideBlockPickupDrop:"slide25",
            overlayBlock:"show-m"
          })
    }

    hideSlideBlock = () =>{
        document.getElementById("bulkUploadForm").reset()
        document.getElementById("inValidDataInfo").innerHTML = "";
        this.setState({
            overlayBlock:"show-n",
            showSlideBlockPickupDrop:"",
        })
      }

      getComparisionData = async (transactionId) =>{
        this.setState({loadshow:'show-m',})
        let locationNames = [{label:'ALL',value:''}]
        let executionDates = [{label:'ALL',value:''}]
        await redirectURL.post("actualdatafo/getComparisionData",{fodatauniqueid:transactionId})
        .then(async res=>{
            if(res.data.fo_summary){
              // console.log(res.data,'datat')
              let boundLocations = res.data.fo_summary
              let counterdata = res.data.fo_counter_data
              if(boundLocations.length > 0){
                boundLocations.forEach(item =>{
                  
                    if(!locationNames.some(location => location.label === item.plant_name)){
                        locationNames.push({ value : item.plant_name,
                                            label : item.plant_name })
                        }
                    if(!executionDates.some(date => date.label === item.execution_date)){
                      executionDates.push({ value : item.execution_date,
                                          label : item.execution_date })
                      }
                })
              }
                
                if(locationNames.length>0){
                  await this.setState({
                              locationNames : locationNames,
                              location : locationNames[0],
                              executionDates : executionDates,
                              execution_date : executionDates[0],
                              rowData:res.data.fo_summary,
                              loadshow:'show-n'
                          })
                }
            }else{
              this.setState({loadshow:'show-n'})
              alert('Something Went Wrong...')
            }
        })

      }

      redirectToPlan = (propdata) => {
        // var data = propdata.data;
        // console.log("here get details", propdata.data);
          if (propdata.colDef.field === "transaction_details") {
            // let data_type = this.state.data_type;
            // let location = this.state.location.value;
            // let qry = {
            //   dispatch_planning_type: data_type,
            //   plant_location_code: location,
            // };
            // localStorage.setItem("qry", JSON.stringify(qry));
    
            var data = propdata.data;
            let basestring = data.transaction_id;
            let encryptedstring = window.btoa(basestring);
            // window.location.href = '/viewdispatchplandetails?'+encryptedstring;
            window.open(
              window.location.origin +
                "/viewdispatchplandetails?" +
                encryptedstring,
              "_blank"
            );
            let logParams = {
            //   location_code: this.state.location.value,
            //   location_name: this.state.location.label,
              user_name: localStorage.getItem("username"),
              useremail: localStorage.getItem("email"),
              client: localStorage.getItem("client"),
              screen: "Adherence Analysis",
              activity: "clicked on View Details Button",
              event: `Redirected to view dispatch plan page for ${encryptedstring} id `,
            //   data_type: data_type,
            };
            redirectURL.post("/master/loguserUpdatesorChanges", logParams);
          }
      };

      locationChanged = (location) =>{
        this.setState({
            location : location
        })
        let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "FO Screen ",
            "activity" : `Clicked on Plant Dropdown`,
            "event" : `Selected ${this.state.location.label} Plant`,
            "data_type" : this.state.data_type,
        }        
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }


    handleExecutionDate = (val) =>{
      this.setState({
        execution_date:val
      })
    }
      
        render(){

          let data = this.state.rowData;
          let locValue = this.state.location.value;
          let datefilter = this.state.execution_date;
          let  filteredData;
          if(!locValue){
              filteredData = this.state.rowData
          }  else{
              filteredData = data.filter(item => item.plant_name == locValue)
          }

          if (datefilter.value != '') {
            filteredData = filteredData.filter(item => item.execution_date == datefilter.value);
        }

            var colsDefs=[
                
              { headerName: "Update Data", field: "transaction_details", colId: "transaction_details",pinned:'left',
                params:{buttonName:'Edit', onClickFunction:this.redirectToPlan,page:'adherenceedit'},
                cellRendererSelector:function(params){
                    if(params.data.is_qty_same === 0 || params.data.is_transaction_id_missmatch === 1 || params.data.is_trip_no_missmatch === 1 ||
                        params.data.is_vehicle_same === 0 || params.data.is_volume_same === 0 || params.data.is_weight_same === 0) {
                            var rendComponent = {
                                component: "GridButton",
                                };
                                return rendComponent
                        }else{
                           var rendComponent = {
                                component: "GridButton",
                              };
                              return rendComponent;
                        }
                }
               },
               {
                headerName: "Actual Data",
                headerClass: ["cellTopHeaderTemplate16","cellTopHeader","cellstylegrid"],
                children: [
               { headerName: "FO Number",headerClass: ["cellstylegrid"], field: "fo_number",colId: "fo_number",pinned:'left' },
               { headerName: "Order Number",headerClass: ["cellstylegrid"],field: "order_number", colId: "order_number",pinned:'left' },
                ]
              },
              {
                // headerName: "Actual Data",
                headerClass: ["cellTopHeaderTemplate16","cellTopHeader","cellstylegrid"],
                children: [
                    // { headerName: "FO Number",headerClass: ["cellstylegrid"], field: "fo_number",colId: "fo_number",pinned:'left' },
                    // { headerName: "Order Number",headerClass: ["cellstylegrid"],field: "order_number", colId: "order_number",pinned:'left' },
                    { headerName: "Plant Name",headerClass: ["cellstylegrid"],field: "plant_name", colId: "plant_name" },
                    { headerName: "Quantity",headerClass: ["cellstylegrid"],field: "quantity", colId: "quantity" },
                    { headerName: "Weight",headerClass: ["cellstylegrid"],field: "weight", colId: "weight" },
                    { headerName: "Volume",headerClass: ["cellstylegrid"],field: "volume", colId: "volume" },
                    { headerName: "Vehicle No",headerClass: ["cellstylegrid"],field: "vehicle_no", colId: "vehicle_no" },
                    { headerName: "Vehicle Type",headerClass: ["cellstylegrid"],field: "vehicle_type", colId: "vehicle_type" },
                    { headerName: "City",headerClass: ["cellstylegrid"],field: "location_city", colId: "location_city" },
                    { headerName: "Dealer Code",headerClass: ["cellstylegrid"],field: "location_code", colId: "location_code" },
                    { headerName: "Dealer Name",headerClass: ["cellstylegrid"],field: "location_name", colId: "location_name" },
                    { headerName: "Carrier",headerClass: ["cellstylegrid"],field: "transporter_code", colId: "transporter_code" },
                    { headerName: "Transporter Name",headerClass: ["cellstylegrid"],field: "transporter_name", colId: "transporter_name" },
                    { headerName: "Means Of Transport",headerClass: ["cellstylegrid"],field: "means_of_transport", colId: "means_of_transport" },
                    { headerName: "Execution Date",headerClass: ["cellstylegrid"],field: "execution_date", colId: "execution_date" },
                ],
              },
              {
                headerName: "Planned Data",
                headerClass: ["cellTopHeaderTemplate16","cellTopHeader","cellstylegridG"],
                children: [
                    { headerName: "Transaction Id",headerClass: ["cellstylegridG"],field: "transaction_id", colId: "transaction_id",width:'180',
                        cellClass:function(params){
                            try {
                                if(params.data.is_transaction_id_missmatch != undefined ){
                                  if(params.data.is_transaction_id_missmatch == 1 || params.data.is_transaction_id_missmatch == ''){
                                    return "redbg";
                                  }
                                }
                            } catch (error) {
                                
                            }
                            }
                     },
                    { headerName: "Trip No",headerClass: ["cellstylegridG"],field: "trip_no", colId: "trip_no",
                        cellClass:function(params){
                            try {
                                if(params.data.is_trip_no_missmatch != undefined ){
                                  if(params.data.is_trip_no_missmatch == 1 || params.data.is_trip_no_missmatch == ''){
                                    return "redbg";
                                  }
                                }
                            } catch (error) {
                                
                            }
                            }
                     },
                //   { headerName: "Order Number",field: "planned_order_number", colId: "planned_order_number" },
                //   { headerName: "Plant Code",field: "planned_pickup_node", colId: "planned_pickup_node" },
                //   { headerName: "Drop Location Code (Destination)",field: "planned_drop_node", colId: "planned_drop_node" },
                //   { headerName: "Drop Location Name (Destination)", field: "planned_drop_node_name", colId: "planned_drop_node_name" },
                  { headerName:"Quantity",headerClass: ["cellstylegridG"], field: "planned_quantity",field: "planned_quantity", colId: "planned_quantity",
                    cellClass:function(params){
                    try {
                        if(params.data.is_qty_same != undefined ){
                          if(params.data.is_qty_same == 0){
                            return "redbg";
                          }
                        }
                    } catch (error) {
                        
                    }
                    }
                  },
                  { headerName:"Weight",headerClass: ["cellstylegridG"], field: "planned_weight", colId: "planned_weight",
                    cellClass:function(params){
                        try {
                            if(params.data.is_weight_same != undefined ){
                              if(params.data.is_weight_same == 0){
                                return "redbg";
                              }
                            }
                        } catch (error) {
                            
                        }
                        }
                   },
                  { headerName:"Volume",headerClass: ["cellstylegridG"], field: "planned_volume", colId: "planned_volume",
                    cellClass:function(params){
                        try {
                            if(params.data.is_volume_same != undefined ){
                              if(params.data.is_volume_same == 0){
                                return "redbg";
                              }
                            }
                        } catch (error) {
                            
                        }
                        }
                   },
                //   { headerName:"Transporter Code", field: "planned_transporter_code", colId: "planned_transporter_code" },
                //   { headerName:"Transporter Name", field: "planned_transporter_name", colId: "planned_transporter_name" },
                  { headerName:"Vehicle No",headerClass: ["cellstylegridG"], field: "planned_vehicle_no", colId: "planned_vehicle_no",
                    cellClass:function(params){
                        try {
                            if(params.data.is_vehicle_same != undefined ){
                              if(params.data.is_vehicle_same == 0){
                                return "redbg";
                              }
                            }
                        } catch (error) {
                            
                        }
                        }
                   },
                //   { headerName:"Vehicle Type", field: "planned_vehicle_type", colId: "planned_vehicle_type" },
                ],
              },
            ];

            var templatePath = require('../../../assets/json/fo_input_data_template.csv');


            return(
                <>
                <div className="row mb-5p ">
                    <div className="col-sm-12 pt-5p">
                        <h5 className='fbold '>
                            <span> Adherence Analysis</span>
                            {/* <div className='float-right col-sm-3' style={{marginTop:'30px'}}>
                                
                                <span className={"float-right "}>
                                <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">Upload FO Data</a>
                                </span>
                            </div> */}
                        </h5>
                        <div className="col-md-2 col-sm-12">
                            <div className="form-group ">
                                <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                <Select
                                placeholder={"Select"}
                                onChange={this.locationChanged}
                                name="location"
                                value={this.state.location}
                                options={this.state.locationNames} />
                            </div>
                        </div>

                        <div className="col-md-2 col-sm-12">
                            <div className="form-group ">
                                <label className="col-form-label f12">Execution Date</label>
                                <Select
                                placeholder={"Select"}
                                onChange={this.handleExecutionDate}
                                name="location"
                                value={this.state.execution_date}
                                options={this.state.executionDates} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockPickupDrop)}>
                {/* <h5 className="crd-bg p-10p">{Constant.DEALER_FORM_UPLOAD_PARTS}</h5> */}
                <div className="row">   
                    <div className="col-sm-12">
                    <form id="bulkUploadForm" onSubmit={this.formCSVUpload}>
                    <div className="row p-20p">
                        <div className="form-group col-sm-12">
                                        <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>                            
                        </div>
                        <div className="form-group col-sm-12 mb-20p">
                            {/* <label style={{color:"#000"}}>{Constant.DEALER_FORM_UPLOAD_FILE_XLS} <span className="rclr fbold">*</span></label> */}
                            
                            <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                        </div>
                        <div className="form-group col-sm-12 mb-20p">
                        <button type="submit" id="bulkUploadBtn" className="btn btn-danger">SAVE</button>
                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                        </div>
                        <div id="inValidDataInfo" style={{color:"red"}}></div>
                    </div>
                    </form>
                    </div>
                </div>
                </div>


                        <div className="col-sm-12 d-inline">
                            <div id="myGrid" style={{width:"100%",height:"70vh",marginTop:"2em"}} className="ag-theme-balham">
                            <AgGridReact
                                // modules={this.state.modules} 
                                rowData={filteredData}
                                columnDefs={colsDefs}
                                gridOptions={{context:{componentParent:this}}}
                                defaultColDef={this.state.defaultColDef}
                                frameworkComponents={this.state.frameworkComponents}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                enableRangeSelection = {true}
                                enableCellContextMenu={true}
                                statusBar={this.state.statusBar}
                                sideBar = {this.state.sideBar}
                                // enablePivot = {true}
                            />
                            </div>
                        </div>

                        <div className={"loader " + this.state.loadshow}></div>
                </>
            )
            
        }
}