import React, { Component } from 'react';


import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export default class ColumnChartLoadingDashboard extends Component {

    render() {
        //console.log("this.props.code) ", this.props.code)
        const dStyles = {
            width: '100%',
            height: '100%'
        }
        const highchartoptions = {
            chart: {
                type: 'column',
                height: "100%"
            },
            //   plotOptions: {
            //       column: {
            //           colorByPoint: true
            //       },
            //       series: {
            //     	maxPointWidth: 20
            //       }
            //   },
            plotOptions: {
                pointWidth: "40px",
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                },
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    },
                    cursor: 'pointer',
                    point: {

                    }
                }
            },

            title: {
                text: this.props.title
            },
            subtitle: {
                text: this.props.subtitle
            },

            xAxis: {
                categories: this.props.categories,
                title: {
                    text: null
                },
                labels: {
                    enabled: true,//default is true
                    //y : 20, rotation: -45, align: 'right' 
                }

            },
            yAxis: {
                min: 0,
                title: {
                    text: this.props.yaxistitle
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} Hrs</b><br/>'
            },
            series: this.props.series,
            //colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            // colors:['#00f0b5', '#fff493', '#f88fa2'],
            colors: ['#f35e82', '#7f84e9', '#f3d800', '#FF5370'],
            credits: false,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: '100%'
                    },
                    chartOptions: {
                        chart: {
                            height: '100%'
                        },
                        subtitle: {
                            text: null
                        },
                        navigator: {
                            enabled: false
                        }
                    }
                }]
            },
            getTransportData: function (params) {
                // console.log("Tras", params)
            }
        };

        return (
            <div>
                <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

            </div>
        );
    }

}
