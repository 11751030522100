import React, {Component} from 'react';
import PowerBIReport from "./powerBI";
import HTMLCSVApp from "./html-csv";
import Select from 'react-select';

export default class KpiPowerBi extends Component{

    constructor(props){
        super(props);
        this.state={
            allKpisTab:'activet',
            contractualKpisTab:"",
            operationalKpisTab : "",
            allKpisData:true,//added for all in one kpi dashboard
            contractualKpisData: false,
            operationalKpisData:false,
            unloadingTab:'',
            unloadingData:false,
            downloadApiData:{label:'Plan Summary',Value:'Plan Summary'},
            downloadApiDataNames:[{label:'Plan Summary',Value:'Plan Summary'},{label:'Plan Adherence',Value:'Plan Adherence'},{label:'Cool Chain Adherence',Value:'Cool Chain Adherence'},{label:'Ontime Plan Adherence',Value:'Ontime Plan Adherence'},{label:'Unloading Data',Value:'Unloading Data'},{label:'Shipment Current Status',Value:'Shipment Current Status'}],
            downloadApiLink:'http://216.48.191.229:8010/dispatch_planning/vehiclePlansOverPeriod'
        }
    }


    onClickTab = (pageType) => {

        if(pageType == "contractualKpis")
        {
            var contractualKpisTab = "activet";
            var operationalKpisTab  =  "";
            var contractualKpisData =  true;
            var operationalKpisData = false;
            var unloadingTab = '';
            var unloadingData = false;
            var downloadApiData = {label:'Plan Summary',Value:'Plan Summary'};
            var downloadApiDataNames = [{label:'Plan Summary',Value:'Plan Summary'},{label:'Plan Adherence',Value:'Plan Adherence'},{label:'Cool Chain Adherence',Value:'Cool Chain Adherence'}];
            var downloadApiLink = 'http://216.48.191.229:8010/dispatch_planning/vehiclePlansOverPeriod';
            
        }
        if(pageType == "operationalKpis")
        {
            var contractualKpisTab = "";
            var operationalKpisTab  =  "activet";
            var contractualKpisData =  false;
            var operationalKpisData = true;
            var unloadingTab = '';
            var unloadingData = false;
            var downloadApiData = {label:'Ontime Plan Adherence',Value:'Ontime Plan Adherence'};
            var downloadApiDataNames = [{label:'Ontime Plan Adherence',Value:'Ontime Plan Adherence'}];
            var downloadApiLink = 'http://216.48.191.229:8001/generateShipmentPlanningOntimeAdherenceData';
            
        }
        if(pageType == "unloading")
        {
            var contractualKpisTab = "";
            var operationalKpisTab  =  "";
            var contractualKpisData =  false;
            var operationalKpisData = false;
            var unloadingTab = 'activet';
            var unloadingData = true;
            var downloadApiData = {label:'Unloading Data',Value:'Unloading Data'};
            var downloadApiDataNames = [{label:'Unloading Data',Value:'Unloading Data'}];
            var downloadApiLink = 'http://216.48.191.229:8001/unloadingBIDashboardData';
            
        }
       
        this.setState({
            contractualKpisTab:contractualKpisTab,
            operationalKpisTab:operationalKpisTab,
            contractualKpisData:contractualKpisData,
            operationalKpisData:operationalKpisData,
            unloadingTab : unloadingTab,
            unloadingData : unloadingData,
            downloadApiData : downloadApiData,
            downloadApiDataNames : downloadApiDataNames,
            downloadApiLink : downloadApiLink


        })
    }

    downloadApiDataChanged = (downloadApiData) =>{

        if(downloadApiData.label == 'Plan Summary'){
            var downloadApiLink = 'http://216.48.191.229:8010/dispatch_planning/vehiclePlansOverPeriod';
        }else if(downloadApiData.label == 'Plan Adherence'){
            var downloadApiLink = 'http://216.48.191.229:8001/generateShipmentPlanningAdherenceData';
        }else if(downloadApiData.label == 'Cool Chain Adherence'){
            var downloadApiLink = 'http://216.48.191.229:8010/dispatch_planning/coolChainAdherenceChartsData';
        }else if(downloadApiData.label == 'Ontime Plan Adherence'){
            var downloadApiLink = 'http://216.48.191.229:8001/generateShipmentPlanningOntimeAdherenceData';
        }else if(downloadApiData.label == 'Unloading Data'){
            var downloadApiLink = 'http://216.48.191.229:8001/unloadingBIDashboardData';
        }
        else if(downloadApiData.label == 'Ontime Plan Adherence'){
            var downloadApiLink = 'http://216.48.191.229:8001/generateShipmentPlanningOntimeAdherenceData';
        }else if(downloadApiData.label == 'Unloading Data'){
            var downloadApiLink = 'http://216.48.191.229:8001/unloadingBIDashboardData';
        }
        else if(downloadApiData.label == 'Shipment Current Status'){
            var downloadApiLink = 'http://216.48.191.229:8001/fetchShipmentsLastestStatusData';
        }
        this.setState({
            downloadApiData : downloadApiData,
            downloadApiLink : downloadApiLink
        })
    }

    render(){

    return(
    
    <div className="col-sm-12 pt-3p">
        
        <div className="col-md-12 col-sm-12">
            <h5 className="fbold pb-0 mt-2 float-left ">KPI Dashboard</h5>
            <ul className="d-tabs  ">
                
                
                {/* <li onClick={this.onClickTab.bind(this,"contractualKpis")} className={"lttabs "+(this.state.contractualKpisTab)}>
                    <button type="button" className="btn">Contractual KPIs </button>
                </li>
                
                <li onClick={this.onClickTab.bind(this,"operationalKpis")} className={"lttabs "+(this.state.operationalKpisTab)}>
                    <button type="button" className="btn">Operational KPIs</button>
                </li>

                <li onClick={this.onClickTab.bind(this,"unloading")} className={"lttabs "+(this.state.unloadingTab)}>
                    <button type="button" className="btn">Unloading Dasboard </button>
                </li> */}

                {/* <li className="float-right" style={{alignSelf:"center"}}> */}
                    {/* <button type="button" 
                    className="btn btn-danger ml-2p float-right"
                    ><i className="fa fa-download"></i>  */}
                    {/* <HTMLCSVApp api={this.state.downloadApiLink}/> */}
                    {/* </button> */}
                {/* </li> */}
                {/* <li className="float-right col-sm-2 mb-2" style={{alignSelf:"center"}}> */}
                    {/* <span className="f12">Select File</span> */}
                    {/* <Select
                        placeholder={"Select"}
                        onChange={this.downloadApiDataChanged}
                        name="downloadApiData"
                        value={this.state.downloadApiData}
                        options={this.state.downloadApiDataNames} />
                </li> */}

            </ul>

        </div>
            {this.state.allKpisTab ? <PowerBIReport url={'https://app.powerbi.com/view?r=eyJrIjoiNzM0NzI4YTItNDkxZS00MjE1LTk4ZWYtY2U2OTI4OTYxYjc5IiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9'}/> :''}
            {/* {this.state.contractualKpisTab ? <PowerBIReport url={'https://app.powerbi.com/view?r=eyJrIjoiNjllZjQ4MzYtYzRlYS00YWE3LWEzMzAtZTE2MDc1NmUzODdiIiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9'}/> :''}
            {this.state.operationalKpisTab ? <PowerBIReport url={'https://app.powerbi.com/view?r=eyJrIjoiMjNmMmVkZjUtMTM3Ni00MzNjLWI4MmMtMmEwY2MxMmY3ZmY3IiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9'}/> :''}
            {this.state.unloadingTab ? <PowerBIReport url={'https://app.powerbi.com/view?r=eyJrIjoiZWRlODc4MzktMDhkNC00MWRmLTk2NWMtNGMwMzcwZDQ0YWVjIiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9'}/> :''} */}
    </div>
    );
};
};