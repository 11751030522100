import React,{Component} from "react";
import Constant from "../common/constants";
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
// import ComponentIndex from '../common/appendComponents';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import KpiActions from '../manage/kpiActionsComponent';
import KpiDeviationActions from '../manage/kpiDeviationActionsComponent';
import KpiTransitDelayActions from '../manage/kpitrucktransitdelayaction';
import RoutemapTransitAction from '../manage/routemaptransit';
import kpiDayWiseDistanceActions from '../manage/kpiDayWiseDistanceActionsComponent';
import RouteMapForDayWiseDistanceTrip from '../manage/routeMapForDayWiseDistanceTrip';
import Nooverspeed from '../manage/nooverspeedComponent.js';
import Notransit from '../manage/notransitComponent.js';
import RoutemapAction from '../manage/routemapActionComponent';
import RouteNightDrivemap from '../manage/routenightdrivemap';
import KpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import Nonightdrive from '../manage/nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
import KpiLoadingDelayActions from '../manage/kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from '../manage/kpiUnLoadingDelayActions';
import TruckConsignmentLegs from '../manage/truckConsignmentLegs';
import Noenroutes from '../manage/noenroutesComponent.js';
import Gps from '../manage/gpsActionComponent.js';
import NoGps from '../manage/nogpsActionComponent.js';
import ShipmentLegs from "./shipmentlegs";
import $ from "jquery";
import NoGpsLifetime from '../manage/nogpslifetimeActionComponent.js';
import ConsignmentInformation from "./consignmentinformation"
import CountUp from 'react-countup';
import Modal from 'react-responsive-modal';
// import AppendComponentIndex from '../common/appendComponents';
// import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
// import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Counters from './consignmenttruckscountersComponent';
import Viewsharablelink from "../layouts/viewsharablelink";
// import EditBtn from "../layouts/EditBtn";
// import DeviceStatus from "../layouts/DeviceStatus"
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenDDMMYYYY
} from '../common/utils';
// import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import LoadingAreaLegs from "./loadingarealegs";
import LegRouteMap from "./legrouteforactivetruck";
import GridButton from "../optirun-admin/gridButton";
var infoBox = require('../common/google-infowindow');

var moment = require('moment');
// var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;


export default class ManageTruckConsignments extends Component {
	constructor(props) {
		// pre_sdate = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD');
		// pre_edate = moment.parseZone().format('YYYY-MM-DD');
		var sdate = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD');
		var edate = moment.parseZone().format('YYYY-MM-DD');
		super(props);
		this.displayData = [];
		this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			originalData: [],
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			consignmentCode:"",
			mapinfo: '',
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			mobile_network:"",
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			pivotmodeis: false,
			sliderCommentTranslate: "",
			commentsRowData: [],
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			slideBar: "",
			truckInfo: "",
			consignments: "",
			consignment_date:"",
			invoice_date:"",
			currenttrucklocation: "",
			defaultColDef: {
				sortable: true,
				filter: true,
				editable: false,
				resizable: true,
				minWidth: 70,
				initialWidth: 200,
				wrapHeaderText: true,
				autoHeaderHeight: true,
			},
			rownode: [],
			rowData: [],
			maprowData: [],
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			waiting:false,
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				kpiActions: KpiActions,
				kpiDeviationActions: KpiDeviationActions,
				kpiTransitDelayActions: KpiTransitDelayActions,
				nooverspeed: Nooverspeed,
				notransit: Notransit,
				kpiNightDriveActions: KpiNightDriveActions,
				nonightdrive: Nonightdrive,
				kpiEnrouteStoppagesActions: KpiEnrouteStoppagesActions,
				noenroutes: Noenroutes,
				kpiLoadingDelayActions: KpiLoadingDelayActions,
				kpiUnLoadingDelayActions: KpiUnLoadingDelayActions,
				truckConsignmentLegs: TruckConsignmentLegs,
				gps: Gps,
				nogps: NoGps,
				noGpsLifetime: NoGpsLifetime,
				kpiDayWiseDistanceActions: kpiDayWiseDistanceActions,
				LoadingAreaLegs:LoadingAreaLegs,
				ShipmentLegs:ShipmentLegs,
				ConsignmentInformation:ConsignmentInformation,
				Viewsharablelink:Viewsharablelink,
				GridButton:GridButton
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 1000,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: '',
			tripvalue: '',
			consigner: '',
			consigner_coordinates: [],
			consignee_coordinates: [],
			baseRouteCoords: [],
			pagecounters: '',
			startDate: moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD'),
			containerslist: [],
			endDate: moment.parseZone().format('YYYY-MM-DD'),
			defaultsdate: sdate,
			defaultedate: edate,
			tolls: [],
			loginplant: "",
			filterCities: [],
			filterCustomers: [],
			filterStates: [],
			filterZones: [],
			filterTransporters: [],
			filterTransportersZones: [],
			flCity: "",
			flCustomer: [],
			flState: "",
			flZone: "",
			flTransporter: "",
			flTransporterZone: "",
			movementtype: { "value": "all", "label": "All" },
			plants: [],
			selectedFilter: "",
            usergridstate:[],
			sliderBulkForceClose:"",
			screenurl: "",
			screentitle: "",
            map_type_of_tracking: "",
			filterNames: "Truck / Shipment No",
			filterOptions: [{ label: "Truck", value: "Truck" }, { label: "Shipment No", value: "shipment_id" }],
			rowIds : [],
			priority : 0,
			priorityBtnShow : 'show-m',
			alltrucksBtnShow : 'show-n',
			isunmarkpriority:true,
			padding:6,
			lastRunTime:"",
			seconds:120,
			sim_tracking_service_provider: [{ label: "JIO", value: "jio" }, { label: "NON-JIO", value: "non_jio" }],
			shipment_time:"",
			shipment_date:"",
			transporterCode:"",
			driver_code:"",
			driver_name:"",
			mobile_no:"",
			mobile_network:"",
			shipment_date:"",
			shipment_time:"",
			invoice_no:"",
			transporter_name:"",
			selectedtruck:"",
			selectedshipment_id:"",
			selectedconsigner_code:"",
			consignee_code:"",
			old_consignee_code:"",
			screen_type:"Active",
			screen_type_val:{ "value": "Active", label: "Active Trips" },
			activeTab:"activet",
			deliveredTab:"",
			allTab:"",
            overlyRing:'show-n',
			base_route_show:true,
			openForceCloseModel :false,
			forceClose_reason:'gps_issue',
			selectedShipmentId:'',
			assignVehicleloader:'show-n',
			forceCloseVehicle:'',
			placeholder_select:'Select',
			pre_sdate:moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD'),
			pre_edate:moment.parseZone().format('YYYY-MM-DD'),
			showSlideBlockUpdate:'show-n',
			overlayBlock:'show-n',
			shipmentidIfo:'',
			locationNames:[],
			originalLocationNames: [],
			zones:[{value:"ALL", label: "ALL"}],
			zone:"",
        	location:'',
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
		// this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onClickShowMapView = this.onClickShowMapView.bind(this);
		this.onShowCommentsDiv = this.onShowCommentsDiv.bind(this);
		this.onClickShowLoadingAreaLegs = this.onClickShowLoadingAreaLegs.bind(this);
        this.onClickShowShipmentLegs = this.onClickShowShipmentLegs.bind(this);
		this.onShowShipmentLegRouteMap = this.onShowShipmentLegRouteMap.bind(this);
		this.onClickLink = this.onClickLink.bind(this);
		// this.startTimer = this.startTimer.bind(this);
		// this.countDown = this.countDown.bind(this);
		this.changeMobileNoHandler = this.changeMobileNoHandler.bind(this);
		this.onClickEdit = this.onClickEdit;
		this.onClickDeviceStatus = this.onClickDeviceStatus
		this.onChangeStartDate = this.onChangeStartDate.bind(this)
		this.onChangeEndDate = this.onChangeEndDate.bind(this)
		// this.timer = 0;
	}
	componentDidMount() {
		
		var from_date = moment().startOf('month').format('YYYY-MM-DD');
        var to_date = moment.parseZone().format("YYYY-MM-DD")
        $("#osfromDate").val(from_date);
		$("#osdate").val(to_date);
		loadDateTimeScript();
		this.setState({
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			}
		});
		if(localStorage.getItem("user_type") == "CUSTOMER")
		{
			this.setState({
				filterNames: "Truck / Invoice No",
				filterOptions: [{ label: "Truck", value: "Truck" }, { label: "Invoice", value: "sales_order" }]
			});
		}
		var loguser = localStorage.getItem("m");
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			this.setState({
				loginplant: loginplant,
				// consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

			})
		}
		catch (e) {

		}
		var urlpath;
		var dpt;
		var sdate = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD');
		var edate = moment.parseZone().format('YYYY-MM-DD');
		// var sdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');
		//filters data
		let params = {
            data_type : 2
        }
		this.boundTypeLocations(params)
			redirectURL.post("/tracking/plants", { dept_code: (dpt) })
				.then(async(resp) => {
					let responseData = resp.data.plants;
					if(responseData.length > 0)
					{
						responseData = await distinctArrayBy(responseData,"plant_code");
					}
					let location_access = JSON.parse(localStorage.getItem("location_access"))
					if(location_access != null){
						responseData = responseData.filter(itm => location_access.includes(itm.plant_code))
					}
					this.setState({
						plants: responseData
					});
				})
				.catch((err)=>{
					var errDetails={
						url:"/tracking/plants",
						screen:'Trips',
						err,
					}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					
				})
				
			// console.log(localStorage.getItem('roles'),"line 395")
				redirectURL.post("/tracking/getConsignmentsNewFilterKeysForSndTrucks", { dept_code: (dpt),email:localStorage.getItem('email'),role:localStorage.getItem('roles'),customer_code:localStorage.getItem('customer_code'),consigner_code:localStorage.getItem('pc'),plant_code : JSON.parse(localStorage.getItem("location_access"))})
					.then((resp) => {
						// console.log("responseforDropDown", resp.data)
						var data = resp.data.data
						var transporters = resp.data.data.transporters;
						let tlist = [];
						tlist.push({
							"value": "",
							"label": "All",
						});
						// console.log(transporters,"transporters")
						if(transporters.length > 0)
						{
							var uniqueArray = Array.from(new Set(transporters.map(item => item.transporter_code))).map(transporter_code => transporters.find(item => item.transporter_code === transporter_code));
							uniqueArray.map((t) => {
								tlist.push({
									"value": t.transporter_code,
									"label": t.transporter_name,
									"plant_code":t.location_code
								});
							});
						}
						// console.log(tlist,"tlist")
						
						var cities = data.cities;
						var filterCities = [];
						if (cities.length > 0) {
							filterCities.push({
								label: "All",
								value: "",
							});
							cities.map(function (c) {
								filterCities.push({
									label: c,
									value: c,
								});
							});
						}
						var customers = data.customers;
						// console.log(customers,"dealer list")
						
						var filterCustomers = [];
						const uniqueCustomers = [];
						if (customers.length > 0) {
							filterCustomers.push({
								label: "All",
								value: "",
							});
							
							
							customers.forEach((c)=>{
								const uniqueIndex = uniqueCustomers.findIndex(e => e.value === c.location_code)
								if(uniqueIndex === -1){
									uniqueCustomers.push({
										label: c.location_code + " - " + c.location_name,
										value: c.location_code,
										plant_code: c.drop_corresponding_pickup_location_code
									  });
								} else {

								}
							})
							// console.log(uniqueCustomers,"uniqueCustomers")
							// customers.map(function (c) {
							// 	filterCustomers.push({
							// 		label: c.location_code+" - "+c.location_name,
							// 		value: c.location_code,
							// 		plant_code:c.drop_corresponding_pickup_location_code									
							// 	});
							// });
						}												
						this.setState({
							filterCities: filterCities,
							filterCustomers: uniqueCustomers,
							filterTransporters: tlist,
						});
					})
					.catch((err)=>{
						// console.log('hit err here for the')
						var errDetails={
							url:"/tracking/getConsignmentsNewFilterKeysForSndTrucks",
							screen:'Trips',
							err,
						}
						// console.log('hitttt',errDetails)
						redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					})
				
			



		this.setState({
			loadshow: 'show-m',
			//startDate: sdate,
			//endDate: edate,
			deptcode: dpt,
			defaultsdate: sdate,
			defaultedate: edate
		});

		var reqparams = {
			dept_code: dpt,
			from_date: sdate,
			to_date: edate,
			// consigner_code: ["ALL"],
			//from_date: sdate,
			//to_date: edate
		}
		try{
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			{
				reqparams.consigner_code = localStorage.getItem("pc")
				this.setState({
					consigner:[localStorage.getItem("pc")]
				})
			}
			else{
				reqparams.consigner_code = "ALL"
			}
		}catch(e){
			
		}
		var activeconsignemntarr = [];
		var activetrucksarr = [];
		var transitdelaysarr = [];
		var gpsdatanaarr = [];
		var overspeedtrucksarr = [];
		var nightdrivearr = [];
		var alltrucksarr = [];

		// try {
		// 	var userrols = localStorage.getItem("roles");
		// 	var splitrole = userrols.split(",")
		// 	if (splitrole.length > 0) {
		// 		if (splitrole.includes("tpt_sndg") == true || splitrole.includes("tpt_prt") == true) {
		// 			reqparams.transporter_codes = localStorage.getItem("transportercode")
		// 		}
		// 	}
		// }
		// catch (e) {

		// }
		if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
		{
			reqparams.transporter_codes = localStorage.getItem("transportercode")
		}
		if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		{
			reqparams.customer_code = localStorage.getItem("customer_code")
		}
		// this.logPageView(); // Log page view to GA
		// let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		// this.setState({ eventAction: eventAction });
		// redirectURL.post("/tracking/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			// .then(async (resp) => {
			// 	console.log(resp.data,"usergridstate")
			// 	await this.setState({
			// 		usergridstate: resp.data
			// 	});
			// 	setTimeout(()=>{
			// 		this.restoreGridStates();
			// 	},1000);
			// })
			// .catch((err)=>{
			// 		var errDetails={
			// 			url:"/tracking/usergridstates",
			// 			screen:'Trips',
			// 			err,
			// 		}
			// 		redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					
			// 	})

		this.restoreGridStates();
		
		this.onLoadCustomers();
		this.onLoadShowTrucksList(urlpath, reqparams)
		this.getZones();
		// console.log(reqparams,"reqparams")
	};

	boundTypeLocations = async(param) => {

        let location_access = JSON.parse(localStorage.getItem("location_access"))

        let locationNames = [];

        if(location_access == null){
            locationNames.push({label:'ALL',value:''})
        }
        
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
				//   console.log(boundLocations,"boundLocations")
				  this.setState({
					boundLocationsRec: boundLocations
				  })
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                        if(location_access != null){
                            if(location_access.includes(item.location_code)){
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })

                                }
                            }else{
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })
                            }
                      })
                      
                      if(locationNames.length>0){
                        await this.setState({
									originalLocationNames: locationNames,
                                    locationNames : locationNames,
                                    location : locationNames[0]
                                })
                    }else{
                        await this.setState({
                            locationNames : [{label:'Please Add Data',value:'no_data'}],
                            location : [{label:'Please Add Data',value:'no_data'}]
                        })
                    }
                  }
              }
          })
          .catch(function (error) {
            var errDetails={
                url:"master/getBoundTypeLocations",
                screen:'Trips Screen',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    }

    onLoadCustomers(){
		
        redirectURL.post("/tracking/customers")
        .then((response) => {
            var customersarr = response.data.customers;
            var customers = []
            if(customersarr.length > 0)
            {
                customersarr.map((item) => {
                    customers.push({value:item.consignee_code,label:item.consignee_name+"-"+item.consignee_city+"-"+item.pin_code})
                })
            }
            // console.log(customers,"customers-data")
            this.setState({
                customers:customers
            })
        })
		.catch((err)=>{
					var errDetails={
						url:"/tracking/customers",
						screen:'Trips',
						err,
					}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					
		})
	
    }
    getZones(){
		
        redirectURL.post("/tracking/zones")
        .then((response) => {
            var zonesArr = response.data.zones;
            var zones = []
            if(zonesArr.length > 0)
            {
				// console.log(zonesArr,"zonesArrrr")
                zonesArr.map((item) => {
					if(item){
						zones.push({value:item,label:item})
					}
                })
            }
            // console.log(customers,"customers-data")
			this.setState((prevState) => ({
				zones: [...prevState.zones, ...zones]
			}));
        })
		.catch((err)=>{
					var errDetails={
						url:"/tracking/zones",
						screen:'Trips',
						err,
					}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					
		})
	
    }
	// logPageView = () => {
	// 	try {
	// 		if (googleAnalytics.page.enableGA) {
	// 			googleAnalytics.logPageView();
	// 			let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
	// 			let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
	// 			let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
	// 			this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
	// 		} else {
	// 			console.log("GA disabled...");
	// 		}
	// 	} catch (error) {
	// 		console.error("Error occured while logging to GA, error = ", error);
	// 	}
	// }


	onLoadShowTrucksList = (urlpath, parameters) => {
		// console.log("parameters", parameters, "urlpath", urlpath)
		let location_access = JSON.parse(localStorage.getItem("location_access"))
		if(location_access != null){
			parameters.plant_code = location_access
			// console.log('hello')
		}
		// var urlpath;
		// var dpt;
		// var sdate = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD');
		// var urlpath = '/tracking/intranitRoadMobileData';		
		// parameters.screen_type = this.state.screen_type;
		// parameters.data_type = "allTrucks";
		// parameters.priority = this.state.priority
        // var showRail = 0;
        // parameters.showRail = 0;
		// console.log('Hit',parameters)
		var k={}
		if (!Object.keys(parameters).length){
			// console.log('hell')
			parameters.from_date=moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD')
			parameters.to_date=moment.parseZone().format('YYYY-MM-DD')
			parameters.consigner_code='ALL'
		}
		var reqparams={
			...parameters,
			screen_type:this.state.screen_type,
			data_type:'allTrucks',
			priority:this.state.priority,
			showRail:0
		}
		// console.log(reqparams,'req',parameters)
		
		redirectURL.post('/tracking/intranitRoadMobileData', reqparams)
					.then((response) => {
						// this.setState({assignVehicleloader:'show-n',})
				
				var trucksArr = response.data;

				// console.log(trucksArr.vehicle_start_time_mins,"trucksArr")
				if(trucksArr.length > 0)
				{
					trucksArr = trucksArr.filter((ta)=>ta.status <= 15);
				}
				// console.log(trucksArr,"trucksArrtrucksArr")
				if (typeof response.data == 'object') {
					var recordsarr = response.data;
				}
				else {
					var dd = response.data;
					var recordsarr = JSON.parse(response.data);
				}
				var records = [];
				
				var counters = recordsarr.counters_data;
				records = records.sort(GetSortDescOrder("invoice_time"));
				var lastRunTime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
				// console.log(trucksArr[0],"trucksArr1")
				this.setState({
					rowData: trucksArr,
					allRowData: trucksArr,
					originalData: trucksArr,
					lastRunTime:lastRunTime,
					loadshow: 'show-n',
					overly: 'show-n',
					pagecounters: counters
				});
			})
			.catch(function (error) {
				var errDetails={
                    url:"/tracking/intranitRoadMobileData",
                    screen:'Trips',
                    error,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
				// console.log('error met')
				console.log(error);
			});
	}
	// countDownTimer = async() => {
	// 	var lastRunTime = this.state.lastRunTime;
	// 	var currentDate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
	// 	// var startdate = moment.parseZone(lastRunTime).format("YYYY-MM-DD HH:mm:ss")
	// 	var startdate = lastRunTime
	// 	var diffreetime = moment.duration(moment(currentDate).diff(startdate));
	// 	var runseconds = Math.round(diffreetime.asSeconds());
	// 	console.log(runseconds,lastRunTime,currentDate,"runseconds");
	// 	if(parseInt(runseconds) < 60)
	// 	{
	// 		this.startTimer();
	// 	}
	// }
	secondsToTime(secs){
		let hours = Math.floor(secs / (60 * 60));
	
		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);
	
		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);
	
		let obj = {
		  "h": hours,
		  "m": minutes,
		  "s": seconds
		};
		return obj;
	}
	// startTimer() {
	// 	if (this.timer == 0 && this.state.seconds > 0) {
	// 	  this.timer = setInterval(this.countDown, 2000);
	// 	}
	// }
	// countDown() {
	// 	// Remove one second, set state so a re-render happens.
	// 	let seconds = this.state.seconds - 1;
	// 	this.setState({
	// 	  time: this.secondsToTime(seconds),
	// 	  seconds: seconds,
	// 	});
	// 	if(this.state.seconds == 30 || this.state.seconds == 60 || this.state.seconds == 90)
	// 	{
	// 		//console.log(this.state.seconds,"this.state.seconds")
	// 		this.onLoadShowTrucksList();
	// 	}
	// 	// Check if we're at zero.
	// 	if (seconds == 0) { 
	// 		clearInterval(this.timer);
	// 		$("#timerDiv").hide();
	// 		this.onLoadShowTrucksList();
	// 	}
	// }
	


	// onPropsApply = (params) =>{
	// 	console.log("Props Params ", params)
	// }
	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow: 'show-m'
		});
		var reqData = {
			consignment_code: e.consignment_code
		}
		//console.log("reqData: ", reqData);
		redirectURL.post('/tracking/timeline', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				//console.log("records", records);

				this.setState({
					uploadDivWidth: '30%',
					sliderTranslate: "slider-translate-50p",
					showDiv: 'show-m',
					timelinedata: records,
					loadshow: 'show-n',
					overly: 'show-m'
				});
			})
			.catch(function (err) {
				console.log(err);
				var errDetails={
                    url:"/tracking/timeline",
                    screen:'Trips',
                    err,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
			});

	}

	onCloseUploadDiv = () => {
		// console.log("function call");
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			sideBar: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}
	onShowCommentsDiv = (e) => {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Show Comments",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		var reqparams = {
			truck_no: e.truck_no,
			dept_code: this.state.deptcode,
			screen: "Active Trucks"
		}
		redirectURL.post("/tracking/getCommentLogs", reqparams)
		.then((response) => {
			// console.log(response.data);
			this.setState({
				commentsRowData: response.data.data
			})
		}).catch(function (error) {
			var errDetails={
				url:"/tracking/getCommentLogs",
				screen:'Trips',
				error,
							}
			redirectURL.post("/master/logErrorsForApiCalls",errDetails)
			console.log(error);
		})
		this.setState({
			sliderCommentTranslate: "slider-translate-60p",
			showDiv: 'show-m',
			loadshow: 'show-n',
			overly: 'show-m',
		});
	}
	onShowRouteDiv = (e) => {
		// console.log("e ", e)
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		var consignmentcodes = []
		//console.log("leg_details ", JSON.parse(e.leg_details))
		if(e.leg_details!="" && e.leg_details!=undefined){
			var legs = JSON.parse(e.leg_details);
			// console.log('hithithit',legs)
			legs.map((item) => {
				consignmentcodes.push(item.consignment_code)
			})
		}
		
		// console.log("consignmentcodes ", consignmentcodes)
		this.setState({
			loadshow: 'show-m',
			overlyRing:'show-m',
		});
		var startdate = ""
		if(e.tpa_entry_time != undefined && e.tpa_entry_time != "" && e.tpa_entry_time != null){
			startdate = getYYYYMMDDHHMMSS(e.tpa_entry_time)
		}
		else if(e.shipment_time != undefined && e.shipment_time != "" && e.shipment_time != null){
			startdate = getYYYYMMDDHHMMSS(e.shipment_time)
		}
		else if(e.invoice_time != undefined && e.invoice_time != "" && e.invoice_time != null){
			startdate = getYYYYMMDDHHMMSS(e.invoice_time)
		}
		else{
            startdate = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
		}
		let sD = startdate.split(" ")
		let date = sD[0].replaceAll("/", "-")
		startdate = date + " " + sD[1]

		if(e.status == "9" || e.status == "10" ||e.status == "11" || e.status == 9 || e.status == 10|| e.status == 11)
        {
			if(e.recent_dealer_reported !=undefined && e.recent_dealer_reported !="")
			{
				var edate = moment.parseZone(e.recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss");
			}
			else
			{
                var edate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			}
        }
		else if(e.status == "12" || e.status == "13" ||e.status == "14" || e.status == 12 || e.status == 13|| e.status == 14)
        {
			if(e.left_dealer_marked !=undefined && e.left_dealer_marked !="")
			{
				var edate = moment.parseZone(e.left_dealer_marked).format("YYYY-MM-DD HH:mm:ss");
			}
			else
			{
                var edate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			}
        }
		else if(e.status == "15" || e.status == 15)
        {
			if(e.reached_plant_at !=undefined && e.reached_plant_at !="")
			{
				var edate = moment.parseZone(e.reached_plant_at).format("YYYY-MM-DD HH:mm:ss");
			}
			else
			{
                var edate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			}
        }
        else{
            if(e.status == 6 || e.status == "6")
            {
				if(e.force_closure_time !=undefined && e.force_closure_time !="")
				{
                var edate = moment.parseZone(e.force_closure_time).format('YYYY-MM-DD HH:mm:ss');
				}
            	else{
                var edate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
            	} 
			}else{
				var edate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			}           
        }
		var reqData = {
			truck_no: e.truck_no,
            shipment_id:e.shipment_id,
			consignment_codes: consignmentcodes,
			fromdate: startdate,
			todate: edate
		}
		// console.log("reqData: ", reqData);
		var mapUrl = '/tracking/truckmap';
		redirectURL.post(mapUrl, reqData)
			.then((response) => {
				var records = response.data;
				// console.log("check ",Array.isArray(records))
				// console.log("Props data ", response.data)
				try{
					if(Array.isArray(records) == false)
					{
						var recs = records.replace(/NaN/g,"0")
						records = JSON.parse(recs)
					}
				}
				catch(e){

				}
				
				// console.log("records list ", records);
				this.setState({
					loadshow: 'show-m'
				});
				// console.log("S1")
				try {
					// console.log("S2")
					// console.log("records.coords ", records.coords)
					if (records.coords.length == 0) {
						// console.log("S3")
						//console.log("records", records.coords);
						this.setState({
							show: true,
							basicTitle: 'No Route Available',
							basicType: "danger",
							loadshow: 'show-n',
							overlyRing:'show-n',
						});
					}
					else {
						// console.log("S4")
						// console.log("recordss ", records)
						// if (records != '') {

							this.setState({
								sliderRouteTranslate: "slider-translate-60p",
								showDiv: 'show-m',
								mapinfo: records,
								dealer: records.consignee_coordinates,
								consignment_code: "Truck NO : " + e.truck_no,
								maptruckno: e.truck_no,
								routeTruck: { "truck_no": e.truck_no, "startTime": records.route_details.start_time, "endTime": records.route_details.end_time },
								loadshow: 'show-n',
								sidebarSubHeader: "Truck Route Details",
								overly: 'show-m',
								rownode: e,
								consigner_coordinates: records.consigner_coordinates,
								consignee_coordinates: records.consignee_coordinates,
								baseRouteCoords: records.base_route,
								tolls: records.tolls,
								map_type_of_tracking : records.type_of_tracking,
								base_route_show:true

							});
							// console.log("s5 ")
							this.renderMap();
						// }
					}
				}
				catch (e) {
					this.setState({
						show: true,
						basicTitle: 'No Route Available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}



			})
			.catch(function (error) {
				// console.log(error);
				var errDetails={
					url:'/tracking/truckmap',
					screen:'Trips',
					error,
					
				}
				redirectURL.post("/master/logErrorsForApiCalls",errDetails)
			});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			showDiv: 'show-n',
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			overlyRing:'show-n',
		});
	}

	/*Truck Sidebar Component Starts*/



	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	};

	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);

	}

	renderMap = () => {
		// loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
		// window.initMap = this.initMap
	}

	initMap = () => {
		// console.log("mapinfo ",this.state.mapinfo);
		var currentwindow;

		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;


		//console.log("Here",arr);
		if (arr.length > 0) {
			var lt = arr[0].lat;
			var ln = arr[0].lng;
		}
		else {
			if (this.state.defTransitCoords == '') {
				var lt = 28.4519751;
				var ln = 77.0310713;
			}
			else {
				var lt = JSON.parse(this.state.defTransitCoords).lat;
				var ln = JSON.parse(this.state.defTransitCoords).lng;
			}

		}
		var routeinfo = jsondata.route_details;
		var mapOptions = {
			zoom: 7,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels: true,
			mapTypeControlOptions: {
				mapTypeIds: ['satellite', 'roadmap'],
			},
			center: new window.google.maps.LatLng(lt, ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};

		var map = new window.google.maps.Map(document.getElementById('map'),
			mapOptions);

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt, ln));
		// Create our info window content
		var currentinfowindow = null;
		var line = new window.google.maps.Polyline(
			{
				map: map,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: [{
					icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor: '#ff8c52',
						fillColor: '#ff8c52',
						fillOpacity: 1,
						strokeWeight: 2
					},
					repeat: '100px',
					path: []
				}]
			});

		if (arr.length > 0) {
			for (let i = 0; i < arr.length; i++) {
				var path = line.getPath().getArray();

				let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
				path.push(latLng);
				line.setPath(path);




				//Change line color based on map type
				window.google.maps.event.addListener(map, 'maptypeid_changed', function () {
					if (map.getMapTypeId() == 'satellite' || map.getMapTypeId() == 'roadmap') {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#FFFFFF',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});
					}
					else {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#157254',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});

					}

					for (i = 0; i < arr.length; i++) {
						var path = line.getPath().getArray();
						let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
						path.push(latLng);
						line.setPath(path);
						//map.setCenter(latLng);
					}

					var x = map.getZoom();
					var c = map.getCenter();
					window.google.maps.event.trigger(map, 'resize');
					map.setZoom(x);
					map.setCenter(c);
				});

				bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
			}

			var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
					icon: require('../../assets/icons/cf.png'),
					map: map,
				});

				window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
					return function () {
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
						var contentarr = []
						var header = "Break - " + (l + 1)
						contentarr.push({ "key": "Start time", "value": getDDMMYYYYHHMMSS(locations[l].break_start) })
						contentarr.push({ "key": "End time", "value": getDDMMYYYYHHMMSS(locations[l].break_end) + " (" + secondsToString(locations[l].break_time_seconds) + ")" })
						var contentString = infoBox(marker.icon, header, contentarr)
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						//console.log(marker.position.toJSON());
						//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
					}
				})(marker, l));
			}
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
				icon: require('../../assets/icons/track_start.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])
					var contentarr = []
					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
					var header = "Starting Point"
					contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
					var contentString = infoBox(marker.icon, header, contentarr, '')


					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker));
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
				icon: require('../../assets/icons/truck-end.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])
					var contentarr = []
					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
					var header = "End Point"
					contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) });
					try{
						// console.log(jsondata.trucksdetails)
						if(jsondata.trucksdetails != undefined)
						{
							if(jsondata.trucksdetails.length > 0)
							{
								contentarr.push({"key":"Address ", "value":jsondata.trucksdetails[0].last_known_address});
							}
							
						}
					}
					catch(e){

					}
					var contentString = infoBox(marker.icon, header, contentarr)

					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker));
		}
		try {
			// console.log("consigner_coordinates ", this.state.consigner_coordinates)
			if (this.state.consigner_coordinates != undefined) {
				if ((this.state.consigner_coordinates[0].lat != '' || this.state.consigner_coordinates[0].lat != 0)
					&& (this.state.consigner_coordinates[0].lng != '' || this.props.consigner_coordinates[0].lng != 0)) {
					// console.log(this.state.consigner_coordinates[0].lat+" ==   "+ this.state.consigner_coordinates[0].lng);
					marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(this.state.consigner_coordinates[0].lat, this.state.consigner_coordinates[0].lng),
						icon: require('../../assets/icons/business.png'),
						map: map,
					});
					var nplantname = this.state.consigner_coordinates[0].name;
					window.google.maps.event.addListener(marker, 'click', (function (marker) {
						console.log(marker)
						return function () {
							//	console.log(clusters[0])
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
							var header = "Plant"
							// contentarr.push({ "key": "End at ", "value": nplantname })
							contentarr.push({ "key": "Start at ", "value": nplantname })
							var contentString = infoBox(marker.icon, header, contentarr)

							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
						}
					})(marker));

				}
			}
		}
		catch (e) {

		}
		try {

			if (this.state.consignee_coordinates != undefined) {
				if (this.state.consignee_coordinates.length > 0) {
					var consigneecrds = this.state.consignee_coordinates;
					consigneecrds.map((item) => {
						// console.log("Coords itm ", item)
						try {
							//console.log(typeof(item))
							var coordinate = JSON.parse(item);
						}
						catch (e) {
							var coordinate = item;
						}
						if (coordinate.lat != 0 && coordinate.lng != 0) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(coordinate.lat, coordinate.lng),
								icon: require('../../assets/icons/customer_pin_38.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var header = "Customer"
									contentarr.push({ "key": "End at ", "value": coordinate.name })
									var contentString = infoBox(marker.icon, header, contentarr)

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));

						}

					});

				}
			}
		}
		catch (e) {

		}
		// BASE ROUTES STARTS
		var baseRouteCoords = this.state.baseRouteCoords;
		console.log(baseRouteCoords,"baseRouteCoords---")
		var poly_line = new window.google.maps.Polyline({
			map:map,
			strokeColor: "red",
			strokeOpacity: 1.0,
			strokeWeight: 3.5,
			icons: [{
					icon: {
							path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
							strokeColor:'#ff8c52',
							fillColor:'#ff8c52',
							fillOpacity:1,
							strokeWeight: 3.5
							},
					repeat:'500px',
					path:[]
				}]
		});
		var path = poly_line.getPath().getArray();
		console.log(baseRouteCoords.length,"baseRouteCoords-beforeFilter")
		if(baseRouteCoords.length > 0)
		{
			baseRouteCoords = baseRouteCoords.filter(function (value, index, darr) {
				return (index % 5 == 0);
			} );
		}
		console.log(baseRouteCoords.length,"baseRouteCoords-afterFilter")
		for(var ba=0;ba<baseRouteCoords.length;ba++)
		{
			
			let latLng = new window.google.maps.LatLng(baseRouteCoords[ba].lat,baseRouteCoords[ba].lng);
			bounds.extend(new window.google.maps.LatLng(baseRouteCoords[ba].lat,baseRouteCoords[ba].lng));
			path.push(latLng);
			poly_line.setPath(path);
		}
		// BASE ROUTES END
		map.fitBounds(bounds)
	}



	onShowOverspeedKpi = async (params) => {
		//console.log(e);
		// console.log("Consign Overspeed ",params);
		// let eventLabel = googleAnalytics.page.action.overspeed;
		// let eventOptions = {
		// 	"category": this.state.pagetitle,
		// 	"action": this.state.eventAction,
		// 	"label": eventLabel,
		// }
		// googleAnalytics.logEvent(eventOptions);
		if (params != 0) {

			var overspeedData = [];

			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",
								width: 50,
								cellRendererFramework: RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No", width: 140, field: "truck_no", filter: true, resizable: true },
							{ headerName: "Speed (km/h)", width: 100, field: "speed", filter: true, resizable: true },
							{
								headerName: "Reported From Date", field: "first_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									// return getDDMMYYYYHHMMSS(params.data.first_instance);
									try {
										if (params.data.first_instance != "" && params.data.first_instance != undefined) {
											return getDDMMYYYYHHMMSS(params.data.first_instance);
										}
										else {
											return "";
										}
									}
									catch (e) { }
								}
							},

							{
								headerName: "Reported End Date", field: "recent_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									try {
										if (params.data.recent_instance != "" && params.data.recent_instance != undefined) {
											return getDDMMYYYYHHMMSS(params.data.recent_instance);
										}
										else {
											return "";
										}
									}
									catch (e) { }

								}
							},
						],

					},
					getDetailRowData: function (param) {
						// console.log("Step 4 ",param)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})
						
						redirectURL.post('/tracking/truckoverspeed', {
							consignment_code: consignments,
							truck_no: param.data.truck_no
						})
							.then(async (response) => {
								// console.log("Step 1 ",response.data)
								overspeedData = response.data;
								param.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							})
							.catch((err)=>{
										var errDetails={
											url:"/tracking/truckoverspeed",
											screen:'Trips',
											err,
										}
										redirectURL.post("/master/logErrorsForApiCalls",errDetails)
										
									})
						
					},
					masterDetail: true
				}
			});

			if (propsdata.column.colDef.field == 'overspeeding_exception') {

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else {

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
						
					}
				}
			});
		}

	}

	onShowTransitKpi = async (params) => {
		if(params != 0)
		{
			// let eventLabel = googleAnalytics.page.action.transitDelay;
			// let eventOptions = {
			// 	"category": this.state.pagetitle,
			// 	"action": this.state.eventAction,
			// 	"label": eventLabel,
			// }
			// googleAnalytics.logEvent(eventOptions);
			if(params != 0)
			{
				var transitedelaydata=[];
				
				await this.setState({
						
					detailCellRendererParams:{
						suppressRefresh: true,
						detailGridOptions: {
							headerHeight:50,
							columnDefs: [
								{ headerName: "",field: "_id", 
								
									cellRendererFramework:RoutemapTransitAction,
									width:50,
									suppressSizeToFit: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Leg Start Time",field: "leg_start", width:120,resizable: true,
									valueGetter:function(params){
										return getDDMMYYYYHHMMSS(params.data.leg_start);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Leg End Time",field: "leg_end_eta", 
								width:120,resizable: true,
									valueGetter:function(params){
										return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Exp. Leg Distance (KM)",
								field: "leg_expected_distance", width:140,resizable: true,
									valueGetter:function(params){
										return Math.round(params.data.leg_expected_distance);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Actual Leg Distance Covered (KM)",
								field: "actual_leg_distance", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined)
										{
											return Math.round(params.data.actual_leg_distance);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},							
								{ headerName: "Exp. Distance from Trip Start(KM)",
								field: "expected_distance_start_to_leg", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined)
										{
											return Math.round(params.data.expected_distance_start_to_leg);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
								},
								{ headerName: "Actual Distance from Trip Start (KM)",
								field: "actual_distance_traveled_start_to_leg", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined)
										{
											return Math.round(params.data.actual_distance_traveled_start_to_leg);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								
								{ 
									headerName: "Google Distance from Start (KM)",
									field: "actual_start_to_leg_google_distance", 
									width:140,
									resizable: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								{ 
									headerName: "GPS Data Available",
									field: "no_gps_data", 
									width:140,
									resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.no_gps_data == 1)
										{
											return "Not Available";
										}
										if(params.data.no_gps_data == 0)
										{
											return "Available";
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								
								
								{ 
									headerName: "Trip Completed",
									field: "trip_completed", 
									width:140,
									resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.trip_completed == 1)
										{
											return "Yes";
										}
										if(params.data.trip_completed == 2)
										{
											return "";
										}
										if(params.data.trip_completed == 0)
										{
											return "";
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								}							
							],
							overlayNoRowsTemplate: 'No rows to show',						
						},
						getDetailRowData: function(params) {
							//console.log("Step 4 ",transitedelaydata)
							//console.log("Step 4 ",params)
							
							redirectURL.post('/tracking/transitdelay', {
								shipment_id:params.data.shipment_id,
								truck_no:params.data.truck_no
							})
							.then(async (response) =>{
								//console.log("Step 1 ",response.data)
								transitedelaydata=response.data;
								var legsarr=[]
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								
								//console.log("Step 2 ",response.data)
								
							})
							.catch((err)=>{
								var errDetails={
									url:"/tracking/transitdelay",
									screen:'Trips',
									err,
								}
								redirectURL.post("/master/logErrorsForApiCalls",errDetails)
								
							})
						
						}
					}
				});
				
				//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
				if(params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data')
				{
					params.node.setExpanded(!params.node.expanded);
					//console.log("expanded ",params.node.expanded)
					if(params.node.expanded == false)
					{
						transitedelaydata=[]
					}
				}
				else{

					params.node.setExpanded(false);
				}
				//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
				
			}
			else{
				this.setState({
				
					detailCellRendererParams:{
						detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No",field: "truck_no" },
							{ headerName: "Speed (km/h)",field: "speed" },
							{ headerName: "Reported At",field: "first_instance" }
						]
						
						},
						getDetailRowData: function(params) {
							//console.log("Child ", params);
						params.successCallback([]);
						
						}
					}
				});
			}
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"Transit for this consignment has not yet started",
				basicType:"info"
			});
		}
		
	}
	onClickLink=(params)=>{
		var truckNo = params.data.truck_no 
		window.open('/connect/'+truckNo, '_blank');


	}


	onShowNightDriveKpi = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			// let eventLabel = googleAnalytics.page.action.nightDrive;
			// let eventOptions = {
			// 	"category": this.state.pagetitle,
			// 	"action": this.state.eventAction,
			// 	"label": eventLabel,
			// }
			// googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({
				map_type_of_tracking : params.data.type_of_tracking,
				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",

								cellRendererFramework: RouteNightDrivemap,
								width: 100,
								suppressSizeToFit: true
							},
							{ headerName: "Truck No", field: "truck_no", width: 150, resizable: true },
							{
								headerName: "Traveled From", field: "first_instance", width: 180, resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
							{
								headerName: "Traveled Until", field: "recent_instance", resizable: true,
								width: 140,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.recent_instance);
								}
							},
							{
								headerName: "Distance Traveled (KM)", field: "distance_from_first_instance", width: 180,
								resizable: true,
								valueGetter: function (params) {
									return Math.round(params.data.distance_from_first_instance)
								}
							}
						],

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",overspeedData)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})
						
						redirectURL.post('/tracking/trucknightdriving', {
							consignment_code: consignments,
							gate_out_time: params.data.parking_in_time,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								params.successCallback(overspeedData);
								
								//console.log("Step 2 ",response.data)

							})
							.catch((err)=>{
									var errDetails={
										url:"/tracking/trucknightdriving",
										screen:'Trips',
										err,
									}
									redirectURL.post("/master/logErrorsForApiCalls",errDetails)
									
								})
						
					}

				}
			});

			if (params.column.colDef.field == 'nightdriving_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
						
					}
				}
			});
		}

	}

	/*NO GPS DATA*/


	onShowNoGPSKpi = async (params) => {
		//console.log(params);
		//console.log("Consign onShowNoGPSKpi params = ",params);
		//console.log(params.column.colId);

		if (params != 0) {
			// let eventLabel = googleAnalytics.page.action.gpsAvailable;
			// let eventOptions = {
			// 	"category": this.state.pagetitle,
			// 	"action": this.state.eventAction,
			// 	"label": eventLabel,
			// }
			// googleAnalytics.logEvent(eventOptions);
			var transitedelaydata = [];

			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							/*{ headerName: "",field: "_id",

								cellRendererFramework:RoutemapNoGPSAction,
								width:50,
								suppressSizeToFit: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							},
							*/
							{
								headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Last Data Receieved",
								field: "last_packet_datetime",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Data Received on",
								field: "data_received_on",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.packet_received_on);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							}
						],
						overlayNoRowsTemplate: 'No transit time available for this Consignee',

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})
						
						redirectURL.post('/tracking/trucknogpsdata', {
							consignment_code: consignments,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								transitedelaydata = response.data;
								var legsarr = []
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								
								//console.log("Step 2 ",response.data)

							})
							.catch((err)=>{
										var errDetails={
											url:"/tracking/trucknogpsdata",
											screen:'Trips',
											err,
										}
										redirectURL.post("/master/logErrorsForApiCalls",errDetails)
										
									})
						
					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'is_gps_working') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
						
					}
				}
			});
		}

	}

	onShowEnrouteStoppagesKpi = async (params) => {
		let logParams = {
            // "location_code" : location.value,
            // "location_name" : location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Trips",
            "activity" : 'clicked on enroute stoppege icon  in the aggrid',
            "event" : "Aggrid opened below the row",
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
		// console.log("Consign Overspeed ",params);

		if (params != 0) {
			// let eventLabel = googleAnalytics.page.action.enrouteStoppages;
			// let eventOptions = {
			// 	"category": this.state.pagetitle,
			// 	"action": this.state.eventAction,
			// 	"label": eventLabel,
			// }
			// googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "Address",
								field: "address",
								width: 250,
								resizable: true
							},
							{
								headerName: "Break Start",
								field: "stoppage_start_time",
								width: 150,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.stoppage_start_time != undefined) {
										return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Break End",
								field: "reset_datetime",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.reset_datetime != undefined && params.data.reset_datetime != "Total") {
										return getDDMMYYYYHHMMSS(params.data.reset_datetime)
									}
									else {
										if(params.data.reset_datetime == 'Total')
										{
											return "Total";
										}
										else{
											return '';
										}
									}

								}
							},
							
							{
								headerName: "Break Time",
								field: "elaped_time_from_last_moved_seconds",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.elaped_time_from_last_moved_seconds != undefined) {
										var seconds = params.data.elaped_time_from_last_moved_seconds;
										var hours = parseInt(seconds / 3600);
										var minutes = Math.round((seconds - (hours * 3600)) / 60)
										//return hours+" Hr(s) "+minutes+" Min(s)";
										return secondsToString(seconds);
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Last Update on",
								field: "modified_date",
								width: 220,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.modified_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.modified_date)
									}
									else {
										return '';
									}

								}
							},
						],
						defaultColDef:this.state.defaultColDef,
						statusBar:this.state.statusBar,
						sideBar:this.state.sideBar,
						enableRangeSelection:true
					},
					getDetailRowData: async function (params) {
						//console.log("Step 4 ",overspeedData)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})
						
						redirectURL.post('/tracking/truckenroutes', {
							consignment_code: consignments,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								// console.log("Step 1 ",response.data)
								overspeedData = response.data;
								var summaryrow = {};
								var elaped_time_from_last_moved_seconds = 0;
								if(overspeedData.length > 0){
									overspeedData.map((it) => {
										elaped_time_from_last_moved_seconds = elaped_time_from_last_moved_seconds+it.elaped_time_from_last_moved_seconds;
									})
								}
								if(params.data.enroute_exception == 1)
								{
									if(overspeedData.length > 0)
									{
										summaryrow.stoppage_start_time = "";
										summaryrow.reset_datetime = "Total";
										summaryrow.elaped_time_from_last_moved_seconds = elaped_time_from_last_moved_seconds;
										summaryrow.modified_date = "";
										overspeedData.push(summaryrow)
									}
								}
								params.successCallback(overspeedData);
								
								//console.log("Step 2 ",response.data)

							})
							.catch((err)=>{
										var errDetails={
											url:"/tracking/truckenroutes",
											screen:'Trips',
											err,
										}
										redirectURL.post("/master/logErrorsForApiCalls",errDetails)
										
									})
						
					}
				}
			});

			if (params.column.colDef.field == 'enroute_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
						
					}
				}
			});
		}

	}


	onShowLoadingDelayKpi = async (params) => {
		//console.log(e);
		// console.log("Consign Overspeed ", params);
		//console.log(params.column.colId);
		if (params != 0) {
			// let eventLabel = googleAnalytics.page.action.loadingDelay;
			// let eventOptions = {
			// 	"category": this.state.pagetitle,
			// 	"action": this.state.eventAction,
			// 	"label": eventLabel,
			// }
			// googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							// {
							// 	headerName: "Gate In Time",
							// 	field: "gate_in_time",
							// 	width: 180,
							// 	resizable: true,
							// 	valueGetter: function (params) {
							// 		if (params.data.gate_in_time != undefined) {
							// 			return params.data.gate_in_time;
							// 		}
							// 		else {
							// 			return "NA"
							// 		}

							// 	}
							// },
							// {
							// 	headerName: "Gate Out Time",
							// 	field: "gate_out_time",
							// 	width: 180,
							// 	resizable: true,
							// 	valueGetter: function (params) {
							// 		if (params.data.gate_out_time != undefined) {
							// 			return params.data.gate_out_time;
							// 		}
							// 		else {
							// 			return "NA"
							// 		}
							// 	}
							// },
							{
								headerName: "Loading Time",
								field: "loading_time",
								width: 180,
								valueGetter: function (params) {
									if (params.data.loading_time != "" && params.data.loading_time != undefined) {
										return params.data.loading_time;
									}
									else {
										return "";
									}
								}
							}

						],

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",params)
						// if (params.data.gate_in_time != undefined) {
						// 	var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
						// 	//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
						// 	var gdate1 = new Date(params.data.gate_in_time);
						// 	var fromDate = g1;
						// }
						// else {

						var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
						var gdate1 = new Date(params.data.inside_fence);
						var fromDate = g1;
						// }
						//console.log("g1 ", g1)//console.log("Step 4 ",params)
						if (params.data.parking_in_time != undefined) {
							var g2 = getDDMMYYYYHHMMSS(params.data.parking_in_time);
							var gdate2 = new Date(params.data.parking_in_time);
							var toField = g2;
							//console.log("gatein ", gatein)
							//console.log("gateout ", gateout)
							//var total =0 ;
						}
						else {
							var c = new Date();
							var todaydate = new Date(moment(c).format("YYYY-MM-DD HH:mm:ss"));
							var g2 = getDDMMYYYYHHMMSS(todaydate);
							var gdate2 = new Date(todaydate);
							var toField = g2;
						}
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						var s1 = g1.split("-");
						var s2 = g2.split("-");


						var gatein = gdate1.getTime();
						var gateout = gdate2.getTime();
						var total = gateout - gatein;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						let loadingTimeDifference = null;

						// let timeDiffereceDays = null;
						// let timeDiffereceHours = null;
						// let timeDiffereceMinutes = null;
						// let totalDifferece = null;

						// gdate1 = g1;
						// gdate2 = g2;
						// console.log("gdate1 && gdate2 =", gdate1, gdate2);
						// if(gdate1 && gdate2) {
						// 	console.log("IN IF gdate1 && gdate2");
						// 	timeDiffereceDays = gdate2.diff(gdate1, 'days');  // calculating time difference in days between two dates
						// 	timeDiffereceHours = gdate2.diff(gdate1, 'hours');  // calculating time difference in days between two dates
						// 	timeDiffereceMinutes = gdate2.diff(gdate1, 'minutes');  // calculating time difference in days between two dates
						// 	totalDifferece = gdate2.diff(gdate1);  // calculating time difference in days between two dates

						// } else {
						// 	console.log("IN ELSE  gdate1 && gdate2");
						// }
						// console.log("timeDiffereceDays = ", timeDiffereceDays);
						// console.log("timeDiffereceHours = ", timeDiffereceHours);
						// console.log("timeDiffereceMinutes = ", timeDiffereceMinutes);
						// console.log("totalDifferece = ", totalDifferece);

						var dataarr = []
						// dataarr.push({
						// 	"gate_in_time":fromDate,
						// 	"gate_out_time": toField,
						// 	"loading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
						// })

						// if (diffDays) {
						// 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
						// } else {
						// 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
						// }

						// eslint-disable-next-line use-isnan
						if (fromDate != "" && toField != "") {
							if (diffHrs != "" && diffHrs != undefined && diffHrs != "NaN" && diffHrs != NaN) {
								var adiffHrs = diffHrs;
							}
							else {
								var adiffHrs = 0;
							}
							// eslint-disable-next-line use-isnan
							if (diffMins != "" && diffMins != undefined && diffMins != "NaN" && diffMins != NaN) {
								var adiffMins = diffMins;
							}
							else {
								var adiffMins = 0;
							}
							var ltime = adiffHrs + " Hr(s) " + adiffMins + " Min(s)";
						}
						else {
							var ltime = "";
						}

						// console.log("diffMins ", adiffMins)
						dataarr.push({
							"gate_in_time": fromDate,
							"gate_out_time": toField,
							"loading_time": ltime
						})
						params.successCallback(dataarr);
						
					}

				}
			});

			if (params.column.colId == 'loading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}



	onShowUnLoadingDelayKpi = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			// let eventLabel = googleAnalytics.page.action.unloadingDelay;
			// let eventOptions = {
			// 	"category": this.state.pagetitle,
			// 	"action": this.state.eventAction,
			// 	"label": eventLabel,
			// }
			// googleAnalytics.logEvent(eventOptions);

			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "From Date",
								field: "from_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									// console.log("child params ", params)
									if (params.data.from_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.from_date);
									}
									else {
										return "NA"
									}

								}
							},
							{
								headerName: "To Date",
								field: "to_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.to_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.to_date);
									}
									else {
										return "NA"
									}
								}
							},
							{
								headerName: "Unloading Time",
								field: "unloading_time",
								width: 180,

							}

						],

					},
					getDetailRowData: function (params) {
						if (params.data.locked_dealer_reported == undefined) {
							var g1 = getYYYYMMDDHHMMSS(params.data.first_dealer_reported);
							var fromField = g1;
						}
						else {

							var g1 = getYYYYMMDDHHMMSS(params.data.locked_dealer_reported);
							var fromField = g1;
						}
						//console.log("params.data.left_dealer_marked ", params.data.left_dealer_marked)
						if (params.data.left_dealer_marked == undefined) {
							var c = new Date();
							var todaydate = c;
							var g2 = getYYYYMMDDHHMMSS(todaydate);
							var toField = g2;
						}
						else {
							var g2 = getYYYYMMDDHHMMSS(params.data.left_dealer_marked);
							var toField = g2;
							//console.log("toField stee " ,toField);
						}
						var fstr = fromField.split("-");
						//console.log(fstr);
						var gdate1 = new Date(fromField);
						var gdate2 = new Date(toField);
						var frmd = gdate1.getTime();
						var tod = gdate2.getTime();
						var total = tod - frmd;
						//console.log("From Date ", fromField)
						//console.log("To Date ", toField)
						//var total =0 ;
						//console.log("gdate1 ", frmd)
						//console.log("gdate2 ", tod)
						//console.log("Total ", total)
						var diffMs = total;
						var diffDays = Math.floor(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						// var sr = params.data.unloading_time_in_hrs;
						// var hrs=sr.toString().split(".");
						// var hours = parseInt(hrs[0]);
						// var mins = Math.round((hrs[1]/100)*60)
						var dataarr = []
						// dataarr.push({
						// 	"from_date":fromField,
						// 	"to_date": toField,
						// 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
						// 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						// })
						let loadingTimeDifference = null;
						if (diffDays) {
							loadingTimeDifference = diffDays + " Day(s) " + diffHrs + " Hr(s) " + diffMins + " Min(s)";
						} else {
							loadingTimeDifference = diffHrs + " Hr(s) " + diffMins + " Min(s)";
						}
						dataarr.push({
							"from_date": fromField,
							"to_date": toField,
							"unloading_time": loadingTimeDifference
							//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						})
						params.successCallback(dataarr);
						
					}

				}
			});

			if (params.column.colDef.field == 'unloading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}


	onShowDeviate = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			// let eventLabel = googleAnalytics.page.action.deviation;
			// let eventOptions = {
			// 	"category": this.state.pagetitle,
			// 	"action": this.state.eventAction,
			// 	"label": eventLabel,
			// }
			// googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]
					},
					getDetailRowData: async function (params) {
						// console.log("Step 4 ", overspeedData)
						await params.successCallback(overspeedData);
						
					}
				}
			});

			if (params.column.colId == '_id_1' || params.column.colId == '_id_2') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
						
					}
				}
			});
		}

	}

	onRowClicked(params) {
		//console.log("row click ", params)
		if (params.column.colId == '_id_1' || params.column.colId == '_id_2') {

			params.node.setExpanded(!params.node.expanded);
		}
		else {

			params.node.setExpanded(false);
		}

	};

	onShowRouteMap(rownode) {
		//console.log("rownode", rownode);
		//console.log("rownode.consignment_code ", rownode.consignment_code)

		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }

		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: finstance,
			recent_instance: rinstance,
			screen: "consignment"
		}
		
		redirectURL.post('/reports/overspeedgpsroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log(response);
				this.setState({
					mapinfo: response.data,
					dealer: rownode.consignee_code,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					overly: 'show-m',
					loadshow: 'show-n',
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				var errDetails={
                    url:"/reports/overspeedgpsroutes",
                    screen:'Trips',
                    error,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
				console.log(error);
			});

	}

	onShowTransitRouteMap(rownode) {
		//console.log("rownode", rownode);
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.leg_start);
		var r = new Date(rownode.leg_end_eta);
		var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Inner Grid ",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Consignment : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						tolls: response.data.tolls

					});
					this.renderMap();

				}
			})
			.catch(function (error) {
				var errDetails={
                    url:"/reports/nightdriveroutes",
                    screen:'Trips',
                    error,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
				console.log(error);
			});

	}
	onShowGoogleRoute(consignmentcode, consigneecode, legno, truck_no) {
		//console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }

		var reqData = {
			consignment_code: consignmentcode,
			consignee_code: consigneecode,
			leg_no: legno,
			truck_no: truck_no
		}
		redirectURL.post('/tracking/googlemaproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				// console.log("Google ", response)
				//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]'
				this.setState({
					googelRoutes: response.data.googleroute
				})
			})
			.catch(function (e) {
				var errDetails={
                    url:"/tracking/googlemaproutes",
                    screen:'Trips',
                    e,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
				console.log(e)
			})
	}
	onShowNoGPSRouteMap(rownode) {
		//console.log("rownode", rownode);
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }

		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Inner Grid ",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Consignment : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
				var errDetails={
                    url:"/reports/nightdriveroutes",
                    screen:'Trips',
                    error,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
			});

	}

	onShowNightDriveRouteMap(rownode) {
		//console.log("nightdrivenode", rownode);
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		this.setState({
			loadshow: 'show-m'
		});
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		//var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

		var fintance = getHyphenYYYYMMDDHHMMSS(rownode.first_instance);
		var eintance = getHyphenYYYYMMDDHHMMSS(rownode.recent_instance);

		var params = {
			truck_no: rownode.truck_no,
			first_instance: fintance,
			recent_instance: eintance,
		}

		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("nightroutedata", response.data);

				this.setState({
					mapinfo: response.data,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					defTransitCoords: { "lat": rownode.first_lat, "lng": rownode.first_lng },
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					loadshow: 'show-n',
					overly: 'show-m',
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				var errDetails={
                    url:"/reports/nightdriveroutes",
                    screen:'Trips',
                    error,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
				console.log(error);
			});


	}
	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			sliderCommentTranslate: "",
			slideBar: ''
		});

	}

	hideSlideBlock = () =>{
		$("#foNumber").val('')
		$('#fo_date').val('')
        this.setState({
            overlyRing:'show-n',
			sliderRouteTranslate:"",
			overly: 'show-n',
			showSlideBlockUpdate:'',
			overlayBlock:'show-n'
        })
    }

	closeAlert = () => {
		this.setState({
			show: false,
			loadshow: 'show-n',
			overlyRing:'show-n',
		});
		// window.location.reload()
	}



	onClickCounterShowData(params) {
		// console.log("Counter Params Here ", params);
		this.setState({
			loadshow: 'show-m'
		});
		var filterInstance;
		let filterActivated = false;
		this.gridApi.setFilterModel(null);
		this.gridApi.onFilterChanged();
		console.log("params ", params)
		switch (params) {
			case "transit_delay":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "inside_plant":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
			case "in_transit_to_customer":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
			case "reached_customer":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
			case "is_gps_working":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "no_gps_data_lifetime":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "overspeeding_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "nightdriving_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "loading_delay_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "unloading_delay_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "enroute_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "sim_issue":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "distance_deviation":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "deviation_in_customer_serving":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "sequence_not_followed":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			default:
				//console.log("filterDeavtivated params = ", params);
				filterActivated = false;
		}
		// console.log("filterActivated ", filterActivated)
		// if (filterActivated) {
		// 	filterInstance.selectNothing();
		// 	let isFilterDataAvailable = this.state.allRowData.filter(rec => {
		// 		if (rec[params]) {
		// 			return rec[params] == 1;
		// 		}
		// 	});
		// 	//console.log("isFilterDataAvailable ", isFilterDataAvailable)
		// 	if (isFilterDataAvailable.length) {
		// 		filterInstance.selectValue("1");
		// 	}
		// 	//console.log("filterInstance ", filterInstance)
		// 	filterInstance.applyModel();
		// 	this.gridApi.onFilterChanged();
		// 	this.setState({
		// 		activeFilter: params
		// 	});
		// }

		if (filterActivated) {
			// console.log(filterActivated,"filterActivated")
			// console.log(this.state.allRowData,"all rowData")
			
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if(params == "is_gps_working")
				{
					return (rec['is_gps_working'] == "NOT_WORKING");
				}
				else{
					if(params == "inside_plant") {
						if(rec["status"] === 2 || rec["status"] === 3){
							return true
						}
					} if(params == "in_transit_to_customer") {
						if(rec["status"] === 8){
							return true
						}
					} if(params == "reached_customer") {
						if(rec["status"] === 11){
							return true
						}
					} if(params == "distance_deviation") {
						if(rec["distance_deviation"] === 1){
							return true
						}
					}if(params == "deviation_in_customer_serving") {
						if(rec["deviation_in_customer_serving"] === 1){
							return true
						}
					}if(params == "sequence_not_followed") {
						if(rec["sequence_not_followed"] === 1){
							return true
						}
					}
				}
			});
			// console.log("filterInstance ", this.gridApi.getFilterInstance(params))
			// console.log("isFilterDataAvailable ", isFilterDataAvailable)
			if (isFilterDataAvailable.length) {
				//this.gridApi.getFilterInstance(params).selectNothing();
				//filterInstance.selectValue("1");
				//filterInstance.applyModel();
				this.gridApi.setRowData(isFilterDataAvailable);
				this.gridApi.onFilterChanged();
				this.setState({
					loadshow: 'show-n'
				});
			}
			else {
				// filterInstance.selectNothing();
				//filterInstance.selectValue("");
				// filterInstance.applyModel();
				this.gridApi.setRowData([]);
				this.gridApi.onFilterChanged();
				this.setState({
					loadshow: 'show-n'
				});
			}
		}
		else {
			this.gridApi.setRowData(this.state.allRowData);
			this.gridApi.onFilterChanged();
			this.setState({
				loadshow: 'show-n'
			});
		}


	}



	onSelectTrip = triptype => {
		this.setState(
			{ triptype },
			// () => console.log(`Trip Option selected:`, this.state.triptype)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onClickTripButton = async () => {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Filter Button",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }

		this.setState({
			loadshow: 'show-m'
		});
		//console.log("Buton this.state.triptype ", this.state.triptype)
		var selectcons = []
		if (this.state.consigner.length > 0) {
			this.state.consigner.forEach(item => {
				selectcons.push(item.value);
			});
		}

		if (this.state.triptype.value != "0") {
			var tdata = {
				triptype: this.state.triptype.value,
				consigner_code: selectcons
			}
			await redirectURL.post("/tracking/triptrucks", tdata)
				.then((response) => {
					// console.log("Trip array ", response.data)
					var respData = response.data;
					if(localStorage.getItem("user_type") == "CUSTOMER")
					{
						if(respData.length > 0)
						{
							respData = respData.filter((t) => t.is_tracking_available == 1);
						}
					}
					this.setState({
						tripvalue: this.state.triptype.value,
						rowData: respData,
						allRowData: respData,
						loadshow: 'show-n',
						//consigner:''
					});
				})
				.catch(function (e) {
					var errDetails={
						url:"/tracking/triptrucks",
						screen:'Trips',
						e,
									}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					console.log("Counters Grid Error ", e)
				})

		}
		else {
			// console.log("this.state.allRowData ", this.state.originalData)
			this.setState({
				rowData: this.state.originalData,
				allRowData: this.state.originalData,
				tripvalue: this.state.triptype.value,
				loadshow: 'show-n'
			});
		}



	}

	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			//   () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	changeZone = (zone) => {
		var plantNames = []
		let locationNamesArr = this.state.locationNames;
		
		this.setState({
			locationNames: [{value:"", label:"ALL"}]
		})
		this.setState({ zone },
			() => {
				// console.log(zone,"zone")
				let boundLocations = this.state.boundLocationsRec;
				// console.log(locationNamesArr,"locationNamesArr")
				if(zone.value === "ALL"){
					plantNames = this.state.originalLocationNames
				} else{

					let filterLocations = boundLocations.filter((e)=>{
						if(e.location_zone && zone.value !=="ALL"){
								return e.location_zone.toLowerCase() === zone.value.toLowerCase()
						}
					}).map((e) => ({
						location_name: e.location_name,
						location_code: e.location_code
					  }));
	
					// console.log(filterLocations,"filterLocations")
	
					if (filterLocations.length > 0) {
						filterLocations.forEach((e) => {
						  plantNames.push({ value: e.location_code, label: e.location_name });
						});
					} else {
						plantNames = locationNamesArr;
					}
				}

				const labelExists = plantNames.some(plant => plant.label === "ALL");
				if (!labelExists) {
					plantNames.unshift({ label: "ALL", value: "" });
				}
				
				this.setState({
					locationNames: plantNames,
					errorMessage: '',
					successMessage: ''
				})
			}
		);
		
	}
	// selectConsignerOptionsItems() {
	// 	let items = [];

	// 	var plants = this.state.plants;

	// 	items.push({ value: 'ALL', label: 'All' });
	// 	if (plants.length > 0) {
	// 		plants.forEach(item => {
	// 			if (this.state.loginplant != "" && this.state.loginplant != undefined) {
	// 				if (this.state.loginplant == item.plant_code) {
	// 					items.push({ value: item.plant_code, label: item.plant_name });
	// 				}

	// 			}
	// 			else {
	// 				items.push({ value: item.plant_code, label: item.plant_name });
	// 			}

	// 		});
	// 	}

	// 	// console.log(items,"items---3272")
	// 	return items;
	// }

	newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}

	newSelector = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}

	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		this.pre_sdate=this.state.startDate
		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		var pre_sdate=this.state.startDate
		this.setState({
			startDate: startdate,
			pre_sdate:pre_sdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		// console.log(event,"selectedDate")
		var d = new Date(event._d);
		
		// this.pre_edate=this.state.endDate
		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		var pre_edate=this.state.endDate
		this.setState({
			endDate: edate,
			pre_edate
		});
	}
	onChangeStartDate(event) {
		var pre_sdate=this.state.startDate
		this.setState({
			pre_sdate,
			startDate: event.target.value,
		})
	}
	onChangeEndDate(event) {
		var pre_edate=this.state.endDate
		console.log(this.state.endDate,"aaaaaaa")
		this.setState({
			pre_edate,
			endDate: event.target.value
		})
	}
	onGetConsigners = async (event) => {
		let logParams = {
            // "location_code" : location.value,
            // "location_name" : location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Trips",
            "activity" : 'clicked on Submit Button for select plant/Dealer Centers filter',
            "event" : "Data in the trips aggrid filtered",
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
		this.setState({loadshow:'show-m',})
		var sDate=this.state.startDate;
		var eDate = this.state.endDate;
		// if(sDate !=undefined && sDate !="" && eDate !=undefined && eDate !="")
		// {
		// 	var start = sDate.split(" ")
		// 	sDate = start[0]
		// 	var end = eDate.split(" ")
		// 	eDate = end[0]
		// }
		
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Filters",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }


		// var sDate = document.getElementById("from_date").value;
		// var eDate = document.getElementById("to_date").value;
		// var sDate = this.state.startDate
		// console.log(sDate,"sDate12")
		// var eDate = this.state.endDate
		// console.log(eDate,"eDate21")

		// this.setState({
		// 	// startDate:moment(sDate).format("DD-MM-Y"),
		// 	// endDate:moment(eDate).format("DD-MM-Y"),
		// 	loadshow: 'show-m'
		// });

		var selectcons = []
		//console.log("this.state.consigner.length ", this.state.consigner.length)
		var datechk = 1;
		var errpopmsg = "";
		
		if(sDate != undefined && sDate != "")
		{
			// console.log(eDate,"eDate")
			if(eDate == undefined || eDate == "")
			{
				datechk = 0;
				errpopmsg = "Please select Invoice To Date"
			}
		}

		if(eDate != undefined && eDate != "")
		{
			if(sDate == undefined || sDate == "")
			{
				// console.log(sDate,"sDate")
				datechk = 0;
				errpopmsg = "Please select Invoice From Date"
			}
		}
		if(sDate != undefined && sDate != "" && eDate != undefined && eDate != ""){
			// console.log(new Date(this.state.startDate) >=new Date(this.state.endDate))
			
			if(new Date(this.state.startDate) >=new Date(this.state.endDate))
			{
				// console.log('hit')
				datechk = 0;
				errpopmsg = "Invoice From Date should be less than Invoice To Date"
				this.setState({startDate:this.pre_sdate,endDate:this.pre_edate})
				
			}
		}
		if(datechk === 1){
			if(localStorage.getItem("pc") !=undefined && localStorage.getItem("pc") !="")
			{
				selectcons.push(localStorage.getItem("pc"));
			}
			else if (this.state.consigner) {
				// console.log(this.state.consigner,"ddddddddddddddddddddddddddddddddd")
				if (this.state.consigner.value !== "") {
					selectcons = this.state.consigner.value
				}
				else {
					selectcons = "ALL";
				}
			}
			else {
				selectcons = "ALL";
			}


			var parameters = {
				consigner_code: selectcons,
				dept_code: this.state.deptcode,
				from_date: sDate,
				to_date: eDate,
				zone: this.state.zone
			}
			// console.log(localStorage.getItem("pc"),"plant")
			if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
			{
				parameters.transporter_code = localStorage.getItem("transportercode")
			}
			if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
			{
				parameters.customer_code = localStorage.getItem("customer_code")
			}
			if (this.state.deptcode == 'SNDG') {
				let zone = this.state.flZone
				if (zone !== "" && zone !== undefined && zone !== null) {
					parameters['zone'] = [zone.value]
				}
			}

			let transporter = this.state.flTransporter
			// console.log("transporter type", typeof (transporter.value))
			if (transporter !== "" && transporter !== undefined && transporter !== null) {
				if (transporter.value != "") {
					// parameters['transporter'] = [transporter.label]
					parameters['transporter_code'] = JSON.stringify([transporter.value])
				}
			}
			let flTransporterZone = this.state.flTransporterZone;
			if (flTransporterZone !== "" && flTransporterZone !== undefined && flTransporterZone !== null) {
				if (flTransporterZone.value != "") {
					// parameters['transporter'] = [transporter.label]
					parameters['transporter_zone'] = [flTransporterZone.value]
				}

			}
			let city = this.state.flCity
			let flCustomer = this.state.flCustomer
		
			if (city !== "" && (city) !== undefined && city !== null) {
				if (city.value != "") {
					parameters['city'] = [city.value]
				}
			}
			if (flCustomer !== "" && (flCustomer) !== undefined && flCustomer !== null) {
				var flList = []; 
				flCustomer.map((fl) => {
					flList.push(fl.value);
				});
				if (flList.length > 0) {
					parameters['flCustomer'] = flList
				}
			}
			var urlpath;
			// console.log(parameters,"parameters")
			// console.log(urlpath,"123")

			this.onLoadShowTrucksList(urlpath, parameters)
		}
		else{
			var pre_edate=this.state.pre_edate
			var pre_sdate=this.state.pre_sdate
			this.setState({
				show: true,
				basicTitle: errpopmsg,
				basicType: "warning",
				startDate:pre_sdate,
				endDate:pre_edate
			})
		}
			
	}
	

	onShowTruckConsignments = async (legData) => {
		//console.log("legData", legData)
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Show Legs",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }

		await this.setState({

			detailCellRendererParams: {
				detailGridOptions: {
					columnDefs: [
						{
							headerName: "Consignment",
							field: "consignment_code"
						},
						{
							headerName: "Expected Trip End",
							field: "expected_trip_end"
						},
						{
							headerName: "Revised Trip End",
							field: "revised_trip_end"
						},
						// {
						// 	headerName: "Invoice Time",
						// 	field: "invoice_time"
						// },
						// {
						// 	headerName: "Gate In Time",
						// 	field: "gate_in_time"
						// },
						// {
						// 	headerName: "Gate Out Time",
						// 	field: "gate_out_time"
						// },
						{
							headerName: "Status",
							field: "status"
						},
						{
							headerName: "Consigner Code",
							field: "consigner_code"
						},
						{
							headerName: "Cusomter Code",
							field: "consignee_code"
						},

						// {
						// 	headerName: "Cluster",
						// 	field: "cluster"
						// },

						{
							headerName: "Transit Time",
							field: "transit_time"
						},

					]
				},
				getDetailRowData: async function (params) {
					var data = params.data.leg_details;
					//console.log("Data ", params)
					var legs = JSON.parse(data);
					//console.log("Data ", legs)
					await params.successCallback(legs);
					
				}
			}
		});
		if (legData.column.colDef.field == 'truck_no') {

			legData.node.setExpanded(!legData.node.expanded);
		}
		else {

			legData.node.setExpanded(false);
		}
	}

	onClickShowMapView = async () => {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }

		//this.gridApi.deselectAll();
		if (this.state.containerslist.length > 0) {
			var reqparms = {
				truck_no: this.state.containerslist,
				dept_code: this.state.deptcode
			}
		}
		else {
			var reqparms = {
				truck_no: [],
				dept_code: this.state.deptcode
			}
		}

		await redirectURL.post("/tracking/mapview", reqparms)
			.then((response) => {
				//console.log("Res ",response.data.message)
				var records = response.data.message;

				var truck = JSON.parse(records.truck_info);
				if (truck.length > 0) {
					if (truck.length > 1) {
						var consinees = this.state.plants;
					}
					else {
						var consinees = records.consignee_coordinates;
					}
				}
				else {
					var consinees = [];
				}
				// console.log("truckinfo,", truck)
				//console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
				this.setState({
					open: true,
					maprowData: truck,
					rownode: truck,
					maptruckno: records.truck_no,
					consigneecoords: consinees,
					consigner_coordinates: records.consigner_coordinates
				});


				//containerslist=[];
			})
			.catch(function (e) {
				var errDetails={
                    url:"/tracking/mapview",
                    screen:'Trips',
                    e,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
				console.log("Error ", e)
			})
	}
	onRowSelection(event) {
		var rowIds = [];
		var rwCount = event.api.getSelectedNodes();
		rwCount.map((item) => {
			rowIds.push(item.data.shipment_id)
		});

		this.setState({
			rowIds: rowIds
		})
		console.log("checkedlist ", rowIds)
	}

	

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ open: false });
		this.restoreGridStates()
	};
	// onClickHideAll(){
	// 	this.setState({
	// 		loadshow:'show-n',
	// 		overly:'show-n',
	// 		sliderAddTransporter:'',
	// 		sliderEditTransporter:""
	// 	});
		
    // }
	formForEdit(event){
		event.preventDefault();
		var permit_expiry_date = $("#permit_expiry_date").val();
		var consignment_date = $("#consignment_date").val();
		// var invoice_date = $("#invoice_date").val()
		console.log(consignment_date,"consignment_date")
		var transporter_code = this.state.transporterCode;
		var transporter_name = this.state.transporter_name;
		var driver_code = this.state.driver_code;
		var driver_name = this.state.driver_name;
		var mobile_no = this.state.mobile_no;
		var invoice_no=this.state.invoice_no;
		var mobile_network = this.state.mobile_network;
		var consignment_date = consignment_date ;
		var invoice_date = invoice_date
		var consignee_code = this.state.consignee_code
		var old_consignee_code = this.state.old_consignee_code
		var ofs_no = this.state.ofs_no
		var params = {
			driver_code:driver_code,
			driver_name:driver_name,
            mobile_no:mobile_no,
			transporter_code:transporter_code,
			invoice_no:invoice_no,
			mobile_network:mobile_network,
			consignment_date:consignment_date,
			// invoice_date:invoice_date,
			invoice_date:consignment_date,
			truck_no:this.state.selectedtruck,
			shipment_id:this.state.selectedshipment_id,
			consigner_code:this.state.selectedconsigner_code,
			consignment_code:this.state.consignmentCode,			
			transporter_name:transporter_name,
			consignee_code:consignee_code,
			old_consignee_code:old_consignee_code,
			ofs_no:ofs_no,
			permit_expiry_date:permit_expiry_date,
		}
		if(mobile_network.value=="jio"){
			params.sim_tracking_service_provider = 1 
		}
		else if(mobile_network.value=="non_jio"){
			params.sim_tracking_service_provider = 2 
		}
			
		// console.log(params,"paramsforedit")
		
		redirectURL.post("/tracking/updatedetails",params)
			.then((response) => {
				if(response.data.status == "success")
				{
					this.setState({
						show:true,
						basicTitle:"Updated Successfully",
						basicType:"success",
						driver_code:"",
						driver_name:"",
						assigned_truck_no:"",
						is_active:0,
						sliderEditTransporter:"",
						overly:"show-n"
					})
					var reqparams = {
					}
					try{
						if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
						{
							reqparams.consigner_code =localStorage.getItem("pc");
							this.setState({
								consigner:[localStorage.getItem("pc")]
							})
						}
						else{
							reqparams.consigner_code = "ALL"
						}
					}catch(e){
						
					}
					if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
					{
						reqparams.transporter_codes = localStorage.getItem("transportercode")
					}
					if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
					{
						reqparams.customer_code = localStorage.getItem("customer_code")
					}
					this.onLoadShowTrucksList("urlPath",reqparams);
				}
				else{
					this.setState({
						show:true,
						basicTitle:response.data.message,
						basicType:"danger"
					})
				}
				
			})
			.catch((err)=>{
					var errDetails={
						url:"/tracking/updatedetails",
						screen:'Trips',
						err,
					}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					
				})
		



	}
	changeAlphaNumericHandler(event){
        console.log(event.target.name,"431")
        var name= event.target.name;
        var value = event.target.value;
        // const re = /^[A-Za-z]+$/;
        // if(value != "")
        // {
        //     if(validAlphaNumericSpace.test(value) == true)
        //     {
                

                this.setState({
                    [name]:value
                })
        //     }
        // }
        // else{
            

        //     this.setState({
        //         [name]:value
        //     })
        // }
        
    }
	
	changeHandler(event){
		var name = event.target.name;
		var value = event.target.value;
		this.setState({[name]:value});
	}
	changeMobileNoHandler(event){
        var name= event.target.name;
        var value = event.target.value;

		const re = /^[0-9\b]+$/;
		if(isNaN(value) == true)
		{

		}
		else{
			if (((value === '') || (re.test(value))) && (value.length <= 10)) {
				this.setState({ 
					mobile_no: value,
					err_mobile:""
				});
			}
			else{
				this.setState({
					mobile_no: value,
					err_mobile:""
				})
			}
		}
        
    }
	OnBlurMobile(event){
		var name= event.target.name;
        var value = event.target.value;

		const re = /^[0-9\b]+$/;
        console.log("Value ", ((value === '') +"&&"+ (re.test(value))) +"&&"+ (value.length < 10));

		if(value == "")
		{
			this.setState({ err_mobile:"Please enter mobile no",mobile_no:"" });
		}
		else{
			if ((re.test(value)) && (value.length < 10))
			{
				this.setState({ err_mobile:"Invalid Mobile number",mobile_no:"" });
			}
			else if((value.length > 10))
			{
				this.setState({ err_mobile:"Invalid Mobile number",mobile_no:"" });
			}
		}
       
	}
	


	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	};

	onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ openforceclose: false });
	};
	onclickdownloadbtn=()=>{

	}
	onClickCreateshipment(){
		window.location.href="/create-shipment"
	}
	onClickDeviceStatus=(rowdata)=>{
		this.setState({loadshow:'show-n'})
		var row = rowdata.data 
		var DeviceStatus = row.device_status 
		var shipment_id = row.shipment_id
		var params = {
			device_status:DeviceStatus,
			updatedOn:moment.parseZone().format("YYYY-MM-DD HH:mm:ss"),
			email:localStorage.getItem("email"),
			shipment_id:shipment_id
		}
		console.log(params,"params")
		
		redirectURL.post("/tracking/saveDeviceStatus",params)
		.then((resp)=>{
			if(resp.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully Updated",
                    basicType:"success",
                })
            }
		})
		.catch((err)=>{
					var errDetails={
						url:"/tracking/saveDeviceStatus",
						screen:'Trips',
						err,
					}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					
				})
	

	}
	onClickEdit=(params)=>{
		var consignment_date = "";
		if(params.data.shipment_time !=undefined && params.data.shipment_time !="")
		{
			consignment_date = getHyphenYYYYMMDDHHMMSS(params.data.shipment_time)
		}
		var mobile_network= "";
		if(params.data.sim_tracking_service_provider_name !=undefined && params.data.sim_tracking_service_provider_name !="")
		{
			mobile_network = { label: params.data.sim_tracking_service_provider_name.toUpperCase(), value: params.data.sim_tracking_service_provider_name }
		}
		var consignee_code = "";
		if(params.data.sim_tracking_service_provider_name !=undefined && params.data.sim_tracking_service_provider_name !="")
		{
			consignee_code = { label: params.data.consignee_name+"-"+params.data.consignee_city+"-"+params.data.consignee_pincode, value: params.data.consignee_code }
		}
		var permit_expiry_date = "";
		if(params.data.permit_expiry_date !=undefined && params.data.permit_expiry_date !="")
		{
			permit_expiry_date = getHyphenYYYYMMDDHHMMSS(params.data.permit_expiry_date)
		}
		var invoice_date = "";
		if(params.data.invoice_time !=undefined && params.data.invoice_time !="")
		{
			invoice_date = getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
		}
		this.setState({
			selectedtruck:params.data.truck_no,
			selectedshipment_id:params.data.shipment_id,
			transporter_code:params.data.transporter_code,
			transporter_name:params.data.transporter_name,
			driver_name:params.data.driver_name,
			mobile_no:params.data.driver_mobile_no,
			mobile_network:mobile_network,
			invoice_no:params.data.sales_order,
			consignmentCode:params.data.consignment_code,
			consignment_date:consignment_date,
			invoice_date:invoice_date,
			permit_expiry_date:permit_expiry_date,
			ofs_no:params.data.ofs_no,
			consignee_code:consignee_code,
			old_consignee_code:consignee_code,
			sliderEditTransporter:'slider-translate',
			overly:"show-m"
		});
		// var permit_expiry_date = $("#permit_expiry_date").val(permit_expiry_date);
		// var consignment_date = $("#consignment_date").val(consignment_date);
		// var invoice_date = $("#invoice_date").val(invoice_date)
	}
	


	onClickSaveGridState =async()=> {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Save Grid Layout",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		
			// console.log(this.gridApi,"this.gridApi")
			// console.log('hit')
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			// window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();

			let screenpage = '';

			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: [],
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: screenpage
			}
			await redirectURL.post("/tracking/saveGridStates", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully saved grid layout",
						basicType: "success",
						screenurl: window.location.pathname,
						screentitle: screenpage
					})
				})
				.catch(function (e) {
					var errDetails={
						url:"/tracking/saveGridStates",
						screen:'Trips',
						e,
									}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					console.log("Error ", e)
				})
				setTimeout(()=>{
					this.restoreGridStates()
				},1001)		
	}

	restoreGridStates=async()=> {
		// console.log(this.state.usergridstate,"this.state.usergridstate")
		redirectURL.post("/tracking/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				// console.log(resp.data,"usergridstate")
				// await this.setState({
				// 	usergridstate: resp.data
				// });
				if (resp.data.length !== 0) {
					try {
						setTimeout(()=>{
							var windowstates=resp.data
							console.log(windowstates,"windowStates")
							this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
							this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
							// this.gridApi.setSortModel(windowstates[0].gridcolsort);
							this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
						},1000)
						
		
					}
					catch (e) {
		
					}
				}
				// setTimeout(()=>{
				// 	this.restoreGridStates();
				// },1000);
			})
		
	}

	resetState =async () => {

		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Reset Grid Layout",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		try {
			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			// this.gridApi.setSortModel(null);
			// console.log('hit')
			this.gridApi.setFilterModel();
			//console.log('column state reset');
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			// window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();

			let screenpage = '';

			var reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: [],
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: screenpage
			}
			// console.log("reqparams ", reqparams)
			await redirectURL.post("/tracking/saveGridStates", reqparams)
				.then((response) => {
					//console.log("State response ",response.data)
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
				.catch(function (e) {
					var errDetails={
						url:"/tracking/saveGridStates",
						screen:'Trips',
						e,
									}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					console.log("Error ", e)
				})
				setTimeout(()=>{
					this.restoreGridStates()
				},1000)
			// redirectURL.post("/tracking/removeUserGridState", reqparams)
			// 	.then((response) => {
			// 		this.setState({
			// 			show: true,
			// 			basicTitle: "Successfully reset default grid layout",
			// 			basicType: "success"
			// 		})
			// 	})
			// 	.catch((err)=>{
			// 			var errDetails={
			// 				url:"/tracking/removeUserGridState",
			// 				screen:'Trips',
			// 				err,
			// 			}
			// 			redirectURL.post("/master/logErrorsForApiCalls",errDetails)
						
			// 		})
			
		}
		catch (e) { }

	};
	selectMovementTypeOptionsItems() {
		let items = [];
		items.push({ value: "all", label: "All" });
		items.push({ value: true, label: "Local Movement" });
		items.push({ value: false, label: "Non Local Movement" });
		return items;
	}

	getTruckConsignments =async (event) => {
		// console.log('hello')
		let logParams = {
            // "location_code" : location.value,
            // "location_name" : location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Trips",
            "activity" : 'clicked on Submit Button for truck/shipment filter',
            "event" : "Data in the trips aggrid filtered",
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
		this.setState({
			loadshow: "show-m",
			overly: "show-m",
			// assignVehicleloader:'show-m',
			
		})
		// this.setState({
		// 	assignVehicleloader:'show-m',
		// 	})
		// console.log('hit')
		event.preventDefault();
		
		var reqparams={
			
			from_date:this.state.startDate,
			to_date:this.state.endDate,
			screen_type:this.state.screen_type,
			data_type:'allTrucks',
			priority:this.state.priority,
			showRail:0,
			consigner_code:this.state.consignee_code,
			priority:0,
			showRail:0
		}
		// console.log('reqparams',reqparams)
		var trucksArr=[]
		await redirectURL.post('/tracking/intranitRoadMobileData', reqparams)
		.then(async(response) => {
			// console.log('hitted')
				
			 trucksArr = response.data;
			// this.setState({assignVehicleloader:'show-n',})

			// console.log(trucksArr,"trucksArr")
			if(trucksArr.length > 0)
			{
				trucksArr = trucksArr.filter((ta)=>ta.status <= 15);
			}
			if (typeof response.data == 'object') {
				var recordsarr = response.data;
			}
			else {
				var dd = response.data;
				var recordsarr = JSON.parse(response.data);
			}
			var records = [];
			
			var counters = recordsarr.counters_data;
			records = records.sort(GetSortDescOrder("invoice_time"));
			var lastRunTime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
			// console.log(trucksArr,"trucksArr1")
			await this.setState({
				rowData: trucksArr,
				allRowData: trucksArr,
				originalData: trucksArr,
				lastRunTime:lastRunTime,
				loadshow: 'show-n',
				overly: 'show-n',
				pagecounters: counters
			});
		})
		.catch(function (error) {
			// console.log('error met')
			var errDetails={
				url:"/tracking/intranitRoadMobileData",
				screen:'Trips',
				error,
							}
			redirectURL.post("/master/logErrorsForApiCalls",errDetails)
			console.log(error);
		});
		// console.log(reqparams)
		// var selectedFilter = $("#selectedFilter").text();
		let selectedFilter = this.state.selectedFilter
		// console.log("selectedFilter", selectedFilter)
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		// console.log("selFilter",inputTruckConsignment)
		
		if (selectedFilter == "" || inputTruckConsignment == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true,
				loadshow: "show-n",
				overly: "show-n"
			})
		}
		else {
			// console.log('hit',inputTruckConsignment)
			// const { allRowData } = this.state
			// console.log("allRowData",trucksArr )
			// console.log(selectedFilter.value,'Truck')	
			if (selectedFilter.value == "Truck") {
				let filteredData = trucksArr.filter(item => item.truck_no.includes(inputTruckConsignment))
				// console.log("filteredData", filteredData)
				// this.gridApi.setRowData(filteredData);
				// this.gridApi.onFilterChanged();
				this.setState({
					rowData: filteredData,
					 allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});

			}
			if (selectedFilter == "GR No") {
				let filteredData = trucksArr.filter(item => item.consignment_codes.includes(inputTruckConsignment))

				// console.log("Consingments", filteredData)
				this.setState({
					rowData: filteredData,
					 allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"

				});
			}
			if (selectedFilter == "Invoice") {
				let filteredData = trucksArr.filter(item => item.sales_order.indexOf(inputTruckConsignment) >= 0)
				console.log("Invoice", filteredData)
				this.setState({
					rowData: filteredData,
					 allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});
			}
			if(selectedFilter.value == "shipment_id"){
				let filteredData = trucksArr.filter(item => item.shipment_id.includes(inputTruckConsignment))
				// console.log("Shipment", filteredData)
				this.setState({
					rowData: filteredData,
					 allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});
			}
		}
	}

	reloadPageData = () => {
		window.location.reload();
	}

	onShowDayWiseDistanceTrip = async (params) => {
		// console.log("params", params)
		// let eventLabel = googleAnalytics.page.action.nightDrive;
		// let eventOptions = {
		// 	"category": this.state.pagetitle,
		// 	"action": this.state.eventAction,
		// 	"label": eventLabel,
		// }
		// googleAnalytics.logEvent(eventOptions);
		if (params != 0) {
			var transitedelaydata = [];
			var checkboxSelectionval = true
			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "",
								field: "_id",
								cellRendererFramework: RouteMapForDayWiseDistanceTrip,
								width: 50,
								suppressSizeToFit: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},

							{
								headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "Distance Travelled(Km)",
								field: "distance_travelled_km",
								width: 180,
								resizable: true,
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "Day",
								field: "day",
								width: 180,
								resizable: true,
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "Start time",
								field: "start_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.start_time);
								},
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "End time",
								field: "end_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.end_time);
								},
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: function (param) {
						//console.log("Step 4 ",transitedelaydata)
						// console.log("Step 4 ",param)
						
						redirectURL.post('/tracking/getDayWiseDistanceTravelled', {
							invoice_nos: param.data.invoice_nos,
							invoice_time: getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
						})
							.then(async (response) => {
								// console.log("Step 1 ", response.data)
								transitedelaydata = response.data;
								var legsarr = []
								if (transitedelaydata.length !== 0)
									transitedelaydata.map((item) => {
										//if(item.trip_completed < 2)
										//{
										legsarr.push(item)
										//}
									})
								// console.log('transitedelaydata ', transitedelaydata);

								param.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							})
							.catch((err)=>{
										var errDetails={
											url:"/tracking/getDayWiseDistanceTravelled",
											screen:'Trips',
											err,
										}
										redirectURL.post("/master/logErrorsForApiCalls",errDetails)
										
									})
						
					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'day_wise_distance_trip' || params.column.colDef.field == 'day_wise_distance_trip') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {
				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			// console.log("elseData")
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Distance Travelled(Km)", field: "distance_travelled_km" },
							{ headerName: "Day", field: "day" },
							{ headerName: "Start Time", field: "start_time" },
							{ headerName: "End Time", field: "end_time" }
						],
						defaultColDef:this.state.defaultColDef,
						statusBar:this.state.statusBar,
						sideBar:this.state.sideBar,
						enableRangeSelection:true

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
						
					}
				}
			});
		}
	}

	onShowDayWiseRouteMap = (rownode) => {
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		//console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.end_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			// consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			startDate: fintance,
			endDate: eintance,
			screen: "consignment"
		}
		redirectURL.post('/gmap', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("Inner Grid ",JSON.parse(response.data.data).coords);
				var records = JSON.parse(response.data.data);
				// console.log("records ", records)
				if (records.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					// if (records.route_details.start_time != '' && records.route_details.start_time != undefined) {
					// 	sdate = records.route_details.start_time;
					// }
					// if (records.route_details.end_time != '' && records.route_details.end_time != undefined) {
					// 	edate = getDDMMYYYYHHMMSS(records.route_details.end_time)
					// }
					this.setState({
						mapinfo: records,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Truck No : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": fintance, "endTime": eintance },
						loadshow: 'show-n',
						sidebarSubHeader: "View Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				var errDetails={
                    url:"/gmap",
                    screen:'Trips',
                    error,
                                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
				console.log(error);
			});

	}
	onClickShowLoadingAreaLegs = async (rownode) =>  {
        console.log("rownode", rownode)
        var hideForCustomer="";
		var hideForNotCustomer="";
		var hideForCustomer = false;
		var hideForNotCustomer = true;
        var commentHeaders = [        
            {
                headerName : Constant.COL_CONSIGNMENT_CODE,
                field : "consignment_code",
                resizable : true,
                width:140,
                filter : true
            }, 
			{
                headerName : Constant.COL_STATUS,
                field : "status",
                resizable : true,
                width:140,
                filter : true,
				valueGetter: function (params) {
					//console.log("GPS ", params.data.gps_data_available);
					if(params.data.status == 1)
					{
						return Constant.INSIDE_TPA;
					}
					else if(params.data.status == 2)
					{
						return Constant.INSIDE_MY_PMILL;
					}
					
					if(params.data.status == 1 || params.data.status == "1")
					{
						return Constant.INSIDE_TPA;
					}
					if(params.data.status == 2 || params.data.status == "2")
					{
						return  Constant.INTRANSIT_TO_MY;
					}
					if(params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5")
					{
						return  Constant.INSIDE_MY_PMILL;
					}			
					else if(params.data.status == 6)
					{
						return Constant.FORCE_CLOSE;
					}
					else if(params.data.status == 7)
					{
						return Constant.INSIDE_TPA_AFTER_LOADING;
					}
					else if(params.data.status == 8 )
					{
						return Constant.INTRANSIT;
					}
					else if(params.data.status == 9 )
					{
						return Constant.NEAR_CUSTOMER;
					}
					else if(params.data.status == 10 )
					{
						return Constant.REACHED_CUSTOMER;
					}
					else if(params.data.status == 11 )
					{
						return Constant.LEFT_CUSTOMER;
					}
					else{
						return ""
					}
				}
            },
            {
                headerName:Constant.COL_DELIVERY_NO,
                field:"delivery_no",
                width:120,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.COL_SALE_ORDER,
                field:"sales_order",
                width:120,
                resizable : true,
                filter : true,
            },
            
            {
                headerName:Constant.COL_ITEM_NO,
                field:"item_no",
                width:120,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.COL_ITEM_DESC,
                field:"item_desc",
                width:200,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.COL_ITEM_QTY,
                field:"item_quantity",
                width:120,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.MATERIAL_INFO,
                field:"material_desc",
                resizable : true,
                filter : true,
                width:120,
            },
			
            {
                headerName:Constant.SR_GRADE,
                field:"sr_grade",
                resizable : true,
                filter : true,
                width:120,
            },            
            {
                headerName:Constant.COL_CUSTOMER_NAME,
                field:"consignee_name",
                width:200,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.COL_CUSTOMER_CODE,
                field:"consignee_code",
                width:120,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.COL_CUSTOMER_CITY,
                field:"consignee_city",
                width:140,
                resizable : true,
                filter : true,
            },
			{
                headerName:Constant.COL_CONSIGNEE_STATE,
                field:"consignee_state",
                width:140,
				valueGetter:function(params){
					try{
						if(params.data.consignee_state != "" && params.data.consignee_state != undefined)
						{
							return params.data.consignee_state;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				},
                resizable : true,
            },
            {
                headerName:Constant.COL_CONSIGNEE_ADDRESS,
                field:"consignee_address",
                width:200,
				valueGetter:function(params){
					try{
						if(params.data.consignee_address != "" && params.data.consignee_address != undefined)
						{
							return params.data.consignee_address;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				},
                resizable : true,
            },
			{
				headerName: "Original ETA",
				field: "expected_trip_end",
				width: 120,
				resizable: true,
				hide:hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				valueGetter: function (params){
					if(params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
					}else{
						return "N/A"
					}
				},
				comparator: dateComparator,
			},
			{
				headerName: "Revised ETA",
				field: "revised_trip_end",
				width: 120,
				resizable: true,
				hide:hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				valueGetter: function (params) {
					//console.log(" ETA ", params.data)
					if(params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined)
						{
						  if(params.data.expected_trip_end == '' || params.data.expected_trip_end == undefined){
							  return "N/A"
						  }
						  else{
							  return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						  }
						}
						else{
							return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
						}
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				cellClass: function (params) {
					let hourDifference = 0;
					if (params.data.revised_trip_end && params.data.expected_trip_end) {
						let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
						let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
						hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
						//console.log("diff= ", params.data.consignment_code, hourDifference);
					} else {
						//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
					}

					if (hourDifference >= 1) {
						return 'bgColorDangerMedium'
					}

					if (hourDifference <= -1) {
						return 'bgColorSuccessMedium'
					}
					else {
						return 'N/A'
					}
				}

			},
            {
                headerName:Constant.COL_EXPECTED_DISTANCE_KM,
                field:"distance_in_km",
                width:180,
                resizable : true,
                filter : true,
            },
			{
				headerName: "Remaining Distance To Customer (KM)",
				field: "distance_from_dealer_location",
				width: 180,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true,
				valueGetter:function(params){
					try{
						if(params.data.distance_from_dealer_location != "" && params.data.distance_from_dealer_location != undefined)
						{
							return params.data.distance_from_dealer_location ;
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			
			{
				headerName: "ETA",
				field: "revised_trip_end",
				width: 120,
				resizable: true,
				hide: hideForNotCustomer,
					valueGetter: function (params) {
					//console.log(" ETA ", params.data)
					if(params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined)
					{
						if(params.data.expected_trip_end == '' || params.data.expected_trip_end == undefined){
							return "N/A"
						}
						else{
							return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						}
					}
					else{
						return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
					}
				},
			}
			
			// {
			// 	headerName: "chettinad Distance (KM)",
			// 	field: "distance_in_km",
			// 	width: 200,
			// 	filter: "agSetColumnFilter",
			// 	hide:hideForCustomer,
			// 	suppressColumnsToolPanel:hideForCustomer,
			// 	cellRenderer: '', resizable: true
			// },

			// {
			// 	headerName: "Transit Time (Days)",
			// 	field: "transit_time",
			// 	width: 200,
			// 	filter: "agSetColumnFilter",
			// 	hide:hideForCustomer,
			// 	suppressColumnsToolPanel:hideForCustomer,
			// 	cellRenderer: '', resizable: true
			// },

        ]
        await this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    if(param.data.leg_details != "" && param.data.leg_details != undefined)
                    {
                        try{
							var output = JSON.parse(param.data.leg_details);
                            output.map((e) => e.type_of_tracking = param.data.type_of_tracking);
							if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		                    {
                                var customer_code = JSON.parse(localStorage.getItem("customer_code"));
								// console.log(customer_code,"customer_code--")
                                if(customer_code.length > 0)
                                {
									var output1 = [];
                                    var otpt = await output.map(async(e) => {
										var customer_codes = e.consignee_code;
										customer_codes = parseInt(customer_codes).toString();
										// console.log(customer_codes,customer_code.indexOf(customer_codes),"testing")
										if(customer_code.indexOf(customer_codes) >= 0)
										{
											output1.push(e);
										}
                                    })
									var output = output1;
                                }             
                            }
                            console.log("output ", output)
                            param.successCallback(output);
                        }
                        catch(e){}
                    }
                     
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'loadingarealegs')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
	
    onClickShowShipmentLegs= async (rownode) =>  {
        // console.log("rownode", rownode)
		let logParams = {
            // "location_code" : location.value,
            // "location_name" : location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Trips",
            "activity" : 'clicked on Shipment Legs icon in the aggrid',
            "event" : "Aggrid opened below the row",
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        var commentHeaders = [
            {
				headerName : "",
				field : "",
				resizable : true,
				width:60,
				filter : true,
                cellRenderer:LegRouteMap,
			},
			{
				headerName : "Truck No",
				field : "truck_no",
				resizable : true,
				width:120,
				filter : true
			},
			{
				headerName : "Area Name",
				field : "fence_name",
				resizable : true,
				width:200,
				filter : true
			},
			{
				headerName : "Entry Time",
				field : "first_inside_fence",
				resizable : true,
				width:160,
				filter : true,
				valueGetter:function(params){
					try{
						if(params.data.first_inside_fence != "" && params.data.first_inside_fence != undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.first_inside_fence);
						}
						else{
							return "";
						}
					}
					catch(e){}
				}
			},
			{
				headerName : "Exit Time",
				field : "first_outside_fence",
				resizable : true,
				width:160,
				filter : true,
				valueGetter:function(params){
					try{
						if(params.data.first_outside_fence != "" && params.data.first_outside_fence != undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.first_outside_fence);
						}
						else{
							return "";
						}
					}
					catch(e){}
				}
				},
			{
				headerName : "Time Spent",
				field : "time_spent",
				resizable : true,
				width:200,
				filter : true,
				valueGetter:function(params){
					try{
						
						return params.data.time_spent;
						
					}
					catch(e){}
				}
			},

        ]
        await this.setState({
			map_type_of_tracking : rownode.data.type_of_tracking,
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
					onCellClicked:this.onShowShipmentLegRouteMap,
					defaultColDef:this.state.defaultColDef,
					statusBar:this.state.statusBar,
					sideBar:this.state.sideBar,
					enableRangeSelection:true
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    // console.log("Params ", param);
                    if(param.data.tpa_entry_time != "" && param.data.tpa_entry_time != undefined){
                        var startdate = param.data.tpa_entry_time;
                    }
                    else{
                        var startdate = param.data.parking_in_time;
                    }
                    var cdate = moment.parseZone().format("YYYY-MM-DD HH:mm");
                    // var enddate = moment.parseZone(new Date(cdate+":00")).utcOffset("+05:30")._d;
					// if(param.data.status == "10" || param.data.status == "11" || param.data.status == 10 || param.data.status == 11)
					// {
					// 	var enddate = param.data.recent_dealer_reported;
					// }
					// if(param.data.status == "12" || param.data.status == "13" ||param.data.status == "14" || param.data.status == 12 || param.data.status == 13|| param.data.status == 14)
        			// {
					// 		if(param.data.left_dealer_marked !=undefined && param.data.left_dealer_marked !="")
					// 		{
					// 			var enddate = moment.parseZone(param.data.left_dealer_marked).format("YYYY-MM-DD HH:mm:ss");
					// 		}
					// 		else
					// 		{
					// 			var enddate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
					// 		}
					// 	}
					if(param.data.status == "15" || param.data.status == 15)
        			{
							if(param.data.reached_plant_at !=undefined && param.data.reached_plant_at !="")
							{
								// var enddate = moment.parseZone(param.data.reached_plant_at).format("YYYY-MM-DD HH:mm:ss");
								var enddate = param.data.reached_plant_at
							}
							else
							{
								var enddate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
							}
						}
					else{
						if(param.data.status == 6 || param.data.status == "6")
						{
							if(param.data.force_closure_time != undefined && param.data.force_closure_time != ''){
							var enddate = param.data.force_closure_time;
							}else{
								var enddate = moment.parseZone(new Date(cdate+":00")).utcOffset("+05:30")._d;
							}
						}
						else{
							var enddate = moment.parseZone(new Date(cdate+":00")).utcOffset("+05:30")._d;
						}
						
					}
                    var parameters = {
                        consignment_code : param.data.consignment_code,
                        truck_no : param.data.truck_no,
                        startdate:startdate,
                        enddate:enddate
                    }
                    await redirectURL.post("/tracking/loadingarealegsdata",parameters)
					.then(async (response) =>{
                        //console.log("Step 1 ",response.data)
                        // console.log(response.data.data);
                        var comments=response.data;
                        var output=[]
                        if(comments.length > 0){
                            comments.map((item) => {
                                try{
                                    if(item.first_inside_fence != "" && item.first_inside_fence != undefined)
                                    {
                                        var ste = item.first_inside_fence;
                                        var stsec = moment.parseZone(ste).format("x");
                                        item.orderpos = stsec;
                                    }
                                    if(item.first_outside_fence != "" && item.first_outside_fence != undefined){
                                        var etsec = moment.parseZone(item.first_outside_fence).format("x")
                                    }
                                    else{
                                        var etsec = moment.parseZone().format("x");
                                    }
                                    if(item.first_inside_fence != "" && item.first_inside_fence != undefined)
                                    {
                                        // console.log("etsec ", etsec)
                                        // console.log("stsec ", stsec)
                                        var diff = (parseInt(etsec)-parseInt(stsec))/1000;
                                        var timespent = secondsToDhms(diff);
                                    }
                                    else{
                                        var timespent = "";
                                    }
                                    item.time_spent = timespent;
                                        
                                }
                                catch(e){

                                }
                                output.push(item);
                            })
                        }
                        output.sort(GetSortASCOrder("orderpos"));
                        param.successCallback(output);
                        
                        
                        //console.log("Step 2 ",response.data)
                        
                    }).catch(function(error){
						var errDetails={
							url:"/tracking/loadingarealegsdata",
							screen:'Trips',
							error,
										}
						redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                        console.log(error);
                    })
                     
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'shipmentlegs')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }

	onClickShowConsignmentInformation= async (rownode) =>  {
        // console.log("rownode", rownode.data)
		var data=rownode.data
		let logParams = {
            // "location_code" : location.value,
            // "location_name" : location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Trips",
            "activity" : 'clicked on consignment information icon',
            "event" : "Aggrid opened below the row",
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
		let screen_type = this.state.screen_type;
		var params = {
            shipment_id: rownode.data.shipment_id
        }
        var newCommentHeaders = [
            {
				headerName : "Order No",
				field : "consignment_code",
				resizable : true,
				width:130,
				filter : true,
			},
			{
                headerName:"Shipment Status",
                field:"status",
                width:130,
				resizable : true,
                //pinned:'left',
                valueGetter:function(params){
					// console.log('hit')
					// console.log(params,"delivered legs params")
					try {
						var status = "Shipment Created"
						// console.log('hit')
						if(screen_type == 'Delivered'){
							// console.log(params.data,'hit')
                            if(params.data.status === 12){
                                status = "Left Customer";
                            } else if(params.data.status === 13){
                                status = "Possible Trip Closure";
                            } else if(params.data.status === 14){
                                status = "Proximity Trip Closure";
                            } else if(params.data.status === 15) {
                                if(params.data.dealer_not_served && params.data.dealer_not_served == 1){
                                    status ="Dealer Not Served"
                                }else{
                                    status = "Reached Plant";
                                }
                            } else if (params.data.status == 6 ) {
                                status = "Force Closure";
                            } else {
                                status ="";
                            }
						}else{
							// console.log('params.data.status',params.data.status)
						if (params.data.status == 1 || params.data.status == "1") {
							status = "Shipment Created";
						}
						// if (params.data.status == 2 || params.data.status == "2") {
						// 	// status = "Intransit To Collection Center";
						// 	status = "Intransit";
						// }
						if (params.data.status == 3 || params.data.status == "3") {
							// status = "Intransit To Customer";
							status = "Inside Plant";
						}
						if (params.data.status == 4 || params.data.status == "4") {
							// status = "Possible Customer Location";
							status = "Inside Loading Yard";
						}
						if (params.data.status == 5 || params.data.status == "5") {
							// status = "Near Customer Location";
							status = "Outside Loading Yard";
						}
						if (params.data.status == 6 || params.data.status == "6") {
							status = "Force Closure";
						}
						// if (params.data.status == 7 || params.data.status == "7") {
						// 	status = "Reached Dispatch Center";
						// }
						if (params.data.status == 8 || params.data.status == "8") {
							status = "In Transit to Customer";
						}
						if (params.data.status == 9 || params.data.status == "9") {
							status = "Proximity Customer Location";
						}
						if (params.data.status == 10 || params.data.status == "10") {
							status = "Near Customer Vicinity";
						}
						if (params.data.status == 11 || params.data.status == "11") {
							status = "Reached Customer";
						}
						if (params.data.status == 12 || params.data.status == "12") {
							status = "Left Customer";
						}
						if (params.data.status == 13 || params.data.status == "13") {
							status = "Possible Trip Closure";
						}
						if (params.data.status == 14 || params.data.status == "14") {
							status = "Proximity Trip Closure";
						}
						if (params.data.status == 15 || params.data.status == "15") {
							if(params.data.dealer_not_served == 1){
								status ="Dealer Not Served"
							}else{
							status = "Reached Plant";
							}
						}
					}
						return status
					}
                    catch(e){}
                }
            },
			{
				headerName : "CD Status",
				field : "cd_status",
				resizable : true,
				width:110,
				filter : true,
				valueGetter:(params)=>{
					try {
						var status = "Shipment Created"
						if (params.data.cd_status === 1 || params.data.cd_status === '1') {
							status = "Shipment Created";
						}
						// if (params.data.cd_status == 2 || params.data.cd_status == "2") {
						// 	// status = "Intransit To Collection Center";
						// 	status = "Intransit";
						// }
						if (params.data.cd_status === 3 || params.data.cd_status === '3') {
							// status = "Intransit To Customer";
							status = "Inside Plant";
						}
						if (params.data.cd_status === 4 || params.data.cd_status === '4') {
							// status = "Possible Customer Location";
							status = "Inside Loading Yard";
						}
						if (params.data.cd_status === 5 || params.data.cd_status === '5') {
							// status = "Near Customer Location";
							status = "Outside Loading Yard";
						}
						if (params.data.cd_status === 6 || params.data.cd_status === '6') {
							status = "Force Closure";
						}
						// if (params.data.cd_status == 7 || params.data.cd_status == "7") {
						// 	status = "Reached Dispatch Center";
						// }
						if (params.data.cd_status === 8 || params.data.cd_status === '8') {
							status = "In Transit to Customer";
						}
						if (params.data.cd_status === 9 || params.data.cd_status === '9') {
							status = "Proximity Customer Location";
						}
						if (params.data.cd_status === 10 || params.data.cd_status === '10') {
							status = "Near Customer Vicinity";
						}
						if (params.data.cd_status === 11 || params.data.cd_status === '11') {
							status = "Reached Customer";
						}
						if (params.data.cd_status === 12 || params.data.cd_status === '12') {
							status = "Left Customer";
						}
						if (params.data.cd_status === 13 || params.data.cd_status === '13') {
							status = "Possible Trip Closure";
						}
						if (params.data.cd_status === 14 || params.data.cd_status === '14') {
							status = "Proximity Trip Closure";
						}
						if (params.data.cd_status === 15 || params.data.cd_status === '15') {
							status = "Reached Plant";
						}
					
						return status
					}
                    catch(e){}
				}
			},
			
			{
				headerName : "Trip Sequence",
				field : "drop_sequence",
				resizable : true,
				width:120,
				filter : true,
			},
			{
				headerName : "Actual Sequence",
				field : "actual_sequence_no",
				resizable : true,
				width:150,
				filter : true,
				hide: screen_type === "Active"? true: false
			},
			{
				headerName : "Dealer Code",
				field : "consignee_code",
				resizable : true,
				width:120,
				filter : true,
			},
			{
				headerName : "Dealer Name",
				field : "consignee_name",
				resizable : true,
				width:140,
				filter : true,
			},
			{
				headerName : "Quantity (Cases)",
				field : "demand_quantity",
				resizable : true,
				width:130,
				filter : true,
				valueGetter:function(params){
					if(params.data.demand_quantity!==undefined && params.data.demand_quantity!==''){
						return (params.data.demand_quantity).toFixed(2)
					}else{
						return 'N/A'
					}
					
					console.log(params.data.demand_quantity)
				}
			},
			{
				headerName : "Weight (KG)",
				field : "demand_weight",
				resizable : true,
				width:110,
				filter : true,
			},
			{
				headerName : "Volume (M^3)",
				field : "demand_volume",
				resizable : true,
				width:115,
				filter : true,
			},
			{
				headerName : "Reached Customer At",
				field : "recent_dealer_reported",
				resizable : true,
				width:170,
				filter : true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
				}
			},
			{
				headerName : "Left Customer At",
				field : "left_dealer_marked",
				resizable : true,
				width:150,
				filter : true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
				}
			},
			// {
			// 	headerName : "Truck No",
			// 	field : "truck_no",
			// 	resizable : true,
			// 	width:120,
			// 	filter : true
			// },
			// {
			// 	headerName : "Area Name",
			// 	field : "fence_name",
			// 	resizable : true,
			// 	width:200,
			// 	filter : true
			// },
			// {
			// 	headerName : "Entry Time",
			// 	field : "first_inside_fence",
			// 	resizable : true,
			// 	width:160,
			// 	filter : true,
			// 	valueGetter:function(params){
			// 		try{
			// 			if(params.data.first_inside_fence != "" && params.data.first_inside_fence != undefined)
			// 			{
			// 				return getHyphenDDMMMYYYYHHMM(params.data.first_inside_fence);
			// 			}
			// 			else{
			// 				return "";
			// 			}
			// 		}
			// 		catch(e){}
			// 	}
			// },
			// {
			// 	headerName : "Exit Time",
			// 	field : "first_outside_fence",
			// 	resizable : true,
			// 	width:160,
			// 	filter : true,
			// 	valueGetter:function(params){
			// 		try{
			// 			if(params.data.first_outside_fence != "" && params.data.first_outside_fence != undefined)
			// 			{
			// 				return getHyphenDDMMMYYYYHHMM(params.data.first_outside_fence);
			// 			}
			// 			else{
			// 				return "";
			// 			}
			// 		}
			// 		catch(e){}
			// 	}
			// 	},
			// {
			// 	headerName : "Time Spent",
			// 	field : "time_spent",
			// 	resizable : true,
			// 	width:200,
			// 	filter : true,
			// 	valueGetter:function(params){
			// 		try{
						
			// 			return params.data.time_spent;
						
			// 		}
			// 		catch(e){}
			// 	}
			// },
			{
				headerName : "Expected ETA",
				field : "drop_node_total_time_hrs",
				resizable : true,
				width:150,
				filter : true,
				valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.drop_node_total_time_hrs);
                }
			},
			{
				headerName : "Unloading Duration",
				field : "unloading_time_in_hrs",
				resizable : true,
				width:150,
				filter : true,
				
			},
			// {
			// 	headerName : "Highlight delay",
			// 	field : "left_dealer_marked",
			// 	resizable : true,
			// 	width:150,
			// 	filter : true,
				
			// },
			
        ]
        await this.setState({
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: newCommentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
					defaultColDef:this.state.defaultColDef,
					statusBar:this.state.statusBar,
					sideBar:this.state.sideBar,
					enableRangeSelection:true
                },
                getDetailRowData: async function (param) {
					var leg_details=data.leg_details
					var legDetails=JSON.parse(leg_details)
					param.successCallback(legDetails);
                    // await redirectURL.post("/tracking/getConsignmentsData", params)
                        // .then((response) => {
                        //     if (response.status === 200) {
						// 		// console.log(response.data)
								// param.successCallback([response.data]);
                        //     }
                        //     else {
                        //         console.log("error")
                        //     }
                        // })
						// .catch((err)=>{
						// 			var errDetails={
						// 				url:"/tracking/getConsignmentsData",
						// 				screen:'Trips',
						// 				err,
						// 			}
						// 			redirectURL.post("/master/logErrorsForApiCalls",errDetails)
									
						// 		})
					
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'consignmentinformation')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
	

    onShowShipmentLegRouteMap = (e) => {
		// console.log(e,"nightdrivingData")
		if(e.colDef.headerName == ''){

		var e = e.data
		// if (googleAnalytics.page.enableGA) {
        //     let eventOptions = {
		// 		"category": this.state.pagetitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		
		// console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});
        // console.log("e.data.parking_in_time ", e.parking_in_time)
        if(e.first_inside_fence != "" && e.first_inside_fence != undefined)
        {
            var ptime = moment.parseZone(e.first_inside_fence).format("YYYY-MM-DD HH:mm:ss");
		}
        else{
            var ptime = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
		}
        if(e.first_outside_fence != "" && e.first_outside_fence != undefined)
        {
            var end_date = moment.parseZone(e.first_outside_fence).format("YYYY-MM-DD HH:mm:ss");        
        }
        else{
            var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        }
		
        var reqData = {
            truck_no:e.truck_no,
			// consignment_code:e.consignment_code,
			consignee_code:e.consignee_code,
			gate_out_time:ptime,
			seconddate:end_date,
			screen:"truck"
        }
        // console.log("reqData: ", reqData);
		redirectURL.post('/tracking/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			// console.log("Props data ", records)

			this.setState({
				loadshow:'show-m'
			});
			// try{
				if(records.status == "failure" || records.status == "Failure")
				{
					//console.log("records", records.coords);
					this.setState({
						show: true,
						basicTitle:'No Route data available',
						basicType:"danger",
						loadshow:'show-n'
					});
				}
				else{
                    if(records.coords.length == 0)
                    {
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n'
                        });
                    }
                    else{
                        if(records != '')
                        {
                            
                            this.setState({
                                sliderRouteTranslate:"slider-translate-60p",
                                showDiv:'show-m',
                                mapinfo:records,
                                dealer:e.consignee_code,
                                consignment_code:"Truck : "+e.truck_no,
                                maptruckno:e.truck_no,
                                routeTruck:	{"truck_no":e.truck_no,"startTime":ptime,"endTime": end_date},
                                loadshow:'show-n',
                                sidebarSubHeader:"Truck Information",
                                overly:'show-m',
                                rownode:e,
                                leg_no:0,
                                tolls : records.tolls,
								base_route_show:false,
                                // map_type_of_tracking:e.type_of_tracking,
                                // baseRouteCoords: baseRouteCoords
                            });
                        
                            // this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
                            // this.renderMap();

                        }
                    }
				}
			// }
			// catch(e){
            //     console.log("here")
			// 	this.setState({
			// 		show: true,
			// 		basicTitle:'No Route data available',
			// 		basicType:"danger",
			// 		loadshow:'show-n'
			// 	});
			// }
			
		})
		.catch(function (error) {
			var errDetails={
				url:"/tracking/maproutes",
				screen:'Trips',
				error,
							}
			redirectURL.post("/master/logErrorsForApiCalls",errDetails)
			console.log(error);
		});
		}
	}
	unmarkPriority=()=>{
		var rowIds = this.state.rowIds;
		// console.log(rowIds,"rowIds")
		if(rowIds.length > 0)
		{
			var reqparams = {
				rowIds : rowIds
			}
			redirectURL.post("/tracking/updateunmarkpriority",reqparams)
				.then((response)=>{
					console.log(response.data.message)
					if(response.data.message == "Success")
					{
						this.setState({
							show: true,
							basicTitle:'Successfully unmarked as priority',
							basicType:"success",
							loadshow:'show-m'
						});
						var reqparams = {
						}
						try{
							if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
							{
								reqparams.consigner_code = [localStorage.getItem("pc")];
								this.setState({
									consigner:localStorage.getItem("pc")
								})
							}
							else{
								reqparams.consigner_code = "ALL"
							}
						}catch(e){
							
						}
						if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
						{
							reqparams.transporter_codes = localStorage.getItem("transportercode")
						}
						if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
						{
							reqparams.customer_code = localStorage.getItem("customer_code")
						}
						this.onLoadShowTrucksList("urlpath", reqparams)
					}
					else
					{
						
						this.setState({
							show: true,
							basicTitle:'Failed to unmark as priority',
							basicType:"danger",
							loadshow:'show-n'
						});
					}
				})
				.catch(function (e) {
					var errDetails={
						url:"/tracking/updateunmarkpriority",
						screen:'Trips',
						e,
									}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					console.log("Error ", e)
				})
				
			}
			else
			{
				
				this.setState({
					show: true,
					basicTitle:'Select data to proceed',
					basicType:"danger",
					loadshow:'show-n'
				});
			}

	}
	updatePriority = () => {
		var rowIds = this.state.rowIds;
		console.log(rowIds,"rowIds")
		if(rowIds.length > 0)
		{
			var reqparams = {
				rowIds : rowIds
			}
			redirectURL.post("/tracking/updatepriority",reqparams)
				.then((response)=>{
					console.log(response.data.message)
					if(response.data.message == "Success")
					{
						this.setState({
							show: true,
							basicTitle:'Successfully marked as priority',
							basicType:"success",
							loadshow:'show-m'
						});
						var reqparams = {
						}
						try{
							if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
							{
								reqparams.consigner_code = [localStorage.getItem("pc")];
								this.setState({
									consigner:[localStorage.getItem("pc")]
								})
							}
							else{
								reqparams.consigner_code = ["ALL"];
							}
						}catch(e){
							
						}
						if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
						{
							reqparams.transporter_codes = localStorage.getItem("transportercode")
						}
						if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
						{
							reqparams.customer_code = localStorage.getItem("customer_code")
						}
						this.onLoadShowTrucksList("urlpath", reqparams)
					}
					else
					{
						
						this.setState({
							show: true,
							basicTitle:'Failed to mark as priority',
							basicType:"danger",
							loadshow:'show-n'
						});
					}
				})
				.catch(function (e) {
					var errDetails={
						url:"/tracking/updatepriority",
						screen:'Trips',
						e,
									}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					console.log("Error ", e)
				})
				
			}
			else
			{
				
				this.setState({
					show: true,
					basicTitle:'Select data to proceed',
					basicType:"danger",
					loadshow:'show-n'
				});
			}

		}
	showPriorityTrucks = async() => {
		var rowIds = this.state.rowIds;
		console.log(rowIds,"rowIds")
		await this.setState({
			priority : 1,
			priorityBtnShow : 'show-n',
			alltrucksBtnShow : 'show-m',
			loadshow:"show-m",
			isunmarkpriority:false
		})
		var reqparams = {
		}
		try{
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			{
				reqparams.consigner_code = [localStorage.getItem("pc")];
				this.setState({
					consigner:[localStorage.getItem("pc")]
				})
			}
			else{
				reqparams.consigner_code = ["ALL"];
			}
		}catch(e){
			
		}
		if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
		{
			reqparams.transporter_codes = localStorage.getItem("transportercode")
		}
		if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		{
			reqparams.customer_code = localStorage.getItem("customer_code")
		}
		await this.onLoadShowTrucksList("urlpath", reqparams)
	}
	showAllTrucks = async() => {
		await this.setState({
			priority:0,
			priorityBtnShow : 'show-m',
			alltrucksBtnShow : 'show-n',
			loadshow:"show-m",
			isunmarkpriority:true
		})
		var reqparams = {
		}
		try{
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			{
				reqparams.consigner_code = [localStorage.getItem("pc")];
				this.setState({
					consigner:[localStorage.getItem("pc")]
				})
			}
			else{
				reqparams.consigner_code = ["ALL"];
			}
		}catch(e){
			
		}
		if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
		{
			reqparams.transporter_codes = localStorage.getItem("transportercode")
		}
		if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		{
			reqparams.customer_code = localStorage.getItem("customer_code")
		}
		await this.onLoadShowTrucksList("urlpath", reqparams)
	}
	
	onClickTab = async(screen_type) => {
		var activeTab = "";
		var deliveredTab = "";
		var allTab = "";
		if(screen_type == "Active")
		{
			activeTab = "activet";
		}
		if(screen_type == "Delivered")
		{
			this.restoreGridStates()
			deliveredTab = "activet";
		}
		if(screen_type == "All")
		{
			this.restoreGridStates()
			allTab = "activet";
		}
		var sdate=this.state.defaultsdate
		var edate=this.state.defaultedate
		$("#inputTruckConsignment").val("")
		await this.setState({ screen_type: screen_type,loadshow: 'show-m',overly: 'show-m',activeTab,deliveredTab,allTab,consigner:'',flCustomer:'',flTransporter:'',flCity:'',zone:'',startDate:sdate,endDate:edate,selectedFilter:'',}) 
		await this.onLoadShowTrucksList("", {})
	}

	viewPlanDetails = async(propdata) =>{
		let logParams = {
            // "location_code" : location.value,
            // "location_name" : location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Trips",
            "activity" : 'clicked on view plan button in the aggrid',
            "event" : "Navigated to summary dashboard",
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
		let data_type = parseInt(localStorage.getItem('data_type'));
		let location = propdata.data.consigner_code;
		let qry = {
			dispatch_planning_type : data_type,
			plant_location_code : location
		}
		localStorage.setItem('qry',JSON.stringify(qry))
		
		var data = propdata.data;
		let basestring = data.transaction_id;
		let encryptedstring = window.btoa(basestring);
		// window.location.href = '/viewdispatchplandetails?'+encryptedstring;
		window.open(window.location.origin+'/viewdispatchplandetails?'+encryptedstring, "_blank");
	}

	handle_base_route = () =>{
		this.setState(prevState =>({base_route_show:!prevState.base_route_show}))
	}

	forceCloseAndReassign = (param) =>{

        this.setState({
			assignVehicleloader:'show-m',
			})
        
        var shipment_id = this.state.selectedShipmentId;
        var truck_no = this.state.forceCloseVehicle;
        var dept_code = "SNDG";
        // var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        var username = localStorage.getItem('username');
        var userEmail = localStorage.getItem('email');
         var others_Reason = '';
        var reasonforceclose = this.state.forceClose_reason;
        var params = {
            dept_code:dept_code,
            shipment_id:[shipment_id],
            truck_no:truck_no,
            username:username,
            userEmail:userEmail,
            reasonforceclose:reasonforceclose,
            others_reason : others_Reason,
        }

			
        redirectURL.post("/dispatch/forceCloseTrip",params)
        .then(response =>{
            if(response.data.status == 'Success')
            {
                this.setState({
					assignVehicleloader:'show-n',
					openForceCloseModel :false})
					this.onClickTab("Active")
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Error",
                    basicType:"danger",
                    assignVehicleloader:'show-n',
					openForceCloseModel :false,
                })
            }
        })
		.catch((err)=>{
					var errDetails={
						url:"/dispatch/forceCloseTrip",
						screen:'Trips',
						err,
					}
					redirectURL.post("/master/logErrorsForApiCalls",errDetails)
					
				})
	
    }

	handelModelCancel = ()=>{
        this.setState({
            openForceCloseModel :false,
        })
    }

	handleForceCloseModel = (param) =>{
		this.setState({openForceCloseModel:true,
			selectedShipmentId:param.data.shipment_id,
			forceCloseVehicle:param.data.truck_no})
	}

	forceCloseInputField = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }
	updateFONo=(params)=>{
		if (params.data.fo_number===undefined){
			// $("#foNumber").val(params.data.fo_number)
			// $('#fo_date').val(params.data.actual_gate_out_time)
			this.setState({
				showSlideBlockUpdate: "slide25",
				overlayBlock: "show-m",
				shipmentidIfo:params.data.shipment_id
			});
		}

	}
	onSubmitInput=(event)=>{
		event.preventDefault()
		// console.log(event,'hello')
		let consigner_code = '';
		let transaction_id = '';
		let truck_no = '';

		var foNumber=($("#foNumber").val()).toUpperCase();
		var foDate=$('#fo_date').val()
		var rowData=this.state.rowData
		// console.log(rowData,'hello')
		rowData.map((item)=>{
			if (item.shipment_id===this.state.shipmentidIfo){
				item.fo_number=foNumber
				item.actual_gate_out_time=foDate
				consigner_code = item.consigner_code
				transaction_id = item.transaction_id
				truck_no = item.truck_no

			}
		})
        this.setState({
            overlyRing:'show-n',
			sliderRouteTranslate:"",
			overly: 'show-n',
			showSlideBlockUpdate:'',
			overlayBlock:'show-n',
			rowData
        })
		var params={shipment_id:this.state.shipmentidIfo,
		fo_number:foNumber,
		actual_gate_out_time:foDate,transaction_id,consigner_code,truck_no}
		redirectURL.post('tracking/updateIFOnumber',params)
		.then((response)=>{
			if (response.status==200){
				this.setState({
					show:true,
					basicTitle:"Updated Successfully",
					basicType:"success",
				})
			}
		})
		this.hideSlideBlock()

	}

	render() {
		const modalStyles = {
			width: '1300px !important',
		}
		const { open,screen_type } = this.state;
		const { openforceclose } = this.state;

		var maptransporter = '';
		if (this.state.rownode.transporter) {
			///console.log("map rownode ",this.state.rownode.transporter[0])
			maptransporter = this.state.rownode.transporter[0].transporter_name;
		}
		//console.log("Rendere ",this.state.deptcode)			
		var hideforsales = false;
        var hideData=false;
        var hideForReverse="";
		var hideForActive = true
		var columnWithDefs1 = [            
			{
				headerName: "",
				field: "_id",
				width: 60,
				pinned: 'left',
				cellRenderer: 'consignmentActions',
				hide:hideForReverse,
				suppressColumnsToolPanel:hideForReverse,
			},
			{
				headerName: "",
				field: "is_gps_working",
				colId: "is_gps_working",
				width: 40,
				pinned: 'left',
				pivot: false,
				cellRendererSelector:function(params){
					var comp='gps';	
				if (params.data.is_gps_working == "WORKING"){
					comp = 'gps'
				}
				else if (params.data.is_gps_working == "NOT_WORKING"){
					comp = 'nogps'
				}
					var rendComponent = {
						component:comp
					};
					return rendComponent
				},
				filter: true,resizable: true
			},
			{
				headerName: "",
				field: "consignmentinformation",
				width: 50,
				pinned: 'left',
				cellRenderer:'ConsignmentInformation',
				filter: false,resizable: true,
			},
			
			{
				headerName:"View Plan Details",
				headerTooltip:"View Plan Details",
				field:"cluster",
				width:"140",
				//pinned:'left',
				filter:false,
				sortable:false,
				params:{buttonName:'View Plan',
						iconName:'fa fa-eye',
						onClickFunction:this.viewPlanDetails},
				cellRendererSelector:function(params){
					
					var rendComponent = {
						component: "GridButton"
					};
					return rendComponent
				
				},
			},
			{
				headerName:"Update FO Number",
				headerTooltip:"Edit",
				field:"UFO",
				width:"130",
				// pinned:'right',
				filter:false,
				sortable:false,
				params:{buttonName:'Update',
						iconName:'fa fa-edit',
						onClickFunction:this.updateFONo
					},
				cellRendererSelector:function(params){
					
					var rendComponent = {
						component: "GridButton"
					};
					return rendComponent
				
				},
			},
			{
				headerName:"Shipment Id",
				field:"shipment_id",
				width:140,
				hide:hideData,
				suppressColumnsToolPanel:hideforsales,
			}, 
			{
				headerName:"Plant Code",
				field:"consigner_code",
				width:100
			},
			{
				headerName:"Plant Name",
				field:"consigner_name",
				width:160
			}, 
			{
				headerName:"Plant Zone",
				field:"plant_zone",
				width:150,
			},  
			{
				headerName:"Truck No",
				field:"truck_no",
				width:100,
				// pinned:"left",
				valueGetter: function(params){
					if(params.data.truck_no !=undefined && params.data.truck_no !="")
					{
						return params.data.truck_no;
					}
					else
					{
						return "No Data Available";
					}
				}
			},
			// {
			// 	headerName:"Vehicle Type",
			// 	field:"vehicle_type",
			// 	width:150,
			// },			
			{
				headerName:"Vehicle Priority",
				field:"vehicle_priority",
				width:150,
			},
			{
				headerName:"Invoice Date",
				field:"invoice_time",
				width:130,
				valueGetter:function(params){
					try{
					   
						if(params.data.invoice_time != "" && params.data.invoice_time != undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
						}
						else{
							return "Not Available";
						}
					}
					catch(e){}
				},
				comparator: dateComparator,
				hide:hideForReverse,
				suppressColumnsToolPanel:hideForReverse,
			},
			{
				headerName:"Consignment Creation Time",
				field:"shipment_time",
				width:130,
				valueGetter:function(params){
					try{
					   
						if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
						}
						else{
							return "Not Available";
						}
					}
					catch(e){}
				},
			},
			{
				headerName:"Shipment Status",
				field:"status",
				width:130,
				valueGetter:function(params){
					try {
						var status = "Shipment Created"

						if(screen_type == 'Delivered'){
							const numericArray = params.data.status_list.map(Number);
							const maxValue = Math.max(...numericArray);
							
							if (maxValue == 12 ) {
								status = "Left Customer";
							}
							if (maxValue == 13 ) {
								status = "Possible Trip Closure";
							}
							if (maxValue == 14 ) {
								status = "Proximity Trip Closure";
							}
							if (maxValue == 15 ) {
								status = "Reached Plant";
							}
							if (params.data.status == 6 ) {
								status = "Force Closure";
							}
						}else{
						if (params.data.status == 1 || params.data.status == "1") {
							status = "Shipment Created";
						}
						// if (params.data.status == 2 || params.data.status == "2") {
						// 	// status = "Intransit To Collection Center";
						// 	status = "Intransit";
						// }
						if (params.data.status == 3 || params.data.status == "3") {
							// status = "Intransit To Customer";
							status = "Inside Plant";
						}
						if (params.data.status == 4 || params.data.status == "4") {
							// status = "Possible Customer Location";
							status = "Inside Loading Yard";
						}
						if (params.data.status == 5 || params.data.status == "5") {
							// status = "Near Customer Location";
							status = "Outside Loading Yard";
						}
						if (params.data.status == 6 || params.data.status == "6") {
							status = "Force Closure";
						}
						// if (params.data.status == 7 || params.data.status == "7") {
						// 	status = "Reached Dispatch Center";
						// }
						if (params.data.status == 8 || params.data.status == "8") {
							status = "In Transit to Customer";
						}
						if (params.data.status == 9 || params.data.status == "9") {
							status = "Proximity Customer Location";
						}
						if (params.data.status == 10 || params.data.status == "10") {
							status = "Near Customer Vicinity";
						}
						if (params.data.status == 11 || params.data.status == "11") {
							status = "Reached Customer";
						}
						if (params.data.status == 12 || params.data.status == "12") {
							status = "Left Customer";
						}
						if (params.data.status == 13 || params.data.status == "13") {
							status = "Possible Trip Closure";
						}
						if (params.data.status == 14 || params.data.status == "14") {
							status = "Proximity Trip Closure";
						}
						if (params.data.status == 15 || params.data.status == "15") {
							status = "Reached Plant";
						}
					}
						return status
					}
					catch(e){}
				}
			},

			{
				headerName:"Transporter Name",
				field:"transporter_name",
				width:150,
			},
			{
				headerName:"Dealer Code",
				field:"consignee_code",
				width:110,
				resizable : true,
				filter : true,
				hide:hideData,
				suppressColumnsToolPanel:hideData,
			},
			{
				headerName:"Dealer Name",
				field:"consignee_name",
				width:120,
				resizable : true,
				filter : true,
			},    
			{
				headerName:"Dealer City",
				field:"consignee_city",
				width:110,
				resizable : true,
				filter : true,
			},
			{
				headerName:"Dealer State",
				field:"consignee_state",
				width:120,
				valueGetter:function(params){
					try{
						if(params.data.consignee_state != "" && params.data.consignee_state != undefined)
						{
							return params.data.consignee_state;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				},
				resizable : true,
			},
			{
				headerName:"Expected Distance",
				field:"round_trip_distance",
				width:140,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.round_trip_distance != undefined && params.data.round_trip_distance != '')
						{
							return params.data.round_trip_distance;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Planned Customers",
				field:"planned_customers",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.planned_customers !== undefined && params.data.planned_customers !== '')
						{
							return params.data.planned_customers;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Served Customers",
				field:"served_customers",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.served_customers !== undefined && params.data.served_customers !== '')
						{
							return params.data.served_customers;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Deviation in Sequence",
				headerTooltip:'Deviation in Sequence',
				field:"sequence_not_followed",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.sequence_not_followed !== undefined && params.data.sequence_not_followed !== '')
						{
							return params.data.sequence_not_followed == 1 ? 'Yes' :'No';
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName: "Last Packet Time",
				headerTooltip:'Last Packet Time',
				field: "timestamp",
				width: 110,
				
				resizable: true,
				
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				valueGetter:function(params){
					try{
						if(params.data.timestamp !== "" && params.data.timestamp !== undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				},
				hide:hideForReverse,
				suppressColumnsToolPanel:hideForReverse,
			},
			{
				headerName:"Last Known Address",
				headerTooltip:'Last Known Address',
				field:"address",
				width:110
			},
			{
				headerName:"Gps Service Provider",
				headerTooltip:'Gps Service Provider',
				field:"actual_lspuser",
				width:110,
				hide:true,
				suppressColumnsToolPanel:hideData,     
			},		
			{
				headerName:"Plant exit time",
				headerTooltip:'Plant exit time',
				field:"loading_yard_exit_time",
				width:110,
				valueGetter:function(params){
					try{
						// console.log('helloo')
						if (params.data.loading_yard_exit_time=='' ||params.data.loading_yard_exit_time==undefined ){
							return 'N/A'
						}else{
							return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time)
						}

					}
					catch(e){}
				}  
			},	
			{
				headerName : "Expected Trip Start",
				headerTooltip : "Expected Trip Start",
				field : "vehicle_start_time_mins",
				resizable : true,
				width:150,
				filter : true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.vehicle_start_time_mins);
				}
			},
			{
				headerName : "Actual Trip Start",
				headerTooltip : "Actual Trip Start",
				field : "loading_yard_exit_time",
				resizable : true,
				width:110,
				filter : true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time);
				}
			},
			{
				headerName:'FO Number',
				field:'fo_number',
				size:100,
				width:110
			
			},
			{
				headerName:'Actual Dispatch Time(User)',
				headerTooltip:'Actual Dispatch Time',
				field:'actual_gate_out_time',
				width:110,
				size:100,
				valueGetter:(params)=>{
					if(params.data.actual_gate_out_time!==undefined && params.data.actual_gate_out_time!=='' ){
						return getHyphenDDMMMYYYYHHMM(params.data.actual_gate_out_time)
					}else{
						return 'N/A'
					}
				}
			
			},
			{
				headerName : "Expected Return To Plant",
				headerTooltip: "Expected Return To Plant",
				field : "expected_return_to_plant",
				resizable : true,
				width:150,
				filter : true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.expected_return_to_plant);
				}
			},
		]
		var columnWithDefs2 = [            
			{
				headerName: "",
				field: "_id",
				width: 60,
				pinned: 'left',
				cellRenderer: 'consignmentActions',
				hide:hideForReverse,
				suppressColumnsToolPanel:hideForReverse,
			},
			{
				headerName: "",
				field: "is_gps_working",
				colId: "is_gps_working",
				width: 40,
				pinned: 'left',
				pivot: false,
				cellRendererSelector:function(params){
					var comp='gps';	
				if (params.data.is_gps_working == "WORKING"){
					comp = 'gps'
				}
				else if (params.data.is_gps_working == "NOT_WORKING"){
					comp = 'nogps'
				}
					var rendComponent = {
						component:comp
					};
					return rendComponent
				},
				filter: true,resizable: true
			},
			{
				headerName: "",
				field: "consignmentinformation",
				width: 50,
				pinned: 'left',
				cellRenderer:'ConsignmentInformation',
				filter: false,resizable: true,
			},
			
			{
				headerName:"View Plan Details",
				headerTooltip:"View Plan Details",
				field:"cluster",
				width:"140",
				//pinned:'left',
				filter:false,
				sortable:false,
				params:{buttonName:'View Plan',
						iconName:'fa fa-eye',
						onClickFunction:this.viewPlanDetails},
				cellRendererSelector:function(params){
					
					var rendComponent = {
						component: "GridButton"
					};
					return rendComponent
				
				},
			},
			{
				headerName:"Update FO Number",
				headerTooltip:"Edit",
				field:"UFO",
				width:"130",
				// pinned:'right',
				// filter:false,
				// sortable:false,
				params:{buttonName:'Update',
						iconName:'fa fa-edit',
						onClickFunction:this.updateFONo
					},
				cellRendererSelector:function(params){
					
					var rendComponent = {
						component: "GridButton"
					};
					return rendComponent
				
				},
			},
			{
				headerName:"Shipment Id",
				field:"shipment_id",
				width:140,
				hide:hideData,
				suppressColumnsToolPanel:hideforsales,
			}, 
			{
				headerName:"Plant Code",
				field:"consigner_code",
				width:100
			},
			{
				headerName:"Plant Name",
				field:"consigner_name",
				width:160
			},   
			{
				headerName:"Truck No",
				field:"truck_no",
				width:100,
				// pinned:"left",
				valueGetter: function(params){
					if(params.data.truck_no !=undefined && params.data.truck_no !="")
					{
						return params.data.truck_no;
					}
					else
					{
						return "No Data Available";
					}
				}
			},
			{
				headerName:"Invoice Date",
				field:"invoice_time",
				width:130,
				valueGetter:function(params){
					try{
					   
						if(params.data.invoice_time != "" && params.data.invoice_time != undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
						}
						else{
							return "Not Available";
						}
					}
					catch(e){}
				},
				comparator: dateComparator,
				hide:hideForReverse,
				suppressColumnsToolPanel:hideForReverse,
			},
			{
				headerName:"Consignment Creation Time",
				field:"shipment_time",
				width:130,
				valueGetter:function(params){
					try{
					   
						if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
						}
						else{
							return "Not Available";
						}
					}
					catch(e){}
				},
			},
			{
				headerName:"Shipment Status",
				field:"status",
				width:130,
				valueGetter:function(params){
					try {
						var status = "Shipment Created"

						if(screen_type == 'Delivered'){
							const numericArray = params.data.status_list.map(Number);
							const maxValue = Math.max(...numericArray);
							
							if (maxValue == 12 ) {
								status = "Left Customer";
							}
							if (maxValue == 13 ) {
								status = "Possible Trip Closure";
							}
							if (maxValue == 14 ) {
								status = "Proximity Trip Closure";
							}
							if (maxValue == 15 ) {
								status = "Reached Plant";
							}
							if (params.data.status == 6 ) {
								status = "Force Closure";
							}
						}else{
						if (params.data.status == 1 || params.data.status == "1") {
							status = "Shipment Created";
						}
						// if (params.data.status == 2 || params.data.status == "2") {
						// 	// status = "Intransit To Collection Center";
						// 	status = "Intransit";
						// }
						if (params.data.status == 3 || params.data.status == "3") {
							// status = "Intransit To Customer";
							status = "Inside Plant";
						}
						if (params.data.status == 4 || params.data.status == "4") {
							// status = "Possible Customer Location";
							status = "Inside Loading Yard";
						}
						if (params.data.status == 5 || params.data.status == "5") {
							// status = "Near Customer Location";
							status = "Outside Loading Yard";
						}
						if (params.data.status == 6 || params.data.status == "6") {
							status = "Force Closure";
						}
						// if (params.data.status == 7 || params.data.status == "7") {
						// 	status = "Reached Dispatch Center";
						// }
						if (params.data.status == 8 || params.data.status == "8") {
							status = "In Transit to Customer";
						}
						if (params.data.status == 9 || params.data.status == "9") {
							status = "Proximity Customer Location";
						}
						if (params.data.status == 10 || params.data.status == "10") {
							status = "Near Customer Vicinity";
						}
						if (params.data.status == 11 || params.data.status == "11") {
							status = "Reached Customer";
						}
						if (params.data.status == 12 || params.data.status == "12") {
							status = "Left Customer";
						}
						if (params.data.status == 13 || params.data.status == "13") {
							status = "Possible Trip Closure";
						}
						if (params.data.status == 14 || params.data.status == "14") {
							status = "Proximity Trip Closure";
						}
						if (params.data.status == 15 || params.data.status == "15") {
							status = "Reached Plant";
						}
					}
						return status
					}
					catch(e){}
				}
			},
			{
				headerName:"Force Closure Issue",
				field:"force_closure_issue",
				width:170,
				resizable : true,
				filter : true,
			}, 
			{
				headerName:"Transporter Name",
				field:"transporter_name",
				width:150,
			},
			// {
			// 	headerName:"Vehicle Type",
			// 	field:"vehicle_type",
			// 	width:150,
			// },			
			{
				headerName:"Vehicle Priority",
				field:"vehicle_priority",
				width:150,
			},
			{
				headerName:"Plant Zone",
				field:"plant_zone",
				width:150,
			},
			{
				headerName:"Dealer Code",
				field:"consignee_code",
				width:110,
				resizable : true,
				filter : true,
				hide:hideData,
				suppressColumnsToolPanel:hideData,
			},
			{
				headerName:"Dealer Name",
				field:"consignee_name",
				width:120,
				resizable : true,
				filter : true,
			},    
			{
				headerName:"Dealer City",
				field:"consignee_city",
				width:110,
				resizable : true,
				filter : true,
			},
			{
				headerName:"Dealer State",
				field:"consignee_state",
				width:120,
				valueGetter:function(params){
					try{
						if(params.data.consignee_state != "" && params.data.consignee_state != undefined)
						{
							return params.data.consignee_state;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				},
				resizable : true,
			},
			{
				headerName:"Expected Distance",
				field:"round_trip_distance",
				width:140,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.round_trip_distance != undefined && params.data.round_trip_distance != '')
						{
							return params.data.round_trip_distance;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Actual Distance",
				field:"actual_round_trip_km",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.actual_round_trip_km != undefined && params.data.actual_round_trip_km != '')
						{
							return params.data.actual_round_trip_km;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Distance Difference",
				field:"distance_difference",
				width:160,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.distance_difference || Math.floor(params.data.distance_difference) === 0)
						{
							return params.data.distance_difference;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Deviation in Distance",
				field:"distance_deviation",
				width:100,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.distance_deviation !== undefined && params.data.distance_deviation !== '')
						{
							return params.data.distance_deviation == 1 ? 'Yes' :'No';
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Planned Customers",
				field:"planned_customers",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.planned_customers !== undefined && params.data.planned_customers !== '')
						{
							return params.data.planned_customers;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Served Customers",
				field:"served_customers",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.served_customers !== undefined && params.data.served_customers !== '')
						{
							return params.data.served_customers;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Deviation in Customer Serving",
				headerTooltip:'Deviation in Customer Serving',
				field:"deviation_in_customer_serving",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.deviation_in_customer_serving !== undefined && params.data.deviation_in_customer_serving !== '')
						{
							return params.data.deviation_in_customer_serving == 1 ? 'Yes' :'No';
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Deviation in Sequence",
				headerTooltip:'Deviation in Sequence',
				field:"sequence_not_followed",
				width:160,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.sequence_not_followed !== undefined && params.data.sequence_not_followed !== '')
						{
							return params.data.sequence_not_followed == 1 ? 'Yes' :'No';
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Gps Service Provider",
				headerTooltip:'Gps Service Provider',
				field:"actual_lspuser",
				width:110,
				hide:true,
				suppressColumnsToolPanel:hideData,     
			},
			{
				headerName:"Plant exit time",
				headerTooltip:'Plant exit time',
				field:"loading_yard_exit_time",
				width:110,
				valueGetter:function(params){
					try{
						// console.log('helloo')
						if (params.data.loading_yard_exit_time=='' ||params.data.loading_yard_exit_time==undefined ){
							return 'N/A'
						}else{
							return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time)
						}

					}
					catch(e){}
				}  
			},
			{
				headerName : "Expected Trip Start",
				headerTooltip : "Expected Trip Start",
				field : "vehicle_start_time_mins",
				resizable : true,
				width:150,
				filter : true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.vehicle_start_time_mins);
				}
			},
			{
				headerName : "Actual Trip Start",
				headerTooltip : "Actual Trip Start",
				field : "loading_yard_exit_time",
				resizable : true,
				width:110,
				filter : true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time);
				}
			},
			{
				headerName:'FO Number',
				field:'fo_number',
				size:100,
				width:110
			
			},
			{
				headerName:'Actual Dispatch Time(User)',
				headerTooltip:'Actual Dispatch Time',
				field:'actual_gate_out_time',
				width:110,
				size:100,
				valueGetter:(params)=>{
					if(params.data.actual_gate_out_time!==undefined && params.data.actual_gate_out_time!=='' ){
						return getHyphenDDMMMYYYYHHMM(params.data.actual_gate_out_time)
					}else{
						return 'N/A'
					}
				}
			
			},
			{
				headerName : "Expected Return To Plant",
				headerTooltip: "Expected Return To Plant",
				field : "expected_return_to_plant",
				resizable : true,
				width:150,
				filter : true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.expected_return_to_plant);
				}
			},
			{
				headerName : "Reached Plant At",
				field : "reached_plant_at",
				resizable : true,
				width:110,
				filter : true,
				valueGetter:function(params){
					if (params.data.reached_plant_at!==''){
					return moment.parseZone(params.data.reached_plant_at).format('DD-MMM-YYYY HH:mm');
					}
				}
				
			},
		]
		var columnWithDefs3 = [            
			{
				headerName: "",
				field: "_id",
				width: 60,
				pinned: 'left',
				cellRenderer: 'consignmentActions',
				hide:hideForReverse,
				suppressColumnsToolPanel:hideForReverse,
			},
			{
				headerName: "",
				field: "is_gps_working",
				colId: "is_gps_working",
				width: 40,
				pinned: 'left',
				pivot: false,
				cellRendererSelector:function(params){
					var comp='gps';	
				if (params.data.is_gps_working == "WORKING"){
					comp = 'gps'
				}
				else if (params.data.is_gps_working == "NOT_WORKING"){
					comp = 'nogps'
				}
					var rendComponent = {
						component:comp
					};
					return rendComponent
				},
				filter: true,resizable: true
			},
			{
				headerName: "",
				field: "consignmentinformation",
				width: 50,
				pinned: 'left',
				cellRenderer:'ConsignmentInformation',
				filter: false,resizable: true,
			},
			
			{
				headerName:"View Plan Details",
				headerTooltip:"View Plan Details",
				field:"cluster",
				width:"140",
				//pinned:'left',
				filter:false,
				sortable:false,
				params:{buttonName:'View Plan',
						iconName:'fa fa-eye',
						onClickFunction:this.viewPlanDetails},
				cellRendererSelector:function(params){
					
					var rendComponent = {
						component: "GridButton"
					};
					return rendComponent
				
				},
			},
			{
				headerName:"Update FO Number",
				headerTooltip:"Edit",
				field:"UFO",
				width:"130",
				// pinned:'right',
				// filter:false,
				// sortable:false,
				params:{buttonName:'Update',
						iconName:'fa fa-edit',
						onClickFunction:this.updateFONo
					},
				cellRendererSelector:function(params){
					
					var rendComponent = {
						component: "GridButton"
					};
					return rendComponent
				
				},
			},
			{
				headerName:"Shipment Id",
				field:"shipment_id",
				width:140,
				hide:hideData,
				suppressColumnsToolPanel:hideforsales,
			}, 
			{
				headerName:"Plant Code",
				field:"consigner_code",
				width:100
			},
			{
				headerName:"Plant Zone",
				field:"plant_zone",
				width:150,
			},
			{
				headerName:"Plant Name",
				field:"consigner_name",
				width:160
			},   
			{
				headerName:"Truck No",
				field:"truck_no",
				width:100,
				// pinned:"left",
				valueGetter: function(params){
					if(params.data.truck_no !=undefined && params.data.truck_no !="")
					{
						return params.data.truck_no;
					}
					else
					{
						return "No Data Available";
					}
				}
			},			
			// {
			// 	headerName:"Vehicle Type",
			// 	field:"vehicle_type",
			// 	width:150,
			// },			
			{
				headerName:"Vehicle Priority",
				field:"vehicle_priority",
				width:150,
			},
			{
				headerName:"Invoice Date",
				field:"invoice_time",
				width:130,
				valueGetter:function(params){
					try{
					   
						if(params.data.invoice_time != "" && params.data.invoice_time != undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
						}
						else{
							return "Not Available";
						}
					}
					catch(e){}
				},
				comparator: dateComparator,
				hide:hideForReverse,
				suppressColumnsToolPanel:hideForReverse,
			},
			{
				headerName:"Consignment Creation Time",
				field:"shipment_time",
				width:130,
				valueGetter:function(params){
					try{
					   
						if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
						}
						else{
							return "Not Available";
						}
					}
					catch(e){}
				},
			},
			{
				headerName:"Shipment Status",
				field:"status",
				width:130,
				valueGetter:function(params){
					try {
						var status = "Shipment Created"

						if(screen_type == 'Delivered'){
							const numericArray = params.data.status_list.map(Number);
							const maxValue = Math.max(...numericArray);
							
							if (maxValue == 12 ) {
								status = "Left Customer";
							}
							if (maxValue == 13 ) {
								status = "Possible Trip Closure";
							}
							if (maxValue == 14 ) {
								status = "Proximity Trip Closure";
							}
							if (maxValue == 15 ) {
								status = "Reached Plant";
							}
							if (params.data.status == 6 ) {
								status = "Force Closure";
							}
						}else{
						if (params.data.status == 1 || params.data.status == "1") {
							status = "Shipment Created";
						}
						// if (params.data.status == 2 || params.data.status == "2") {
						// 	// status = "Intransit To Collection Center";
						// 	status = "Intransit";
						// }
						if (params.data.status == 3 || params.data.status == "3") {
							// status = "Intransit To Customer";
							status = "Inside Plant";
						}
						if (params.data.status == 4 || params.data.status == "4") {
							// status = "Possible Customer Location";
							status = "Inside Loading Yard";
						}
						if (params.data.status == 5 || params.data.status == "5") {
							// status = "Near Customer Location";
							status = "Outside Loading Yard";
						}
						if (params.data.status == 6 || params.data.status == "6") {
							status = "Force Closure";
						}
						// if (params.data.status == 7 || params.data.status == "7") {
						// 	status = "Reached Dispatch Center";
						// }
						if (params.data.status == 8 || params.data.status == "8") {
							status = "In Transit to Customer";
						}
						if (params.data.status == 9 || params.data.status == "9") {
							status = "Proximity Customer Location";
						}
						if (params.data.status == 10 || params.data.status == "10") {
							status = "Near Customer Vicinity";
						}
						if (params.data.status == 11 || params.data.status == "11") {
							status = "Reached Customer";
						}
						if (params.data.status == 12 || params.data.status == "12") {
							status = "Left Customer";
						}
						if (params.data.status == 13 || params.data.status == "13") {
							status = "Possible Trip Closure";
						}
						if (params.data.status == 14 || params.data.status == "14") {
							status = "Proximity Trip Closure";
						}
						if (params.data.status == 15 || params.data.status == "15") {
							status = "Reached Plant";
						}
					}
						return status
					}
					catch(e){}
				}
			},
			{
				headerName:"Force Closure Issue",
				field:"force_closure_issue",
				width:170,
				resizable : true,
				filter : true,
			}, 
			{
				headerName:"Transporter Name",
				field:"transporter_name",
				width:150,
			},
			{
				headerName:"Dealer Code",
				field:"consignee_code",
				width:110,
				resizable : true,
				filter : true,
				hide:hideData,
				suppressColumnsToolPanel:hideData,
			},
			{
				headerName:"Dealer Name",
				field:"consignee_name",
				width:120,
				resizable : true,
				filter : true,
			},    
			{
				headerName:"Dealer City",
				field:"consignee_city",
				width:110,
				resizable : true,
				filter : true,
			},
			{
				headerName:"Dealer State",
				field:"consignee_state",
				width:120,
				valueGetter:function(params){
					try{
						if(params.data.consignee_state != "" && params.data.consignee_state != undefined)
						{
							return params.data.consignee_state;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				},
				resizable : true,
			},
			{
				headerName:"Expected Distance",
				field:"round_trip_distance",
				width:140,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.round_trip_distance != undefined && params.data.round_trip_distance != '')
						{
							return params.data.round_trip_distance;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Actual Distance",
				field:"actual_round_trip_km",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.actual_round_trip_km != undefined && params.data.actual_round_trip_km != '')
						{
							return params.data.actual_round_trip_km;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Distance Difference",
				field:"distance_difference",
				width:160,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.distance_difference || Math.floor(params.data.distance_difference) === 0)
						{
							return params.data.distance_difference;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Deviation in Distance",
				field:"distance_deviation",
				width:100,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.distance_deviation !== undefined && params.data.distance_deviation !== '')
						{
							return params.data.distance_deviation == 1 ? 'Yes' :'No';
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Planned Customers",
				field:"planned_customers",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.planned_customers !== undefined && params.data.planned_customers !== '')
						{
							return params.data.planned_customers;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Served Customers",
				field:"served_customers",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.served_customers !== undefined && params.data.served_customers !== '')
						{
							return params.data.served_customers;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Deviation in Customer Serving",
				headerTooltip:'Deviation in Customer Serving',
				field:"deviation_in_customer_serving",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.deviation_in_customer_serving !== undefined && params.data.deviation_in_customer_serving !== '')
						{
							return params.data.deviation_in_customer_serving == 1 ? 'Yes' :'No';
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName:"Deviation in Sequence",
				headerTooltip:'Deviation in Sequence',
				field:"sequence_not_followed",
				width:110,
				resizable : true,
				valueGetter:function(params){
					try{
						if(params.data.sequence_not_followed !== undefined && params.data.sequence_not_followed !== '')
						{
							return params.data.sequence_not_followed == 1 ? 'Yes' :'No';
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			// {
			// 	headerName: "Expected ETA",
			// 	field: "expected_trip_end",
			// 	width: 110,				
			// 	resizable: true,				
			// 	// filter: "agDateColumnFilter",
			// 	comparator: dateComparator,
			// 	valueGetter:function(params){
			// 		try{
			// 			if(params.data.expected_trip_end !== "" && params.data.expected_trip_end !== undefined)
			// 			{
			// 				return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
			// 			}
						
			// 			else{
			// 				return "No Data Available";
			// 			}

			// 		}
			// 		catch(e){}
			// 	}
			// },
			// {
			// 	headerName: "Revised ETA",
			// 	field: "revised_trip_end",
			// 	width: 110,				
			// 	resizable: true,				
			// 	// filter: "agDateColumnFilter",
			// 	comparator: dateComparator,
			// 	valueGetter:function(params){
			// 		try{
			// 			if(params.data.revised_trip_end !== "" && params.data.revised_trip_end !== undefined)
			// 			{
			// 				return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
			// 			}
						
			// 			else{
			// 				return "No Data Available";
			// 			}

			// 		}
			// 		catch(e){}
			// 	}
			// },
			{
				headerName: "Last Packet Time",
				headerTooltip:'Last Packet Time',
				field: "timestamp",
				width: 110,
				
				resizable: true,
				
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				valueGetter:function(params){
					try{
						if(params.data.timestamp !== "" && params.data.timestamp !== undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				},
				hide:hideForReverse,
				suppressColumnsToolPanel:hideForReverse,
			},
			{
				headerName:"Last Known Address",
				headerTooltip:'Last Known Address',
				field:"address",
				width:110
			},
			{
				headerName:"Gps Service Provider",
				headerTooltip:'Gps Service Provider',
				field:"actual_lspuser",
				width:110,
				hide:true,
				suppressColumnsToolPanel:hideData,     
			},		
			{
				headerName:"Plant exit time",
				headerTooltip:'Plant exit time',
				field:"loading_yard_exit_time",
				width:110,
				valueGetter:function(params){
					try{
						// console.log('helloo')
						if (params.data.loading_yard_exit_time=='' ||params.data.loading_yard_exit_time==undefined ){
							return 'N/A'
						}else{
							return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time)
						}

					}
					catch(e){}
				}  
			},	
			{
				headerName : "Expected Trip Start",
				headerTooltip : "Expected Trip Start",
				field : "vehicle_start_time_mins",
				resizable : true,
				width:150,
				filter : true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.vehicle_start_time_mins);
				}
			},
			{
				headerName : "Actual Trip Start",
				headerTooltip : "Actual Trip Start",
				field : "loading_yard_exit_time",
				resizable : true,
				width:110,
				filter : true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.loading_yard_exit_time);
				}
			},
			{
				headerName:'FO Number',
				field:'fo_number',
				size:100,
				width:110
			
			},
			{
				headerName:'Actual Dispatch Time(User)',
				headerTooltip:'Actual Dispatch Time',
				field:'actual_gate_out_time',
				width:110,
				size:100,
				valueGetter:(params)=>{
					if(params.data.actual_gate_out_time!==undefined && params.data.actual_gate_out_time!=='' ){
						return getHyphenDDMMMYYYYHHMM(params.data.actual_gate_out_time)
					}else{
						return 'N/A'
					}
				}
			
			},
			{
				headerName : "Expected Return To Plant",
				headerTooltip: "Expected Return To Plant",
				field : "expected_return_to_plant",
				resizable : true,
				width:150,
				filter : true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.expected_return_to_plant);
				}
			},
			{
				headerName : "Reached Plant At",
				field : "reached_plant_at",
				resizable : true,
				width:110,
				filter : true,
				valueGetter:function(params){
					if (params.data.reached_plant_at!==''){
					return moment.parseZone(params.data.reached_plant_at).format('DD-MMM-YYYY HH:mm');
					}
				}
				
			},
		]

		var columnWithDefs = []
		console.log("screentype", this.state.screen_type)
		if(screen_type === 'Active'){
			columnWithDefs = columnWithDefs1
		}else if (screen_type === 'Delivered') {
			columnWithDefs = columnWithDefs2

		}else if (screen_type === 'All') {
			columnWithDefs = columnWithDefs3
		}
		
		const commentColumns = [
			{
				headerName: "Truck No",
				field: "truck_no",
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: "GR No",
				field: "consignment_code",
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: "User Type",
				field: "user_type",
				width: 100,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.user_type == "ORGUSER") {
						return "MSIL"
					}
					if (params.data.user_type == "DCTUSER") {
						return "DCT"
					}
					if (params.data.user_type == "TRANSPORTER") {
						return "TRANSPORTER"
					}
				}
			},
			{
				headerName: "Comment",
				field: "comment",
				cellClass: ['wraptext'],
				resizable: true,
				filter: true
			},
			{
				headerName: "Commented By",
				field: "username",
				cellClass: ['wraptext'],
				resizable: true,
				width: 100,
				filter: true
			},

			{
				headerName: "Commented Date",
				field: "comment_date",
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.comment_date != "") {
						return getYYYYMMDDHHMMSS(params.data.comment_date);
					}
				}
			}

		]
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}
		var hText = 'All';
		var filterClassName = "form-group col-xl-12 col-lg-12";
		var filterCardStyle1 = { minHeight: "234px" };
		var filterCardStyle2 = { padding: '10px' };
		var mnFilterClass = { marginLeft: "0px", padding: "0px" };
		if (this.state.deptcode == 'SNDG') {
			hText = ''
			filterClassName = "form-group col-xl-12 col-lg-12";
			filterCardStyle1 = { minHeight: "234px" };
			filterCardStyle2 = { padding: '10px' };
		}

		if (this.state.deptcode == 'LOG-PRT') {
			hText = ''
			mnFilterClass = { marginLeft: "0px" };
		}
		if (this.state.deptcode == 'LOG-TNP') {
			hText = ''
			filterCardStyle1 = { minHeight: "240px" };
		}
		const tripoptoins = [
			{ "value": "0", label: "All" },
			{ "value": "S", label: "Short Haul" },
			{ "value": "L", label: "Long Haul" }
		]
		var baseRouteCoordsData = [];
		if(localStorage.getItem("user_type") == "TECHUSER")
		{
			baseRouteCoordsData = this.state.baseRouteCoords
		}
		const screenTypes = [
			{ "value": "Active", label: "Active Trips" },
			{ "value": "Delivered", label: "Delivered Trips" },
			{ "value": "All", label: "All Trips" }
		]
		var screenName = "Trips";
		try{
			if(this.state.screen_type !=undefined && this.state.screen_type !="")
			{
				if(this.state.screen_type.label !=undefined && this.state.screen_type.label !="")
				{
					screenName = this.state.screen_type.label;
				}
			}
		}catch(e){

		}
		// console.log(this.state.consigner,"this.state.consigner")
		var consigners = []
		consigners.push(this.state.consigner)
		let plantCodesList =[];
		if (consigners !== ""){
			consigners.map(each => (
				plantCodesList.push(each.value)
			))
		}
		// console.log(plantCodesList,"plantCodesList");
		// console.log(this.state.filterCustomers,"this.state.filterCustomers")
		let filterCustomers = this.state.filterCustomers
		let filterTransporters = this.state.filterTransporters
		var filteredCustomers 
		var filteredTransporters 
			// console.log(filterCustomers.length,filterTransporters.length,'lll4')
		if (this.state.consigner!== ""){
			var filteredCustomers = plantCodesList? filterCustomers.filter(each => plantCodesList.includes(each.plant_code)):"";
			var filteredTransporters = plantCodesList? filterTransporters.filter(each => plantCodesList.includes(each.plant_code)):"";
			// console.log(filteredCustomers.length,filteredTransporters.length,'lll')
		}else {
			// console.log("notttttttttt")
			filteredCustomers = filterCustomers;
			filteredTransporters = filterTransporters;
		}

		
		return (
			<>
			
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				<div className="row">
					<div className="col-xl-12 col-lg-12 mb-3">
						<ul className="d-tabs">
							<li onClick={this.onClickTab.bind(this,"Active")} className={"lttabs "+this.state.activeTab}>
								<button type="button" className="btn">Active Trips </button>
							</li>                            
							<li onClick={this.onClickTab.bind(this,"Delivered")} className={"lttabs "+this.state.deliveredTab}>
								<button type="button" className="btn">Delivered Trips </button>
							</li>                            
							<li onClick={this.onClickTab.bind(this,"All")} className={"lttabs "+this.state.allTab}>
								<button type="button" className="btn">All Trips</button>
							</li>
						</ul>
					</div>
					<div className="col-xl-4 col-lg-4">
						<div className="card card1" style={filterCardStyle1}>
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<form className="row" onSubmit={this.getTruckConsignments}>
									<div className={filterClassName}>
										<label>Select Type :  </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedFilter"
											style={{ borderRadius: "0px" }}
											options={this.state.filterOptions}
											// onChange={e => { this.setState({ selectedFilter: e.label }) }}
											onChange={this.newSelector.bind(this, "selectedFilter")}
											placeholder={'Select'}
											value={this.state.selectedFilter}
											
											required />
									</div>
									<div className={filterClassName}>
										<label className="reason">{this.state.filterNames}</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left" }}>
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="col-xl-8 col-lg-8">
						<div className="card card2" style={filterCardStyle1}>
							<div className="row card-body" style={filterCardStyle2}>
									<div className="row col-xl-12 col-lg-12" style={mnFilterClass}>
										{(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined || localStorage.getItem("roles")=="CUSTOMER")?
										"":	
											<div className="form-group col-xl-3 col-lg-3">
												<label>Select Plant: </label>
												<Select
													maxHeight={200}
													placeholder={"ALL"} closeMenuOnSelect={true}
													value={this.state.consigner}
													className="border-radius-0"
													onChange={this.changeConsignerArrayHandler}
													style={{ borderRadius: "0px" }}
													options={this.state.locationNames} required />

											</div>
										}
										<div className="form-group col-xl-3 col-lg-3">
											<label>Select Zone: </label>
											<Select
												maxHeight={200}
												placeholder={"ALL"} closeMenuOnSelect={true}
												value={this.state.zone}
												className="border-radius-0"
												onChange={this.changeZone}
												style={{ borderRadius: "0px" }}
												options={this.state.zones} required />

										</div>
										{/* <div className="col-xl-3 col-lg-3 form-group">
											<label>Invoice From Date</label>
											<Datetime
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ name: 'startDate', autoComplete: 'off', readOnly: 'true'}}
												style={{color:"#000"}}
												dateFormat="YYYY-MM-DD"
												name="startDate"
												className="custom-input-style"
												timeFormat={false}
												value={this.state.startDate}
												placeholder={moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD')}
												disableTimePicker={true}
												onChange={this.handlerStartDateTime} />
										</div>                              
										
										<div className="col-xl-3 col-lg-3 form-group">
											<label>Invoice To Date</label>
											<Datetime
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ name: 'endDate', autoComplete: 'off', readOnly: 'true'}}
												dateFormat="YYYY-MM-DD"
												timeFormat={false}
												className="custom-input-style"
												value={this.state.endDate}
												placeholder={moment.parseZone().format('YYYY-MM-DD')}
												onChange={this.handlerEndDateTime} />
										</div> */}
										<div className="col-md-3">
											<div className="form-group ">
												<label>Invoice From Date</label>
												<input
												type="date"
												className="form-control"
												name="startData"
												id="startData"
												autoComplete="off"
												onKeyDown={(e) => e.preventDefault()}
												max={getCurrentDateTime()}
												onChange={this.onChangeStartDate}
												value={this.state.startDate}
												readonly
												/>
											</div>
										</div>
										<div className="col-md-3">
										<div className="form-group ">
											<label>Invoice To Date</label>
											<input
											type="date"
											className="form-control"
											name="endDate"
											id="endDate"
											autoComplete="off"
											onKeyDown={(e) => e.preventDefault()}
											max={getCurrentDateTime()}
											onChange={this.onChangeEndDate}
											value={this.state.endDate}
											readonly
											/>
										</div>
										</div>
									</div>

									<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
										{(localStorage.getItem("roles")!="CUSTOMER") ?
											<div className="form-group col-xl-5 col-lg-4">
												<label>Dealer Centers :  </label>
												<Select
													maxHeight={200}
													className="border-radius-0"
													closeMenuOnSelect={true}
													isMulti={true}
													id="selectedCustomer"
													style={{ borderRadius: "0px" }}
													value={this.state.flCustomer}
													onChange={this.newFilter.bind(this, "flCustomer")}
													options={filteredCustomers}
												/>
											</div>
										: ""}
									
										{(localStorage.getItem("roles")!="CUSTOMER") ?
											<div className="form-group col-xl-3 col-lg-3">
												<label>Dispatch Centers City :  </label>
												<Select
													maxHeight={200}
													className="border-radius-0"
													isMulti={false}
													id="selectedCity"
													style={{ borderRadius: "0px" }}
													value={this.state.flCity}
													onChange={this.newFilter.bind(this, "flCity")}
													options={this.state.filterCities}
												/>
											</div>
										: ""}
										
										{(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="" || localStorage.getItem("roles")== "CUSTOMER") ? "":
										<div className="form-group col-xl-3 col-lg-3">
											<label>Transporter :  </label>
											<Select
												maxHeight={200}
												className="border-radius-0"
												isMulti={false}
												id="selectedTransporter"
												style={{ borderRadius: "0px" }}
												value={this.state.flTransporter}
												onChange={this.newFilter.bind(this, "flTransporter")}
												options={filteredTransporters}
											/>
										</div>
										}
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
										<button type="button" className="btn btn-success" onClick={this.onGetConsigners}>Submit</button>
									</div>
							</div>
						</div>
					</div>

				</div>
				{/*  :""} */}
				{(localStorage.getItem('roles')!="CUSTOMER")?
				<Counters
					context={this}
					gridData={this.state.allRowData}
					deptcode={this.state.deptcode}
					screen_type={this.state.screen_type}
				/>:
				<div className="col-xl-12 col-lg-12 col-sm-12 beffect mt-20p">
                <div className="card">
                    <div className="card-body" id="counterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounterShowData.bind(this,"all")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> {Constant.COUNTER_TOTAL_TRUCKS}</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.rowData != '')?this.state.rowData.length:0}/></span></h4>
                                    
                                </div>
							</div>	
							</div>	
							</div>	
							</div>
							</div>
				}
				<div className="row mt-20p">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {screenName} </span>

								</h5>
							</div>
							<div className="card-body pt-10px" >
								<div className="row">
									<div className="col-xl-12 col-lg-12 mb-10p">
									{(localStorage.getItem('roles')!="CUSTOMER")?
										<span className="float-right" style={{ fontSize: "13px", fontWeight: "100" }}>
											{/* <i className="fa fa-globe f20" style={{ fontSize: "22px" }}></i> - View Route */}
											<img style={{ height: "18px" }} src={require("../../assets/icons/distance.png")} /> - View Route
											&nbsp;
											{/* <i className="fa fa-clock-o" style={{ color: "#ff0000", fontSize: "22px" }}></i> - Transit Delay */}
											<i className="fa fa-info-circle" style={{ color: "#17a2b8", fontSize: "22px" }}></i> - Consignment Information
											&nbsp;<i className="fa fa-map-marker txt-danger" style={{ fontSize: "22px" }}></i> - GPS Data Not Available
											&nbsp;<i className="fa fa-map-marker txt-secondary" style={{ fontSize: "22px" }}></i> - GPS Data Not Adequate
											{/* &nbsp;<i className="fa fa-tachometer txt-success" style={{ fontSize: "22px	" }}></i> - Overspeed
											<span>&nbsp;<i className="fa fa-moon-o txt-primary" style={{ fontSize: "22px" }}></i> - Night Driving</span> */}
											{!(this.state.deptcode == 'LOG-PRT') ?
												<span>
													{/* <span>&nbsp;<i className="fa fa-moon-o txt-primary" style={{ fontSize: "22px" }}></i> - Night Driving
													</span> */}
													{/* <span>&nbsp;<i className="fa fa-truck-alt darkorange" style={{ fontSize: "22px" }}></i> - Loading Delay
													</span> */}

												</span>
												: ""}
											{/* <span>&nbsp;<i className="fa fa-stop-circle maroon" style={{ fontSize: "22px" }}></i> - Enroute Stoppages
											</span> */}
											{/* <span>&nbsp;<i className="icofont icofont-list" style={{ fontSize: "22px" }}></i> - Day Wise Distance Trip
											</span> */}
											{/* &nbsp;<i className="fa fa-stop-circle" style={{color:"rgb(174, 70, 239)", fontSize:"22px	"}}></i> - Enroute Stoppages */}


										</span>:""}

									</div>
									<div className="col-xl-12 mb-10p">
										{/* <button className="float-left custom-btn btn-warning" onClick={this.onClickShowMapView.bind(this)}>
											<i className="fa fa-map-marker"></i> Map View
										</button> */}

										<span className="layoutbtns">
											{/* {this.state.isunmarkpriority ? 
											<button className="float-right custom-btn white btn-warning" type="button" onClick={this.updatePriority.bind(this)}> Mark As Priority
												</button> : <button className="float-right custom-btn white btn-warning" type="button" onClick={this.unmarkPriority.bind(this)} > Unmark Priority
												</button>} */}
											<button className="float-right custom-btn white btn-danger" style={{ marginRight: "10px" }}  onClick={this.onClickSaveGridState.bind(this)}>
												<i className="icofont icofont-save"></i> Save Grid Layout
											</button>
											<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
												<i className="icofont icofont-refresh"></i> Reset Default Layout
											</button>
											{(localStorage.getItem("user_type") == "TECHUSER")  ?
											<button className="float-right custom-btn white btn-warning" style={{ marginRight: "10px" }} onClick={this.onClickCreateshipment.bind(this)}>
												Create Shipment
											</button>
											:""}
											{/* <button type="button" onClick={this.onClickEdit.bind(this)} className="float-right custom-btn white btn btn-success pull-right" style={{ marginRight: "10px" }}>Edit</button> */}

											{/* <button className="float-right custom-btn white btn-success" style={{ marginRight: "10px" }} onClick={this.onclickdownloadbtn.bind(this)}>
												<i className="icofont icofont-refresh"></i> Download URL
											</button> */}
											{/* {(localStorage.getItem("roles").indexOf("admin") >= 0) ?
											<a href={"/sndconsignments"} className="float-right custom-btn white btn-success" style={{ marginRight: "10px" }}>
												Go To Consignments
											</a>
											:""} */}
											{/* <button className={"float-right custom-btn white btn btn-secondary " + this.state.priorityBtnShow} type="button" onClick={this.showPriorityTrucks.bind(this)}  style={{ marginRight: "10px" }} > Show Priority Trucks
												</button> */}
												
												<button className={"float-right custom-btn white btn btn-secondary " + this.state.alltrucksBtnShow} type="button"  style={{ marginRight: "10px" }} onClick={this.showAllTrucks.bind(this)}> Show All Trucks
												</button>
										</span>
									</div>
								</div>
								{/*
				   				   <Grid
								   ashow={this.state.show}
								   atype={this.state.basicType}
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}
									table={"trucklocation"}
									columnyaxis={this.state.rowData}
									columnaxis={columnwithDefs}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									childconfs={this.state.childconfs}
									childrow={this.state.childrow}
									context={this }

									/>
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
								*/}

								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									{/* {console.log('hit',this.state.rowData)} */}
									<AgGridReact
										// modules={this.state.modules}
										columnDefs={columnWithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										// autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//onRowSelected={this.onRowSelection.bind(this)}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this },
											maintainColumnOrder: true
										}}
										masterDetail={true}
										// rowSelection={this.state.rowSelection}
										onRowSelected={this.onRowSelection.bind(this)}
									/>


								</div>
							</div>
						</div>
					</div>

				</div>
				{this.state.sliderCommentTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderCommentTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Consignment Comments
								<span className="float-right closebtn" style={{ marginRight: "25px",cursor:"pointer" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>
							<div id="myGrid1" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={commentColumns}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.commentsRowData}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
									enableCharts={true}
									enableRangeSelection={true}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}

								/>



							</div>
						</div>

					</div>
					: ""
				}
				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }} >
						<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px",cursor:"pointer" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap
									mapFor={"truck"}
									consignercords={this.state.consigner_coordinates}
									tolls={this.state.tolls}
									consigneecords={this.state.consignee_coordinates}
									baseRouteCoords={this.state.baseRouteCoords}
									rownode={this.state.rownode}
									truckno={this.state.maptruckno}
									dealer={this.state.dealer}
									mapinfo={this.state.mapinfo}
									defTransitCoords={this.state.defTransitCoords}
									base_route_show={this.state.base_route_show}
									handle_base_route={this.handle_base_route} />
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">GPS Start Time</label>
												<div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">GPS End Time</label>
												<div>{(this.state.routeTruck.endTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime) : "NA"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">Avg Temparature</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? (this.state.mapinfo.route_details.average_temperature).toFixed(2) : "0"} °C</div>
											</div>
											{/* {(localStorage.getItem('roles')!="CUSTOMER")?
												<div className="col route-block">
													<label className="sidebar-label">Tracking Type</label>
													{(this.state.map_type_of_tracking == 1)?
													<div>GPS Tracking</div>
													:
													<div>SIM Tracking</div>
													}
												</div>
											:""} */}
											
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					: ""
				}
				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} classNames={{ overlay: 'customOverlay', modal: 'customModal' }}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
						{(this.state.maprowData != '') ?
							<ConsignmentDrawMap
								context={this} griddata={this.state.maprowData}
								consigner_coordinates={this.state.consigner_coordinates}
								consigneecoords={this.state.consigneecoords}
								rownode={this.state.rownode}
								mapFor={"consignment"} />
							: ""}

					</div>
				</Modal>

				<Modal closeOnOverlayClick={false} open={this.state.openForceCloseModel} onClose={this.handelModelCancel} 
                    classNames={{modal: 'vehiclepriority-modelClass handle-postion'}} >
                        <div className="col-sm-12">

                            <div className="mt-20p mb-10p">
                                <h6>Do you wish to <b>Force Close</b> Shipment ID-{this.state.selectedShipmentId}?</h6>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group ">
                                    <label htmlFor="forceClose_reason" className="col-form-label f12">Force Close Reason</label>

                                    <select name="forceClose_reason" id="forceClose_reason" value = {this.state.forceClose_reason}
                                        required
                                        className= "forminp form-control"
                                        onChange={this.forceCloseInputField}>
                                        {/* <option value="" disabled selected>Select</option> */}
                                        <option value="gps_issue">GPS Issue</option>
                                        <option value="trip_closed">Trip Closed</option>
                                        <option value="load_cancelled">Load Cancelled</option>
                                        <option value="new_trip_allocated">New Trip Allocated</option>
                                        <option value="driver_changed">Driver Changed</option>
                                    </select>
                                </div>
                            </div> 
                        
                            <div className="btns-center">
                            <button type="button" onClick={this.forceCloseAndReassign} className="btn tbtn assign-btn" >Force Close</button>
                            <button type='button' className="btn tbtn cancel-btn ass-cancel-btn" onClick={this.handelModelCancel}>CANCEL</button>	
                            </div>
                            <div className={"chartLoading "+(this.state.assignVehicleloader)}>
                                                <div className="loaderpos">
                                                    <img src={require("../../assets/images/loading.gif")} />
                                                </div>
                            </div>
                        </div>
                    </Modal>

				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<div className={"slide-r " + (this.state.slideBar)} >
					{this.displayData}
				</div>

                <div className={"overlay-part "+(this.state.overlyRing)} onClick={this.hideSlideBlock.bind(this)}></div>
			</div>
			<div className={"sliderBlock2 " + this.state.showSlideBlockUpdate}>
				<h5 className="crd-bg p-10p">Update Fields</h5>
				<div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="container-fluid">
                            <form id="upform" className="theme-form" onSubmit={this.onSubmitInput}>

                                <div className="form-group mt-20p">
									<label> FO Number</label>
                                    <input type="text" name="foNumber" id="foNumber" placeholder="FO Number" className="form-control" required />
                                </div>

                                <div className="form-group mt-20p">
									<label>Actual Dispatch Time</label>
                                    <input type="datetime-local" name="fo_date" id="fo_date"  className="form-control" required/>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.hideSlideBlock.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
			</div>
			<div
				className={"overlay-block " + this.state.overlayBlock}
				onClick={this.hideSlideBlock}
        	></div>
			<div className={"loader " + this.state.loadshow}></div>

			</>

		);
	}
}

function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}

function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber, monthNumber, dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript() {
	// alert("timeDate");
	// $('.datetimepicker_mask').datetimepicker({
	// 	mask: '39-19-9999',
	// 	format: 'd-m-Y',
	// 	timepicker: true
		
	// });
	// $('.datetimepicker_date').datetimepicker({
	// 	format:'Y-m-d',
	// 	timepicker:true
	// });
	// var index = window.document.getElementsByTagName("script")[1]
	// var script = window.document.createElement("script")
	// script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	// //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	// script.async = true
	// script.defer = true
	// index.parentNode.insertBefore(script, index)
}

	
function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
}       
function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // January is 0
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }