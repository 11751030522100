import React, { Component } from "react";


class Level4 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            click: true
        }
    }

    componentDidMount = () => {
        var data = this.props.data
        var keys = Object.keys(data)
    }

    changeHandler = async (item, event) => {
        var data = this.props.data
        var keys = Object.keys(data)
        if (data[item].value === 0) {
            data[item].value = 1
        } else {
            data[item].value = 0
        }
        if (item === 'Per KM Cost') {
            data['Per CMT Cost'].value = 0
        } if (item === 'Per CMT Cost') {
            data['Per KM Cost'].value = 0
        }
        if (item === 'Plant Vicinity Circle Radius') {
            data[item].value = parseInt(event.target.value)
        }
        await this.setState((prevState) => {
            return ({ click: !prevState.click })
        })
    }

    statusChange = async () => {
        // console.log('hi9t')
        var data = this.props.data
        var keys = Object.keys(data)
        keys.map((item1) => {
            if (item1 !== 'type' && item1 !== 'value' && item1 !== 'name' && item1 !== 'id') {
                data[item1].value = 0
            }
        })
        
    }


    render() {



        var data = this.props.data
        var keys = Object.keys(data)
        if (this.props.status === 0) {
            this.statusChange()
        }
        return (
            <div>{keys.map((item,idx) => {
                if (item !== 'type' && item !== "name" && item !== "value" && this.props.status === 1 && item !== 'id') {
                    return (
                        <div className="row ml-3 per col-md-12" key={idx} id={item} >
                            <label className="fw-600"><input type={data[item].type} checked={data[item].value === 1} name={data[item].id} value={data[item].value} onChange={(event) => { this.changeHandler(item, event) }}/>&nbsp;&nbsp;{item}</label>
                        </div>)
                }
            })}</div>)
    }
}

export default Level4