import React, { useState, useEffect } from "react";
import NewConfigL4 from "./newconfgL4";

const NewConfigL3 = (props) => {
  //   console.log(props, "prop3");
  var [data, setData] = useState(props.data);
  useEffect(()=>{
    setData(props.data)
  },[props.data])
  // if (data !== props.data) {
  //   setData(props.data);
  // }

  const changeHandler = async (item, event) => {
    // console.log("hit here");
    const updatedData = { ...data };
    var keys = Object.keys(updatedData);
    // console.log(item, event.target.value);
    if (updatedData[item].value === 0) {
      updatedData[item].value = 1;
      if (updatedData[item].type == "radio") {
        keys.map((key) => {
          if (
            updatedData[item].id === updatedData[key].id &&
            updatedData[item].name !== updatedData[key].name
          ) {
            updatedData[key].value = 0;
          }
        });
      }
    } else {
      updatedData[item].value = 0;
    }

    if (updatedData[item].type === "number") {
      updatedData[item].value = parseInt(event.target.value);
    }

    setData(updatedData);
  };
  // var data = props.data;
  // console.log(data,'hda')
  //   var [data, setData] = useState(props.data);
  var keys = Object.keys(data);
  //   console.log(data, "da l3");
  if (props.status == 0) {
    keys.map((item) => {
      data[item].value = 0;
    });
  }
  return (
    <div className="row ml-3 per col-md-12" style={{ display: "block" }}>
      <div className="row mt-30p">
        {keys.map((item, idx) => {
          if (
            item !== "type" &&
            item !== "location" &&
            item !== "value" &&
            item !== "disabled" &&
            item !== "name" &&
            item !== "id" &&
            item !== "visible" &&
            props.status === 1
          ) {
            // console.log(props.status === 1, "item");
            if (data[item].type === "checkbox") {
              return (
                <div key={idx} className="col-md-12 mr-3" id={item}>
                  <label className="fw-600">
                    <input
                      checked={data[item].value === 1}
                      disabled={data[item].enabled}
                      type={data[item].type}
                      value={data[item].value}
                      onChange={(event) => changeHandler(item, event)}
                    />
                    &nbsp;&nbsp;{data[item].name}
                  </label>
                  {data[item].data !== undefined ? (
                    <NewConfigL4
                      data={data[item].data}
                      status={data[item].value}
                    />
                  ) : (
                    ""
                  )}
                </div>
              );
            } else if (data[item].type === "number") {
              return (
                <div key={idx} className="col-md-12 mr-3" id={item}>
                  <label className="fw-600">
                    &nbsp;&nbsp;{data[item].name}&nbsp;&nbsp;
                    <input
                      //   checked={data[item].value === 1}
                      disabled={data[item].enabled}
                      type={data[item].type}
                      value={data[item].value}
                      onChange={(event) => changeHandler(item, event)}
                    />
                  </label>
                </div>
              );
            } else if (data[item].type === "radio") {
              // console.log(data[item],'helo')
              return (
                <div key={idx} className="col-md-12 mr-3" id={item}>
                  <label className="fw-600">
                    <input
                      checked={data[item].value === 1}
                      disabled={data[item].enabled}
                      type={data[item].type}
                      value={data[item].value}
                      onClick={(event) => changeHandler(item, event)}
                      name={data[item].id}
                    />
                    &nbsp;&nbsp;{data[item].name}
                  </label>
                  {data[item].data !== undefined ? (
                    <NewConfigL4
                      data={data[item].data}
                      status={data[item].value}
                    />
                  ) : (
                    ""
                  )}
                </div>
              );
            }
          }
        })}
      </div>
    </div>
    // <>hello</>
  );
};

export default NewConfigL3;
