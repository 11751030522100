
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const KpiTruckTransitDelayActions = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        console.log("TransitDelay ",props.data);
        
        if(props.data.transit_delay == 1)
        {
            props.context.componentParent.onShowTransitKpi(props);
        }
        else
        {
            props.context.componentParent.onShowTransitKpi(0);
        }
        
        
    };
    

    return (
        <div>
			<button onClick={handleOverspeedClick} className="custom-btn f22 label label-success" title="Transit Delay" style={{color:"#ff0000"}}><i className="fa fa-clock-o"></i> &nbsp;</button>
            
        </div>
    );
};

export default KpiTruckTransitDelayActions;
