import React, { Component } from "react";
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import redirectURL from '../redirectURL'
// import ColumnChartLoadingDashboard from "./columnChartLoadingDashboard";
import $ from "jquery";
// import BubbleChart from "./bubbleChart";
import LineLoadingDashboard from "./lineLoadingDashboard";
import ScatterChartLoadingDashboard from "./scatterChartLoadingDashboard";
import ColumnDrilldownLoadingDashboard from "./columndrilldownloading";
import ColumnChartLoadingDashboard from "./columnChartLoadingDashboard";
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import AverageTransitTime from "../optirun-admin/averageTransitTime";
import UnLoadingDashboard from "./unloadingdashboard";
import {    getHyphenYYYYMMDDHHMMSS,getHyphenDDMMMYYYYHHMM,} from '../common/utils';
import HourlyGateOutChart from "./hourlyGateOut";
import Datetime from 'react-datetime';
import PieChartLoadingDashboard from "./pieChartLoadingDashboard";


var moment = require('moment');
export default class LoadingAnalysisData extends Component {
    constructor(props) {
        super(props)
        this.displayData = [];
        this.state = {
            pre_sdate:moment.parseZone().subtract(2, 'months').format('YYYY-MM-DD'),
			pre_edate:moment.parseZone().format('YYYY-MM-DD'),

            fromDate: moment.parseZone().subtract(2, 'months').format('YYYY-MM-DD'),
            toDate: moment.parseZone().format('YYYY-MM-DD'),
            plantCode: "",
            transporterCode: "",
            plants: [],
            transportersList: [],
            transporters:'',
            alert: null,
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true,
                minWidth: 70,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            rowData: [],
            loadingData: [],
            paginationPageSize: 50,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            activeTabId:"LOADING",
            flTransporter:"",
            transportersCodes:"",
            plantCodes:"",
            data:'',
            show:false,
            basicType:"default",
            basicTitle:"",
            maximumLoadingPlant:'',
            maximumLoadingTime:'',
            minimumLoadingPlant:'',
            minimumLoadingTime:'',
            locationNames:[],
            location:'',
            pre_selectedPlant:"",
            loadshow: 'show-n',
            overlayBlock:'show-n',
        }
    }

    componentDidMount() {
        this.setState({
			overlayBlock:'show-m',
            loadshow:"show-m"
        })
        var dpt;
        let params = {
            data_type : 2
        }
        this.boundTypeLocations(params)
        redirectURL.post("/tracking/plants", { dept_code: (dpt) })
            .then(async (resp) => {
                let responseData = resp.data.plants;
                let plantsList = [];
                plantsList.push({ value: "All", label: "All" });
                responseData.map(item => {
                    plantsList.push({ value: item.plant_code, label: item.plant_name })
                })
                this.setState({
                    plants: plantsList
                });
            })
            .catch((err) => {
                var errDetails = {
                    url: "/tracking/plants",
                    screen: '',
                    err,
                }
                redirectURL.post("/master/logErrorsForApiCalls", errDetails)

            })

        redirectURL.post("/tracking/getConsignmentsNewFilterKeysForSndTrucks", { dept_code: (dpt) })
            .then(async (resp) => {
                let responseData = resp.data.data.transporters
                let transportersList = [];
                responseData.map(item => {
                    transportersList.push({ value: item.transporter_code, label: item.transporter_name })
                })
                let transportersArray = [...new Set(transportersList)]
                // console.log(transportersArray)
                this.setState({ transportersList: transportersArray })
            })
            .catch((err) => {
                var errDetails = {
                    url: "/tracking/getConsignmentsNewFilterKeysForSndTrucks",
                    screen: "",
                    err,
                }
                redirectURL.post("/master/logErrorsForApiCalls", errDetails)
            })

        this.getAnalysisData()

    }

    getAnalysisData = () => {
        this.setState({
            loadshow:"show-m",
            overlayBlock:"show-m"
        })
        let fDate = this.state.fromDate
        // console.log(fDate)
        let fromDate = getHyphenYYYYMMDDHHMMSS(fDate)
        let tDate = this.state.toDate
        let toDate = getHyphenYYYYMMDDHHMMSS(tDate)
        var selectedPlant = this.state.plantCode === "" ? "": this.state.plantCode.value
        let transportersCodes = this.state.transportersCodes
        
        let params = {
            plant_code: selectedPlant === "All" ? "": selectedPlant,
            from_date: fromDate,
            to_date: toDate,
            transporter_code: transportersCodes,
        }
        console.log(params)
        redirectURL.post("/tracking/getloadingAnalysisData", params)
            .then(async (resp) => {
                // console.log(resp.data.complete_data,'hello')
                console.log(resp.data,'lllll')
                var data = resp.data
                var complete_data=JSON.parse(resp.data.complete_data)
                console.log(complete_data,'kjkj')
                if (complete_data.length > 0){
                
                let top10Loading = JSON.parse(resp.data.top_10_longest_loading_data)
                let completeData = JSON.parse(resp.data.complete_data)
                
                var maximumLoadingPlant=''
                var maximumLoadingTime=''
                var minimumLoadingPlant=''
                var minimumLoadingTime=''
                
                
                maximumLoadingPlant=Object.keys(data.min_max_loading_plants.maximum_loading_time)[0]
                maximumLoadingTime=(data.min_max_loading_plants.maximum_loading_time[maximumLoadingPlant]).toFixed(2)
                minimumLoadingPlant=Object.keys(data.min_max_loading_plants.minimum_loading_time)[0]
                minimumLoadingTime=(data.min_max_loading_plants.minimum_loading_time[minimumLoadingPlant]).toFixed(2)
                

                    this.setState({
                        data: data,
                        completeData: resp.data.complete_data,
                        plantWiseAvgData: resp.data.plant_wise_avg_data,
                        rowData: top10Loading,
                        loadingData: completeData,
                        maximumLoadingPlant,maximumLoadingTime,
                        minimumLoadingPlant,minimumLoadingTime,
                        loadshow:"show-n",
                        overlayBlock:"show-n"

                    })
                }                
                else{
                    console.log('hit here')
                    await this.setState({
                        show:true,
                        basicTitle:"No Data Found",
                        basicType:"danger",   
                        loadshow:"show-n",
                        overlayBlock:"show-n"           
                    })

                }
                // console.log(top10Loading)

            })
            .catch((err) => {
                var errDetails = {
                    url: "/tracking/getloadingAnalysisData",
                    screen: "",
                    err,
                }
                redirectURL.post("/master/logErrorsForApiCalls", errDetails)
                this.setState({
                    loadshow:"show-n",
                    overlayBlock:"show-n"
                })
            })
    }

    boundTypeLocations = async(param) => {

        let location_access = JSON.parse(localStorage.getItem("location_access"))

        let locationNames = [];

        if(location_access == null){
            locationNames.push({label:'ALL',value:''})
        }
        
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                        if(location_access != null){
                            if(location_access.includes(item.location_code)){
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })

                                }
                            }else{
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })
                            }
                      })
                      
                      if(locationNames.length>0){
                        await this.setState({
                                    locationNames : locationNames,
                                    location : locationNames[0]
                                })
                    }else{
                        await this.setState({
                            locationNames : [{label:'Please Add Data',value:'no_data'}],
                            location : [{label:'Please Add Data',value:'no_data'}]
                        })
                    }
                  }
              }
          })
          .catch(function (error) {
            var errDetails={
                url:"master/getBoundTypeLocations",
                screen:'Analytics Dashboard',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
      }

    closeAlert = async() => {
        let pre_selected_plant = this.state.pre_selectedPlant;
        let pre_sdate = this.state.pre_sdate;
        let pre_edate = this.state.pre_edate;
        await this.setState({
            show: false,
            fromDate:pre_sdate,
            toDate:pre_edate,
            // fromDate: moment.parseZone().subtract(2, 'months').format('YYYY-MM-DD HH:mm'),
            // toDate:moment.parseZone().format('YYYY-MM-DD HH:mm'),
            plantCode:pre_selected_plant,
            
        });
        // this.getAnalysisData()
	}

    handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		this.pre_sdate=this.state.fromDate
		var fromdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		var pre_sdate=this.state.fromDate
		this.setState({
			fromDate: fromdate,
			pre_sdate:pre_sdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		this.pre_edate=this.state.toDate
		var todate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		// var pre_edate=this.state.toDate
		this.setState({
			toDate: todate,
			pre_edate:this.pre_edate
		});
	}

    onChangeConsignerArrayHandler = (e) => {
        let value = e.target.value
        this.setState(
            { plantCode: value },
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    newFilter = (filterType, selOption) => {
        this.setState({ [filterType]: selOption });
    }

    onChangePlantCode = (event) => {
        let pre_selected_plant = this.state.plantCode;

        console.log(event.value)
        this.setState({
            plantCode:event,
            pre_selectedPlant:pre_selected_plant
        })
    }

    onClickSubmit = async() => {
        this.getAnalysisData()
    }

    onChangeTab = tabId => {
		this.setState({activeTabId:tabId})
	}
    render() {

        var data = this.state.data
        var hourlyData = []
        var hourlyGateOut = []
        var timeDitr = []
        var monAvgLoading = []
        var avgData = []
        var tptAvgloadTime = []
        var totlaLoadingTime={}
        if (data !== undefined && data !== '' ) {
            hourlyData = JSON.parse(data.hourly_avg_loading_data)
            hourlyGateOut = JSON.parse(data.hourly_gateout_data)
            timeDitr = JSON.parse(data.time_distribution_data)
            monAvgLoading = JSON.parse(data.monthly_avg_loading_data)
            avgData = JSON.parse(data.plant_wise_avg_data)
            tptAvgloadTime = JSON.parse(data.tpt_wise_Avg_loading_time)
            totlaLoadingTime.data=[data.total_avg_loading_time_Hrs.total_avg_loading_time_hrs]
            totlaLoadingTime.name='Total Avg Loading Time'
            // console.log(data.total_avg_loading_time_Hrs,'total_avg_loading_time_Hrs')
            // console.log(JSON.parse(data.time_distribution_data), 'time_distribution_data')
            // console.log(JSON.parse(data.plant_wise_avg_data), 'plant_wise_avg_data')
            // console.log(JSON.parse(data.tpt_wise_Avg_loading_time), 'tpt_wise_Avg_loading_time')
            // console.log(JSON.parse(data.monthly_avg_loading_data), 'monthly_avg_loading_data')
            // console.log(JSON.parse(data.hourly_gateout_data), 'hourly_gateout_data')
            // console.log(JSON.parse(data.hourly_avg_loading_data), 'hourly_avg_loading_data')
        }

        var categories = {}, i = 0
        var plants = []
        hourlyData.map((item) => {
            if (!plants.includes(item.plant_name)) {                
                plants.push(item.plant_name)
            }
        })

        var series = [], hourlyGateOutSeries = [], monthlyLoading = []
        var monAvgLoadingCat = {}, plantAvgData = []
        plantAvgData.push(totlaLoadingTime)
        plants.map((plant) => {
            var data = {}
            data.name = plant
            var srData = []
            hourlyData.map((item) => {
                if (item.plant_name === plant) {
                    srData.push(parseInt(item.Average_Loading_Time_Hrs))
                    categories[i] = item.time_interval
                    i += 1
                }
            })
            data.data = srData
            series.push(data)            
            data = {}
            data.name = plant
            srData = []
            // console.log(hourlyGateOut,'helo1')
            hourlyGateOut.map((item) => {
                if (item.plant_name === plant) {
                    // console.log(item,'item')
                    srData.push(parseInt(item['hourly gate count']))
                }
            })
            data.data = srData
            hourlyGateOutSeries.push(data)


            data = {}
            data.name = plant
            srData = []
            var j = 0
            monAvgLoading.map((item) => {
                if (item.plant_name === plant) {
                    srData.push(parseFloat(item.Average_Loading_Time_Hrs))
                    monAvgLoadingCat[j] = item.month_year
                    // monAvgLoadingCat.push({j:item.month_year})
                    // console.log(j,item.month_year)
                    j += 1
                }
            })
            data.data = srData
            monthlyLoading.push(data)

            data = {}
            data.name = plant
            srData = []
            avgData.map((item) => {
                if (item.plant_name === plant) {
                    srData.push(parseFloat(item.Average_Loading_Time_Hrs))
                }
            })
            data.data = srData
            plantAvgData.push(data)
        })
        // console.log(monAvgLoadingCat[1],'monAvgLoadingCat')
        var scatterdepartmentarray = []
        var scatterData = []
        timeDitr.map((item) => {
            scatterData.push({ name: item.cluster, y: (parseFloat(item.percentage)) })
        })
        scatterdepartmentarray = [{ name: 'NESTLE', data: scatterData }]


        var tptDrillDown = [], tptdrillSeries = []
        // console.log(tptAvgloadTime,'tpt')
        tptAvgloadTime.map((item) => {
            tptDrillDown.push({
                name: item.transporter_name,
                y: parseFloat(item.Average_Loading_Time_Hrs),
                drilldown: item.transporter_name,
            })
            var drillDownData = {}
            drillDownData.name = item.transporter_name
            drillDownData.id = item.transporter_name
            var data = []
            var truckType = JSON.parse(item.truck_type_level)
            // console.log(truckType,'l')
            // console.log(truckType,'hai')
            if(truckType!==undefined){
            Object.keys(truckType).map((truck) => {
                data.push([truck, parseFloat(truckType[truck])])
            })
            }
            drillDownData.data = data
            // console.log(data,'h',k)
            tptdrillSeries.push(drillDownData)
        })

        // console.log( 'drill', tptdrillSeries)
        var columnwithDefs = [
            {
                headerName: "Plant Name",
                field: "Plant Name",
                // width: 150
            },
            {
                headerName: "Truck No",
                field: "Truck No",
                // width: 120
            },
            {
                headerName: "Transporter Name",
                field: "Transporter Name",
                // width: 200,
                valueGetter: function (params) {
                    if (params.data["Transporter Name"] !== undefined || params.data["Transporter Name"] !== null) {
                        return params.data["Transporter Name"];
                    }
                }
            },
            

            {
                headerName: "GPS Gate In(DD-MM-YYYY HH:MM) ",
                field: "Gate In",
                width: 250,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data["Gate In"])
                },
                comparator: dateComparator,

            },
            {
                headerName: "GPS Gate Out(DD-MM-YYYY HH:MM)  ",
                field: "Gate Out",
                width: 250,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data["Gate Out"])
                },
                comparator: dateComparator,
            },
           
            {
                headerName: "Loading Time (Hrs)",
                field: "Loading Time (Hrs)",
                // width: 120,
            },
        ]
        
        var activeTabId = this.state.activeTabId
        return (
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            {this.state.alert}
                    <div className="row">
                        <div className="row col-xl-12 col-lg-12"> 
                        <ul className="d-tabs">
                            <li className={activeTabId === "LOADING" ? "lttabs activet" : "lttabs"}>
                                <button className="btn" type="button"
                                    onClick={()=>this.onChangeTab("LOADING")}                               
                                >
                                    Loading Dashboard
                                </button>
                            </li>
                            <li className={activeTabId === "UNLOADING" ? "lttabs activet" : "lttabs"}>
                                <button type="button" className="btn"
                                    onClick={()=>this.onChangeTab("UNLOADING")}                               
                                >
                                    Unloading Dashboard
                                </button>
                            </li>
                            <li className={activeTabId === "AVERAGE" ? "lttabs activet" : "lttabs"}>
                                <button className="btn" type="button"
                                    onClick={()=>this.onChangeTab("AVERAGE")}                             
                                >
                                    AverageTransitTime
                                </button>
                            </li>
                        </ul>                         
                        </div>  
                        
                        { activeTabId === "LOADING" &&                                               
                            <div className=" row col-xl-12 col-lg-12 p-2">
                                <div className="form-group col-xl-2 col-lg-2" >
                                    <label style={{color:"black"}}>Select Plant: </label>
                                    <Select
                                        maxHeight={200}
                                        placeholder={"All"}
                                        closeMenuOnSelect={true}                
                                        value={this.state.plantCode}
                                        className="border-radius-0"
                                        onChange={this.onChangePlantCode}
                                        style={{ borderRadius: "0px"}}
                                        options={this.state.locationNames}
                                        required
                                    />
                                </div>

                                <div className="col-xl-2 col-lg-2 form-group">
                                <label style={{color:"black"}}>From Date</label>
                                <Datetime
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ name: 'fromDate', autoComplete: 'off', readOnly: 'true'}}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat={false}
                                    value={this.state.fromDate}
                                    placeholder={moment.parseZone().format('YYYY-MM-DD')}
                                    onChange={this.handlerStartDateTime} />
                            </div>
                            <div className="col-xl-2 col-lg-2 form-group">
                                <label style={{color:"black"}}>To Date</label>
                                <Datetime
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ name: 'toDate', autoComplete: 'off', readOnly: 'true'}}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat={false}
                                    value={this.state.toDate}
                                    placeholder={moment.parseZone().format('YYYY-MM-DD')}
                                    onChange={this.handlerEndDateTime} />
                            </div>

                                <div className="form-group col-xl-2 col-lg-2 pt-3" style={{ marginLeft: "0px" }}>
                                    <button type="button" className="btn btn-success m-3" onClick={this.onClickSubmit}>Submit</button>
                                </div>



                            </div>
                        }
                    </div>

                    {/* Loading Dashboad */}

                    { activeTabId === "LOADING" &&                      
                    <div className="row" id="Loading Dashboard">  
                    <div className="row col-xl-12 col-lg-12">
                    </div>                      
                        <div className="row col-xl-12 col-lg-12">
                            <div className="row col-xl-12 col-lg-12 mb-4">
                                <div className="col-xl-6 col-lg-6 mb-2">
                                <div style={{color:'black',paddingLeft:'5px',marginLeft:'5px'}}>
                                    
                                    <h5>Minimum Loading Time</h5>
                                        <h6 style={{paddingLeft:'5px'}}>{this.state.minimumLoadingPlant} : {this.state.minimumLoadingTime}hrs</h6>
                                    {/* <h5>{this.state.maximumLoadingTime}</h5> */}
                                </div>
                                    <ColumnChartLoadingDashboard
                                        title={"Plant Wise Avg. Time"}
                                        subtitle={""}
                                        context={this}
                                        xaxistitle={"Gate In Hour"}
                                        yaxistitle={"Avg. Loading Time (Hrs)"}
                                        pointpostfix={"Hrs"}
                                        categories={{ 0: 'Nestle' }}
                                        series={plantAvgData}
                                    />
                                </div>

                                <div className="col-xl-6 col-lg-6 mb-2">
                                    {/* <div>
                                        <h5>Minimum Loading Time</h5>
                                        <h5>{this.state.minimumLoadingPlant}</h5>
                                        <h5>{this.state.minimumLoadingTime}</h5>
                                    </div> */}
                                    <div style={{color:'black',paddingLeft:'5px',marginLeft:'5px'}}>
                                        <h5>Maximum Loading Time</h5>
                                    <h6  style={{paddingLeft:'5px'}}>{this.state.maximumLoadingPlant} : {this.state.maximumLoadingTime}hrs</h6>
                                    {/* <h5>{this.state.maximumLoadingTime}</h5> */}
                                    </div>
                                    <PieChartLoadingDashboard
                                        title={"Loading Time Distribution"}
                                        subtitle={""}
                                        context={this}
                                        data={scatterData}
                                        // series={scatterdepartmentarray}
                                    //categories={item.categories}
                                    //yaxistitle={item.yaxistitle}
                                    //dept_code={this.state.dept_code}
                                    />
                                </div>
                            </div>
                            <div className="row col-xl-12 col-lg-12 mb-4">
                                <div className="col-xl-6 col-lg-6 mb-2">
                                    <LineLoadingDashboard
                                        title={"Hourly Loading Avg. Time"}
                                        subtitle={""}
                                        context={this}
                                        xaxistitle={"Gate In Hour"}
                                        yaxistitle={"Avg. Loading Time (Hrs)"}
                                        pointpostfix={"Hrs"}
                                        categories={categories}
                                        series={series}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6 mb-2">
                                    <LineLoadingDashboard
                                        title={"Monthly Avg. Loading Time"}
                                        subtitle={""}
                                        context={this}
                                        xaxistitle={"Gate In Hour"}
                                        yaxistitle={"Avg. Loading Time (Hrs)"}
                                        pointpostfix={"Hrs"}
                                        categories={monAvgLoadingCat}
                                        series={monthlyLoading}
                                    />
                                </div>

                            </div>
                            <div className="col-xl-12 col-lg-12 mb-3">
                                <HourlyGateOutChart
                                    title={'Hourly Gate Out '}
                                    subtitle={"Avg. number of carrriers exiting the plant in that hour"}
                                    context={this}
                                    series={hourlyGateOutSeries}
                                    categories={categories}
                                    yaxistitle={"No. of Trucks"}
                                // drilldownseries={this.state.transdrilldown}
                                // dept_code={this.state.dept_code}
                                />
                            </div>

                            <div className="col-xl-12 col-lg-12 mb-3">
                                <ColumnDrilldownLoadingDashboard
                                    title={'Avg. Loading Time / Transporter '}
                                    subtitle={"Avg. Time taken inside the plant from Gate in to Gate out per transporter"}
                                    context={this}
                                    series={tptDrillDown}
                                    // categories={item.categories}
                                    // yaxistitle={"Wt. Avg Loading Time"}
                                    drilldownseries={tptdrillSeries}
                                // dept_code={this.state.dept_code}
                                />
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 m-2 p-1"
                            style={{ backgroundColor: "white" }}>
                            <h5>Top 10 longest Loading Data </h5>
                            <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    // onGridReady={this.onGridReady}
                                    // onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                />

                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 m-2 p-1"
                            style={{ backgroundColor: "white" }}>
                            <h5>Loading Data </h5>
                            <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.loadingData}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    // onGridReady={this.onGridReady}
                                    // onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                />

                            </div>
                        </div>
                    </div>
                    }

                    {
                        activeTabId === "AVERAGE" && 
                        <div className="col-xl-12 col-lg-12 mt-2">                            
                            <AverageTransitTime />
                        </div>

                    }
                    {
                        activeTabId === "UNLOADING" && <UnLoadingDashboard />
                    }

                    <div
                        className={"overlay-block " + this.state.overlayBlock}
                        onClick={this.hideSlideBlock}
                    ></div>
                    <div className={"loader " + this.state.loadshow}></div>
                
            </>
        )
    }

}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}