
import React, { Component } from 'react';

 
const RepeatIcon = (props) => {
    
  
    return (
        <div>
           
            {( props.data.is_available !== undefined && props.data.is_available  !== "" )?
            props.data.is_available === 0?
            <span className='text-info'>
            <i  style={{color:"#ff0000"}} className="fa fa-repeat" aria-hidden="true"></i> {props.data.vehicle_no == "Not Assigned" ?'Not Available':props.data.vehicle_no}
            </span>
            :props.data.vehicle_no
            :props.data.vehicle_no
            }        
        </div>
    );
};

export default RepeatIcon;
