
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ShowTPTGpsMap = (props) => {
    //console.log("LoadingDelay ",props);
    const handleUnLoadingDelayClick = (e) => {
        e.stopPropagation();
        
       props.context.componentParent.onShowTPTGPSMapRoute(props);
    };
   
    return (
        <div>
           <button onClick={handleUnLoadingDelayClick} className="custom-btn f22 label label-success" title="View Map" ><i className="fa fa-globe"></i> &nbsp;</button>
            
        </div>
    );
};

export default ShowTPTGpsMap;
