import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function PieChartLoadingDashboard(props) {
  const highchartoptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: "100%",
      // width:'100%'
    },
    title: {
      text: "Loading Time Distribution",
      align: "center",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        size:'50%'
      },
    },
    series: [
      {
        name: "Data",
        colorByPoint: true,
        data: props.data, // Pass data from props
      },
    ],
    credits: false,
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={highchartoptions} />
    </div>
  );
}

export default PieChartLoadingDashboard;
