import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ConsignmentInformation = (props) => {
    const handleRouteClick = (e) => {
        // console.log("I am Clicking")
        e.stopPropagation();
         props.context.componentParent.onClickShowConsignmentInformation(props);
    };

    return (
        <div className="map-icon-component">
			{/*<button onClick={handleClick} className="custom-btn label label-success" title="View Timeline"><i className="icofont icofont-tree-alt"></i> Timeline </button>
            &nbsp;| &nbsp;
            */}
            <button  onClick={handleRouteClick} className="custom-btn label label-success" title="Consignment Information"><i style={{color:"#17a2b8"}} className="fa fa-info-circle f15"></i> </button>
       
        </div>
    );
};

export default ConsignmentInformation;