import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/heatmap')(Highcharts);

const HeatmapChart = ({data}) => {
  useEffect(() => {
    if (!data || Object.keys(data).length === 0) {
      console.error("Invalid data structure");
      return;
    }

    // Extract common dates from the first vehicle
    const commonDates = Object.keys(data["0"]).filter(key => key !== 'vehicle_no');

    const seriesData = Object.keys(data).flatMap(vehicleId => (
      commonDates.map(date => [commonDates.indexOf(date), parseInt(vehicleId), +data[vehicleId][date]])
    ));

    const options = {
      chart: {
        type: 'heatmap',
        marginTop: 40,
        marginBottom: 80,
      },
      title: {
        text: 'Vehicle Utilization Chart',
      },
      xAxis: {
        type: 'category',
        categories: commonDates,
        title: {
          text: 'Dates',
          style: {
            fontWeight: 'bold',
          },
        },
        labels: {
          style: {
            fontWeight: 'bold',
          },
        },
      },
      yAxis: {
        title: {
          text: 'Vehicle Numbers',
          style: {
            fontWeight: 'bold',
          },
        },
        categories: Object.keys(data).map(vehicleId => data[vehicleId].vehicle_no),
        labels: {
          style: {
            fontWeight: 'bold',
          },
        },
      },
      colorAxis: {
        min: 0,
        max: 1,
        minColor: 'yellow',
        maxColor: "#28a745",
        labels: {
          formatter: function () {
            return this.value === 0 ? 'Non-Utilized' : 'Utilized';
          },
          style: {
            fontWeight: 'bold',
          },
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0.5,
          borderColor: 'lightgrey',
          // dataLabels: {
          //   enabled: true,
          //   format: '{point.value}',
          // },
        },
      },
      tooltip: {
        formatter: function () {
          const status = this.point.value === 0 ? 'Non-utilized' : 'Utilized';
          return `<b>Vehicle No:</b> ${data[this.point.y].vehicle_no}<br><b>Date:</b> ${commonDates[this.point.x]}<br><b>Utilization Status:</b> ${status}`;
        },
      },
      series: [{
        data: seriesData,
      }],
    };

    Highcharts.chart('chart-container', options);
  }, [data]);

  return (
    <div>
      <div id="chart-container" style={{ width: '100%', height: '100%' }}>
      </div>
    </div>
  );
};

export default HeatmapChart;