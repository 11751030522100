import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { getHyphenDDMMMYYYYHHMM} from '../common/utils'; 
import {verifyext} from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import ColumnChart from "./columnChartComponent";
import PolarChart from "./polarChartComponent";
import AreaRangeChart from "./areaRangeChart";
import ViewTransData from "./viewtransactiondata";

var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

export default class ForecastInputData extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                GridButton:GridButton,
                ViewTransData:ViewTransData
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            showSlideBlockStack:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            scluster:"",
            stackContent:"",
            stackImage:"",
            stacktbl:[],
            stacktblbottom:[],
            stackOriginalData:[],
            showrunprocessbtn:"show-m",
            showanimate:"show-n",
            locationNames:[],
            loadFile:'',
            material_configurations:0,
            location:{label:"Select Location",value:""},
            fy:{label:"Select",value:""},
            fyChart:{label:"Select",value:""},
            loadshow:'show-m',
            overly:'show-m',
            processErrMsg : "",
            processErrShow : "",
            transactionId : "",
            droppedList : [],
            chartdata:"show-n",
            datagrid:'show-m',
            datagridTab:"activet",
            chartdataTab:"btn-default",
            chartseries:[],
            defFY:{label:"Select",value:""},
            selectFY:{label:"Select",value:""},
            itemCd:{label:"Select",value:"All"},
            itemCodesList:{value:"",label:"All"},
            itemCodeSeries:[]
        }
        
    }
    async componentDidMount() {
        this.setState({
            fyChart:[{value:"2021-2022",label:"2021-2022"}],
            // selectFY:{value:"2021-2022",label:"2021-2022"}
        })
        let url_type = window.location.href
        let path_type = url_type.split('?')
        let data_type
        let path = path_type[path_type.length-1]

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'));
        var material_configurations = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                material_configurations = toolConfig[0].material_configuration;
            }
        }
        this.setState({
            material_configurations : material_configurations
        });
        await this.boundTypeLocations(params)
        await this.loadInputPlanData()
    }

    loadInputPlanData = () =>{
        var params = {
            fy : this.state.selectFY.value
        }
        redirectURL.post('/forecast/fyinputdata',params)
        .then(response =>{
            var records = response.data;
            var rowdata = [];
            var itemCodesList = [];
            itemCodesList.push({value:"",label:"All"});
            var itemCodeSeries  = [];
            var months = ["Jan","Feb","Mar","Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            if(records.length > 0)
            {
                var itmdata = groupBy(records, rdata => rdata.fy);
                // console.log("itmdata ", JSON.stringify(itmdata))
                try {
                    itmdata.forEach((valueslist,keys)=>{
                        if(valueslist.length > 0)
                        {
                            var valueslistXC =  groupBy(valueslist, rdata => rdata.customer_code);
                            valueslistXC.forEach((valuesA,aKey) => {
                                if(valuesA.length > 0)
                                {
                                    var valueslistX =  groupBy(valuesA, rdata => rdata.item_code);
                                    valueslistX.forEach((values,keys)=>{
                                        itemCodesList.push({itemcode:keys});
                                        var rw = {}
                                            if(values.length > 0)
                                            {
                                                rw["_id"] = values[0]._id;
                                                rw["transaction_id"] = values[0].transaction_id;
                                                rw["fy"] = values[0].fy;
                                                rw["fy_start_year"] = values[0].fy_start_year;
                                                rw["fy_end_year"] = values[0].fy_end_year;
                                                rw["item_code"] = values[0].item_code;
                                                rw["customer_code"] = values[0].customer_code;
                                                rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
        
                                            values.map((i) => {
                                                rw[months[parseInt(i.month)-1]] = i.item_qty;
                                                rw[months[parseInt(i.month)-1]+"_cost"] = i.item_total_cost;
                                            })
                                            // console.log("RWW ", JSON.stringify(rw))
                                            rowdata.push(rw);
                                        }
                                    })
                                }
                            })
                            
                        }
                    })
                } catch (error) {
                    
                }
                records.map((item) => {

                })
            }
            var chartseries = {};
            if(rowdata.length > 0)
            {

                var fywisedata = groupBy(rowdata, rdata => rdata.fy);
                try {
                    // console.log("fywisedata ",JSON.stringify(fywisedata))
                    var overallordersfy=[];
                    var overallcostfy=[];
                    var fynonths=[]
                    var fynonthsCost  = [];
                    var historify=[];

                    
                    var itmoverallordersfy=[];
                    var itmoverallcostfy=[];
                    var itmfynonths=[]
                    var itmfynonthsCost  = [];
                    var itmhistorify=[];
                    fywisedata.forEach((values,keys) => {
                        var totlOrders = 0;
                        var totlCosts = 0;

                        var itmtotlOrders = 0;
                        var itmtotlCosts = 0;
                        // console.log("values",values)
                        if(values.length > 0)
                        {
                            var monthfy = []
                            var monthfyCost = [];
                            var hmonthfy = []
                            var pmonthfy = []
                            var apr=0;
                            var may=0;
                            var jun=0;
                            var jul=0;
                            var aug=0;
                            var sep=0;
                            var oct=0;
                            var nov=0;
                            var dec=0;
                            var jan=0;
                            var feb=0;
                            var mar=0;

                            var apr_cost=0;
                            var may_cost=0;
                            var jun_cost=0;
                            var jul_cost=0;
                            var aug_cost=0;
                            var sep_cost=0;
                            var oct_cost=0;
                            var nov_cost=0;
                            var dec_cost=0;
                            var jan_cost=0;
                            var feb_cost=0;
                            var mar_cost=0;
                            values.map((item) => {
                                totlOrders = parseInt(totlOrders)+parseInt(item.Apr)+parseInt(item.May)+parseInt(item.Jun)+parseInt(item.Jul)+parseInt(item.Aug)+parseInt(item.Sep)+parseInt(item.Oct)+parseInt(item.Nov)+parseInt(item.Dec)+parseInt(item.Jan)+parseInt(item.Feb)+parseInt(item.Mar);

                                totlCosts = parseInt(totlCosts)+parseInt(item.Apr_cost)+parseInt(item.May_cost)+parseInt(item.Jun_cost)+parseInt(item.Jul_cost)+parseInt(item.Aug_cost)+parseInt(item.Sep_cost)+parseInt(item.Oct_cost)+parseInt(item.Nov_cost)+parseInt(item.Dec_cost)+parseInt(item.Jan_cost)+parseInt(item.Feb_cost)+parseInt(item.Mar_cost);
                                apr = parseInt(apr)+parseInt(item.Apr);
                                may = parseInt(may)+parseInt(item.May);
                                jun = parseInt(jun)+parseInt(item.Jun);
                                jul = parseInt(jul)+parseInt(item.Jul);
                                aug = parseInt(aug)+parseInt(item.Aug);
                                sep = parseInt(sep)+parseInt(item.Sep);
                                oct = parseInt(oct)+parseInt(item.Oct);
                                nov = parseInt(nov)+parseInt(item.Nov);
                                dec = parseInt(dec)+parseInt(item.Dec);
                                jan = parseInt(jan)+parseInt(item.Jan);
                                feb = parseInt(feb)+parseInt(item.Feb);
                                mar = parseInt(mar)+parseInt(item.Mar);

                                apr_cost = parseInt(apr_cost)+parseInt(item.Apr_cost);
                                may_cost = parseInt(may_cost)+parseInt(item.May_cost);
                                jun_cost = parseInt(jun_cost)+parseInt(item.Jun_cost);
                                jul_cost = parseInt(jul_cost)+parseInt(item.Jul_cost);
                                aug_cost = parseInt(aug_cost)+parseInt(item.Aug_cost);
                                sep_cost = parseInt(sep_cost)+parseInt(item.Sep_cost);
                                oct_cost = parseInt(oct_cost)+parseInt(item.Oct_cost);
                                nov_cost = parseInt(nov_cost)+parseInt(item.Nov_cost);
                                dec_cost = parseInt(dec_cost)+parseInt(item.Dec_cost);
                                jan_cost = parseInt(jan_cost)+parseInt(item.Jan_cost);
                                feb_cost = parseInt(feb_cost)+parseInt(item.Feb_cost);
                                mar_cost = parseInt(mar_cost)+parseInt(item.Mar_cost);

                            });
                            hmonthfy.push(apr);
                            hmonthfy.push(may);
                            hmonthfy.push(jun);
                            hmonthfy.push(jul);
                            hmonthfy.push(aug);
                            hmonthfy.push(sep);
                            hmonthfy.push(oct);
                            hmonthfy.push(nov);
                            hmonthfy.push(dec);
                            hmonthfy.push(jan);
                            hmonthfy.push(feb);
                            hmonthfy.push(mar);
                            monthfy.push({
                                name:"Apr",y:apr
                            },{
                                name:"May",y:may
                            },{
                                name:"Jun",y:jun
                            },{
                                name:"Jul",y:jul
                            },{
                                name:"Aug",y:aug
                            },{
                                name:"Sep",y:sep
                            },{
                                name:"Oct",y:oct
                            },{
                                name:"Nov",y:nov
                            },{
                                name:"Dec",y:dec
                            },{
                                name:"Jan",y:jan
                            },{
                                name:"Feb",y:feb
                            },{
                                name:"Mar",y:mar
                            });

                            monthfyCost.push({
                                name:"Apr",y:apr_cost
                            },{
                                name:"May",y:may_cost
                            },{
                                name:"Jun",y:jun_cost
                            },{
                                name:"Jul",y:jul_cost
                            },{
                                name:"Aug",y:aug_cost
                            },{
                                name:"Sep",y:sep_cost
                            },{
                                name:"Oct",y:oct_cost
                            },{
                                name:"Nov",y:nov_cost
                            },{
                                name:"Dec",y:dec_cost
                            },{
                                name:"Jan",y:jan_cost
                            },{
                                name:"Feb",y:feb_cost
                            },{
                                name:"Mar",y:mar_cost
                            });

                            overallordersfy.push({
                                name:"FY - "+keys,
                                y:(totlOrders < 0)?0:totlOrders,
                                lbl:totlOrders.toLocaleString()
                            });
                            // console.log("totlCosts ", totlCosts)
                            overallcostfy.push({
                                name:"FY - "+keys,
                                y:(totlCosts < 0)?0:totlCosts,
                                lbl:totlOrders.toLocaleString()
                            });
                            fynonths.push({
                                name:keys,
                                data:monthfy
                            })
                            fynonthsCost.push({
                                name:keys,
                                data:monthfyCost
                            })
                            historify.push(hmonthfy)
                            
                            // Item Code Wise Data
                            var itmcodata = groupBy(values, rdata => rdata.item_code);
                            itmcodata.forEach((itemls ,ikey) => {
                                // console.log("ikey ", ikey)
                                if(itemls.length > 0)
                                {
                                    var itmmonthfy = []
                                    var itmmonthfyCost = [];
                                    var itmhmonthfy = []
                                    var itmpmonthfy = []
                                    var apr=0;
                                    var may=0;
                                    var jun=0;
                                    var jul=0;
                                    var aug=0;
                                    var sep=0;
                                    var oct=0;
                                    var nov=0;
                                    var dec=0;
                                    var jan=0;
                                    var feb=0;
                                    var mar=0;
        
                                    var apr_cost=0;
                                    var may_cost=0;
                                    var jun_cost=0;
                                    var jul_cost=0;
                                    var aug_cost=0;
                                    var sep_cost=0;
                                    var oct_cost=0;
                                    var nov_cost=0;
                                    var dec_cost=0;
                                    var jan_cost=0;
                                    var feb_cost=0;
                                    var mar_cost=0;
                                    itemls.map((item) => {
                                        itmtotlOrders = parseInt(itmtotlOrders)+parseInt(item.Apr)+parseInt(item.May)+parseInt(item.Jun)+parseInt(item.Jul)+parseInt(item.Aug)+parseInt(item.Sep)+parseInt(item.Oct)+parseInt(item.Nov)+parseInt(item.Dec)+parseInt(item.Jan)+parseInt(item.Feb)+parseInt(item.Mar);
        
                                        itmtotlCosts = parseInt(itmtotlCosts)+parseInt(item.Apr_cost)+parseInt(item.May_cost)+parseInt(item.Jun_cost)+parseInt(item.Jul_cost)+parseInt(item.Aug_cost)+parseInt(item.Sep_cost)+parseInt(item.Oct_cost)+parseInt(item.Nov_cost)+parseInt(item.Dec_cost)+parseInt(item.Jan_cost)+parseInt(item.Feb_cost)+parseInt(item.Mar_cost);
                                        apr = parseInt(apr)+parseInt(item.Apr);
                                        may = parseInt(may)+parseInt(item.May);
                                        jun = parseInt(jun)+parseInt(item.Jun);
                                        jul = parseInt(jul)+parseInt(item.Jul);
                                        aug = parseInt(aug)+parseInt(item.Aug);
                                        sep = parseInt(sep)+parseInt(item.Sep);
                                        oct = parseInt(oct)+parseInt(item.Oct);
                                        nov = parseInt(nov)+parseInt(item.Nov);
                                        dec = parseInt(dec)+parseInt(item.Dec);
                                        jan = parseInt(jan)+parseInt(item.Jan);
                                        feb = parseInt(feb)+parseInt(item.Feb);
                                        mar = parseInt(mar)+parseInt(item.Mar);
        
                                        apr_cost = parseInt(apr_cost)+parseInt(item.Apr_cost);
                                        may_cost = parseInt(may_cost)+parseInt(item.May_cost);
                                        jun_cost = parseInt(jun_cost)+parseInt(item.Jun_cost);
                                        jul_cost = parseInt(jul_cost)+parseInt(item.Jul_cost);
                                        aug_cost = parseInt(aug_cost)+parseInt(item.Aug_cost);
                                        sep_cost = parseInt(sep_cost)+parseInt(item.Sep_cost);
                                        oct_cost = parseInt(oct_cost)+parseInt(item.Oct_cost);
                                        nov_cost = parseInt(nov_cost)+parseInt(item.Nov_cost);
                                        dec_cost = parseInt(dec_cost)+parseInt(item.Dec_cost);
                                        jan_cost = parseInt(jan_cost)+parseInt(item.Jan_cost);
                                        feb_cost = parseInt(feb_cost)+parseInt(item.Feb_cost);
                                        mar_cost = parseInt(mar_cost)+parseInt(item.Mar_cost);
        
                                    });
                                    itmhmonthfy.push(apr);
                                    itmhmonthfy.push(may);
                                    itmhmonthfy.push(jun);
                                    itmhmonthfy.push(jul);
                                    itmhmonthfy.push(aug);
                                    itmhmonthfy.push(sep);
                                    itmhmonthfy.push(oct);
                                    itmhmonthfy.push(nov);
                                    itmhmonthfy.push(dec);
                                    itmhmonthfy.push(jan);
                                    itmhmonthfy.push(feb);
                                    itmhmonthfy.push(mar);
                                    itmmonthfy.push({
                                        name:"Apr",y:apr
                                    },{
                                        name:"May",y:may
                                    },{
                                        name:"Jun",y:jun
                                    },{
                                        name:"Jul",y:jul
                                    },{
                                        name:"Aug",y:aug
                                    },{
                                        name:"Sep",y:sep
                                    },{
                                        name:"Oct",y:oct
                                    },{
                                        name:"Nov",y:nov
                                    },{
                                        name:"Dec",y:dec
                                    },{
                                        name:"Jan",y:jan
                                    },{
                                        name:"Feb",y:feb
                                    },{
                                        name:"Mar",y:mar
                                    });
        
                                    itmmonthfyCost.push({
                                        name:"Apr",y:apr_cost
                                    },{
                                        name:"May",y:may_cost
                                    },{
                                        name:"Jun",y:jun_cost
                                    },{
                                        name:"Jul",y:jul_cost
                                    },{
                                        name:"Aug",y:aug_cost
                                    },{
                                        name:"Sep",y:sep_cost
                                    },{
                                        name:"Oct",y:oct_cost
                                    },{
                                        name:"Nov",y:nov_cost
                                    },{
                                        name:"Dec",y:dec_cost
                                    },{
                                        name:"Jan",y:jan_cost
                                    },{
                                        name:"Feb",y:feb_cost
                                    },{
                                        name:"Mar",y:mar_cost
                                    });
        
                                    itmoverallordersfy.push({
                                        name:keys,
                                        itemname:ikey,
                                        y:(itmtotlOrders < 0)?0:itmtotlOrders,
                                        lbl:itmtotlOrders.toLocaleString()
                                    });
                                    // console.log("totlCosts ", totlCosts)
                                    itmoverallcostfy.push({
                                        name:keys,
                                        itemname:ikey,
                                        y:(itmtotlCosts < 0)?0:itmtotlCosts,
                                        lbl:itmtotlCosts.toLocaleString()
                                    });
                                    // console.log("itmmonthfy ", itmmonthfy)
                                    itmfynonths.push({
                                        name:keys,
                                        itemname:ikey,
                                        data:itmmonthfy
                                    })
                                    itmfynonthsCost.push({
                                        itemname:ikey,
                                        name:keys,
                                        data:itmmonthfyCost
                                    })
                                    itmhistorify.push(itmhmonthfy)
                                }
                            })
                        }


                    })

                    
                    // console.log("itmcodata ", itmcodata)
                    // console.log("overallcostfy ", itmoverallordersfy)
                    chartseries["overallfinancialorders"] = {name:"Orders",colorByPoint: true,data:overallordersfy}
                    chartseries["overallfinancialcost"] = {name:"Cost",colorByPoint: true,data:overallcostfy}
                    var selectedfymonths = fynonths.filter((f) => this.state.fyChart[0].value === f.name);
                    var selectedfymonthsCost = fynonths.filter((f) => this.state.fyChart[0].value === f.name);
                    chartseries["allfymonthwise"] = {name:"Orders",colorByPoint: true,data:fynonths}
                    chartseries["allfymonthwiseCost"] = {name:"Orders",colorByPoint: true,data:fynonthsCost}
                    chartseries["selectedfymonths"] = selectedfymonths
                    chartseries["selectedfymonthsCost"] = selectedfymonthsCost
                    chartseries["historic"] = historify

                    chartseries["all_overallfinancialorders"] = {name:"Orders",colorByPoint: true,data:overallordersfy}
                    chartseries["all_overallfinancialcost"] = {name:"Cost",colorByPoint: true,data:overallcostfy}
                    chartseries["all_allfymonthwise"] = {name:"Orders",colorByPoint: true,data:fynonths}
                    chartseries["all_allfymonthwiseCost"] = {name:"Orders",colorByPoint: true,data:fynonthsCost}
                    chartseries["all_selectedfymonths"] = selectedfymonths
                    chartseries["all_selectedfymonthsCost"] = selectedfymonthsCost
                    chartseries["all_historic"] = historify

                    chartseries["itemoverallorders"] = {name:"Orders",colorByPoint: true,data:itmoverallordersfy}
                    chartseries["itemoverallcost"] = {name:"Cost",colorByPoint: true,data:itmoverallcostfy}
                    var itmselectedfymonths = itmfynonths.filter((f) => this.state.fyChart[0].value === f.name);
                    var itmselectedfymonthsCost = itmfynonths.filter((f) => this.state.fyChart[0].value === f.name);
                    chartseries["itemallfymonthwise"] = {name:"Orders",colorByPoint: true,data:itmfynonths}
                    chartseries["itemallfymonthwiseCost"] = {name:"Orders",colorByPoint: true,data:itmfynonthsCost}
                    chartseries["itemselectedfymonths"] = itmselectedfymonths;
                    chartseries["itemselectedfymonthsCost"] = itmselectedfymonthsCost;
                    chartseries["itemhistoric"] = itmhistorify;
                    chartseries["dtype"] = "default"

                } catch (error) {
                    
                }
                // item = item.sort(GetSortAscOrder("sequence_no"));
            }
            // console.log("itemCodesList ", itemCodesList)
            var itemCodesListarr = [];
            var newArray = [];
            let totuniqueObject = {}; 
            var totobjTitle;
            // Loop for the array elements 
            for (let i in itemCodesList) { 

                // Extract the title
                if(itemCodesList[i]['itemcode'] !== "" && itemCodesList[i]['itemcode'] !== undefined)
                {
                    totobjTitle = itemCodesList[i]['itemcode']; 

                    // Use the title as the index 
                    totuniqueObject[totobjTitle] = itemCodesList[i];
                } 
                 
            } 
            // Loop to push unique object into array 
            for (var a in totuniqueObject) { 
                newArray.push(totuniqueObject[a]); 
            } 
            if(newArray.length > 0)
            {
                // itemCodesListarr.push({value:"",label:"All"})
                newArray.map((item) => {
                    itemCodesListarr.push({value:item.itemcode,label:item.itemcode});
                })
            }
            // console.log("itemCodesListarr ", itemCodesListarr)
            this.setState({
                rowData:rowdata,
                loadshow:'show-n',
                overly:'show-n',
                overlayBlock:"show-n",
                chartseries:chartseries,
                itemCodesList:itemCodesListarr
            })
        })
    }

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
                    await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    
    onClickShowUpload = () =>{
        this.setState({
            showSlideBlockUpload:"slide25",
            overlayBlock:"show-m"
        })
    }

    hideSlideBlock = () =>{
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:"",
            showSlideBlockRoute:"",
            showSlideBlockStack:"",
            loadFile:'',
            loadshow:'show-n',
            overly:'show-n',
        })
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }
    fixProcess = () => {
        this.setState({
            processErrMsg : "",
            processErrShow : 0,
            loadFile:''
        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
        //document.getElementById("upform").reset();
    }
    ignoreProcessError = () => {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var transactionId = this.state.transactionId;
        console.log("transactionId",transactionId)
        var params = {
            transactionId : transactionId,
            data_type : this.state.data_type,
        }
        redirectURL.post("dispatch/generateLoadByTransactionId",params).then((response)=>{
            //console.log(response.data,"response")
            var statusCode = response.data.status;
            if(statusCode == 0 || statusCode == 1)
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    processErrMsg : "",
                    processErrShow : 0,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
            }
            else
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            //window.location.reload();
        });
    }
    
    changeFileHandler = async (e) => {
        console.log(e);
        this.setState({
            loadFile : e.target.files[0]
        });  
        
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    
    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        var fy = this.state.fy.value;
        let saveData = new FormData();
        if(flag === 0 && fy !== "")
        {      
            var s = fy.split("-");
            var fyS = s[0];
            var fyE = s[1];
            saveData.append("file", this.state.loadFile);
           
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
            redirectURL.post("/forecast/readXLSData",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                var uploadxlsdata = response.data.records;
                //   console.log("uploadxlsdata ", uploadxlsdata)
                var fyData = [];
                  var months = ["apr", "may","jun","jul","aug","sep","oct","nov","dec","jan","feb","mar"];
               
                  if(uploadxlsdata.length > 0)
                  {
                    uploadxlsdata.map((item,ind) => {
                        
                        // console.log("items ", item)
                        var emonths = Object.keys(item);
                        var keyarr = [];
                        emonths.map((key) => {
                            // console.log("Keys ", key)
                            if(key !== "YEAR" && key !== "customer_code_1" && key !== "item_code" && key !== "nrb_customer_code_1")
                            {
                                var k = key.split("_");
                                // console.log("kkkk ", k)
                                keyarr.push(k[1]);
                            }
                        })
                        if(keyarr.length > 0){
                            var diffkeys = arr_diff(keyarr,months);
                            // console.log("diffkeys ", diffkeys)
                            if(diffkeys.length > 0)
                            {
                                diffkeys.map((id) => {
                                    if(id !== "itemIndex")
                                    {
                                        item["month_"+id] = 0;
                                    }
                                })
                            }
                        }
                        // console.log("after ", item)
                        fyData.push(item);
                        
                    })
                  }
                  if(fyData.length > 0)
                  {
                    var p = {
                      xlsdata:fyData,
                      fy:fy
                    }
                    // console.log("p ", p)
                    redirectURL.post("/forecast/savefyinputdata",p)
                    .then((resp) => {
                    //   console.log(resp.data)
                      if(resp.data.message == "success")
                      {
                        this.setState({
                          uploadfile:"",
                          showSlideBlockUpload:"",
                          overlayBlock:"show-n",
                          overly:"show-n",
                          loadshow:"show-n",
                          show:true,
                          basicTitle:"Successfully upload data",
                          basicType:"success",
                          showloader:"show-n"
                        });
                        window.location.reload();
                      }
                      else{
                        this.setState({
                          show:true,
                          basicTitle:"Failed to upload data",
                          basicType:"danger",
                          showloader:"show-n"
                        });
                      }
                    })
                  }
            });
        }
        else
        {
            this.setState({
                basicTitle : "Please select Financial Year",
                basicType : "warning",
                show : true,
            })
        }
    };

    checkTransactionStatus = (transactionId) =>{
        var query = {
            transaction_id : transactionId
        }
        redirectURL.post("/dispatch/checkTransactionStatus",query).then((response)=>{
            console.log(response,"responseFromcheckTransactionStatus")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode == 0)
            {
                var checkCount = this.state.checkCount;
                var updatedCnt = parseInt(checkCount)+1;
                this.setState({
                    checkCount : updatedCnt
                });
                console.log(updatedCnt,"checkCount")
                if(updatedCnt < 3)
                {
                    this.chkStatus(response.data.transactionId);
                }
                else
                {
                    this.setState({                    
                        basicTitle : "File is processing",
                        basicType : "success",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        showSlideBlockUpload:"",
                        loadFile:''
                    })
                    $("#uploadFile").val("");
                    $("#notify_email").val("");
                }
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
                this.getDispatchPlanData()
            }
        });
    }

    chkStatus = (transactionId) => {
        this.checkTransactionStatus(transactionId);
    }

    viewPlanDetails = async(propdata) =>{
        var data = propdata.data;
        let basestring = data.transaction_id;
        let encryptedstring = window.btoa(basestring);
        window.location.href = '/viewdispatchplandetails?'+encryptedstring;
    }


    locationChanged = async(location) =>{
        await this.setState({
            location : location
        })
        await this.getDispatchPlanData()
    }
    fyitems(){
        var items = [];
        var c = moment.parseZone().format("YYYY");
        for(var n = 2016;n<parseInt(c)-1;n++)
        {
            items.push({value:n+"-"+(parseInt(n)+1),label:n+"-"+(parseInt(n)+1)})
        }
        return items;
    }
    onChangeFY = async(fy) =>{
        await this.setState({fy},()=>{console.log("Select ", this.state.fy)})
    }
    onClickTab(oVal){
        if(oVal == "1")
        {
            this.setState({
                datagrid:"show-m",
                chartdata:"show-n",
                datagridTab:"activet",
                chartdataTab:"btn-default"
            })
        }
        if(oVal == "2")
        {
            this.setState({
                datagrid:"show-n",
                chartdata:"show-m",
                datagridTab:"btn-default",
                chartdataTab:"activet"
            })
        }
    }
    onChangeFYData(fyChart){
        this.setState({fyChart},()=>{
            var dtype = this.state.chartseries.dtype;
            // console.log("dtype ", dtype)
            if(dtype === "default")
            {
                
                // console.log("fyChart ", this.state.fyChart)
                var chartseries = this.state.chartseries.allfymonthwise.data;
                var selectedfymonths = [];
                if(this.state.fyChart.length > 0)
                {
                    this.state.fyChart.map((dt) => {
                        chartseries.filter((f) => {
                            if(dt.value === f.name)
                            {
                                selectedfymonths.push(f);
                            }
                        });
                    })
                }
                // var selectedfymonths = chartseries.filter((f) => this.state.fyChart.value === f.name);
                var newchartseries = this.state.chartseries;
                newchartseries["selectedfymonths"] = selectedfymonths
                // console.log('selectedfymonths ', selectedfymonths)
                this.setState({
                    chartseries:newchartseries
                })
            }
            else{
                // console.log("fyChart ", this.state.fyChart)
                var chartseries = this.state.chartseries.allfymonthwise.data;
                var selectedfymonths = [];
                if(this.state.fyChart.length > 0)
                {
                    this.state.fyChart.map((dt) => {
                        chartseries.filter((f) => {
                            if(dt.value === f.name)
                            {
                                selectedfymonths.push(f);
                            }
                        });
                    })
                }
                // var selectedfymonths = chartseries.filter((f) => this.state.fyChart.value === f.name);
                var newchartseries = this.state.chartseries;
                newchartseries["selectedfymonths"] = selectedfymonths
                // console.log('selectedfymonths ', selectedfymonths)
                this.setState({
                    chartseries:newchartseries
                })
            }
        })
    }
    onSelectFYData(selectFY){
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
            overlayBlock:"show-m",
        })
        this.setState({selectFY},()=>{
            this.loadInputPlanData()
        })
    }
    onChangeItemCode(itemCd){
        this.setState({itemCd})
    }
    onClickGetItemData(){
        
        var chartseries = this.state.chartseries;
        var newchartseries = this.state.chartseries;
        if(this.state.itemCd.value === "")
        {
           
            
            newchartseries["dtype"] = "default"
            newchartseries["overallfinancialorders"] =chartseries.all_overallfinancialorders;
            newchartseries["overallfinancialcost"] = chartseries.all_overallfinancialcost;
            newchartseries["allfymonthwise"] = chartseries.all_allfymonthwise;
            newchartseries["allfymonthwiseCost"] = chartseries.all_allfymonthwiseCost;
            newchartseries["selectedfymonths"] = chartseries.all_selectedfymonths;
            newchartseries["selectedfymonthsCost"] = chartseries.all_selectedfymonthsCost;
        }
        else{
            var itemcds = [this.state.itemCd];
            // console.log("chartseries ", chartseries)
            chartseries["dtype"] = "item"
            var chartseries = this.state.chartseries.itemoverallorders.data;
            var chartseriescost = this.state.chartseries.itemoverallcost.data;
            var chartseriesmonths= [];
            try {
                if(this.state.chartseries.itemallfymonthwise.data.length > 0)
                {
                    var chartseriesmon = this.state.chartseries.itemallfymonthwise.data;
                    // console.log('chartseriesmon , ',chartseriesmon)
                    var itmdata = groupBy(chartseriesmon, rdata => rdata.itemname);
                    // console.log("itmdata ", itmdata)
                    itmdata.forEach((vals,keys)=>{
                        if(this.state.itemCd.value === keys)
                        {
                            if(vals.length > 0)
                            {
                                var itmdatayrs = groupBy(vals, rdata => rdata.name);
                                itmdatayrs.forEach((vlasd,mk) => {
                                    if(vlasd.length > 0)
                                    {
                                        if(this.state.fyChart.length > 0)
                                        {
                                            this.state.fyChart.map((dt) => {
                                                vlasd.filter((f) => {
                                                    if(dt.value === f.name && f.itemname === this.state.itemCd.value)
                                                    {
                                                        chartseriesmonths.push(f);
                                                    }
                                                });
                                            })
                                        }
                                    }
                                    
                                })
                                
                            }
                        }
                    })
                }
                else{

                }    
            } catch (error) {
                
            }
            
            var itemoverallorders = [];
            var itemoverallcostfy = [];
            var selectedfymonths = [];
            if(itemcds.length > 0)
            {
                itemcds.map((dt) => {
                    chartseries.filter((f) => {
                        if(dt.value === f.itemname)
                        {
                            itemoverallorders.push(f);
                        }
                    });
                    chartseriescost.filter((f) => {
                        if(dt.value === f.itemname)
                        {
                            itemoverallcostfy.push(f);
                        }
                    });
                    chartseriesmonths.filter((f) => {
                        if(dt.value === f.itemname)
                        {
                            selectedfymonths.push(f);
                        }
                    });

                    
                })
            }
            // console.log("selectedfymonths ",selectedfymonths)
            // var selectedfymonths = chartseriesmonths.filter((f) => this.state.fyChart.value === f.name);
            // var newchartseries = this.state.chartseries;
            

            newchartseries["overallfinancialorders"] = {name:"Orders",colorByPoint: true,data:itemoverallorders}
            newchartseries["overallfinancialcost"] = {name:"Cost",colorByPoint: true,data:itemoverallcostfy}
            // chartseries["allfymonthwise"] = {name:"Orders",colorByPoint: true,data:fynonths}
            // chartseries["allfymonthwiseCost"] = {name:"Orders",colorByPoint: true,data:fynonthsCost}
            newchartseries["selectedfymonths"] = selectedfymonths
            // chartseries["selectedfymonthsCost"] = selectedfymonthsCost
            // chartseries["historic"] = historify
        }
        this.setState({
            chartseries:newchartseries
        })
    }

    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    }
    onGridReady1 = (params) => {
		this.gridApiNew = params.api;
		this.gridColumnApiNew = params.columnApi;

    }
    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    }
    onBtExportExcel = () => {
        this.gridApi.exportDataAsExcel();
    }
    onBtnExportF = () => {
        this.gridApiNew.exportDataAsCsv();
    }
    onBtExportExcelF = () => {
        this.gridApiNew.exportDataAsExcel();
    }
    render() {

        var columnwithDefs = [
            
            {
                headerName: "Financial Year",
                field: "fy",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Item Code",
                field: "item_code",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Customer Code",
                field: "customer_code",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            // {
            //     headerName: "NRB Customer Code",
            //     field: "nrb_customer_code_1",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "Apr",
                field: "Apr",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "May",
                field: "May",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jun",
                field: "Jun",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jul",
                field: "Jul",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Aug",
                field: "Aug",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Sep",
                field: "Sep",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Oct",
                field: "Oct",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Nov",
                field: "Nov",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Dec",
                field: "Dec",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jan",
                field: "Jan",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Feb",
                field: "Feb",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Mar",
                field: "Mar",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            }
           
            
        ];

        
        return (
            <div class="container-fluid">
                <div className={"animatenow "+(this.state.showanimate)} style={{textAlign:"center"}}>
                    <span className="pp-h">Processing Plan. Please wait.....</span>
                    <img src={require("../../assets/images/r.gif")} style={{width:"85vh"}} />
                </div>
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            Forecasting - Historic Data
                            <button type="button" className="btn btn-warning float-right font-white" onClick={this.onClickShowUpload}>Upload FY Input Data</button>
                        </h5>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-sm-3">
                        <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Select FY: </label>
                        <div className="col-sm-8">
                            <Select
                                value={this.state.selectFY}
                                options={this.fyitems()}
                                onChange={this.onSelectFYData.bind(this)}
                            />
                        </div>
                    </div>
                </div> */}
                <div className="row mt-20p">
                    <div className="col-md-12 col-sm-12">
                        <ul className="d-tabs">
                            <li onClick={this.onClickTab.bind(this,"1")} className={"lttabs "+(this.state.datagridTab)}>
                                <button type="button" className="btn">Summary Data</button>
                            </li>

                            <li onClick={this.onClickTab.bind(this,"2")} className={"lttabs "+(this.state.chartdataTab)}>
                                <button type="button" className="btn">Data Visualization</button>
                            </li>

                        </ul>
                    </div>
                </div>
                
                {/* <div className={"row "+(this.state.chartdata)}>
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="row">
                                <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Customer: </label>
                                <div className="col-sm-8">
                                    <Select
                                        value={this.state.fyChart}
                                        options={this.fyitems()}
                                        onChange={this.onChangeFYData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="row">
                                <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Material: </label>
                                <div className="col-sm-8">
                                    <Select
                                        value={this.state.fyChart}
                                        options={this.fyitems()}
                                        onChange={this.onChangeFYData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class={"row mb-20p "+(this.state.chartdata)}>
                    <div className={"col-sm-3 mt-20p"} >
                        <Select                        
                            defaultValue={null}
                            value={this.state.itemCd}
                            // isMulti={true}
                            options={this.state.itemCodesList}
                            onChange={this.onChangeItemCode.bind(this)}
                        />
                    </div>
                    <div className={"col-sm-2 mt-20p"} >
                        <button type="button" className="btn btn-danger" onClick={this.onClickGetItemData.bind(this)}>Get Data</button>
                    </div>
                    <div className={"mxheit col-sm-12 mt-20p"} >
                        <div className="card">
                            <div className="card-body">
                                <ColumnChart
                                    title={"Cumulative Order Summary"}
                                    subtitle={""}
                                    series={this.state.chartseries.overallfinancialorders}
                                />
                            
                            </div>
                        </div>
                    </div>
                    <div className={"mxheit col-sm-12 mt-20p"} >
                        <div className="card">
                            <div className="card-body">
                                <ColumnChart
                                    title={"Cumulative Price Summary"}
                                    subtitle={""}
                                    series={this.state.chartseries.overallfinancialcost}
                                />
                               
                            </div>
                        </div>
                    </div>
                    <div className={"mxheit col-sm-12 mt-20p"}>
                        <div className="card">
                            <div className="card-header">
                                <div style={{width:"100%",zIndex:"9"}}>
                                    <div className="row">
                                        <label className="col-sm-2 mt-10p" style={{color:"#000"}}>Select FY: </label>
                                        <div className="col-sm-10">
                                            <Select
                                                value={this.state.fyChart}
                                                isMulti={true}
                                                options={this.fyitems()}
                                                onChange={this.onChangeFYData.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                
                                    <PolarChart
                                        title={"Monthly Order Trend"}
                                        subtitle={""}
                                        series={this.state.chartseries.selectedfymonths}
                                    />
                               
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className={"mxheit col-sm-6 mt-20p"}>
                        <div className="card">
                            <div className="card-body">
                                <div style={{position:"absolute",right:"10px", top:"10px",width:"30%",zIndex:"9"}}>
                                    <div className="row">
                                        <label className="col-sm-4 mt-10p" style={{color:"#000"}}>Select FY: </label>
                                        <div className="col-sm-8">
                                            <Select
                                                value={this.state.fyChart}
                                                options={this.fyitems()}
                                                onChange={this.onChangeFYData.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <AreaRangeChart
                                    title={"Monthly Costs FY "+(this.state.fyChart.value)}
                                    series={this.state.chartseries.historic}
                                    predictseries={this.state.chartseries.selectedfymonths}
                                />
                            </div>
                        </div>
                    </div> */}
                    <br /><br />
                </div>
                <div class={"row mb-20p "+(this.state.datagrid)}>
                    <div className="row">
                        <div className="col-sm-12">
                            <button type="button" className="btn btn-default float-right" onClick={this.onBtnExport.bind(this)}>Export to CSV</button>
                            <button type="button" className="btn btn-default float-right" onClick={this.onBtExportExcel.bind(this)}>Export to Excel</button>
                        </div>
                    </div>
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                // masterDetail={true}
                                // detailCellRendererParams={this.state.detailCellRendererParams}
									
                                />
                        </div>
                    </div>
                    
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">Upload Input File</h5>
                    <div className="row">
                        
                        <div className="col-sm-12">
                            {this.state.processErrShow == 0 ?
                            <form onSubmit={this.uploadBulkFormHandler.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Select FY</label> 
                                        <Select
                                            value={this.state.fy}
                                            options={this.fyitems()}
                                            onChange={this.onChangeFY.bind(this)}
                                        />
                                    </div> 
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label style={{color:"#000"}}>Upload File</label> 
                                        <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
                                    </div>                        
                                    
                                    <div className="form-group col-sm-12 mb-20p">
                                        <button type="submit" className="btn btn-info">SAVE</button>
                                        <button type="button" className="btn btn-danger" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                            :
                            ""
                            }
                        </div>
                    </div>
                </div>
                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                <div className={"loader "+this.state.loadshow}></div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>
            </div>
        )
    }
}
function arr_diff (a1, a2) {

    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
}
function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  } 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}