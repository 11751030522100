
import React, { Component } from 'react';

 
const DeletetxtButton = (props) => {
    return (
        <div style={{"textAlign":"center"}}>           
            Deleted            
        </div>
    );
};

export default DeletetxtButton;
