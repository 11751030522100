import React, {Component} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

export default class Logout extends Component {
	constructor(props) {
        super(props);
        if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null)
		{
            localStorage.removeItem('token');
            localStorage.removeItem('userid');
            localStorage.removeItem('dept_code');
            localStorage.removeItem('firstname');
            localStorage.removeItem('lastname');
            localStorage.removeItem('email');
            localStorage.clear()
            //localStorage.setItem('menuText',[]);
            delete axios.defaults.headers.common['Authorization'];
            // Remove the "token" cookie
            Cookies.remove("token");
            Cookies.remove("username");
            this.setState({
                redirectToReferrer:''
            });
            console.log("logout successfully",localStorage.getItem('token'));
            window.location.reload();
        }
        else
        {
            return window.location.href="/";
        }        
    }
}