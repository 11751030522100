import React from 'react'
import { Navigate } from 'react-router-dom'
function getUserLocationAccess (){
    let storedAccess  = localStorage.getItem('location_access')
    if (storedAccess ){
        const accessArray = JSON.parse(storedAccess );
        return accessArray.includes('LK1SLK02') ? ['LK1SLK02'] : [];
    }
    return []
}
const ProtectedRoute = ({ element: Component, allowedForLocation, ...rest }) => {
    const userLocationAccess  = getUserLocationAccess()
    
    const hasAccess = allowedForLocation.some(code => userLocationAccess.includes(code))
    return hasAccess ? <Component {...rest} /> : <Navigate to="/unauthorized" />
}
export default ProtectedRoute
