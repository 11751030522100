import React,{Component} from 'react';
import redirectURL from '../redirectURL';
class Questionnaire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consignmentWeightInfo: '',
            fuelTypeInfo: '',
            fleetOwnership: '',
            fuelConsumptionOwned: '',
            fuelConsumptionInfoMethod: '',
            payloadMileageOwned: '',
            fuelConsumptionThird: '',
            thirdPartyFuelInfoMethod: '',
            payloadMileageThird: '',
            output: ''
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
        // console.log(name, value);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        var fleetOwnership = this.state.fleetOwnership.toLowerCase()
        if(fleetOwnership=='third party logistics'){
            fleetOwnership = 'third_party'
        }
        var fuelOwned = this.state.fuelConsumptionOwned === "Yes" ? 1 : 0;
        var fuelThird = this.state.fuelConsumptionThird === "Yes" ? 1 : 0;
        var payloadOwned = this.state.payloadMileageOwned === "Yes" ? 1 : 0;
        var payloadThird = this.state.payloadMileageThird === "Yes" ? 1 : 0;
        var params = {
            fleetOwnership: fleetOwnership,
            fuelConsumptionOwned: fuelOwned,
            payloadMileageOwned: payloadOwned,
            fuelConsumptionThird: fuelThird,
            payloadMileageThird: payloadThird,
        }
        // console.log('hit here')
        redirectURL.post('master/saveQuestionDetails',params)
        .then(response =>{
            // console.log(response,'reson')
            if(response.status == 200){
                // console.log(response.data,"bnm")
                this.setState({
                    output: response.data.message
                })
            }
        })
    }

    renderQuestion = (questionText, options, stateVariable) => (
        <div className='mt-3' style={{color: "#094154", fontSize: "14px",
            borderBottom: "1px dashed #ccc"}}>
            <p className='ml-3'>{questionText}</p>
            {options.map(option => (
                <label key={option}>
                    <input
                        className='ml-3 mr-2'
                        type="radio"
                        name={stateVariable}
                        value={option}
                        checked={this.state[stateVariable] === option}
                        onChange={this.handleInputChange}
                    />
                    {option}
                </label>
            ))}
        </div>
    );

    render() {
        const {
            consignmentWeightInfo,
            fuelTypeInfo,
            fleetOwnership,
            fuelConsumptionOwned,
            fuelConsumptionInfoMethod,
            payloadMileageOwned,
            fuelConsumptionThird,
            thirdPartyFuelInfoMethod,
            payloadMileageThird,
        } = this.state;

        return (
            <div className='container'>
                <div className='row'>
                    <form className='col-sm-12' style={{background: "#fff"}} onSubmit={this.handleSubmit}>
                        <div className="row d-flex justify-content-between align-items-center">
                        <h1 className="p-2" style={{ fontSize: "18px", color: "#0f3c4c", fontWeight: "bold" }}>
                        Questionnaire
                        </h1>
                        <span className="mt-2 mr-3" style={{ fontSize: "15px", fontWeight: "bold" }}>
                        {this.state.output}
                        </span>
                        </div>
                        <div className='ml-3 mt-2'>
                        <h2 style={{fontSize: "16px", fontWeight: "bold"}}>Consignment Weight Related Information</h2>
                        {this.renderQuestion("How will you provide information on the consignment weight carried per trip ?", ["Bulk Upload", "API"], "consignmentWeightInfo")}

                        <h2 style={{fontSize: "16px", fontWeight: "bold"}}>Fuel Related Information</h2>
                        {this.renderQuestion("How will you provide information on fuel type used in your fleet for operational vehicles?", ["API", "Vehicle Master"], "fuelTypeInfo")}

                        <h2 style={{fontSize: "16px", fontWeight: "bold"}}>Fleet Operation Related Information</h2>
                        {this.renderQuestion("What type of fleet is deployed in your operations?", ["Owned", "Third party logistics", "Mixed"], "fleetOwnership")}

                        {fleetOwnership === 'Owned' && this.renderQuestion("Do you have information on the fuel consumption of your vehicle per trip?", ["Yes", "No"], "fuelConsumptionOwned")}
                        {fleetOwnership === 'Owned' && fuelConsumptionOwned === 'Yes' && this.renderQuestion("How would you like to provide information on fuel consumption?", ["Bulk Upload", "API"], "fuelConsumptionInfoMethod")}
                        {fleetOwnership === 'Owned' && fuelConsumptionOwned === 'No' && this.renderQuestion("Do you have information on average payload and mileage of different vehicle types?", ["Yes", "No"], "payloadMileageOwned")}

                        {fleetOwnership === 'Third party logistics' && this.renderQuestion("Do you have information regarding the fuel consumption for 3PL vehicles?", ["Yes", "No"], "fuelConsumptionThird")}
                        {fleetOwnership === 'Third party logistics' && fuelConsumptionThird === 'Yes' && this.renderQuestion("How would you prefer to share information on fuel consumption for 3PL vehicles?", ["Bulk Upload", "API"], "thirdPartyFuelInfoMethod")}
                        {fleetOwnership === 'Third party logistics' && fuelConsumptionThird === 'No' && this.renderQuestion("Do you have information on the average payload & mileage for 3PL vehicle types?", ["Yes", "No"], "payloadMileageThird")}

                        {fleetOwnership === 'Mixed' && (
                            <>
                                <h2 style={{fontSize: "16px", fontWeight: "bold"}}>Owned</h2>
                                {this.renderQuestion("Do you have information on the fuel consumption of your vehicle per trip?", ["Yes", "No"], "fuelConsumptionOwned")}
                                {fuelConsumptionOwned === 'Yes' && this.renderQuestion("How would you like to provide information on fuel consumption?", ["Bulk Upload", "API"], "fuelConsumptionInfoMethod")}
                                {fuelConsumptionOwned === 'No' && this.renderQuestion("Do you have information on average payload and mileage of different vehicle types?", ["Yes", "No"], "payloadMileageOwned")}

                                <h2 style={{fontSize: "16px", fontWeight: "bold"}}>Third party logistics</h2>
                                {this.renderQuestion("Do you have information regarding the fuel consumption for 3PL vehicles?", ["Yes", "No"], "fuelConsumptionThird")}
                                {fuelConsumptionThird === 'Yes' && this.renderQuestion("How would you prefer to share information on fuel consumption for 3PL vehicles?", ["Bulk Upload", "API"], "thirdPartyFuelInfoMethod")}
                                {fuelConsumptionThird === 'No' && this.renderQuestion("Do you have information on the average payload & mileage for 3PL vehicle types?", ["Yes", "No"], "payloadMileageThird")}
                            </>
                        )}
                        </div>
                        <div className="row ml-3 mb-3 mt-2 d-flex justify-content-between align-items-center">  
                        <button type="submit" className="btn btn-primary mt-2">Submit</button>
                        <span className="mt-2 mr-3" style={{ fontSize: "15px", fontWeight: "bold" }}>
                        {this.state.output}
                        </span>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Questionnaire;
