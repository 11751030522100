
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// var redirectURL = require('../redirectURL');
import redirectURL from '../redirectURL';

 
const DeviationRouteActionConsignment = (props) => {
    // console.log('hit')
    // const handleClick = (e) => {
    //     e.stopPropagation();
    //     props.context.componentParent.onCloseRouteDiv(e);
    //     props.context.componentParent.onCloseUploadDiv(e);
    //     props.context.componentParent.onShowTimelineDiv(props.data);
    // };
    const handleRouteClick = (e) => {
        let logParams = {
            // "location_code" : this.state.location.value,
            // "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Trips",
            "activity" : 'clicked on view route icon in aggrid',
            "event" : "slider with map or pop of notification appeared",
            // "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        e.stopPropagation();
        props.onCloseUploadDiv(e);
        props.onCloseRouteDiv(e);
        props.onShowRouteDiv(props.data);
    };

    return (
        <div className="map-icon-component">
			{/*<button onClick={handleClick} className="custom-btn label label-success" title="View Timeline"><i className="icofont icofont-tree-alt"></i> Timeline </button>
            &nbsp;| &nbsp;
            */}
            <button onClick={handleRouteClick} className="custom-btn label label-success" title="View Route">
                {/* <i className="fa fa-globe f25"></i>  */}
                <img style={{ height: "18px" }} src={require("../../assets/icons/distance.png")} />
                </button>
       
        </div>
    );
};

export default DeviationRouteActionConsignment;
