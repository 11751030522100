import React, { Component } from 'react';

 
const vehicleAssigned = (props) => {
    return (
        <div style={{"textAlign":"center"}}>           
            Vehicle Assigned           
        </div>
    );
};

export default vehicleAssigned;