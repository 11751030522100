/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from "react";
import _ from "lodash";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import Select from "react-select";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/datepicker";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import SndUnloadingColumnChart from "./components/tracking/sndUnloadingColumnChart";
import redirectURL from "./components/redirectURL";
import SweetAlert from "react-bootstrap-sweetalert";
var moment = require("moment");

export default class KPIDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "2023-08-01",
      endDate: moment.parseZone().format("YYYY-MM-DD"),
      series: [],
      typeWiseCategory: [],
      avg_dis_drilldown: {},
      avg_qan_drilldown: {},
      planned_distance: [],
      typeWiseCategory: [],
      planned_quantity: [],
      planned_round_trip_time: [],
      avg_round_trip_drilldown: {},
      planned_plan_time: [],
      avg_plan_time_drilldown: {},
      planned_volume: [],
      avg_volume_drilldown: {},
      planned_weight: [],
      avg_weight_drilldown: {},
      locationNames: [],
      selectedLocation: { value: "2005", label: "IN DC Kolkata" },
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      loadshow: "show-m",
    };
    this.previousEndDate = this.state.endDate;
    this.previousStartDate = this.state.startDate;
    this.previousLocationName = this.state.selectedLocation;
  }

  async componentDidMount() {
    var startDate = "2023-08-01";
    var endDate = moment.parseZone().format("YYYY-MM-DD");
    var reqparams = {
      start_date: startDate,
      end_date: endDate,
      plant_location_code: "2005",
    };
    let url_type = window.location.href;
    // console.log(url_type,"url_type")
    let path_type = url_type.split("?");
    // console.log(path_type,"path_type")
    let data_type;
    let path = path_type[path_type.length - 1];

    // console.log(path,"path")
    if (path == "inbound") {
      data_type = 1;
    } else {
      data_type = 2;
    }
    var params = {
      data_type: data_type,
    };
    await this.boundTypeLocations(params);
    // console.log(this.state.locationNames);
    await this.plotDataToHighChart(reqparams);
  }

  plotDataToHighChart = async (reqparams) => {
    this.setState({ loadshow: "show-m" });
    redirectURL
      .post("/tracking/getkpidetails", { formdata: reqparams })
      .then((response) => {
        // console.log(response.data);
        if (response.data === "no data available") {
          this.setState({
            basicType: "danger",
            show: true,
            basicTitle: "No Data Available",
            startDate: this.previousStartDate,
            endDate: this.previousEndDate,
            selectedLocation: this.previousLocationName,
            loadshow: "show-n",
          });
        } else {
          this.previousEndDate = this.state.endDate;
          this.previousStartDate = this.state.startDate;
          this.previousLocationName = this.state.selectedLocation;
          var typeWiseData = response.data;
          var typeWiseCategory = [];
          var avg_dis_drilldownseries = [];
          var avg_qan_drilldownseries = [];
          var planned_distance = [];
          var planned_quantity = [];
          var avg_round_trip_drilldownseries = [];
          var planned_round_trip_time = [];
          var avg_total_time_drilldownseries = [];
          var planned_plan_time = [];
          var avg_volume_drilldownseries = [];
          var planned_volume = [];
          var avg_weight_drilldownseries = [];
          var planned_weight = [];

          for (var i of Object.keys(typeWiseData)) {
            var each_vec_avg_distance = [];
            var each_vec_avg_quantity = [];
            var each_vec_avg_round_trip = [];
            var each_vec_avg_total_time = [];
            var each_vec_avg_volume = [];
            var each_vec_avg_weight = [];

            planned_distance.push({
              name: i,
              y: parseFloat(typeWiseData[i].avg_planned_distance.toFixed(2)),
              drilldown: i,
            });
            planned_quantity.push({
              name: i,
              y: parseFloat(typeWiseData[i].avg_planned_quantity.toFixed(2)),
              drilldown: i,
            });
            planned_round_trip_time.push({
              name: i,
              y: parseFloat(
                typeWiseData[i].avg_planned_round_trip_time.toFixed(2)
              ),
              drilldown: i,
            });

            planned_plan_time.push({
              name: i,
              y: parseFloat(
                typeWiseData[i].avg_planned_total_plan_time_hrs.toFixed(2)
              ),
              drilldown: i,
            });

            planned_volume.push({
              name: i,
              y: parseFloat(typeWiseData[i].avg_planned_volume.toFixed(2)),
              drilldown: i,
            });

            planned_weight.push({
              name: i,
              y: parseFloat(typeWiseData[i].avg_planned_weight.toFixed(2)),
              drilldown: i,
            });
            typeWiseCategory.push(i);
            var vehicles = typeWiseData[i].vehicles;

            vehicles.map((e) => {
              each_vec_avg_distance.push([
                Object.keys(e)[0],
                parseFloat(
                  e[Object.keys(e)[0]]["avg_planned_distance"].toFixed(2)
                ),
              ]);
              each_vec_avg_quantity.push([
                Object.keys(e)[0],
                parseFloat(
                  e[Object.keys(e)[0]]["avg_planned_quantity"].toFixed(2)
                ),
              ]);

              each_vec_avg_round_trip.push([
                Object.keys(e)[0],
                parseFloat(
                  e[Object.keys(e)[0]].avg_planned_round_trip_time.toFixed(2)
                ),
              ]);

              each_vec_avg_total_time.push([
                Object.keys(e)[0],
                parseFloat(
                  e[Object.keys(e)[0]].avg_planned_total_plan_time_hrs.toFixed(
                    2
                  )
                ),
              ]);

              each_vec_avg_volume.push([
                Object.keys(e)[0],
                parseFloat(e[Object.keys(e)[0]].avg_planned_volume.toFixed(2)),
              ]);

              each_vec_avg_weight.push([
                Object.keys(e)[0],
                parseFloat(e[Object.keys(e)[0]].avg_planned_weight.toFixed(2)),
              ]);
            });

            avg_dis_drilldownseries.push({
              name: i,
              id: i,
              data: each_vec_avg_distance,
            });

            avg_qan_drilldownseries.push({
              name: i,
              id: i,
              data: each_vec_avg_quantity,
            });

            avg_round_trip_drilldownseries.push({
              name: i,
              id: i,
              data: each_vec_avg_round_trip,
            });

            avg_total_time_drilldownseries.push({
              name: i,
              id: i,
              data: each_vec_avg_total_time,
            });

            avg_volume_drilldownseries.push({
              name: i,
              id: i,
              data: each_vec_avg_volume,
            });

            avg_weight_drilldownseries.push({
              name: i,
              id: i,
              data: each_vec_avg_weight,
            });
          }

          // console.log(avg_distance, "avg distance");
          this.setState({
            planned_distance: planned_distance,
            planned_quantity,
            typeWiseCategory,
            avg_dis_drilldown: { series: avg_dis_drilldownseries },
            avg_qan_drilldown: { series: avg_qan_drilldownseries },
            planned_round_trip_time,
            avg_round_trip_drilldown: {
              series: avg_round_trip_drilldownseries,
            },
            planned_plan_time,
            avg_plan_time_drilldown: { series: avg_total_time_drilldownseries },
            planned_volume,
            avg_volume_drilldown: { series: avg_volume_drilldownseries },
            planned_weight,
            avg_weight_drilldown: { series: avg_weight_drilldownseries },
            loadshow: "show-n",
          });
        }
      });
  };
  onSelectLocation(params) {
    // console.log(params);
    this.previousLocationName = this.state.selectedLocation;
    this.setState({
      selectedLocation: params,
    });
  }

  boundTypeLocations = async (param) => {
    let location_access = JSON.parse(localStorage.getItem("location_access"));
    // console.log("Hle");

    let locationNames = this.state.locationNames;
    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        // console.log("suss", response.data);
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  locationNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });
                }
              } else {
                locationNames.push({
                  value: item.location_code,
                  label: item.location_name,
                });
              }
            });

            if (locationNames.length > 0) {
              await this.setState({
                locationNames: locationNames,
                selectedLocation: locationNames[0],
              });
            } else {
              await this.setState({
                locationNames: [{ label: "Please Add Data", value: "no_data" }],
                selectedLocation: [
                  { label: "Please Add Data", value: "no_data" },
                ],
              });
            }
          }
        }
      });
  };
  handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var mm = d.getMonth() + 1;
    if (mm < 10) {
      var month = "0" + mm;
    } else {
      var month = mm;
    }

    var startdate =
      d.getFullYear() +
      "-" +
      month +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
    this.previousStartDate = this.state.startDate;
    this.setState({
      startDate: startdate,
    });
  };
  handlerEndDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var mm = d.getMonth() + 1;
    if (mm < 10) {
      var month = "0" + mm;
    } else {
      var month = mm;
    }

    var endDate =
      d.getFullYear() +
      "-" +
      month +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
    this.previousEndDate = this.state.endDate;
    // console.log("end date ", endDate);
    this.setState({
      endDate: endDate,
    });
  };

  onClickGetData = () => {
    var reqparams = {
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      plant_location_code: this.state.selectedLocation.value,
    };
    // console.log(reqparams, "u have clicked submit");
    if (this.state.startDate >= this.state.endDate) {
      //   console.log("Hit");
      this.setState({
        basicType: "danger",
        show: true,
        basicTitle: "Start Date is Greater or Equal to End Date",
        startDate: this.previousStartDate,
        endDate: this.previousEndDate,
        selectedLocation: this.previousLocationName,
        loadshow: "show-n",
      });
    } else {
      this.plotDataToHighChart(reqparams);
    }
  };
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    return (
      <>
        <div className=" col-xl-12 col-lg-12 mt-35p">
          <div className="form-group col-xl-2 col-lg-2">
            <label>Plant Location Code: </label>
            <Select
              placeholder={"IN DC Kolkata"}
              isMulti={false}
              value={this.state.selectedLocation}
              className="border-radius-0"
              onChange={this.onSelectLocation.bind(this)}
              style={{ borderRadius: "0px" }}
              options={this.state.locationNames}
              required
            />
          </div>
          <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
          ></SweetAlert>

          <div className="form-group col-xl-2 col-lg-2">
            <label>Start Date: </label>
            <Datetime
              value={this.state.startDate}
              disableCloseOnClickOutside={false}
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{
                placeholder: "Start Date",
                name: "startDate",
                autoComplete: "off",
              }}
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChange={this.handlerStartDateTime.bind(this)}
            />
          </div>

          <div className="form-group col-xl-2 col-lg-2">
            <label>End Date: </label>
            <Datetime
              value={this.state.endDate}
              disableCloseOnClickOutside={false}
              closeOnSelect={true}
              timeFormat={false}
              inputProps={{
                placeholder: "Start Date",
                name: "startDate",
                autoComplete: "off",
              }}
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChange={this.handlerEndDateTime.bind(this)}
            />
          </div>
          <div className="form-group col-xl-2 col-lg-2">
            <label>&nbsp;</label>
            <br />
            <button
              type="button"
              className="btn btn-success"
              onClick={this.onClickGetData.bind(this)}
            >
              Submit
            </button>
          </div>

          <div className="col-sm-12">
            <div className="col-sm-6 mt-4" id="avg_plan_dis">
              <SndUnloadingColumnChart
                title={"Vehicle Average Planned Distance"}
                subtitle={""}
                context={this}
                seriesName="Vehicle Types"
                series={this.state.planned_distance}
                categories={this.state.typeWiseCategory}
                yaxistitle="Average Planned Distance (KM) "
                drilldownseries={this.state.avg_dis_drilldown}
              />
            </div>
            <div className="col-sm-6 mt-4" id="avg_plan_quan">
              <SndUnloadingColumnChart
                title={"Vehicle Average Planned Quantity"}
                subtitle={""}
                context={this}
                seriesName="Vehicle Types"
                series={this.state.planned_quantity}
                categories={this.state.typeWiseCategory}
                yaxistitle="Average Planned Quantity (Cases) "
                drilldownseries={this.state.avg_qan_drilldown}
              />
            </div>
            <div className="col-sm-6 mt-4" id="avg_plan_roun_trip">
              <SndUnloadingColumnChart
                title={"Vehicle Average Planned Round Trip Time "}
                subtitle={""}
                context={this}
                seriesName="Vehicle Types"
                series={this.state.planned_round_trip_time}
                categories={this.state.typeWiseCategory}
                yaxistitle="Average Planned Time (Hrs)"
                drilldownseries={this.state.avg_round_trip_drilldown}
              />
            </div>
            <div className="col-sm-6 mt-4" id="avg_plan_total_time">
              <SndUnloadingColumnChart
                title={"Vehicle Average Planned Total Plan Time "}
                subtitle={""}
                context={this}
                seriesName="Vehicle Types"
                series={this.state.planned_plan_time}
                categories={this.state.typeWiseCategory}
                yaxistitle="Average Planned Time (Hrs)"
                drilldownseries={this.state.avg_plan_time_drilldown}
              />
            </div>

            <div className="col-sm-6 mt-4" id="avg_plan_vol">
              <SndUnloadingColumnChart
                title={"Vehicle Average Planned Volume "}
                subtitle={""}
                context={this}
                seriesName="Vehicle Types"
                series={this.state.planned_volume}
                categories={this.state.typeWiseCategory}
                yaxistitle="Average Planned Volume (M3)"
                drilldownseries={this.state.avg_volume_drilldown}
              />
            </div>

            <div className="col-sm-6 mt-4" id="avg_plan_weight">
              <SndUnloadingColumnChart
                title={"Vehicle Average Planned Weight "}
                subtitle={""}
                context={this}
                seriesName="Vehicle Types"
                series={this.state.planned_weight}
                categories={this.state.typeWiseCategory}
                yaxistitle="Average Planned Weight (KG)"
                drilldownseries={this.state.avg_weight_drilldown}
              />
            </div>
          </div>
        </div>
        <div className={"loader " + this.state.loadshow}></div>
      </>
    );
  }
}
