import React, {Component} from 'react';
import axios from 'axios';
import {Navigate} from 'react-router-dom';
import withRouter from "../withRouter";
import $ from "jquery";
import Select from 'react-select';
import Cookies from 'js-cookie';
import { IoMdKey } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import { AiOutlineQuestionCircle } from "react-icons/ai";
class Navbar extends Component {
    constructor(props){
      super(props);
      this.state = {
        dispatchDataTypecheck : 0,
        dispatch_data_type : {"label":"Select Data Type","value":""},
        mouseEnter:'show-n',        
      }
    }
    componentDidMount()
    {
        if(localStorage.getItem("module_type") == 1 && localStorage.getItem("both_data_types_enabled_for_dispatch") == 1)
        {
            var currentUrl = window.location.href;
            if(localStorage.getItem("data_type") == 1)
            {
                var dataType = "Inbound"
            }
            else
            {
                var dataType = "Outbound"
            }
            if(currentUrl.includes("viewdispatchplandetails") == true)
            {
                this.setState({
                    dispatchDataTypecheck : 0
                });
            }
            else
            {
                this.setState({
                    dispatchDataTypecheck : 1,
                    dispatch_data_type : {"label":dataType,"value":localStorage.getItem("data_type")}
                });
            }
        }
    }
    logout = async(e) => {
        // console.log('hit')
            e.preventDefault();
          
            await localStorage.removeItem('token');
            await localStorage.removeItem('email');
            await localStorage.removeItem('user_type');
            await localStorage.removeItem('role');
            await localStorage.removeItem('user_code');
            await localStorage.clear();
            sessionStorage.setItem('ref',null);
            //localStorage.setItem('menuText',[]);
            await delete axios.defaults.headers.common['Authorization'];
            this.setState({
                redirectToReferrer:''
            });
            console.log("logout successfully",localStorage.getItem('token'));
            
            window.location.reload();
            // await relaodpage(loginType);
        
           
        }
    changeDispatchDataType = (event) => {
        if(event.value !=undefined && event.value !="")
        {
            localStorage.setItem("data_type",event.value);
            var url_type = window.location.href
            console.log(url_type,"url_type")
            var path_type = url_type.split('?')
            console.log(path_type,"path_type")
            if(event.value == 1)
            {
                var pathend = "inbound";
            }
            else
            {
                var pathend = "outbound";
            }
            // var path = path_type[0]+"?"+pathend;
            var path = "loadprocessrun?"+pathend;
            window.location.href = path;
        }
    }
    dispathTypeOptns = () =>  {
        var optns = [];
        // optns.push({"label" : "Select Data Type","value" : ""});
        optns.push({"label" : "Inbound","value" : 1});
        optns.push({"label" : "Outbound","value" : 2});
        console.log(optns,"optns")
        return optns;
    }
    handleMouseEnter= async()=>{
        this.setState({mouseEnter:'show-m'})
    }
    handleMouseLeave= async()=>{
        this.setState({mouseEnter:'show-n'})
    }

    render(){
        if(Cookies.get("token") === "" || Cookies.get("token") === undefined || Cookies.get("token") === null)
		{
            console.log(Cookies.get("token"),'cook')
			return <Navigate  to={"/login"} push={true} />
		}
        if(localStorage.getItem("token") === "" || localStorage.getItem("token") === undefined || localStorage.getItem("token") === null)
		{
            console.log(Cookies.get("token"),'cook',localStorage.getItem("token"))
			return <Navigate  to={"/login"} push={true} />
		}
        var moduleTitle = "";
        if(localStorage.getItem("module_type") == 1)
        {
            moduleTitle = "Dispatch Planning";
        }
        if(localStorage.getItem("module_type") == 2)
        {
            moduleTitle = "Stacking";
        }
        if(localStorage.getItem("module_type") == 3)
        {
            moduleTitle = "Production Planning";
        }
        if(localStorage.getItem("module_type") == 4)
        {
            moduleTitle = "Forecasting";
        }
        if(localStorage.getItem("module_type") == 5)
        {
            moduleTitle = "Resource Scheduling";
        }

        var userManualPath = require('../../assets/json/Nestle_Optirun_User_ Manual V0.1.3.pdf');
        return (
            <div className="nav_menu">
                <div className="nav toggle" style={{position:"relative"}}>
                    {localStorage.getItem("user_type") === "SERVICE" ? 
                    <a href={"/trips"} style={{fontSize:"20px"}}>
                        <img src={require("../../assets/images/enmovil.png")} alt="enmovil" style={{width:"170px",height:"40px"}} />
                        <span className="module-header-text fhttle">{moduleTitle}</span>
                    </a>
                    :
                    <a href={"/"} style={{fontSize:"20px"}}>
                        <img src={require("../../assets/images/enmovil.png")} alt="enmovil" style={{width:"170px",height:"40px"}} />
                        <span className="module-header-text fhttle">{moduleTitle}</span>
                    </a>
                    }
                </div>
                <div className="col-sm-4">
                    {/* <h4 className="module-header-text">{moduleTitle}</h4> */}
                </div>
                {this.state.dispatchDataTypecheck == 1 ?
                <div className="col-sm-3">
                    <Select
                        placeholder={"Select"}
                        onChange={this.changeDispatchDataType}
                        name="location"
                        id="selectoptns"
                        className="mt-5p selectoptns"
                        value={this.state.dispatch_data_type}
                        options={this.dispathTypeOptns()} 
                    />
                </div> 
                :""}
                <nav className="nav navbar-nav">
                    <ul className=" navbar-right  mb-0">
                        {/* <li>
                        <ul onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className=" navbar-right mt-0"> */}
                        <div>
                        <li className='tooltip-container float-right'>
                            <a  href = {userManualPath} download={userManualPath} >
                            <AiOutlineQuestionCircle size={22} style={{marginRight:"8px", color:"#6e6c69"}}                            
                            /> 
                            <span className="tooltip-help">Help</span> 
                            </a>                            
                        </li>
                        </div>
                        <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                            <li className="nav-item" style={{paddingLeft: "15px"}}>
                                <a href="/logout"className="user-profile">
                                    {/* <i className='fa fa-sign-out'></i> */}
                                    <MdLogout style={{fontSize: "20px", marginRight: "5px"}}/>
                                </a>
                            </li>
                            

                            {/* 'popup ' */}
                            <li>
                                <div style={{textAlign:"center",alignItems:'center',paddingRight:'20px'}} className={'popup '+ this.state.mouseEnter} >
                                    <ol style={{display:'d-flex',flexDirection:'column'}}>
                                        <li className='m-auto' style={{display: "flex", alignItems: "center"}}>
                                           
                                            <a href="/reset">
                                            <IoMdKey  style={{fontSize: "20px", marginRight: "5px"}}/>
                                            {/* <i className='fa fa-key  fa-rotate-360' style={{fontSize: "20px", marginRight: "5px"}}></i> */}
                                             &nbsp;Change Password</a></li>
                                        <li className='mr-3' style={{display: "flex", alignItems: "center"}}>
                                            
                                            <a href="/logout">
                                            <MdLogout  style={{fontSize: "20px", marginRight: "5px"}}/>
                                            {/* <i className='fa fa-sign-out' style={{fontSize: "20px", marginRight: "5px"}}></i> */}
                                            Logout&nbsp;</a></li>
                                    </ol>
                                </div>
                            </li>
                            
                            
                            <li className="nav-item" style={{paddingLeft: "15px"}}>
                                {(localStorage.getItem("username"))}
                            </li>
                        </div>
                        
                        {/* </ul>
                        </li> */}
                        <li>
                            <img src={require("../../assets/images/nestle.png")} style={{height:"45px", marginTop:"-10px"}} />
                        </li>
                        
                    </ul>
                </nav>
            </div>
        );
    }
}

export default withRouter(Navbar) ;