import React, { Component } from 'react';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
export default class StackedColumnPercentChart extends Component {

    constructor(props) {
        super(props);
    }
    render(){
        // console.log(this.props.series)
        const highchartoptions = {
            chart: {
                type: 'column',
                height:"37%",
            },
            title: {
                text: this.props.title,
                align: 'center',
                style: {
                    color: 'black',
                    fontSize: '10px', 
                    fontWeight: 'bold', 
                    fontFamily: 'sans-serif',              
                }
            },
            xAxis: {
                categories: this.props.categories,
                labels: {
                    style: {
                        fontSize: '10px', 
                        fontWeight: 'normal', // Set the font weight of the x-axis labels
                        fontFamily: 'sans-serif', // Set the font family of the x-axis labels
                    }
                }
            },
            yAxis: {
                tickInterval:25,
                min: 0,
                title: {
                    text: 'Percent'
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                shared: true
            },
            plotOptions: {
                column: {
                    stacking: 'percent',
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.0f}%'
                    }
                }
            },
            series: this.props.series,
            colors: [ "#4dacd1", "#0f6080","#4ca688","#6ae6bc","#517a8a"],
            credits: false,
            legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
                itemStyle: {
                    fontSize: '8px',
                    marginTop:"5px"
                }                      
            },  


        }

        return (
            <HighchartsReact containerProps={{ style: {padding:"5px", margin:"5px", }}} highcharts = {Highcharts} options = {highchartoptions} />
        )

    }
}








