import React, {Component} from 'react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils'; 

import Modal from 'react-responsive-modal';



import $ from 'jquery';

var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var viewMarkersArr=[];
var coords_copy = [];
var geoFencingArea;
var markersArray;
var map;
var infowindows  = []

var activeInfoWindow = null;

var route_deviation_polyline_dict = {1: "route_deviation_polyline_1",2: "route_deviation_polyline_2",3: "route_deviation_polyline_3"}
var pathcolor = ["#157254","blue","red"]
class DrawMapDisptach extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			tolls : [],
			proptruckno:'',
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:false,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: null,
			  
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {

            },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  showMarkers:0,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  },
		  data_type : "",
		  selectedorigin:"",
		  selecteddestination:"",
		  returnTripsDashboardRowData: [],
		  uncheckls:[],
		  viewcount:0,
		  showBackWardMap:1,
		  showOnWardMap:1,
		  mapFor:'',
		  tripsData:{},
		  showPopup: false,
		  popupContent:'',
		};
	}
	componentDidMount(){
	
		// console.log(this.props.tripsData,'jj')
		if(this.props.trip_gps_data !== "" && this.props.trip_gps_data !== undefined)
		{
			var trip_gpsarr = this.props.trip_gps_data;
		}
		else{
			var trip_gpsarr = []
		}
		var uncheckls = [];
		// var colors = ["#012071","#E01146","#054ECF","#AB7502","#4D8E05","#017163","#70CF05","#CFCC05","#7CE011","#11E0A1","#055BCF","#CF0505","#CF8905","#CFCC05","#05CF9B","#05CF0E","#05B3CF", "#6705CF","#B705CF","#CF057F","#CF05BD","#CF0564","#CF0505","#4C7101","#017141","#014F71","#713601","#470171","#710167","#2CA3A5","#A3A52C","#1169E0","#9B11E0","#E011D3","#E0117F"]
		var colors = [
			"#E01146", "#AB7502", "#4D8E05", "#017163", "#70CF05",
			"#055BCF", "#05CF9B", "#05CF0E", "#05B3CF",
			"#6705CF", "#B705CF", "#CF057F", "#CF05BD", "#CF0564",
			"#4C7101", "#017141", "#014F71", "#713601", "#470171",
			"#710167", "#2CA3A5", "#A3A52C", "#1169E0", "#9B11E0",
			"#E011D3", "#E0117F", "#29B791", "#00FF80", "#FF80BF",
			"#FF8080", "#FFBF00", "#80FF00", "#80FFBF", "#80FFFF",
			"#BF80FF", "#FF80FF", "#A0A424", "#37A6A6", "#A36A24",
			"#A6544D", "#802000", "#804040", "#40A080", "#3D9140",
			"#91403D", "#91A03D", "#FFAA80", "#808080", "#FFFF80",
			"#80FFFF"
		  ];
		var sn=0;
		if(trip_gpsarr.length > 0)
		{
			// console.log(trip_gpsarr.length,"trip_gpsarr.length")
			var sqs = groupBy(trip_gpsarr, rdata => rdata.trip_no);
			try {
				sqs.forEach((itm,keys) => {
					uncheckls.push({id:keys,label:itm[0].vehicle_no,isChecked:true, colorCode:colors[sn]})
					sn = sn+1
				})
			} catch (error) {
				
			}
		}
		var tripsInfo={}
		this.props.tripsData.map((item)=>{

			var vfr=(Math.max(
                ((item.total_weight / item.vehicle_max_weight) *
                100
              ).toFixed(2),((item.total_demand / item.vehicle_max_volume) *
              100
            ).toFixed(2)))
			tripsInfo[item.trip_no] = vfr

		})
		this.setState({
			uncheckls:uncheckls,
			viewcount:this.props.viewtype,
			mapFor:this.props.map_for,
			tripsData: tripsInfo
		})
		this.renderMap()
	}
	componentWillReceiveProps(newprops){
		// mapFor:this.newprops.mapFor
		// console.log(newprops.map_for,'hello')
		this.setState({showOnWardMap:1,showBackWardMap:1,mapFor:newprops.map_for})
		if(newprops.trip_gps_data !== "" && newprops.trip_gps_data !== undefined)
		{
			var trip_gpsarr = newprops.trip_gps_data;
		}
		else{
			var trip_gpsarr = []
		}
		var uncheckls = [];
		// var colors = ["#012071","#E01146","#054ECF","#AB7502","#4D8E05","#017163","#70CF05","#CFCC05","#7CE011","#11E0A1","#055BCF","#CF0505","#CF8905","#CFCC05","#05CF9B","#05CF0E","#05B3CF", "#6705CF","#B705CF","#CF057F","#CF05BD","#CF0564","#CF0505","#4C7101","#017141","#014F71","#713601","#470171","#710167","#2CA3A5","#A3A52C","#1169E0","#9B11E0","#E011D3","#E0117F"]
		var colors = [
			"#E01146", "#AB7502", "#4D8E05", "#017163", "#70CF05",
			"#055BCF", "#05CF9B", "#05CF0E", "#05B3CF",
			"#6705CF", "#B705CF", "#CF057F", "#CF05BD", "#CF0564",
			"#4C7101", "#017141", "#014F71", "#713601", "#470171",
			"#710167", "#2CA3A5", "#A3A52C", "#1169E0", "#9B11E0",
			"#E011D3", "#E0117F", "#29B791", "#00FF80", "#FF80BF",
			"#FF8080", "#FFBF00", "#80FF00", "#80FFBF", "#80FFFF",
			"#BF80FF", "#FF80FF", "#A0A424", "#37A6A6", "#A36A24",
			"#A6544D", "#802000", "#804040", "#40A080", "#3D9140",
			"#91403D", "#91A03D", "#FFAA80", "#808080", "#FFFF80",
			"#80FFFF"
		  ];
		var sn=0;
		// console.log(trip_gpsarr,'trip_gpsarr')
		if(trip_gpsarr.length > 0)
		{
			// console.log(trip_gpsarr.length,"trip_gpsarr.length--1")
			var sqs = groupBy(trip_gpsarr, rdata => rdata.trip_no);
	
			try {
				sqs.forEach((itm,keys) => {
					uncheckls.push({id:keys,label:itm[0].vehicle_no,isChecked:true, colorCode:colors[sn]})
					sn = sn+1
				})
			} catch (error) {
				
			}
		}
		this.setState({
			uncheckls:uncheckls
		})

		if(newprops.truckno != this.state.proptruckno)
		{
			// this.renderMap()
			this.setState({
				proptruckno:newprops.truckno
			})
		}
		// if(newprops.selectedorigin != this.state.selectedorigin && newprops.selecteddestination != this.state.selecteddestination)
		// {
			this.renderMap()
		// }

		this.setState({
			viewcount:newprops.viewtype
		})
		
		
	}
    renderMap = () => {   
		
		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	    if(this.state.deptcode == 'LOG-PRT')
	    {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if(this.state.deptcode == 'SNDG')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}

		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	handleCheckboxChange = (event) => {
		const { uncheckls } = this.state;
		// console.log("unchecklsasd ", uncheckls)
		const target = event.target;
		// console.log("target ", target.value)
		const index = uncheckls.findIndex(option => parseInt(option.id) === parseInt(target.value));
		// console.log("index ", index)
		// console.log("uncheckls", uncheckls[index].isChecked)
		uncheckls[index].isChecked = !uncheckls[index].isChecked;
		this.setState({ uncheckls: uncheckls });
		this.renderMap();
	}
	

	initMap = () => {
		
    //console.log("Yes Here")
    //console.log("mapFor ",this.props.mapFor)
    //console.log("mapinfo ",this.props.mapinfo)
		try{
			var currentwindow;
			var dealer='';
			var consigner='';
			this.setState({
				dealerCode:this.props.consigneecoords
			});
			var routeinfo ;
			if(this.props.plant_loc_data !== "" && this.props.plant_loc_data !== undefined)
			{
				var plant_loc_data = this.props.plant_loc_data;
			}
			else{
				var plant_loc_data = []
			}
			
			if(this.props.trip_gps_data !== "" && this.props.trip_gps_data !== undefined)
			{
				var trip_gpsarr = this.props.trip_gps_data;
			}
			else{
				var trip_gpsarr = []
			}
			if(this.props.trip_nodes_data !== "" && this.props.trip_nodes_data !== undefined)
			{
				var trip_nodes_data = this.props.trip_nodes_data;
			}
			else{
				var trip_nodes_data = []
			}
			var trip_gps = [];
			
			if(this.props.palletLoadLocations !== "" && this.props.palletLoadLocations !== undefined)
			{
				var palletLoadLocations = this.props.palletLoadLocations;
			}
			else{
				var palletLoadLocations = [];
			}

			if(plant_loc_data.length > 0)
			{
				var lt=plant_loc_data[0].latitude;
				var ln=plant_loc_data[0].longitude;
			}
			else if(palletLoadLocations.length > 0)
			{
				var lt=palletLoadLocations[0].demand_node_latitude;
				var ln=palletLoadLocations[0].demand_node_longitude;
			}
			else{
				var lt=37.0902;
				var ln=95.7129;
			}
			// console.log("palletLoadLocations ",palletLoadLocations)
			var mapOptions = {
				zoom: 10,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels:true,
				mapTypeControlOptions: {
					mapTypeIds: ['roadmap'],
				},
				center: new window.google.maps.LatLng(lt,ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};
					
			map = new window.google.maps.Map(document.getElementById('map'),
				mapOptions);

				const icons = {
					day2: {
					name: "Day-2",
					icon: require("../../assets/images/day2.png"),
					},
					day3: {
					name: "Day-3",
					icon: require("../../assets/images/day3.png"),
					},
					day4: {
					name: "Day-4",
					icon: require("../../assets/images/day4.png"),
					},
					day5: {
					name: "Day-5",
					icon: require("../../assets/images/day5.png"),
					},
					day6: {
					name: "Day-6",
					icon: require("../../assets/images/day6.png"),
					}
					
				};
				
				var legend = document.createElement("div");
				legend.style.backgroundColor = 'white';
				legend.style.border = '1px solid #ccc';
				if(legend != null){
					legend.innerHTML = '';
					for (var key in icons) {
						var type = icons[key];
						var name = type.name;
						var icon = type.icon;
						var div = document.createElement("span");
						
						div.innerHTML = '<img class="legend-height" src="' + icon + '"> ' + name;
						legend.appendChild(div);
					}
					map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(legend);
				}
				 // Attach an event listener to detect fullscreen changes
				 document.addEventListener('fullscreenchange', () => {
					if (document.fullscreenElement) {
						// Perform actions when the map enters fullscreen mode
						// this.setState({fullScreenView:true})
						// console.log('zoom in')
						var legendDiv = document.getElementById('legend2');
						if(legendDiv != null){
						legendDiv.innerHTML=''}
						var options=this.state.uncheckls
						var legend = document.createElement("div");
						legend.style.backgroundColor = 'white';
						legend.style.border = '1px solid #ccc';
						legend.style.marginRight = '1px'; // Add margin-right of 4px
						if(legend != null){
							legend.innerHTML = '';
							legend.id='legend2'
							options.map((option) => {
								
								if(option.isChecked){
								var labl = document.createElement("label");
								labl.className = 'align-items-center';
								labl.style.margin = '2px';
								labl.style.backgroundColor = option.colorCode;
								labl.style.padding = '5px';
								labl.style.color = '#FFFFFF';
								labl.style.fontSize = '12px';
								labl.style.verticalAlign = 'middle';
								labl.innerHTML += option.label + '-' + option.id;
						
								legend.appendChild(labl);
							}
								// console.log("Label appended to legend:", legend);
							});
							map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(legend);
						}
					} else {
						// console.log('zoom out')
						// Remove the legend div when exiting fullscreen mode
						var legendDiv = document.getElementById('legend2');
						if (legendDiv) {
							legendDiv.parentNode.removeChild(legendDiv);
						}
						// Perform actions when the map exits fullscreen mode
					}
				});
			var bounds = new window.google.maps.LatLngBounds();
			bounds.extend(new window.google.maps.LatLng(lt,ln));
				// Create our info window content
			var currentinfowindow = null;
			
			var arr = trip_gps;
			// console.log("trip_gps ", arr)
			var infowindow = new window.google.maps.InfoWindow();
			var markers= []
			if(arr.length > 0)
			{
				var line=new window.google.maps.Polyline(
				{
					map:map,
					strokeColor: '#157254',
					strokeOpacity: 1.0,
					strokeWeight: 3,
					icons: [{
							icon: {
									path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
									strokeColor:'#ff0000',
									fillColor:'#157254',
									fillOpacity:1,
									strokeWeight: 2
									},
							repeat:'100px',
							path:[]
						}]
					}); 
				for (let i = 0; i < arr.length; i++) {
				
					var path=line.getPath().getArray();
					let latLng=new window.google.maps.LatLng(arr[i].latitude,arr[i].longitude);
					path.push(latLng);
					line.setPath(path);

				}
				
			}
			var uncheckls = this.state.uncheckls;
			// console.log("uncheckls ", uncheckls)
			var unckitems = []
			if(uncheckls.length > 0)
			{
				uncheckls.map((it) => {
					if(it.isChecked === false)
					{
						unckitems.push(it.id)
					}
					
				})
			}
			
			if(trip_gpsarr.length > 0)
			{
				var trip_gpsarrli = []
				var ratio = 1;
				trip_gpsarrli = trip_gpsarr.filter(function (value, index, darr) {
					return (index % ratio == 0);
				} );
				// var colors = ["#012071","#E01146","#054ECF","#AB7502","#4D8E05","#017163","#70CF05","#CFCC05","#7CE011","#11E0A1","#055BCF","#CF0505","#CF8905","#CFCC05","#05CF9B","#05CF0E","#05B3CF", "#6705CF","#B705CF","#CF057F","#CF05BD","#CF0564","#CF0505","#4C7101","#017141","#014F71","#713601","#470171","#710167","#2CA3A5","#A3A52C","#1169E0","#9B11E0","#E011D3","#E0117F"]
				var colors = [
					"#E01146", "#AB7502", "#4D8E05", "#017163", "#70CF05",
					"#055BCF", "#05CF9B", "#05CF0E", "#05B3CF",
					"#6705CF", "#B705CF", "#CF057F", "#CF05BD", "#CF0564",
					"#4C7101", "#017141", "#014F71", "#713601", "#470171",
					"#710167", "#2CA3A5", "#A3A52C", "#1169E0", "#9B11E0",
					"#E011D3", "#E0117F", "#29B791", "#00FF80", "#FF80BF",
					"#FF8080", "#FFBF00", "#80FF00", "#80FFBF", "#80FFFF",
					"#BF80FF", "#FF80FF", "#A0A424", "#37A6A6", "#A36A24",
					"#A6544D", "#802000", "#804040", "#40A080", "#3D9140",
					"#91403D", "#91A03D", "#FFAA80", "#808080", "#FFFF80",
					"#80FFFF"
				];
				var sqs = groupBy(trip_gpsarrli, rdata => rdata.trip_no);
				try {
					var sn = 0;
					
					sqs.forEach((itmlist,keys) => {
						if(unckitems.length > 0)
						{
							// console.log("uncheckls.indexOf(keys) ", unckitems.indexOf(keys))
							if(unckitems.indexOf(keys) >= 0)
							{
								
							}
							else{
								var line=new window.google.maps.Polyline(
									{
										map:map,
										// strokeColor: '#157254',
										strokeColor: colors[sn],
										strokeOpacity: 1.0,
										strokeWeight: 3,
										icons: [{
												icon: {
														path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
														strokeColor:'#ff0000',
														fillColor:'#157254',
														fillOpacity:1,
														strokeWeight: 2
														},
												repeat:'100px',
												path:[]
											}]
										});
									if(itmlist.length > 0)
									{
										for (let i = 0; i < itmlist.length; i++) {
							
											var path=line.getPath().getArray();
											let latLng=new window.google.maps.LatLng(itmlist[i].latitude,itmlist[i].longitude);
											path.push(latLng);
											line.setPath(path);
						
										}
									}
							}
						}
						else{

							// need to validate this peice of code
							let colorLine = [];
							let noColorLine = [];

							if(itmlist.length > 0){
								itmlist.filter(flg =>{
									if(flg.return_route == 1){
										if(this.state.showBackWardMap===1){
										colorLine.push(flg)
										}
									}else{
										if(this.state.showOnWardMap===1){
										noColorLine.push(flg)
										}
									}
								})
							}
							var line=new window.google.maps.Polyline(
							{
								map:map,
								// strokeColor: '#157254',
								strokeColor: colors[sn],
								strokeOpacity: 1.0,
								strokeWeight: 3,
								icons: [{
										icon: {
												path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
												strokeColor:'#ff0000',
												fillColor:'#157254',
												fillOpacity:1,
												strokeWeight: 2
												},
										repeat:'100px',
										path:[]
									}]
								});

								var line1=new window.google.maps.Polyline(
									{
										map:map,
										strokeColor: '#157254',
										// strokeColor: colors[sn],
										strokeOpacity: 1.0,
										strokeWeight: 3,
										icons: [{
												icon: {
														path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
														strokeColor:'#ff0000',
														fillColor:'#157254',
														fillOpacity:1,
														strokeWeight: 2
														},
												repeat:'100px',
												path:[]
											}]
										});

								
							if(colorLine.length > 0)
							{
								for (let i = 0; i < colorLine.length; i++) {
					
									var path=line1.getPath().getArray();
									let latLng=new window.google.maps.LatLng(colorLine[i].latitude,colorLine[i].longitude);
									path.push(latLng);
									line1.setPath(path);
				
								}
							}
							if(noColorLine.length > 0)
							{
								for (let i = 0; i < noColorLine.length; i++) {
					
									var path=line.getPath().getArray();
									let latLng=new window.google.maps.LatLng(noColorLine[i].latitude,noColorLine[i].longitude);
									path.push(latLng);
									line.setPath(path);
				
								}
							}
						}
						sn = sn+1;
					})
				} catch (error) {
					
				}
			}
			var infowindow = new window.google.maps.InfoWindow();
			infowindow.close();
			if(plant_loc_data.length > 0)
			{
				for (var l = 0; l < plant_loc_data.length; l++) {
					// console.log(plant_loc_data[l])
					bounds.extend(new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)));
					var marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)),
						content:plant_loc_data[l],
						title:plant_loc_data[l].location_label,
						icon:require('../../assets/images/business.png'),
						map: map,
					});
					addMarker(new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)),plant_loc_data[l].location_label.toString(),plant_loc_data[l]);
					window.google.maps.event.addListener(marker, 'click', (function(marker) {
						
						// console.log("marker ", marker)
						return function() {
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = marker.content.location_code
						contentarr.push({"key":"Location", "value":marker.content.location_name})
						if(marker.content.distance_from_pickup !=undefined && marker.content.distance_from_pickup !="undefined")
						{
							contentarr.push({"key":"Distance / Time From Pickup Location", "value":marker.content.distance_from_pickup+" Kms / "+marker.content.time_from_pickup+" Mins"})
							contentarr.push({"key":"Distance / Time From Previous Drop Location", "value":marker.content.distance_from_prev_drop+" Kms/"+marker.content.time_from_prev_drop+" Mins"})
						}
						
						var contentString = infoBox(marker.icon, header, contentarr,'')
							
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
												
						// Set the new info window as active
						// activeInfoWindow = infoWindow;
						// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker));
					// window.google.maps.event.addListener(marker, 'mouseout', function() {
					// 	infowindow.close();
					// });
				}

			}
			if(trip_nodes_data.length > 0)
			{
				if(trip_nodes_data.length > 0)
				{
					var sqst = groupBy(trip_nodes_data, rdata => rdata.trip_no);
					try {
						var sna = 0;
						sqst.forEach((values,kys) => {
							if(unckitems.length > 0)
							{
								// console.log("uncheckls.indexOf(keys) ", unckitems.indexOf(keys))
								if(unckitems.indexOf(kys) >= 0)
								{
									
								}
								else{
										
									if(values.length > 0){
										for (var l = 0; l < values.length; l++) {
											if(values[l].location_label.toString() !== undefined)
											{
												var lblText = values[l].location_label.toString();
											}
											else{
												var lblText = "";
											}
											bounds.extend(new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)));
											var icon = {

											path: "M0-10c-9.8 0-17.7 7.8-17.7 8.4 0 7.5 8.7 15.6 8.7 15.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
											fillColor: colors[sna],
											fillOpacity: 1,
											anchor: new window.google.maps.Point(0,0),
											strokeWeight: 0,
											scale: 0.8
										}										  
											var marker = new window.google.maps.Marker({
												position: new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)),
												// icon:require('../../assets/images/business.png'),
												content:values[l],
												icon:icon,
												title:values[l].location_label,
												map: map,
											});
											addMarker(new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)),lblText,values[l], colors[sna]);
											
										
										}
									}
								}
							}
							else{
								if(values.length > 0){
									for (var l = 0; l < values.length; l++) {
										if(values[l].location_label.toString() !== undefined)
										{
											var lblText = values[l].location_label.toString();
										}
										else{
											var lblText = "";
										}
										bounds.extend(new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)));
										var icon = {

											path: "M0-20c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
											fillColor: colors[sna],
											fillOpacity: 1,
											anchor: new window.google.maps.Point(0,0),
											strokeWeight: 0,
											scale: 0.8
										}
										var marker = new window.google.maps.Marker({
											position: new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)),
											icon:icon,
											content:values[l],
											title:values[l].location_label.toString(),
											map: map,
										});
										addMarker(new window.google.maps.LatLng(parseFloat(values[l].latitude), parseFloat(values[l].longitude)),lblText,values[l], colors[sna]);
										
									
									}
								}
							}
							sna = sna+1;
						})
					}
					catch(e){

					}
				}
				// for (var l = 0; l < trip_nodes_data.length; l++) {
				// 	bounds.extend(new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)));
				// 	var marker = new window.google.maps.Marker({
				// 		position: new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)),
				// 		// icon:require('../../assets/images/business.png'),
				// 		content:trip_nodes_data[l],
				// 		title:trip_nodes_data[l].location_label,
				// 		map: map,
				// 	});
				// 	addMarker(new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)),trip_nodes_data[l].location_label,trip_nodes_data[l]);
					
				
				// }

			}
			function addMarkerPlant(location,lbl,item) {
				var marker = new window.google.maps.Marker({
					// color:"#ffffff",
					// fontSize:"20px",
					position: location,
					label:lbl,
					icon: require('../../assets/images/business.png'),
					map: map
				});
				
				markers.push(marker);
				var infowindow = new window.google.maps.InfoWindow({
					maxWidth: 350
				});

				marker.addListener('click', function() {
					infowindow.open(map, marker);
				});
				//oms.addMarker(marker);
			}	
			function addMarker(location,lbl,item,color) {
				if(item.drop_node_total_time_day == 1){
					color = "#00C9FF"
				}else if(item.drop_node_total_time_day == 2){
					color = "#FFD700"
				}else if(item.drop_node_total_time_day == 3){
					color = "#ff7f27"
				}else if(item.drop_node_total_time_day == 4){
					color = "#b83dba"
				}else if(item.drop_node_total_time_day == 5){
					color = "#88001b"
				}
				var icon = {

											path: "M0-20c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
											fillColor: color,
											fillOpacity: 1,
											anchor: new window.google.maps.Point(0,0),
											strokeWeight: 0,
											scale: 0.8
										}
				var marker = new window.google.maps.Marker({
					// color:"#ffffff",
					// fontSize:"20px",
					position: location,
					label:{text:lbl,color:"#ffffff",fontSize:"15px"},
					icon: icon,
					map: map
				});
				
				markers.push(marker);
				var infowindow = new window.google.maps.InfoWindow({
					maxWidth: 350
				});
				// activeInfoWindow = marker;
				marker.addListener('click', function() {
					closeAllInfoWindows()
					var contentarr = []
					var header = item.location_name +" - "+item.location_code+"";
					// contentarr.push({"key":"Location", "value":item.location_name})
					if(item.distance_from_pickup !=undefined && item.distance_from_pickup !="undefined")
					{
						contentarr.push({"key":"Location City", "value":item.location_city})
						contentarr.push({'key':'Planned Day',"value":parseInt(item.drop_node_total_time_day)+1})
						contentarr.push({"key":"Quantity","value":item.quantity })
						contentarr.push({"key":"Demand Volume","value":item.demand_volume})
						contentarr.push({"key":"Demand Weight", "value":item.demand_weight})
						// contentarr.push({"key":"Distance / Time From Pickup Location", "value":item.distance_from_pickup+" Kms / "+item.time_from_pickup+" Mins"})
						// contentarr.push({"key":"Distance / Time From Previous Drop Location", "value":item.distance_from_prev_drop+" Kms/"+item.time_from_prev_drop+" Mins"})
					}
					
					var contentString = infoBox("", header, contentarr,'')
						
					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindows.push(infowindow);
					infowindow.open(map, marker);
				});

				// marker.addListener('mouseout', function() {
					
				// 	infowindow.close();
				// });

			}	

			try {
				// palletLoadLocations
				if(palletLoadLocations.length > 0)
				{
					for (var l = 0; l < palletLoadLocations.length; l++) {
						// console.log(palletLoadLocations[l])
						
						bounds.extend(new window.google.maps.LatLng(parseFloat(palletLoadLocations[l].demand_node_latitude), parseFloat(palletLoadLocations[l].demand_node_longitude)));
						var marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(parseFloat(palletLoadLocations[l].demand_node_latitude), parseFloat(palletLoadLocations[l].demand_node_longitude)),
							content:palletLoadLocations[l],
							title:palletLoadLocations[l].location_label,
							icon:require('../../assets/images/pin.png'),
							map: map,
						});
						
						window.google.maps.event.addListener(marker, 'click', (function(marker) {
							return function() {
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = marker.content.demand_node_location
							contentarr.push({"key":"Location", "value":marker.content.demand_node_location})
							contentarr.push({"key":"Quantity", "value":marker.content.quantity})
							contentarr.push({"key":"Weight", "value":marker.content.demand_weight})
							// contentarr.push({"key":"Vehicle", "value":marker.content.vehicle_no})
							// contentarr.push({"key":"Demand (lbs)", "value":marker.content.demand})
							
							var contentString = infoBox(marker.icon, header, contentarr,'')
								
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker));
					}

				}
			} catch (error) {
				
			}

			// map.fitBounds(bounds)
		}
		catch(e){
			console.log("Error ",e)
		}
	}


	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };
	 
	 showOnWardMap =async(event)=>{
		// console.log('hit')
		if(event.target.value == 0)
		{
			await this.setState({
				showOnWardMap : 1,
			});
			
			
		}
		else
		{
			await this.setState({
				showOnWardMap : 0,
			});
		}
		this.renderMap()
	}

	 showBackWardMap=async(event)=>{
		if(event.target.value == 0)
		{
			await this.setState({
				showBackWardMap : 1,
			});
			
		}
		else
		{
			await this.setState({
				showBackWardMap : 0,
			});
		}
		this.renderMap()
	}
	// Function to toggle the popup state and set its content
	togglePopup = (content,id) => {
		this.setState(prevState => ({
			showPopup: !prevState.showPopup,
			popupContent: content,
			vfrId:id
		}));
	};

render () {
 const modalStyles  = {
              width:'500px !important',
      		}
	  const { open } = this.state;

	var options = this.state.uncheckls;

    return (
           
			<div className="col-xl-12 col-md-12">
				{/* {console.log("this.steae.viewcount ", this.state.viewcount)} */}
				{(this.state.mapFor === "viewRoute")?
				<>
				<li className="float-left">
				 		<input type="checkbox" name="viewtimelinemarkers" value={this.state.showOnWardMap} checked={this.state.showOnWardMap===1}  onClick={this.showOnWardMap.bind(this)} /> <span style={{fontSize:"14px"}}>View Onward Route &nbsp;</span>
						
			 	</li>
				<li className="float-left">
				 		<input type="checkbox" name="viewtimelinemarkers" value={this.state.showBackWardMap}  checked={this.state.showBackWardMap===1} onClick={this.showBackWardMap.bind(this)} /> <span style={{fontSize:"14px"}}>View Return Route </span>
						
			 	</li>
				 </>:''}
				
						
				{(this.state.viewcount === 0)?
					(options.map(option => (
						<>
						
						<label className=' align-items-center' style={{margin:"2px","backgroundColor":(option.colorCode),padding:"5px",color:"#FFFFFF",fontSize:'12px',verticalAlign:'middle',borderRadius:'2px'}} key={option.id}  onMouseEnter={() => this.togglePopup(this.state.tripsData[option.id],option.id)} // Show popup on mouse enter
						onMouseLeave={() => this.togglePopup('')} >
							<input
							type="checkbox"
							value={option.id}
							checked={option.isChecked}
							onChange={this.handleCheckboxChange}
							style={{fontSize:'2px',verticalAlign:'middle'}}
							title={this.state.tripsData[option.id]} // Tooltip set to the value of tripsData
							/>{/* Render the popup if showPopup is true  +`-${this.state.tripsData[option.id]}`*/}
							&nbsp;{option.label+"-"+option.id}&nbsp;
							<i
								class="fa fa-info-circle info-icon-map"
								aria-hidden="true"
								data-tooltip={`VFR : ${this.state.tripsData[option.id]}%`}
							></i>
						</label>
							{/* {this.state.showPopup && option.id===this.state.vfrId? <span className="popup-map">VFR:{this.state.popupContent}%</span>:''} */}
						</>
						
						)))
				:""}
				
				
				<div id="map" className="loadSummaryMap" style={{width:'100%',height:"90vh"}}></div>
	
			</div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}   

function intervals(startString, endString) {
    var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
    var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
        result.push(current.format('DD-MM-YYYY HH:mm'));
        current.add(15, 'minutes');
    }

    return result;
}


function secondsToString(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 
function generateRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}



function closeAllInfoWindows() {
    for (var i=0;i<infowindows.length;i++) {
        infowindows[i].close();
    }
  }

export default DrawMapDisptach;