import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class ConfidenceChart extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		// console.log("this.props.series ", this.props.series)
		const highchartoptions = {
            chart: {
                zoomType: 'x',
                panning: true,
                panKey: 'shift'
            },
			title: {
                text: this.props.title,
            },
            subtitle:{
                text:this.props.subtitle
            },
            xAxis: {
                // min: -0.5,
                // max: 5.5
                // type:"category",
                // accessibility: {
                //     rangeDescription: 'Range: Apr 1st 2016 to Mar 31st 2022.'
                // }
                // categories:["2016","2017","2018","2019","2020","2021","2022"]
            },
            
            yAxis: {
                // min: 0
            },
            tooltip: {
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.0f}'
			},
            legend: {
                enabled: true
            },
            plotOptions: {
                series: {
                    dataGrouping:true
                    // data: this.props.confAreaYHat,
                    // pointInterval: 1,
                    // dataGrouping: {
                    //     approximation: 'average',
                    //     enabled: true,
                    //     forced: true,
                    //     // units: [
                    //     //     ['hour', [2]]
                    //     // ]
                    // }
                }
              },
            series: [
                {
                    type: 'areasplinerange',
                   showInLegend: true,
                   visible: false,
                //    pointStart: Date.UTC(2016, 0),
                //     pointIntervalUnit: 'month',
                //     zoneAxis: 'x',
                   color:"#D0E4FF",
                   // enableMouseTracking: true,
                   name:"Confidence Interval",
                    data: this.props.confAreaData,
                    
                    // pointInterval: 2592000000,
                    marker: {
                        radius: 4,
                    
                        enabled: false
                    },
                    tooltip:{
                        enabled:false
                    }
                },
            {
                type: 'spline',
                showInLegend: true,
                name: 'Predicted Trend',
                color:"#260CFF",
                data: this.props.confAreaYHat,
                marker: {
                    enabled: false
                },
                // states: {
                //     hover: {
                //         lineWidth: 0
                //     }
                // },
                // enableMouseTracking: false
            },
            // {
            //     type: 'spline',
            //     showInLegend: true,
            //     color:"#ff0000",
            //     name: 'Predict Points',
            //     data: this.props.confLinePredictTest,
            //     // marker: {
            //     //     enabled: false
            //     // },
            //     // states: {
            //     //     hover: {
            //     //         lineWidth: 0
            //     //     }
            //     // },
            //     // enableMouseTracking: false
            // },
            {
                type: 'scatter',
                showInLegend: true,
                // enableMouseTracking: false,
                color:"#444444",
                name: 'Actual Data',
                data: this.props.confScatter,
                marker: {
                    radius: 3
                }
            }
            
            ],
			credits:false
			};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}












