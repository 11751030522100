import React, {Component} from 'react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils'; 

import Modal from 'react-responsive-modal';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import MarkerClusterer from "@google/markerclusterer";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

var infoBox = require('./google-infowindow');
var moment = require('moment');

var redirectURL = require('../redirectURL');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
var map;
class ConsignmentDrawMap extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:true,
			viewgoogleroutes:true,
			viewkpiview:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			maprowData: null,
			  
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			  customLoadingCellRenderer: CustomLoadingCellRenderer
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  }	
		};
		
	}
	componentDidMount(){
		
		this.renderMap()
	}
	componentWillReceiveProps(){
		this.renderMap()
	}
    renderMap = () => {   
		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	    if(this.state.deptcode == 'LOG-PRT')
	    {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if(this.state.deptcode == 'SNDG')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}

		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	initMap = () => {
		
		//console.log("Yes Here")
		//console.log("mapFor ",this.props.mapFor)
        //console.log("mapinfo ",this.props.mapinfo)
        try{
		var currentwindow;
		var dealer='';
		var consigner='';
		this.setState({
			dealerCode:this.props.consigneecoords
		});
		var arr=[];
		//console.log("dealerCode ",this.state.dealerCode)
    
    	if(this.props.mapFor == 'consignment')
		{
			var jsondata = this.props.rownode;
			var arr = jsondata;
			dealer=this.props.consigneecoords;
			consigner=this.props.consigner_coordinates;
		}

		//console.log("dealer",dealer);
		//console.log("arr",arr);
		if(arr.length > 0 && arr.length != undefined)
		{
        
            var lt=arr[0].latitude;
            var ln=arr[0].longitude;
			
		}
		else{
		
				var lt=21.141058047268885;
				var ln=72.65782317936426;
			
		}
		//console.log("Lt ",lt);
		//console.log("Ln ",ln);
		var routeinfo ;
		if(this.props.mapFor == 'consignment' || this.props.mapFor == 'ticket')
		{
			var routeinfo = jsondata.route_details;
    	
		}
         var mapOptions = {
			zoom: 13,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels:true,
			mapTypeControlOptions: {
				mapTypeIds: ['roadmap'],
			},
			center: new window.google.maps.LatLng(lt,ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};
    	        
		map = new window.google.maps.Map(document.getElementById('map'),
			mapOptions);

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt,ln));
			// Create our info window content
		var currentinfowindow = null;
		
		if(arr.length > 0)
		{

			var allpoints = [];
		
			if(arr.length < 500)
			{
				var ratio = 1;
			}
			else{
				var ratio = 5;
			}
			var arr1=[];
			//console.log("Total ",ratio);
			if(this.state.timelinesmarkers.length > 0)
			{
				arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
					return (index % ratio == 0);
				} );
				
	
				//var arr1 = this.state.timelinesmarkers;
			}
			
			if(this.props.mapFor == 'consignment')
			{
				var markers= []
    		  	for (let i = 0; i < arr.length; i++) {
				  //if(arr[i].speed > 0)
				 // {

                    var markerLatlng = new window.google.maps.LatLng(parseFloat(arr[i].latitude), parseFloat(arr[i].longitude));
                    var image =  require('../../assets/icons/truck_na_24.png');

                    var mark = new window.google.maps.Marker({
                        position: markerLatlng,
                        map: map,
                        title:arr[i].truck_no,
                        icon: image
					});
					markers.push(mark)
                    mark.addListener('click', function() {
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = arr[i].truck_no
						contentarr.push({"key":"Transporter Name", "value":(arr[i].transporter_name != undefined)?arr[i].transporter_name:""})
						contentarr.push({"key":"Address", "value":(arr[i].last_known_address != undefined)?arr[i].last_known_address:""})
						contentarr.push({"key":"Last Moved Time", "value":getDDMMYYYYHHMMSS(arr[i].last_moved_time)})
						contentarr.push({"key":"City/Area", "value":arr[i].area})
						contentarr.push({"key":"State", "value":arr[i].state})

						var contentString = infoBox(mark.icon, header, contentarr,'')
							
						infowindow.setContent(contentString);	
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, mark);
					  });
					
					// window.google.maps.event.addListener(mark, 'click', (function(marker) {
					// 	console.log("mark ",marker)
					// 	return function() {
					// 		//	console.log(clusters[0])
							
					// 	//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
					// 	var contentarr = []
					// 	//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
					// 	var header = arr[i].truck_no
					// 	contentarr.push({"key":"Transporter Name", "value":arr[i].transporter_name})
					// 	contentarr.push({"key":"Address", "value":arr[i].google_address})
					// 	contentarr.push({"key":"Last Moved Time", "value":getDDMMYYYYHHMMSS(arr[i].last_moved_time)})
					// 	contentarr.push({"key":"City/Area", "value":arr[i].area})
					// 	contentarr.push({"key":"State", "value":arr[i].state})

					// 	var contentString = infoBox(marker.icon, header, contentarr,'')
							
					// 	infowindow.setContent(contentString);	
					// 	infowindow.setContent(contentString);
					// 	currentwindow = infowindow;
					// 	infowindow.open(map, marker);
					// 	console.log('{"lat":'+marker.position.latitude() + ',"lng":' +marker.position.longitude() +'}'); 
					// 	}
					// })(marker));

                    mark.setMap(map);
                    
				bounds.extend(new window.google.maps.LatLng(arr[i].latitude,arr[i].longitude));
				//}
    		  }
			  var markerCluster = new MarkerClusterer(map, markers,
				{imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;
                
                if(arr.length == 1)
                {
                    /*DEALER*/
                    //console.log("dealer.length ", dealer.length);
                    if(dealer.length > 0)
                    {
						var markers=[];
                        for(var d=0;d<dealer.length;d++)
                        {
                            //console.log(d+"====="+dealer[d].lat+", "+dealer[d].lng);
                            marker = new window.google.maps.Marker({
                                position: new window.google.maps.LatLng(dealer[d].lat, dealer[d].lng),
                                icon:require('../../assets/icons/customer_pin_38.png'),
                                map: map,	
                            });
							markers.push(marker);
                            
                            var markerLatlng1;
                            markerLatlng1 = new window.google.maps.LatLng(dealer[d].lat,dealer[d].lng);
                            //console.log("markerLatlng", markerLatlng1)
                            var circle = new window.google.maps.Circle({
                                strokeColor: '#71caab',
                                strokeOpacity: 0.7,
                                strokeWeight: 1,
                                fillColor: '#71caab',
                                fillOpacity: 0.25,
                                map: map,
                                center: markerLatlng1,
                                radius: 25000
                            });
						}
						console.log("marker1",markers);
						var markerCluster = new MarkerClusterer(map, markers,
							{imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
                        
                    }
                    
                    /*Consigner*/
                    //console.log("consigner.length ", consigner.length);
                    if(consigner.length > 0)
                    {
						var markers=[];
                        for(var c=0;c<consigner.length;c++)
                        {
                            //console.log(c+"====="+consigner[c].lat+", "+consigner[c].lng);
                            marker = new window.google.maps.Marker({
                                position: new window.google.maps.LatLng(consigner[c].lat, consigner[c].lng),
                                icon:require('../../assets/icons/business.png'),
                                map: map,	
                                title:"Consigner"
                            });
							markers.push(marker);
                            
                            var markerLatlng1;
                            markerLatlng1 = new window.google.maps.LatLng(consigner[c].lat,consigner[c].lng);
                            //console.log("markerLatlng", markerLatlng1)
                            var circle = new window.google.maps.Circle({
                                strokeColor: '#71caab',
                                strokeOpacity: 0.7,
                                strokeWeight: 1,
                                fillColor: '#71caab',
                                fillOpacity: 0.25,
                                map: map,
                                center: markerLatlng1,
                                radius: 25000
                            });
						}
						console.log("marker2",markers);
						var markerCluster = new MarkerClusterer(map, markers,
							{imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
                        
                    }
                }
                else{
                    if(dealer.length > 0)
                    {
						var markers=[];
                        for(var d=0;d<dealer.length;d++)
                        {
                            var plantcoords = JSON.parse(dealer[d].plant_coordinates);
                           // console.log("plantcoords ",plantcoords.lat)
                            //console.log(d+"====="+dealer[d].lat+", "+dealer[d].lng);
                            marker = new window.google.maps.Marker({
                                position: new window.google.maps.LatLng(plantcoords.lat, plantcoords.lng),
                                icon:require('../../assets/icons/business.png'),
                                map: map,	
                                title:"Plant Code: "+dealer[d].plant_code
                            });
							markers.push(marker)
                            
                            var markerLatlng1;
                            markerLatlng1 = new window.google.maps.LatLng(plantcoords.lat,plantcoords.lng);
                            //console.log("markerLatlng", markerLatlng1)
                            var circle = new window.google.maps.Circle({
                                strokeColor: '#71caab',
                                strokeOpacity: 0.7,
                                strokeWeight: 1,
                                fillColor: '#71caab',
                                fillOpacity: 0.25,
                                map: map,
                                center: markerLatlng1,
                                radius: 50000
                            });
						}
						console.log("marker3",markers);
						var markerCluster = new MarkerClusterer(map, markers,
							{imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
                        
                    }
                }
            }
        }
		map.fitBounds(bounds)
    }
    catch(e){
        console.log("Error ",e)
    }
}
popmarker(e)
	{
		console.log("OnClick Table ",e.data)
		var lat = parseFloat(e.data.latitude);
		var lng =parseFloat(e.data.longitude);
		var data =e.data;
		map.setCenter(new window.google.maps.LatLng(lat,lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		//var image =  require('../../assets/icons/truckblue.png');
		var image =  require('../../assets/icons/truck_na_24.png');
		
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title:data.truck_no,
			icon:image
		});


		var contentarr = []
		var header = data.truck_no
		contentarr.push({"key":"Transporter Name", "value":data.transporter_name})
		contentarr.push({"key":"Address", "value":data.last_known_address})
		contentarr.push({"key":"Last Moved Time", "value":getDDMMYYYYHHMMSS(data.last_moved_time)})
		contentarr.push({"key":"City/Area", "value":data.area})
		contentarr.push({"key":"State", "value":data.state})

		var contentString = infoBox(marker.icon, header, contentarr,'')
		/*
		var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Department:" + data.dept_code+"<br />Address:"+data.address;
		var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+truckText+"</td></tr><tr><th>Distance from Geofence: </th><td style='text-align: right'>"+data.distancel
		contentString = contentString + " (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
		contentString = contentString + data.transporter_name + "</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name+"</td></tr><tr><th>Department:</th>";
		contentString = contentString + "<td style='text-align: right'>"+data.dept_code+"</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
			*/	
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});
		
		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function() {
			infowindow.open(map, marker);
		});
		
		marker.setMap(map);
		
		
	}
	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };

	onShowTimelines(){
		this.setState({
			viewtimelinemarkers:!this.state.viewtimelinemarkers
		})
		if(this.state.viewtimelinemarkers == true)
		{			
			var arr=[];
			if(this.props.mapFor == 'consignment')
			{
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if(this.props.mapFor == 'importsconsignment')
			{
				var jsondata = this.props.mapinfo;
				var locations = jsondata.points;
				var arr = jsondata.route;
			}
			if(this.props.mapFor == 'truckgps')
			{
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if(arr.length > 0)
			{

				var allpoints = [];
				

				if(arr.length < 50)
				{
					var ratio = (arr.length/5);
				}
				else if(arr.length > 50 && arr.length <200)
				{
					var ratio = 5;
				}
				else if(arr.length > 200 && arr.length <400)
				{
					var ratio = 20;
				}
				else{
					var ratio = 100;
				}
				
				//console.log("Total ",arr.length);
				var arr1 = arr.filter(function (value, index, arr) {
					//return (index % ratio == 0);
					return index;
				} );
				this.setState({
					timelinesmarkers:arr1
				})
			}
		}
		else{
			this.setState({
				timelinesmarkers:[]
			})
		}
			this.renderMap()
		
	}
	onViewTimelines(e){
		this.setState({
			viewtimelinemarkers:!this.state.viewtimelinemarkers
		})
		//console.log("viewtimelinemarkers ", this.state.viewtimelinemarkers)
	}
	onShowGridPop(e){
		if(this.props.mapFor == 'consignment' || this.props.mapFor == 'ticket')
		{
			this.setState({
				loadshow:'show-m'
			})
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var coordinates = jsondata.coords;
			//console.log("truckno ", this.props.truckno)
			//console.log("coordinates ", coordinates)
			var dataarr=[]
			//console.log("coordinates ", coordinates)
			coordinates.map((item) => {
				dataarr.push({"truck_no":this.props.truckno, "lat":item.lat, "lng":item.lng, "speed":item.speed, "stime":item.stime,"dist_from_prev_point":item.dist_from_prev_point, "received_on":item.received_on, "time_from_prev_point":item.time_from_prev_point})
			});
			this.setState({
				rowData:dataarr,
				open:true,
				loadshow:'show-n'
			})
		}
	}
 

	onShowGoogleRoutes(){
		this.setState({
			viewgoogleroutes:!this.state.viewgoogleroutes
		})
		if(this.state.viewgoogleroutes == true)
		{			
			if(this.props.mapFor == 'consignment')
			{
				try{
					var googleroutes = JSON.parse(this.props.googleroutes);
					//console.log("googleroutes ", googleroutes)
					if(googleroutes == 0)
					{
						this.props.context.showGoogleRouteAlert();
						this.setState({
							viewgoogleroutes:!this.state.viewgoogleroutes
						});
					}
					else{
						this.setState({
							googleroutepath:googleroutes
						})
					}
				}
				catch(e){
					console.log(e)
				}
			
			}
		}
		else{
			this.setState({
				googleroutepath:[],
				viewgoogleroutes:!this.state.viewgoogleroutes
			})
		}
			
		this.renderMap()
	}
	onShowKpiView(){
		this.setState({
			viewkpiview:!this.state.viewkpiview
		})
		if(this.state.viewkpiview == true)
		{			
			console.log("Success ", this.props.rownode)
			var trucks = [];
			if(this.props.rownode.length > 0)
			{
				this.props.rownode.map((truck) =>{
					trucks.push(truck.truck_no);
				})
			}
			console.log("trucks ", trucks)
			var reparams = {
				trucks_list:trucks
			}
			redirectURL.post("/consignments/kpimapview",reparams)
			.then((response) => {
				var data = response.data.message
				console.log("Output ",data)
				
			})
			.catch(function(e){
				console.log("Error ", e)
			})
		}
		else{
			
		}
		//	this.renderMap()
		
	}
    render () {
 
		var mapcolumnwithDefs= [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 120,
			  
			  
			},
			{
				headerName: "Transporter Name",
				field: "transporter_name",
				width: 120,
				
				
			  },
			 
			// {
			// 	headerName: "LSP Name",
			// 	field: "lsp_name",
			// 	width: 120,
				
				
			//   },
			//   {
			// 	  headerName: "Actual LSP Name",
			// 	  field: "actual_lspuser",
			// 	  width: 120,
				  
				  
			// 	},
				{
					headerName: "Last Packet Time",
					field: "timestamp",
					width: 120,
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.timestamp)
					}
					
				  },
			
				//   {
				// 	headerName: "City",
				// 	field: "city",
				// 	width: 120,
					
					
				//   },
				
				  {
					headerName: "Last City/Area",
					field: "area",
					width: 120,
					
					
				  },
				
				  {
					headerName: "Last State",
					field: "state",
					width: 120,
					
					
				  },
		  ]
        return (
           
			<div className="col-xl-12 col-lg-12 row">
				<div className="col-xl-8 col-lg-8">
					<ul>
						{/* <li className="float-left">
							<input type="checkbox" name="viewtimelinemarkers" value="1" onClick={this.onShowKpiView.bind(this)} /> <span style={{fontSize:"14px"}}>View Markers</span>
						</li> */}
					</ul>
					<div id="map" className="" style={{height:"62vh"}}></div>
				</div>
				<div className="col-xl-4 col-lg-4">
					<div id="myGrid1" style={{ height: "480px"}}  className="ag-theme-balham">
						<AgGridReact
						modules={this.state.modules}
						columnDefs={mapcolumnwithDefs}
						defaultColDef={this.state.defaultColDef}
						rowData={this.props.griddata}
						enableCharts={true}
						enableRangeSelection={true}
						autoGroupColumnDef={this.state.autoGroupColumnDef}
						onGridReady={this.onGridReady}
						onGridState={this.onGridState}
						statusBar={this.state.statusBar}
						sideBar={this.state.sideBar}
						paginationPageSize={this.state.paginationPageSize}
						pagination={true}
						frameworkComponents={this.state.frameworkComponents}
						detailCellRendererParams={this.state.detailCellRendererParams}
						//editType={this.state.editType}
						stopEditingWhenGridLosesFocus={true}
						//floatingFilter={true}
						enableCellChangeFlash={true}
						suppressCellFlash={true}
						rowClassRules={this.state.rowClassRules}
						//onCellClicked={this.onRowClicked.bind(this)}
						gridOptions={{
							context: { componentParent: this }
						}}
						masterDetail={true}
						onCellClicked={this.popmarker.bind(this)}
						rowSelection={this.state.rowSelection}
						overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
						/>
					</div>
				</div>
			</div>		
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}   

function intervals(startString, endString) {
    var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
    var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
        result.push(current.format('DD-MM-YYYY HH:mm'));
        current.add(15, 'minutes');
    }

    return result;
}


function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


export default ConsignmentDrawMap;