
import React, { Component } from 'react';

 
const PlanFollowedButton = (props) => {
    
    return (
        
        <div>
           
        <button className={'btn btn-info grid-btn'} style={{border:"0px"}}>
            Implement Plan
        </button>
        
    </div>
    );
};

export default PlanFollowedButton;
