import React, { Component } from "react";
import redirectURL from '../redirectURL';
import Select from 'react-select';

import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";

export default class AverageTransitTime extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            locationNames:[],
            plantCode:{label:"All", value:""},
            pre_selectedPlant:"",
            reportType:{label:"All", value:""},
            pre_selected_report_type:"",
            reportTypeOptions:[],
            rowData: '',
            filteredData:"",
            modules: AllModules,
            paginationPageSize: 50,
            loadshow: 'show-n',
            overlayBlock:'show-n',
            defaultColDef : {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true,
                minWidth: 70,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
     }
    }
    componentDidMount = async () => {
        this.setState({
			overlayBlock:'show-m',
            loadshow:"show-m"
        })

        let params = {
            data_type : 2
        }
        this.boundTypeLocations(params)
        this.getTransitTimeData()
    }

    getTransitTimeData = async() => {
        var reportTypes = [] ;    
        var reportTypesList = [{label:'ALL',value:''}];    

        await redirectURL.post('tracking/getAveragetransittimesummary')
            .then((response) => {
                this.setState({ 
                    rowData: response.data,
                    filteredData:response.data                
                })

                response.data.map(each => {
                    // console.log(reportTypes.includes(each.report_type))
                    if (!(reportTypes.includes(each.report_type))){
                        reportTypes.push(each.report_type);
                    }
                });                
            })
        reportTypes.map(each => (
            reportTypesList.push({ label:each, value:each })
        ));
        // console.log(reportTypesList);
        await this.setState({
            reportTypeOptions:reportTypesList,
            loadshow:"show-n",
            overlayBlock:"show-n"
        })
    }

    boundTypeLocations = async(param) => {

        let location_access = JSON.parse(localStorage.getItem("location_access"))

        let locationNames = [];

        if(location_access == null){
            locationNames.push({label:'ALL',value:''})
        }
        
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                        if(location_access != null){
                            if(location_access.includes(item.location_code)){
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })

                                }
                            }else{
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })
                            }
                      })
                      
                      if(locationNames.length>0){
                        await this.setState({
                                    locationNames : locationNames,
                                    location : locationNames[0]
                                })
                    }else{
                        await this.setState({
                            locationNames : [{label:'Please Add Data',value:'no_data'}],
                            location : [{label:'Please Add Data',value:'no_data'}]
                        })
                    }
                  }
              }
          })
          .catch(function (error) {
            var errDetails={
                url:"master/getBoundTypeLocations",
                screen:'Analytics Dashboard',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
      }

      hideSlideBlock = () =>{
		
        this.setState({
			overlayBlock:'show-n',
            loadshow:"show-n"
        })
    }

    onChangePlantCode = (event) => {
      let pre_selected_plant = this.state.plantCode;
        // console.log(event.value, typeof(event.value))
        this.setState({
            plantCode:event,
            pre_selectedPlant:pre_selected_plant
        });
        let rowData = this.state.rowData;
        let reportType = this.state.reportType.value;
        // console.log(reportType,"report")
        let filteredData = [];
        if (event.value === ""){
            if ( reportType === ""){
                this.setState({filteredData : rowData })
            }else {
                rowData.map(each => {
                    if (each.report_type == reportType){
                        filteredData.push(each)
                    }
                });
                this.setState({
                    filteredData
                });
            }
        }
        else{
            if ( reportType === ""){
                rowData.filter(each => {
                    if (parseInt(each.plant_location_code) === parseInt(event.value)){
                        filteredData.push(each)
                    }
                })
                this.setState({
                    filteredData
                })
            }else{
                rowData.filter(each => {
                    if (parseInt(each.plant_location_code) === parseInt(event.value) && each.report_type === reportType){
                        filteredData.push(each)
                    }
                });
                this.setState({
                    filteredData
                })
            }
        }
        // console.log(filteredData)
    }

    onChangeReportType = event => {
        console.log(event.value)
        let pre_selected_report_type = this.state.reportType;
        this.setState({
            reportType:event,
            pre_selected_report_type:pre_selected_report_type
        })
        let rowData = this.state.rowData;
        let plantCode = this.state.plantCode.value
        console.log(plantCode,"plant code")
        let filteredData = [];
        if (event.value === ""){
            if (plantCode === ""){
            this.setState({filteredData:rowData})
            }else{
                rowData.filter(each => {
                    if (parseInt(each.plant_location_code) === parseInt(plantCode)){
                        filteredData.push(each)
                    }
                })
            }
        }else{
            if (plantCode === ""){
                rowData.map(each => {
                    if (each.report_type === event.value){
                        filteredData.push(each)
                    }
                });
                this.setState({
                    filteredData
                })
            }else{
                rowData.filter(each => {
                    if (each.report_type === event.value && parseInt(each.plant_location_code) === parseInt(plantCode)){
                        filteredData.push(each);
                    }
                });
                this.setState({
                    filteredData
                })
            }
        }
    }

    render() {
        var columnDefs = [            
            
            // {
            //     headerName: "Plant Location Code",
            //     field: "plant_location_code",
            //     width: "150",
            // },
            // {
            //     headerName: "Report Type",
            //     field: "report_type",
            //     width: "150",
            // },
            {
                headerName: "Source",
                field: "source",
                width: "100",
            },
            {
                headerName: "Source Name",
                field: "source_name",
                width: "130",
            },
            {
                headerName: "Source City",
                field: "source_city",
                width: "130",
            },
            {
                headerName: "Destination Name",
                field: "destination_name",
                width: "150",
            },
           
            {
                headerName: "Destination City",
                field: "destination_city",
                width: "130",
            },
            {
                headerName: "Destination",
                field: "destination",
                width: "130",
            },
            
            
            {
                headerName: "Actual Transit Time(Mins)",
                field: "actual_transit_time_mins",
                width: "180",
                
            },
            {
                headerName: "Time (In Mins)",
                field: "time_in_mins",
                width: "130",
            },
            {
                headerName: "Time (In Secs)",
                field: "time_in_secs",
                width: "130",
            },
            
        ]
        return (
            <>
                <div className=" row col-xl-12 col-lg-12 pt-2">
                    <div className="form-group col-xl-2 col-lg-2" >
                        <label style={{color:"black"}}>Select Plant: </label>
                        <Select
                            maxHeight={200}
                            placeholder={"All"}
                            closeMenuOnSelect={true}                
                            value={this.state.plantCode}
                            className="border-radius-0"
                            onChange={this.onChangePlantCode}
                            style={{ borderRadius: "0px"}}
                            options={this.state.locationNames}
                            required
                        />
                    </div>
                    <div className="form-group col-xl-2 col-lg-2" >
                        <label style={{color:"black"}}>Select Report type: </label>
                        <Select
                            maxHeight={200}
                            placeholder={"All"}
                            closeMenuOnSelect={true}                
                            value={this.state.reportType}
                            className="border-radius-0"
                            onChange={this.onChangeReportType}
                            style={{ borderRadius: "0px"}}
                            options={this.state.reportTypeOptions}
                            required
                        />
                    </div>
                </div>
                
                <div className="text-black" style={{ color: "black",backgroundColor:"white" }}>
                    <h4 className="text-black pt-2 pl-2 pb-2" style={{ color: "black" }}>Average Transit Time</h4>
                    <div id="myGrid" style={{ width: "100%",height:"500px", padding:"10px" }} className="ag-theme-balham">
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.filteredData}
                            enableCharts={false}
                            // onGridReady={this.onGridReady}
                            // onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus={true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this },
                            }}
                            // components={this.state.components}
                            // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                            enableRangeSelection={true}
                        // onCellEditingStopped={this.onCellEditingCell.bind(this)}
                        // masterDetail={true}
                        // detailCellRendererParams={this.state.detailCellRendererParams}
                        // onCellEditingStopped={this.onCellValueChanged.bind(this)}
                        />
                    </div>
                </div>

                <div
                    className={"overlay-block " + this.state.overlayBlock}
                    onClick={this.hideSlideBlock}
                ></div>
                <div className={"loader " + this.state.loadshow}></div>
            </>
        )
    }
}

