import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const NewColumnChart = ({ data, title, xAxisTitle, yAxisTitle }) => {
    console.log(data, "colChart")
  const chartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: title,
    },
    xAxis: {
      categories: Object.keys(data),
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
    plotOptions: {
        column: {
          colorByPoint: true,
          colors: ["#012071","#E01146","#054ECF","#AB7502","#4D8E05","#017163","#70CF05","#CFCC05","#7CE011","#11E0A1","#055BCF","#CF0505","#CF8905","#CFCC05","#05CF9B","#05CF0E","#05B3CF", "#6705CF","#B705CF","#CF057F","#CF05BD","#CF0564","#CF0505","#4C7101","#017141","#014F71","#713601","#470171","#710167","#2CA3A5","#A3A52C","#1169E0","#9B11E0","#E011D3","#E0117F"],
        },
      },
    series: [
      {
        name: xAxisTitle,
        data: Object.values(data),
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default NewColumnChart;
