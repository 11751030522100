import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import "jquery-ui";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import SweetAlert from "react-bootstrap-sweetalert";
import Constant from "../common/constants";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import { getDDMMMYYYY, getHyphenDDMMMYYYYHHMM, getHyphenMMDDYYYYHHMM } from "../common/utils";
import { verifyext } from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from "../redirectURL";
import GridButton from "./gridButton";
import CSVFileValidator from "csv-file-validator";
import * as XLSX from 'xlsx';

var infoBox = require("../common/google-infowindow");
const scaleanimation = {
  transition: "all 1s easy-out",
};

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows = [];
var markersArraylist = [];
let loc_code = ''

export default class LoadProcessRun extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
      },
      rowData: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 1000,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      showSlideBlockRoute: "sidebarcls",
      showSlideBlockUpload: "sidebarcls",
      showSlideBlockStack: "sidebarcls",
      overlayBlock: "show-n",
      routeData: [],
      rfreshmap: 1,
      deliverpickupslist: [],
      dealers: [],
      plants: [],
      uploadfile: "",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      loadplan: [],
      allLoadPlan: [],
      deliverydates: [],
      deliveryopts: [],
      filterDeliveryDate: { value: "", label: "Select Delivery Date" },
      clusterlist: [],
      filtercluster: { value: "", label: "Select Cluster" },
      vehicletypelist: [],
      filterVehicleType: { value: "", label: "All" },
      mapcol: "show-n",
      gridocl: "col-sm-12",
      filter_date: "",
      vtypediv: "show-n",
      vsupplierdiv: "show-n",
      selectedCluster: "",
      fdealerlist: [],
      filterdealer: { value: "", label: "All" },
      showloader: "show-n",
      mpnode: [],
      mpfor: "",
      clusterpoints: [],
      nmapcluster: "show-n",
      nmap: "show-m",
      scluster: "",
      stackContent: "",
      stackImage: "",
      stacktbl: [],
      stacktblbottom: [],
      stackOriginalData: [],
      showrunprocessbtn: "show-m",
      showanimate: "show-n",
      locationNames: [],
      loadFile: "",
      material_configurations: 0,
      location: { label: "Select Location", value: "" },
      loadshow: "show-m",
      overly: "show-m",
      processErrMsg: "",
      processErrShow: "",
      transactionId: "",
      droppedList: [],
      pickupList: [],
      dropList: [],
      materialList: [],
      boundLocations: [],
      previous_order: [{ label: "Select", value: "" }],
      club_order: "",
      showMoreInfo: false,
      ignoreProcess: false,
      clickOnsave: false,
      clickOnIgnore: false,
      dropLocationCodes: [],
      inputXlFile: false,
      plantCodes: [],
    };
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
  }
  async componentDidMount() {
    let url_type = window.location.href;
    // console.log(url_type,"url_type")
    let path_type = url_type.split("?");
    // console.log(path_type,"path_type")
    let data_type;
    let path = path_type[path_type.length - 1];

    // console.log(path,"path")
    if (path === "inbound") {
      data_type = 1;
    } else {
      data_type = 2;
    }
    await this.setState({ data_type: data_type });
    let toolparameters = {
      data_type: data_type,
    };
    await getToolConfiguration(toolparameters);
    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    var material_configurations = 0;
    if (toolConfig !== undefined && toolConfig !== "") {
      if (toolConfig.length > 0) {
        material_configurations = toolConfig[0].material_configuration;
      }
    }
    this.setState({
      material_configurations: material_configurations,
    });
    let params = {
      data_type: data_type,
    };
    $("#uploadFile").val("");
    var location_access = JSON.parse(localStorage.getItem("location_access"));
    redirectURL
      .post("master/getBoundLocations", params)
      .then(async (response) => {
        let boundLocations = response.data.boundLocations;
        let materialconfigurations = response.data.materialconfigurations;
        if (boundLocations.length > 0) {
          var pickupData = [];
          if (location_access !== null) {
            pickupData = boundLocations.filter(
              (bl) =>
                bl.location_type === 1 &&
                location_access.includes(bl.location_code)
            );
          } else {
            pickupData = boundLocations.filter(
              (bl) => bl.location_type === 1 || bl.location_type === 3
            );
          }
          var pickupList = [];
          pickupData.map((pd) => {
            pickupList.push(pd.location_code);
            return true;
          });
          var dropData = boundLocations.filter(
            (bl) =>
              (bl.location_type === 2 || bl.location_type === 3) &&
              pickupList[0] === bl.drop_corresponding_pickup_location_code
          );
          var dropList = [];
          var dropLocationCodes = [];
          dropData.map((dd) => {
            dropList.push(dd.location_name + "-" + dd.location_code);
            dropLocationCodes.push(dd.location_code);
            return true;
          });
          this.setState({
            pickupList: pickupList,
            dropList: dropList,
            boundLocations: boundLocations,
            dropLocationCodes,
          });
        }

        if (materialconfigurations.length > 0) {
          var materialList = [];
          materialconfigurations.map((pd) => {
            materialList.push(pd.material_code);
            this.setState({
              materialList: materialList,
            });
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getBoundLocations",
          screen: "Run New Plan",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
    
    await this.boundTypeLocations(params);
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      overlayBlock: "show-n",
    });
    // await this.getDispatchPlanData()
  }

  onclickUploadFile=()=>{
    $("#uploadFile").val("");
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Run New Plan",
      activity: "clicked on Upload File Option in the slide",
      event: "File Upload slide opened",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    }
  onClickDownloadSample=()=>{
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'clicked on Download Sample Data Button in the slider',
      "event" : "Sample template has been Downloaded",
      "data_type" : this.state.data_type,
  }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
  }

  // getDispatchPlanData = () =>{
  //     var params = {
  //         data_type : this.state.data_type,
  //         location_code : this.state.location.value,
  //     }
  //     redirectURL.post('dispatch/getDispatchPlanData',params)
  //     .then(response =>{
  //         this.setState({
  //             rowData:response.data,
  //             loadshow:'show-n',
  //             overly:'show-n',
  //             overlayBlock:"show-n"
  //         })
  //     })
  // }

  boundTypeLocations = async (param) => {
    let location_access = JSON.parse(localStorage.getItem("location_access"));

    let locationNames = this.state.locationNames;
    var plantCodes=[]
    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  locationNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });
                }
              } else {
                locationNames.push({
                  value: item.location_code,
                  label: item.location_name,
                });
              }
              plantCodes.push(item.location_code)
            });
            if (locationNames.length > 0) {
              await this.setState({
                locationNames: locationNames,
                location: locationNames[0],
                boundLocations: boundLocations,
                plantCodes
              });
            } else {
              await this.setState({
                locationNames: [{ label: "Please Add Data", value: "no_data" }],
                location: [{ label: "Please Add Data", value: "no_data" }],
                plantCodes
              });
            }
            param.location_code = locationNames[0].value;
            this.getLatestDispatchOrderId(param);
          }
        }
      })
      .catch(function (error) {
        var errDetails={
          url:"master/getBoundTypeLocations",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
    
  };

  closeAlert = () => {
    if (this.state.basicTitle == "File is processing") {
      let url_type = window.location.href;
      let path_type = url_type.split("?");
      let path = path_type[path_type.length - 1];
      window.location.href = "dipatchplanhistory?" + path;
    } else {
      this.setState({
        show: false,
      });
    }
  };

  onClickShowUpload = () => {
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'clicked on Run Plan By File Button',
      "event" : "Slider Opened with a option to upload file",
      "data_type" : this.state.data_type,
  }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    this.setState({
      showSlideBlockUpload: "slide25",
      overlayBlock: "show-m",
    });
  };

  hideSlideBlock = () => {
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'clicked on Cancel Button in the slider',
      "event" : "Slider closes",
      "data_type" : this.state.data_type,
  }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    this.setState({
      overlayBlock: "show-n",
      showSlideBlock: "",
      showSlideBlockUpload: "",
      showSlideBlockRoute: "",
      showSlideBlockStack: "",
      loadFile: "",
      loadshow: "show-n",
      overly: "show-n",
      club_order: [{ label: "Select", value: "" }],
      inputXlFile: false,
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    document.getElementById("inValidDataInfo").innerHTML = "";
  };
  fixProcess = () => {
    this.setState({
      processErrMsg: "",
      processErrShow: 0,
      loadFile: "",clickOnsave:false
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
  };
  ignoreProcessError = () => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var transactionId = this.state.transactionId;
    // console.log("transactionId",transactionId)
    var params = {
      transactionId: transactionId,
      data_type: this.state.data_type,
    };
    
    redirectURL
      .post("dispatch/generateLoadByTransactionId", params)
      .then((response) => {
        //console.log(response.data,"response")
        var statusCode = response.data.status;
        if (statusCode == 0 || statusCode == 1) {
          this.setState({
            basicTitle: response.data.message,
            basicType: "success",
            show: true,
            processErrMsg: "",
            processErrShow: 0,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
            showSlideBlockUpload: "",
          });
        } else {
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        }
        //window.location.reload();
      })
      .catch(function (error) {
        var errDetails={
          url:"dispatch/generateLoadByTransactionId",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
    
  };

  csvFileValidationsForInputFile=(csvFileData)=>{
    var {plantCodes}=this.state
    // console.log('first',csvFileData)
        const config = {
          headers: [
            {
              name: "Pickup Location",
              inputName: "Pickup Location",
              required: true,
              validate: function (code) {
                return isPickUPCodeValid(code);
              },
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
            {
              name: "Drop Location",
              inputName: "Drop Location",
              required: true,
              validate: function(code) {
                return isDropCodeValid(code,plantCodes)
              },
              validateError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
              },
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
            {
              name: "Order Number",
              inputName: "Order Number",
              required: true,
              unique: true,
              uniqueError: function (headerName) {
                return `${headerName} is not unique`;
              },
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
            },
            {
              name: "Invoicing Date",
              inputName: "Invoicing Date",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
              validate: function (date) {
                return isDateFormatValid(date);
              },
              validateError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column should be in M/D/YYYY`;
              },
            },
            {
              name: "Quantity",
              inputName: "Quantity",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
              validate: function (quantity) {
                return parseFloat(quantity)<1000
              },
              validateError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column s/b less than 1000`;
              },
            },
            {
              name: "Demand",
              inputName: "Demand",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
              validate: function (demand) {
                return parseFloat(demand)< 100
              },
              validateError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column s/b less than 100`;
              },
            },
            {
              name: "Weight",
              inputName: "Weight",
              required: true,
              requiredError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
              },
              validate: function (weight) {
                return isValidWeight(weight);
              },
              validateError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column s/b less than 50000`;
              },
            },
            {
              name: "Dealer Available Time (From 00:00)",
              inputName: "Dealer Available Time (From 00:00)",
              required: false,
            },
            {
              name: "Dealer Available Time (To 23:59)",
              inputName: "Dealer Available Time (To 23:59)",
              required: false,
            },
            {
              name: "Delivery Date",
              inputName: "Delivery Date",
              // required: true,
              // requiredError: function (headerName, rowNumber, columnNumber) {
              //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
              // }
              validate: function (date) {
                return isDateFormatValid(date);
              },
              validateError: function (headerName, rowNumber, columnNumber) {
                return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column should be in M/D/YYYY`;
              },
            },
          ],
        };
        // var data =
        CSVFileValidator(csvFileData, config)
          .then((csvData) => {
            //commented as per customer
            var dealers = [];
            const invoicingDateIndex = config.headers.findIndex(
              (header) => header.name === "Invoicing Date"
            );
            const deliveryDateIndex = config.headers.findIndex(
              (header) => header.name === "Delivery Date"
            );
            if (invoicingDateIndex !== -1 && deliveryDateIndex !== -1) {
              // Assuming 'csvData' is an array of objects representing CSV rows
              csvData.data.forEach((row, idx) => {
                dealers.push(row["Drop Location"]);
                const invoicingDate = row["Invoicing Date"];
                const deliveryDate = row["Delivery Date"];
                var parseInvoiceD = moment(invoicingDate, "M/D/YYYY");
                var parseOrderD = moment(deliveryDate, "M/D/YYYY");
                const formattedInVdate =
                  parseInvoiceD.format("M/D/YYYY");
                var formattedOdD = parseOrderD.format("M/D/YYYY");
                const minDate = moment().subtract(30, "days");
                const maxDate = moment().add(30, "days");
                const isInRange = moment(
                  formattedInVdate,
                  "M/D/YYYY"
                ).isBetween(minDate, maxDate, null, "[]");
                const isDeliverRange = moment(
                  formattedOdD,
                  "M/D/YYYY"
                ).isBetween(minDate, maxDate, null, "[]");
                // console.log(isInRange,'dfji')
                if (new Date(formattedInVdate) && new Date(formattedOdD)) {
                  if (new Date(formattedInVdate) > new Date(formattedOdD)) {
                    csvData.inValidData.push({
                      columnIndex: '4,10',
                      message: "Invoice date should be less than delivery date",
                      rowIndex: idx+2,
                    });
                  }
                }
                if (!isInRange) {
                  csvData.inValidData.push({
                    columnIndex: 4,
                    message: "Invoice date is out of range",
                    rowIndex: idx+2,
                  });
                }
                if (!isDeliverRange) {
                  csvData.inValidData.push({
                    columnIndex: 10,
                    message: "Delivery date is out of range",
                    rowIndex: idx+2,
                  });
                }
              });
              // console.log(dealers,'dealers',this.state.dropLocationCodes)
              var check = dealers.some((loc) =>
                this.state.dropLocationCodes.includes(loc)
              );
              if (!check) {
                csvData.inValidData.push({
                  message: "All drop locations are invalid, Please check..!!",
                });
                this.setState({
                  uploadFile: "",
                  show: true,
                  basicType: "danger",
                  basicTitle:
                    "All drop locations are invalid, Please check..!!",
                });
              }
            }
            document.getElementById("inValidDataInfo").innerHTML = "";
            if (csvData.inValidData.length > 0) {
              $("#uploadFile").val("");
              document.getElementById("bulkUploadBtn").type = "button";
              document
                .getElementById("bulkUploadBtn")
                .classList.remove("btn-info");
              document
                .getElementById("bulkUploadBtn")
                .classList.add("btn-secondary");
              let invalidData = csvData.inValidData;
              let element = document.getElementById("inValidDataInfo");
              invalidData.map((item) => {
                let row, column;
                if (item.rowIndex == undefined) {
                  row = "NA";
                } else {
                  row = item.rowIndex;
                }
                if (item.columnIndex == undefined) {
                  column = "NA";
                } else {
                  column = item.columnIndex;
                }

                element.innerHTML +=
                  "Column : " +
                  column +
                  "," +
                  " Row : " +
                  row +
                  "--" +
                  item.message +
                  "<br></br>";
              });
              let throwMsg = 0;
              // console.log(invalidData,'invalid data')
              invalidData.map((itm) => {
                if (itm.columnIndex == 10) {
                  throwMsg = 1;
                }
              });
              // if (throwMsg) {
              //   this.setState({
              //     uploadFile: "",
              //     show: true,
              //     basicType: "danger",
              //     basicTitle:
              //       "Please Input Valid Data and also Ensure Date is in this Format only M/D/YYYY",
              //   });
              // }
            } else {
              document.getElementById("bulkUploadBtn").type = "submit";
              document
                .getElementById("bulkUploadBtn")
                .classList.remove("btn-secondary");
              document
                .getElementById("bulkUploadBtn")
                .classList.add("btn-info");
            }
          })
          .catch((err) => {
            var errDetails = {
              url: "CSVFileValidator",
              err,
              screen: "Run New Plan",
            };
            redirectURL.post("/master/logErrorsForApiCalls", errDetails);
          });
  }

  validateAllValues=(jsonData)=>{
    console.log('first jsondata',jsonData)
    var inValidData=[]
    loc_code = this.state.location.value;
    jsonData.map((rec, idx) => {
      // console.log(rec["Pickup Location"],rec["Pickup Location"] != loc_code)
      if (rec["Pickup Location"] !== loc_code) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 1,
          message: "Pick Location Code is required",
        });
      }
      if (rec["Drop Location"] === "" || rec["Drop Location"] === undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 2,
          message: "Drop Location Code is required",
        });
      }
      if (rec["Order Number"] === "" || rec["Order Number"] === undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 3,
          message: "Order Number  is required",
        });
      }
      // console.log('first incoice',rec['Invoicing Date'],idx)
      if (rec["Invoicing Date"] && rec["Invoicing Date"].includes("-")) {
        if (
          rec["Invoicing Date"] === "" ||
          rec["Invoicing Date"] === undefined
        ) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 4,
            message: "Invoicing Date is required",
          });
        } else if (!isDateFormatValid(rec["Invoicing Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 4,
            message: "Invoicing Date Format is not Valid",
          });
        }
      } else {
        if (
          rec["Invoicing Date"] === "" ||
          rec["Invoicing Date"] === undefined
        ) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 4,
            message: "Invoicing Date is required",
          });
        } else if (!isDateFormatValid(rec["Invoicing Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 4,
            message: "Invoicing Date Format is not Valid",
          });
        }
      }
      if (rec["Quantity"] === "" || rec["Quantity"] === undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 5,
          message: "Quantity  is required",
        });
      }
      if (rec["Demand"] === "" || rec["Demand"] === undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 6,
          message: "Demand  is required",
        });
      }
      if (rec["Weight"] === "" || rec["Weight"] === undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 7,
          message: " Weight is required",
        });
      }
      if (
        rec["Dealer Available Time (From 00:00)"] === "" ||
        rec["Dealer Available Time (From 00:00)"] === undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 8,
          message: "Dealer Available Time is required",
        });
      }
      if (
        rec["Dealer Available Time (To 23:59)"] === "" ||
        rec["Dealer Available Time (To 23:59)"] === undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 9,
          message: "Dealer Available Time (To 23:59)  is required",
        });
      }
      // console.log(!isDateFormatValidforXLSX(rec["Delivery Date"]),'delivery',isDateFormatValid(rec["Delivery Date"]))
      // console.log('first',(rec['Delivery Date']).includes('-'))
      if (rec["Delivery Date"] && rec["Delivery Date"].includes("-")) {
        if (rec["Delivery Date"] === "" || rec["Delivery Date"] === undefined) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 10,
            message: "Delivery Date is required",
          });
        } else if (!isDateFormatValid(rec["Delivery Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 10,
            message: "Delivery Date Format is not Valid",
          });
        }
      } else {
        if (rec["Delivery Date"] === "" || rec["Delivery Date"] === undefined) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 10,
            message: "Delivery Date is required",
          });
        } else if (!isDateFormatValid(rec["Delivery Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 10,
            message: "Delivery Date Format is not Valid",
          });
        }
      }
      return true;
    });
    document.getElementById("inValidDataInfo").innerHTML = "";
    if (inValidData.length > 0) {
      $("#uploadFile").val("");
      document.getElementById("bulkUploadBtn").type = "button";
      document.getElementById("bulkUploadBtn").classList.remove("btn-info");
      document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
      let invalidData = inValidData;
      let element = document.getElementById("inValidDataInfo");
      invalidData.map((item) => {
        let row, column;
        if (item.rowIndex === undefined) {
          row = "NA";
        } else {
          row = item.rowIndex;
        }
        if (item.columnIndex === undefined) {
          column = "NA";
        } else {
          column = item.columnIndex;
        }

        element.innerHTML +=
          "Column : " +
          column +
          "," +
          " Row : " +
          row +
          "--" +
          item.message +
          "<br></br>";
        return true;
      });
      // let throwMsg = 0;
      // invalidData.map((itm) => {
      //   if (itm.columnIndex === 10) {
      //     throwMsg = 1;
      //   }
      // });
      // if (throwMsg) {
      //   this.setState({
      //     uploadFile: "",
      //     show: true,
      //     basicType: "danger",
      //     basicTitle:
      //       "Please Input Valid Data and also Ensure Date is in this Format only M/D/YYYY",
      //   });
      // }
      return false;
    } else {
      document.getElementById("bulkUploadBtn").type = "submit";
      document
        .getElementById("bulkUploadBtn")
        .classList.remove("btn-secondary");
      document.getElementById("bulkUploadBtn").classList.add("btn-info");
      return true;
    }
  }

  changeFileHandler = async (e) => {
    loc_code = this.state.location.value
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'Uploaded the File',
      "event" : "Uploaded the File",
      "data_type" : this.state.data_type,
    }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    this.setState({
      loadFile: e.target.files[0],
    });
    var dropLocationCodes=this.state.dropLocationCodes
    document.getElementById("inValidDataInfo").innerHTML = "";
    var extentions = ["config","exe","js","jsx","svg","JPG","jpg","jpeg","GIF","gif","PNG","png","BMP","bmp","html","xls","doc","docx","ppt","pptx","pdf","pdfx","html","css","sh", "php5", "pht", "phtml", "shtml", "asa", "cer", "asax", "swf","xap","php","htaccess","xml","xds","asp","aspx","java","c","c++","ctl","conf","qewe","encrypted","enc","crypted","locked","crypto","crypt","txt"];
    var check = !extentions.includes(e.target.files[0].name);
    if (check) {
      // console.log("hit");
      if (
        e.target.files[0].type === "" ||
        e.target.files[0].type === "text/csv" ||
        e.target.files[0].type === "application/vnd.ms-excel" ||
        e.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
        if (
          e.target.files[0].type === "" ||
          e.target.files[0].type === "text/csv"
        ) {
          this.setState({inputXlFile:false})
          this.csvFileValidationsForInputFile(e.target.files[0]);
        } else if (
          e.target.files[0].type === "application/vnd.ms-excel" ||
          e.target.files[0].type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const file = e.target.files[0];
          const reader = new FileReader();
          var jsondata = [];
          reader.onload = async (evt) => {
            const data = evt.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet, { raw: false });
            // console.log(jsonData, "json data");
            // jsonData.map((item)=>{
            var result =await this.validateAllValues(jsonData);
            // })
            // Convert JSON data to CSV format with custom date formatting
            var csvData = jsonData.reduce((csv, row, index) => {
              if (index === 0) {
                // Add header row
                csv += Object.keys(row).join(",") + "\n";
              }
              // Format the invoicing_date field to mm-dd-yyyy
              if (row["Invoicing Date"]) {
                const dateParts = row["Invoicing Date"].split("/");
                if (dateParts.length === 3) {
                  const month = dateParts[0].padStart(2, "0");
                  const day = dateParts[1].padStart(2, "0");
                  const year = dateParts[2];
                  row["Invoicing Date"] = `${month}/${day}/${year}`;
                }
              }
              // console.log('first', row["Delivery Date"]);
              if (row["Delivery Date"]) {
                const dateParts = row["Delivery Date"].split("/");
                if (dateParts.length === 3) {
                  const month = dateParts[0].padStart(2, "0");
                  const day = dateParts[1].padStart(2, "0");
                  const year = dateParts[2];
                  row["Delivery Date"] = `${month}/${day}/${year}`;
                }
              }
              // Add row data
              csv +=
                Object.values(row)
                  .map((value) => {
                    // Check if the value is a number and convert it to a number type
                    if (!isNaN(value)) {
                      if (value.includes(".")) {
                        return parseFloat(value);
                      } else {
                        return parseInt(value, 10);
                      }
                    }
                    return `"${value}"`;
                  })
                  .join(",") + "\n";
              return csv;
            }, "");
            // console.log(csvData,'csv')
            await this.setState({
              loadFile: csvData,
              inputXlFile: true,
            });
            if (result) {
              // console.log('first hit after vcalidation',csvData)
              this.csvFileValidationsForInputFile(csvData);
            }
          };
          reader.readAsBinaryString(file);
        }
      } else {
        e.target.value = null;
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle: "Please upload file having extensions .csv or .xlsx only.",
        });
      }
    } else {
      e.target.value = null;
      this.setState({
        uploadFile: "",
        show: true,
        basicType: "danger",
        basicTitle: "Please upload file having extensions .csv or .xlsx only.",
      });
    }
  };

  uploadBulkFormHandler=async(event)=> {
    
    let ignoreProcess = this.state.ignoreProcess;
    var logParams = {
      "location_code" : this.state.location.value,
      "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Summary Dashboard",
      "activity" : 'clicked on SAVE Button in the slider',
      "event" : "navigated to run plan page",
      "data_type" : this.state.data_type,
  }
  redirectURL.post("/master/loguserUpdatesorChanges",logParams)

  if(ignoreProcess == false){
    event.preventDefault();
  }
    
    
    let saveData = new FormData();
    // var cnf = window.confirm("Are you sure you want to proceed ?");
    if (this.state.clickOnsave !== true) {
      await this.setState({clickOnsave: true})
      if (this.state.inputXlFile) {
        const csvBlob = new Blob([this.state.loadFile], { type: "text/csv" });
        // console.log('hello',this.state.loadFile)
        saveData.append("loadFile", csvBlob, "input.csv");
      } else {
        saveData.append("loadFile", this.state.loadFile);
      }
      var notify_email = $("#notify_email").val();
      saveData.append("notify_email", notify_email);
      saveData.append("module_type", 1);
      saveData.append("data_type", this.state.data_type);
      saveData.append(
        "material_configurations",
        this.state.material_configurations
      );
      let location = this.state.location;
      saveData.append("location_code", location.value);
      saveData.append("location_name", location.label);
      saveData.append("user_name", localStorage.getItem("username"));
      // saveData.append("email", localStorage.getItem('email'))
      saveData.append("useremail", localStorage.getItem("email"));
      saveData.append("client", localStorage.getItem("client"));
      let old_order_id = this.state.club_order;
      if (old_order_id.value != undefined && old_order_id.value != "") {
        saveData.append("old_order_id", old_order_id.value);
      }
      // console.log("saveData",saveData);
      this.setState({
        loadshow: "show-m",
        overly: "show-m",
        // showSlideBlockUpload: "",
        // overlayBlock: "show-n",
      });
      var logParams = {
        "location_code" : location.value,
        "location_name" : location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Summary Dashboard",
        "activity" : 'Input Data File Upload',
        "event" : "Run New Plan By File",
        "data_type" : this.state.data_type,
      }
     
      redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
      .then(()=>{

      })
      .catch(function (error) {
        var errDetails={
          url:"/master/logUserUpdateOrSaveDataActivities",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
         
    if(ignoreProcess == false){
    redirectURL
      .post("/dispatch/validateInputDealerCode", saveData,)
      .then((response) => {
        var newStatusCode = response.data.status;
        if (newStatusCode == 1) {
          redirectURL
            .post("/dispatch/generateLoad", saveData, )
            .then((response) => {
              var statusCode = response.data.status;
              if (statusCode == 8) {
                this.setState({
                  processErrMsg: response.data.message,
                  processErrShow: 1,
                  transactionId: response.data.transactionId,
                  droppedList: response.data.droppedList,
                  statusCode: statusCode,
                  loadshow: "show-n",
                  overly: "show-n",
                  overlayBlock: "show-n",
                });
              } else if (statusCode > 3 && statusCode != 8) {
                if (statusCode == 11) {
                  var processMsg =
                    "Use the standard input data file template for uploading loads";
                } else {
                  var processMsg = response.data.message;
                }
                this.setState({
                  processErrMsg: response.data.message,
                  processErrShow: 1,
                  transactionId: response.data.transactionId,
                  statusCode: statusCode,
                  loadshow: "show-n",
                  overly: "show-n",
                  overlayBlock: "show-n",
                  inputXlFile: false,
                });
              } else {
                let basestring = response.data.transactionId;
                let encryptedstring = window.btoa(basestring);
                window.location.href = "/plannewdispatch?" + encryptedstring;

                //temp fix location need to be passed
                localStorage.setItem(
                  "runPlanLocation",
                  this.state.location.value
                );
              }
            })
            .catch(function (error) {
              var errDetails = {
                url: "/dispatch/generateLoad",
                screen: "Run New Plan",
                error,
              };
              redirectURL.post("/master/logErrorsForApiCalls", errDetails);
            });
        } else {
          if (newStatusCode == 8) {
            this.setState({
              processErrMsg: response.data.message,
              processErrShow: 1,
              droppedList: response.data.droppedList,
              statusCode: newStatusCode,
              loadshow: "show-n",
              overly: "show-n",
              overlayBlock: "show-n",
            });
          }
        }
      });
    }else{
      redirectURL
      .post("/dispatch/generateLoad", saveData, {
        headers: {
          "content-type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
          "Access-Control-Allow-Headers": "Origin, Content-Type",
        },
      })
      .then((response) => {
        // console.log(response, "responseFromProcess");
        var statusCode = response.data.status;
        if (statusCode == 8) {
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            droppedList: response.data.droppedList,
            statusCode: statusCode,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        } else if (statusCode > 3 && statusCode != 8) {
          if (statusCode == 11) {
            var processMsg =
              "Use the standard input data file template for uploading loads";
          } else {
            var processMsg = response.data.message;
          }
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            statusCode: statusCode,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        } else {
          let basestring = response.data.transactionId;
          let encryptedstring = window.btoa(basestring);
          window.location.href = "/plannewdispatch?" + encryptedstring;

          //temp fix location need to be passed
          localStorage.setItem("runPlanLocation", this.state.location.value);
        }
      })
    }
          
    } 
    // else {
    //   this.setState({
    //     basicTitle: "Please upload the file",
    //     basicType: "warning",
    //     show: true,
    //   });
    // }
  }

  checkTransactionStatus = (transactionId) => {
    var query = {
      transaction_id: transactionId,
    };
   
    redirectURL
      .post("/dispatch/checkTransactionStatus", query)
      .then((response) => {
        // console.log(response, "responseFromcheckTransactionStatus");
        var statusCode = response.data.status;
        if (statusCode == 8) {
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            droppedList: response.data.droppedList,
            statusCode: statusCode,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        } else if (statusCode > 3 && statusCode != 8) {
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            statusCode: statusCode,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        } else if (statusCode == 0) {
          var checkCount = this.state.checkCount;
          var updatedCnt = parseInt(checkCount) + 1;
          this.setState({
            checkCount: updatedCnt,
          });
          // console.log(updatedCnt, "checkCount");
          if (updatedCnt < 3) {
            this.chkStatus(response.data.transactionId);
          } else {
            this.setState({
              basicTitle: "File is processing",
              basicType: "success",
              show: true,
              loadshow: "show-n",
              overly: "show-n",
              overlayBlock: "show-n",
              showSlideBlockUpload: "",
              loadFile: "",
            });
            $("#uploadFile").val("");
            $("#notify_email").val("");
          }
        } else {
          this.setState({
            basicTitle: response.data.message,
            basicType: "success",
            show: true,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
            showSlideBlockUpload: "",
          });
          this.getDispatchPlanData();
        }
      })
      .catch(function (error) {
        var errDetails={
          url:"/dispatch/checkTransactionStatus",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
        
  };

  chkStatus = (transactionId) => {
    this.checkTransactionStatus(transactionId);
  };

  viewPlanDetails = async (propdata) => {
    let data_type = this.state.data_type;
    let location = this.state.location.value;
    let qry = {
      dispatch_planning_type: data_type,
      plant_location_code: location,
    };
    localStorage.setItem("qry", JSON.stringify(qry));

    var data = propdata.data;
    let basestring = data.transaction_id;
    let encryptedstring = window.btoa(basestring);
    window.location.href = "/viewdispatchplandetails?" + encryptedstring;
  };

  locationChanged = async (location) => {
    let boundLocations = this.state.boundLocations;
    var dropData = boundLocations.filter(
      (bl) =>
        (bl.location_type == 2 || bl.location_type == 3) &&
        location.value == bl.drop_corresponding_pickup_location_code
    );
    var dropList = [];
    var dropLocationCodes=[]
    dropData.map((dd) => {
      dropList.push(dd.location_name + "-" + dd.location_code);
      dropLocationCodes.push(dd.location_code)
    });
    // console.log(dropLocationCodes,'dropLocationCodes')
    await this.setState({
      location: location,
      dropList: dropList,
      dropLocationCodes
    });
    let params = {
      location_code: location.value,
    };

    // await this.getDispatchPlanData()
    this.getLatestDispatchOrderId(params);
    await getToolConfiguration({plant_code:location.value})
  };
  addNewRow() {
    // console.log(this.gridApi);
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'clicked on Add Plan Dispatch Data Button',
      "event" : "New Row is added to aggrid",
      "data_type" : this.state.data_type,
  }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    if (this.state.material_configurations == 1) {
      var newRowData = [
        {
          pickup_location_code: "",
          drop_location_code: "",
          material_code: "",
          quantity: "",
        },
      ];
      var transaction = {
        add: newRowData,
        addIndex: -1,
      };
      this.gridApi.updateRowData(transaction);
    } else {
      var newRowData = [
        {
          pickup_location_code: "",
          drop_location_code: "",
          demand_cmt: "",
          weight_tons: "",
          delivery_date: "",
          invoicing_date: "",
          quantity: '',
          order_number: ''

        },
      ];
      var transaction = {
        add: newRowData,
        addIndex: -1,
      };
      this.gridApi.updateRowData(transaction);
    }
  }

  deleteRow = (params) => {
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'clicked on Delete Icon Button',
      "event" : "Row is deleted from the aggrid",
      "data_type" : this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    var deleteTransaction = [params.data];
    this.gridApi.updateRowData({ remove: deleteTransaction });
  };
  onGridReady1 = (params) => {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  };

  onGridState1 = () => {
    this.colState1 = this.gridColumnApi1.getColumnState();
    this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();
    this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
    window.filterState1 = this.gridApi1.getFilterModel();
    this.gridApi1.setFilterModel(window.filterState1);
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };
  onGridState = () => {
    this.colState = this.gridColumnApi.getColumnState();
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
  };
  onClickRunDispatchPlan = () => {
    let logParams = {
      "location_code" : this.state.location.value,
        "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Run New Plan",
      "activity" : 'clicked on Submit & Run Button',
      "event" : "alert is opened with yes or no",
      "data_type" : this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    var inputData = [];
    var orderNo=[]
    var drop_location_code = "";
    var errorField = "";
    var check_for_empty = false;
    var check_for_invalid = false;
    var check_for_valid_loc = false
    var checkOfDupli=false
    var check_for_Invoice_date = false
    var check_for_delivery_date = false
    var dealerAvlChek=false
    if (localStorage.getItem("data_type") == 1) {
      drop_location_code = this.state.location.value;
    }
    var pickup_location_code = "";
    if (localStorage.getItem("data_type") == 2) {
      pickup_location_code = this.state.location.value;
    }
    var dropList = this.state.dropList
    this.gridApi.forEachNode(function (rowNode, index) {
      var data = rowNode.data;
      // console.log('first',data);

      orderNo.push(data.order_number);
      if (localStorage.getItem("data_type") == 1) {
        data.drop_location_code = drop_location_code;
        // data.delivery_date = "";
        // data.invoicing_date = "";
      }
      if (localStorage.getItem("data_type") == 2) {
        data.pickup_location_code = pickup_location_code;
        // data.delivery_date = "";
        // data.invoicing_date = "";
      }
      if (
        data.dealer_available_start == "" ||
        data.dealer_available_start == undefined
      ) {
        data.dealer_available_start = "09:00";
      }
      if (
        data.dealer_available_end == "" ||
        data.dealer_available_end == undefined
      ) {
        data.dealer_available_end = "18:00";
      }
      inputData.push(data);
      // console.log(Object.keys(data));
      var drop_location_code_check = data.drop_location_code
      // console.log(drop_location_code_check)

      // console.log(dropList)
      // console.log(Object.keys(data),"hello")
      var dealerAvblStart=data['dealer_available_start'],dealerAvblEnd=data['dealer_available_end']

      // Split the time strings into hours and minutes
      var [hours1, minutes1] = dealerAvblStart.split(":").map(Number);
      var [hours2, minutes2] = dealerAvblEnd.split(":").map(Number);

      // Convert hours to minutes and add them to the minutes
      var totalMinutes1 = hours1 * 60 + minutes1;
      var totalMinutes2 = hours2 * 60 + minutes2;

      // Compare the total minutes
      if (totalMinutes1 < totalMinutes2) {
          dealerAvlChek=true
      }
      
      for (var k of Object.keys(data)) {

        if (data[k] === "" || data[k] == null) {
          check_for_empty = true;
          errorField = k;
        }
        if (data[k] === "In valid Format") {
          check_for_invalid = true;
          errorField = k;
        }
        if (data[k] === "Invoice date is greater than delivery date") {
          // console.log(k)
          check_for_Invoice_date = true;   
          errorField = k;   
        }
        if (data[k] === "Delivery Date is less than Invoice Date"){
          // console.log(k)
          check_for_delivery_date = true;
          errorField = k;
        }
        // if (data[k] === "Invoicing Date should not be less than today"){
        //   console.log(k)
        //   check_for_Invoice_date = true;
        //   errorField = k
        // }
        // if (data[k] === "Delivery Date should be later or equal to invoice date") {
        //   console.log(k)
        //   check_for_delivery_date = true;
        //   errorField = k
        // }
        if (!dropList.includes(drop_location_code_check)) {
          check_for_valid_loc = true

        }

      }
    });
    
    if (!dealerAvlChek){
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle:`Dealer Available start time should be less than end time`,
      });
      return
    }
    orderNo.map((item)=>{
      let element=item 
      var filteredList=orderNo.filter((item2)=>{
        return item2===element
      })
      if (filteredList.length>1){
        checkOfDupli=true
      }
    })
    // console.log(check_for_empty, 'empty', check_for_invalid, 'invalid', check_for_valid_loc, 'valid loc')

    if (check_for_empty) {
      // alert(`Please Fill ${errorField} Field`);
      if(errorField === 'demand_cmt'){
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle:`Please Fill Volume Field`,
        });
        return
      }
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle:`Please Fill ${errorField} Field`,
        });
    } else if (check_for_invalid) {
      // alert(`Please Fill ${errorField} in a valid Format`);
      if(errorField === 'demand_cmt'){
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle:`Please Fill Volume Field in Valid Format`,
        });
        return
      }
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle:`Please Fill ${errorField} in a valid Format`,
        });
    } else if (check_for_valid_loc) {
      // alert('Please choose a Valid Drop Location')
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle:'Please choose a Valid Drop Location',
        });
    }else if (checkOfDupli) {
      // alert('Please Enter Unique Order IDs')
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle:'Please Enter Unique Order IDs',
        });
    }else if(check_for_Invoice_date){
      // alert ("Please Enter a valid Invoice Date")
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle:"Please Enter a valid Invoice Date",
        });
    }else if (check_for_delivery_date){
      // alert("Please Enter valid Delivery Date")
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle:"Please Enter valid Delivery Date",
        });
    } else {

      if (inputData.length) {
        var cnf = window.confirm("Do you want to proceed?");
        if (cnf) {
          inputData.map((item)=>{
            item.invoicing_date=moment(item.invoicing_date, "M/D/YYYY").format("DD-MM-YYYY");
            item.delivery_date=moment(item.delivery_date, "M/D/YYYY").format("DD-MM-YYYY");
          })
        // console.log(inputData,'first')
        let saveData = new FormData();
          saveData.append("module_type", 1);
          saveData.append("data_type", this.state.data_type);
          saveData.append(
            "material_configurations",
            this.state.material_configurations
          );
          let location = this.state.location;
          saveData.append("location_code", location.value);
          saveData.append("location_name", location.label);
          saveData.append("inputData", JSON.stringify(inputData));
          saveData.append("user_name", localStorage.getItem("username"));
          saveData.append("useremail", localStorage.getItem("email"));
          saveData.append("client", localStorage.getItem("client"));
          // console.log(saveData, saveData);
          this.setState({
            loadshow: "show-m",
            overly: "show-m",
          });
          let logParams = {
            "location_code" : location.value,
            "location_name" : location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Summary Dashboard",
            "activity" : 'Input Data Entered',
            "event" : "Run New Plan Manually",
            "data_type" : this.state.data_type,
          }
          // console.log(logParams,'params')
          
          redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
          .then(()=>{})
          .catch(function (error) {
            var errDetails={
              url:"/master/logUserUpdateOrSaveDataActivities",
              screen:'Run New Plan',
              error,
              
          }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
            
          
          redirectURL
            .post("/dispatch/generateLoadByInputData", saveData)
            .then((response) => {
              // console.log(response, "responseFromProcess");
              var statusCode = response.data.status;
              if (statusCode == 8) {
                this.setState({
                  processErrMsg: response.data.message,
                  processErrShow: 1,
                  transactionId: response.data.transactionId,
                  droppedList: response.data.droppedList,
                  statusCode: statusCode,
                  loadshow: "show-n",
                  overly: "show-n",
                  overlayBlock: "show-n",
                });
              } else if (statusCode > 3 && statusCode != 8) {
                if (statusCode == 11) {
                  var processMsg =
                    "Use the standard input data file template for uploading loads";
                } else {
                  var processMsg = response.data.message;
                }
                this.setState({
                  processErrMsg: response.data.message,
                  processErrShow: 1,
                  transactionId: response.data.transactionId,
                  statusCode: statusCode,
                  loadshow: "show-n",
                  overly: "show-n",
                  overlayBlock: "show-n",
                });
              } else {
                let basestring = response.data.transactionId;
                let encryptedstring = window.btoa(basestring);
                window.location.href = "/plannewdispatch?" + encryptedstring;

                //temp fix location need to be passed
                localStorage.setItem(
                  "runPlanLocation",
                  this.state.location.value
                );
              }
              //window.location.reload();
            })
            .catch(function (error) {
              var errDetails={
                url:"/dispatch/generateLoadByInputData",
                screen:'Run New Plan',
                error,
                
            }
              redirectURL.post("/master/logErrorsForApiCalls",errDetails)
              
          })
              
        }
      } else {
        this.setState({
          basicTitle: "Enter Input Data To Continue",
          basicType: "warning",
          show: true,
        });
      }
    }
    // }
  };

  onCellValueChanged(params) {
    // console.log(params.colDef.field,'para')
    // let toDay = moment().parseZone().format("DD-MM-YYYY")
    if (params.colDef.field === "invoicing_date" ) {
      const inputValue = params.newValue;
      const isValidDate = validateDate(inputValue);
      const deliveryDate = params.data.delivery_date
      // console.log(deliveryDate,'helo')
      // if (isValidDate) {
      //   if ((inputValue) < toDay ){
      //     params.node.setDataValue("invoicing_date", "Invoicing Date should not be less than today")
      //   }
      // }
      if (!isValidDate) {
        params.node.setDataValue("invoicing_date", "In valid Format");     
      }      
      else if (deliveryDate !== "" && deliveryDate !== null && deliveryDate !== undefined ){
        if(deliveryDate < inputValue){
          params.node.setDataValue("invoicing_date", "Invoice date is greater than delivery date")
        }
      } 
    }
     else if (params.colDef.field === "delivery_date") {
      const inputValue = params.newValue;
      const isValidDate = validateDate(inputValue);
      const invoiceDate = params.data.invoicing_date;
      // if (isValidDate) {
      //   if ((inputValue) < toDay && ((inputValue) < (invoiceDate) )){
      //     params.node.setDataValue("delivery_date", "Delivery Date should be later or equal to invoice date")          
      //   }
      // }
      if (!isValidDate) {
        params.node.setDataValue("delivery_date", "In valid Format");
      }else if (invoiceDate !== "" && invoiceDate !== null && invoiceDate !== undefined ){
        if(inputValue < invoiceDate) {
        params.node.setDataValue("delivery_date", "Delivery Date is less than Invoice Date")
        }
      }
    } 
    else if (params.colDef.field === "weight_tons") {
      const inputValue = params.newValue;

      if (typeof inputValue === "undefined") {
        params.node.setDataValue("weight_tons", "In valid Format");
      }
    } else if (params.colDef.field === "quantity") {
      const inputValue = parseInt(params.newValue);
      // console.log('typeof', inputValue);
      if (!Number.isInteger(inputValue)) {
        params.node.setDataValue("quantity", "In valid Format");
      }
    } else if (params.colDef.field === "order_number") {
      const inputValue = parseInt(params.newValue);
      //   console.log(typeof inputValue);
      if (!Number.isInteger(inputValue)) {
        params.node.setDataValue("order_number", "In valid Format");
      }
    } else if (params.colDef.field === "dealer_available_start") {
      const inputValue = params.newValue;
      const isValidDate = validateTime(inputValue);
      // console.log(typeof inputValue);
      // console.log(isValidDate);

      if (!isValidDate) {
        params.node.setDataValue(
          "dealer_available_start",
          "In valid Format"
        );
      }
    } else if (params.colDef.field === "dealer_available_end") {
      const inputValue = params.newValue;
      const isValidDate = validateTime(inputValue);
      // console.log(typeof inputValue);
      // console.log(isValidDate);

      if (!isValidDate) {
        params.node.setDataValue(
          "dealer_available_end",
          "In valid Format"
        );
      }
    } else if (params.colDef.field === "demand_cmt") {
      const inputValue = parseInt(params.newValue);
      // console.log('typeof', inputValue);
      if (!Number.isInteger(inputValue)) {
        params.node.setDataValue("demand_cmt", "In valid Format");
      }
    }

    // if (params.colDef.field === "dealer_available_start"){
    // if(params.colDef.field === "dealer_available_start"){
    //   const inputValue = params.newValue;
    // const isValidDate = validateTime(inputValue);
    //   if (!isValidDate) {
    //     params.node.setDataValue('dealer_available_start', "In valid Time Format Please Input 24Hr Format");
    //   }
    // }

    // if(params.colDef.field === "dealer_available_end"){
    //     const inputValue = params.newValue;
    //   const isValidDate = validateTime(inputValue);
    //   if (!isValidDate) {
    //     params.node.setDataValue('dealer_available_end', "In valid Time Format Please Input 24Hr Format");
    //   }
    // }
    // }
  }

  previousOrderSelected = (club_order) => {
    this.setState({ club_order });
  };

  getLatestDispatchOrderId = (params) => {
    let previous_order = [{ label: "Select", value: "" }];
    let data_type = this.state.data_type;
    params.data_type = data_type;
    
    redirectURL
      .post("/dispatch/getLatestDispatchOrderId", params)
      .then((response) => {
        if (response.data.orderId.length > 0) {
          let id = response.data.orderId[0].order_id;
          this.setState({
            previous_order: [...previous_order, { label: id, value: id }],
          });
        } else {
          this.setState({ previous_order: [{ label: "Select", value: "" }] });
        }
      })
      .catch(function (error) {
        var errDetails={
          url:"/dispatch/getLatestDispatchOrderId",
          screen:'Run New Plan',
          error,
          
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
        
  };

  toggleMoreInfo = () => {
    this.setState((prevState) => ({ showMoreInfo: !prevState.showMoreInfo }));
  };

  handleIgnoreProcess = async() =>{
    if (!this.state.clickOnIgnore){
    await this.setState({ignoreProcess:true,clickOnsave:false,clickOnIgnore:true})
    await this.uploadBulkFormHandler()
    }
  }
 
  render() {
    if (localStorage.getItem("data_type") == 1) {
      var inputDetailscolsDefs = [
        {
          headerName: "Actions",
          field: "delete",
          width: "80",
          pinned: "left",
          params: {
            buttonName: "Delete",
            iconName: "fa fa-trash",
            onClickFunction: this.deleteRow,
          },
          cellRendererSelector: function (params) {
            var rendComponent = {
              component: "GridButton",
            };
            return rendComponent;
          },
        },
        {
          headerName: "Pick Up Location (Source)",
          field: "pickup_location_code",
          width: "150",
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: this.state.pickupList,
          },
        },
        {
          headerName: "Drop Location (Destination)",
          field: "drop_location_code",
          width: "150",
          editable: false,
          // cellEditor: 'agSelectCellEditor',
          // cellEditorParams: {
          //     values: this.state.dropList,
          // },
          valueGetter: () => {
            var location = this.state.location;
            if (location != undefined && location != "") {
              return location.value;
            }
          },
        },
      ];
    } else {
      var inputDetailscolsDefs = [
        {
          headerName: "Pick Up Location (Source)",
          field: "pickup_location_code",
          width: "150",
          editable: false,
          // cellEditor: 'agSelectCellEditor',
          // cellEditorParams: {
          //     values: this.state.pickupList,
          // },
          valueGetter: () => {
            var location = this.state.location;
            if (location != undefined && location != "") {
              return location.value;
            }
          },
        },
        {
          headerName: "Drop Location (Destination)",
          field: "drop_location_code",
          width: "250",
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: this.state.dropList,
          },
        },
        {
          headerName: "Order Number",
          field: "order_number",
          width: "200",
          editable: true,
          cellEditor: ValidationCellEditor,
        },
        {
          headerName: "Invoicing Date(M/D/YYYY)",
          field: "invoicing_date",
          width: "150",
          editable: true,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          width: "100",
          editable: true,
          cellEditor: ValidationCellEditor,
        },
        {
          headerName: "Dealer Available Time (from 00:00)",
          field: "dealer_available_start",
          width: "150",
          editable: true,
          valueGetter: function (params) {
            if (
              params.data.dealer_available_start == undefined ||
              params.data.dealer_available_start == ""
            ) {
              return "09:00";
            } else {
              return params.data.dealer_available_start;
            }
          },
        },
        {
          headerName: "Dealer Available Time (to 23:59)",
          field: "dealer_available_end",
          width: "150",
          editable: true,
          valueGetter: function (params) {
            if (
              params.data.dealer_available_end == undefined ||
              params.data.dealer_available_end == ""
            ) {
              return "18:00";
            } else {
              return params.data.dealer_available_end;
            }
          },
        },
        {
          headerName: "Actions",
          field: "delete",
          width: "80",
          pinned: "left",
          params: {
            buttonName: "Delete",
            iconName: "fa fa-trash",
            onClickFunction: this.deleteRow,
          },
          cellRendererSelector: function (params) {
            var rendComponent = {
              component: "GridButton",
            };
            return rendComponent;
          },
        },
        {
          headerName: "Volume (M³)",
          field: "demand_cmt",
          width: "120",
          editable: true,
          cellEditor: ValidationCellEditor,
        },
        {
          headerName: "Weight (Kgs)",
          field: "weight_tons",
          width: "120",
          editable: true,
          cellEditor: ValidationCellEditor,
        },
        {
          headerName: "Delivery Date(M/D/YYYY)",
          field: "delivery_date",
          width: 150,
          editable: true,
        },
      ];
    }

    var columnwithDefsForDropped = [
      {
        headerName: "Dealer Code",
        field: "dealer_code",
        width: 130,
        filter: true,
        resizable: true,
        editable: false,
      },
      // {
      //   headerName: "Demand",
      //   field: "demand",
      //   width: 130,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      // },
      {
        headerName: "Remarks",
        field: "remarks",
        width: 200,
        filter: true,
        resizable: true,
        editable: false,
        cellRenderer: (params) => {
          if (params.value === null || params.value === undefined) {
            return "Dealer Details Not Found";
          } else {
            return params.value;
          }
        },
      },
    ];
    // if (this.state.material_configurations == 1) {
    //   var templatePath = require("../../assets/json/input_data_with_material_config_template.csv");
    // } else {
    var templatePath = require("../../assets/json/input_data_template.csv");
    // }
    return (
      <div class="container-fluid">
        <div
          className={"animatenow " + this.state.showanimate}
          style={{ textAlign: "center" }}
        >
          <span className="pp-h">Processing Plan. Please wait.....</span>
          <img
            src={require("../../assets/images/r.gif")}
            style={{ width: "85vh" }}
          />
        </div>
        <div className={"lodr " + this.state.showloader}>
          <div className="loaderdiv">
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
            <div className="loaderwave"></div>
          </div>
          <div className="ldrtext">Loading....</div>
        </div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        {this.state.alert}
        <div class="row mb-5p">
          <div className="col-sm-12 pt-5p">
            <h5 className="fbold">
              Run New Plan
              <button
                type="button"
                className="btn btn-warning float-right font-white"
                onClick={this.onClickShowUpload}
              >
                Run New Plan By File
              </button>
              <button
                className="btn btn-success"
                style={{ float: "right" }}
                onClick={this.addNewRow.bind(this)}
              >
                Add Dispatch Plan Data
              </button>
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group ">
              <label className="col-form-label f12">
                {Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}
              </label>
              <Select
                placeholder={"Select"}
                onChange={this.locationChanged}
                name="location"
                value={this.state.location}
                options={this.state.locationNames}
              />
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-3"></div>
        </div>
        <div class="row mb-20p">
          <div className={"mxheit col-sm-12 "}>
            <div
              id="myGrid1"
              style={{ width: "100%", height: "55vh" }}
              className={"ag-theme-balham"}
            >
              <AgGridReact
                // modules={this.state.modules}
                columnDefs={inputDetailscolsDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.rowData}
                enableCharts={false}
                onGridReady={this.onGridReady}
                onGridState={this.onGridState}
                frameworkComponents={this.state.frameworkComponents}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                stopEditingWhenGridLosesFocus={true}
                paginationPageSize={this.state.paginationPageSize}
                pagination={false}
                gridOptions={{
                  context: { componentParent: this },
                }}
                // components={this.state.components}
                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                enableRangeSelection={true}
                // onCellEditingStopped={this.onCellEditingCell.bind(this)}
                // masterDetail={true}
                // detailCellRendererParams={this.state.detailCellRendererParams}
                onCellEditingStopped={this.onCellValueChanged.bind(this)}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary mt-20p float-right"
              onClick={this.onClickRunDispatchPlan}
            >
              Submit & Run Plan
            </button>
          </div>
        </div>
        <div
          className={"overlay-block " + this.state.overlayBlock}
          onClick={this.hideSlideBlock}
        ></div>
        <div className={"sliderBlock2 " + this.state.showSlideBlockUpload}>
          <h5 className="crd-bg p-10p">Upload Input File</h5>
          <div className="row">
            <div className="col-sm-12">
              {this.state.processErrShow == 0 ? (
                <form onSubmit={this.uploadBulkFormHandler.bind(this)}>
                  <div className="row p-20p">
                    <div className="form-group col-sm-12">
                      <a
                        className="btn btn-warning"
                        href={templatePath}
                        // target="_blank"
                        onClick={this.onClickDownloadSample}
                      >
                        Download Sample Template
                      </a>
                    </div>
                    <div className="form-group col-sm-12 mb-10p">
                      <label style={{ color: "#000" }}>Upload File</label>
                      <input
                        type="file"
                        name="uploadFile"
                        id="uploadFile"
                        onChange={this.changeFileHandler}
                        className="form-control"
                        onClick={this.onclickUploadFile}
                        required
                      />
                    </div>

                    <div
                      className="form-group col-sm-12 note-font"
                      onClick={this.toggleMoreInfo}
                    >
                      Note : Blank Rows/Columns are not Allowed{" "}
                      <span className="more-btn">
                        {this.state.showMoreInfo ? "Less <" : "More >"}
                      </span>
                      {this.state.showMoreInfo && (
                        <div className="more-info">
                          To Check for Blank Data 1. Click Find & Select. 2.
                          Click to Go to Special. 3. Choose Blanks. 4. Click OK
                          and then all the blank rows/cells will be highlighted.
                          5. Choose the Delete under Cells section on the Home
                          Tab. 6. Click Delete Sheet Rows.
                        </div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 note-font">
                      Disclaimer : Dealer available window for OT customers will
                      be considered from input file, GT customer timings will be
                      considered from pickup/drop locations.
                    </div>
                    {/* <div className="form-group col-sm-12 mb-20p">
                      <label style={{ color: "#000" }}>
                        Notify users via email once the process is completed
                        (comma seperated)
                      </label>
                      <textarea
                        name="notify_email"
                        id="notify_email"
                        className="form-control"
                        placeholder="abc@mail.com,xyz@mail.com"
                      ></textarea>
                    </div> */}
                    {this.state.previous_order.length > 1 ? (
                      <div className="col-sm-12">
                        <div className="form-group ">
                          <label
                            htmlFor="club_order"
                            className="col-form-label f12"
                          >
                            Club Previous Order
                          </label>

                          {/* <select name="club_order" id="club_order" defaultValue={this.state.club_order}
                                            className= "forminp form-control"
                                            onChange={this.previousOrderSelected}>
                                            <option value="" selected>Select</option>
                                            <option value={this.state.previous_order}>{this.state.previous_order}</option>
                                        </select> */}

                          <Select
                            placeholder={"Select"}
                            onChange={this.previousOrderSelected}
                            name="club_order"
                            value={this.state.club_order}
                            options={this.state.previous_order}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form-group col-sm-12 mb-20p">
                      <button
                        type="submit"
                        id="bulkUploadBtn"
                        className="btn btn-info"
                      >
                        SAVE
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={this.hideSlideBlock.bind(this)}
                      >
                        CANCEL
                      </button>
                    </div>
                    <div id="inValidDataInfo" style={{ color: "red" }}></div>
                  </div>
                </form>
              ) : (
                <div className="" style={{ marginTop: "1em" }}>
                  <p style={{ marginBottom: "1em" }}>
                    {this.state.processErrMsg}
                  </p>
                  {this.state.droppedList.length > 0 ? (
                    <div
                      id="myGrid"
                      style={{
                        width: "100%",
                        height: "300px",
                        marginBottom: "1em",
                      }}
                      className={"ag-theme-balham " + this.state.showGridData}
                    >
                      <AgGridReact
                        // modules={this.state.modules}
                        columnDefs={columnwithDefsForDropped}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.droppedList}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady1}
                        onGridState={this.onGridState1}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus={true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={false}
                        gridOptions={{
                          context: { componentParent: this },
                        }}
                        // components={this.state.components}
                        enableRangeSelection={true}
                        //onCellClicked={this.onCellClicked}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    onClick={this.fixProcess.bind(this)}
                    className="btn btn-success"
                  >
                    Fix and Reupload
                  </button>
                  {this.state.statusCode == 11 ? (
                    <button
                      type="button"
                      onClick={this.hideSlideBlock.bind(this)}
                      className="btn btn-default"
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={this.handleIgnoreProcess.bind(this)}
                      className="btn btn-default"
                    >
                      Ignore
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
        <div className={"loader " + this.state.loadshow}></div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.hideSlideBlock.bind(this)}
        ></div>
      </div>
    );
  }
}

function ValidationCellEditor() { }

ValidationCellEditor.prototype.init = function (params) {
  this.eGui = document.createElement("div");
  this.eGui.innerHTML = `
    <input value=${params.value} >
  `;
  this.eInput = this.eGui.querySelector("input");
  this.eInput.addEventListener("input", this.inputChanged.bind(this));
};

ValidationCellEditor.prototype.inputChanged = function (event) {
  const val = event.target.value;
  if (!this.isValid(val)) {
    this.eInput.classList.add("invalid-cell");
  } else {
    this.eInput.classList.remove("invalid-cell");
  }
};

ValidationCellEditor.prototype.isValid = function (value) {
  // console.log("isNaN(value) ", isNaN(value))
  if (isNaN(value) === false) {
    return value.length >= 1 || value.length === 20;
  } else {
    return "";
  }
};

ValidationCellEditor.prototype.getValue = function () {
  return parseFloat(this.eInput.value);
};

ValidationCellEditor.prototype.isCancelAfterEnd = function () {
  return !this.isValid(this.eInput.value);
};

ValidationCellEditor.prototype.getGui = function () {
  return this.eGui;
};

ValidationCellEditor.prototype.destroy = function () {
  this.eInput.removeEventListener("input", this.inputChanged);
};

function validateDate(inputValue) {
  const isoDateRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/\d{4}$/;
  if (!isoDateRegex.test(inputValue)) {
    return false;
  }
  const [month, day, year] = inputValue.split("/").map(Number);

  const date = new Date(year, month - 1, day);
  return (
    date.getDate() === day &&
    date.getMonth() === month - 1 &&
    date.getFullYear() === year
  );
}

function validateTime(window_time) {
  // console.log(window_time, "Window Time");
  //array[00:00,00:00]
  // Regular expression to match the 24-hour time format (HH:mm)
  var regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return regex.test(window_time);
  let frm_hr = window_time[0].split(":");
  let to_hr = window_time[1].split(":");
  // console.log(frm_hr, to_hr);
  // console.log(typeof window_time);
  var timeObj = window_time.split(":");
  if (timeObj[0] > 23) {
    // console.log(timeObj[0]);
    return false;
  }
  if (timeObj[1] > 59) {
    // console.log(timeObj[1]);
    return false;
  }

  if (window_time[0] == window_time[1]) {
    // return "Please Input Time Appropriately 24Hr Format";
    return false;
  } else if (frm_hr[0] > to_hr[0]) {
    // return "Please Input Time Appropriately 24Hr Format";
    return false;
  } else if (frm_hr[0] == to_hr[0]) {
    if (frm_hr[1] > to_hr[1]) {
      // return "Please Input Time Appropriately 24Hr Format";
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

function isDateFormatValid(date) {
  const dateParts = date.split("/");

  if (dateParts.length !== 3) {
    return false;
  }

  const day = parseInt(dateParts[1], 10);
  const month = parseInt(dateParts[0], 10);
  const year = parseInt(dateParts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return false;
  }

  const formattedDate = new Date(year, month - 1, day);

  if (
    formattedDate.getDate() !== day ||
    formattedDate.getMonth() !== month - 1 ||
    formattedDate.getFullYear() !== year
  ) {
    return false;
  }

  return true;
}

function isDateFormatValidforXLSX(date) {
  // console.log(date, 'date');
  let dateParts = date.split("/");

  if (dateParts.length !== 3) {
    return false;
  }

  var month = parseInt(dateParts[0], 10);
  var day = parseInt(dateParts[1], 10);
  var year = parseInt(dateParts[2], 10);

  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    return false;
  }

  // Adjust the year if it's two digits
  if (year >= 0 && year < 100) {
    // Assuming if the year is less than 50, it belongs to the 21st century, otherwise 20th century
    year += (year < 50) ? 2000 : 1900;
  }

  var formattedDate = new Date(year, month - 1, day);
  // console.log(formattedDate,'formated date')
  if (
    formattedDate.getDate() !== day ||
    formattedDate.getMonth() !== month - 1 ||
    formattedDate.getFullYear() !== year
  ) {
    return false;
  }

  return true;
}

function isValidWeight(weight){
  if (weight.includes(',')){
    return false
  } else if(parseFloat(weight)>50000){
    return false
  } else{
    return true
  }
}

function isPickUPCodeValid(code) {

  if(code != loc_code){
    return false
  }else{
    return true
  }
    
}

function isDropCodeValid(code,plantCodes) {
 if(!plantCodes.includes(code)){
   return true
 }else{
 return false
 }
}
