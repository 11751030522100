/* eslint-disable no-unused-vars */
/* eslint-disable no-multi-str */
/* eslint-disable eqeqeq */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  getDDMMYYYY,
  getDDMMYYYYHHMMSS,
  getYYYYMMDD,
  getYYYYMMDDHHMMSS,
} from "../common/utils";

import Highcharts from "highcharts";
import drilldown from "highcharts-drilldown";
import HighchartsReact from "highcharts-react-official";
import $ from "jquery";
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require("../redirectURL");
var criticaldata = [];
export default class SndUnloadingColumnChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showgrid: "display:none",
      showtransgrid: "display:none",
      rowdata: [],
      test: "TEstng",
    };
    this.handleDrilldown = this.handleDrilldown.bind(this);
  }
  componentDidMount = async () => {
    $(".grid-" + this.props.code).hide();
    $(".grid-trans-" + this.props.code).hide();
    $(".dataLoadpagesum" + this.props.code).hide();
    $(".dataLoadpageimgsum" + this.props.code).hide();
    $(".overlay-partsum" + this.props.code).hide();
  };
  componentWillReceiveProps(newprops) {
    if (newprops.title != this.props.dept_code) {
      //console.log("newprops ",newprops);
    }
  }
  handleDrilldown = (params) => {
    // console.log("Here ,", params)
  };
  render() {
    //console.log("this.props.code) ", this.props.code)

    const dStyles = {
      width: "100%",
      height: "100%",
    };
    const highchartoptions = {
      chart: {
        type: "column",
        events: {
          // click: (function(self) {
          // return function() {
          //     const point = {
          //     x: this.x,
          //     y: this.y
          //     };
          //     self.handleDrilldown(point);
          // };
          // })(this)
        },
      },
      title: {
        text: this.props.title,
        //align:'left'
      },
      subtitle: {
        text: this.props.subtitle,
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: this.props.yaxistitle,
        },
      },
      legend: {
        enabled: true,
      },
      // point: {
      //     events:{
      //         click:function(e)
      //         {
      //             alert("test")
      //            return this.handleDrilldown(e)
      //         }
      //     }
      // },
      plotOptions: {
        series: {
          events: {
            legendItemClick: function (event) {
              // Prevent the default behavior (toggling visibility)
              event.preventDefault();
            },
          },
        },
        column: {
          dataLabels: {
            enabled: true,
          },
          pointWidth: 40,
          stacked: false,
          enableMouseTracking: true,
          cropThreshold: 500,
        },
        line: {
          dataLabels: {
            enabled: true,
            // format:'{point.y}'
          },
        },
      },

      tooltip: {
        // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} {(point.unloadtext)}</b></b><br><span style="color:{point.color}">No of trips</span>: <b>{point.trips}</b>'
        formatter: function () {
          //   console.log("series", this.series);
          // console.log('point',this.point)
          if (this.point.showTooltip == 1) {
            return (
              "<span style='font-size:11px'>" +
              this.series.name +
              "</span><br>\
                            <span style='color:{point.color}'>" +
              this.point.name +
              "</span>: <b>" +
              this.point.y +
              "</b></b><br>"
            );
          } else {
            if (this.point.trips !== undefined) {
              return (
                "<span style='font-size:11px'>" +
                this.series.name +
                "</span><br>\
                                <span style='color:{point.color}'>" +
                this.point.name +
                "</span>: <b>" +
                this.point.y +
                "</b></b><br>\
                                <span style='color:{point.color}'>No of trips</span>: <b>" +
                this.point.trips +
                "</b>"
              );
            } else {
              return false;
            }
          }
        },
      },

      series: [
        {
          name: this.props.seriesName,
          colorByPoint: true,
          data: this.props.series,
        },
      ],
      drilldown: this.props.drilldownseries,
      credits: false,
    };
    // console.log(highchartoptions.drilldown,"drilldown");
    // console.log(highchartoptions.series,"series")
    return (
      <div>
        <HighchartsReact
          containerProps={{ style: { width: "100%", height: "100%" } }}
          highcharts={Highcharts}
          style={dStyles}
          options={highchartoptions}
          onClick={this.handleDrilldown.bind(this)}
        />
      </div>
    );
  }
}
