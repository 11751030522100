import React from 'react';

const GridCheckbox = (props) => {
  const { value, data, screenName } = props;
  // console.log(data,screenName,'kkk')

  // Check the condition and determine the checkbox state
  var isChecked = data.vehicle_breakdown==1 ? true : false;
  if (data.assigned_to_ot !== undefined && data.assigned_to_ot && screenName === 'summaryDash'){
    isChecked=true
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <input type="checkbox" checked={isChecked} disabled={true} />
    </div>
  );
};

export default GridCheckbox;

