import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import redirectURL from "../redirectURL";

 
const DeviationConsignmentInformation = (props) => {
    const handleRouteClick = async (e) => {
        // console.log("I am Clicking")
        let logParams = {
            // "location_code" : this.state.location.value,
            // "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Trips",
            "activity" : 'clicked on view route icon in aggrid',
            "event" : "slider with map or pop of notification appeared",
            // "data_type" : this.state.data_type,
        }
    
        await redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        e.stopPropagation();
        props.onClickShowConsignmentInformation(props);
    };

    return (
        <div className="map-icon-component">
			{/*<button onClick={handleClick} className="custom-btn label label-success" title="View Timeline"><i className="icofont icofont-tree-alt"></i> Timeline </button>
            &nbsp;| &nbsp;
            */}
            <button  onClick={handleRouteClick} className="custom-btn label label-success" title="Consignment Information"><i style={{color:"#17a2b8"}} className="fa fa-info-circle f15"></i> </button>
       
        </div>
    );
};

export default DeviationConsignmentInformation;