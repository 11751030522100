import React,{Component} from 'react';
import Switch from "react-switch";
import redirectURL from '../redirectURL';
import CapacityStacking from './capacityStacking'

export default class StackingConfiguration extends Component{
    constructor(props){
        super(props);
        this.state = {
            stackingConfigs : "show-n",
            stackingChecked : false,
            itemStackingChecked : false,
            itemStackingConfigs : "show-n",
            plant_locations:1,
            vehicle_configurations:1,
            item_stacking_max_trucks_check:0,
            max_no_of_trucks:4,
            material_configuration:1,
            stacking_plan:0,
            stacking_plan_options:"show-n",
            loading_unloading_sequencing:0,
            stacking_3d:0,
            stacking_2d:0,
            fregility_index_preferences:0,
            rotation_preferences:0,
            weight_preferences:0,
            material_size_preferences:0,




        }
    }

    componentDidMount(){

        this.getConfigurations()
    }

        getConfigurations = () =>{
            redirectURL.post('master/getConfigurations')
            .then(async response =>{
                let stackingConfigs = "show-n";
                let stackingChecked = false;
                let itemStackingChecked = false;
                let itemStackingConfigs = "show-n";
                let stacking_plan_options = "show-n";
                let max_no_of_trucks = 4;
                let stacking_plan = 0;
                let loading_unloading_sequencing = 0;
                let stacking_3d = 0;
                let stacking_2d = 0;
                let fregility_index_preferences = 0;
                let rotation_preferences = 0;
                let weight_preferences = 0;
                let material_size_preferences = 0;
                var configData = response.data;

            if(configData.length > 0)
            {
                var itemStacking = await configData.filter((cd) => cd.module_type == 2 && cd.data_type == 1);
                if(itemStacking.length > 0)
                {
                    stackingConfigs = "";
                    stackingChecked = true;
                    itemStackingChecked = true;
                    itemStackingConfigs = "";
                    
                    max_no_of_trucks = itemStacking[0].max_no_of_trucks;
                    stacking_plan = itemStacking[0].stacking_plan;
                    loading_unloading_sequencing = itemStacking[0].loading_unloading_sequencing;
                    stacking_3d = itemStacking[0].stacking_3d;
                    stacking_2d = itemStacking[0].stacking_2d;
                    fregility_index_preferences = itemStacking[0].fregility_index_preferences;
                    rotation_preferences = itemStacking[0].rotation_preferences;
                    weight_preferences = itemStacking[0].weight_preferences;
                    material_size_preferences = itemStacking[0].material_size_preferences;
                    if(stacking_plan){
                        stacking_plan_options = '';
                    }
                }
                // var dispatchOutBound = await configData.filter((cd) => cd.module_type == 2 && cd.data_type == 2);
                // if(dispatchOutBound.length > 0)
                // {
                //     dispatchChecked = true;
                //     dispatchConfigs = "";
                //     dispatchOutboundChecked = true;
                //     dispatchConfigsOutbound = "";
                // }

            }
            this.setState({
                stackingConfigs : stackingConfigs,
                stackingChecked : stackingChecked,
                itemStackingChecked : itemStackingChecked,
                itemStackingConfigs : itemStackingConfigs,
                stacking_plan_options : stacking_plan_options,
                max_no_of_trucks : max_no_of_trucks,
                stacking_plan : stacking_plan,
                loading_unloading_sequencing : loading_unloading_sequencing,
                stacking_3d : stacking_3d,
                stacking_2d : stacking_2d,
                fregility_index_preferences : fregility_index_preferences,
                rotation_preferences : rotation_preferences,
                weight_preferences : weight_preferences,
                material_size_preferences : material_size_preferences,
            })
            })
        }


    handleStackingChange = (checked) => {
        var stackingConfigs = "show-n";
        if(checked == true)
        {
            stackingConfigs = "";
        }
        this.setState({ stackingChecked : checked, stackingConfigs:stackingConfigs});
    }

    handleItemStackingChange = (checked) => {
        var itemStackingConfigs = "show-n";
        if(checked == true)
        {
            itemStackingConfigs = "";
        }
        this.setState({ itemStackingChecked : checked, itemStackingConfigs:itemStackingConfigs});
    }

    changeHandler = (event) => {

        let name = event.target.name;
		let value = event.target.value;
    	
        if(value == 1){
            this.setState({[name]:0});
        }else{
            this.setState({[name]:1});
        }
		
        if(name == "stacking_plan"){
            if(value==0){
            this.setState({stacking_plan_options:"show-m"})
            }else{
                this.setState({stacking_plan_options:"show-n"})
            }
        }
    }

    maxTrucksChangeHandler = (event) =>{
        let name = event.target.name;
		let value = event.target.value;
        
        this.setState({[name]:value});
    }

    saveItemStackingConfigs = () => {
        let module_type = 2;
        let data_type = 1;
        // let plant_locations =this.state.plant_locations;
        // let vehicle_configurations =this.state.vehicle_configurations;
        // let material_configuration =this.state.material_configuration;
        let max_no_of_trucks = this.state.max_no_of_trucks;
        let stacking_plan = this.state.stacking_plan;
        let loading_unloading_sequencing = this.state.loading_unloading_sequencing;
        let stacking_3d = this.state.stacking_3d;
        let stacking_2d = this.state.stacking_2d;
        let fregility_index_preferences = this.state.fregility_index_preferences;
        let rotation_preferences = this.state.rotation_preferences;
        let weight_preferences = this.state.weight_preferences;
        let material_size_preferences = this.state.material_size_preferences;

        if(stacking_plan == 0){
            loading_unloading_sequencing = 0;
            stacking_3d = 0;
            stacking_2d = 0;
        }

        let params = {
            max_no_of_trucks : parseInt(max_no_of_trucks),
            stacking_plan : stacking_plan,
            loading_unloading_sequencing : loading_unloading_sequencing,
            stacking_3d : stacking_3d,
            stacking_2d : stacking_2d,
            fregility_index_preferences : fregility_index_preferences,
            rotation_preferences : rotation_preferences,
            weight_preferences : weight_preferences,
            material_size_preferences : material_size_preferences,
            module_type : module_type,
            data_type : data_type
        }

        redirectURL.post('master/saveappconfigurations',params)
        .then(response =>{
            
            if(response.data.status == 'success'){
                this.getConfigurations()
                this.setState({
                    show:true,
                    basicTitle:"Data Saved successfully",
                    basicType:"success"
                })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Data Not Saved successfully",
                    basicType:"danger"
                })
            }

        })

    }

    render(){
        const{stackingChecked,stackingConfigs,itemStackingChecked,itemStackingConfigs,plant_locations,vehicle_configurations,item_stacking_max_trucks_check,
            max_no_of_trucks,material_configuration,stacking_plan,stacking_plan_options,loading_unloading_sequencing,stacking_3d,stacking_2d,
            fregility_index_preferences,rotation_preferences,weight_preferences,material_size_preferences}=this.state
        return(
            <div className="">
            <div class={"row col-sm-12 mt-30p"}>
                <div className="col-sm-4">
                    <label className="fw-800 mt-20per theme-clr">Enable/Disable Stacking Module</label>    
                </div>
                <div className="col-sm-2">
                    <Switch
                        checked={stackingChecked}
                        onChange={this.handleStackingChange}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor="#20749b"
                        onHandleColor="#d3dbdf"
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
                </div>
                <div class={"row col-sm-12 mt-20p "+stackingConfigs}>
                    <div className="col-sm-6 brd-right">
                        <div class={"row mt-20p"}>
                            <div className="col-sm-7 txt-center">
                                <h5>Item Stacking </h5>
                            </div>
                            <div className="col-sm-5">
                                <label htmlFor="material-switch">
                                    <span style={{"marginRight":"10px"}}>Enable/Disable</span>
                                    <Switch
                                        checked={itemStackingChecked}
                                        onChange={this.handleItemStackingChange}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={15}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={10}
                                        width={28}
                                        className="react-switch"
                                        id="material-switch"
                                    />                                                    
                                </label>
                            </div>
                        </div>
                        
                        <div class={itemStackingConfigs}>

                            <div className="row mt-30p">
                                <label className="fw-600"><input name="plant_locations" id="plant_locations" value={plant_locations} type="checkbox" checked disabled/>&nbsp;&nbsp;Pickup Locations*</label>
                            </div>

                            <div className="row">
                                <label className="fw-600"><input name="vehicle_configurations" id="vehicle_configurations" value={vehicle_configurations} type="checkbox"  checked disabled/>&nbsp;&nbsp;Plant Wise Vehicle Type / Container Configuration*</label>
                            </div>
                                <div className="row ml-3per">
                                    <label className="fw-600">
                                        <input name="item_stacking_max_trucks_check" id="item_stacking_max_trucks_check" value={item_stacking_max_trucks_check} type="checkbox" onChange={this.changeHandler} />&nbsp;&nbsp;Max No. Of Truck Types&nbsp;&nbsp;
                                    <input name="max_no_of_trucks" className="num-cls" id="max_no_of_trucks" value={max_no_of_trucks} disabled={(item_stacking_max_trucks_check == 1)?false:true} onChange={this.maxTrucksChangeHandler} type="number" min={4} style={{width:"40px"}}/></label>
                                </div>
                                
                            <div className="row">
                                <label className="fw-600"><input name="material_configuration" id="material_configuration" value={material_configuration} type="checkbox" checked disabled/>&nbsp;&nbsp;Material Configuration</label>
                            </div>
                            <div className="row ml-3per">
                                    <label className="fw-600"><input name="fregility_index_preferences" id="fregility_index_preferences" value={fregility_index_preferences} checked={(fregility_index_preferences == 1)?true:false} type="checkbox" onChange={this.changeHandler} />&nbsp;&nbsp;Fregility Index Preferences</label>
                                </div>

                            <div className="row">
                                <label className="fw-600"><input name="stacking_plan" id="stacking_plan" value={stacking_plan} type="checkbox" checked={(stacking_plan == 1)?true:false} onChange={this.changeHandler} />&nbsp;&nbsp;Stacking Plan</label>
                            </div>
                            <div class={stacking_plan_options}>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="stacking_2d" id="stacking_2d" value={stacking_2d} type="checkbox" checked={(stacking_2d == 1)?true:false} onChange={this.changeHandler} />&nbsp;&nbsp;2D Stacking</label>
                                </div>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="stacking_3d" id="stacking_3d" value={stacking_3d} type="checkbox" checked={(stacking_3d == 1)?true:false} onChange={this.changeHandler} />&nbsp;&nbsp;3D Stacking</label>
                                </div>
                                <div className="row ml-3per">
                                    <label className="fw-600"><input name="loading_unloading_sequencing" id="loading_unloading_sequencing" value={loading_unloading_sequencing} type="checkbox" checked={(loading_unloading_sequencing == 1)?true:false} onChange={this.changeHandler} />&nbsp;&nbsp;Loading & Unloading Sequencing</label>
                                </div>
                            </div>



                            <div className="row">
                                <label className="fw-600"><input name="rotation_preferences" id="rotation_preferences" value={rotation_preferences} type="checkbox" onChange={this.changeHandler} checked={(rotation_preferences == 1)?true:false}  />&nbsp;&nbsp;Rotation Preferences</label>
                            </div>
                            <div className="row">
                                <label className="fw-600"><input name="weight_preferences" id="weight_preferences" value={weight_preferences} type="checkbox" onChange={this.changeHandler} checked={(weight_preferences == 1)?true:false}  />&nbsp;&nbsp;Weight Preferences</label>
                            </div>      
                            <div className="row">
                                <label className="fw-600"><input name="material_size_preferences" id="material_size_preferences" value={material_size_preferences} type="checkbox" onChange={this.changeHandler} checked={(material_size_preferences == 1)?true:false}  />&nbsp;&nbsp;Material Size Preferences</label>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <button type="button" className="btn btn-warning float-right" onClick={this.saveItemStackingConfigs}>Save Item Stacking Configurations</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                    <CapacityStacking/>
                    </div>
                </div>
                </div>
                                        
        )
    }

}