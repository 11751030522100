import redirectURL from "../redirectURL"

export function logFormChanges (logParams, params, previousData) {

            Object.keys(previousData).forEach(key => {
                if (JSON.stringify(params[key]) !== JSON.stringify(previousData[key])) {
                    // console.log(typeof params[key],params[key])
                    if (Array.isArray(params[key])) {
                        logParams.activity[key] = `${key} changed from ${previousData[key].map(item =>item).join(',')} to ${params[key].map(item => item).join(',')}`;
                    }
                    else if (typeof params[key] === 'object' && params[key] !== null && 'value' in params[key]) {
                        logParams.activity[key] = `${key} changed from ${previousData[key].value} to ${params[key].value}`
                    }
                    else {
                        logParams.activity[key] = `${key} changed from ${previousData[key]} to ${params[key]}`;
                    }
                }
            });
            
            if (Object.keys(logParams.activity).length !== 0){
                console.log(logParams, "logParams");
                redirectURL.post("/master/logUserUpdateOrSaveDataActivities", logParams);
            }
        }

