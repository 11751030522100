import React, {Component} from 'react';

import { Navigate } from 'react-router-dom';
import GaugeChart from "./GaugeChartComponent";
import Speedometer from "./SpeedomeeterComponent";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import $ from "jquery";
import redirectURL from '../redirectURL';
import getToolConfiguration from "../optirun-admin/commonFunctions";
import { getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getHyphenDDMMYYYY,getHyphenDDMMYYYYHHMMSS } from "../common/utils";
import moment from 'moment';
import DrawMapDisptach from "./DrawmapPlan";
import SweetAlert from "react-bootstrap-sweetalert";
import DrawMapDisptachRoutes from "./drawmapDispatch";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ConfigSummary from "./configInSummary";
import * as XLSX from "xlsx";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";


export default class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true,
                minWidth: 70,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents: {
                // GridButton: GridButton,
                // RepeatIcon: RepeatIcon,
                // AssignVehicleBtn: AssignVehicleBtn,
                // NAButton: NAButton,
                // RouteButton: RouteButton,
                // UpdateVehicleBtn: UpdateVehicleBtn,
                // RevokeVehicleBtn:RevokeVehicleBtn,
                // WarningButton:WarningButton,
                // HyphenButton:HyphenButton,
                // GridCheckbox:GridCheckbox,
                // DeleteBtn:DeleteBtn,
            },
            rowData: [],
            rowSelection: "multiple",
            revokedBack: false,
            updatedPlan: false,
            rowModelhighchartoptionsType: "enterprise",
            rowGroupPanelShow: "always",
            detailCellRendererParams: {},
            statusBar: {
                statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left",
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center",
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
                ],
            },
            paginationPageSize: 100,
            detailCellRendererParams: {},
            rowClassRules: {
                //"highlitRow": "data.transit_delay == 1",
                //"green":"data.status >= 3"
            },
            sideBar: {
                toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
                ],
            },
            animateRows: true,
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            isOpen: false,
            startDate:"",
            showCalendar:'show-n',
            location:{value:"",label:""},
            rowData: [],
            revokedBack: false,
            updatedPlan: false,
            openVehicleAvailabilityModel: false,
            openFreightConfigurationModel: false,
            show: false,
            basicTitle: "",
            basicType: "default",
            showAvailablityTable: false,
            data_type: 2,
            vehicle_type: { label: "Select Vehicle Type", value: "" },
            state_name: { label: "Select State", value: "" },
            city: "",
            fixed_vehicle_cost: "",
            driver_cost: "",
            maintenance_cost: "",
            per_cmt_cost: "",
            per_km_cost: "",
            rowId: "",
            locationNames: [],
            transactions:[{value:"",label:"Select Transaction ID"}],
            vehicle_types: [],
            state_names: [],
            transactionDetails: [],
            outputDetails: [],
            inputDetails: [],
            config: "",
            plannedTrucks: "",
            unPlannedTrucks: "",
            loadsDropped: "",
            inputInfo: "",
            planSummary: "",
            configData: "show-n",
            plannedTrucksData: "show-n",
            unPlannedTrucksData: "show-n",
            loadsDroppedData: "show-n",
            inputInfoData: "show-n",
            planSummaryData: "show-n",
            vehicle_type_config: [],
            material_configuration: [],
            freight_configuration: [],
            vehicle_availability_config: [],
            plannedData: [],
            unPlannedData: [],
            loadDroppedDetails: [],
            planningColumnChart: [],
            planningPieChart: [],
            summary: "activet",
            summaryData: "show-m",
            total_demand_cmt: 0,
            total_dropped_loads: 0,
            tool_config: "",
            overlayBlock: "show-n",
            showSlideBlockUpload: "sidebarcls",
            showSlideMap: "show-n",
            showSlideMapLocations: "",
            sliderTitle: "",
            loadingLocation: [],
            mapType: 1,
            latLngArray: [],
            orderId: "",
            transactionId: "",
            current_transaction:{value:"",label:""},
            trip_gps_data: [],
            trip_gps_data_all:[],
            plant_loc_data: [],
            trip_nodes_data: [],
            viewcount: 0,
            opti_type: "",
            run_type: "",
            palletLoadLocations: [],
            sla: 1,
            planningWeightColumnChart: [],
            total_demand_weight: 0,
            chartloader1: "show-m",
            chartloader2: "show-m",
            chartloader3: "show-m",
            chartloader4: "show-m",
            showAdditionalPlannedVehicles: 1,
            locationNameInHeader: "",
            allCustomersList: [],
            allOrdersList: [],
            filterCustomer: { value: "", label: "All" },
            filterOrder: { value: "", label: "All" },
            filterPlannedTucksData: 0,
            showSlideAssignVehicle: "show-n",
            assignvehicle: { value: "", label: "Select" },
            vehicleLists: [],
            showAssignFields: "show-n",
            assignrowdata: [],
            tripinfo: [],
            assignVehicleloader: "show-n",
            planningQuantityColumnChart: [],
            selectedTrucks: "show-n",
            vehiclesSelectedData: [],
            selectedVehicle: "show-n",
            activeVehicleList: [],
            openReassignVehicleModel: false,
            shipmentIds: [],
            selectedShipmentId: "",
            forceClose_reason: "gps_issue",
            forceCloseVehicle: "",
            openUpdateVehicleModel: false,
            oldVehicleNO: "",
            oldVehTripNo: "",
            updateVehicleNo: false,
            assign_vehicle_type: "",
            assign_vehicle_list: [],
            total_customers: 0,
            plan_created_date: 0,
            total_tansit_time: 0,
            total_distance: 0,
            total_round_distance: 0,
            VFR:0,
            adherence:0,
            total_orders:0,
            planned_orders:0,
            loadshow:'show-n',
            is_reload:'',
            filteredData:[],
            allPlanSummaryData:[],
            transactionInputDetails:[],
            minVFR:0,
            mapFor:'',
            total_planned_quantity:0,
            total_given_quantity:0,
            ontimedispatchPercentage:0,
            ontimedeliveryPercentage:0,
            openSavedConfigs:false,
            configs:[],
            showExecutedTime:false,
            showPlanDetails:false,
            showConfigButtonTooltip:false,
            createdDate:"",
            current_selected_date:"",
            simulation_time:'',
            regular_vehicles:1,
            additional_vehicles:1,
            plantLocation:{},
            vehicleLocation:{},
            showVehicleMap: "sidebarcls",
            openEditVehiclePlanModel:false,
            showSlideEditVehiclePlan:'show-n',
            vehicleTypeList : [],
            dispatchPlanObdUpdateData:[],
            newObdVehicleType:{label:'Select',value:''},
            showNewPlan:false,
            editplanvfr:0,
            editplanmaxdispatch:'',
            editplanqty:0,
            editplanvehicle_volume:0,
            editplanvehicle_weight:0,
            editplanmaxvehiclevolume:0,
            editplanmaxvehicleweight:0,
            editplanloader:'show-n',
            editplantintervalid:'',
            editplanttripno:0,
            editplanuniqueid:0,
            editplantransit_type:'',
            drop_node_details:[],
            showDeleteSlider:false,
            deletePlanParams:"",
            is_sunday_planning_enabled:0,
            consolidatedTrnsxId:"",
            orderNumbersList:[],
            maxStartTime:'',
            remark:{value:'',label:'Select'},
            subReason:"",
            dedicated:0,
            spot:0,
            selectDate:"",
            latestData:[],            
            defFlag:0,
            rownode:[],
            detailsB:"show-n",
            assignTrips:0,
            order_transactionId:{value:"",label:"Select Transaction ID"},
            detailsBlk:"show-n",
            planconf:"show-n",
            summaryTabData:"show-m",
            vehAvailable:"show-n",
            unpl:"show-n",
            stab:"ptr-s-tags-active",
            vatab:"",
            ultab:"",
            actionloader:"show-n",
            orderinuts:"show-n",
            startOfTrans:{}
        };
        this.loadPageData = this.loadPageData.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCalender = this.handleCalender.bind(this);
        this.handleCalenderHide = this.handleCalenderHide.bind(this);
        this.handleTransaction = this.handleTransaction.bind(this);
        this.reloadPage = this.reloadPage.bind(this);
        this.getWeekOffColumn = this.getWeekOffColumn.bind(this);
        this.getStartingTransactionData =this.getStartingTransactionData.bind(this);
        this.getStartTransactionIDData = this.getStartTransactionIDData.bind(this);
        
    }
    componentDidMount = async () => {
        this.loadPageData();
    }
    loadPageData = async () => {
      this.setState({
        actionloader:"show-m"
      })
      try {
        window.initMapRoutes = this.initMapRoutes;
        var curl = window.location.search;
        var ul = curl.split("?");
        // console.log("ulll", ul)
        if(ul.length > 0){
          if(ul.length > 1)
          {
            let url_type = ul[parseInt(ul.length) - 1];
            var transactionId = url_type;
            let orderId;
            if (
              transactionId != undefined &&
              transactionId != "undefined" &&
              transactionId != ""
            ) {
              transactionId = window.atob(transactionId);
              // console.log('transactionId ', transactionId)
              let order = transactionId.split("-");
              orderId = order[0];
            }
            var current_transaction = {value: orderId,label: orderId}
            await this.setState({ transactionId: transactionId, orderId: orderId, current_transaction:current_transaction });
            let params = {
              transactionId: transactionId,
            };
            // await this.getChartsData(transactionId, orderId);
            this.onLoadGetConfigData(orderId)
            // console.log('hello')
            await this.getPlanDetails();
            
            await this.getPlanOutputDetails();
          }
          else{
            // console.log("S1");
            var toolParams = {
              data_type: this.state.data_type,
              };
              await getToolConfiguration(toolParams);
              // console.log("S2");
              let location_access = JSON.parse(localStorage.getItem("location_access"))
        
              let locationNames = this.state.locationNames;
        
              await redirectURL.post('master/getBoundTypeLocations',toolParams)
              .then(async (response) =>{
                  if(response.data.status == 'success'){
                      let boundLocations = response.data.boundLocations
                      // locationNames.push({ value : "",
                      //     label : "Select" })
                      if(boundLocations.length > 0){
                          boundLocations.map(item =>{
                              if(location_access != null){
                                  if(location_access.includes(item.location_code)){
                                      locationNames.push({ value : item.location_code,
                                                          label : item.location_name })
      
                                      }
                                  }else{
                                      locationNames.push({ value : item.location_code,
                                                          label : item.location_name })
                                  }
                          })
      
                          if(locationNames.length>0){
      
                              await this.setState({
                                  locationNames : locationNames,
                                  location : locationNames[0]
                              },async ()=>{
                                // console.log("locationNames[0]", locationNames[0])
                                await this.getStartingTransactionData(locationNames[0])
                                
                              })
                              
                          }else{
                              await this.setState({
                                  actionloader:"show-n",                                
                                  locationNames : [{label:'Please Add Data',value:'no_data'}],
                                  location : [{label:'Please Add Data',value:'no_data'}]
                              })
                          }
                      }
                  }
                  else{
                    this.setState({
                      actionloader:"show-n",                                
                      locationNames : [{label:'Please Add Data',value:'no_data'}],
                      location : [{label:'Please Add Data',value:'no_data'}]
                  })
                }
              })
              .catch(function (error) {
                  var errDetails={
                      url:'master/getBoundTypeLocations',
                      screen:'Summary Dashboard',
                      error,
                      
                  }
                  redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                  
              })
            //   await this.getPlanDetails();
            
            // await this.getPlanOutputDetails();
            // var orderId = this.state.current_transaction;
            // this.onLoadGetConfigData(orderId)
          }
        }
        else{
          var toolParams = {
            data_type: this.state.data_type,
            };
            await getToolConfiguration(toolParams);
            let location_access = JSON.parse(localStorage.getItem("location_access"))
      
            let locationNames = this.state.locationNames;
      
            await redirectURL.post('master/getBoundTypeLocations',toolParams)
            .then(async (response) =>{
                if(response.data.status == 'success'){
                    let boundLocations = response.data.boundLocations
                    // locationNames.push({ value : "",
                    //     label : "Select" })
                    if(boundLocations.length > 0){
                        boundLocations.map(item =>{
                            if(location_access != null){
                                if(location_access.includes(item.location_code)){
                                    locationNames.push({ value : item.location_code,
                                                        label : item.location_name })
    
                                    }
                                }else{
                                    locationNames.push({ value : item.location_code,
                                                        label : item.location_name })
                                }
                        })
    
                        if(locationNames.length>0){
    
                            await this.setState({
                                locationNames : locationNames,
                                location : locationNames[0]
                            },async ()=>{
                              // console.log("locationNames[0]", locationNames[0])
                              await this.getStartingTransactionData(locationNames[0])
                              
                            })
                            
                        }else{
                            await this.setState({
                                actionloader:"show-n",                                
                                locationNames : [{label:'Please Add Data',value:'no_data'}],
                                location : [{label:'Please Add Data',value:'no_data'}]
                            })
                        }
                    }
                }
                else{
                  this.setState({
                    actionloader:"show-n",                                
                    locationNames : [{label:'Please Add Data',value:'no_data'}],
                    location : [{label:'Please Add Data',value:'no_data'}]
                })
                }
            })
            .catch(function (error) {
                var errDetails={
                    url:'master/getBoundTypeLocations',
                    screen:'Summary Dashboard',
                    error,
                    
                }
                redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                
            })
        }
      } catch (error) { }
      await this.getDetailsOfFilteredData()
      this.enablesundayplanning()
    var params = {}
    }
    onLoadGetConfigData = async(order_id) =>{
        var configs=[]
       await redirectURL.post("/master/getorderConfigs",{order_id})
        .then( (response)=>{
          configs=response.data
        })
       await this.setState({
          configs:configs
        })
      }
    
    getChartsData = (transactionId, orderId) => {
        var transactionId = transactionId;
        let qry = JSON.parse(localStorage.getItem("qry"));
        let location = this.state.location;
        let plant_location_code = qry.plant_location_code;
        let dispatch_planning_type = qry.dispatch_planning_type;
        if(Object.keys(qry).length === 0){
        plant_location_code = location.value
        dispatch_planning_type = 2
        }
        if(this.state.orderId != undefined && this.state.orderId != ''){
        let plant = this.state.orderId.split('_')
        plant = plant[0]
        // console.log(plant,'plant') 
        plant_location_code = plant
        }
        // console.log("qry ",qry)
        this.setState({
            planningColumnChart: [],
            planningModeChart: [],
            chartloader: "show-m",
            chartloader1: "show-m",
            chartloader2: "show-m",
            chartloader3: "show-m",
            chartloader4: "show-m",
        });
        try {
        var reqparams = new FormData();
        reqparams.append("transaction_id", transactionId);
        reqparams.append("order_id", orderId);
        reqparams.append("plant_location_code", plant_location_code);
        reqparams.append("dispatch_planning_type", dispatch_planning_type);

        // redirectURL.post("http://216.48.191.229:8007/dispatch_planning/transactionLevelChartsData",reqparams)
        
        redirectURL
            .post("dispatch/dispatchplandetails", reqparams)
            .then(async (response) => {
            // console.log("apiResponse ", response.data)
            let planData = JSON.parse(response.data.planning_chart_data);
            let planWeightData = JSON.parse(
                response.data.planning_chart_weight_data
            );
            let planQuantityData = JSON.parse(
                response.data.planning_chart_quantity_data
            );
            // let dropData = JSON.parse(response.data.dropped_reason_chart_data)
            // let total_dropped_loads = dropData[0].total_dropped_loads;
            let total_demand_cmt = planData[0].total_demand_volume;
            let total_demand_weight = planWeightData[0].total_demand_weight;
            let total_quantity_data =
                planQuantityData[0].planning_chart_quantity_data;
            let planningColumnChart = [
                {
                name: "Dispatch Load Volume Summary (M3)",
                colorByPoint: true,
                data: [
                    {
                    name: "Total Loads Volume (M3)",
                    y: planData[0].total_demand_volume,
                    },
                    {
                    name: "Planned Loads Volume (M3)",
                    y: planData[0].planned_demand_volume,
                    },
                    {
                    name: "Unplanned Loads Volume (M3)",
                    y: planData[0].unplanned_demand_volume,
                    },
                    {
                    name: "Dropped Loads Volume (M3)",
                    y: planData[0].dropped_demand_volume,
                    },
                ],
                },
            ];

            let planningWeightColumnChart = [
                {
                name: "Dispatch Load Weight Summary (Kgs)",
                colorByPoint: true,
                data: [
                    {
                    name: "Total Loads Weight (Kgs)",
                    y: planWeightData[0].total_demand_weight,
                    },
                    {
                    name: "Planned Loads Weight (Kgs)",
                    y: planWeightData[0].planned_demand_weight,
                    },
                    {
                    name: "Unplanned Loads Weight (Kgs)",
                    y: planWeightData[0].unplanned_demand_weight,
                    },
                    {
                    name: "Dropped Loads Weight (Kgs)",
                    y: planWeightData[0].dropped_demand_weight,
                    },
                ],
                },
            ];

            let planningQuantityColumnChart = [
                {
                name: "Dispatch Load Quantity Summary",
                colorByPoint: true,
                data: [
                    {
                    name: "Total Quantity",
                    y: planQuantityData[0].total_quantity,
                    },
                    {
                    name: "Planned Quantity",
                    y: planQuantityData[0].planned_quantity,
                    },
                    {
                    name: "Unplanned Quantity",
                    y: planQuantityData[0].unplanned_quantity,
                    },
                    {
                    name: "Dropped Quantity",
                    y: planQuantityData[0].dropped_quantity,
                    },
                ],
                },
            ];
            //    let planningPieChart = [{
            //     name:"Dropped",
            //     colorByPoint: true,
            //     data :  [
            //         {
            //          name:"Total Excluded Passengers",
            //          y: (dropData[0].total_dropped_loads)
            //         },{
            //          name:"Invalid Locations",
            //          y: (dropData[0].invalid_locations)
            //         },
            //     //     {
            //     //     name:"Invalid Materials",
            //     //     y: (dropData[0].invalid_materials)
            //     // }
            // ]
            //    }]

            await this.setState(
                {
                planningColumnChart: planningColumnChart,
                planningWeightColumnChart: planningWeightColumnChart,
                planningQuantityColumnChart: planningQuantityColumnChart,
                // planningPieChart:planningPieChart,
                // total_dropped_loads:total_dropped_loads,
                total_demand_cmt: total_demand_cmt,
                total_demand_weight: total_demand_weight,
                total_quantity_data: total_quantity_data,
                total_planned_quantity: planQuantityData[0].planned_quantity,
                total_given_quantity: planQuantityData[0].total_quantity
                },
                async () => {
                await setTimeout(() => {
                    this.setState(
                    {
                        chartloader1: "show-n",
                    },
                    () => {
                        setTimeout(() => {
                        this.setState(
                            {
                            chartloader2: "show-n",
                            },
                            () => {
                            setTimeout(() => {
                                this.setState(
                                {
                                    chartloader3: "show-n",
                                },
                                () => {
                                    setTimeout(() => {
                                    this.setState({
                                        chartloader4: "show-n",
                                    });
                                    }, 500);
                                }
                                );
                            }, 400);
                            }
                        );
                        }, 300);
                    }
                    );
                }, 2500);
                }
            );
            })
            .catch(function (error) {
            var errDetails={
                url:"dispatch/dispatchplandetails",
                screen:'Summary Dashboard',
                error,
                
            }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
        } catch (e) { }
    };

    getPlanDetails = () => {
        let locationNameInHeader;
        let locationCode;
        var params = {
            transactionId: this.state.transactionId,
        };
        
        redirectURL
        .post("dispatch/getTransactionDetails", params)
        .then(async (response) => {
            // console.log("response.data ", response.data);

            // this.setState({ plan_created_date: response.data.created_on });
            var minVFR=response.data[0].min_VFR
            // console.log(minVFR,'MINVFR')
            if (minVFR===undefined){
            minVFR=0
            }
            this.setState({minVFR})
            var data_type = 2;

            if (response.data.length > 0) {
            try {
                data_type = response.data[0].data_type;
                // console.log(response.data,"response.data")
                var toolParams = {
                data_type: response.data[0].data_type,
                };
                await getToolConfiguration(toolParams);
                await this.boundTypeLocations(toolParams);
                var tool_config = "";
                if (
                response.data[0].tool_config != undefined &&
                response.data[0].tool_config != "undefined" &&
                response.data[0].tool_config != ""
                ) {
                tool_config = response.data[0].tool_config;
                }
                var vehicle_type_config = [];
                if (
                response.data[0].vehicle_type_config != undefined &&
                response.data[0].vehicle_type_config != "undefined" &&
                response.data[0].vehicle_type_config != ""
                ) {
                if (typeof response.data[0].vehicle_type_config == "string") {
                    vehicle_type_config = JSON.parse(
                    response.data[0].vehicle_type_config
                    );
                    locationNameInHeader = vehicle_type_config[0].location_name;
                    locationCode = vehicle_type_config[0].location_code;

                } else {
                    vehicle_type_config = response.data[0].vehicle_type_config;
                    locationNameInHeader = vehicle_type_config[0].location_name;
                    locationCode = vehicle_type_config[0].location_code;
                }
                }
                var material_configuration = [];
                if (
                response.data[0].material_configuration != undefined &&
                response.data[0].material_configuration != "undefined" &&
                response.data[0].material_configuration != ""
                ) {
                if (typeof response.data[0].material_configuration == "string") {
                    material_configuration = JSON.parse(
                    response.data[0].material_configuration
                    );
                } else {
                    material_configuration =
                    response.data[0].material_configuration;
                }
                }
                var freight_configuration = [];
                if (
                response.data[0].freight_configuration != undefined &&
                response.data[0].freight_configuration != "undefined" &&
                response.data[0].freight_configuration != ""
                ) {
                if (typeof response.data[0].freight_configuration == "string") {
                    freight_configuration = JSON.parse(
                    response.data[0].freight_configuration
                    );
                } else {
                    freight_configuration = response.data[0].freight_configuration;
                }
                }
                var vehicle_availability_config = [];
                if (
                response.data[0].vehicle_availability_config != undefined &&
                response.data[0].vehicle_availability_config != "undefined" &&
                response.data[0].vehicle_availability_config != ""
                ) {
                if (
                    typeof response.data[0].vehicle_availability_config == "string"
                ) {
                    vehicle_availability_config = JSON.parse(
                    response.data[0].vehicle_availability_config
                    );
                } else {
                    vehicle_availability_config =
                    response.data[0].vehicle_availability_config;
                }
                }
                var opti_type = "";
                if (
                response.data[0].opti_type == 0 ||
                response.data[0].opti_type == "0"
                ) {
                opti_type = "(Optimize by Distance)";
                } else {
                opti_type = "(Optimize by Time)";
                }
                var run_type = "";
                if (
                response.data[0].run_with_transit == 1 ||
                response.data[0].run_with_transit == "1"
                ) {
                run_type = "(Transit Time With Multiple City Clubing)";
                }
                if (
                response.data[0].run_with_RDD == 1 ||
                response.data[0].run_with_RDD == "1"
                ) {
                run_type = "(RDD Preference)";
                }
                if (
                response.data[0].run_with_transit_cityclub == 1 ||
                response.data[0].run_with_transit_cityclub == "1"
                ) {
                run_type = "(Transit Time With Single City Clubing)";
                }
                if (
                response.data[0].days_of_runs != "" &&
                response.data[0].days_of_runs != undefined
                ) {
                var days_of_runs = response.data.days_of_runs;
                } else {
                var days_of_runs = 1;
                }

                var gpsVehiclesSelectedData = [];
                if (
                response.data[0].gpsVehiclesSelectedData !== undefined &&
                response.data[0].gpsVehiclesSelectedData !== "undefined" &&
                response.data[0].gpsVehiclesSelectedData !== ""
                ) {
                if (typeof response.data[0].gpsVehiclesSelectedData == "string") {
                    gpsVehiclesSelectedData = JSON.parse(
                    response.data[0].gpsVehiclesSelectedData
                    );
                }
                }
                var currentAvailableVehiclesData=response.data[0].currentAvailableVehicles
                var no_of_spot_vehicles_givenData=response.data[0].no_of_spot_vehicles_given
                // console.log(currentAvailableVehiclesData)
                // console.log(no_of_spot_vehicles_givenData)
                if (
                currentAvailableVehiclesData !== undefined &&
                currentAvailableVehiclesData !== "undefined" &&
                currentAvailableVehiclesData !== ""
                ) {
                
                if (typeof currentAvailableVehiclesData == "string") {
                    currentAvailableVehiclesData = JSON.parse(
                    response.data[0].currentAvailableVehicles
                    );
                    }
                // currentAvailableVehiclesData.map((item)=>{
                //   item.vehicle_next_available_date=getDDMMMYYYYHHMMDefault(item.vehicle_next_available_date)

                //   })
                } 
                if (
                response.data[0].no_of_spot_vehicles_given !== undefined &&
                response.data[0].no_of_spot_vehicles_given !== "undefined" &&
                response.data[0].no_of_spot_vehicles_given !== ""
                ) {
                
                if (typeof response.data[0].no_of_spot_vehicles_given == "string") {
                    no_of_spot_vehicles_givenData = JSON.parse(
                    response.data[0].no_of_spot_vehicles_given
                    );
                    }
                no_of_spot_vehicles_givenData.map((item)=>{
                    item.vehicle_type=item.vehicle_type.label
                    // item.vehicle_next_available_date=getDDMMMYYYYHHMMDefault(item.available_date_time)
                    item.vehicle_no='spot'
                    item.location_name=locationNameInHeader
                    item.location_code=this.state.transactionId.split('_')[0]
                    item.max_distance_per_day='N/A'
                })
                }
                let is_reload = '';
                if(response.data[0].select_reload == 1){
                    is_reload = 'Reload Plan'
                }
                var vehiclesSelectedData=[]
            
                if (
                currentAvailableVehiclesData !== undefined &&
                currentAvailableVehiclesData !== "undefined" &&
                currentAvailableVehiclesData !== ""
                ) {
                
                vehiclesSelectedData=[...currentAvailableVehiclesData]
                }if (
                no_of_spot_vehicles_givenData !== undefined &&
                no_of_spot_vehicles_givenData !== "undefined" &&
                no_of_spot_vehicles_givenData !== ""
                ) {
                vehiclesSelectedData=[...vehiclesSelectedData,...no_of_spot_vehicles_givenData]
                }

                if(gpsVehiclesSelectedData.length>0 && currentAvailableVehiclesData.length===0){
                vehiclesSelectedData=[...gpsVehiclesSelectedData,...vehiclesSelectedData]
                }
                var total_vehicles=response.data[0].total_vehicles
                if (
                total_vehicles !== undefined &&
                total_vehicles !== "undefined" &&
                total_vehicles !== ""
                ) {
                
                if (typeof total_vehicles == "string") {
                    total_vehicles = JSON.parse(
                    response.data[0].total_vehicles
                    );
                    locationNameInHeader = total_vehicles[0].location_name;
                    locationCode = total_vehicles[0].location_code;
                    }
                // currentAvailableVehiclesData.map((item)=>{
                //   item.vehicle_next_available_date=getDDMMMYYYYHHMMDefault(item.vehicle_next_available_date)

                //   })
                }else{
                total_vehicles = vehiclesSelectedData
                }
                // console.log(gpsVehiclesSelectedData,'hellorrr')
                // vehiclesSelectedData=[...vehiclesSelectedData,...gpsVehiclesSelectedData]
                // console.log(vehiclesSelectedData,'gpsVehiclesSelectedData',total_vehicles)
                var date = response.data[0].createDate
                let createDate = getHyphenDDMMMYYYYHHMM(date)
                var current_selected_date = getHyphenYYYYMMDD(date)
                var selectDate = getHyphenDDMMYYYY(date);
                // var d = date.split("T")
                // var createDate = d[0]
                // var time = d[1].split(":").slice(0,2).join(":")
                // createDate +=(`  ${time}`)

                let drop_node_details
                if(response.data[0].drop_node_details !== undefined &&
                response.data[0].drop_node_details !== "undefined" &&
                response.data[0].drop_node_details !== ""){
                    drop_node_details = response.data[0].drop_node_details
                }
                // console.log("response.data[0].order_id", response.data[0].order_id)
                await this.setState({
                    transactionDetails: response.data,
                    createdDate:createDate,
                    selectDate:selectDate,
                    current_selected_date:current_selected_date,
                    sla: days_of_runs,
                    data_type: data_type,
                    opti_type: opti_type,
                    tool_config: tool_config,
                    vehicle_type_config: vehicle_type_config,
                    material_configuration: material_configuration,
                    freight_configuration: freight_configuration,
                    vehicle_availability_config: vehicle_availability_config,
                    orderId: response.data[0].order_id,
                    current_transaction: {value:response.data[0].order_id,label:response.data[0].order_id},
                    locationNameInHeader,
                    run_type: run_type,
                    vehiclesSelectedData:total_vehicles,
                    is_reload:is_reload,
                    location:{label:locationNameInHeader,value:locationCode},
                    simulation_time:response.data[0].simulation_time,
                    drop_node_details:drop_node_details,
                    actionloader:"show-n"
                });
                // this.locationChanged({label:locationNameInHeader,value:locationCode});
                var p = {
                data_type: response.data[0].data_type,
                location_code: response.data[0].location_code,
                };
            
                this.getVehiclesData(p);
                // this.getAssignVehiclesNearPlant(p);
            } catch (e) { }
            }
            else{
              
              this.setState({
                actionloader:"show-n"
              })
            }
            await setTimeout(async () => {
            await this.getPlanInputDetails();
            }, 500);
        })
        .catch(function (error) {
            var errDetails={
            url:"dispatch/getTransactionDetails",
            screen:'Summary Dashboard',
            error,
            
        }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
    };
    
  getVehiclesData(params) {
   
    redirectURL
      .post("/master/getMasterAvailabilityVehiclesData", params)
      .then(async(response) => {
        if (response.data.status === "success") {
          var vehiclesData = response.data.masterVehicleAvailabilityData;
          vehiclesData=vehiclesData.filter((item)=>item.vehicle_breakdown!==1)
          // console.log(vehiclesData)
          var vehicleLists = [];
          var filteredVehicles = [];
          let vehicleTypeList = [];
          filteredVehicles = vehiclesData;
          if (filteredVehicles.length > 0) {
            vehicleTypeList = [...new Set(filteredVehicles.map(obj => obj.vehicle_type))]
                                      .map(vehicle_type => ({ label: vehicle_type, value: vehicle_type }));
            filteredVehicles.map((item) => {
              vehicleLists.push({
                value: item.vehicle_no,
                label: item.vehicle_no,
                type: item.vehicle_type,
              });
            });
          }
          
            vehicleLists.push({ value: "0", label: "Other",type:'others' });
            await this.setState({
                vehicleLists: vehicleLists,
                vehicleTypeList:vehicleTypeList
            });
        }
      })
      .catch(function (error) {
        var errDetails={
          url:"/master/getMasterAvailabilityVehiclesData",
          error,
          screen:'Summary Dashboard'
      }
        redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        
    })
    
  }

    
    getPlanOutputDetails = () => {
        var params = {
          transactionId: this.state.transactionId,
        };
        
        redirectURL
        .post("dispatch/getTransactionOutputDetails", params)
        .then(async (response) => {
            var outputDetails = response.data;
            // console.log("outputDetails ", outputDetails)
            var consolidatedTrnsxId = outputDetails[0].consolidatedTrnsxId
            this.setState({
            consolidatedTrnsxId,
            })
            var plannedData = [];
            var unPlannedData = [];
            var allCustomersList = [];
            var allOrdersList = [];
            // console.log(outputDetails,"outputDetails")
            if (outputDetails.length > 0) {
            var plannedIndividualData = await outputDetails.filter(
                (od) => od.is_planned == 1
            );
            if (plannedIndividualData.length > 0) {
                try {
                plannedIndividualData = plannedIndividualData.sort(
                    GetSortAscOrder("trip_no")
                );
                var groupPlannedData = groupBy(
                    plannedIndividualData,
                    (rdata) => rdata.trip_no
                );
                // groupPlannedData1.forEach((plannedVehicleData1, key) =>{
                //     plannedVehicleData1 = plannedVehicleData1.sort(GetSortAscOrder("trip_no"));
                //     var groupPlannedData = groupBy(plannedVehicleData1, rdata => rdata.trip_no);
                // console.log("groupPlannedData ", groupPlannedData)
                groupPlannedData.forEach((plannedVehicleData, k) => {
                    var demand_volume = 0;
                    var demand_weight = 0;
                    var demand_quantity = 0;
                    var distance = 0;
                    var triptime = 0;
                    var transitTime = 0;
                    var unLoadingTime = [];
                    var loadingTime = [];
                    var totaltires = 0;
                    var latLngArray = [];
                    var customers = [];
                    plannedVehicleData.map((pvd) => {
                    // console.log('pve,',pvd)
                    allCustomersList.push({
                        customer_code: pvd.drop_node,
                        customer_name: pvd.drop_node_name,
                    });
                    allOrdersList.push({ orderNo: pvd.order_number });

                    demand_volume =
                        parseFloat(demand_volume) + parseFloat(pvd.demand_volume);
                    demand_weight =
                        parseFloat(demand_weight) + parseFloat(pvd.demand_weight);
                    demand_quantity =
                        parseInt(demand_quantity) + parseInt(pvd.quantity);
                    distance = pvd.distance;
                    triptime = pvd.drop_node_total_time_hrs;
                    // transitTime = pvd.drop_node_transit_time_hrs;
                    loadingTime.push(pvd.drop_node_loading_time_mins);
                    unLoadingTime.push(pvd.drop_node_unloading_time_mins);
                    latLngArray.push({
                        lat: pvd.demand_node_latitude,
                        lng: pvd.demand_node_longitude,
                        drop_node: pvd.drop_node,
                        vehicle_type: pvd.vehicle_type,
                        demand_volume: pvd.demand_volume,
                        demand_weight: pvd.demand_weight,
                    });
                    totaltires = parseInt(totaltires) + parseInt(pvd.no_of_tires);
                    customers.push({ customer: pvd.drop_node });
                    });
                    var customerArray = [];
                    let totuniqueObject = {};
                    var totobjTitle;
                    loadingTime = addTimes(loadingTime);
                    unLoadingTime = addTimes(unLoadingTime);
                    // Loop for the array elements
                    for (let i in customers) {
                    // Extract the title
                    if (
                        customers[i]["customer"] !== "" &&
                        customers[i]["customer"] !== undefined
                    ) {
                        totobjTitle = customers[i]["customer"];

                        // Use the title as the index
                        totuniqueObject[totobjTitle] = customers[i];
                    }
                    }
                    // Loop to push unique object into array
                    for (var a in totuniqueObject) {
                    customerArray.push(totuniqueObject[a]);
                    }
                    // console.log("customerArray ", customerArray)
                    plannedData.push({
                    vehicle_no: plannedVehicleData[0].vehicle_no,
                    cluster: plannedVehicleData[0].cluster,
                    zone: plannedVehicleData[0].zone,
                    pickup_node: plannedVehicleData[0].pickup_node,
                    drop_node: plannedVehicleData[0].drop_node,
                    total_demand: parseFloat(demand_volume).toFixed(2),
                    total_weight: parseFloat(demand_weight).toFixed(2),
                    total_quantity: parseInt(demand_quantity),
                    distance: distance,
                    triptime: triptime,
                    route_no: plannedVehicleData[0].route_no,
                    is_available: plannedVehicleData[0].is_available,
                    vehicle_type: plannedVehicleData[0].vehicle_type,
                    vehicle_start_time_mins:
                        plannedVehicleData[0].vehicle_start_time_mins,
                    vehicle_loading_start_time:
                        plannedVehicleData[0].vehicle_loading_start_time,
                    vehicle_volume: plannedVehicleData[0].vehicle_volume,
                    vehicle_priority: plannedVehicleData[0].vehicle_priority,
                    transporter_code: plannedVehicleData[0].transporter_code,
                    transporter_name: plannedVehicleData[0].transporter_name,
                    route_mode: plannedVehicleData[0].route_mode,
                    order_date: plannedVehicleData[0].order_date,
                    no_of_tires: totaltires,
                    no_of_drops: plannedVehicleData.length,
                    no_of_customer: customerArray.length,
                    legs_data: plannedVehicleData,
                    latLngArray: latLngArray,
                    trip_no: plannedVehicleData[0].trip_no,
                    vehicle_max_volume: plannedVehicleData[0].vehicle_max_volume,
                    vehicle_max_weight: plannedVehicleData[0].vehicle_max_weight,
                    order_number: plannedVehicleData[0].order_number,
                    invoicing_date: plannedVehicleData[0].invoicing_date,
                    quantity: plannedVehicleData[0].quantity,
                    round_trip_distance:
                        plannedVehicleData[0].round_trip_distance,
                    arrival_time_at_first_dealer:
                        plannedVehicleData[0].arrival_time_at_first_dealer,
                    last_dealer_time_to_complete:
                        plannedVehicleData[0].last_dealer_time_to_complete,
                    return_time_to_plant_from_last_dealer:
                        plannedVehicleData[0].return_time_to_plant_from_last_dealer,
                    round_trip_time: plannedVehicleData[0].round_trip_time,
                    total_drop_node_transit_time_hrs:
                        plannedVehicleData[0].total_drop_node_transit_time_hrs,
                    // transitTime: transitTime,
                    total_plan_time_hrs:
                        plannedVehicleData[0].total_plan_time_hrs,
                    // loadingTime:loadingTime,
                    // unLoadingTime:unLoadingTime
                    loadingTime: plannedVehicleData[0].total_loading_time,
                    unLoadingTime: plannedVehicleData[0].total_unloading_time,
                    route_finalized: plannedVehicleData[0].route_finalized,
                    max_start_time: plannedVehicleData[0].max_start_time,
                    cost:plannedVehicleData[0].cost,
                    is_vfr_violated:plannedVehicleData[0].is_vfr_violated,
                    vehicle_transit_type:plannedVehicleData[0].vehicle_transit_type,
                    is_addremove_plan:plannedVehicleData[0].is_addremove_plan
                    });
                    //console.log(plannedData, "planned data");

                    var total_customers = 0;
                    var total_turn_time = 0;
                    var transit_time_min = 0
                    var transit_time_hrs = 0
                    var total_distance = 0
                    var total_round_distance = 0
                    var total_planned_orders=0
                    var weight_utilization=0, volume_utilization=0,finalVfr=0 
                    var spotvfr = 0;
                    var dedicatedvfr = 0;
                    var assigneddata=[];
                    var unwantedData = ['Not Assigned', 'Not Available'];
                    plannedData.map((item) => {
                        total_planned_orders+=item.no_of_drops;
                        total_customers += item.no_of_customer;
                        total_turn_time += item.round_trip_time;
                        var time_veh = item.total_drop_node_transit_time_hrs.split(':')
                        transit_time_hrs += parseInt(time_veh[0])
                        transit_time_min += parseInt(time_veh[1])
                        total_distance += item.distance
                        total_round_distance += parseFloat(item.round_trip_distance)
                        weight_utilization=parseFloat((
                            (parseFloat(item.total_weight) / parseFloat(item.vehicle_max_weight)) *
                            100
                        ))
                        volume_utilization= parseFloat((
                            (parseFloat(item.total_demand) / parseFloat(item.vehicle_max_volume)) *
                            100
                        ))
                        if(weight_utilization>volume_utilization){
                            finalVfr+=weight_utilization
                        }else{
                            finalVfr+=volume_utilization
                        }
                        
                        if(item.is_available === 0)
                        {
                            if(weight_utilization>volume_utilization){
                                spotvfr+=weight_utilization
                            }else{
                                spotvfr+=volume_utilization
                            }
                        }
                        
                        if(item.is_available === 1)
                        {
                            if(weight_utilization>volume_utilization){
                                dedicatedvfr+=weight_utilization
                            }else{
                                dedicatedvfr+=volume_utilization
                            }
                        }
                        if(unwantedData.indexOf(item.vehicle_no) < 0)
                        {
                            assigneddata.push(item)   
                        }
                    });

                    if (transit_time_min > 60) {
                    transit_time_hrs += parseInt(transit_time_min / 60)
                    transit_time_min -= (parseInt(transit_time_min / 60) * 60)
                    }

                    var planned_data_len = plannedData.length;
                    var VFR=(finalVfr/planned_data_len).toFixed(2)
                    var spot=(spotvfr/planned_data_len).toFixed(2)
                    var dedicated=(dedicatedvfr/planned_data_len).toFixed(2)
                    if (transit_time_hrs<10){
                    transit_time_hrs='0'+transit_time_hrs
                    }
                    if (transit_time_min<10){
                    transit_time_min='0'+transit_time_min
                    }
                    this.setState({
                        total_tansit_time: transit_time_hrs + ':' + transit_time_min,
                        total_distance: total_distance,
                        total_round_distance: parseInt(total_round_distance),
                        VFR,
                        spot,
                        dedicated,
                        planned_orders:total_planned_orders,
                        total_customers,
                        assignTrips:assigneddata.length,
                        actionloader:"show-n"
                    // adherence:((total_planned_orders/total_orders)*100).toFixed(2)
                    });
                });
                // });
                } catch (error) {
                // console.log(error, "errer");
                }
            }
            else{
              this.setState({
                actionloader:"show-n"
              })
            }
            unPlannedData = await outputDetails.filter(
                (od) => od.is_planned != 1
            );

            var customerlistsdata = [{ value: "", label: "All" }];
            if (allCustomersList.length > 0) {
                var customernewArray = [];

                let totuniqueObject = {};
                var totobjTitle;

                for (let i in allCustomersList) {
                if (
                    allCustomersList[i]["customer_code"] !== "" &&
                    allCustomersList[i]["customer_code"] !== undefined
                ) {
                    totobjTitle = allCustomersList[i]["customer_code"];
                    totuniqueObject[totobjTitle] = allCustomersList[i];
                }
                }
                for (var a in totuniqueObject) {
                customernewArray.push(totuniqueObject[a]);
                }
                if (customernewArray.length > 0) {
                customernewArray.map((item) => {
                    customerlistsdata.push({
                    value: item.customer_code,
                    label: item.customer_code + " - " + item.customer_name,
                    });
                });
                }
            }

            var orderlistsdata = [{ value: "", label: "All" }];
            if (allOrdersList.length > 0) {
                var ordernewArray = [];

                let totuniqueObject = {};
                var totobjTitle;

                for (let i in allOrdersList) {
                if (
                    allOrdersList[i]["orderNo"] !== "" &&
                    allOrdersList[i]["orderNo"] !== undefined
                ) {
                    totobjTitle = allOrdersList[i]["orderNo"];
                    totuniqueObject[totobjTitle] = allOrdersList[i];
                }
                }
                for (var a in totuniqueObject) {
                ordernewArray.push(totuniqueObject[a]);
                }
                if (ordernewArray.length > 0) {
                ordernewArray.map((item) => {
                    orderlistsdata.push({
                    value: item.orderNo,
                    label: item.orderNo,
                    });
                });
                }
            }
            }

            this.setState({
                outputDetails: response.data,
                plannedData: plannedData,
                unPlannedData: unPlannedData,
                allCustomersList: customerlistsdata,
                allOrdersList: orderlistsdata,
                filteredData: plannedData
            });        
            var onTimeDispatchCount=0;
            var onTimeDeliveryCount=0;

            var ontimedeliveryPercentage;
            var ontimedispatchPercentage;

            if (response.data[0].ontime_delivery === undefined) {
            this.setState({ontimedeliveryPercentage: "N/A"});
            this.setState({ontimedispatchPercentage : "N/A"})
            }
            else if (response.data[0].ontime_dispatch !== undefined){          
            response.data.map(each => {
                if (each.ontime_dispatch === 1) {
                    onTimeDispatchCount++;
                }            
                if (each.ontime_delivery === 1) {
                    onTimeDeliveryCount++;            }
            });
            ontimedispatchPercentage = ((onTimeDispatchCount/response.data.length)*100).toFixed(2) 
            ontimedeliveryPercentage = ((onTimeDeliveryCount/response.data.length)*100).toFixed(2) 

            this.setState({ ontimedispatchPercentage,ontimedeliveryPercentage })
            }     
            // console.log(onTimeDeliveryCount, "onTimeDeliveryCount")
            // console.log(onTimeDispatchCount,"ontimedispatchcount")   
        })
        .catch(function (error) {
            var errDetails={
            url:"dispatch/getTransactionOutputDetails",
            screen:'Summary Dashboard',
            error,
            
        }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
    };

    getPlanInputDetails = () => {
        var params = {
        // transactionId : this.state.transactionId
        order_id: this.state.orderId,
        transaction_id: this.state.transactionId,
        };
        
        redirectURL
        .post("dispatch/getTransactionRequestInputDetails", params)

        .then(async (response) => {
            // console.log(response.data[0].created_on.split("T")[0]);
            var [year, mon, day] = response.data[0].created_on
            .split("T")[0]
            .split("-");

            var [hr, min] = response.data[0].created_on.split("T")[1].split(":");
            var date = day + "-" + mon + "-" + year + " " + hr + ":" + min;
            this.setState({
            created_on: date,
            });
            var inputDetails = response.data;
            // await this.setState({i : response.data })
            // console.log(inputDetails)

            // console.log(inputDetails.length)
            var validInputDetails = [];
            var invalidInputDetails = [];
            if (inputDetails.length > 0) {
            validInputDetails = await inputDetails.filter(
                (od) => od.invalid_data != 1
            );
            invalidInputDetails = await inputDetails.filter(
                (od) => od.invalid_data == 1 || od.is_qty_less == 1
            );
            }

            var orderNumbersList =[] ;
        validInputDetails.map(each =>{
        orderNumbersList.push(each.order_number)
        })
        // console.log(orderNumbersList,"viewdispatch order numbers before render")
            
        var total_orders=inputDetails.length
    // console.log(total_orders)
            await this.setState({
              orderNumbersList:orderNumbersList,
              inputDetails: validInputDetails,
              loadDroppedDetails: invalidInputDetails,
              total_orders
            
            },()=>{
              this.getWeekOffColumn()
            });
        })
        .catch(function (error) {
            var errDetails={
            url:"dispatch/getTransactionRequestInputDetails",
            screen:'Summary Dashboard',
            error,
            
        }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
    };

  
    getDetailsOfFilteredData=async()=>{
    // console.log('HIT')
  
   let filteredData = [];
   let allPlanSummaryData = [];
   
   let data = this.state.plannedData;
  //  console.log(data,'data')
   data.map((item) => allPlanSummaryData.push(...item.legs_data));
  //  console.log(this.state.filterPlannedTucksData,'filterd 1')
   if (this.state.filterPlannedTucksData == 1) {
     let customer_code = this.state.filterCustomer.value;
     let orderNo = this.state.filterOrder.value;
     
     if (customer_code != "" && orderNo != "") {
       filteredData = data.filter((item) =>
         item.legs_data.some(
           (drops) =>
             drops.drop_node == customer_code && drops.order_number == orderNo
         )
       );
      //  console.log(filteredData, "new hit here");
     } else if (customer_code != "") {
       filteredData = data.filter((item) =>
         item.legs_data.some((drops) => drops.drop_node == customer_code)
       );
     } else if (orderNo != "") {
       filteredData = data.filter((item) =>
         item.legs_data.some((drops) => drops.order_number == orderNo)
       );
     }
   } else {
     filteredData = this.state.plannedData;
   }
  //  console.log(filteredData)
  await this.setState({allPlanSummaryData,filteredData})
    }
    
    enablesundayplanning = () =>{
        
    const currentDate = new Date(this.state.editplanmaxdispatch);
   
    const currentDay = currentDate.getDay();

    const currentHour = currentDate.getHours();
    
    const enableCheckbox =
    (currentDay === 6 && currentHour >= 16) || 
    (currentDay === 0 && currentHour < 16); 
    
    if (enableCheckbox) {
        this.setState({
            enable_sunday_planning:true
        })
    } else {
        this.setState({
            enable_sunday_planning:false,
            is_sunday_planning_enabled: 0,
        })
    }

    }

    
    boundTypeLocations = async(param) => {
        let location_access = JSON.parse(localStorage.getItem("location_access"))
        
        let locationNames = this.state.locationNames;
        
        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  // locationNames.push({ value : "",
                  //     label : "Select" })
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                          if(location_access != null){
                              if(location_access.includes(item.location_code)){
                                  locationNames.push({ value : item.location_code,
                                                      label : item.location_name })
  
                                  }
                              }else{
                                  locationNames.push({ value : item.location_code,
                                                      label : item.location_name })
                              }
                      })
  
                      if(locationNames.length>0){
  
                          await this.setState({
                              locationNames : locationNames,
                              location : locationNames[0]
                          })
                      }else{
                          await this.setState({
                              locationNames : [{label:'Please Add Data',value:'no_data'}],
                              location : [{label:'Please Add Data',value:'no_data'}]
                          })
                      }
                  }
              }
          })
          .catch(function (error) {
              var errDetails={
                  url:'master/getBoundTypeLocations',
                  screen:'Summary Dashboard',
                  error,
                  
              }
              redirectURL.post("/master/logErrorsForApiCalls",errDetails)
              
          })
      
          
      }

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
    handleCalender = () => this.setState({ showCalendar: 'show-m' });
    handleCalenderHide = () => this.setState({ showCalendar: 'show-n' });
    handleChange(date) {
        // console.log("selected Date",new Date(date))
        // console.log("Using Moment", moment.parseZone(new Date(date)).format("YYYY-MM-DD HH:mm:ss"))
        var cdate = getHyphenDDMMMYYYYHHMM(date)
        var selectDate = getHyphenDDMMYYYY(date)
        // console.log(cdate,"cdate")
        this.setState({
            startDate: date,
            showCalendar:"show-n",
            createdDate:cdate,
            selectDate:selectDate,
            transactions:[{value:"",label:"Select Transaction ID"}],
            current_transaction:[{value:"",label:"Select Transaction ID"}]
        },()=>{
            // console.log("this.state.transactionId ", this.state.transactionId)
            // this.reloadPage(this.state.transactionId);
            this.locationChanged(this.state.location)
        });
    }
    handleTransaction(transactionid){

      // console.log(this.props);
        // console.log(transactionid,"transactionid")
        this.setState({
            order_transactionId:transactionid,
            current_transaction:transactionid,
            actionloader:"show-m"
        },()=>{
            // this.loadPageData()
           this.getStartTransactionIDData()
            // this.getTransactionIDData();
        })
    }
    getStartTransactionIDData(){
      
      var iparams = {
          order_id : this.state.order_transactionId.value,
          generateDate:this.state.selectDate
      }
      // console.log("iparams ", iparams)
      redirectURL.post("/dispatch/getPlanRunHistory",iparams).then(async (response) =>{
          // console.log("GetPlans",response.data)							
          var plan = response.data;
         
          var tid = '';
          if(plan.length > 0)
          {
              tid = plan[0].transaction_id;
              this.setState({
                  transactionId:tid,
                  actionloader:"show-n"
              },async ()=>{
                await this.getPlanDetails();
                await this.getPlanOutputDetails();
                
              })    
          }
          else{
            this.setState({
                show:true,
                basicTitle:"No Plan exists",
                basicType:"info",
                actionloader:"show-n"
            })
          }
                            
          // console.log("da", tid)
          // this.reloadPage(tid);
      })
  }
    getTransactionIDData(){
        var iparams = {
            order_id : this.state.order_transactionId.value,
            generateDate:this.state.selectDate
        }
        console.log("iparams ", iparams)
        redirectURL.post("/dispatch/getPlanRunHistory",iparams).then(async (response) =>{
            // console.log("GetPlans",response.data)							
            var plan = response.data;
           
            var tid = '';
            if(plan.length > 0)
            {
                tid = plan[0].transaction_id;
                this.setState({
                    transactionId:tid
                })    
            }
            // console.log("da", tid)
            this.reloadPage(tid);
        })
    }
    showRowData(oVal, rowid){
       
        $(".hid_"+oVal).show();
        $(".opt_"+oVal).hide();
        $(".optless_"+oVal).show();

    }
    viewMapRoutes(rowid){
        // console.log(rowid,"rowid")
        var data = this.state.filteredData;
        var rownode = []
        if(data.length > 0){
            rownode = data[rowid];
        }
        this.setState({rownode:[rownode],detailsB:"show-m"});
        // console.log("rownode",rownode);
        this.onRouteData(rownode)
        // $(".hid_"+oVal).show();
        // $(".opt_"+oVal).hide();
        // $(".optless_"+oVal).show();

    }
    viewDetailsData(rowid){
        // console.log(rowid,"rowid")
        var data = this.state.filteredData;
        var rownode = []
        if(data.length > 0){
            rownode = data[rowid];
        }
        // console.log("Details ", rownode)
        this.setState({rownode:[rownode],detailsBlk:"show-m"});
        
    }
    closeDetails(){
        this.setState({
            rownode:[],
            detailsB:"show-n",
            detailsBlk:"show-n",
            showSlideMap:"show-n",
            showSlideEditVehiclePlan:"show-n",
            showSlideAssignVehicle:"show-n",
            planconf:"show-n",
            actionloader:"show-n",
            orderinuts:"show-n"
        });
    }
    hideRowData(oVal){
        $(".hid_"+oVal).hide();
        $(".opt_"+oVal).show();
        $(".optless_"+oVal).hide();
    }

    getStartingTransactionData = async(location) =>{
        
      await this.setState({
          location : location,
         // transactions:[{value:"",label:"Select Transaction ID"}],
          // current_transaction:[{value:"",label:"Select Transaction ID"}]
      },()=>{
          // console.log("location", this.state.location)
          if(this.state.location.value !== "" && this.state.location.value !== undefined){
              try {
                  var selectDate = this.state.selectDate;
                      var params = {
                          data_type : this.state.data_type,
                          location_code : this.state.location.value,
                          selectDate:selectDate,
                          useremail: localStorage.getItem('email'),
                          client : localStorage.getItem('client'),
                          userType : localStorage.getItem('user_type'),
                          generateDate:selectDate
                      }
                      // console.log("params",params)
                  // }
                  
              } catch (error) {
                  
              }
              
              redirectURL.post('dispatch/getDispatchPlanData',params)
              .then(async response =>{
                  try {
                      var records = response.data;
                      if(records.length > 0)
                      {
                            
                          var rowData = records.sort(GetSortDescOrder("order_date"));
                          var latestData = [];
                          var otherData = [];
                          var rowDataarr = [];
                          var trans = [{value:"",label:"Select Transaction ID"}]
                                  // console.log("rowData",rowData)
                          if(rowData.length > 0)
                          {
                              var latestData = await rowData.filter((rd) => rd.latest_run == 1);
                              var otherData = await rowData.filter((rd) => (rd.latest_run == 0 || rd.latest_run == undefined));
                              rowData.map((item) => {
                                  var converoDate = moment.parseZone(item.order_date).format("x");
                                  item.orderpos = converoDate;
                                  rowDataarr.push(item)
                                  trans.push({value:item.order_id,label:item.order_id+" - "+item.useremail+ " - "+item.simulation_time})
                              })
                              
                          }
                          // console.log("trans ", trans)
                          // if(rowDataarr.length > 0)
                          // {
                          //     rowDataarr = rowDataarr.sort(GetSortDescOrder("orderpos"));
                          // }
                          // if(latestData.length>0){
                          //     this.getPendingTransactions(latestData)
                          // }
                          this.setState({
                              latestData:latestData,
                              rowData:otherData,
                              loadshow:'show-n',
                              overly:'show-n',
                              overlayBlock:"show-n",
                              defFlag:1,
                              transactions:trans,
                              startOfTrans:trans[1]

                          },async ()=>{
                            var defTrans = this.state.startOfTrans;
                            // console.log("defTrans", defTrans)
                            var current_transaction = {value: defTrans.value,label: defTrans.value}
                            // console.log("current_transaction", current_transaction)
                            // order_transactionId
                            await this.setState({ 
                              // transactionId: transactionId, 
                              orderId: defTrans.value, 
                              current_transaction:current_transaction, 
                              order_transactionId:current_transaction 
                            },async ()=>{
                              
                              this.onLoadGetConfigData(defTrans.value)
                              // console.log('hello')
                                
                              this.getStartTransactionIDData();
                            },1000);

                          })
                      }
                      else{
                          this.setState({
                              rowData:[],
                              loadshow:'show-n',
                              overly:'show-n',
                              overlayBlock:"show-n",
                              defFlag:1
                          })
                      }
                  } catch (error) {
                      
                  }
                  
              })
              .catch(function (error) {
                  var errDetails={
                      url:'dispatch/getDispatchPlanData',
                      screen:'Summary Dashboard',
                      error,
                      
                  }
                  redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                  
              })
          }
      })
      // await this.getDispatchPlanData()
      await getToolConfiguration({plant_code:location.value})
  }
    locationChanged = async(location) =>{
        
        await this.setState({
            location : location,
           // transactions:[{value:"",label:"Select Transaction ID"}],
            // current_transaction:[{value:"",label:"Select Transaction ID"}]
        },()=>{
            // console.log("location", this.state.location)
            if(this.state.location.value !== "" && this.state.location.value !== undefined){
                try {
                    var selectDate = this.state.selectDate;
                    // if(this.state.defFlag === 0)
                    // {
                    //     var params = {
                    //         data_type : this.state.data_type,
                    //         location_code : this.state.locationNames[0].value,
                    //         useremail: localStorage.getItem('email'),
                    //         client : localStorage.getItem('client'),
                    //         userType : localStorage.getItem('user_type')
                    //     }
                    // }
                    // else{
                        var params = {
                            data_type : this.state.data_type,
                            location_code : this.state.location.value,
                            selectDate:selectDate,
                            useremail: localStorage.getItem('email'),
                            client : localStorage.getItem('client'),
                            userType : localStorage.getItem('user_type'),
                            generateDate:selectDate
                        }
                        // console.log("params",params)
                    // }
                    
                } catch (error) {
                    
                }
                
                redirectURL.post('dispatch/getDispatchPlanData',params)
                .then(async response =>{
                    try {
                        var records = response.data;
                        if(records.length > 0)
                        {
                              
                            var rowData = records.sort(GetSortDescOrder("order_date"));
                            var latestData = [];
                            var otherData = [];
                            var rowDataarr = [];
                            var trans = [{value:"",label:"Select Transaction ID"}]
                                    // console.log("rowData",rowData)
                            if(rowData.length > 0)
                            {
                                var latestData = await rowData.filter((rd) => rd.latest_run == 1);
                                var otherData = await rowData.filter((rd) => (rd.latest_run == 0 || rd.latest_run == undefined));
                                rowData.map((item) => {
                                    var converoDate = moment.parseZone(item.order_date).format("x");
                                    item.orderpos = converoDate;
                                    rowDataarr.push(item)
                                    trans.push({value:item.order_id,label:item.order_id})
                                })
                                
                            }
                            // console.log("trans ", trans)
                            // if(rowDataarr.length > 0)
                            // {
                            //     rowDataarr = rowDataarr.sort(GetSortDescOrder("orderpos"));
                            // }
                            // if(latestData.length>0){
                            //     this.getPendingTransactions(latestData)
                            // }
                            this.setState({
                                latestData:latestData,
                                rowData:otherData,
                                loadshow:'show-n',
                                overly:'show-n',
                                overlayBlock:"show-n",
                                defFlag:1,
                                transactions:trans

                            },()=>{
                              console.log("trans[1", trans[1])
                                this.getStartingTransactionData(this.state.location)

                            })
                        }
                        else{
                            this.setState({
                                rowData:[],
                                loadshow:'show-n',
                                overly:'show-n',
                                overlayBlock:"show-n",
                                defFlag:1
                            })
                        }
                    } catch (error) {
                        
                    }
                    
                })
                .catch(function (error) {
                    var errDetails={
                        url:'dispatch/getDispatchPlanData',
                        screen:'Summary Dashboard',
                        error,
                        
                    }
                    redirectURL.post("/master/logErrorsForApiCalls",errDetails)
                    
                })
            }
        })
        // await this.getDispatchPlanData()
        await getToolConfiguration({plant_code:location.value})
    }

    reloadPage = (transactionid) => {
        
        let encryptedstring = window.btoa(transactionid);
        window.location.href=window.location.origin+'/overall-dashboard?'+encryptedstring;
    }
    
    getPendingTransactions = (latestData) =>{
        let data
        if(latestData.length>0){
            data = latestData  
        }else{
            data = this.state.latestData
        }

            
        let locValue = this.state.location.value   
        let transactions = data.filter(item => item.location_code == locValue)
        let allTransactionIds = [];   
        
        redirectURL.post("/dispatch/getPlanRunHistory",{
            order_id : transactions[0].order_id
        })
        .then(async (response) =>{
        let transData = response.data
        
        transData.map(itm => {
            if(itm.data_processed != 1 && itm.data_processed != 17){
                
                let givenDateTime = moment.parseZone(itm.createDate).format('DD-MM-YYYY HH:mm');
                let currentDateTime = moment.parseZone(new Date()).format('DD-MM-YYYY HH:mm');
                
                
                givenDateTime = moment(givenDateTime, 'DD-MM-YYYY HH:mm');
                currentDateTime = moment(currentDateTime, 'DD-MM-YYYY HH:mm');
                const differenceInMinutes = currentDateTime.diff(givenDateTime, 'minutes');
                
                const targetTimeDifferenceInMinutes = 25;
                let flag = true;
                
                if (differenceInMinutes >= targetTimeDifferenceInMinutes) {
                    flag = false
                }
                
                if(differenceInMinutes <= 8){
                    if(localStorage.getItem(itm.transaction_id) != 0 && flag){
                        allTransactionIds.push({id:itm.transaction_id,duration:(8-differenceInMinutes)*60})
                    }
                }else{
                    if(localStorage.getItem(itm.transaction_id) != 0 && flag){
                        allTransactionIds.push({id:itm.transaction_id,duration:8*60})
                    }
                }
                
                if(localStorage.getItem(itm.transaction_id) == 0){
                    this.setState({
                        show: true, 
                        basicType:'danger', 
                        basicTitle:'Please Contact Admin Time Elapsed',
                    });
                    var url = window.location.host;
                    var environment = ''
                    if (url === '216.48.191.229:3011') {
                        environment='DEV'
                    }else if (url==='164.52.218.255:3009'){
                        environment='QA'
                    }else if(url==='nestle.enmovil.net'){
                        environment='UAT'
                    }else if(url==='nestle.optirun.in'){
                        environment='PROD'
                    }else{
                        environment="Local"
                    }
                    var details={env:environment,
                                    tran_id:itm.transaction_id,
                                    plant_code:this.state.location.value,
                                    email:localStorage.getItem('email')
                                }

                    redirectURL.post("/master/triggerEmailOnTimeOut",details)
                    localStorage.removeItem(itm.transaction_id)
                }

            }else{
                localStorage.removeItem(itm.transaction_id)
            }
    
        })
        if(allTransactionIds.length==0){
            clearInterval(this.intervalId);
        }
        this.setState({allTransactionIds:allTransactionIds})

        })
        .catch(function (error) {
            var errDetails={
                url:'/dispatch/getPlanRunHistory',
                screen:'Summary Dashboard',
                error,
                
            }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
    
        
    }
    
    onRouteData = async (row) => {
        this.setState({
          actionloader:"show-m"
        })
        let logParams = {
          "location_code" : this.state.location.value,
          "location_name" : this.state.location.label,
          "user_name" : localStorage.getItem("username"),
          "useremail" : localStorage.getItem("email"),
          "client" : localStorage.getItem("client"),
          "screen" : "planned Summary ",
          "activity" : 'clicked on Route Icon in aggrid',
          "event" : "Slider opened with a map",
          "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        await this.setState({
          overlayBlock: "show-m",
          tripinfo: [row],
          mapFor:'viewRoute'
        });
        // console.log("allroutes ",this.state.mapFor)
        var loadingLocation = [];
        if (this.state.location != undefined && this.state.location != "") {
          loadingLocation.push({
            lat: this.state.location.latitude,
            lng: this.state.location.longitude,
          });
        }
        let qry = JSON.parse(localStorage.getItem("qry"));
        let location = this.state.location;
        let plant_location_code = qry.plant_location_code;
        let dispatch_planning_type = qry.dispatch_planning_type;
        if(Object.keys(qry).length === 0){
          plant_location_code = location.value
          dispatch_planning_type = 2
        }
    
        var params = {
          transaction_id: this.state.transactionId,
          dispatch_planning_type: dispatch_planning_type,
          plant_location_code: plant_location_code,
          trip_no: row.trip_no,
          return_route: 1,
        };
        if (row.is_available == 0) {
          params.return_route = 0;
        }
    
        if(this.state.orderId != undefined && this.state.orderId != ''){
          let plant = this.state.orderId.split('_')
          plant = plant[0]
          // console.log(plant,'plant') 
            params.plant_location_code = plant
        }
    
        redirectURL
          .post("/dispatch/fetchPlanRouteDetails", params)
          .then((response) => {
            // console.log("Response4", response.data);
            
            if (
              response.data.plant_loc_data !== "" &&
              response.data.plant_loc_data !== undefined
            ) {
              var plantData = JSON.parse(response.data.plant_loc_data);
            } else {
              var plantData = [];
            }
    
            if (
              response.data.trip_gps_data !== "" &&
              response.data.trip_gps_data !== undefined
            ) {
              var trip_gps = JSON.parse(response.data.trip_gps_data);
            } else {
              var trip_gps = [];
            }
            // console.log("trip_gps ", trip_gps)
            if (
              response.data.trip_nodes_data !== "" &&
              response.data.trip_nodes_data !== undefined
            ) {
              var tripnodes = JSON.parse(response.data.trip_nodes_data);
            } else {
              var tripnodes = [];
            }
            // console.log("trip_gps ", trip_gps)
            // console.log("plantData ", plantData)
            // console.log("tripnodes ", tripnodes)
            if (row.is_available == 0) {
              this.setState({
                trip_gps_data: trip_gps,
                plant_loc_data: plantData,
                trip_nodes_data: tripnodes,
                // showSlideBlockUpload:"slide60",
                // showSlideMap: "slide60",
                // overlayBlock: "show-m",
                viewcount: 1,
                showAdditionalPlannedVehicles: 1,
                actionloader:"show-n"
              });
            } else {
              this.setState({
                trip_gps_data: trip_gps,
                plant_loc_data: plantData,
                trip_nodes_data: tripnodes,
                // showSlideBlockUpload:"slide60",
                // showSlideMap: "slide60",
                // overlayBlock: "show-m",
                viewcount: 1,
                showAdditionalPlannedVehicles: 0,
                actionloader:"show-n"
              });
            }
          })
          .catch(function (error) {
            var errDetails={
              url:"/dispatch/fetchPlanRouteDetails",
              screen:'Summary Dashboard',
              error,
              
          }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
    };

    onClickUpdateAssignVehicle = (rowid) => {
        var data = this.state.filteredData;
        var rownode = []
        if(data.length > 0){
            rownode = data[rowid];
        }
        let logParams = {
          "location_code" : this.state.location.value,
          "location_name" : this.state.location.label,
          "user_name" : localStorage.getItem("username"),
          "useremail" : localStorage.getItem("email"),
          "client" : localStorage.getItem("client"),
          "screen" : "Planned Summary",
          "activity" : 'clicked on Update Vehicle Button',
          "event" : "Modal Opened with Yes or No",
          "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    
        var assign_vehicle_list = [];
        this.state.vehicleLists.filter((item) => {
          if (item.type == rownode.vehicle_type ||  item.type == 'others') {
            assign_vehicle_list.push({
              value: item.value,
              label: item.label,
            });
          }
        });
    
        this.setState({
          oldVehicleNO: rownode.vehicle_no,
          oldVehTripNo: rownode.trip_no,
          openUpdateVehicleModel: true,
          assign_vehicle_list,
          assignrowdata: [rownode],
        });
      };
    
    onClickRevokeAssignVehicle = (rowid) => {
        var data = this.state.filteredData;
        var row = []
        if(data.length > 0){
            row = data[rowid];
        }
        // console.log(row.data,'da56a')
        let logParams = {
          "location_code" : this.state.location.value,
          "location_name" : this.state.location.label,
          "user_name" : localStorage.getItem("username"),
          "useremail" : localStorage.getItem("email"),
          "client" : localStorage.getItem("client"),
          "screen" : "Summary Dashboard",
          "activity" : 'clicked on Revoke Button',
          "event" : "Alert is opened with yer or no",
          "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    
        let transaction_id = this.state.transactionId;
        let vehicle_no = row.vehicle_no;
        let trip_no = row.trip_no;
        let round_trip_distance = row.round_trip_distance
    
        var cnf = window.confirm("Are you sure you want to proceed?");
    
        if(cnf === true)
        {
    
          this.setState({
            loadshow:'show-m',
          })
    
          let param = {
            transaction_id : transaction_id,
            vehicle_no : vehicle_no,
            trip_no : trip_no,
            round_trip_distance : round_trip_distance,
            useremail : localStorage.getItem("email")
    
          }
    
          let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Summary Dashboard",
            "activity" : 'Vehicle Revoked',
            "event" : "Vehicle Revoke",
            "data_type" : this.state.data_type,
          }
    
          redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
    
          let clickdetails = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Summary Dashboard",
            "activity" : 'Vehicle Revoked',
            "event" : "clicked on Revoke Vehicle",
            "data_type" : this.state.data_type,
          }
    
          redirectURL.post("/master/loguserUpdatesorChanges",clickdetails)
    
          redirectURL.post('/dispatch/revokeVehicleAssignment',param)
          .then(resp =>{
            if(resp.data.status == 'Success' && resp.data.message == "success"){
              this.getPlanOutputDetails();
              this.setState({
                loadshow:'show-n',
                show: true,
                basicTitle: "Vehicle Successfully Revoked",
                basicType: "success",
              });
            }else{
              this.setState({
                show: true,
                basicTitle: "Error",
                basicType: "danger",
                loadshow:'show-n',
              });
            }
          })
          .catch(function (error) {
            var errDetails={
              url:"/dispatch/revokeVehicleAssignment",
              screen:'Summary Dashboard',
              error,
              
          }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)        
        }) 
        }
    }
    editVehicleDispatchPlan = async(rowid) =>{
        var row = this.state.filteredData;
        var data = []
        if(row.length > 0){
            data = row[rowid];
        }
        console.log("data ",data)
        let vfr = (Math.max(
          ((data.total_weight / data.vehicle_max_weight) *
          100
            ).toFixed(2),((data.total_demand / data.vehicle_max_volume) *
            100
          ).toFixed(2)))
          // console.log(moment.parseZone(data.max_start_time).format('YYYY-MM-DD HH:mm'),'getHyphenYYYYMMDDHHMMSS(data.max_start_time)')
        let editplanvfr = vfr;
        let editplanmaxdispatch = moment
          .parseZone(data.max_start_time)
          .format("YYYY-MM-DD HH:mm");
        var maxStartTime = new Date(
          moment.parseZone(data.max_start_time).format("YYYY-MM-DD HH:mm")
        );
        let editplanqty = data.total_quantity;
        let editplanvehicle_volume = data.total_demand;
        let editplanvehicle_weight = data.total_weight;
        let editplanmaxvehiclevolume = data.vehicle_max_volume;
        let editplanmaxvehicleweight = data.vehicle_max_weight;
        let editplanttripno = data.trip_no;
        let editplantransit_type = data.vehicle_transit_type;
    
        //changes commented need to test
        // let current_time = new Date();
        let checktime = new Date(editplanmaxdispatch);
        let current_time =await this.checkCurrentTime(checktime);
        if(checktime < current_time){
          editplanmaxdispatch = moment.parseZone(current_time).format('YYYY-MM-DD HH:mm')
          // console.log(current_time,'cure  timr',editplanmaxdispatch)
        }
    
        await this.setState({
            showSlideEditVehiclePlan:"show-m",
            overlayBlock: "show-m",
            dispatchPlanObdUpdateData:data.legs_data,
            editplanvfr,editplanmaxdispatch,editplanqty,
            editplanvehicle_volume,editplanvehicle_weight,
            editplanmaxvehiclevolume,editplanmaxvehicleweight,
            editplanttripno,
            newObdVehicleType:{label: data.vehicle_type, value: data.vehicle_type},
            editplantransit_type,
            // newObdVehicleType:{label: data.vehicle_type, value: data.vehicle_type},
            maxStartTime
        })
        await this.enablesundayplanning()
    
    }
    onClickOrderInputs(){
      this.setState({
        orderinuts:"show-m"
      });
    }

    onClickDownloadPlannedData = () => {
        let logParams = {
          "location_code" : this.state.location.value,
          "location_name" : this.state.location.label,
          "user_name" : localStorage.getItem("username"),
          "useremail" : localStorage.getItem("email"),
          "client" : localStorage.getItem("client"),
          "screen" : "Planned Summary",
          "activity" : 'clicked on Download Planned Data Button',
          "event" : "Planned Data Dowloaded in Excel",
          "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
        let planData = this.state.plannedData;
        let allData = [];
        planData.map((item) => allData.push(...item.legs_data));
    
        const data = allData.map((item) => {
          if (item.drop_node_total_time_day == 0) {
            item.drop_node_total_time_day = "Day - 1";
          } else if (item.drop_node_total_time_day == 1) {
            item.drop_node_total_time_day = "Day - 2";
          } else if (item.drop_node_total_time_day == 2) {
            item.drop_node_total_time_day = "Day - 3";
          }
          return {
            "Trip No": item.trip_no,
            "Drop Sequence": item.drop_sequence,
            "Vehicle Type": item.vehicle_type,
            "Order Number": item.order_number,
            "Vehicle No": item.vehicle_no,
            "Invoicing Date": item.invoicing_date,
            Quantity: item.quantity,
            "Drop Location Code (Destination)": item.drop_node,
            "Drop Location Name (Destination)": item.drop_node_name,
            "Destination Location City": item.demand_node_location,
            "Volume (M3)": item.demand_volume,
            "Weight (Kgs)": item.demand_weight,
            "Planned Day": item.drop_node_total_time_day,
            "Max Dispatch Time (HH:MM)": item.vehicle_start_time_mins,
            "Distance To Destination (Kms)": item.distance,
            "Time To Destination": item.drop_node_total_time_hrs,
            "Delivery Date": item.delivery_date,
          };
        });
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
    
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
    
        const dataBlob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileName = `Planned Vehicles Data_${timestamp}.xlsx`;
        const downloadLink = document.createElement("a");
    
        downloadLink.href = window.URL.createObjectURL(dataBlob);
        downloadLink.download = fileName;
        downloadLink.click();
    };

    onClickShowRoutesData() {
        this.setState({
          actionloader:"show-m"
        })
        let logParams = {
          "location_code" : this.state.location.value,
          "location_name" : this.state.location.label,
          "user_name" : localStorage.getItem("username"),
          "useremail" : localStorage.getItem("email"),
          "client" : localStorage.getItem("client"),
          "screen" : "Summary Dashboard",
          "activity" : 'clicked on view planned available vehicle routes Button',
          "event" : "Slider Opened with a map",
          "data_type" : this.state.data_type,
        }
    
        redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    
        this.setState({
          overlayBlock: "show-m",
        });
        let qry = JSON.parse(localStorage.getItem("qry"));
        let location = this.state.location;
        let plant_location_code = qry.plant_location_code;
        let dispatch_planning_type = qry.dispatch_planning_type;
        if(Object.keys(qry).length === 0){
          plant_location_code = location.value
          dispatch_planning_type = 2
        }
        var params = {
          transaction_id: this.state.transactionId,
          dispatch_planning_type: dispatch_planning_type,
          plant_location_code: plant_location_code,
        };
    
        if(this.state.orderId != undefined && this.state.orderId != ''){
                
          let plant = this.state.orderId.split('_')
          plant = plant[0]
          // console.log(plant,'plant') 
          params.plant_location_code = plant
        }
    
        redirectURL
          .post("/dispatch/fetchPlanRouteDetails", params)
          .then((response) => {
            // console.log("Response", response.data);
            if (
              response.data.plant_loc_data !== "" &&
              response.data.plant_loc_data !== undefined
            ) {
              var plantData = JSON.parse(response.data.plant_loc_data);
            } else {
              var plantData = [];
            }
    
            if (
              response.data.trip_gps_data !== "" &&
              response.data.trip_gps_data !== undefined
            ) {
              var trip_gps = JSON.parse(response.data.trip_gps_data);
            } else {
              var trip_gps = [];
            }
            // console.log("trip_gps ", trip_gps)
            if (
              response.data.trip_nodes_data !== "" &&
              response.data.trip_nodes_data !== undefined
            ) {
              var tripnodes = JSON.parse(response.data.trip_nodes_data);
            } else {
              var tripnodes = [];
            }
            // console.log(trip_gps,plantData,tripnodes,'ll')
            this.setState({
                trip_gps_data_all: trip_gps,
              plant_loc_data: plantData,
              trip_nodes_data: tripnodes,
              // showSlideBlockUpload:"slide60",
              showSlideMap: "show-m",
              viewcount: 0,
              actionloader:"show-n"
            });
          })
          .catch(function (error) {
            var errDetails={
              url:"/dispatch/fetchPlanRouteDetails",
              screen:'Summary Dashboard',
              error,
              
          }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
            
        })
        
    }
    
  onChnageVehicleType=(event)=>{
    // console.log(event.target.name,typeof event.target.value,'hello')
    var name=event.target.name,value=parseInt(event.target.value)
    // console.log('hello',value === 1)
    if (value === 1){
      // console.log('hello',value)
      value=0
    }else{
      value=1
    }
    this.setState({[name]:value})
  }
  onClickShowAssignForm(rowid) {
    var data = this.state.filteredData;
    var row = []
    if(data.length > 0){
        row = data[rowid];
    }
    let logParamsWebPage = {
      "location_code" : this.state.location.value,
      "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Summary Dashboard",
      "activity" : 'clicked on Assign Vehicle Button',
      "event" : "Slider Opened with assign vehicle option",
      "data_type" : this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges",logParamsWebPage)


    var assign_vehicle_list = [];
    // console.log(this.state.vehicleLists)
    this.state.vehicleLists.filter((item) => {
      // if (item.type == row.vehicle_type ||  item.type == 'others') {
        assign_vehicle_list.push({
          value: item.value,
          label: item.label,
        });
      // }
    });
    // console.log(this.state.vehicleLists, "vehicle list");
    console.log(assign_vehicle_list, "assign");
    // console.log(row.vehicle_type, "assign");
    this.setState({
      showSlideAssignVehicle: "show-m",
      assignrowdata: [row],
      assign_vehicle_list,
    });
  }
  onChangeAssignVehicle(assignvehicle) {
    this.setState({ assignvehicle, revokedBack:true }, () => {
      var vehicle_no = this.state.assignvehicle.value;
      if (vehicle_no !== "") {
        if (vehicle_no === "0") {
          this.setState({
            showAssignFields: "show-m",
          });
        } else {
          this.setState({
            showAssignFields: "show-n",
          });
        }
      } else {
        this.setState({
          showAssignFields: "show-n",
        });
      }
    });

    if (this.state.activeVehicleList.length > 0) {
      let shipList = this.state.shipmentIds;
      let selectedShipmentId;
      shipList.map((itm) => {
        if (itm.truck_no == assignvehicle.value) {
          selectedShipmentId = itm.shipment_id;
        }
      });

    }
  }
  
  changeHandler(event) {
    var name = event.target.name;
    var value = event.target.value;
    var pattern = /^[A-Z0-9]{9}$/;
    // var key=event.target.keyCode()
    // console.log(key)
    var symbols = "'\"~!@#$%^&*()_+=-_|}{][:;<>?/ .,";

    var key = value[value.length - 1];

    // if (pattern.test(value.toUpperCase())) {
    //     console.log(value,'valuw')
    // }
    // console.log('Clicked',!symbols.includes(key))
    if (!symbols.includes(key)) {
      this.setState({
        [name]: value.toUpperCase(),
      });
    }

    if (this.state.activeVehicleList.length > 0) {
      let shipList = this.state.shipmentIds;
      let selectedShipmentId;
      shipList.map((itm) => {
        if (itm.truck_no == value) {
          selectedShipmentId = itm.shipment_id;
        }
      });
    }
  }
  
  onClickAssignSelectedVehicle() {

    let clickdetails = {
      "location_code" : this.state.location.value,
      "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Summary Dashboard",
      "activity" : 'clicked on Assigned a Vehicle',
      "event" : "Assign Vehicle",
      "data_type" : this.state.data_type,
    }
    // console.log(logParams)                                                              
    redirectURL.post("/master/loguserUpdatesorChanges",clickdetails)

    this.setState({ assignVehicleloader: "show-m" });
    var assignvehicle = this.state.assignvehicle.value;
    var transactionId = this.state.transactionId;
    var other_vehicle_no = this.state.other_vehicle_no;
    var orderId = this.state.orderId;
    var rowD = this.state.assignrowdata;
    // console.log(rowD);
    var oNumber = "";
    var trip_no = "";
    var plant_location_code = "";
    var viewPage = window.location.href;
    if (this.state.updateVehicleNo != true) {
      if (rowD.length > 0) {
        trip_no = rowD[0].trip_no;
        oNumber = rowD[0].order_number;
        plant_location_code = rowD[0].pickup_node;
      }
      if (
        assignvehicle !== "" &&
        transactionId !== "" &&
        transactionId !== undefined &&
        orderId !== "" &&
        orderId !== undefined
      ) {
        // console.log("assignvehicle ", assignvehicle)
        if (assignvehicle === "0") {
          var vNo = other_vehicle_no;
        } else {
          var vNo = assignvehicle;
        }
        if (vNo !== "") {
          var params = {
            vehicle_no: vNo,
            transaction_id: transactionId,
            order_id: orderId,
            trip_no: trip_no,
            oNumber: oNumber,
            application_link: viewPage,
            plant_location_code: plant_location_code,
          };



          // console.log('hit',this.state.location.value)  
          var updateVehicle=''
          if (this.state.updateVehicleNo){
            updateVehicle='Update Vehicle'
          }else{
            updateVehicle='Assign Vehicle'
          }
      
          let logParams = {
            "location_code" : this.state.location.value,
            "location_name" : this.state.location.label,
            "user_name" : localStorage.getItem("username"),
            "useremail" : localStorage.getItem("email"),
            "client" : localStorage.getItem("client"),
            "screen" : "Summary Dashboard",
            "activity" : updateVehicle,
            "event" : updateVehicle,
            "data_type" : this.state.data_type,
          }
                                                                     
          redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)


          redirectURL
            .post("/dispatch/assignVehicleAndCreateShipment", params)
            .then((response) => {
                
            if(response.data.status.status == 'Success'){             

              this.setState({
                showSlideAssignVehicle: "show-n",
                overlayBlock: "show-m",
                assignrowdata: [],
                other_vehicle_no: "",

                showNewPlan:false,
                updatedPlan: true,
                showSlideEditVehiclePlan:'show-n',
                overlayBlock:'show-n',

                assignvehicle: { value: "", label: "Select" },
                assignVehicleloader: "show-n",
                showAssignFields: "show-n",
                openReassignVehicleModel: false,
                updateVehicleNo: false,
              });
              this.getPlanOutputDetails();
            }else if(response.data.status.status != 'Success'){
                this.setState({
                    show: true,
                    basicTitle: response.data.status.message,
                    basicType: "danger",
                    assignVehicleloader: "show-n",
                    showSlideAssignVehicle: "show-n",
                    overlayBlock: "show-n",
                    assignrowdata: [],
                    other_vehicle_no: "",
                    assignvehicle: { value: "", label: "Select" },
                    assignVehicleloader: "show-n",
                    showAssignFields: "show-n",
                    openReassignVehicleModel: false,
                    updateVehicleNo: false,
                  });
                  this.getPlanOutputDetails();
            }
            })
            .catch(function (error) {
              var errDetails={
                url:"/dispatch/assignVehicleAndCreateShipment",
                screen:'Summary Dashboard',
                error,
                
            }
              redirectURL.post("/master/logErrorsForApiCalls",errDetails)
              
          })
        } else {
          this.setState({
            show: true,
            basicTitle: "Please choose vehicle",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        }
      } else {
        if (assignvehicle === "") {
          this.setState({
            show: true,
            basicTitle: "Please choose vehicle",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        } else {
          this.setState({
            show: true,
            basicTitle: "Please check transaction id and order id",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        }
      }
    } else {
      let oldVehicleNO = this.state.oldVehicleNO;
      let oldVehTripNo = parseInt(this.state.oldVehTripNo);
      let planned_distance = 0
      
      if(rowD.length>0){
        planned_distance = rowD[0].round_trip_distance
      }

      if (
        assignvehicle !== "" &&
        transactionId !== "" &&
        transactionId !== undefined &&
        orderId !== "" &&
        orderId !== undefined
      ) {
        // console.log("assignvehicle ", assignvehicle)
        if (assignvehicle === "0") {
          var vNo = other_vehicle_no;
        } else {
          var vNo = assignvehicle;
        }
        if (vNo != undefined && vNo !== "") {
          var params2 = {
            new_vehicle_no: vNo,
            transaction_id: transactionId,
            order_id: orderId,
            trip_no: oldVehTripNo,
            old_vehicle_no: oldVehicleNO,
            planned_distance:planned_distance,
            user_email:localStorage.getItem("email")
          };
          
          redirectURL
            .post("/dispatch/updateVehicleNoOfAssignedVehicle", params2)
            .then((response) => {
              if (
                response.data.status == "Success" &&
                response.data.message == "success"
              ) {
                this.setState({
                  updateVehicleNo: false,
                  assignVehicleloader: "show-n",
                  showSlideAssignVehicle: "show-n",
                  overlayBlock: "show-n",
                  showAssignFields: "show-n",
                  openReassignVehicleModel: false,
                });
                this.getPlanOutputDetails();
              } else {
                this.setState({
                  show: true,
                  basicTitle: response.data.message,
                  basicType: "danger",
                  assignVehicleloader: "show-n",
                  showSlideAssignVehicle: "show-n",
                  overlayBlock: "show-n",
                  openReassignVehicleModel: false,
                  updateVehicleNo: false,
                });
              }
            })
            .catch(function (error) {
              var errDetails={
                url:"/dispatch/updateVehicleNoOfAssignedVehicle",
                screen:'Summary Dashboard',
                error,
                
            }
              redirectURL.post("/master/logErrorsForApiCalls",errDetails)
              
          })
          
        } else {
          this.setState({
            show: true,
            basicTitle: "Please choose vehicle",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        }
      } else {
        this.setState({
          show: true,
          basicTitle: "Please check transaction id and order id",
          basicType: "danger",
          assignVehicleloader: "show-n",
        });
      }
    }
  }
  handelModelCancel = () => {
    this.setState({
      openReassignVehicleModel: false,
      overlayBlock: "show-n",
      updateVehicleNo: false,
      assignvehicle: { value: "", label: "Select" },
    });
  };

  handelUpdateModelCancel = () => {
    this.setState({
      openUpdateVehicleModel: false,
      overlayBlock: "show-n",
      updateVehicleNo: false,
    });
  };
  
  handleUpdateVehicle = () => {
    let logParams = {
      "location_code" : this.state.location.value,
      "location_name" : this.state.location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "Summary Dashboard",
      "activity" : 'clicked on yes Button in the modal',
      "event" : "Slider opended with assign vehicle option",
      "data_type" : this.state.data_type,
    }

    redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    this.setState({
      showSlideAssignVehicle: "show-m",
      overlayBlock: "show-m",
      updateVehicleNo: true,
      openUpdateVehicleModel: false,
    });
  };
  onClickCloseConfigs=()=>{
    this.setState({openSavedConfigs:false, planconf:"show-n"})
  }
  onClickConfigs=async()=>{
    await this.setState({
        openSavedConfigs:true,
        planconf:"show-m"
    })
  }
  cancelplanhideSlideBlock = () =>{
    this.setState({
      showSlideEditVehiclePlan: "show-n",
      showNewPlan: false,
      overlayBlock: "show-n",
      remark: { value: "", label: "Select" },
      subReason: "",
    });
  }
  
async checkCurrentTime(checktime){
    // console.log("first,", this.state.editplanmaxdispatch);
    var week_off = "Sunday",
      pickupFrom,
      pickupTo;
  
    var location = this.state.location.value;
    await redirectURL
      .post("master/getBoundTypeLocations", { data_type: 2 })
      .then(async (response) => {
        if (response.data.status === "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length) {
            var record = boundLocations.filter(
              (item) => item.location_code === location
            );
            record = record[0];
  
            week_off = record.week_off[0].toLowerCase();
            pickupFrom = record.pickup_from_time;
            pickupTo = record.pickup_to_time;
          }
        }
      });
    const weekdays = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
  
    let current_time = new Date();
    var startTime = current_time.getHours();
    pickupFrom = parseInt(pickupFrom.split(":")[0]);
    pickupTo = parseInt(pickupTo.split(":")[0]);
    var { editplanmaxdispatch } = this.state;
    if (this.state.editplanmaxdispatch === "") {
      if (startTime > pickupTo) {
        current_time.setDate(current_time.getDate() + 1);
  
        current_time.setHours(pickupFrom);
        current_time.setMinutes(0);
        current_time.setSeconds(0);
        current_time.setMilliseconds(0);
      }
  
      if (
        weekdays[current_time.getDay()] === week_off &&
        this.state.is_sunday_planning_enabled === 0
      ) {
        current_time.setDate(current_time.getDate() + 1);
        current_time.setHours(pickupFrom);
        current_time.setMinutes(0);
        current_time.setSeconds(0);
        current_time.setMilliseconds(0);
      }
      editplanmaxdispatch = moment
        .parseZone(checktime)
        .format("YYYY-MM-DD HH:mm");
      if (checktime < current_time) {
        editplanmaxdispatch = moment
          .parseZone(current_time)
          .format("YYYY-MM-DD HH:mm");
      }
  
      await this.setState({ editplanmaxdispatch });
    } else {
      if (
        weekdays[new Date(editplanmaxdispatch).getDay()] === week_off &&
        this.state.is_sunday_planning_enabled === 0
      ) {
        if (startTime > pickupTo) {
          current_time.setDate(current_time.getDate() + 1);
          current_time.setHours(pickupFrom);
          current_time.setMinutes(0);
          current_time.setSeconds(0);
          current_time.setMilliseconds(0);
        }
        if (
          weekdays[current_time.getDay()] === week_off &&
          this.state.is_sunday_planning_enabled === 0
        ) {
          current_time.setDate(current_time.getDate() + 1);
          current_time.setHours(pickupFrom);
          current_time.setMinutes(0);
          current_time.setSeconds(0);
          current_time.setMilliseconds(0);
        }
  
        this.setState({
          show: true,
          basicTitle: "Week Off Plan is not Enabled",
          basicType: "danger",
          editplanmaxdispatch: moment
            .parseZone(current_time)
            .format("YYYY-MM-DD HH:mm"),
        });
      }
    }
    return current_time;
  }
  onChangeRemark=(remark)=>{
    // console.log('event',remark)
    this.setState({remark})
  
  }
  

  handleEditVehiclePlan = () => {

    this.setState({
      showSlideEditVehiclePlan:"show-m",
      overlayBlock: "show-m",
      openEditVehiclePlanModel:false,

    })

  }

  handelChangeTypeVehiclePlan = () => {

    this.setState({
      openEditVehiclePlanModel:false,
      show: true,
      basicTitle: "Please Revoke Vehicle",
      basicType: "info",

    })

  }

  handleNewObdVehicleType = (vehTyp) => {
    this.setState({
      newObdVehicleType:vehTyp
    })
  }

  handleRowDataChanged = (params) =>{
    params.api.forEachNode((node) => {
        node.setSelected(true)
    });
  }

  handlerStartDateTime=async (event)=>{
        
    // var d = getHyphenYYYYMMDDHHMM(event._d);
    
    var d = moment.parseZone(event.target.value).format('YYYY-MM-DD HH:mm')
    
    await this.setState({editplanmaxdispatch:d})
    await this.enablesundayplanning()
    await this.checkCurrentTime(this.state.maxStartTime)
  }

  

getCurrentDateTime() {
    const now = new Date();
    // Subtract 7 days from the current date
    now.setDate(now.getDate() - 7);
    // Add an offset, for example, 1 minute
    now.setMinutes(now.getMinutes() + 1); // Adding 1 minute to ensure future time
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  calculateTotal = (array) => {
  const initialValue = { quantity: 0, demand_volume: 0, demand_weight: 0 };

    return array.reduce((accumulator, { quantity, demand_volume, demand_weight }) => ({
      quantity: accumulator.quantity + quantity,
      demand_volume: accumulator.demand_volume + demand_volume,
      demand_weight: accumulator.demand_weight + demand_weight,
    }), initialValue);
  };
  onChangeSubRemark=(reason)=>{
    // console.log("onChangeSubRemark", "reason", reason.target.value.length);
    var value = reason.target.value;
    var regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value) && value.length < 126) {
        this.setState({ subReason: reason.target.value });
    }
  }
  
  onPlannedDataSelectionChanged = async() =>{
  
  
    let editplanmaxvehiclevolume = this.state.editplanmaxvehiclevolume;
    let editplanmaxvehicleweight = this.state.editplanmaxvehicleweight;

    let planneddata = this.gridApi3.getSelectedRows()
    let unplanneddata = this.gridApi4.getSelectedRows();
    
    
    const totalArr = await this.calculateTotal(planneddata);
    const totalArr2 = await this.calculateTotal(unplanneddata);

    const combinedTotal = {
      alleditplanqty: totalArr.quantity + totalArr2.quantity,
      alleditplanvehicle_volume: totalArr.demand_volume + totalArr2.demand_volume,
      alleditplanvehicle_weight: totalArr.demand_weight + totalArr2.demand_weight,
    };
    
    let vfr = (Math.max(
      ((combinedTotal.alleditplanvehicle_weight / editplanmaxvehicleweight) *
      100
        ).toFixed(2),((combinedTotal.alleditplanvehicle_volume / editplanmaxvehiclevolume) *
        100
      ).toFixed(2)))

      await this.setState({
      editplanvfr : vfr,
      editplanqty : combinedTotal.alleditplanqty.toFixed(2),
      editplanvehicle_volume : combinedTotal.alleditplanvehicle_volume.toFixed(2),
      editplanvehicle_weight : combinedTotal.alleditplanvehicle_weight.toFixed(2)
      })
  }

  updateThePlan = async () =>{
        let location_code = this.state.location.value
        let transaction_id = this.state.transactionId;
        let trip_no = this.state.editplanttripno;
        let addremoveplanID = this.state.editplanuniqueid

    
        if (this.state.remark.value==='' ){
        this.setState({
            show: true,
            basicTitle: "Please provide reason for Plan change",
            basicType: "danger",
        });
        return;
        }
        
        if (this.state.remark.value==='Others' && this.state.subReason.trim()===''){
        this.setState({show:true,
            basicTitle:'Please provide reason for Plan change',
            basicType:'danger'
        })
        return;
        }
        let params = {
        transaction_id,
        location_code,
        trip_no,
        addremoveplanID,
        application_link: window.location.href,
        remark:this.state.remark.value,
        subReason:this.state.subReason
        };
        await redirectURL.post("tsp/updateThePlan",params)
        .then(res =>{

        // console.log(res.data,'redd')
        if(res.data.status == 'success'){
            var message 
            if (res.data.message == "successfull"){
            message = "Trip Edited Successfully"
            }
            else {
            message = res.data.message
            }
            this.setState({
            showNewPlan:false,
            show: true,
            updatedPlan: true,
            basicTitle: message,
            basicType: "success",
            showSlideEditVehiclePlan:'show-n',
            overlayBlock:'show-n',
            remark: { value: "", label: "Select" },
            subReason: "",
            })
            this.getPlanOutputDetails();
        }else{
            this.setState({
            showNewPlan:false,
            show: true,
            basicTitle: res.data.message,
            basicType: "info",
            showSlideEditVehiclePlan:'show-n',
            overlayBlock:'show-n'
            })
        }

        })

    }
    tabAction(oVal)
    {
      if(oVal == 1)
      {
        this.setState({
          summaryTabData:"show-m",
          vehAvailable:"show-n",
          unpl:"show-n",
          stab:"ptr-s-tags-active",
          vatab:"",
          ultab:""
        })
      }
      if(oVal == 2)
      {
        this.setState({
          summaryTabData:"show-n",
          vehAvailable:"show-m",
          unpl:"show-n",
          stab:"",
          vatab:"ptr-s-tags-active",
          ultab:""
        })
      }
      if(oVal == 3)
      {
        this.setState({
          summaryTabData:"show-n",
          vehAvailable:"show-n",
          unpl:"show-m",
          stab:"",
          vatab:"",
          ultab:"ptr-s-tags-active"
        },()=>{
          this.getWeekOffColumn()
        })
      }
    }

    getWeekOffColumn = () => {
      var inputDetails = this.state.inputDetails
        var unPlannedData = this.state.unPlannedData
        let data = []
        for (let each of unPlannedData){
          data.push(...(inputDetails.filter(item => item.order_number === each.order_number)))
        }
        unPlannedData.map((item)=>{
          data.map((order)=>{
            if(order.order_number===item.order_number){
              item.holiday=order.holiday[0];
              item.dealer_type = order.dealer_type
            }
          })
        })
        this.setState({unPlannedData})
    }
    closeAlert = async () => {
      await this.setState({
        show: false,
        remark: { value: "", label: "Select" },
        subReason: "",
      });
    };
    render() {
        if(localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined )
        {
          return <Navigate to={"/login"} push={true} />
        }

    
        let availableVehiclesGpsData = [];
        let availableVehiclesNodeData = [];
        
        if (this.state.regular_vehicles == 1 && this.state.additional_vehicles == 1 ) {
            
        availableVehiclesGpsData = this.state.trip_gps_data.filter(
          (item) => item.is_available == 0 || item.is_available == 1
        )
        availableVehiclesNodeData = this.state.trip_nodes_data.filter(
          (item) => item.is_available == 0 || item.is_available == 1
        );
        } 
        else if (this.state.regular_vehicles == 1 && this.state.additional_vehicles == 0){
          availableVehiclesGpsData = this.state.trip_gps_data.filter(
            (item) => item.is_available == 1
          );
          availableVehiclesNodeData = this.state.trip_nodes_data.filter(
            (item) => item.is_available == 1
          );
        }else if (this.state.regular_vehicles == 0 && this.state.additional_vehicles == 1) {
          availableVehiclesGpsData = this.state.trip_gps_data.filter(
            (item) => item.is_available == 0
          );
          availableVehiclesNodeData = this.state.trip_nodes_data.filter(
            (item) => item.is_available == 0
          );
        }

    // All Routes
    let availableVehiclesGpsDatal = [];
    let availableVehiclesNodeDatal = [];
    
    if (this.state.regular_vehicles == 1 && this.state.additional_vehicles == 1 ) {
        
    availableVehiclesGpsDatal = this.state.trip_gps_data_all.filter(
        (item) => item.is_available == 0 || item.is_available == 1
    )
    availableVehiclesNodeDatal = this.state.trip_nodes_data.filter(
        (item) => item.is_available == 0 || item.is_available == 1
    );
    } 
    else if (this.state.regular_vehicles == 1 && this.state.additional_vehicles == 0){
        availableVehiclesGpsDatal = this.state.trip_gps_data_all.filter(
        (item) => item.is_available == 1
        );
        availableVehiclesNodeDatal = this.state.trip_nodes_data.filter(
        (item) => item.is_available == 1
        );
    }else if (this.state.regular_vehicles == 0 && this.state.additional_vehicles == 1) {
        availableVehiclesGpsDatal = this.state.trip_gps_data_all.filter(
        (item) => item.is_available == 0
        );
        availableVehiclesNodeDatal = this.state.trip_nodes_data.filter(
        (item) => item.is_available == 0
        );
    }

    let outputDetails = this.state.outputDetails 
    
    let plannedCustomersList = []
    let  unPlannedCustomersList = []

    outputDetails.map(each => {
      if (each.is_planned === 1 ){
        let check = plannedCustomersList.includes(each.drop_node)
        if (!check){
          plannedCustomersList.push(each.drop_node)
        }
      }
      else if (each.is_planned === 0 ){
        let check = unPlannedCustomersList.includes(each.drop_node)
        if (!check){
          unPlannedCustomersList.push(each.drop_node)
        }
      }
    })

    let plannedQuantity = 0 
    let unPlannedQuantity = 0
    for (let each of outputDetails) {
      if (each.is_planned === 1) {
        plannedQuantity += each.quantity 
      }else if (each.is_planned === 0) {
        unPlannedQuantity += each.quantity
      }
    }
    let totalQuantity = plannedQuantity+unPlannedQuantity

    let data = this.state.filteredData
    var tatTotal = 0
    var tat = 0 ;
    if (data.length > 0 ){
      data.map(each => {
        tatTotal += parseFloat(each.round_trip_time)
      })
      tat = (tatTotal/(data.length)).toFixed(2)
    }
    
    var existingPlanColDfs = [
        {
          headerName: "Drop Location Code (Destination)",
          headerTooltip: "Drop Location Code (Destination)",
          field: "drop_node",
          width: "150",
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: "Drop Location Name (Destination)",
          headerTooltip: "Drop Location Name (Destination)",
          field: "drop_node_name",
          width: "150",
        },
        {
          headerName: "Destination Location City",
          headerTooltip: "Destination Location City",
          field: "demand_node_location",
          width: "120",
        },
        {
          headerName: "Order Number",
          headerTooltip: "Order Number",
          field: "order_number",
          width: "120",
        },
        {
          headerName: "Invoicing Date",
          headerTooltip: "Invoicing Date",
          field: "invoicing_date",
          width: "120",
        },
        {
          headerName: "Quantity",
          headerTooltip: "Quantity",
          field: "quantity",
          width: "90",
          valueGetter:(params)=>{
              return params.data.quantity.toFixed(2)
          }
        },
        {
          headerName: "Weight (Kgs)",
          headerTooltip: "Weight (Kgs)",
          field: "demand_weight",
          width: "90",
        },
        {
          headerName: "Volume (M3)",
          headerTooltip: "Volume (M3)",
          field: "demand_volume",
          width: "90",
        },
        {
          headerName: "Distance To Destination (Kms)",
          headerTooltip: "Distance To Destination (Kms)",
          field: "distance",
          width: "150",
        },
        {
          headerName: "Expected Delivery Date",
          field: "delivery_date",
          width: "120",
        },
        {
          headerName: "Time To Destination",
          headerTooltip: "Time To Destination",
          field: "drop_node_total_time_hrs",
          width: "150",
        },
        {
          headerName: "Transit Time (HH:MM)",
          headerTooltip: "Transit Time (HH:MM)",
          field: "drop_node_transit_time_hrs",
          width: "120",
        },
        {
          headerName: "Unloading Time (HH:MM)",
          headerTooltip: "Unloading Time (HH:MM)",
          field: "drop_node_unloading_time_mins",
          width: "120",
        },
  
      ];
  
      var newPlanColDfs = [
        {
          headerName: "Planned Day",
          headerTooltip: "Planned Day",
          field: "drop_node_total_time_day",
          width: "90",
          valueGetter: function (params) {
            try {
              if (
                params.data.drop_node_total_time_day !== "" &&
                params.data.drop_node_total_time_day !== undefined
              ) {
                var a = parseInt(params.data.drop_node_total_time_day);
                return "Day - " + (a + 1);
              } else {
                return params.data.drop_node_total_time_day;
              }
            } catch (error) { }
          },
        },
        {
          headerName: "Drop Sequence",
          headerTooltip: "Drop Sequence",
          field: "drop_sequence",
          width: "100",
        },
        {
          headerName: "Drop Location Code (Destination)",
          headerTooltip: "Drop Location Code (Destination)",
          field: "drop_node",
          width: "150",
          // checkboxSelection: true,
          // headerCheckboxSelection: true,
        },
        {
          headerName: "Drop Location Name (Destination)",
          headerTooltip: "Drop Location Name (Destination)",
          field: "drop_node_name",
          width: "150",
        },
        {
          headerName: "Destination Location City",
          headerTooltip: "Destination Location City",
          field: "demand_node_location",
          width: "120",
        },
        {
          headerName:"Dealer Type",
          field:"dealer_type",
          width:"100",
          valueGetter:(params)=>{
            if(params.data.dealer_type!=='' && params.data.dealer_type!==undefined){
              return params.data.dealer_type
            }else{
              return 'N/A'
            }
          }
        },
        {
          headerName: "Week Off",
          field: "week_off",
          width: 100,
        },
        {
          headerName: "Order Number",
          headerTooltip: "Order Number",
          field: "order_number",
          width: "120",
        },
        {
          headerName: "Invoicing Date",
          headerTooltip: "Invoicing Date",
          field: "invoicing_date",
          width: "120",
        },
        {
          headerName: "Quantity",
          headerTooltip: "Quantity",
          field: "quantity",
          width: "90",
          valueGetter:(params)=>{
              return params.data.quantity.toFixed(2)
          }
        },
        {
          headerName: "Weight (Kgs)",
          headerTooltip: "Weight (Kgs)",
          field: "demand_weight",
          width: "90",
        },
        {
          headerName: "Volume (M3)",
          headerTooltip: "Volume (M3)",
          field: "demand_volume",
          width: "90",
        },
  
        {
          headerName: "Distance To Destination (Kms)",
          headerTooltip: "Distance To Destination (Kms)",
          field: "distance",
          width: "150",
        },
        {
          headerName: "Expected Delivery Date",
          field: "delivery_date",
          width: "120",
        },
        
        {
          headerName: "Time To Destination",
          headerTooltip: "Time To Destination",
          field: "drop_node_total_time_hrs",
          width: "150",
        },
        {
          headerName: "Transit Time (HH:MM)",
          headerTooltip: "Transit Time (HH:MM)",
          field: "drop_node_transit_time_hrs",
          width: "120",
        },
        
        {
          headerName: "Unloading Time (HH:MM)",
          headerTooltip: "Unloading Time (HH:MM)",
          field: "drop_node_unloading_time_mins",
          width: "120",
        },
  
      ];
  
      var updatedunPlannedcolsDefs = [
        
        {
          headerName: "Drop Location Code",
          headerTooltip: "Drop Location Code",
          field: "drop_node",
          width: "150",
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: "Drop Location Name",
          headerTooltip: "Drop Location Name",
          field: "drop_node_name",
          width: "180",
        },
        {
          headerName: "Drop Location City",
          headerTooltip: "Drop Location City",
          field: "demand_node_location",
          width: "150",
        },
        {
          headerName: "Order Number",
          field: "order_number",
          width: "120",
          valueGetter:(params)=>{          
            return params.data.order_number
          }
        },
        {
          headerName:"Distance from Plant",
          headerTooltip:"Distance from plant",
          field:"distance_from_plant",
          width:"100",
          valueGetter:(params)=> {
            if(params.data.distance_from_plant !=="" && params.data.distance_from_plant !== undefined){
              return params.data.distance_from_plant.toFixed(2)
            }else{
              return 'N/A'
            }
          }
  
        },
        {
          headerName: "Week Off",
          field: "holiday",
          width: 100,
          filter: true,
          resizable: true,
          editable: false,
        },
        {
          headerName: "Demand Volume (M3)",
          headerTooltip: "Demand Volume (M3)",
          field: "demand_volume",
          width: "130",
        },
        {
          headerName: "Weight (Kgs)",
          headerTooltip: "Weight (Kgs)",
          field: "demand_weight",
          width: "120",
        },
        {
          headerName: "Quantity",
          headerTooltip: "Quantity",
          field: "quantity",
          width: "100",
          valueGetter:(params)=>{
            if (params.data.quantity!=='' && params.data.quantity!== undefined){
              return params.data.quantity.toFixed(2)
            }else{
              return 'N/A'
            }
          }
        },
        {
          headerName: "Delivery Date",
          field: "delivery_date",
          width: "120",
        },
       
      ];
      var remarks = [
        { value: "Load Cancelled", label: "Load Cancelled" },
        {
          value: "New OBD received within same route",
          label: "New OBD received within same route",
        },
        {
          value: "Multiple vehicles planned for same route",
          label: "Multiple vehicles planned for same route",
        },
        { value: "Invalid Clubbing", label: "Invalid Clubbing" },
        { value: "IFRS Risk", label: "IFRS Risk" },
        {
          value: "Delay in Customer Serving",
          label: "Delay in Customer Serving",
        },
        { value: "Others", label: "Others" },
      ];
      let updatedInputdetails = this.state.inputDetails
    let drop_node_details = this.state.drop_node_details
    try {
      
      if(updatedInputdetails.length>0){
        updatedInputdetails.map(itm => {
          
          if(itm.drop_location_name == undefined ){
            drop_node_details.map(item =>{
            
              if(item.location_code == itm.drop_location_code){
                itm.drop_location_name = item.location_name
              }
            })
          }

        })
      }
      
    } catch (error) {
        
    }
    var inputDetailscolsDefs = [
      {
        headerName: "Pickup Location (Source)",
        headerTooltip: "Pickup Location (Source)",
        field: "pickup_location_code",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Drop Location Code (Destination)",
        headerTooltip: "Drop Location Code (Destination)",
        field: "drop_location_code",
        width: 160,
        filter: true,
        resizable: true,
        editable: false,
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Drop Location Name (Destination)",
        headerTooltip: "Drop Location Name (Destination)",
        field: "drop_location_name",
        width: 150,
        filter: true,
        resizable: true,
        editable: false,
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Drop Location City",
        headerTooltip: "Drop Location City",
        field: "location_city",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Week Off",
        field: "holiday",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      // {
      //     headerName: "Destination State",
      //     field: "drop_location_state",
      //     width:200,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      // {
      //     headerName: "Sales Order No",
      //     field: "so_number",
      //     width:200,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      // {
      //     headerName: "No. of Tires",
      //     field: "no_of_tires",
      //     width:200,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      {
        headerName: "Order Number",
        headerTooltip: "Order Number",
        field: "order_number",
        width: "130",
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Invoicing Date",
        headerTooltip: "Invoicing Date",
        field: "invoicing_date",
        width: "120",
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Quantity",
        headerTooltip: "Quantity",
        field: "quantity",
        width: "100",
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Weight (Kgs)",
        headerTooltip: "Weight (Kgs)",
        field: "weight_tons",
        width: 120,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          try {
            if (
              params.data.weight_tons !== "" &&
              params.data.weight_tons !== undefined
            ) {
              return Math.round(params.data.weight_tons * 100) / 100;
            } else {
              return "";
            }
          } catch (error) { }
        },
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Demand Volume (M3)",
        headerTooltip: "Demand Volume (M3)",
        field: "demand_cmt",
        width: 120,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          try {
            if (
              params.data.demand_cmt !== "" &&
              params.data.demand_cmt !== undefined
            ) {
              return Math.round(params.data.demand_cmt * 100) / 100;
            } else {
              return "";
            }
          } catch (error) { }
        },
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Dealer Available From",
        headerTooltip: "Dealer Available From",
        field: "dealer_available_start",
        width: "130",
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Dealer Available To",
        headerTooltip: "Dealer Available To",
        field: "dealer_available_end",
        width: "120",
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Delivery Date",
        field: "delivery_date",
        width: "120",
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
      }
      },
      {
        headerName: "Dealer Type",
        field: "dealer_type",
        width: "120",
        valueGetter:(params)=>{
          if(params.data.dealer_type!=='' && params.data.dealer_type!==undefined){
            return params.data.dealer_type
          }else{
            return 'N/A'
          }
        },
        cellClass:function(params){
          try {
              if(params.data.new_obd != undefined ){
                if(params.data.new_obd == 1){
                  return "gHighlight";
                }
              }
          } catch (error) {
              
          }
        }
      }
    ];

        // const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return (
                <div className="dashb float-left" >
                     <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                  ></SweetAlert>

                    <div className="row" >
                        <div className="card nobrd col-sm-12">
                            <div className="card-header" style={{padding:"0",background:"transparent"}}>
                                <div className="row">
                                    <div className="col-sm-7">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <h4 className="ptr sltc">
                                                    <Select
                                                    placeholder={"Select"}
                                                    onChange={this.locationChanged}
                                                    name="location"
                                                    value={this.state.location}
                                                    options={this.state.locationNames} />
                                                </h4>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="ptr-s pointer tAlignCenter"  onClick={this.handleCalender}>
                                                    <i className="fa fa-calendar"></i> {this.state.selectDate}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="ptr sltc">
                                                <Select
                                                    placeholder={"Select"}
                                                    className="f-width"
                                                    onChange={this.handleTransaction}
                                                    name="current_transaction"
                                                    value={this.state.current_transaction}
                                                    options={this.state.transactions} 
                                                  />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 tAlignRight pt-15p">
                                        <button type="button" className="btn btn-default actionBtns" onClick={this.onClickOrderInputs.bind(this)}> <i className="fa fa-cubes"></i> Order Inputs</button>
                                        <button type="button" className="btn btn-default actionBtns" onClick={this.onClickDownloadPlannedData.bind(this)}> <i className="fa fa-upload"></i> Export Plan</button>
                                    
                                        <button type="button" className="btn btn-default actionBtns" onClick={this.onClickShowRoutesData.bind(this)}><i className="fa fa-map-marker"></i> View Routes</button>
                                        <button type="button" className="btn btn-default actionBtns" onClick={this.onClickConfigs.bind(this)}><i className="fa fa-cogs"></i> Plan Config</button>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="card nobrd">
                            <div className="row">
                            <div className="col-sm-5">
                                <div className="card cardbg p-15p nobrd">
                                    
                                    <div className={"card-body "+this.state.showCalendar}>
                                        <div className="close-cl pointer" onClick={this.handleCalenderHide}>Close</div>
                                        <div className="cal-datepicker">
                                            <div className="datepicker-here float-md-right" data-language='en'>
                                                <DatePicker
                                                    height = "500"
                                                    inline
                                                    // selected={this.state.current_selected_date}
                                                    value={this.state.current_selected_date}
                                                    onChange={this.handleChange}
                                                />
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 mheight">
                                            <Speedometer
                                                pointValue={((this.state.planned_orders/this.state.total_orders)*100).toFixed(2)}
                                            />
                                            <div className="chart-title">
                                                <button className="btn btn-success">Planning %</button>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mheight">
                                            <GaugeChart
                                                overall={this.state.VFR}
                                                dedicated={this.state.dedicated}
                                                spot={this.state.spot}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-80p mt60p">
                                        <div className="col-sm-4 mb-10p">
                                            <div className="counter-value">
                                                <div className="row">
                                                    <h5 className="h5 col-sm-12 extrpd1440">Trips Planned</h5>
                                                    <div className="col-sm-12 dashbCount">
                                                    {plannedCustomersList.length}
                                                    </div>
                                                    {/* <div className="counter-desc  col-sm-12">
                                                        <p>OptiRun is a mobile app developed by Enmovil</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4 mb-10p">
                                            <div className="counter-value">
                                                <div className="row">
                                                    <h5 className="h5 col-sm-12 extrpd1440">Trips Assigned</h5>
                                                    <div className="col-sm-12 dashbCount">
                                                        {this.state.assignTrips}
                                                    </div>
                                                    {/* <div className="counter-desc  col-sm-12">
                                                        <p>OptiRun is a mobile app developed by Enmovil</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mb-10p">
                                            <div className="counter-value">
                                                <div className="row">
                                                    <h5 className="h5 col-sm-12">Quantity Planned</h5>
                                                    <div className="col-sm-12 dashbCount">
                                                    {plannedQuantity}
                                                    </div>
                                                    {/* <div className="counter-desc  col-sm-12">
                                                        <p>OptiRun is a mobile app developed by Enmovil</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mb-10p">
                                            <div className="counter-value">
                                                <div className="row">
                                                    <h5 className="h5 col-sm-12">Round Trip Distance</h5>
                                                    <div className="col-sm-12 dashbCount">
                                                        {this.state.total_round_distance} <span className="small-t">Kms</span>
                                                    </div>
                                                    {/* <div className="counter-desc  col-sm-12">
                                                        <p>OptiRun is a mobile app developed by Enmovil</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mb-10p">
                                            <div className="counter-value">
                                                <div className="row">
                                                    <h5 className="h5 col-sm-12">Cool Chain Adherence</h5>
                                                    <div className="col-sm-12 dashbCount">
                                                        100%
                                                    </div>
                                                    {/* <div className="counter-desc  col-sm-12">
                                                        <p>OptiRun is a mobile app developed by Enmovil</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mb-10p">
                                            <div className="counter-value">
                                                <div className="row">
                                                    <h5 className="h5 extrpd col-sm-12">Ontime Dispatch</h5>
                                                    <div className="col-sm-12 dashbCount">
                                                        {this.state.ontimedispatchPercentage}%
                                                    </div>
                                                    {/* <div className="counter-desc  col-sm-12">
                                                        <p>OptiRun is a mobile app developed by Enmovil</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                    {/* END Counters */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-7">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className={"ptr-s-tags pointer tAlignCenter "+this.state.stab} onClick={this.tabAction.bind(this,1)}>
                                            Summary
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-4">
                                        <div className={"ptr-s-tags pointer tAlignCenter "+this.state.vatab} onClick={this.tabAction.bind(this,2)}>
                                            Vehicle Availability
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-4">
                                        <div className={"ptr-s-tags pointer tAlignCenter "+this.state.ultab} onClick={this.tabAction.bind(this,3)}>
                                            Unplanned Loads
                                        </div>
                                    </div>
                                </div>
                                {/* Summary Tab */}
                                <div className={this.state.summaryTabData} >
                                  <div className="card cardbg p-15p  nobrd" >
                                      <div className="row" style={{position:"relative"}}>
                                          <div className="col-sm-12">
                                              <div className="card cardbg nbrd" >
                                                  <div className="div-table">
                                                      {/* Row Start */}
                                                      {/* {console.log("filterdata", this.state.filteredData)} */}
                                                      {(this.state.filteredData.length > 0)?
                                                      (this.state.filteredData.map((item,idx)=>
                                                          
                                                      <div className="row even fixh_">
                                                          <div className="col-sm-12">
                                                              <div className="row">
                                                                  <div className="w245 pl0 tAlignCenter">
                                                                      <div className=" hightlit t-tooltip">
                                                                          {/* {console.log("ÏTEMS",idx)} */}
                                                                          <h4 className="info-htitle">{item.vehicle_no}</h4>
                                                                          <p className="info-sub">{item.vehicle_type}</p>
                                                                          
                                                                          <div className="t-tooltiptext">
                                                                              <div className="row">
                                                                               <div className="col-sm-6">
                                                                                    <div className="">Max Weight (Kgs)</div>
                                                                                    <p className="">{item.vehicle_max_weight}</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <div className="">Max Volume (M3)</div>
                                                                                    <p className="">{item.vehicle_max_volume}</p>
                                                                                </div>
                                                                             
                                                                              </div>
                                                                          </div>
                                                                        
                                                                      </div>
                                                                  </div>
                                                                  <div className="col pt-20p">
                                                                      <div className="row">
                                                                          <div className="col-sm-6">
                                                                              <div className="div-tr-section">Quantity</div>
                                                                              <p className="info-data fb pointer" onClick={this.viewDetailsData.bind(this,idx)}>{item.total_quantity}</p>
                                                                          </div>
                                                                          <div className="col-sm-6">
                                                                              <div className="div-tr-section">Utilization (%)</div>
                                                                              <p className="info-data fb pointer" onClick={this.viewDetailsData.bind(this,idx)}>
                                                                                  {
                                                                                  (item.total_weight !== "" && item.total_weight !== undefined)?
                                                                                  (Math.max(
                                                                                      ((item.total_weight / item.vehicle_max_weight) *
                                                                                      100
                                                                                    ).toFixed(2),((item.total_demand / item.vehicle_max_volume) *
                                                                                    100
                                                                                  ).toFixed(2)))
                                                                                  :0}
                                                                              </p>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col pt-20p">
                                                                      <div className="row">
                                                                          <div className="col-sm-6">
                                                                              <div className="div-tr-section">Onward Trip (kms)</div>
                                                                              <p className="info-data fb pointer" onClick={this.viewMapRoutes.bind(this,idx)}>{item.distance}</p>
                                                                          </div>
                                                                          <div className="col-sm-6">
                                                                              <div className="div-tr-section">Round Trip (Kms)</div>
                                                                              <p className="info-data fb pointer" onClick={this.viewMapRoutes.bind(this,idx)}>{item.round_trip_distance}</p>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  
                                                                  <div className="col-1 gd-more ">
                                                                      <span className={"opt_"+idx}>
                                                                          <a href="javascript:;" className="btn btn-default" onClick={this.showRowData.bind(this,idx)}>
                                                                              <i className="fa fa-caret-down"></i>
                                                                          </a>
                                                                      </span>
                                                                      <span className={"hid_"+idx} style={{display:"none"}}>
                                                                          <a href="javascript:;" className="btn btn-default" onClick={this.hideRowData.bind(this,idx)}>
                                                                              <i className="fa fa-caret-up"></i>
                                                                          </a>
                                                                      </span>
                                                                  </div>
                                                              </div>
                                                              <div className={"row mt-30p brd-btm hid_"+idx} style={{display:"none"}}>
                                                                  <div className="col-2 pl0">
                                                                      <div className="tAlignCenter">
                                                                          <div className="div-tr-section-bottom">Transit Type</div>
                                                                          <div className="info-data fb">{item.vehicle_transit_type}</div>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col">
                                                                      <div className="row">
                                                                          <div className="col-sm-12">
                                                                              <div className="div-tr-section-bottom">Orders</div>
                                                                              <p className="info-data fb">{item.no_of_drops}</p>
                                                                          </div>
                                                                      </div>
                                                                    </div>
                                                                    
                                                                  <div className="col">
                                                                      <div className="row">
                                                                          <div className="col-sm-12">
                                                                              <div className="div-tr-section-bottom">Customers</div>
                                                                              <p className="info-data fb">{item.no_of_customer}</p>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  
                                                                  <div className="col-3">
                                                                      <div className="row">
                                                                          <div className="col-sm-12">
                                                                              <div className="div-tr-section-bottom">Transporter</div>
                                                                              <p className="info-data fb">{item.transporter_name}</p>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col">
                                                                      <div className="row">
                                                                          <div className="col-sm-12">
                                                                              <div className="div-tr-section">Planned Weight (Kgs)</div>
                                                                              <p className="info-data fb">{item.total_weight}</p>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col">
                                                                      <div className="row">
                                                                         
                                                                          <div className="col-sm-12s">
                                                                              <div className="div-tr-section">Planned Volume (M3)</div>
                                                                              <p className="info-data fb">{item.total_demand}</p>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-12 mt-30p">
                                                                      <button className="btn btn-default gd-btn f12" onClick={this.viewMapRoutes.bind(this,idx)}>
                                                                          <i className="fa fa-map-marker"></i> View Route
                                                                      </button>
                                                                      <button className="btn btn-warning gd-btn f12" onClick={this.viewDetailsData.bind(this,idx)}>
                                                                          <i className="fa fa-eye"></i> View Details
                                                                      </button>
                                                                      {
                                                                      (item.vehicle_no == "Not Assigned" &&  item.route_finalized != 1)?
                                                                          ((item.transaction_update == 1)?
                                                                              "NA":
                                                                              <button className="btn btn-danger gd-btn f12" onClick={this.onClickShowAssignForm.bind(this,idx)}>
                                                                                      <i className="fa fa-truck"></i> Assign Vehicle
                                                                              </button>
                                                                          )
                                                                          :
                                                                          ((item.route_finalized == 1)?
                                                                              <button className="btn btn-secondary gd-btn f12" onClick={this.onClickUpdateAssignVehicle.bind(this,idx)}>
                                                                                  <i className="fa fa-wrench"></i> Update Vehicle
                                                                              </button>
                                                                          :"NA")
                                                                      }
                                                                      
                                                                      {
                                                                          (item.route_finalized == 1) ?
                                                                              <button className="btn btn-secondary gd-btn f12" onClick={this.onClickRevokeAssignVehicle.bind(this,idx)}>
                                                                              <i className="fa fa-retweet"></i> Revoke Vehicle
                                                                              </button>  
                                                                              :
                                                                              ""
                                                                      }
                                                                      {/* WB23F4537 */}
                                                                      
                                                                      <button className="btn btn-warning gd-btn f12" onClick={this.editVehicleDispatchPlan.bind(this,idx)}>
                                                                          <i className="fa fa-edit"></i> Edit Plan
                                                                      </button>
                                                                  </div>

                                                                  
                                                                  
                                                              </div>
                                                          </div>
                                                      </div>
                                                          
                                                      ))
                                                      :"No Data found"}
                                                      {/* Row End */}
                                                      
                                                  </div>
                                              </div>
                                          </div>
                                                  
                                      </div>
                                  </div>
                                </div>
                                {/* Vehicle Availability Start */}
                                <div className={this.state.vehAvailable}>
                                  <div className="row p-15p" style={{position:"relative"}}>
                                      <div className="col-sm-12">
                                          <div className="card cardbg nbrd" >
                                            <div className="div-table">
                                            {
                                              (this.state.vehiclesSelectedData.length > 0)?
                                                (this.state.vehiclesSelectedData.map((item) =>
                                                  <div className="row even">
                                                      <div className="col-sm-12">
                                                        <div className="row">
                                                            <div className="col-sm-12 pl0">
                                                                {/* <div className=" hightlit">
                                                                    <h4 className="info-htitle-avl">{item.vehicle_no}</h4>
                                                                    <p className="info-sub">
                                                                        <span>
                                                                        {
                                                                          (item.gps_status_24hrs != undefined && item.gps_status_24hrs != "")?
                                                                            (item.gps_status_24hrs == 'no')?
                                                                              <i className="fa fa-circle red"></i>
                                                                            :
                                                                              (item.gps_status_24hrs == 'N/A')? "N/A":<i className="fa fa-circle green"></i>
                                                                          :
                                                                          "NA"
                                                                          }
                                                                        </span>
                                                                        {item.vehicle_type}</p>
                                                                </div> */}
                                                                <div className=" hightlit-avl ">
                                                                      {/* {console.log("ÏTEMS",idx)} */}
                                                                      <h4 className="info-htitle tAlignLeft">
                                                                      <span className="dot-ico">
                                                                    {
                                                                      (item.gps_status_24hrs != undefined && item.gps_status_24hrs != "")?
                                                                        (item.gps_status_24hrs == 'no')?
                                                                          <i className="fa fa-map-marker red"></i>
                                                                        :
                                                                          (item.gps_status_24hrs == 'N/A')? <i className="fa fa-map-marker gray"></i>:<i className="fa fa-map-marker green"></i>
                                                                      :
                                                                      <i className="fa fa-map-marker gray"></i>
                                                                      }
                                                                    </span>
                                                                    {item.vehicle_no} <small className="sf13">{item.vehicle_type}</small></h4>
                                                                  </div>
                                                               
                                                            </div>
                                                            <div className="col-sm-12">
                                                              <div className="row">
                                                                <div className="col pl0">
                                                                    <div className="tAlignCenter">
                                                                        <div className="div-tr-section-bottom">Plant Code</div>
                                                                        <div className="info-data-avl fb">{item.location_code}</div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-3 pl0">
                                                                    <div className="tAlignCenter">
                                                                        <div className="div-tr-section-bottom">Plant Name</div>
                                                                        <div className="info-data-avl fb">{item.location_name}</div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col pl0">
                                                                    <div className="tAlignCenter">
                                                                        <div className="div-tr-section-bottom">Transit Type</div>
                                                                        <div className="info-data-avl fb">{item.transit_type}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col pl0">
                                                                    <div className="tAlignCenter">
                                                                        <div className="div-tr-section-bottom">Available Time</div>
                                                                        <div className="info-data-avl fb">
                                                                        {
                                                                        (item.vehicle_next_available_date !== "" && item.vehicle_next_available_date !== undefined)?
                                                                            moment.parseZone(item.vehicle_next_available_date).format("YYYY-MM-DD HH:mm")
                                                                          :""
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                              </div>
                                                              
                                                              
                                                            </div>
                                                        </div>
                                                      </div>
                                                  </div>
                                                ))
                                              :"No Data Available"
                                            }
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                {/* END */}
                                {/* Unplanned Loads Start */}
                                <div className={this.state.unpl}>
                                    <div className="row p-15p" style={{position:"relative"}}>
                                      <div className="col-sm-12">
                                          <div className="card cardbg nbrd" >
                                            <div className="div-table">
                                            {
                                              (this.state.unPlannedData.length > 0)?
                                                (this.state.unPlannedData.map((item) =>
                                                  <div className="row even">
                                                      <div className="col-sm-12">
                                                        <div className="row rpd1030">
                                                            <div className="col-sm-12">
                                                              <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="div-tr-avl p10p"> 
                                                                      <span className="info-htitle fb">{item.drop_node} - {item.drop_node_name}</span>
                                                                      
                                                                      <span className="float-right div-tr-section-bottom">Dealer Type: <span className="info-data-avl">{item.dealer_type}</span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="col pl0">
                                                                    <div className="tAlignCenter">
                                                                        <div className="div-tr-section-bottom extrpd">Drop Location City</div>
                                                                        <div className="info-data-avl fb">{item.demand_node_location}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col pl0">
                                                                    <div className="tAlignCenter">
                                                                        <div className="div-tr-section-bottom">Distance from Plant</div>
                                                                        <div className="info-data-avl fb">{item.distance_from_plant}</div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col pl0">
                                                                    <div className="tAlignCenter">
                                                                        <div className="div-tr-section-bottom extrpd">Week Off</div>
                                                                        <div className="info-data-avl fb">{
                                                                        (item.holiday !== "" && item.holiday !== undefined)?
                                                                          item.holiday  
                                                                        :""

                                                                    }</div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col pl0">
                                                                    <div className="tAlignCenter">
                                                                        <div className="div-tr-section-bottom">Demand Volume (M3)</div>
                                                                        <div className="info-data-avl fb">{item.demand_volume}</div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col pl0">
                                                                    <div className="tAlignCenter">
                                                                        <div className="div-tr-section-bottom extrpd">Weight (Kgs)</div>
                                                                        <div className="info-data-avl fb">{item.demand_weight}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col pl0">
                                                                    <div className="tAlignCenter">
                                                                        <div className="div-tr-section-bottom extrpd">Quantity</div>
                                                                        <div className="info-data-avl fb">{item.quantity}</div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col pl0">
                                                                    <div className="tAlignCenter">
                                                                        <div className="div-tr-section-bottom extrpd">Delivery Date</div>
                                                                        <div className="info-data-avl fb">{item.delivery_date}</div>
                                                                    </div>
                                                                </div>


                                                              {/*                                                                 
                                                                <div className="col-sm-4 pl0">
                                                                    <div className="div-tr-avl">Order No: 
                                                                      <span className="info-avl fb">{item.order_number}</span>
                                                                    </div>
                                                                </div> */}
                                                                {/* <div className="col-sm-4 pl0">
                                                                    <div className="div-tr-avl">Distance From Plant: 
                                                                      <span className="info-avl fb">{item.distance_from_plant}</span>
                                                                    </div>
                                                                </div> 
                                                                <div className="col-sm-4 pl0">
                                                                    <div className="div-tr-avl">Week Off: 
                                                                      <span className="info-avl fb">{
                                                                        (item.holiday !== "" && item.holiday !== undefined)?
                                                                          item.holiday  
                                                                        :""

                                                                    }</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 pl0">
                                                                    <div className="div-tr-avl">Demand Volume (M3): 
                                                                      <span className="info-avl fb">{item.demand_volume}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 pl0">
                                                                    <div className="div-tr-avl">Weight (Kgs): 
                                                                      <span className="info-avl fb">{item.demand_weight}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 pl0">
                                                                    <div className="div-tr-avl">Quantity: 
                                                                      <span className="info-avl fb">{item.quantity}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 pl0">
                                                                    <div className="div-tr-avl">Dealer Type: 
                                                                      <span className="info-avl fb">{item.dealer_type}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 pl0">
                                                                    <div className="div-tr-avl">Delivery Date: 
                                                                      <span className="info-avl fb">{item.delivery_date}</span>
                                                                    </div>
                                                                </div>*/}
                                                                

                                                              </div>
                                                              
                                                            </div>
                                                        </div>
                                                      </div>
                                                  </div>
                                                ))
                                              :"No Data Available"
                                            }
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                {/* END */}
                            </div>
                            </div>
                        </div>

                        {/* Map BLock Start */}
                        <div className={"details-b "+this.state.detailsB}>
                            <div className="row">
                                <div className="col-sm-12">
                                    {this.state.trip_gps_data.length > 0 ? (
                                        <DrawMapDisptach
                                            context={this}
                                            plant_loc_data={this.state.plant_loc_data}
                                            trip_gps_data={availableVehiclesGpsData}
                                            trip_nodes_data={availableVehiclesNodeData}
                                            palletLoadLocations={this.state.unPlannedData}
                                            viewtype={this.state.viewcount}
                                            map_for={this.state.mapFor}
                                            tripsData={this.state.filteredData}
                                        />
                                        ) : (
                                        ""
                                    )}
                                    <div className="details-v-map">
                                        <div className="close-cl pointer poabs mpcls" onClick={this.closeDetails.bind(this)}>
                                            <i class="fa fa-close"></i>
                                        </div>
                                    {/* {console.log("this.state.rownode",this.state.rownode)} */}
                                        {(this.state.rownode.length > 0)?
                                            <div className="row det-lbl">
                                                <div className="col-sm-12">
                                                    <h4 className="details-v-title-map">
                                                        {this.state.rownode[0].vehicle_no}
                                                    </h4>
                                                </div>
                                                
                                                <div className="form-group col lbl-ml">
                                                    <label className="lbl-ml">Vehicle Type</label>
                                                    <div className="lbl-desc-mp">
                                                        {this.state.rownode[0].vehicle_type}
                                                    </div>
                                                </div>
                                                <div className="form-group col lbl-ml">
                                                    <label className="lbl-ml">Total Quantity</label>
                                                    <div className="lbl-desc-mp">
                                                        {this.state.rownode[0].total_quantity}
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group col lbl-ml">
                                                    <label className="lbl-ml">Vehicle Utilization (%)</label>
                                                    <div className="lbl-desc-mp">
                                                        {
                                                        (this.state.rownode[0].total_weight !== "" && this.state.rownode[0].total_weight !== undefined)?
                                                        (Math.max(
                                                            ((this.state.rownode[0].total_weight / this.state.rownode[0].vehicle_max_weight) *
                                                            100
                                                            ).toFixed(2),((this.state.rownode[0].total_demand / this.state.rownode[0].vehicle_max_volume) *
                                                            100
                                                        ).toFixed(2)))
                                                        :0}
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group col lbl-ml">
                                                    <label className="lbl-ml">Onward Trip (Kms)</label>
                                                    <div className="lbl-desc-mp">
                                                        {this.state.rownode[0].distance}
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group col lbl-ml">
                                                    <label className="lbl-ml">Round Trip (Kms)</label>
                                                    <div className="lbl-desc-mp">
                                                        {this.state.rownode[0].round_trip_distance}
                                                    </div>
                                                </div>
                                                <div className="form-group col lbl-ml">
                                                    <label className="lbl-ml">No Of Customers</label>
                                                    <div className="lbl-desc-mp">
                                                        {this.state.rownode[0].no_of_customer}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        :""}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Map BLock End */}
                        

                        {/* Trip Details BLock Start */}
                        <div className={"details-bk "+this.state.detailsBlk}>
                            <div className="details-v-b">
                                <div className="close-cl pointer poabs mpcls" onClick={this.closeDetails.bind(this)}>
                                    <i class="fa fa-close"></i>
                                </div>
                            {/* {console.log("this.state.rownode",this.state.rownode)} */}
                                {(this.state.rownode.length > 0)?
                                    (this.state.rownode[0].legs_data.length > 0)?
                                           
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <h4 className="details-v-title-b">
                                                            Order Details - {this.state.rownode[0].vehicle_no} ({this.state.rownode[0].vehicle_type})
                                                        </h4>
                                                    </div>
                                                    {this.state.rownode[0].legs_data.map((itm,ix) =>
                                                        <div className="col-sm-12 lbl-m  det-lbl-b">
                                                            {/* {console.log("asdsa", itm)} */}
                                                            <div className="form-group row">
                                                                <div className="col">
                                                                    <div className="lbl-b fbold">
                                                                        {
                                                                        (itm.drop_node_total_time_day !== "" && itm.drop_node_total_time_day !== undefined) ?
                                                                            "Day - " + (parseInt(parseInt(itm.drop_node_total_time_day)) + 1)
                                                                            : "Day - " + itm.drop_node_total_time_day
                                                                        }
                                                                        <br />
                                                                        Sequence No: {itm.drop_sequence}
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="lbl-b">Drop Code</div>
                                                                    <div className="lbl-desc-b">{itm.drop_node}</div>
                                                                </div>
                                                                
                                                                <div className="col-3">
                                                                    <div className="lbl-b">Destination</div>
                                                                    <div className="lbl-desc-b">{itm.drop_node_name}</div>
                                                                </div>
                                                                
                                                                <div className="col">
                                                                    <div className="lbl-b">Destination City</div>
                                                                    <div className="lbl-desc-b">{itm.demand_node_location}</div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="lbl-b">Dealer Type</div>
                                                                    <div className="lbl-desc-b">{itm.dealer_type}</div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="lbl-b">Week Off</div>
                                                                    <div className="lbl-desc-b">{itm.week_off}</div>
                                                                </div>
                                                                {/* <div className="col">
                                                                    <label className="lbl-b">Order No</label>
                                                                    <div className="lbl-desc-b">
                                                                    {itm.order_number}
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            <div className="row divir">
                                                               
                                                                <div className="col form-group">
                                                                    <label className="lbl-b">Invoice Date</label>
                                                                    <div className="lbl-desc-b">
                                                                        {itm.invoicing_date}
                                                                    </div>
                                                                </div>

                                                                
                                                                <div className="col form-group">
                                                                    <label className="lbl-b">Quantity</label>
                                                                    <div className="lbl-desc-b">
                                                                        {itm.quantity}
                                                                    </div>
                                                                </div>
                                                                <div className="col form-group">
                                                                    <label className="lbl-b">Weight (Kgs)</label>
                                                                    <div className="lbl-desc-b">
                                                                        {itm.demand_weight}
                                                                    </div>
                                                                </div>
                                                                <div className="col form-group">
                                                                    <label className="lbl-b">Volume (M3)</label>
                                                                    <div className="lbl-desc-b">
                                                                        {itm.demand_volume}
                                                                    </div>
                                                                </div>
                                                                <div className="col form-group">
                                                                    <label className="lbl-b">Distance (Kms)</label>
                                                                    <div className="lbl-desc-b">
                                                                        {itm.distance}
                                                                    </div>
                                                                </div>
                                                                <div className="col form-group">
                                                                    <label className="lbl-b">Expected Delivery</label>
                                                                    <div className="lbl-desc-b">
                                                                        {itm.delivery_date}
                                                                    </div>
                                                                </div>
                                                                <div className="col form-group">
                                                                    <label className="lbl-b">Time To Destination</label>
                                                                    <div className="lbl-desc-b">
                                                                        {
                                                                            (itm.drop_node_total_time_hrs !== "" && itm.drop_node_total_time_hrs !== undefined)?
                                                                            getHyphenDDMMMYYYYHHMM(itm.drop_node_total_time_hrs)
                                                                            :""
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )}
                                                    
                                                </div>
                                          
                                        
                                        :""
                                :""}
                                        
                            </div>
                        </div>
                        {/* Trip Details BLock End */}


                        {/* Edit Plan */}
                        <div
                            className={"edt-details-b  " + this.state.showSlideEditVehiclePlan}
                            style={{ overflowX: "hidden" }}
                        >
                            { this.state.showNewPlan == false ? 
                            <div className="edt-b">
                                <div className="close-cl pointer poabs mpcls" onClick={this.closeDetails.bind(this)}>
                                    <i class="fa fa-close"></i>
                                </div>
                                <h5 className="p-10p">Update Vehicle Plan</h5>
                                <div className="row p-15p">
                                    <div className="col-sm-12">
                                        <span className="ml-0 s-tags pl-10 float-left d-flex ">Max Dispatch Time : 
                                                <input type="datetime-local" className="form-control dtField" name="editplanmaxdispatch" id="editplanmaxdispatch" autoComplete='off'
                                                onKeyDown={(e) => e.preventDefault()}  
                                                onChange={this.handlerStartDateTime}
                                                min={this.getCurrentDateTime()} 
                                                value={this.state.editplanmaxdispatch}
                                                style={{}}
                                                readonly />
                                        </span>
                                        <span className=" ml-15p s-tags float-left">VFR % : {this.state.editplanvfr}</span>
                                        <span className=" ml-15p  s-tags float-left">Quantity : {this.state.editplanqty}</span>
                                        <span className=" ml-15p s-tags float-left ">Weight : {this.state.editplanvehicle_weight}</span>
                                        <span className=" ml-10p s-tags float-left">Volume : {this.state.editplanvehicle_volume}</span>
                                        <span className=" ml-10p s-tags float-left">Max Weight : {this.state.editplanmaxvehicleweight}</span>
                                        <span className=" ml-10p s-tags float-left">Max Volume : {this.state.editplanmaxvehiclevolume}</span>
                                        
                                       
                                    </div>
                                    <div className="col-sm-12 mt-20p">
                                        <div className="row">
                                            <div className="col-sm-9">
                                              <h5 className="">Planned OBD
                                                
                                                <span className="float-right s-tags">
                                                
                                                  <input className="mr-2 mr-0 "  type="checkbox" id="sundayplanning" checked = {this.state.is_sunday_planning_enabled === 1 ? true : false} onClick={this.handlesundayplanning} name="is_sunday_planning_enabled" value={this.state.is_sunday_planning_enabled} disabled={!this.state.enable_sunday_planning} />
                                                  <label className="f13" style={{margin:'0px'}} htmlFor="sundayplanning" for="css">&nbsp;&nbsp;Enable DC Week Off planning &nbsp;&nbsp;</label> 
                                                </span>
                                              </h5>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group row">
                                                    <div className="col-sm-4 col-md-5 tAlignRight">
                                                        <div className="mt-10p">Select Vehicle</div>
                                                    </div>
                                                    <div className="col-sm-8 col-md-7 j-select">
                                                        <Select
                                                            value={this.state.newObdVehicleType}
                                                            isDisabled = {this.state.newObdVehicleType.value != '' ? true : false}
                                                            onChange={this.handleNewObdVehicleType}
                                                            options={this.state.vehicleTypeList}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mb-30p">
                                        <div id="myGrid3" style={{width:"100%"}} className="j-grid-height-a ag-theme-balham dropdown j-grid">
                                            <AgGridReact 
                                                rowData={this.state.dispatchPlanObdUpdateData}
                                                columnDefs={existingPlanColDfs}
                                                gridOptions={{context:{componentParent:this},rowSelection: 'multiple',}}
                                                defaultColDef={this.state.defaultColDef}
                                                onRowDataUpdated={this.handleRowDataChanged}
                                                frameworkComponents={this.state.frameworkComponents}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection= {true}
                                                onGridReady={this.onGridReady3}
                                                onGridState={this.onGridState3}
                                                onSelectionChanged={this.onPlannedDataSelectionChanged}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <h5 className="mt-5p">Unplanned OBD</h5>
                                        <div id="myGrid3" style={{width:"100%"}} className="j-grid-height-a ag-theme-balham dropdown j-grid">
                                            <AgGridReact 
                                                rowData={this.state.unPlannedData}
                                                columnDefs={updatedunPlannedcolsDefs}
                                                gridOptions={{context:{componentParent:this},rowSelection: 'multiple'}}
                                                defaultColDef={this.state.defaultColDef}
                                                frameworkComponents={this.state.frameworkComponents}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection= {true}
                                                onGridReady={this.onGridReady4}
                                                onGridState={this.onGridState4}
                                                onSelectionChanged={this.onPlannedDataSelectionChanged}
                                            />
                                        </div>
                                    </div>

                                
                                    <div className="col-sm-12 mt-15p d-flex justify-content-end">
                                        <div className="form-group">
                                        <button
                                            type="button"
                                            className="btn btn-default j-f15 actionBtns"
                                            onClick={this.handleChangePlan}
                                        >
                                            Change Plan
                                        </button>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                            :
                            <div className="edt-b">
                                <div className="close-cl pointer poabs mpcls" onClick={this.closeDetails.bind(this)}>
                                    <i class="fa fa-close"></i>
                                </div>
                                <h5 className="p-10p">Update Vehicle Plan</h5>
                                <div className="row p-10p">
                                <h5 className="col-sm-10 d-flex align-items-end" style={{ fontWeight: "bold" }}>New Plan</h5>

                                <div id="myGrid3" style={{width:"100%",height:"50vh"}} className="col-sm-12 ag-theme-balham dropdown">
                                    <AgGridReact 
                                        rowData={this.state.dispatchNewPlanObdData}
                                        columnDefs={newPlanColDfs}
                                        gridOptions={{context:{componentParent:this},rowSelection: 'multiple',}}
                                        defaultColDef={this.state.defaultColDef}
                                        onRowDataUpdated={this.handleRowDataChanged}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection= {true}
                                        onGridReady={this.onGridReady3}
                                        onGridState={this.onGridState3}
                                    />
                                </div>
                                <div className="col-md-12 pt-3">
                                    <div className="col-md-12 mt-5p">
                                    <div className="float-left col-md-4">
                                    <div className="float-left col-sm-11">
                                        <label htmlFor="remarks" style={{ fontWeight: "bold" }}>
                                        Reason for plan change <span className="redColor">*</span> :
                                        </label>
                                        <Select options={remarks} name="remarks" onChange={this.onChangeRemark} value={this.state.remark} />
                                    </div>
                                    </div>
                                    <div className={`${this.state.remark.value === "Others" ? "show-m" : "show-n"} float-left ml-4`}>
                                        <label className="" style={{ fontWeight: "bold" }} htmlFor="otherreason">
                                        Enter the Reason<span className="redColor"> * </span>:
                                        </label>
                                        <input
                                        onChange={this.onChangeSubRemark}
                                        id="otherreason"
                                        value={this.state.subReason}
                                        className="col-md-12"
                                        type="text"
                                        style={{height:'37px'}}
                                        />
                                    </div>
                                    <div className="form-group float-right mt-4">
                                        <button type="button" className="btn btn-primary actionBtns" onClick={this.updateThePlan}>
                                        Update
                                        </button>
                                        <button type="button" className="btn btn-danger clsBtn" onClick={this.cancelplanhideSlideBlock}>
                                        Cancel
                                        </button>
                                    </div>
                                    </div>
                                </div>

                                </div>
                            </div>
                            }
                            <div className={"chartLoading " + this.state.editplanloader}>
                                <div className="loaderpos">
                                <img src={require("../../assets/images/loading.gif")} />
                                </div>
                            </div>
                        </div>

                        {/* END */}


                        {/* All Map Routes */}
                        <div className={"details-b "+this.state.showSlideMap}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="li-pos-a">
                                      <li className="float-right f13 ml-30p">
                                          <label className="mt-19p d-flex align-items-center"><input onClick={this.onChnageVehicleType.bind(this)} checked={this.state.additional_vehicles} value={this.state.additional_vehicles} name="additional_vehicles" type="checkbox"/>&nbsp;Additional Vehicles</label>
                                      </li>
                                      <li className="float-right f13 align-items-center">
                                          <label className="d-flex align-items-center"><input onClick={this.onChnageVehicleType.bind(this)} name="regular_vehicles" checked={this.state.regular_vehicles} value={this.state.regular_vehicles} type="checkbox"/>&nbsp;Regular Vehicles</label>
                                      </li>
                                    </ul>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {this.state.trip_gps_data_all.length > 0 ? (
                                            <DrawMapDisptachRoutes
                                                context={this}
                                                plant_loc_data={this.state.plant_loc_data}
                                                trip_gps_data={availableVehiclesGpsDatal}
                                                trip_nodes_data={availableVehiclesNodeDatal}
                                                palletLoadLocations={this.state.unPlannedData}
                                                viewtype={this.state.viewcount}
                                                map_for={this.state.mapFor}
                                                tripsData={this.state.filteredData}
                                            />
                                            ) : (
                                            ""
                                            )}
                                             <div className="close-cl pointer poabs-all mpcls" onClick={this.closeDetails.bind(this)}>
                                                <i class="fa fa-close"></i>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* END */}

                        {/* Assign Vehicle Start */}

                        <div className={"details-A "+this.state.showSlideAssignVehicle}>
                            <div className="details-v-AA">
                                <div className="close-cl pointer poabs mpcls" onClick={this.closeDetails.bind(this)}>
                                    <i class="fa fa-close"></i>
                                </div>
                                <div className="row p-15p mt-60p">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                        <label>Select Vehicle</label>
                                        <Select
                                            value={this.state.assignvehicle}
                                            onChange={this.onChangeAssignVehicle.bind(this)}
                                            options={this.state.assign_vehicle_list}
                                        />
                                        </div>
                                    </div>
                                    <div className={"col-sm-12 " + this.state.showAssignFields}>
                                        <div className="form-group">
                                        <label>Enter Vehicle No</label>
                                        <input
                                            type="text"
                                            name="other_vehicle_no"
                                            className="form-control"
                                            value={this.state.other_vehicle_no}
                                            onChange={this.changeHandler.bind(this)}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                        <button
                                            type="button"
                                            className="btn btn-default actionBtns"
                                            onClick={this.onClickAssignSelectedVehicle.bind(this)}
                                        >
                                            Assign Vehicle
                                        </button>
                                        </div>
                                    </div>
                                    <div className={"chartLoading " + this.state.assignVehicleloader}>
                                        <div className="loaderpos">
                                        <img src={require("../../assets/images/loading.gif")} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* END */}
                    </div>
                    {/* Update or Assign Vehicle Start */}
                    <Modal
                        closeOnOverlayClick={false}
                        open={this.state.openUpdateVehicleModel}
                        onClose={this.handelUpdateModelCancel}
                        classNames={{ modal: "vehiclepriority-modelClass handle-postion" }}
                    >
                        <div className="col-sm-12 p-30p">
                        <div className="mt-20p mb-10p">
                            <h6 className="tAlignCenter f24 alrtmsg">
                            Are you sure you want to update <b>Vehicle Number</b> ?
                            </h6>
                        </div>

                        <div className="btns-center mt-35p">
                            <button
                            type="button"
                            onClick={this.handleUpdateVehicle}
                            className="btn tbtn ass-cancel-btn actionBtns cfnt20"
                            >
                            Yes
                            </button>
                            <button
                            type="button"
                            className="btn tbtn cancel-btn ass-cancel-btn brdrad30 cfnt20"
                            onClick={this.handelUpdateModelCancel}
                            >
                            No
                            </button>
                        </div>
                        </div>
                    </Modal>
                    {/* AEND */}
                    {/*Plan Config Start  */}
                    <div className={"details-A "+this.state.planconf}>
                        
                        <div className="details-v-cnf">
                        <   div className="close-cl pointer poabs mpcls" onClick={this.closeDetails.bind(this)}>
                                <i class="fa fa-close"></i>
                            </div>
                            <div className="col-sm-12">
                                <h4 className="details-v-title-b">
                                    Plan Configurations
                                </h4>
                            </div>
                            <div className="row p-15p">
                                <ConfigSummary  handleOnClose={this.onClickCloseConfigs} openConfigmd={this.state.openSavedConfigs} data={this.state.configs}/>
                            </div>
                        </div>
                    </div>
                    {/* END */}
                    <div className={this.state.actionloader}>
                      <div className={"pg-loading "} onClick={this.closeDetails.bind(this)}></div>
                      <div className="pg-loaderpos">
                        <img src={require("../../assets/images/loading.gif")} />
                      </div>
                    </div>

                    {/* Order Information Start */}
                    <div className={"edt-details-b "+this.state.orderinuts}>
                      <div className="edt-b">
                          <div className="close-cl pointer poabs mpcls" onClick={this.closeDetails.bind(this)}>
                              <i class="fa fa-close"></i>
                          </div>
                          <h5 className="p-10p">Order Inputs</h5>
                          <div className="row">
                            <div className="col-sm-12">
                              <div id="myGrid5" style={{width:"100%"}} className="j-grid-oInfo ag-theme-balham dropdown j-grid">
                                <AgGridReact
                                  // modules={this.state.modules} 
                                  rowData={updatedInputdetails}
                                  columnDefs={inputDetailscolsDefs}
                                  gridOptions={{ context: { componentParent: this } }}
                                  defaultColDef={this.state.defaultColDef}
                                  frameworkComponents={this.state.frameworkComponents}
                                  paginationPageSize={this.state.paginationPageSize}
                                  pagination={true}
                                  enableRangeSelection={true}
                                  sideBar={this.state.sideBar}
                                  enablePivot={true}
                                  statusBar={this.state.statusBar}
                                />  
                              </div>
                            </div>
                          </div>
                            
                        </div>

                    </div>
                    {/* END */}
                </div>
        )
    }
}


function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  
  function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
  }
  
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 
  
  function GetSortAscOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }
  
  function arr_diff(a1, a2) {
    var a = [],
      diff = [];
  
    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }
  
    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }
  
    for (var k in a) {
      diff.push(k);
    }
  
    return diff;
  }
  
  function addTimes(timeArray) {
    let totalSeconds = 0;
  
    for (let i = 0; i < timeArray.length; i++) {
      const [hours, minutes, seconds] = timeArray[i].split(":").map(Number);
      if (seconds != undefined && seconds != "") {
        totalSeconds += hours * 3600 + minutes * 60 + seconds;
      } else {
        totalSeconds += hours * 3600 + minutes * 60;
      }
    }
  
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = totalSeconds % 60;
  
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }
  

