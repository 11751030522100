import React, { Component } from "react";
import Level4 from "./configL4";

class Level3 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            click: true,
        }
    }
    componentDidMount = async () => {
        var data = this.props.data
        var keys = Object.keys(data)
    }
    statusChange = async () => {
        var data = this.props.data
        var keys = Object.keys(data)
        keys.map((item1) => {
            if (item1 !== 'type' && item1 !== 'disabled' && item1 !== 'value' && item1 !== 'name' && item1 !== 'id') {
                data[item1].value = 0
            }
        })
        
    }
    changeHandler = async (item, event) => {
        var data = this.props.data
        var keys = Object.keys(data)
        // var activities = this.state.changes
        if (data[item].value === 0) {
            data[item].value = 1
            if (data[item].type == 'radio') {
                keys.map((key) => {

                    if (data[item].id === data[key].id && data[item].name !== data[key].name) {
                        data[key].value = 0
                    }
                })
            }
        } else {
            data[item].value = 0
        }
        if (item === 'No of Vehicles Types') {
            data[item].value = parseInt(event.target.value)
        }

        if(data[item].type==='number'){
            data[item].value=parseInt(event.target.value)
        }
        await this.setState((prevState) => {
            return ({ click: !prevState.click })
        })
    }
    render() {


        var data = this.props.data
        var keys = Object.keys(data)
        if (this.props.status === 0) {
            this.statusChange()

        }
        return (<div>
            {keys.map((item,idx) => {
                if (item !== 'type' && item !== 'disabled' && item !== "value" && this.props.status === 1 && item !== 'name' && item !== 'id') {

                    return (
                        <div className="row ml-3 per col-md-12"key={idx} id={item}  style={{ display: 'block' }}>

                            <label className="fw-600"><input type={data[item].type} onChange={(event) => { this.changeHandler(item, event) }} value={data[item].value} checked={data[item].value === 1} name={data[item].id}  />&nbsp;&nbsp;{item}</label>
                            <Level4 data={data[item]} status={data[item].value} />
                        </div>)
                }
            })}
        </div>)
    }
}

export default Level3