import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

export default class ColumnDrilldownLoadingDashboard extends Component {

    render() {
        //console.log("this.props.code) ", this.props.code)


        const dStyles = {
            width: '100%',
            height: '100%'
        }
        const highchartoptions = {
            chart: {
                type: 'column',
                // event:{
                //     drilldown:handleDrilldown.bind(this)
                // }
            },
            title: {
                text: this.props.title,
                //align:'left'
            },
            subtitle: {
                text: ''
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: this.props.yaxistitle
                }

            },
            legend: {
                enabled: false
            },
            // plotOptions: {
            //     series: {
            //         borderWidth: 0,
            //         dataLabels: {
            //             enabled: true,
            //             format: '{point.y}'
            //         }
            //     }
            // },

            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br> <b>{point.weights}</b>'
                // formatter:function(){
                // 	console.log(this.series)
                // }
            },

            series: [
                {
                    name: "Transporters",
                    colorByPoint: true,
                    data: this.props.series,
                    pointWidth:90,
                }
            ],
            drilldown: {
                series: this.props.drilldownseries
            },
            credits: false
        };

        return (
            <div>
                <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
            </div>
        );
    }
}