import React, { useState, useEffect } from "react";
import redirectURL from "../../redirectURL";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import NewConfigL1 from "./newconfgL1";
export default function ConfigsNewModel(props) {
  var [records, setRecords] = useState([]);
  var [show, setShow] = useState(false);
  var [basicType, setBasicType] = useState("");
  var [basicTitle, setBasicTitle] = useState("");
  var [location, setLocation] = useState("");
  var [locationNames, setLocationNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const location_access = JSON.parse(
          localStorage.getItem("location_access")
        );
        const response = await redirectURL.post(
          "master/getBoundTypeLocations",
          { data_type: 2 }
        );
        if (response.data.status === "success") {
          const boundLocations = response.data.boundLocations;
          const newLocationNames = [];
          boundLocations.forEach((item) => {
            if (location_access != null) {
              if (location_access.includes(item.location_code)) {
                newLocationNames.push({
                  value: item.location_code,
                  label: item.location_name,
                });
              }
            } else {
              newLocationNames.push({
                value: item.location_code,
                label: item.location_name,
              });
            }
          });
          if (newLocationNames.length > 0) {
            setLocationNames(newLocationNames);
            setLocation(newLocationNames[0]);
          } else {
            setLocationNames([{ label: "Please Add Data", value: "no_data" }]);
            setLocation({ label: "Please Add Data", value: "no_data" });
          }
        }
        // setLoadshow("show-n");
      } catch (error) {
        var errDetails = {
          url: "master/getBoundTypeLocations",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    redirectURL
      .post("/master/getConfigureNew", { location_code: location.value })
      .then((res) => {
        // console.log(res.data, "hai");
        if (res.data.length) {
          setRecords(res.data[0]);
        } else {
          setShow(true);
          setBasicTitle("No Data Available");
          setBasicType("danger");
          setLocation(locationNames[0]);
        }
      });
  }, [location]);

  const closeAlert = () => {
    setShow(false);
    if (basicTitle === "Data Saved successfully") {
      window.location.reload();
    }
  };
  const locationChanged = (location) => {
    setLocation(location);
  };

  delete records._id;
  delete records.status;
  delete records.created_date;
  //   delete records.location_code;
  function saveOutboundConfigs() {
    var outboundRec = { ...records };
    var keys1 = Object.keys(outboundRec);
    keys1.map((item1) => {
      var data1 = outboundRec[item1];
      if (data1.value === 0) {
        if (data1.data !== undefined) {
          var data2 = data1.data;
          var keys2 = Object.keys(data2);
          keys2.map((item2) => {
            data2[item2].value = 0;
            if (data2[item2].data !== undefined) {
              var data3 = data2[item2].data;
              var keys3 = Object.keys(data3);
              keys3.map((item3) => {
                data3[item3].value = 0;
                if (data3[item3].data !== undefined) {
                  var data4 = data3[item3].data;
                  var keys4 = Object.keys(data4);
                  keys4.map((item4) => {
                    data4[item4].value = 0;
                  });
                }
              });
            }
          });
        }
      } else {
        // console.log(data1.data, "l");
        // var keys5=Object.keys(data1)
        if (data1.data !== undefined) {
          var data2 = data1.data;
          var keys2 = Object.keys(data2);
          keys2.map((item2) => {
            if (data2[item2].value === 0) {
              if (data2[item2].data !== undefined) {
                var data3 = data2[item2].data;
                // var data4 = data3.data;
                var keys3 = Object.keys(data3);
                keys3.map((item3) => {
                  data3[item3].value = 0;
                  if (data3[item3].data !== undefined) {
                    var data4 = data3[item3].data;
                    var keys4 = Object.keys(data4);
                    keys4.map((item4) => {
                      data4[item4].value = 0;
                    });
                  }
                  return true;
                });
              }
            } else {
              if (data2[item2].data !== undefined) {
                var data3 = data2[item2].data;
                // var data4 = data3.data;
                var keys3 = Object.keys(data3);
                keys3.map((item3) => {
                  if (data3[item3].value === 0) {
                    if (data3[item3].data !== undefined) {
                      var data4 = data3[item3].data;
                      var keys4 = Object.keys(data4);
                      keys4.map((item4) => {
                        data4[item4].value = 0;
                        return true;
                      });
                    }
                  }
                  return true;
                });
              }
            }
            return true;
          });
        }
      }
    });

    outboundRec.location_code = location.value;
    // console.log(outboundRec, "out");
    redirectURL.post("/master/saveConfigNew", outboundRec).then((response) => {
      if (response.status === 200) {
        setShow(true);
        setBasicType("success");
        setBasicTitle("Data Saved successfully");
      }
      // window.location.reload();
    });
  }
  //   console.log(records, "hello");
  return (
    <>
      <SweetAlert
        show={show}
        type={basicType}
        title={basicTitle}
        onConfirm={closeAlert}
      ></SweetAlert>
      <div className="row mb-20p pt-20p">
        <div className="col-sm-12 d-flex">
          <div className={"col-sm-12   d-inline"}>
            <h5 className="fbold  d-flex justify-content-between">
              <span>Plant Wise Configurations</span>
            </h5>
          </div>
        </div>
      </div>
      <div className="card container d-flex align-items-center">
        <div className="card-body ml-5">
          <div className="col-md-2 col-sm-12">
            <div className="form-group ">
              <label className="col-form-label f12">Plants</label>
              <Select
                placeholder={"Select"}
                onChange={locationChanged}
                name="location"
                value={location}
                options={locationNames}
              />
            </div>
          </div>
          <div className="container d-flex align-items-center ">
            <div className="row">
              <div className="float-right">
                <NewConfigL1 data={records} />
              </div>
              <button
                type="button"
                className="btn btn-danger float-left"
                onClick={saveOutboundConfigs}
              >
                Save Plant Wise Configurations
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
