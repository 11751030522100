import React, { useEffect, useState } from 'react';

function Newwebsite() {

    const [showpopup,setshowpopup]= useState('delete-popup')
    useEffect(() => {
        const currentUrl = window.location.href;
        const targetUrl = 'https://nestle.optirun.in/';

        if (currentUrl.includes('http://nestle.enmovil.net/')) {
            const timer = setTimeout(() => {
                window.location.href = targetUrl;
            }, 5000); 

            return () => clearTimeout(timer);
        }
    }, []);

    
    function redirectToNewWebsite(){
        window.location.href = 'https://nestle.optirun.in/';
    }


    return (
        <div>
            {window.location.href.includes('http://nestle.enmovil.net/') ? (
                <div className={"sliderBlock " + showpopup}>
                <p className="fbold redColor f18" >You are being redirected to the new website in 5 seconds...</p>
                    <button className="btn btn-success" onClick={redirectToNewWebsite}>Redirect Now</button>
                </div>
            ) : ''}
        </div>
    );
}

export default Newwebsite;
