import { CSVLink } from 'react-csv';
import React, { useState, useEffect } from 'react';
import redirectURL from '../redirectURL';

function HTMLCSVApp({api}) {


  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
    fetch(api)
      .then((response) => response.text())
      .then(async (html) => {
  
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const table = doc.querySelector('.dataframe');

        if (table) {
          const headers = Array.from(table.querySelectorAll('th')).map((th) => th.textContent);
          const rows = Array.from(table.querySelectorAll('tr')).slice(1); 

          const items = rows.map((row) => {
            const tds = row.querySelectorAll('td');
            return headers.reduce((obj, header, index) => {
              obj[header] = tds[index].textContent;
              return obj;
            }, {});
          });
          // console.log(items,'itnnw')
        await setData(items);
        }

      })
      .catch((error) => console.error(error));
  }, [api]);

  let headers = [
    { label: 'Name', key: 'consigner_code' },
    { label: 'Age', key: 'consigner_code' },
    { label: 'City', key: 'shipment_id' },
  ];

  let file = '';
  const clickedOnDownload=()=>{
    // console.log('hit')
    let logParams = {
      // "location_code" : location.value,
      // "location_name" : location.label,
      "user_name" : localStorage.getItem("username"),
      "useremail" : localStorage.getItem("email"),
      "client" : localStorage.getItem("client"),
      "screen" : "KPI dashboard",
      "activity" : 'clicked on Export to csv button',
      "event" : "excel with kpi data has been downloaded",
      // "data_type" : this.state.data_type,
  }

  redirectURL.post("/master/loguserUpdatesorChanges",logParams)
  }

  if(api == 'http://216.48.191.229:8010/dispatch_planning/vehiclePlansOverPeriod' ){
    file = 'Plan Summary';
    headers = [
      { label: 'Plant Code', key: 'plant_location_code' },
      { label: 'Transaction Id', key: 'transaction_id' },
      { label: 'Trip No', key: 'trip_no' },
      { label: 'Vehicle Type', key: 'vehicle_type' },
      { label: 'Vehicle No', key: 'vehicle_no' },
      { label: 'Start Date', key: 'plan_start_date' },
      { label: 'Max Dispatch Time', key: 'planed_vehicle_start_time' },
      { label: 'Plan Type', key: 'plan_type' },
      // { label: 'Executed Vehicle Start Time', key: 'executed_vehicle_start_time' },
      { label: 'Planned Return Time To Plant', key: 'planed_return_time_to_plant' },
      // { label: 'Executed Return Time To Plant', key: 'executed_return_time_to_plant' },
      { label: 'Weight', key: 'weight' },{ label: 'Volume', key: 'volume' },{ label: 'Vehicle Max Weight', key: 'max_weight' },{ label: 'Vehicle Max Volume', key: 'max_volume' },{ label: 'Volume Utilization', key: 'volume_util' },
      { label: 'Weight Utilization', key: 'weight_util' },{ label: 'Quantity(cases)', key: 'quantity' },
      { label: 'Total Planned Trip Time(Hrs)', key: 'planed_total_plan_time_hrs' },
      // { label: 'Executed Total Plan Time(Hrs)', key: 'executed_total_plan_time_hrs' },
      { label: 'Total Round Trip Time(Hrs)', key: 'planed_round_trip_time' },
      // { label: 'Executed Round Trip Time(Hrs)', key: 'executed_round_trip_time' },
      { label: 'Round Trip(Kms)', key: 'planed_round_trip_distance' },
      // { label: '', key: 'executed_round_trip_distance' },
      { label: 'Onward Trip Distance(Kms)', key: 'planed_distance' },
      // { label: '', key: 'executed_distance' },
      { label: 'No Of Customers Planned', key: 'no_of_customers_planned' },{ label: 'No Of Customers Served', key: 'no_of_customers_served' },
      { label: 'Total Demand Volume', key: 'chart_data_total_demand_volume' },{ label: 'Total Planned Volume', key: 'chart_data_planned_demand_volume' },
      { label: 'Total Unplanned Volume', key: 'chart_data_unplanned_demand_volume' },{ label: 'Dropped Volume', key: 'chart_data_dropped_demand_volume' },
      { label: 'Total Demand Weight', key: 'chart_data_total_demand_weight' },{ label: 'Total Planned Weight', key: 'chart_data_planned_demand_weight' },
      { label: 'Total Unplanned Weight', key: 'chart_data_unplanned_demand_weight' },{ label: 'Dropped Weight', key: 'chart_data_dropped_demand_weight' },
      { label: 'Total Quantity(cases)', key: 'chart_data_total_quantity' },{ label: 'Total Planned Quantity(cases)', key: 'chart_data_planned_quantity' },
      { label: 'Total Unplanned Quantity(cases)', key: 'chart_data_unplanned_quantity' },{ label: 'Dropped Quantity(cases)', key: 'chart_data_dropped_quantity' },
    ];

  }else if (api == 'http://216.48.191.229:8001/generateShipmentPlanningAdherenceData' ){
    file = 'Plan Adherence';
    // const deviation_in_customer_serving = 'deviation_in_customer_serving' == 1 ? 'Yes' : 'No' ;
    // const sequence_not_followed = 'sequence_not_followed' == 1 ? 'Yes' : 'No' ;
    // const distance_deviation = 'distance_deviation' == 1 ? 'Yes':'No' ;
    headers = [
      { label: 'Plant Code', key: 'consigner_code' },
      { label: 'Shipment Id', key: 'shipment_id' },
      { label: 'Vehicle No', key: 'truck_no' },
      { label: 'Plant Exit Time', key: 'loading_yard_exit_time' },
      { label: 'Plant Reached Time', key: 'reached_plant_at' },
      { label: 'TAT Hours', key: 'truck_tat_hours' },
      { label: 'No Of Planned Customers', key: 'planned_customers' },
      { label: 'No Of Served Customers', key: 'served_customers' },
      { label: 'Customer Deviation', key: "deviation_in_customer_serving" },
      { label: 'Sequence Not Followed', key: "sequence_not_followed" },{ label: 'Total Distance', key: 'total_distance_km' },
      { label: 'Actual Round Trip(Kms)', key: 'actual_round_trip_km' },{ label: 'Distance Deviation', key:  "distance_deviation"},
    ];
  }else if (api == 'http://216.48.191.229:8010/dispatch_planning/coolChainAdherenceChartsData' ){
    file = 'Cool Chain Adherence';
    headers = [
      { label: 'Plant Code', key: 'plant_location_code' },
      { label: 'Transaction Id', key: 'transaction_id' },
      { label: 'Planned Orders', key: 'planned_orders' },
      { label: 'Unplanned Orders', key: 'unplanned_orders' },
      { label: 'Invalid Orders', key: 'invalid_orders' },
      { label: 'Total Orders', key: 'total_orders' },
      { label: 'Order Date', key: 'order_date' },
    ];
  }else if (api == 'http://216.48.191.229:8001/generateShipmentPlanningOntimeAdherenceData' ){
    file = 'Ontime Plan Adherence';
    headers = [
      { label: 'Plant Code', key: 'consigner_code' },
      { label: 'Transaction Id', key: 'transaction_id' },
      { label: 'Shipment Id', key: 'shipment_id' },
      { label: 'Order No(OBD)', key: 'consignment_code' },
      { label: 'Vehicle No', key: 'truck_no' },
      {label: 'Loading Time(Mins)', key: 'loading_time_mins'},
      {label: 'Loading Time Per Case(Mins)', key: 'loading_time_mins_per_case'},
      { label: 'Shipment Created Date', key: 'vehicle_start_time_mins' },
      { label: 'Shipment Time', key: 'shipment_time' },
      { label: 'Plant Exit Time', key: 'loading_yard_exit_time' },
      { label: 'Status', key: 'status' },
      { label: 'Planned Dealer Time', key: 'drop_node_total_time_hrs' },
      { label: 'Actual Dealer Time', key: 'recent_dealer_reported' },
      { label: 'Ontime Delivery', key: 'ontime_delivery' },
      { label: 'Ontime Dispatch', key: 'ontime_dispatch' },
      { label: 'Gps Quality', key: 'gps_quality_flag' },
    ];
  }else if (api == 'http://216.48.191.229:8001/unloadingBIDashboardData' ){
    file = 'Unloading Data'
    headers = [
      { label: 'Plant Code', key: 'consigner_code' },
      { label: 'Shipment Id', key: 'shipment_id' },
      { label: 'Order No(OBD)', key: 'consignment_code' },
      { label: 'Trip No', key: 'trip_no' },
      { label: 'Vehicle No', key: 'truck_no' },
      { label: 'Plant Exit Time', key: 'loading_yard_exit_time' },
      { label: 'Dealer Code', key: 'consignee_code' },
      { label: 'Dealer Name', key: 'consignee_name' },
      { label: 'Dealer City', key: 'consignee_city' },
      { label: 'Quantity(cases)', key: 'demand_quantity' },
      { label: 'Dealer Reached Time', key: 'recent_dealer_reported' },
      { label: 'Dealer Left Time', key: 'left_dealer_marked' },
      { label: 'Expected Unloading Time(Hrs)', key: 'unloading_time' },
      { label: 'Expected Unloading Time(Mins)', key: 'expected_unloading_time_mins' },
      { label: 'Unloading Time(Hrs)', key: 'unloading_time_in_hrs' },
      { label: 'Unloading Time(Mins)', key: 'unloading_time_mins' },
      { label: 'Unloading Status', key: 'unloading_status' },
      { label: 'Actual Unloading per Case(Mins)', key: 'actual_unloading_time_per_case' },
    ];
  }
  else if (api == 'http://216.48.191.229:8001/fetchShipmentsLastestStatusData' ){
    file = 'Shipment Current Status'
    headers = [
      { label: 'Plant Code', key: 'consigner_code' },
      { label: 'Shipment Id', key: 'shipment_id' },
      // { label: 'Order No(OBD)', key: 'consignment_code' },
      // { label: 'Trip No', key: 'trip_no' },
      { label: 'Vehicle No', key: 'truck_no' },
      { label: 'Shipment Time', key: 'shipment_time' },
      { label: 'Status', key: 'status' }
    ];
  }
  

  return (
    <div>
      <button type="button"  onClick={clickedOnDownload}
                        className="btn btn-danger ml-2p float-right"
                        ><i className="fa fa-download"></i>&nbsp;&nbsp;
      {data.length>0 ? <CSVLink data={data} headers={headers} filename={file+'.csv'} className='fbold white'>
         Export to CSV
      </CSVLink> :'Loading..'}
      </button>
    </div>
  );
}

export default HTMLCSVApp;
