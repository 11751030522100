
import React, { Component } from 'react';

 
const Consolidatedbtn = (props) => {
    
    return (
        <div style={{"textAlign":"center"}}>           
            Consolidated Run           
        </div>
    );
};

export default Consolidatedbtn;
