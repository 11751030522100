import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { getHyphenDDMMMYYYYHHMM} from '../common/utils'; 
import {verifyext} from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import ColumnChart from "./columnChartComponent";
import PolarChart from "./polarChartComponent";
import AreaRangeChart from "./areaRangeChart";
import ConfidenceChart from "./confidenceChart";
import LineChart from "./lineChart";


var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

export default class ForecastingComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            showSlideBlockStack:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            fyChart:{label:"Select",value:""},
            fyCostChart:{label:"Select",value:""},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            scluster:"",
            stackContent:"",
            stackImage:"",
            stacktbl:[],
            stacktblbottom:[],
            stackOriginalData:[],
            showrunprocessbtn:"show-m",
            showanimate:"show-n",
            locationNames:[],
            loadFile:'',
            material_configurations:0,
            location:{label:"Select Location",value:""},
            loadshow:'show-m',
            overly:'show-m',
            processErrMsg : "",
            processErrShow : "",
            transactionId : "",
            droppedList : [],
            customer_code:{"value":"all","label":"All"},
            material_code:{"value":"all","label":"All"},
            duration:{"value":"all","label":"All"},
            customerlist:[],
            materiallist:[],
            durationlist:[],
            chartdataF:"show-n",
            datagridF:'show-m',
            datagridTabF:"activet",
            chartdataTabF:"btn-default",
            chartseries:[],
            confidenceTrain:[],
            confidenceTrend:[],
            confidenceTestPoints : [], 
            confidencePredictPoint : [],
            seasonality_train_plot:[],
            seasonality_pred_plot:[],
            seasonality_train_plotdata:[],
            allseason:[],
            chartCusts:[],
            chartItems:[],
            chartCustomer:{label:"Select",value:""},
            chartItem:{label:"Select",value:""},
            customerwisedata:[],
            allChartData:[],
            confAreaData:[],
            confTrendplot:[],
            confScatter:[],
            confAreaYHat:[],
            confLineSeasonal:[],
            confAreaDataDemo:[],
            forecastingCustomersData:[],
            chartSeriesByCustomers:[]
        }
        
    }
    async componentWillReceiveProps(newprops) {
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            overlayBlock:"show-n",
            chartSeriesByCustomers:[],
            rowData:[],
            overly:'show-n',
        })
        if(newprops.forecastingCustomersData !== this.state.forecastingCustomersData)
        {
            this.setState({
                forecastingCustomersData:newprops.forecastingCustomersData
            })
            await this.loadOutPlan(newprops.forecastingCustomersData)
        }
        
    }
    loadOutPlan(forecastingCustomersData){
        this.setState({
            loadshow:'show-n',
            overly:'show-m',
            overlayBlock:"show-n",
        })
        var forecastingFor = forecastingCustomersData;
        // console.log("Component , forecastingFor ",forecastingFor)
        if(forecastingFor.length > 0)
        {
            this.setState({
                loadshow:'show-n',
                overly:'show-m',
                overlayBlock:"show-n",
            });
            var params = {
                rownodes : forecastingFor
            }
            redirectURL.post("/forecast/forecastingFor",params)
            .then((response) => {
                
                var chartDataaa = response.data.chartData; 
                var records = response.data.predict;
                var history = response.data.history;
                var rowdata  = [];
                var months = ["Jan","Feb","Mar", "Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
                console.log("records ", records)
                

                if(history.length > 0)
                {
                    var itmdataHt = groupBy(history, rdata => rdata.fy);
                    
                    // console.log("itmdata ", itmdata)
                    try {
                        itmdataHt.forEach((valueslist, kT)=>{

                            if(valueslist.length > 0)
                            {
                                var valueslistXC =  groupBy(valueslist, rdata => rdata.customer_code);
                                valueslistXC.forEach((valuesA,aKey) => {
                                    if(valuesA.length > 0)
                                    {
                                        var valueslistX =  groupBy(valuesA, rdata => rdata.item_code);
                                        valueslistX.forEach((values,keys)=>{
                                            var rw = {}
                                                if(values.length > 0)
                                                {
                                                    rw["_id"] = values[0]._id;
                                                    rw["transaction_id"] = values[0].transaction_id;
                                                    rw["fy"] = values[0].fy;
                                                    rw["fy_start_year"] = values[0].fy_start_year;
                                                    rw["fy_end_year"] = values[0].fy_end_year;
                                                    rw["item_code"] = values[0].item_code;
                                                    rw["customer_code"] = values[0].customer_code;
                                                    rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
            
                                                values.map((i) => {
                                                    rw[months[parseInt(i.month)-1]] = i.item_qty;
                                                    rw[months[parseInt(i.month)-1]+"_cost"] = i.item_total_cost;
                                                })
                                                // console.log("RWW ", JSON.stringify(rw))
                                                // rowdata.push(rw);
                                            }
                                        })
                                    }
                                })
                                
                            }
                        })
                    } catch (error) {
                        
                    }
                }

                if(records.length > 0)
                {
                    // console.log("records Before", records)
                    // records = records.filter((f) => (f.customer_code === itf.customer_code && f.item_code === itf.item_code));
                    
                    // console.log("records After", records)
                    var itmdatay = groupBy(records, rdata => rdata.fy);
                    try {
                        // console.log("itmdatay ", itmdatay)
                        itmdatay.forEach((itmdata,kk) => {
                        
                        var itmdatag = groupBy(itmdata, rdata => rdata.customer_code);
                        
                            itmdatag.forEach((valuesar,keys)=>{
                                if(valuesar.length > 0)
                                {
                                    var valuesinf = groupBy(valuesar, rdata => rdata.item_code);
                                    // console.log("valuesinf ", valuesinf)
                                    valuesinf.forEach((values,vkeys)=>{
                                        var rw = {}
                                        var exmons = [];
                                        if(values.length > 0)
                                        {
                                            rw["_id"] = values[0]._id;
                                            rw["transaction_id"] = values[0].transaction_id;
                                            rw["fy"] = values[0].fy;
                                            rw["fy_start_year"] = values[0].fy_start_year;
                                            rw["fy_end_year"] = values[0].fy_end_year;
                                            rw["item_code"] = values[0].item_code;
                                            rw["customer_code"] = values[0].customer_code;
                                            rw["nrb_customer_code_1"] = values[0].nrb_customer_code_1;
                                            rw["item_cost"] = values[0].iteminfo[0].item_cost

                                            values.map((i,n) => {
                                                rw[months[parseInt(i.month)-1]] = i.item_quantity;
                                                rw[months[parseInt(i.month)-1]+"_cost"] = parseInt(i.item_quantity)*(i.iteminfo[0].item_cost);
                                                exmons.push(months[parseInt(i.month)-1]);
                                                
                                            })
                                            // console.log("RWW ", rw)
                                        
                                        }
                                        
                                        if(exmons.length > 0){
                                            var diffkeys = arr_diff(exmons,months);
                                            // console.log("diffkeys ", diffkeys)
                                            if(diffkeys.length > 0)
                                            {
                                                diffkeys.map((id) => {
                                                    if(id !== "itemIndex")
                                                    {
                                                        rw[id] = 0;
                                                        rw[id+"_cost"] = 0;
                                                    }
                                                })
                                            }
                                        }
                                        rowdata.push(rw);
                                    })
                                }
                                
                            })
                        
                            
                        });
                    } catch (error) {
                                
                    }
                }
                
                // console.log("chartDataaa ", chartDataaa)
                var chartSeriesByCustomer = []
                if(chartDataaa.length > 0)
                {
                    forecastingFor.map((itf) => {
                        // console.log('itf ', itf)
                        if(itf !== null && itf !== "")
                        {
                            
                        }
                    })
                    forecastingFor.map((itf ,ind) => {
                        if(itf !== null && itf !== "")
                        {
                            
                             var chartDatalist = [];
                                chartDataaa.map((itm, index) =>{
                                    // console.log("itm.chart_data ", itm.chart_data)
                                    if(itm.chart_data !== "" && itm.chart_data !== undefined)
                                    {
                                        
                                        try {
                                            itm["chart_data"] = JSON.parse(itm.chart_data);
                                            itm["month_data"] = JSON.parse(itm.month_data);
                                            
                                        } catch (error) {
                                            
                                        }
                                        chartDatalist.push(itm)
                                        
                                        
                                    }
                                })
                            
                                if(chartDatalist.length > 0)
                                {
                                    var chartData = chartDatalist.filter((f) => (f.customer_code === itf.customer_code && f.item_code === itf.item_code));
                                
                                }
                                else{
                                    var chartData = [];
                                }
                                // console.log("index ", index)
                                // console.log("chartSeriesByCustomer ", chartSeriesByCustomer.length)
                                if(chartSeriesByCustomer.length < 5)
                                {
                                var areaplot=[];
                                var areaYHat=[];
                                var trendplot=[];
                                var areaScatter=[];
                                var confLineSeasonal=[]

                                var monthsChart = ["04", "05","06","07","08","09","10","11","12","01","02","03"];
                                var monChart = ["Jan","Feb","Mar", "Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
                                try{
                                    if(chartData.length > 0)
                                    {
                                        chartData.map((mm) => {
                                            var chartSeries = mm.chart_data;
                                            var motnhSeries = mm.month_data;
                                            if(chartSeries.length > 0)
                                            {
                                                chartSeries.map((mt) => {
                                                    var tms = moment.parseZone(mt.ds).format("x");
                                                    // areaplot.push({name:parseInt(tms),y:mt.area_plot});
                                                    areaplot.push(mt.area_plot);
                                                    areaYHat.push((mt.yhat < 0)?0:mt.yhat);
                                                    if(mt.ds !== "" && mt.ds !== undefined)
                                                    {
                                                        var trnedns = moment.parseZone(mt.ds).format("YYYY");
                                                        var trnednsm = moment.parseZone(mt.ds).format("YYYY, MM");
                                                        // trendplot.push({name:trnedns,y:Math.round((mt.trend < 0)?0:mt.trend),lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                                        // confLineSeasonal.push({name:tms/1000,y:Math.round(mt.yearly),lbl:Math.round(mt.yearly).toLocaleString()});
                                                        // confLineSeasonal.push([tms/1000,mt.yearly]);
                                                        if(mt.trend !== undefined && mt.trend !== "NAT" && mt.trend !== null && mt.trend !== "")
                                                        {
                                                            if(parseInt(trnedns) < 2022)
                                                            {
                                                                trendplot.push({name:trnedns,y:mt.trend,lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                                            }
                                                        }
                                                    
                                                    }
                                                    if(mt.actual !== "" && mt.actual !== undefined && mt.actual !== null)
                                                    {
                                                        // if(mt.actual > 0)
                                                        // {
                                                            areaScatter.push(mt.actual);
                                                        // }
                                                        
                                                    }
                                                })
                                                
                                            }
                                            if(motnhSeries.length > 0)
                                            {
                                                motnhSeries.map((mt) => {
                                                    confLineSeasonal.push({name:mt.month,y:Math.round(mt.month_sum),lbl:Math.round(mt.month_sum).toLocaleString()});
                                                    
                                                    
                                                })
                                            }
                                        })
                                    }
                                    // if(seasonality_train_plot.length > 0)
                                    // {
                                    //     var ss = seasonality_train_plot[seasonality_train_plot.length-1]
                                    // }
                                    // else{
                                    //     var ss = [];
                                    // }
                                }
                                catch(e){}
                                // console.log("confLineSeasonal ", confLineSeasonal)
                                // this.setState({
                                //     loadshow:'show-n',
                                //     overly:'show-n',
                                //     overlayBlock:"show-n",
                                    
                                //     // chartCusts:chartCustsarr,
                                //     // chartItems:chartItemarr,
                                //     // allChartData:chartDatalist,
                                //     confAreaData:areaplot,
                                //     confAreaYHat:areaYHat,
                                //     confTrendplot:trendplot,
                                //     confScatter:areaScatter,
                                //     confLineSeasonal:confLineSeasonal
                                // })
                                chartSeriesByCustomer.push({
                                    customer_code:itf.customer_code,
                                    item_code:itf.item_code,
                                    chartSeries:[{
                                        confAreaData:areaplot,
                                        confAreaYHat:areaYHat,
                                        confTrendplot:trendplot,
                                        confScatter:areaScatter,
                                        confLineSeasonal:confLineSeasonal
                                    }]
                                })
                            }
                        }
                    
                    })
                }
                // console.log("chartSeriesByCustomer ", chartSeriesByCustomer)
                // console.log("rowdata ", rowdata)
                this.setState({
                    chartSeriesByCustomers:chartSeriesByCustomer,
                    rowData:rowdata,
                    overly:'show-n',
                })
            })
        }
        
    }
    
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    
    onClickShowUpload = () =>{
        this.setState({
            showSlideBlockUpload:"slide25",
            overlayBlock:"show-m"
        })
    }

    hideSlideBlock = () =>{
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:"",
            showSlideBlockRoute:"",
            showSlideBlockStack:"",
            loadFile:'',
            loadshow:'show-n',
            overly:'show-n',
        })
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }
    fixProcess = () => {
        this.setState({
            processErrMsg : "",
            processErrShow : 0,
            loadFile:''
        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
        //document.getElementById("upform").reset();
    }
    ignoreProcessError = () => {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var transactionId = this.state.transactionId;
        console.log("transactionId",transactionId)
        var params = {
            transactionId : transactionId,
            data_type : this.state.data_type,
        }
        redirectURL.post("dispatch/generateLoadByTransactionId",params).then((response)=>{
            //console.log(response.data,"response")
            var statusCode = response.data.status;
            if(statusCode == 0 || statusCode == 1)
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    processErrMsg : "",
                    processErrShow : 0,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
            }
            else
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            //window.location.reload();
        });
    }
    
    changeFileHandler = async (e) => {
        console.log(e);
        this.setState({
            loadFile : e.target.files[0]
        });  
        
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    
    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if(flag == 0)
        {      
            saveData.append("loadFile", this.state.loadFile);
            var notify_email = $("#notify_email").val();
            saveData.append("notify_email", notify_email);
            saveData.append("module_type", 1);
            saveData.append("data_type", this.state.data_type);
            saveData.append("material_configurations", this.state.material_configurations)
            let location = this.state.location;
            saveData.append("location_code", location.value)
            saveData.append("location_name", location.label)
            saveData.append("user_name", localStorage.getItem('username'))
            saveData.append("email", localStorage.getItem('email'))
            console.log(saveData,saveData);
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
            redirectURL.post("/dispatch/generateLoad",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                console.log(response,"responseFromProcess")
                var statusCode = response.data.status;
                if(statusCode == 8)
                {
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        droppedList : response.data.droppedList,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else if(statusCode > 3 && statusCode != 8)
                {
                    if(statusCode == 11)
                    {
                        var processMsg = "Use the standard input data file template for uploading loads";
                    }
                    else
                    {
                        var processMsg = response.data.message;
                    }
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else
                {
                    this.checkTransactionStatus(response.data.transactionId);
                    // this.setState({                    
                    //     basicTitle : response.data.message,
                    //     basicType : "success",
                    //     show : true,
                    //     loadshow:'show-n',
                    //     overly:'show-n',
                    //     showSlideBlockUpload:"",
                    // });
                }
                //window.location.reload();
            });
        }
        else
        {
            this.setState({
                basicTitle : "Please upload the file",
                basicType : "warning",
                show : true,
            })
        }
    };

    checkTransactionStatus = (transactionId) =>{
        var query = {
            transaction_id : transactionId
        }
        redirectURL.post("/dispatch/checkTransactionStatus",query).then((response)=>{
            console.log(response,"responseFromcheckTransactionStatus")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode == 0)
            {
                var checkCount = this.state.checkCount;
                var updatedCnt = parseInt(checkCount)+1;
                this.setState({
                    checkCount : updatedCnt
                });
                // console.log(updatedCnt,"checkCount")
                if(updatedCnt < 3)
                {
                    this.chkStatus(response.data.transactionId);
                }
                else
                {
                    this.setState({                    
                        basicTitle : "File is processing",
                        basicType : "success",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        showSlideBlockUpload:"",
                        loadFile:''
                    })
                    $("#uploadFile").val("");
                    $("#notify_email").val("");
                }
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
                this.getDispatchPlanData()
            }
        });
    }

    chkStatus = (transactionId) => {
        this.checkTransactionStatus(transactionId);
    }

    viewPlanDetails = async(propdata) =>{
        var data = propdata.data;
        let basestring = data.transaction_id;
        let encryptedstring = window.btoa(basestring);
        window.location.href = '/viewdispatchplandetails?'+encryptedstring;
    }


    locationChanged = async(location) =>{
        await this.setState({
            location : location
        })
        await this.getDispatchPlanData()
    }
    onChangeCustomer(customer_code){
        this.setState({customer_code})
    }
    onChangeMaterial(material_code){
        this.setState({material_code})
    }
    onChangeDuration(duration){
        this.setState({duration})
    }
    onSubmitGetData(event){
        event.preventDefault();
        var customer_code = this.state.customer_code.value;
        var material_code = this.state.material_code.value;
        var duration = this.state.duration.value;
        var params = {
            customer_code:customer_code,
            item_code:material_code,
            fc_period:duration
        }
        redirectURL.post("/forecast/outputdata",params)
        .then((response) => {
            var rowdata = response.data;
            
            this.setState({
                rowData:rowdata
            })
        })
    }
    onClickTab(oVal){
        if(oVal == "1")
        {
            this.setState({
                datagridF:"show-m",
                chartdataF:"show-n",
                datagridTabF:"activet",
                chartdataTabF:"btn-default"
            })
        }
        if(oVal == "2")
        {
            this.setState({
                datagridF:"show-n",
                chartdataF:"show-m",
                datagridTabF:"btn-default",
                chartdataTabF:"activet"
            })
        }
    }

    fyitems(){
        var items = [];
        var c = moment.parseZone().format("YYYY");
        for(var n = 2016;n<parseInt(c)-1;n++)
        {
            items.push({value:n+"-"+(parseInt(n)+1),label:n+"-"+(parseInt(n)+1)})
        }
        return items;
    }

    onChangeFYDataOld(fyChart){
        this.setState({fyChart},()=>{
            // console.log("fyChart ", this.state.fyChart)
           var chartseries = this.state.chartseries[0].historic;
           var pchartseries = this.state.chartseries[0].predictData;
        //    var selectedfymonthsaa = this.state.chartseries.selectedfymonths;
        //    console.log("selectedfymonthsaa ", selectedfymonthsaa)
           console.log("chartseries ", chartseries)
           console.log("pchartseries ", pchartseries)
            var selectedfymonths = chartseries.filter((f) => this.state.fyChart.value === f.name);
            console.log("selectedfymonths ", selectedfymonths)
            var newchartseries = this.state.chartseries;
        //    newchartseries["selectedfymonths"] = selectedfymonths
        newchartseries[0]["allfymonthwise"]=[pchartseries,selectedfymonths[0]]
        console.log("newchartseries ", newchartseries)
            this.setState({
                chartseries:newchartseries
            })
        })
    }
    onChangeFYData(fyChart){
        this.setState({fyChart},()=>{
            // console.log("fyChart ", this.state.fyChart)
           var chartseries = this.state.allseason
        //    console.log("chartseries ", chartseries)
           var sp = this.state.fyChart.value.split("-");
        //    console.log(sp)
            var selectedfymonths = chartseries.filter((f) => parseInt(sp[0]) === f.name);
            // console.log("selectedfymonths ", selectedfymonths)

            this.setState({
                seasonality_train_plot:selectedfymonths[0]
            })
        })
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    onGridReady1 = (params) => {
		this.gridApiNew = params.api;
		this.gridColumnApiNew = params.columnApi;

    };

    onChangeFYCostData(fyCostChart){
        this.setState({fyCostChart},()=>{
            console.log("fyChart ", this.state.fyChart)
           var chartseries = this.state.chartseries[0].historicCost;
           var pchartseries = this.state.chartseries[0].predictCostData;
        //    var selectedfymonthsaa = this.state.chartseries.selectedfymonths;
        //    console.log("selectedfymonthsaa ", selectedfymonthsaa)
        //    console.log("chartseries ", chartseries)
        //    console.log("pchartseries ", pchartseries)
            var selectedfymonths = chartseries.filter((f) => this.state.fyCostChart.value === f.name);
            // console.log("selectedfymonths ", selectedfymonths)
            var newchartseries = this.state.chartseries;
        //    newchartseries["selectedfymonths"] = selectedfymonths
        newchartseries[0]["allfymonthwisecost"]=[pchartseries,selectedfymonths[0]]
        console.log("newchartseries ", newchartseries)
            this.setState({
                chartseries:newchartseries
            })
        })
    }
    onChangeChartCustomer(chartCustomer){
        this.setState({chartCustomer})
    }
    onChangeChartItem(chartItem){
        this.setState({chartItem})
    }
    onClickShowCustomerData(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            overlayBlock:"show-n"
        });
        var customer = this.state.chartCustomer.value;
        var itemCode = this.state.chartItem.value;
        // console.log("customer ", customer)

        var chartDataarr = this.state.allChartData;
        var chartData = chartDataarr.filter((f) => (f.customer_code === customer && f.item_code === itemCode));
        // console.log("chartData ", chartDataarr)
        // console.log("chartDatachartData ", chartData)
        var chartDatalist = [];
        var chartCusts=[];
        var chartItems=[];
        var areaplot=[];
        var areaYHat=[];
        var trendplot=[];
        var areaScatter=[];
        var confLineSeasonal=[]

        var monthsChart = ["04", "05","06","07","08","09","10","11","12","01","02","03"];
        var monChart = ["Jan","Feb","Mar", "Apr", "May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        try{
            if(chartData.length > 0)
            {
                chartData.map((mm) => {
                    var chartSeries = mm.chart_data;
                    if(chartSeries.length > 0)
                    {
                        chartSeries.map((mt) => {
                            var tms = moment.parseZone(mt.ds).format("x");
                            // areaplot.push({name:parseInt(tms),y:mt.area_plot});
                            areaplot.push(mt.area_plot);
                            areaYHat.push((mt.yhat < 0)?0:mt.yhat);
                            if(mt.ds !== "" && mt.ds !== undefined)
                            {
                                var trnedns = moment.parseZone(mt.ds).format("YYYY");
                                var trnednsm = moment.parseZone(mt.ds).format("YYYY, MM");
                                // trendplot.push({name:trnedns,y:Math.round((mt.trend < 0)?0:mt.trend),lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                
                                if(mt.trend !== undefined && mt.trend !== "NAT" && mt.trend !== null && mt.trend !== "")
                                {
                                    // if(parseInt(trnedns) < 2022)
                                    // {
                                        trendplot.push({name:trnedns,y:mt.trend,lbl:Math.round((mt.trend < 0)?0:mt.trend).toLocaleString()});
                                    // }
                                }
                                confLineSeasonal.push({name:tms/1000,y:Math.round(mt.yearly),lbl:Math.round(mt.yearly).toLocaleString()});
                                // confLineSeasonal.push([tms/1000,mt.yearly]);
                            }
                            if(mt.actual !== "" && mt.actual !== undefined && mt.actual !== null)
                            {
                                // if(mt.actual > 0)
                                // {
                                    areaScatter.push(mt.actual);
                                // }
                                
                            }
                        })
                        
                    }
                })
            }
            // if(seasonality_train_plot.length > 0)
            // {
            //     var ss = seasonality_train_plot[seasonality_train_plot.length-1]
            // }
            // else{
            //     var ss = [];
            // }
        }
        catch(e){}
        // console.log("confLineSeasonal ", confLineSeasonal)
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            overlayBlock:"show-n",
            
            // chartCusts:chartCustsarr,
            // chartItems:chartItemarr,
            // allChartData:chartDatalist,
            confAreaData:areaplot,
            confAreaYHat:areaYHat,
            confTrendplot:trendplot,
            confScatter:areaScatter,
            confLineSeasonal:confLineSeasonal
        })
    }
    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };
    onBtExportExcel = () => {
        this.gridApi.exportDataAsExcel();
    };
    onBtnExportF = () => {
        this.gridApiNew.exportDataAsCsv();
    };
    onBtExportExcelF = () => {
        this.gridApiNew.exportDataAsExcel();
    };
    showCharts(oVal){
        $(".b_"+oVal).removeClass("show-n")
        $(".b_"+oVal).addClass("show-m")
        $(".s_"+oVal).removeClass("show-m")
        $(".s_"+oVal).addClass("show-n")
    }
    hideCharts(oVal){
        $(".b_"+oVal).removeClass("show-m")
        $(".b_"+oVal).addClass("show-n")
        $(".s_"+oVal).removeClass("show-n")
        $(".s_"+oVal).addClass("show-m")
    }
    render() {

        var columnwithDefs = [
            // {
            //     headerName: "Transaction Id",
            //     field: "transaction_id",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "Financial Year",
                field: "fy",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Item Code",
                field: "item_code",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Customer Code",
                field: "customer_code",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            // {
            //     headerName: "NRB Customer Code",
            //     field: "nrb_customer_code_1",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            
            
            {
                headerName: "Apr",
                field: "Apr",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "May",
                field: "May",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jun",
                field: "Jun",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jul",
                field: "Jul",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Aug",
                field: "Aug",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Sep",
                field: "Sep",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Oct",
                field: "Oct",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Nov",
                field: "Nov",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Dec",
                field: "Dec",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Jan",
                field: "Jan",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Feb",
                field: "Feb",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Mar",
                field: "Mar",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            }
            
           
            
        ]
        return (
            <div class="container-fluid">
             
               
              
                <div className="row mt-20p">
                    <div className="col-md-12 col-sm-12">
                        <ul className="d-tabs">
                            <li onClick={this.onClickTab.bind(this,"1")} className={"lttabs "+(this.state.datagridTabF)}>
                                <button type="button" className="btn">Summary Data</button>
                            </li>

                            <li onClick={this.onClickTab.bind(this,"2")} className={"lttabs "+(this.state.chartdataTabF)}>
                                <button type="button" className="btn">Data Visualization</button>
                            </li>

                        </ul>
                    </div>
                </div>
                
                <div className={"row mt-20p "+(this.state.chartdataF)}>
                    <div className={"col-sm-12 "} >
                       &nbsp;
                    </div>
                </div>
                <div className={"row mb-20p "+(this.state.chartdataF)}>
                    {(this.state.chartSeriesByCustomers.length > 0)?
                    (this.state.chartSeriesByCustomers.map((item,indx) => 
                        <div className="row">
                            {console.log("indx ", indx)}
                            <div className="col-sm-12 mt-30p sCharts" style={{fontSize:"24px", background:"#f5f5f5", padding:"10px"}}>
                                Customer Name: {item.customer_code}  &nbsp;&nbsp;
                                
                                Material Code: {item.item_code}
                                <button type="button" className={"btn btn-default float-right "+(indx == 0?'show-n':'show-m')+" s_"+item.customer_code+"_"+item.item_code} style={{fontSize:"12px"}} onClick={this.showCharts.bind(this,item.customer_code+"_"+item.item_code)} id={item.customer_code+"_"+item.item_code}>Show</button>
                                <button type="button" className={"btn btn-default float-right  "+(indx == 0?'show-m':'show-n')+" b_"+item.customer_code+"_"+item.item_code} style={{fontSize:"12px"}} onClick={this.hideCharts.bind(this,item.customer_code+"_"+item.item_code)} id={item.customer_code+"_"+item.item_code}>Hide</button>
                            </div>
                            {(item.chartSeries[0].confAreaData.length > 0)?
                            
                            <div className={"mxheit col-sm-12 mt-20p "+(indx == 0?'show-m':'show-n')+" b_"+item.customer_code+"_"+item.item_code}>
                                <div className="card">
                                
                                    <div className="card-body">
                                        <ConfidenceChart
                                            title={"Demand Forecast"}
                                            subtitle={"Item Code: "+(item.item_code)+" Customer Code: "+(item.customer_code)}
                                            confAreaData={item.chartSeries[0].confAreaData}
                                            confAreaYHat={item.chartSeries[0].confAreaYHat}
                                            confScatter={item.chartSeries[0].confScatter}
                                        />
                                    </div>
                                </div>
                            </div>
                            :""}
                            {(item.chartSeries[0].confAreaData.length > 0)?
                            <div className={"mxheit col-sm-12 mt-20p "+(indx == 0?'show-m':'show-n')+" b_"+item.customer_code+"_"+item.item_code}>
                                <div className="card">
                                
                                    <div className="card-body">
                                    
                                        <LineChart
                                            title={"Yearly Trend"}
                                            subtitle={"Item Code: "+(item.item_code)+" Customer Code: "+(item.customer_code)}
                                            confLineTrend = {item.chartSeries[0].confTrendplot}
                                            
                                        />
                                    </div>
                                </div>
                            </div>
                            :""}
                            {(item.chartSeries[0].confAreaData.length > 0)?
                            <div className={"mxheit col-sm-12 mt-20p "+(indx == 0?'show-m':'show-n')+" b_"+item.customer_code+"_"+item.item_code}>
                                <div className="card">

                                    <div className="card-body">
                                        <AreaRangeChart
                                            title={"Seasonality Data"}
                                            subtitle={"Item Code: "+(item.item_code)+" Customer Code: "+(item.customer_code)}
                                            confLineSeasonal={item.chartSeries[0].confLineSeasonal}
                                            // predictseries={this.state.seasonality_pred_plot}
                                        />
                                    </div>
                                </div>
                            </div> 
                            :""}   
                    </div> 
                    ))
                    :"No data found"}
                    
                    <br /><br />
                </div>
                <div class={"row mb-20p "+(this.state.datagridF)}>
                    <div className="col-sm-12">
                        <button type="button" className="btn btn-warning float-right" onClick={this.onBtnExport.bind(this)}>Export to CSV</button>
                        <button type="button" className="btn btn-info float-right" onClick={this.onBtExportExcel.bind(this)}>Export to Excel</button>
                    </div>
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                // masterDetail={true}
                                // detailCellRendererParams={this.state.detailCellRendererParams}
									
                                />
                        </div>
                    </div>
                    
                </div>
            
                <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>
            </div>
        )
    }
}

function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  } 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function arr_diff (a1, a2) {

    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
}

function isValidJSON(obj){
    try {
      JSON.parse(obj);
      return true;
    } catch (e) {
      return false;
    }
};

$(function(){
    // $(".sCharts").on("click",function(){
        // function showCharts(id)
        // {
        //     // var id = $(this).attr("id");
        //     alert("id "+id)
        //     $(".b_"+id).removeClass("show-n")
        //     $(".b_"+id).addClass("show-m")
        // }
    // })
})