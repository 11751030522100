import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class ColumnChartComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		// console.log("this.props.series ", this.props.series)
		const highchartoptions = {
	      		 
			chart: {
				type: 'area'
			},
			title: {
				align: 'left',
				text: this.props.title
			},
			subtitle: {
				align: 'left',
				text: this.props.subtitle
			},
			accessibility: {
				announceNewData: {
					enabled: true
				}
			},
			xAxis: {
				// type: 'category'
				categories:["2016-2017","2017-2018","2018-2019","2019-2020","2020-2021","2021-2022"]
			},
			yAxis: {
				title: {
					text: this.props.yaxisTitle
				}
		
			},
			legend: {
				enabled: true
			},
			plotOptions: {
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						format: '{point.lbl}'
					}
				}
			},
		
			tooltip: {
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.lbl}'
			},
		
			series: this.props.series,
			drilldown: {},
			credits:false
			};

        return ( 
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

			);
		}
}












