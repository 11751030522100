import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { getHyphenDDMMMYYYYHHMM} from '../common/utils'; 
import {verifyext} from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import ViewTransData from "./viewtransactiondata";
import ForecstingParams from "./forecastingComponent";



var infoBox = require('../common/google-infowindow');
const scaleanimation = {
  transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows=[];
var markersArraylist=[];

export default class ForecastOutputParams extends Component {
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                GridButton:GridButton,
                ViewTransData:ViewTransData
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            showSlideBlockRoute:"sidebarcls",
            showSlideBlockUpload:"sidebarcls",
            showSlideBlockStack:"sidebarcls",
            overlayBlock:"show-n",
            routeData:[],
            rfreshmap:1,
            deliverpickupslist:[],
            dealers:[],
            plants:[],
            uploadfile:'',        
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadplan:[],
            allLoadPlan:[],
            deliverydates:[],
            deliveryopts:[],
            filterDeliveryDate:{"value":"","label":"Select Delivery Date"},
            clusterlist:[],
            filtercluster:{"value":"","label":"Select Cluster"},
            vehicletypelist:[],
            filterVehicleType:{"value":"","label":"All"},
            mapcol:"show-n",
            gridocl:"col-sm-12",
            filter_date:"",
            vtypediv:"show-n",
            vsupplierdiv:"show-n",
            selectedCluster:"",
            fdealerlist:[],
            filterdealer:{"value":"","label":"All"},
            showloader:"show-n",
            mpnode:[],
            mpfor:"",
            clusterpoints:[],
            nmapcluster:"show-n",
            nmap:"show-m",
            scluster:"",
            stackContent:"",
            stackImage:"",
            stacktbl:[],
            stacktblbottom:[],
            stackOriginalData:[],
            showrunprocessbtn:"show-m",
            showanimate:"show-n",
            locationNames:[],
            loadFile:'',
            material_configurations:0,
            location:{label:"Select Location",value:""},
            loadshow:'show-m',
            overly:'show-m',
            processErrMsg : "",
            processErrShow : "",
            transactionId : "",
            droppedList : [],
            customer_code:[{"value":"ALL","label":"All"}],
            material_code:[{"value":"ALL","label":"All"}],
            duration:{"value":"12","label":"All"},
            customerlist:[],
            materiallist:[],
            durationlist:[],
            chartdata:"show-n",
            datagrid:'show-m',
            datagridTab:"activet",
            chartdataTab:"btn-default",
            chartseries:[],
            rowForecastData:[],
            forecastitemslist:[],
            forecastcustomerlist:[],
            allforecastcustomerlist:[],
            forecastitem:{"value":"",label:"Select"},
            forecastCustomer:[{"value":"",label:"All"}],
            predRecords:[],
            forecastingCustomersData:[],
            dataShwo:"show-m",
            forecastData:"show-n",
            dayspred:0,
            inpShow:"show-n"
        }
        
    }
    async componentDidMount() {
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            overlayBlock:"show-n"
        })
        let url_type = window.location.href
        let path_type = url_type.split('?')
        let data_type
        let path = path_type[path_type.length-1]

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'));
        var material_configurations = 0;
        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                material_configurations = toolConfig[0].material_configuration;
            }
        }
        this.setState({
            material_configurations : material_configurations
        });
        var durationlist = [{
            value:1,
            label:"Monthly"
        },{
            value:3,
            label:"Quaterly"
        },{
            value:6,
            label:"Half Yearly"
        },{
            value:12,
            label:"Yearly"
        },{
            value:0,
            label:"Custom"
        }]
        this.setState({
            durationlist:durationlist
        })

        await this.boundTypeLocations(params)
        // await this.getDispatchPlanData()
        await this.customers()
        await this.loadOutPlan()
    }
    loadOutPlan(){
        var params = {
           
        }
        redirectURL.post('/forecast/forecastoutputdata',params)
        .then(response =>{
            var rec = response.data.currentforecast;
            var allforecasts = response.data.allforecasts;
            var predRecords = response.data.predRecords;
            // console.log("predRecords ", predRecords)
            var forecastcustomerlist = [];
            var forecastitemslist = [];
            var selectItemCustomers=[];
            if(predRecords.length > 0)
            {
                var customerCodes = groupBy(predRecords, rdata => rdata.customer_code);
                try {
                    customerCodes.forEach((customerData,key) => {
                        forecastcustomerlist.push({"customer":key});
                        if(customerData.length > 0)
                        {
                            var itmms = groupBy(customerData, rdata => rdata.item_code);
                            itmms.forEach((itemslist,ky) => {
                                forecastitemslist.push({itemcode:ky})
                            })
                        }
                    })
                } catch (error) {
                    
                }
            }
            
            var newArray = [];
            let totuniqueObject = {}; 
            var totobjTitle;
            // Loop for the array elements 
            for (let i in forecastcustomerlist) { 

                // Extract the title
                if(forecastcustomerlist[i]['customer'] !== "" && forecastcustomerlist[i]['customer'] !== undefined)
                {
                    totobjTitle = forecastcustomerlist[i]['customer']; 

                    // Use the title as the index 
                    totuniqueObject[totobjTitle] = forecastcustomerlist[i];
                } 
                 
            } 

            // Loop to push unique object into array 
            for (var a in totuniqueObject) { 
                newArray.push(totuniqueObject[a]); 
            } 
            // console.log("newArray ", newArray)
            var forecastcustomerlistarr = [];
            if(newArray.length > 0)
            {
                newArray.map((item) => {
                    forecastcustomerlistarr.push({value:item.customer,label:item.customer})
                })
            }

            var newArrayItems = [];
            let totuniqueObjectItem = {}; 
            var totobjTitleItem;
            // Loop for the array elements 
            for (let i in forecastitemslist) { 

                // Extract the title
                if(forecastitemslist[i]['itemcode'] !== "" && forecastitemslist[i]['itemcode'] !== undefined)
                {
                    totobjTitleItem = forecastitemslist[i]['itemcode']; 

                    // Use the title as the index 
                    totuniqueObjectItem[totobjTitleItem] = forecastitemslist[i];
                } 
                 
            } 

            // Loop to push unique object into array 
            for (var a in totuniqueObjectItem) { 
                newArrayItems.push(totuniqueObjectItem[a]); 
            } 
            // console.log("newArrayItems ", newArrayItems)

            var forecastitemslistarr = [{value:"all", label:"All"}];
            // var forecastitemslistarr = [];
            if(newArrayItems.length > 0)
            {
                newArrayItems.map((item) => {
                    forecastitemslistarr.push({value:item.itemcode,label:item.itemcode})
                })
            }
            // console.log("forecastcustomerlistarr ", forecastcustomerlist)
            this.setState({
                rowData:rec,
                rowForecastData:allforecasts,
                forecastcustomerlist:forecastcustomerlistarr,
                allforecastcustomerlist:forecastcustomerlistarr,
                forecastitemslist:forecastitemslistarr,
                predRecords:predRecords
            })
        })
    }
    customers = () =>{
        var params = {
           
        }
        redirectURL.post('/forecast/customers',params)
        .then(response =>{
            var rec = response.data;
            var records = [];
            if(rec.length > 0)
            {
                rec.map((item) => {
                    records.push({
                        value:item.customer_code_1,
                        label:item.customer_code_1,
                    });
                })
            }
            this.setState({
                customerlist:records
            })
        })
        redirectURL.post('/forecast/materials',params)
        .then(response =>{
            var rec = response.data;
            var records = [];
            if(rec.length > 0)
            {
                rec.map((item) => {
                    records.push({
                        value:item.item_code,
                        label:item.item_code,
                    });
                })
            }
            this.setState({
                materiallist:records
            })
        })
    }

    // getDispatchPlanData = () =>{
    //     var params = {
    //         data_type : this.state.data_type,
    //         location_code : this.state.location.value,
    //     }
    //     redirectURL.post('dispatch/getDispatchPlanData',params)
    //     .then(response =>{
    //         this.setState({
    //             rowData:response.data,
    //             loadshow:'show-n',
    //             overly:'show-n',
    //             overlayBlock:"show-n"
    //         })
    //     })
    // }

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
                    await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    
    onClickShowUpload = () =>{
        this.setState({
            showSlideBlockUpload:"slide25",
            overlayBlock:"show-m"
        })
    }

    hideSlideBlock = () =>{
        this.setState({
            overlayBlock:"show-n",
            showSlideBlock:"",
            showSlideBlockUpload:"",
            showSlideBlockRoute:"",
            showSlideBlockStack:"",
            loadFile:'',
            loadshow:'show-n',
            overly:'show-n',
        })
        $("#uploadFile").val("");
        $("#notify_email").val("");
    }
    fixProcess = () => {
        this.setState({
            processErrMsg : "",
            processErrShow : 0,
            loadFile:''
        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
        //document.getElementById("upform").reset();
    }
    ignoreProcessError = () => {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var transactionId = this.state.transactionId;
        console.log("transactionId",transactionId)
        var params = {
            transactionId : transactionId,
            data_type : this.state.data_type,
        }
        redirectURL.post("dispatch/generateLoadByTransactionId",params).then((response)=>{
            //console.log(response.data,"response")
            var statusCode = response.data.status;
            if(statusCode == 0 || statusCode == 1)
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    processErrMsg : "",
                    processErrShow : 0,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
            }
            else
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            //window.location.reload();
        });
    }
    changeHandler(event){
        var name= event.target.name;
        var value= event.target.value;
        if(value <= 31)
        {
            this.setState({
                [name]:value
            })
        }
    }
    changeFileHandler = async (e) => {
        console.log(e);
        this.setState({
            loadFile : e.target.files[0]
        });  
        
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    
    uploadBulkFormHandler(event){
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if(flag == 0)
        {      
            saveData.append("loadFile", this.state.loadFile);
            var notify_email = $("#notify_email").val();
            saveData.append("notify_email", notify_email);
            saveData.append("module_type", 1);
            saveData.append("data_type", this.state.data_type);
            saveData.append("material_configurations", this.state.material_configurations)
            let location = this.state.location;
            saveData.append("location_code", location.value)
            saveData.append("location_name", location.label)
            saveData.append("user_name", localStorage.getItem('username'))
            saveData.append("email", localStorage.getItem('email'))
            console.log(saveData,saveData);
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            });
            redirectURL.post("/dispatch/generateLoad",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{
                console.log(response,"responseFromProcess")
                var statusCode = response.data.status;
                if(statusCode == 8)
                {
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        droppedList : response.data.droppedList,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else if(statusCode > 3 && statusCode != 8)
                {
                    if(statusCode == 11)
                    {
                        var processMsg = "Use the standard input data file template for uploading loads";
                    }
                    else
                    {
                        var processMsg = response.data.message;
                    }
                    this.setState({
                        processErrMsg : response.data.message,
                        processErrShow : 1,
                        transactionId : response.data.transactionId,
                        statusCode : statusCode,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                    });
                }
                else
                {
                    this.checkTransactionStatus(response.data.transactionId);
                    // this.setState({                    
                    //     basicTitle : response.data.message,
                    //     basicType : "success",
                    //     show : true,
                    //     loadshow:'show-n',
                    //     overly:'show-n',
                    //     showSlideBlockUpload:"",
                    // });
                }
                //window.location.reload();
            });
        }
        else
        {
            this.setState({
                basicTitle : "Please upload the file",
                basicType : "warning",
                show : true,
            })
        }
    };

    checkTransactionStatus = (transactionId) =>{
        var query = {
            transaction_id : transactionId
        }
        redirectURL.post("/dispatch/checkTransactionStatus",query).then((response)=>{
            console.log(response,"responseFromcheckTransactionStatus")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                });
            }
            else if(statusCode == 0)
            {
                var checkCount = this.state.checkCount;
                var updatedCnt = parseInt(checkCount)+1;
                this.setState({
                    checkCount : updatedCnt
                });
                console.log(updatedCnt,"checkCount")
                if(updatedCnt < 3)
                {
                    this.chkStatus(response.data.transactionId);
                }
                else
                {
                    this.setState({                    
                        basicTitle : "File is processing",
                        basicType : "success",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        overlayBlock:"show-n",
                        showSlideBlockUpload:"",
                        loadFile:''
                    })
                    $("#uploadFile").val("");
                    $("#notify_email").val("");
                }
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    overlayBlock:"show-n",
                    showSlideBlockUpload:"",
                });
                this.getDispatchPlanData()
            }
        });
    }

    chkStatus = (transactionId) => {
        this.checkTransactionStatus(transactionId);
    }

    viewPlanDetails = async(propdata) =>{
        var data = propdata.data;
        let basestring = data.transaction_id;
        let encryptedstring = window.btoa(basestring);
        window.location.href = '/viewdispatchplandetails?'+encryptedstring;
    }


    locationChanged = async(location) =>{
        await this.setState({
            location : location
        })
        await this.getDispatchPlanData()
    }
    onChangeCustomer(customer_code){
        this.setState({customer_code})
    }
    onChangeMaterial(material_code){
        this.setState({material_code})
    }
    onChangeDuration(duration){
        this.setState({duration},()=>{
            if(this.state.duration.value === 0){
                this.setState({
                    inpShow:"show-m"
                })
            }
            else{
                this.setState({
                    inpShow:"show-n"
                })
            }
        })
    }
    onSubmitGetData(event){
        event.preventDefault();
        var customer_code = this.state.customer_code;
        var customercodes = [];
        if(customer_code.length > 0)
        {
            if(customer_code.length > 1)
            {
                customer_code.map((item) => {
                    if(item !== "" && item !== "ALL")
                    {
                        customercodes.push(item.value);
                    }
                })
                var index = customercodes.indexOf("ALL");
                if (index > -1) {
                    customercodes.splice(index, 1);
                }
            }
            else{
                customercodes.push("ALL");
            }
            
            
        }
        
        var material_code = this.state.material_code;
        var materialcodes = [];
        if(material_code.length > 0)
        {
            // console.log(' (material_code.length', material_code.length)
            if(material_code.length > 1)
            {
                material_code.map((item) => {
                    if(item !== "" && item !== "ALL")
                    {
                        materialcodes.push(item.value);
                    }
                    
                })
                var index = materialcodes.indexOf("ALL");
                if (index > -1) {
                    materialcodes.splice(index, 1);
                }
            }
            else{
                materialcodes.push("ALL");
            }
            
        }
        var duration = this.state.duration.value;
        var params = {
            customer_code:customercodes,
            item_code:materialcodes,
            fc_period:duration
        }
        // console.log("params ", params)
        redirectURL.post("/forecast/predictdata",params)
        .then((response) => {
            var rowdata = response.data;
            
            // console.log("rowdata ", rowdata)
            this.setState({
                // rowData:rowdata,
                loadshow:'show-n',
                overly:'show-n',
                overlayBlock:"show-n"
            })
           
            
             this.loadOutPlan()
        })
    }
    onClickTab(oVal){
        if(oVal == "1")
        {
            this.setState({
                datagrid:"show-m",
                chartdata:"show-n",
                datagridTab:"activet",
                chartdataTab:"btn-default"
            })
        }
        if(oVal == "2")
        {
            this.setState({
                datagrid:"show-n",
                chartdata:"show-m",
                datagridTab:"btn-default",
                chartdataTab:"activet"
            })
        }
    }
    onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

    };
    onGridReady1 = (params) => {
		this.gridApiNew = params.api;
		this.gridColumnApiNew = params.columnApi;

    };
    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };
    onBtExportExcel = () => {
        this.gridApi.exportDataAsExcel();
    };
    onBtnExportF = () => {
        this.gridApiNew.exportDataAsCsv();
    };
    onBtExportExcelF = () => {
        this.gridApiNew.exportDataAsExcel();
    };
    onChangeCustomers(forecastCustomer)
    {
        this.setState({forecastCustomer},()=>{
            var sct = this.state.forecastCustomer;
            console.log("sct ", sct)
            if(sct.length > 1)
            {
                var list = [];
                sct.map((item) => {
                    if(item.value !== "")
                    {
                        list.push(item);
                    }
                })
                this.setState({
                    forecastCustomer:list
                })
            }
            else{
                this.setState({
                    forecastCustomer:this.state.forecastCustomer
                })
            }
        })
    }
    onChangeItems(forecastitem)
    {
        this.setState({forecastitem},() =>{
            var selectedItem = this.state.forecastitem.value;
            this.setState({
                forecastCustomer:[{"value":"",label:"All"}]
            })
            setTimeout(() => {
                var predRecords = this.state.predRecords;
                var selectItemCustomers=[];
                if(predRecords.length > 0)
                {
                    
                    var customerlist = predRecords.filter((f) => f.item_code === selectedItem);
                    if(customerlist.length > 0)
                    {
                        var customerCodes = groupBy(customerlist, rdata => rdata.customer_code);
                        try {
                            // console.log("customerCodes ", customerCodes)
                            customerCodes.forEach((values,key) => {
                                selectItemCustomers.push({
                                    value:key,
                                    label:key
                                })
                            })
                        } catch (error) {
                            
                        }
                    }
                }
                // console.log("selectItemCustomers ", selectItemCustomers)
                this.setState({
                    forecastcustomerlist:selectItemCustomers
                })
            },300)
            
        })
    }
    onClickGetItemCustomerData(){
        var itemcode = this.state.forecastitem.value;
        var selectedcustomers = [];
        if(itemcode === "all")
        {
            var forecastcustomerlist = this.state.allforecastcustomerlist;
            // console.log("forecastcustomerlist ", forecastcustomerlist)
            if(forecastcustomerlist.length > 0)
            {
                forecastcustomerlist.map((item) => {
                    selectedcustomers.push(item.value);
                })
            }
        }
        var customercodes = this.state.forecastCustomer;
        var sct = []
        // console.log("customercodes ", customercodes)
        if(customercodes.length > 0)
        {
            customercodes.map((item) => {
                if(item.value !== "")
                {
                    selectedcustomers.push(item.value)
                    
                }
                else{
                    sct.push("all")
                }
                
            })
            // forecastcustomerlist
        }
        if(sct.includes("all") === true)
        {
         var sforecastcustomerlist = this.state.forecastcustomerlist;   
         if(sforecastcustomerlist.length > 0)
            {
                sforecastcustomerlist.map((item) => {
                    selectedcustomers.push(item.value);
                })
            }
        }
        // console.log("itemcode ", itemcode)
        // console.log("selectedcustomers ", selectedcustomers)

        var forecastingCustomersData = []
        var predRecords = this.state.predRecords;
        var selectItemCustomers=[];
        if(predRecords.length > 0)
        {
            predRecords = predRecords.sort(GetSortDescOrder("transaction_id"));
            // console.log("predRecords ", predRecords)
            var trans = []
            if(selectedcustomers.length > 0)
            {
                selectedcustomers.map((ti) => {
                    predRecords.filter((f) => {
                        if(itemcode !== "all")
                        {
                            if(f.customer_code === ti && f.item_code === itemcode)
                            {
                                trans.push({customer_code:f.customer_code,item_code:f.item_code,transaction_id:f.transaction_id})
                            }
                        }
                        else{

                            if(f.customer_code === ti)
                            {
                                trans.push({customer_code:f.customer_code,item_code:f.item_code,transaction_id:f.transaction_id})
                            }
                        }
                        
                    })
                })
            }
            // console.log("transs ", trans)
            if(trans.length > 0)
            {
                var newArrayItems = [];
                let totuniqueObjectItem = {}; 
                var totobjTitleItem;
                // Loop for the array elements 
                for (let i in trans) { 
    
                    // Extract the title
                    if(trans[i]['transaction_id'] != "" && trans[i]['transaction_id'] != undefined)
                    {
                        totobjTitleItem = trans[i]['transaction_id']; 
    
                        // Use the title as the index 
                        totuniqueObjectItem[totobjTitleItem] = trans[i];
                    } 
                     
                } 
    
                // Loop to push unique object into array 
                for (var a in totuniqueObjectItem) { 
                    newArrayItems.push(totuniqueObjectItem[a]); 
                } 
                // console.log("newArrayItems ", newArrayItems)
                newArrayItems = newArrayItems.sort(GetSortDescOrder("transaction_id"));
               var customegroup = groupBy(newArrayItems, rdata => rdata.customer_code);
                try {
                    customegroup.forEach((cit) => {
                        if(cit.length > 0){
                            var otm = groupBy(cit, rdata => rdata.item_code);
                            otm.forEach((mm) => {
                                // console.log("mmmm ", mm)
                                mm.sort(GetSortDescOrder("transaction_id"));
                                forecastingCustomersData.push(mm[0])
                            })
                        }
                    })
                } catch (error) {
                    
                }
                
            }
            // groupBy(customerlist, rdata => rdata.customer_code);
        }
        // console.log("forecastingCustomersData ", forecastingCustomersData)
        this.setState({
            forecastingCustomersData:forecastingCustomersData,
            dataShwo:"show-n",
            forecastData:'show-m'
        })
        
    }
    onClickForecastGrid(){
        this.setState({
            forecastingCustomersData:[],
            dataShwo:"show-m",
            forecastData:'show-n',
            forecastitem:{"value":"",label:"Select"},
            forecastCustomer:[{"value":"",label:"All"}],
        })
    }
    render() {

        var columnwithDefs = [
            // {
            //     headerName: "Transaction Id",
            //     field: "transaction_id",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "Financial Year",
            //     field: "fy",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "Item Code",
                field: "item_code",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Customer Code",
                field: "customer_code",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Duration",
                field: "fc_period",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Forecast Run Date",
                field: "created_on",
                width:180,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    try {
                        if(params.data.created_on !== "" && params.data.created_on !== undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.created_on);
                        }
                        else{
                            return "";
                        }
                    } catch (error) {
                        
                    }
                }
            },
            {
                headerName: "Status",
                field: "status",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "",
                field: "viewdata",
                width:150,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "ViewTransData"
                    };
                    return rendComponent
                
                },
            }
            
        ]
        return (
            <div class="container-fluid">
                <div className={"animatenow "+(this.state.showanimate)} style={{textAlign:"center"}}>
                    <span className="pp-h">Processing Plan. Please wait.....</span>
                    <img src={require("../../assets/images/r.gif")} style={{width:"85vh"}} />
                </div>
                <div className={'lodr '+(this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                 <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
              </SweetAlert>
              {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold'>
                            Forecasting
                        </h5>
                    </div>
                </div>
                <div className="row mt-20p">
                    <div className="col-md-12 col-sm-12">
                        <ul className="d-tabs">
                            <li onClick={this.onClickTab.bind(this,"1")} className={"lttabs "+(this.state.datagridTab)}>
                                <button type="button" className="btn">Forecasting</button>
                            </li>

                            <li onClick={this.onClickTab.bind(this,"2")} className={"lttabs "+(this.state.chartdataTab)}>
                                <button type="button" className="btn">Run Forecast</button>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className={"row mb-20p " +(this.state.datagrid)}>
                    
                    <div className={"mxheit col-sm-12 "+(this.state.dataShwo)} >
                        <div className="col-sm-12">
                            <div className="col-md-2">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Material</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.onChangeItems.bind(this)}
                                        name="forecastitem"
                                        value={this.state.forecastitem}
                                        options={this.state.forecastitemslist}
                                    />
                                </div>
                            </div>       
                            <div className="col-md-4">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Customer</label>
                                    <Select
                                        placeholder={"Select"}
                                        isMulti={true}
                                        onChange={this.onChangeCustomers.bind(this)}
                                        name="forecastCustomer"
                                        value={this.state.forecastCustomer}
                                        options={this.state.forecastcustomerlist}
                                    />
                                </div>
                            </div>    
                            <div className="col-md-2">
                                <div className="form-group ">
                                    <button type="button" onClick={this.onClickGetItemCustomerData.bind(this)} className="btn btn-danger mt-30p">Get Data</button>
                                </div>
                            </div>                
                            
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <button type="button" className="btn btn-default float-right" onClick={this.onBtnExport.bind(this)}>Export to CSV</button>
                                <button type="button" className="btn btn-default float-right" onClick={this.onBtExportExcel.bind(this)}>Export to Excel</button>
                            </div>
                        </div>
                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                // modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowForecastData}
                                enableCharts={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
									
                                modules={[MenuModule, ExcelExportModule]}
                                
                                />
                        </div>
                    </div>
                    <div className={"col-sm-12 "+(this.state.forecastData)}>
                        <div className="row">
                            <div className="col-sm-12">
                                <button type="button" className="btn btn-default float-right" onClick={this.onClickForecastGrid.bind(this)}>Back</button>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <ForecstingParams
                                    forecastingCustomersData={this.state.forecastingCustomersData}
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className={"row mb-20p " +(this.state.chartdata)}>
                    <form method="POST" onSubmit={this.onSubmitGetData.bind(this)}>
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group ">
                                <label className="col-form-label f12">Customer</label>
                                <Select
                                    placeholder={"Select"}
                                    isMulti={true}
                                    onChange={this.onChangeCustomer.bind(this)}
                                    name="customer_code"
                                    value={this.state.customer_code}
                                    options={this.state.customerlist}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group ">
                                <label className="col-form-label f12">Material</label>
                                <Select
                                    placeholder={"Select"}
                                    isMulti={true}
                                    onChange={this.onChangeMaterial.bind(this)}
                                    name="material_code"
                                    value={this.state.material_code}
                                    options={this.state.materiallist}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group ">
                                <label className="col-form-label f12">Duration</label>
                                <Select
                                    placeholder={"Select"}
                                    onChange={this.onChangeDuration.bind(this)}
                                    name="duration"
                                    value={this.state.duration}
                                    options={this.state.durationlist}
                                />
                            </div>
                        </div>
                        
                        <div className={"col-md-2 "+(this.state.inpShow)}>
                            <div className="form-group ">
                                <label className="col-form-label f12">No of Days</label>
                                <input type="text" className="form-control" name="dayspred" value={this.state.dayspred} onChange={this.changeHandler.bind(this)} />
                            </div>
                        </div>    
                        <div className="col-md-2">
                            <div className="form-group ">
                            <button type="submit" className="btn btn-danger mt-30p">Predict Now</button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
             
                
                <div className={"row mb-20p " +(this.state.chartdata)}>
                    <div className="col-sm-12">
                        
                        <button type="button" className="btn btn-default float-right" onClick={this.onBtnExportF.bind(this)}>Export to CSV</button>
                        <button type="button" className="btn btn-default float-right" onClick={this.onBtExportExcelF.bind(this)}>Export to Excel</button>
                    </div>
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid1" style={{width:"100%",height:"70vh"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady1}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection= {true}
                                // masterDetail={true}
                                // detailCellRendererParams={this.state.detailCellRendererParams}
									
                                />
                        </div>
                    </div>
                    
                </div>
                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p">Upload Input File</h5>
                    <div className="row">
                        
                    </div>
                </div>
                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                <div className={"loader "+this.state.loadshow}></div>
                {/* <div className={"overlay-part "+(this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div> */}
            </div>
        )
    }
}

function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 

function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}