
import React, { Component } from 'react';

 
const GridButton = (props) => {
    
   const handleOnClick = () => {
        props.colDef.params.onClickFunction(props,true);
    };
    var iconName = "fa fa-edit";
    if(props.colDef.params.iconName !=undefined && props.colDef.params.iconName !="")
    {
        iconName = props.colDef.params.iconName;
    }
    return (
        <div>
           
            <button className="btn btn-warning grid-btn" onClick={handleOnClick} style={{color:"#000",border:"0px"}}>
                <i className={iconName}></i> {props.colDef.params.buttonName}
            </button>
            
        </div>
    );
};

export default GridButton;
