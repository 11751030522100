import React,{Component} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridButton from './gridButton';
import getToolConfiguration from "./commonFunctions";    
import Showlmvalue from "./showlmvalue";
import $ from 'jquery';

export default class TransporterShareOfBusiness extends Component{
    constructor(props){
        super(props);
        this.state={
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true,
                minWidth:100,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            frameworkComponents:{
                GridButton:GridButton,
                Showlmvalue:Showlmvalue
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            openVehicleAvailabilityModel:false,
            openMaterialConfigurationModel:false,            
            show:false,
            basicTitle:"",
            basicType:"default",
            showAvailablityTable:false,
            data_type:'',
            location:'',
            rowId:'',
            locationNames:[],
            trnsportersList:[],
            statesList:[],
            transdata:[],
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        let toolparameters = {
            data_type : data_type
        }
        await getToolConfiguration(toolparameters)
        await this.boundTypeLocations(params)
        await this.getTransporterShareOfBusiness()
    }

    getTransporterShareOfBusiness = () =>{
        let params = {
            data_type : this.state.data_type
        }
        redirectURL.post('master/getTransporterShareOfBusiness',params)
        .then(async response =>{
            var transdata = response.data.transdata;
            var trnsportersList = response.data.trnsportersList;
            var statesList = response.data.statesList;
            this.setState({
                transdata:transdata,
                trnsportersList:trnsportersList,
                statesList:statesList,
            })
            await this.createColumns(trnsportersList,statesList);
            await this.createRows(trnsportersList,statesList,transdata);
        })
    }

    createColumns =(transporters,states)=>{
        var columns = []
        columns.push({
            headerName:"Transporter Name",
            field:"transporter_name",
            width:230,
            editable:false
        })
        columns.push({
            headerName:"Transporter Code",
            field:"transporter_code",
            width:180,
            editable:false
        })
        states.map((trans) => {
            console.log("trans ", trans)
            var defs = {}
            transporters.map((ste) => {
               console.log("ste ", ste)
              
                var ss = trans
                defs.headerName = trans;
                defs.field = ss;
                defs.width = 120;
                defs.editable = true;
                defs.cellRendererSelector = function(params){
                        
                    var rendComponent = {
                        component: 'Showlmvalue'
                    };
                    return rendComponent
                }
            })
            
            columns.push(defs)
        })
        console.log("Columns ", columns)
        this.setState({
            columnwithDefs:columns,
            overly:"show-n",
            loadshow:"show-n",
        })
    }
     createRows = async(transporters,statesList,transdata) =>{
        var rowdefs = []
        await transporters.map((ste) =>{
            var rows = {}
            rows.transporter_name=ste.transporter_name;
            rows.transporter_code=ste.transporter_code;
            if(transdata.length > 0)
            {        
                var transportername = groupBy(transdata, rdata => rdata.zone);
                transportername.forEach((trans, transkey) =>{
                    trans.map((t) => {  
                        //console.log("Here t ", t)
                        rows["zone"] = transkey;
                        if(t.transporter_code == ste.transporter_code)
                        {
                            rows[t.zone] = t.sob
                           // rows["transporter_value"] = t.sob;
                        }   
                            
                                            
                    })
                    
                })
            }
            rowdefs.push(rows)
        })
        //console.log("rowdefs ", rowdefs)
        var pinnedBottomRowData = {}
        var temp = {};
        if(rowdefs.length > 0)
        {
            var gridkeys = Object.keys(rowdefs[0]);
            statesList.map(function(e){
                temp[e] = 0
            })
        }
        else
        {
            var gridkeys = [];
        }
        gridkeys = gridkeys.filter(function(e){return e == "transporter_name" && e !="transporter_code" && e !="zone"})
        rowdefs.map(function(e, index){
            statesList.map(function(k)
            {
                if(e[k] !=undefined && e[k] !="")
                {
                    //console.log(k,e[k],"temp[k]")
                    temp[k] = temp[k]+parseFloat(e[k]);
                }
            })
        })
        temp['transporter_name'] = "Total";
        temp['transporter_code'] = "Total";
        //console.log(temp,"temp")
        
        var zoneDetails = {};
        zoneDetails.transporter_name = "";
        zoneDetails.transporter_code = "";
        statesList.map(function(s){
            zoneDetails[s] = "View States";
        });
        // rowdefs.push(zoneDetails) //Commented By Bhanu Teja
        this.setState({
            rowData:rowdefs,
            pinnedRegionBottomRowData:[temp],
            overly:'show-n',
            load:'show-n',
        })
    }

    openModelForMaterialConfiguration = () =>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel
        }))
    }

    handelMaterialConfigurationModelCancel = ()=>{
        this.setState(prevState =>({
            openMaterialConfigurationModel :!prevState.openMaterialConfigurationModel,
            proximity_radius_kms:'',
            cluster_min_demand_cmt:'',
            rowId:'',
            
        }))

    }

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}
    
    vehicleConfigurationInputFields = (e) =>{
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name] : value })
    }

    locationChanged = (location) =>{
        this.setState({
            location : location
        })
    }

    boundTypeLocations = async(param) => {

      let locationNames = this.state.locationNames;
      await redirectURL.post('master/getBoundTypeLocations',param)
        .then(async (response) =>{
            if(response.data.status == 'success'){
                let boundLocations = response.data.boundLocations
                if(boundLocations.length > 0){
                    boundLocations.map(item =>{
                        locationNames.push({ value : item.location_code,
                            label : item.location_name })
                    })
                    await this.setState({
                        locationNames : locationNames,
                        location : locationNames[0]
                    })
                }
            }
        })
        
    }

    saveAutoClutering = (params)=>{
        let data_type = this.state.data_type
        redirectURL.post('master/saveAutoClutering',params)
        .then(response =>{
            if(response.data.status == 'success'){
                this.getTransporterShareOfBusiness()
                this.setState({
                        openMaterialConfigurationModel:false,
                        proximity_radius_kms:'',
                        cluster_min_demand_cmt:'',
                        rowId:'',
                    })
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to save the data",
                    basicType:"danger"
                })
            }
        })
    }


    formVehicleConfiguration = (e)=>{
        e.preventDefault();
        let proximity_radius_kms = this.state.proximity_radius_kms;
        let cluster_min_demand_cmt = this.state.cluster_min_demand_cmt;
        let data_type = this.state.data_type;
        let location = this.state.location;
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        let rowId = this.state.rowId;
        let params = {
            proximity_radius_kms : parseInt(proximity_radius_kms),
            cluster_min_demand_cmt : parseInt(cluster_min_demand_cmt),
            data_type : data_type,
            location_code : location.value,
            location_name : location.label,
            rowId : rowId         
        }

        if(location.value != null){
            this.saveAutoClutering(params)
        }else{
            this.setState({
                show:true,
                basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
                basicType:"danger"
            })
        }
    }

    editMaterialConfiguration = async(propdata) =>{
        let data = propdata.data
        await this.setState({
            "proximity_radius_kms" : data.proximity_radius_kms,
            "cluster_min_demand_cmt" : data.cluster_min_demand_cmt,
            "rowId" : data._id
        })
        this.openModelForMaterialConfiguration()
    }

    deleteAutoClutering = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('master/deleteAutoClutering',params)
        .then(response =>{
            if(response.data.status == 'success'){
                this.getTransporterShareOfBusiness()
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }
    onCellClicked(e)
    {
        //console.log(e);
        if(e.value == "View States")
        {
            var zone = e.colDef.field;
            if(zone !=undefined && zone !='')
            {
                var zonesList = this.state.zonesList;
                var zoneStates = zonesList.filter(function(e){return e.zone == zone});
                console.log(zonesList,"zonesList")
                console.log(zoneStates,"zoneStates")
                $(".subH").html(zone+" States");
                this.setState({
                    sliderType:2,
                    zoneStates:zoneStates,
                    overly :"show-m",
                    bulkslide : "slider-translate"
                })
            }
        }
    }
    onCellUpdateData = (params) => {
        // console.log("Params ", params)
        var statesList = this.state.statesList
        var clickedField = params.colDef.field;
        // var colData = params.data;
        // var objects = Object.keys(colData);
        var rowTotal = 0;
        // objects.map(function(e){
        //     if(parseInt(params.data[e]).toString() != "NaN")
        //     {
        //         rowTotal = parseInt(rowTotal)+parseInt(params.data[e]); 
        //     }
        // })
        var allrows = []
        this.gridApi.forEachNode((node, index) => {
            //console.log("node ",node)
            allrows.push(node.data)
        })
        allrows.map(function(e){
            if(parseInt(e[clickedField]).toString() != "NaN")
            {
                rowTotal = parseInt(rowTotal)+parseInt(e[clickedField]); 
            }
        })
        if(parseInt(rowTotal) > parseInt(100))
        {
            // console.log(rowTotal,"rowTotal")
            alert("Share Of Business Should not be greater than 100%");
            var currentField = params.colDef.field;
            params.data[currentField] = 0
        }
        
        var pinnedBottomRowData = {}
        var temp = {};
        // console.log(allrows,"allrows")
        if(allrows.length > 0)
        {
            var gridkeys = Object.keys(allrows[0]);
            statesList.map(function(e){
                temp[e] = 0
            })
        }
        else
        {
            var gridkeys = [];
        }
        gridkeys = gridkeys.filter(function(e){return e != "transporter_name" && e !="transporter_code" && e !="zone"})
        gridkeys.map(function(k)
        {
            allrows.map(function(e, index){
                if(e[k] !=undefined && e[k] !="" && e[k] !="View States")
                {
                    // console.log(k,e[k],"temp[k]")
                    temp[k] = temp[k]+parseFloat(e[k]);
                }
            })
        })
        temp['transporter_name'] = "Total";
        temp['transporter_code'] = "Total";
        // console.log(temp,"temp")
        this.setState({
            pinnedRegionBottomRowData:[temp],
        })
        this.gridApi.refreshCells();
    }
    onGridReady = params => {
        this.gridApi = params.api;  
        this.gridColumnApi = params.columnApi;
        var sort = [
            {
              colId: 'transporter_name',
              sort: 'asc',
            },
          ];
        this.gridApi.setSortModel(sort);
    };
    onGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    updateGridData()
    {
        var allrows = []
        this.gridApi.forEachNode((node, index) => {
            //console.log("node ",node)
            allrows.push(node.data)
        })
        //console.log("allrows ", allrows)
        var records = []
        if(allrows.length > 0)
        {
            allrows.forEach((item) => {
                //console.log("item ", item)
                var row = {}
                row.transporter_code = item.transporter_code
                row.transporter_name = item.transporter_name
                var zones = []
                for(var x in item)
                {
                    //console.log("Item key ", x)
                    if(x == "transporter_name" || x == "zone" || x == "transporter_code")
                    {
                    }
                    else
                    {
                        zones.push({
                            "zone":x,
                            "sob":parseFloat(item[x])
                        })
                    }
                }
                row.zones = zones
                records.push(row)
            })
        }
        console.log("records ",records)
        let data_type = this.state.data_type;
        let location = this.state.location;
        var parameters = {
            records : records,
            location_code : location.value,
            location_name : location.label,
            data_type : data_type,
            // data_valid_from : this.state.data_valid_from,
            // data_valid_to : this.state.data_valid_to,
        }
        redirectURL.post("master/updateTransporterShareOfBusiness", parameters)
        .then((response) => {
            console.log(response);
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Updated Successfully.",
                        basicType : "success",
                        show : true
                    })
                    this.getTransporterShareOfBusiness()
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to update.",
                        basicType : "warning",
                        show : true
                    })
                }
        })
    }
    render(){
        var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        return(
            <>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}>
            </SweetAlert>
                
                <div class="row mb-20p pt-20p">
                <div className="col-sm-12 d-flex">
                <div className= {this.state.showAvailablityTable ? "col-sm-7 " : "col-sm-12 " + "  d-inline"}>
                        <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.locationChanged}
                                        name="location"
                                        value={this.state.location}
                                        options={this.state.locationNames} />

                                </div>
                            </div>

                        <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham dropdown">
                            <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={false}
                            gridOptions={{
                                context: { componentParent: this },
                                getRowStyle: function (params) {
                                    if (params.node.rowPinned) {
                                    return { 'font-weight': 'bold','font-size':'16px' };
                                    }
                                },
                            }}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            onCellClicked={this.onCellClicked.bind(this)}
                            onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                            />
                        </div>
                        <div style={{float:"right",marginTop:"1%"}}>
                            <button type="button" onClick={this.updateGridData.bind(this)} className="btn btn-warning">Assign</button>
                        </div>
                        
                    </div>
                    </div>
                </div>

            </>
        )
    }
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}