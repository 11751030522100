import React, { Component } from 'react';


import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

HighchartsSolidGauge(Highcharts);

// drilldown(Highcharts);
function renderIcons() {

    this.series.forEach(series => {
        if (!series.icon) {
            series.icon = this.renderer
                .text(
                    `<i class="fa fa-${series.options.custom.icon}"></i>`,
                    0,
                    0,
                    true
                )
                .attr({
                    zIndex: 10
                })
                .css({
                    color: series.options.custom.iconColor,
                    fontSize: '1.5em'
                })
                .add(this.series[2].group);
        }
        series.icon.attr({
            x: this.chartWidth / 2 - 15,
            y: this.plotHeight / 2 -
                series.points[0].shapeArgs.innerR -
                (
                    series.points[0].shapeArgs.r -
                    series.points[0].shapeArgs.innerR
                ) / 2 +
                8
        });
    });
}

const trackColors = Highcharts.getOptions().colors.map(color =>
    new Highcharts.Color(color).setOpacity(0.3).get()
);
export default class GaugeChartComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
       const highchartoptions = {
	      		 
            chart: {
                type: 'solidgauge',
                height: '80%',
                events: {
                  //  render: renderIcons
                },
                backgroundColor: null
            },
        
            title: {
                text: '',
                style: {
                    fontSize: '24px'
                }
            },
        
            tooltip: {
                borderWidth: 0,
                backgroundColor: 'none',
                shadow: false,
                style: {
                    fontSize: '16px'
                },
                valueSuffix: '%',
                pointFormat: '{series.name}<br>' +
                    '<span class="cfnt13" style=" color: {point.color}; ' +
                    'font-weight: bold">{point.y}</span>',
                positioner: function (labelWidth) {
                    return {
                        x: (this.chart.chartWidth - labelWidth) / 2,
                        y: (this.chart.plotHeight / 2)-17
                    };
                }
            },
        
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{ // Track for Conversion
                    outerRadius: '112%',
                    innerRadius: '88%',
                    // backgroundColor: trackColors[0],
                    backgroundColor: "rgba(167,201,87,0.3)",
                    borderWidth: 0
                }, { // Track for Engagement
                    outerRadius: '87%',
                    innerRadius: '63%',
                    // backgroundColor: trackColors[1],
                    backgroundColor: "rgba(106,153,78,0.3)",
                    borderWidth: 0
                }, { // Track for Feedback
                    outerRadius: '62%',
                    innerRadius: '38%',
                    // backgroundColor: trackColors[2],
                    backgroundColor: "rgba(56,102,65,0.3)",
                    borderWidth: 0
                }]
            },
        
            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
            },
        
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true
                }
            },
        
            series: [{
                name: 'Overall',
                data: [{
                    color: "#a7c957",
                    radius: '112%',
                    innerRadius: '88%',
                    y: (this.props.overall !== "" && this.props.overall !== undefined)? parseInt(this.props.overall):0
                }],
                // custom: {
                //     icon: 'filter',
                //     iconColor: '#303030'
                // }
            }, {
                name: 'Dedicated',
                data: [{
                    color: "#6a994e",
                    radius: '87%',
                    innerRadius: '63%',
                    y: (this.props.dedicated !== "" && this.props.dedicated !== undefined)? parseInt(this.props.dedicated):0
                }],
                // custom: {
                //     icon: 'comments-o',
                //     iconColor: '#ffffff'
                // }
            }, {
                name: 'Spot',
                data: [{
                    color: "#386641",
                    radius: '62%',
                    innerRadius: '38%',
                    y: (this.props.spot !== "" && this.props.spot !== undefined)? parseInt(this.props.spot):0
                }],
                // custom: {
                //     icon: 'commenting-o',
                //     iconColor: '#303030'
                // }
            }],
			credits:false
        };

        return ( 
            <div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
            
               <div className="chart-title">
                    <button className="btn btn-success">VFR %</button>
                </div>
            </div>
			);
		}
}
