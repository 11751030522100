import React, { useState, useEffect } from "react";
import NewConfigL2 from "./newconfgL2";
const NewConfigL1 = (props) => {
  //   console.log(props.data, "prop");
  var [data, setData] = useState(props.data);
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const changeHandler = async (item, event) => {
    const updatedData = { ...data };
    if (updatedData[item].value === 0) {
      updatedData[item].value = 1;
    } else {
      updatedData[item].value = 0;
    }

    if (updatedData[item].type === "number") {
      updatedData[item].value = parseInt(event.target.value);
    }

    setData(updatedData);
  };
  // var data = props.data;
  //   console.log(data,'hda')
  //   var [data, setData] = useState(props.data);
  var keys = Object.keys(data);
  //   console.log(data.drop, "da l1");

  return (
    <div className="col-md-12 mr-5" style={{ display: "block" }}>
      <div className="row mt-30p">
        {keys.map((item, idx) => {
          if (
            item !== "type" &&
            item !== "location" &&
            item !== "value" &&
            item !== "disabled" &&
            item !== "name" &&
            item !== "id" &&
            item !== "visible"
          ) {
            // console.log(data[item].data, "item");
            if (data[item].type === "checkbox") {
              return (
                <div key={idx} className="col-md-12 mr-3" id={item}>
                  <label className="fw-600">
                    <input
                      checked={data[item].value === 1}
                      type={data[item].type}
                      disabled={data[item].enabled}
                      value={data[item].value}
                      onChange={(event) => changeHandler(item, event)}
                    />
                    &nbsp;&nbsp;{data[item].name}
                  </label>
                  <div>
                    {data[item].data !== undefined ? (
                      <NewConfigL2
                        data={data[item].data}
                        status={data[item].value}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <NewConfigL2 data={data[item].data} /> */}
                </div>
              );
            }
          }
        })}
      </div>
    </div>
    // <>hello</>
  );
};

export default NewConfigL1;
