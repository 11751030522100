
import React ,{useState, useEffect, useMemo , useRef, Suspense} from "react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import Constant from "../common/constants";
import MonthPicker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import redirectURL from '../redirectURL';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import { data } from "jquery";
var moment = require('moment');
const GPSQuality = () => {
    const gridRef = useRef(null);
    const [reqRrowData, setReqRowData] = useState([]);
    const [overlayBlock, setOverlayBlock] = useState('show-n');
    const [paginationPageSize, setPaginationPageSize] = useState(15);
    const [loadshow, setLoadShow] = useState('show-n')
    const [show, setShow] = useState(false);
    const [basicTitle, setBasicTitle] = useState('');
    const [basicType, setBasicType] = useState('default')
    const [data_type, setDataType] = useState(null)
    const [vehicle, setVehicle] = useState(null);
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [location, setLocation] = useState('');
    const [locationNames, setLocationNames] = useState([]);
    // const [startDate, setStartDate] = useState(moment.parseZone().subtract(1, 'months').format('YYYY-MM-DDTHH:mm'));
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(moment.parseZone().format('YYYY-MM-DDTHH:mm'));
    const [month, setMonth] = useState({year: new Date().getFullYear(), month: new Date().getMonth() + 1});
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); 
    const initialFormattedDate = `${currentYear}-${currentMonth}`;
    const [formattedDate, setFormattedDate] = useState(initialFormattedDate);
    const [ filterTransporters, setFilterTransporters ] = useState([]);
    const [flTransporter, setFlTransporter] = useState("");
    const [columnDefs, setColumnDefs] = useState([])
    const [showGrid, setShowGrid] = useState(true)
    const defaultColDef = {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true
    };
    const columns = [
        {
            headerName: "Location Code",
            field: `location_code`,
            width: 140,
            pinned: "left"
        },
        {
            headerName: 'Vehicle No',
            field: 'truck_no',
            width: 140,
            pinned: "left"
        },
    ];
    useEffect(async ()=>{
        setLoadShow('show-m')
        setOverlayBlock('show-m')
        let params = {
            data_type: 2,
            dpt: "SNDG"
        };        
        await boundTypeLocations(params)
        
    }, [])
   useEffect(()=>{

    setFilterTransporters([])
    setFlTransporter('')
   }, [location])
    const locationChanged = (e) => {
        console.log(e, "aaaaaaaa")
        setLocation(e);
        let params = {
            data_type: 2,
            location_code: e.value
        }
        console.log(params,"onChangeLocation")
        fetchVehiclesByPlantName(params)
        getTransporterDetails(params)
    };
    const handleChange = (year, month) => {
        setSelectedYear(year);
        setSelectedMonth(month);
        setFormattedDate(`${year}-${month.toString().padStart(2, '0')}`);
        console.log(formattedDate,"formattedDate")
    };
    const handleClick = () => {
        picker.show();
    };
    
    let picker = null;

    const boundTypeLocations = async (param) => {
    
        let listOfLoc = [];
        await redirectURL
          .post("master/getBoundTypeLocations", param)
          .then(async (response) => {
            if (response.data.status == "success") {
              
              let boundLocations = response.data.boundLocations;
              if (boundLocations.length > 0) {
                boundLocations.map((item) => {                  
                    listOfLoc.push({ value : item.location_code, label : item.location_name })
                });
                if (listOfLoc.length > 0) {
                    setLocationNames(listOfLoc);
                    console.log(listOfLoc[0].value,"ssssssssssss")
                    setLocation(listOfLoc[0])
                    let params = {
                        data_type: 2,
                        location_code: listOfLoc[0].value
                    }
                    console.log(params,"paramssss")
                    await fetchVehiclesByPlantName(params)

                    let transParams = {
                        data_type: 2,
                        location_code: listOfLoc[0].value
                    }
                    // console.log(transParams,"transParamstransParams")
                    await getTransporterDetails(transParams)
                    let intitLocValue = listOfLoc[0]
                    console.log(intitLocValue,"WhileLoading")
                    let getLocParams = {
                        intitLocValue: intitLocValue
                    }
                    
                    await onGetGPSDetails(getLocParams)
                    
                }
              }
            }
          })
          .catch(function (error) {
            var errDetails = {
              url: "master/getBoundTypeLocations",
              screen: "Vehicle Forecast Configurations",
              error,
            };
            redirectURL.post("/master/logErrorsForApiCalls", errDetails);
          });
    };
    const getTransporterDetails = async (params) => {
        params.dept_code = "SNDG"
        // console.log(params,"paramssss for transporters")
        await redirectURL.post("/tracking/getTransporterDetails",params)
		.then((resp) => {
            // console.log(resp.data,"resp.dataaaaa")
			var transporters = resp.data.data.transporters;
            // console.log(transporters,"transporters")
			var x=  [{label:"All", value:"All"}];
			if(transporters != undefined ){
                if(transporters.length > 0)
                {
                    transporters.map(function(t){
                        x.push({
                            label : t.transporter_name,
                            value : t.transporter_code,
                        });
                    });
                }
		    }		
            
            setFilterTransporters(x);	
		})        
        .catch(function (error) {
            var errDetails={
                url:"master/getTransporterDetails",
                screen:'GPS Quality',
                error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        })
    }
    const fetchVehiclesByPlantName = async (arg) => {
        console.log(arg,"argggggg")
        setVehicle(null)
        setVehicleOptions([])
        await redirectURL.post("master/fetchPlantWiseVehicles", arg)
        .then((resp)=>{
            if(resp.data.status === "success"){
                let records = resp.data.records;
                console.log(records, "Vehicle records")
                let vOptions = [{label:"All", value:"All"}];
                let duplicateOptions = []
                let vehicleSet = new Set();   
                let x = records.forEach((e)=>{
                    if(vehicleSet.has(e.vehicle_no)){
                        duplicateOptions.push(e)
                    }else{
                        vOptions.push({value: e.vehicle_no, label: e.vehicle_no})
                        vehicleSet.add(e.vehicle_no);
                    }
                })          
                setVehicleOptions(vOptions);
            }
        })
        .catch(function (error) {
            var errDetails={
                url:"master/fetchPlantWiseVehicles",
                screen:'Requirements',
              error,
              }
            redirectURL.post("/master/logErrorsForApiCalls",errDetails)
        })
    }
    const vehicleChanged = (selectedOption) => {
        setVehicle(selectedOption)
    }
    const newFilter = (selectedOption) => {
        console.log(selectedOption,"selectedOption")
        setFlTransporter(selectedOption)
    }
    const onGetGPSDetails = (getLocParams) => {
        
        console.log(formattedDate,"formattedDate")
        
        if(!formattedDate){
            setShow(true);
            setBasicTitle("Please Specify Year & Month")
            setBasicType("danger")
        }else{
            
            let params = {
                year_month: formattedDate,
                ...(vehicle && vehicle.value && vehicle.value!=="All" && {vehicle_no: vehicle.value}),
                ...(location && {location_code: location.value}),
                ...(getLocParams.intitLocValue && {location_code: getLocParams.intitLocValue.value}),
                ...(flTransporter && flTransporter.value && flTransporter.value!=="All" && {transporter: flTransporter.value})
            }                

            console.log(params,"before hitting")
            redirectURL.post("master/getGPSQualityDetails", params)
            .then((resp)=>{
                console.log(resp.data,"getGPS details")
                let gpsQualityData = resp.data;
               
                const [year, month] = formattedDate.split('-').map(Number);
                const daysInMonth = new Date(year, month, 0).getDate(); 
                const availableDates = new Set();
                gpsQualityData.forEach(entry => {
                Object.keys(entry).forEach(key => {
                    if (key.match(/\d{2}-\d{2}-\d{4}/)) {
                    availableDates.add(key);
                    }
                });
                });
                
                for (let day = 1; day <= daysInMonth; day++) {
                    const dateStr = `${day}-${month}-${year}`;
                    columns.push({
                      headerName: dateStr, // Use dateStr for the header name
                      marryChildren: true,
                      children: [
                        {
                          headerName: "Distance Based",
                          field: `${dateStr}_x`,
                          width: 160,
                          valueFormatter: (params) => {
                            const value = params.value;
                            if (value) {
                              return value;
                            }
                            return "N/A";
                          },
                        },
                        {
                          headerName: "Frequency Based",
                          width: 160,
                          field: `${dateStr}_y`,
                          valueFormatter: (params) => {
                            const value = params.value;
                            if (value) {
                              return value;
                            }
                            return "N/A";
                          },
                        }
                      ]
                    });
                }
                let transCol = [                    
                    {
                        headerName: 'Transporter',
                        field: "transporter_name",
                        width: 240,
                        pinned: "left"
                    },
                ]
                setColumnDefs([...transCol,...columns]);
                let formattedData = []
                if(gpsQualityData.length > 0 && gpsQualityData[0].hasOwnProperty("truck_no")){
                    formattedData = gpsQualityData.map(item => {
                        const formattedItem = {
                            truck_no: item.truck_no,
                            location_code: getLocParams?.intitLocValue?.value || location.value,
                            transporter_name: item.transaporter_name
                        };
                    
                        for (let day = 1; day <= daysInMonth; day++) { 
                            const dateStr = `${day}-${month}-${year}`;
                            const dayValue = item[dateStr];
                            if (dayValue) {
                              const [x, y] = dayValue;
                              formattedItem[`${dateStr}_x`] = x || "";
                              formattedItem[`${dateStr}_y`] = y || "";
                            } else {
                              formattedItem[`${dateStr}_x`] = "";
                              formattedItem[`${dateStr}_y`] = "";
                            }
                        }
                    
                        console.log(formattedItem, "formattedItem after processing days");
                        return formattedItem;
                    });
                    
                console.log(formattedData,"formatedDataaaa")
                setReqRowData(formattedData);
                setShowGrid(false);
                setTimeout(()=>{
                    setShowGrid(true)
                }, 500)
                }else {
                    setShow(true);
                    setBasicTitle("No Data Found")
                    setBasicType("danger");
                    setReqRowData([]);
                    setFlTransporter("");
                    setVehicle("");
                }
            })
        }
    }
    const closeAlert = () => {
        setShow(false)
    }
    
  return (
    <div className="row">
        <SweetAlert
            show={show}
            type={basicType}
            title={basicTitle}
            onConfirm={closeAlert}>
        </SweetAlert>
        <div className="col-sm-12 mb-5p ">
            <div className="col-sm-12">
                <div className="col-sm-6">
                    <h5 className='fbold '>
                        <span>{Constant.MASTER_GPS_QUALITY}</span>                            
                    </h5>
                </div>
            </div>
            <div className="col-sm-12 pt-5p">                        
                <div className="col-sm-2">
                    <div className="form-group ">
                        <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                        <Select
                            placeholder={"Select"}
                            onChange={locationChanged}
                            name="location"
                            value={location}
                            options={locationNames} />
                    </div>
                </div>
                <div className="col-sm-3 form-control-custom">
                    <div className="form-group">
                        <label className="col-form-label f12">Select Month and Year <span style={{color:"red"}} className="redColor">*</span></label>
                        <input
                        type="text"
                        className="form-control"
                        value={formattedDate}
                        readOnly
                        onClick={handleClick}
                        style={{backgroundColor:"#fff"}}
                        placeholder="Select Month and Year"
                        />
                        <MonthPicker
                            ref={(ref) => picker = ref}
                            years={{ min: { year: 2000, month: 1 }, max: { year: 2030, month: 12 } }}
                            value={{ year: selectedYear, month: selectedMonth }}
                            onChange={handleChange}
                            lang={{ months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] }}
                            show={true}
                        />
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-group">
                        <label className="col-form-label f12">Transporter  </label>
                        <Select 
                            placeholder={"Select..."}
                            nam="flTransporter"
                            value={flTransporter}
                            onChange={newFilter}
                            options={filterTransporters}
                        />
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-group ">
                        <label className="col-form-label f12">Select Vehicle </label>
                        <Select
                            placeholder={"Select..."}
                            name="vehicle"
                            value={vehicle}
                            onChange={vehicleChanged}
                            options={vehicleOptions}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <button type="button" className="btn btn-warning mt-35px" onClick={onGetGPSDetails}>Get Data</button>
                </div>
            </div>
        </div>
        <div className="row mb-5p" style={{width:"100%"}}>
            <div className="col-sm-12" style={{display:"flex", justifyContent:"right", textAlign:"right"}}>
                <div className="col-sm-8">
                    <ul className="disclaimer">
                        <li><span style={{color:"red"}} className="redColor">*</span>&nbsp; Distance Based - GPS Quality (Based on 5 KM GPS gaps)</li>
                        <li><span style={{color:"red"}} className="redColor">*</span>&nbsp; Frequency based - GPS Consistency % (GPS Every 30 Mins)</li>
                    </ul>
                </div>
            </div>
        </div>
        {showGrid ? 
            <div id="myGrid" style={{maxWidth:"100%",height:"70vh", padding: "0 20px"}} className="col-sm-12 ag-theme-balham dropdown">
                <AgGridReact
                ref={gridRef}
                rowData={reqRrowData}
                paginationPageSize={paginationPageSize}
                pagination={true}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                />
            </div>
        : ""}
        
    </div>
  )
}
const getCurrentDateTime =() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
}
export default GPSQuality
