import React, { Component } from 'react';
import ReactDOM, { render } from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;

export default class ScatterChartLoadingDashboard extends Component {
    render() {
        const highchartoptions = {
            chart: {
                type: 'packedbubble',
                height: '100%'
            },
            title: {
                text: 'Loading Time Distribution'
            },
            subtitle:{
                text:'Segmented distribution of the time taken inside the plant for loading'
            },
            tooltip: {
                useHTML: true,
                pointFormat: '<b>{point.name}: {point.value}</b>'
            },
            plotOptions: {
                packedbubble: {
                    minSize: '60%',
                    maxSize: '180%',
                    zMin: 0,
                    zMax: 1000,
                    layoutAlgorithm: {
                        splitSeries: false,
                        gravitationalConstant: 0.02
                    },
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}',
                        style: {
                            opacity:1,
                            fontSize:"13px"
                        }
                    },
                    fillOpacity:1,
                }
            },
            tooltip: {
                valueDecimals: 2,
                valueSuffix: ' %'
            },
            colors: ['#f35e82', '#7f84e9'],
            series: this.props.series,
            credits:false
        };

        return (
            <div>
                <HighchartsReact highcharts={Highcharts} options={highchartoptions} />
            </div>
        );
    }
}

// import React from 'react';
// import HighchartsReact from 'highcharts-react-official';
// import Highcharts from 'highcharts';

// class MyBubbleChart extends React.Component {
//     render() {
//         const highchartoptions = {
//             chart: {
//                 type: 'packedbubble',
//                 height: '100%'
//             },
//             title: {
//                 text: 'Loading Time Distribution'
//             },
//             subtitle:{
//                 text:'Segmented distribution of the time taken inside the plant for loading'
//             },
//             tooltip: {
//                 useHTML: true,
//                 pointFormat: '<b>{point.name}: {point.value}</b>'
//             },
//             plotOptions: {
//                 packedbubble: {
//                     minSize: '60%',
//                     maxSize: '180%',
//                     zMin: 0,
//                     zMax: 1000,
//                     layoutAlgorithm: {
//                         splitSeries: false,
//                         gravitationalConstant: 0.02
//                     },
//                     dataLabels: {
//                         enabled: true,
//                         format: '{point.name}',
//                         style: {
//                             opacity:1,
//                             fontSize:"13px"
//                         }
//                     },
//                     fillOpacity:1,
//                 }
//             },
//             tooltip: {
//                 valueDecimals: 2,
//                 valueSuffix: ' %'
//             },
//             colors: ['#f35e82', '#7f84e9'],
//             series: this.props.series,
//             credits:false
//         };

//         return (
//             <div>
//                 <HighchartsReact highcharts={Highcharts} options={highchartoptions} />
//             </div>
//         );
//     }
// }

// export default MyBubbleChart;
