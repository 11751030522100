import React,{Component} from 'react';
import CSVFileValidator from 'csv-file-validator';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import SweetAlert from 'react-bootstrap-sweetalert';

import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import redirectURL from '../redirectURL';
import ViewMap from "./viewmap";
import Constant from "../common/constants";
import getStackingToolConfiguration from "./commonFunctions";
import GridButton from './gridButton';


var map;


export default class PlantWarehouseLocations extends Component{
    constructor(props){
        super(props);
    this.state = {
        modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable:true
            },
            frameworkComponents:{
                GridButton:GridButton,
            },
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
			detailCellRendererParams:{},
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
        overlayBlock:"show-n",
        showSlideBlockPickupDrop:"",
        showloader:"show-n",
        csvfiledata:[],
        openPlantWarehouseLocationsModel:false,
        address_name:'',
        addressLat:'',
        addressLng:'',
        location_name:'',
        location_code:'',
        show:false,
        basicTitle:"",
        basicType:"default",
        data_type:"",
        location_address:"",
        latitude:"",
        longitude:"",
        rowId:"",
    }

    this.showMap = this.showMap.bind(this);

    }

    componentDidMount = async() =>{

        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = this.props.match.path
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'itemstacking'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})
        let params = {
            data_type : data_type
        }
        
        // module type need to be sent from local storage
        // or need to have different data type 
        await getStackingToolConfiguration(params)
        await this.getStackingPlantWarehouseLocationsData(params)
    }
    

    getStackingPlantWarehouseLocationsData = (params) =>{
        
        redirectURL.post('/stacking/getStackingPlantWarehouseLocationsData',params)
        .then(response =>{
            if(response.data.status == 'success'){

            this.setState({
                rowData:response.data.plantWarehouseLocations
            })
        }
        })
    }
  
    openModelForPlantWarehouseLocations = () =>{
        this.setState(prevState =>({
            openPlantWarehouseLocationsModel :!prevState.openPlantWarehouseLocationsModel,
        }))
        this.showMap();
    }

    handelPlantWarehouseModelCancel = ()=>{
        this.setState(prevState =>({
            openPlantWarehouseLocationsModel :!prevState.openPlantWarehouseLocationsModel,
            addressLng:'',
            addressLat:'',
            address_name:'',
            location_name:'',
            location_code:'',
            rowId:''
            
        }))

    }

    openUploadOptionForPickupDrop = () =>{
        this.setState({
            showSlideBlockPickupDrop:"slide25",
            overlayBlock:"show-m"
          })
    }

    hideSlideBlock = () =>{
        this.setState({
            overlayBlock:"show-n",
            showSlideBlockPickupDrop:"",
        })
      }

      formCSVUpload = (event) =>
      {
        event.preventDefault();
        this.setState({
          showloader:"show-m"
        })
        var csvfiledata = this.state.csvfiledata;
        // console.log("csvfiledata ", csvfiledata)
        var p = {
          filedata: csvfiledata
        }
        // redirectURL.post("/vehicles/uploadVehicleInfoData",p)
        // .then((resp) => {
          // console.log("resp ", resp.data)
        //   window.location.reload();
        // })
      }

//for bulk upload functionality commented for now
      changeCSVFileHandler = async (e) => {
        const config = {
            headers: [
                { 
                    name: 'Location Type',
                    inputName: 'location_type',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Location Code',
                    inputName: 'location_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Location Name',
                    inputName: 'location_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Latitude',
                    inputName: 'latitude',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                 },
       
                { 
                    name: 'Longitude',
                    inputName: 'longitude',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Location Address',
                    inputName: 'location_address',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            this.setState({
                csvfiledata:csvData.data
            });
        })
        .catch(err => {})
            
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
        });
        
    }


    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {

        //   loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyC6cJAlLsrTTgm5Ck795Vg6X_EToa7FG-A&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}


    initMap = () => {

        const getAddressLatLng = (address,lat,lng)=>{
            
            this.setState({
                addressLng:lng,
                addressLat:lat,
                address_name:address
               })
            }

        const updateLatLngOnClickMap = (lat,lng)=>{

             try{
            var reqparams = new FormData()
            reqparams.append("location_latitude", lat)
            reqparams.append("location_longitude", lng)

            
            redirectURL.post("http://216.48.191.229:8010/dispatch_planning/fetchLocationAddressDetails",reqparams)
            .then((response) => {
                if(response.data.status == "Success"){
                    this.setState({
                        addressLng:lng,
                        addressLat:lat,
                        address_name:response.data.formatted_address
                       })
                }else{
                    this.setState({
                        addressLng:lng,
                        addressLat:lat,
                        address_name:''
                       })
                }

            })
            }catch(e){
                
            }

        }    
        try{
            var latitude = 28.6139391;
            var longitude = 77.2090212;
            if(this.state.addressLat !=undefined && this.state.addressLng !=undefined && this.state.addressLat !="" && this.state.addressLng !="")
            {
                var latitude = this.state.addressLat;
                var longitude = this.state.addressLng;
            }
    	map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 12,
            center: new window.google.maps.LatLng(latitude,longitude),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: window.google.maps.ControlPosition.TOP_RIGHT
			},            
			styles: [
                    
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#444444"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 65
						},
						{
							"visibility": "on"
						}
					]
				},
				
				{
					"featureType": "landscape.man_made",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#c1c2c2"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
			],
			disableDefaultUI: true,
			zoomControl: true
          });
            if(this.state.addressLat !=undefined && this.state.addressLng !=undefined && this.state.addressLat !="" && this.state.addressLng !="")
            {
                var latLng=new window.google.maps.LatLng(parseFloat(this.state.addressLat),parseFloat(this.state.addressLng));
                var marker = new window.google.maps.Marker({
                    position: latLng,
                    icon: require('../../assets/images/business.png'),
                    map: map
                });
                
                markers.push(marker);
            }
        }
        catch(e){}
  	    var input = document.getElementById('search');
          var searchBox = new window.google.maps.places.SearchBox(input);
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

          map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());
          });

  		var markers = [];
  		 searchBox.addListener('places_changed', function() {
            var places = searchBox.getPlaces();
            
            if (places.length == 0) {
              return;
            }

            markers.forEach(function(marker) {
              marker.setMap(null);
            });
            markers = [];
            var bounds = new window.google.maps.LatLngBounds();
            places.forEach(function(place) {
              if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
              }
              var icon = {
                url: place.icon,
                size: new window.google.maps.Size(71, 71),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 34),
                scaledSize: new window.google.maps.Size(25, 25)
              };


			var image =  require('../../assets/images/business.png');
              markers.push(new window.google.maps.Marker({
                map: map,
                icon: image,
                title: place.name,
                position: place.geometry.location
              }));
            
                getAddressLatLng(place.formatted_address,place.geometry.location.lat(),place.geometry.location.lng())
                bounds.extend(place.geometry.location);
            });
            map.fitBounds(bounds);
          });
  		
  		window.google.maps.event.addListener(map, "click", (event) => {
            markers.forEach(function(marker) {
                marker.setMap(null);
              });
              var image
            markers.push(new window.google.maps.Marker({
                map: map,
                icon: image,
                // title: place.name,
                position: event.latLng
              }));
            
            updateLatLngOnClickMap(event.latLng.lat(),event.latLng.lng())
            // geo coder for address not getting address cors error get data from external API
            // const geocoder = new window.google.maps.Geocoder();
            // geocoder
            //     .geocode({ location: event.latlng })
            //     .then((response) => {
            //     if (response.results[0]) {
            //         console.log('rep',response.results[0])
            //     }
            // })
          });
    
 
    }
    
    
    addressInputField = (e) =>{
        
        this.setState({address_name:e.target.value})
    }
    handleSearchLocation = (e) => {

        this.setState({address_name:e.target.value})
    }
    handelLatInput = (e) =>{
        
        this.setState({addressLat:e.target.value})
    }
    handelLngInput = (e) =>{
        
        this.setState({addressLng:e.target.value})
    }
    
    locationCodeField = (event) => {
		let value = (event.target.value).toUpperCase();
    	this.setState({location_code:value});
    }
    locationNameField = (event) => {
		let value = event.target.value;
    	this.setState({location_name:value});
    }
    

    closeAlert = async() => {
        await this.setState({
            show: false
        });
	}

    savePlantWarehouseLocationsData = (params) =>{
        let data_type = this.state.data_type
        redirectURL.post('/stacking/savePlantWarehouseLocationsData',params)
        .then(response =>{

            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getStackingPlantWarehouseLocationsData(param)
                this.setState(prevState =>({
                    openPlantWarehouseLocationsModel :!prevState.openPlantWarehouseLocationsModel,
                    addressLng:'',
                    addressLat:'',
                    address_name:'',
                    location_code:'',
                    location_name:'',
       
                }))
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Data Not Saved successfully",
                    basicType:"danger"
                })
            }
        })
    }

    updatePlantWarehouseLocationsData = (params) =>{
        let data_type = this.state.data_type
        redirectURL.post('/stacking/updatePlantWarehouseLocationsData',params)
        .then(response =>{

            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getStackingPlantWarehouseLocationsData(param)
                this.setState(prevState =>({
                    openPlantWarehouseLocationsModel :!prevState.openPlantWarehouseLocationsModel,
                    addressLng:'',
                    addressLat:'',
                    address_name:'',
                    location_code:'',
                    location_name:'',
                    rowId:''
                }))
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Data Not Saved successfully",
                    basicType:"danger"
                })
            }
        })
    }


    locationFormHandler = (e) =>{
        e.preventDefault();
        
        let address_name = this.state.address_name
        let addressLat = parseFloat(this.state.addressLat)
        let addressLng = parseFloat(this.state.addressLng)
        let location_name = this.state.location_name
        let location_code = this.state.location_code
        let data_type = this.state.data_type
        let rowId = this.state.rowId

        if(address_name == ''){
            try{
                var reqparams = new FormData()
                reqparams.append("location_latitude", addressLat)
                reqparams.append("location_longitude", addressLng)
    
                
                redirectURL.post("http://216.48.191.229:8010/dispatch_planning/fetchLocationAddressDetails",reqparams)
                .then((response) => {
                    if(response.data.status == "Success"){
                        
                        address_name  = response.data.formatted_address
                        
                    }else{
                        this.setState({
                            show:true,
                            basicTitle:"Please check lat and lng",
                            basicType:"danger"
                        })
                    }
                })
            }catch(e){

            }
        }


        let params = {
            location_address : address_name,
            latitude : addressLat,
            longitude : addressLng,
            location_name : location_name,
            location_code : location_code,
            data_type : data_type,
        }

        if(rowId != '') {
            params.rowId = rowId
            this.updatePlantWarehouseLocationsData(params)
        }else{
            this.savePlantWarehouseLocationsData(params)
        }
                
    }
    
    updatePlantWarehouseLocations = async(propdata) =>{
        let data = propdata.data
        await this.setState({
            location_code:data.location_code,
            location_name:data.location_name,
            address_name:data.location_address,
            location_type:data.location_type,
            addressLat:data.latitude,
            addressLng:data.longitude,
            rowId:data.plantWarehouseLocation_id
        })
        this.openModelForPlantWarehouseLocations()
    }

    deleteStackingPlantWarehouseLocationsDetails = (propData) =>{
        let data = propData.data
        let data_type = this.state.data_type
        
        let params = {
            delete_id : data._id
        }
        redirectURL.post('stacking/deleteStackingPlantWarehouseLocationsDetails',params)
        .then(response =>{
            if(response.data.status == 'success'){
                let param = {
                    data_type : data_type
                }
                this.getStackingPlantWarehouseLocationsData(param)
          }else{
            this.setState({
                show:true,
                basicTitle:"Failed to delete the data",
                basicType:"danger"
            })
          }
        })
    }

    render(){
        var colsDefs = [
            {
                headerName:"Actions",
                field:"cluster",
                width:"80",
                filter:false,
                pinned:'left',
                sortable:false,
                params:{buttonName:'Edit',
                        onClickFunction:this.updatePlantWarehouseLocations},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"",
                field:"cluster",
                width:"80",
                pinned:'left',
                params:{buttonName:'Delete',iconName:"fa fa-trash",
                        onClickFunction:this.deleteStackingPlantWarehouseLocationsDetails},
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: "GridButton"
                    };
                    return rendComponent
                
                },
            },
            {
                headerName:"Location Code",
                field:"location_code",
                width:"120"
            },
            {
                headerName:"Location Name",
                field:"location_name",
                width:"130"
            },
            {
                headerName:"Address",
                field:"location_address",
                width:"200"
            },
            
        ]

       
        return(
            <div>

                <div className="row mb-20p  pt-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold '>
                            <span>{Constant.MASTER_STACKING_PLANTWAREHOUSE_LOCATIONS_SCREEN_HEADER_NAME}</span>
                                    <div className='float-right col-sm-4'>
                                    <a href="javascript:;" onClick={this.openModelForPlantWarehouseLocations} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.MASTER_STACKING_PLANTWAREHOUSE_LOCATIONS_SCREEN_ADD_BUTTON_NAME}</a>
                                    {/* bulk data will be done later */}
                                    {/* <span className="float-right">
                                    <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">+ Upload pickup/drop Locations Data</a>
                                    </span> */}
                                    </div>    
                        </h5>
                    </div>
                </div>
                
                <div className="row mb-20p">
                <div className="col-sm-12 d-flex">
                <div className="col-sm-5 d-inline">
                    <div id="myGrid" style={{width:"100%",height:"70vh"}} className="col-sm-12 ag-theme-balham">
                       <AgGridReact
                       modules={this.state.modules} 
                        rowData={this.state.rowData}
                        columnDefs={colsDefs}
                        gridOptions={{context:{componentParent:this}}}
                        defaultColDef={this.state.defaultColDef}
                        frameworkComponents={this.state.frameworkComponents}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                       />
                    </div>
                    </div>
                    <div className='col-sm-7 d-inline'>
                    <ViewMap 
                    locationsData = {this.state.rowData}
                    />
                    {/* <div id="pickupDrop_Locations_map" className="col-xl-12 col-lg-12 ml-0p" style={{width:'100%',height:"80vh"}}></div> */}
                    </div>
                    
                    </div>
                </div>


                <div className={"overlay-block "+(this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 "+(this.state.showSlideBlockPickupDrop)}>
                {/* <h5 className="crd-bg p-10p">{Constant.DEALER_FORM_UPLOAD_PARTS}</h5> */}
                <div className="row">   
                    <div className="col-sm-12">
                    <form onSubmit={this.formCSVUpload}>
                    <div className="row p-20p">
                        <div className="form-group col-sm-12 mb-20p">
                            {/* <label style={{color:"#000"}}>{Constant.DEALER_FORM_UPLOAD_FILE_XLS} <span className="rclr fbold">*</span></label> */}
                            
                            <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={this.changeCSVFileHandler} required />
                        </div>
                        <div className="form-group col-sm-12 mb-20p">
                        <button type="submit" className="btn btn-danger">SAVE</button>
                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                        </div>
                    </div>
                    </form>
                    </div>
                </div>
                </div>




                <Modal closeOnOverlayClick={false} open={this.state.openPlantWarehouseLocationsModel} onClose={this.handelPlantWarehouseModelCancel} 
                    classNames={{modal: 'pickupdrop-modelClass'}} >


                    <div className="container-fluid">
                            <SweetAlert
                                show={this.state.show}
                                type={this.state.basicType}
                                title={this.state.basicTitle}
                                onConfirm={this.closeAlert}>
                                    </SweetAlert>
                    <div className="mappg mprel">
                        <div className="row">
                            <div className="col-xl-8 col-lg-8">

                                <div id="map_canvas" className="col-xl-12 col-lg-12 ml-0p" style={{width:'100%',height:"90vh", borderRadius:"2px"}}></div>
                                
                            </div>
                            <form className="col-xl-4 col-lg-4" onSubmit={this.locationFormHandler}>
                                <input type="text" name="search" onChange={this.handleSearchLocation}  value={this.state.address_name} className="col-xl-6 col-lg-6 mt-20p ml-20p form-control" id="search" placeholder="Enter Address to search" />
                                
                                
                                <div className="col-xl-12 col-lg-12">
                                    <div className="form-group">
                                        <label className="c-lbl fbold">Location Code <span className="redColor">*</span></label>
                                            <input type="text" name="location_code" value={this.state.location_code} 
                                            onChange = {this.locationCodeField}   className="form-control forminp" placeholder="Code" maxLength="100" required />
                                        <label className="c-lbl fbold">Location Name <span className="redColor">*</span></label>
                                            <input type="text" name="location_name" value={this.state.location_name} 
                                            onChange = {this.locationNameField}   className="form-control forminp" placeholder="Name" maxLength="100" required />
                                        {/* <label className="c-lbl fbold">Address <span className="redColor">*</span></label>
                                            <input type="text" name="address_name" value={this.state.address_name} 
                                            onChange = {this.addressInputField}   className="form-control forminp" placeholder="Address" maxLength="100" required /> */}
                                        {/* <p className={this.state.duplicateChk} style={{color:"red",fontWeight:"bold"}}>Already Exists</p> */}
                                        {/* <div className="errfnt" style={{color: "rgb(153, 153, 153)"}}><i className="icofont icofont-warning-alt"></i> Special Characters @&-_. are allowed</div> */}
                                    </div>
                                    <div className="form-group">
                                    <label className="c-lbl fbold">Location Latitude <span className="redColor">*</span></label>
                                        <input type="number" name="" value={this.state.addressLat} onChange={this.handelLatInput}
                                        className="form-control forminp" placeholder="Latitude" maxLength="100" required />
                                        <label className="c-lbl fbold">Location Longitude <span className="redColor">*</span></label>
                                        <input type="number" name="" value={this.state.addressLng} onChange={this.handelLngInput}
                                        className="form-control forminp" placeholder="Longitude" maxLength="100" required />
                                    </div>	
                                    
                                </div>
                                <div className="form-group">
                                        <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                                        <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelPlantWarehouseModelCancel}>CANCEL</button>	
                                </div>
                            </form>
                                            
                            </div>
                        </div>
                    </div>
                </Modal>


            </div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
